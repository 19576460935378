import React from "react";
import Scrolls from 'util/Scrolls';

import MessageListItem from "./MessageListItem";

const Messages = React.forwardRef(({messages}, ref) => {
  return (
    <div className="gx-module-list gx-mail-list">
      <Scrolls className="gx-module-content-scroll" ref={ref} >
        {messages.map((message, index) => {
            return(
              <div style={{backgroundColor: (index%2) == 0 ? "#eeeeff" : ''}}>
                <MessageListItem key={index} message={message} />
              </div>
            )
          }
        )}
      </Scrolls>
    </div>
  )
});

export default Messages;
