import React from "react";
import moment from "moment";

const MessageListItem = ({message}) => {
  return (
    <div className="gx-module-list-item gx-mail-cell">
      <div className="gx-module-list-content">
        <div className="gx-mail-user-des">

          <span className="gx-sender-name">{message.sender.name}</span>

          <span className="gx-toolbar-separator">&nbsp;</span>

          <span className="gx-d-inline-block gx-text-truncate gx-send-subject">{message.subject}</span>

          {message.hasAttachments &&

          <i className="icon icon-attachment"/>}

          <div className="gx-time">{moment.utc(message.created_at).local().format("MMM DD")}</div>

        </div>


        <div className="gx-message">
          <p className="gx-text-truncate"> {message.body}</p>

        </div>
        <div className="gx-labels">
        </div>
      </div>

    </div>
  )
};

export default MessageListItem;
