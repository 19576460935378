import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Select, Tabs} from "antd";
import Invoices from "./Invoices";
import InvoiceSettings from "./InvoiceSettings";
import Breadcrumbs from "components/Breadcrumbs/index";
import Auxiliary from "../../../util/Auxiliary";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../constants";
import axios from "axios";

let clientOptions = [];
const Option = Select.Option;
const TabPane = Tabs.TabPane;

class Invoice extends Component {
  state={
    clients: [],
    loading: true,
  };

  componentDidMount () {
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/clients`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(clientsData => {
      clientOptions=[];
      clientsData.data.data.forEach((client, index) => {
        clientOptions.push(<Option value={(client.id).toString()}>{client.name}</Option>)
      })
      this.setState({...this.state, clients: clientsData.data.data, loading: false});
    })
    .catch(error => error)
  }

  render() {
    const {currentEmployee} = this.props;
    let canManage = currentEmployee && currentEmployee.role && (currentEmployee.role.permissions.includes("edit_company") || currentEmployee.role.permissions.includes("manage_company"));
    const columns = [{
        title: 'ID',
        render: (text, record) => (
          <span className={record.oid ? "gx-text" : "gx-text-grey"}>
              {record && record.oid ? record.oid : record.contract ? record.contract.employee_id : ''}
          </span>
        )
      }];
    return (
      <Auxiliary>
      <Breadcrumbs />
      {!this.state.loading && this.props.currentCompany && this.props.currentEmployee && this.props.currentEmployee.role && this.props.currentEmployee.role.permissions ?
        <div className="gx-profile-content">
          <Card className="gx-card" title="Invoices">
            <Tabs type="card">
              <TabPane tab="Invoice" key="invoice">
                <Invoices clients={clientOptions} allClients={this.state.clients}/>
              </TabPane>
              <TabPane tab="Settings" key="settings">
                <InvoiceSettings />
              </TabPane>
            </Tabs>
          </Card>
        </div>
        : <CircularProgress />
      }
      </Auxiliary>
    )
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, currentCompany, currentEmployee}
};

export default connect(mapStateToProps)(Invoice);
