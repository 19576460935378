import React from "react";
import Timer from "react-compound-timer";

const withTimer = timerProps => WrappedComponent => wrappedComponentProps => (
  <Timer {...timerProps}>
    {timerRenderProps =>
      <WrappedComponent {...wrappedComponentProps} timer={timerRenderProps} />}
  </Timer>
);

class Tracker extends React.Component {

    shouldComponentUpdate(nextProps) {
      return !(this.props.time === nextProps.time);
    }

    render() {
      let count=0;
        return (
          <Timer initialTime={0} startImmediately={false} lastUnit="h">
              {({start, reset, setTime}) => (
              <div style={{display: "inline"}}>
              <div style={{display: "inline"}}>
                <Timer.Hours formatValue={value => `${(value < 10 ? `0${value}` : value)} : `} />
                <Timer.Minutes formatValue={value => `${(value < 10 ? `0${value}` : value)} : `} />
                <Timer.Seconds formatValue={value => `${(value < 10 ? `0${value}` : value)}`} />
              </div>
              {(this.props.start && !(this.props.spinning || this.props.iconLoading)) ? setTime(this.props.time  +(count++ * 1000)) : ''}
              <div style={{display: "inline"}}>
                  {this.props.start ? start() : reset()}
              </div>
              </div>
            )}
          </Timer>
        );
    }
}

export default (Tracker);
