import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Col, Divider, Modal, Row, Select, Table, message} from "antd";
import FieldsModal from "./FieldsModal";
import ReactDragListView from 'react-drag-listview';
import {API_URL_V1} from "../../../constants";
import CircularProgress from "components/CircularProgress/index";
import Auxiliary from "../../../util/Auxiliary";
import axios from "axios";

const { Option } = Select;
let employeeOptions = [];
class ApplicantTrackingFormFields extends Component {
  constructor(props) {
  super(props);
  this.state = {
    formFields : [],
    visible: false,
    employeeCount: 0,
    page: 1,
    per_page: 50,
    employeeData: [],
    title: '',
    editData: '',
    deleteVisible: false,
    deleteLoading: false,
    confirmLoading: false,
    loading: true,
  };
  const that = this;
    this.dragProps = {
        onDragEnd(fromIndex, toIndex) {
            const data = that.state.formFields;
            const item = data.splice(fromIndex, 1)[0];
            data.splice(toIndex, 0, item);
            that.dragItem(fromIndex, toIndex, data);
        },
        handleSelector: "a"
    };
  }

  dragItem = (fromIndex, toIndex, data) => {
    const {authUser, currentCompany} = this.props;
    let from, to;
    this.state.formFields.forEach((formField, index) => {
      if(index === fromIndex) {
        from = formField;
      }
      if(index === toIndex) {
        to = formField;
      }
    })
    axios.put(`${API_URL_V1}/ats/openings/${this.props.value.id}/form-fields/${to.id}`, {
        sort_weight: from.sort_weight-1,
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
        }
      }
    )
    .then(formFieldsData => {
      message.success("Dragged Successfully");
      this.getFormFields();
    })
    .catch(error => {
      message.error("Failed to Drag");
    })
  }

  getFormFields = () => {
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/ats/openings/${this.props.value.id}/form-fields`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(formFieldsData => {
      this.setState({...this.state, formFields: formFieldsData.data, loading: false});
    })
    .catch(error => error)
  }

  componentDidMount () {
    this.getFormFields();
  }

  edit = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, editData: record, visible: true, title: "Edit Field"});
  }

  onClick = () => {
    this.setState({...this.state, visible: true, editData: '', title: "Add Field"});
  }

  handleCancel = () => {
    this.setState({...this.state, visible: false});
  }

  handleOk = (values, id) => {
    const {authUser, currentCompany} = this.props;
    this.setState({...this.state, confirmLoading: true});
    if(!id) {
      axios.post(`${API_URL_V1}/ats/openings/${this.props.value.id}/form-fields`, {
          name: values.name,
          display_name: values.display_name,
          type: values.type ? (values.type).toString() : null,
          choices: values.choices ? values.choices : null,
        },
        {
          headers: {
            'Authorization': 'Bearer '+authUser
        }
      })
      .then(formFieldData => {
        let formFields = this.state.formFields;
        formFields.push(formFieldData.data);
        message.success("New Field Added");
        this.setState({...this.state, formFields: formFields, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Add Field");
        this.setState({...this.state, confirmLoading: false});
      })
    } else {
      axios.put(`${API_URL_V1}/ats/openings/${this.props.value.id}/form-fields/${id}`, {
          name: values.name,
          display_name: values.display_name,
          type: values.type ? (values.type).toString() : null,
          choices: values.choices ? values.choices : null,
        },
        {
          headers: {
          'Authorization': 'Bearer '+authUser
          }
        }
      )
      .then(formFieldsData => {
        let formFields = this.state.formFields;
        formFields.forEach((formField, index) => {
          if(formField.id == formFieldsData.data.id){
            formFields[index] = {...formField, ...formFieldsData.data};
          }
        })
        message.success("Field Editted Successfully");
        this.setState({...this.state, formFields: formFields, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Edit");
        this.setState({...this.state, confirmLoading: false});
      })
    }
  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser, currentCompany} = this.props;
    axios.delete(`${API_URL_V1}/ats/openings/${record.opening_id}/form-fields/${record.id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(formFieldsData => {
      message.success("Field Deleted Successfully");
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      this.getFormFields();
    })
    .catch(error => error)
  }

  render() {
    const {currentEmployee} = this.props;
    let canManage = currentEmployee && currentEmployee.role && (currentEmployee.role.permissions.includes("edit_company") || currentEmployee.role.permissions.includes("manage_company"));
    const columns = [{
        title: 'Field Name',
        render: (text, record) => (
          <span>
            {record.name}
          </span>
        )
      }, {
        title: 'Display Name',
        render: (text, record) => (
          <span>
            {record.display_name}
          </span>
        )
      }, {
        title: 'Type',
        render: (text, record) => (
          <span>
            {record.type}
          </span>
        )
      }, {
        title: 'Required',
        render: (text, record) => (
          <span>
          {<i className={`icon ${record.is_required ? "icon-check-circle-o" : "icon-close-circle"}`} title="Edit" style={{verticalAlign: "middle", color: record.is_required ? "green" : "red"}}/>}
          </span>
        )
      }, {
        title: 'Actions',
        render: (text, record, index) => (
          <span>
          {canManage ?
          <span className="gx-link" onClick={(e) => {this.edit(record, e)}}><Divider type="vertical"/><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span> : <span></span>}
          {canManage ?
              <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><Divider type="vertical"/><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span> :
              <span></span>
          }
          </span>
        )
      }];
    return(
      <div>
      {this.state.loading ?
      <CircularProgress /> :
      <Auxiliary>
        <Modal
          visible={this.state.deleteVisible}
          title="Delete Opening?"
          onOk={(e) => this.deleteRecord(this.state.record, e)}
          onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
          confirmLoading={this.state.deleteLoading}
          footer={[
            <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
              OK
            </Button>,
            <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
              Cancel
            </Button>
          ]}
        >
          <p>Are you Sure you want to delete this Form Field?</p>
        </Modal>
        <div className="gx-profile-content">
          {this.state.visible ?
            <FieldsModal visible={this.state.visible} title={this.state.title} value={this.state.editData} onOk={this.handleOk} onCancel={this.handleCancel} employees={employeeOptions} loading={this.state.confirmLoading}/>
            : null
          }
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div style={{paddingBottom: "2.5rem"}}>
                { canManage &&
                  <Button style={{float: "right"}} disabled={!canManage} onClick={this.onClick} value="Run">Add Field</Button>
                }
                </div>
                <div style={{ margin: 20 }}>
                <ReactDragListView {...this.dragProps}>
                  <Table className="gx-table-responsive" columns={columns} dataSource={this.state.formFields}/>
                </ReactDragListView>
                </div>
            </Col>
          </Row>
        </div>
      </Auxiliary>}
      </div>
    )
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, currentCompany, currentEmployee}
};

export default connect(mapStateToProps)(ApplicantTrackingFormFields);
