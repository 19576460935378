import React, {Component} from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router';
import {Button, Col, Row, Table, Spin, Modal, Divider, message} from "antd";
import {API_URL_V1} from "../../../constants";
import EmployeesPayroll from "./EmployeesPayroll";
import CircularProgress from "components/CircularProgress/index";
import Auxiliary from "../../../util/Auxiliary";
import axios from "axios";

class Payroll extends Component {
  state= {
    visible: false,
    loading: true,
    employees: [],
    payruns: [],
    employeespayroll: false,
    payrun: '',
    spinning: false,
    deleteVisible: false,
    deleteLoading: false,
    record: '',
    pagination: {current: 1, pageSize: 15, total: 0},
  }

  getPayruns = () => {
    this.setState({...this.state, spinning: true});
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/payroll/payruns`, {
      params: {
        page: this.state.pagination.current, per_page: this.state.pagination.pageSize,
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(payrunsData => {
      const pagination = { ...this.state.pagination };
      pagination.pageSize = 15;
      pagination.total =payrunsData.data.total;
      this.setState({...this.state, payruns: payrunsData.data.data, employees: this.props.employees, pagination: pagination, spinning: false, loading: false});
    })
    .catch(error => error)
  }
  componentDidMount() {
    this.getPayruns();
  }

  onClick = () => {
    this.setState({...this.state, employeespayroll: true, payrun: ''});
  }

  onBack = (data) => {
    this.setState({...this.state, employeespayroll: false}, () => {if(data === 'data') {this.getPayruns()}});
  }


  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser, currentCompany} = this.props;
    axios.delete(`${API_URL_V1}/workspaces/${currentCompany.id}/payroll/payruns/${record.id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(payrollData => {
      message.success("Payroll Deleted Successfully");
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      this.getPayruns();
    })
    .catch(error => error)
  }

  handleTableChange = (paginationData) => {
    this.setState({...this.state, pagination: paginationData}, () => {this.getPayruns()});
  }

  render() {
    const {currentEmployee} = this.props;
    let canManage = currentEmployee && currentEmployee.role && (currentEmployee.role.permissions.includes("edit_company") || currentEmployee.role.permissions.includes("manage_company"));
    const columns = [{
        title: 'Pay Date',
        render: (text, record) => (
          <span>
          {record.pay_date}
          </span>
        )
      }, {
        title: 'Pay Period',
        render: (text, record) => (
          <span>
            {record.start} - {record.end}
          </span>
        )
      }, {
        title: 'Gross',
        render: (text, record) => (
          <span>
            {record.gross_pay}
          </span>
        ),
      }, {
        title: 'Net',
        render: (text, record) => (
          <span>
            {record.net_pay}
          </span>
        )
      }, {
        title: 'Employees',
        render: (text, record) => (
          <span>
            {record.entries_count}
          </span>
        )
      }, {
        title: 'Status',
        render: (text, record) => (
          <span>
            {record.status}
          </span>
        )
      }, {
        title: 'Actions',
        render: (text, record) => (
          <span>
            <span className="gx-link" onClick={(e) => {this.setState({...this.state, employeespayroll: true, payrun: record})}}><i className={`icon icon-check-circle-o ${record.status === 'complete' ?  "gx-text-green" : ''}`} title={record.status === 'complete' ? "completed" : "Proceed to Approve"} style={{verticalAlign: "middle"}}/></span>
            {record.status === 'draft' ?
            <span><Divider type="vertical"/>
            <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span>
            </span>
            : '' }
          </span>
        )
      }];
    return (
      <div>
      {this.state.loading ?
      <CircularProgress /> :
      <Auxiliary>
        <Modal
          visible={this.state.deleteVisible}
          title="Delete Payroll?"
          onOk={(e) => this.deleteRecord(this.state.record, e)}
          onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
          confirmLoading={this.state.deleteLoading}
          footer={[
            <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
              OK
            </Button>,
            <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
              Cancel
            </Button>
          ]}
        >
          <p>Are you Sure you want to delete this Payroll?</p>
        </Modal>
        <div className="gx-profile-content">
          {!this.state.employeespayroll ?
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div style={{paddingBottom: "2.5rem"}}>
                { canManage &&
                  <Button style={{float: "right"}} disabled={!canManage} onClick={this.onClick} value="Run">Run Payroll</Button>
                }
                </div>
                <Spin spinning={this.state.spinning}>
                  <Table className="gx-table-responsive" columns={columns} dataSource={this.state.payruns} pagination={this.state.pagination} onChange={this.handleTableChange}/>
                </Spin>
            </Col>
          </Row> :
            <EmployeesPayroll onBack={this.onBack} employees={this.props.employees} currentCompany={this.props.currentCompany} payrun={this.state.payrun}/>}
        </div>
      </Auxiliary>}
      </div>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, currentCompany, currentEmployee}
};

export default withRouter(connect(mapStateToProps)(Payroll));
