import React, {Component} from "react";
import {Button, Form, Input} from "antd";
import IntlMessages from "util/IntlMessages";
import axios from 'axios';
import {Link} from "react-router-dom";
import {showAuthMessage, hideMessage} from "../appRedux/actions/Auth";
import {connect} from "react-redux";
import {message} from "antd/lib/index";
import {API_URL, API_URL_V1} from "../constants";
import backgroundImage from "../assets/images/signIn.svg";

const FormItem = Form.Item;


class ForgotPassword extends Component {

  state = {
    status: false,
    companyInfo: '',
    loading: true,
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        axios.post(`${API_URL_V1}/auth/password/email`, {
          email: values.email,
        }).then(message => {
          if (message.request.status == '200') {
            this.setState({status: true})
          }
          this.props.showAuthMessage(message.data.status);
        }).catch((e) => {
          this.props.showAuthMessage(e.response.data.email);
        })
      }
    });
  };

  componentDidMount() {
    let urlParts = window.location.host.split('.');
    let subdomain = urlParts[0];
    if(!(subdomain === 'app' || subdomain === 'localhost:3000')) {
      axios.get(`${API_URL}/${subdomain}/v1/public/info`)
      .then(companyData => {
        this.setState({...this.state, companyInfo: companyData.data, loading: false});
      })
      .catch(error => {
        this.setState({...this.state, loading: false});
      })
    } else {
      this.setState({...this.state, loading: false});
    }
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        setTimeout(() => {
          if (this.state.status)
            this.props.history.push('/');
        }, 1000);
        this.props.hideMessage();
      }, 100);
    }
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const {showMessage, alertMessage} = this.props;

    return (
      <div className="gx-app-login-wrap" style={{background: `url(${backgroundImage})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundColor: "#fff"}}>
      <div className="gx-app-login-container">

          <div className="gx-app-logo-content">
          <div className="gx-app-logo-wid">
            <div className="gx-app-logo gx-mb-2">
              {this.state.loading ? '' :
                <img height="30px" alt="logo" src={this.state.companyInfo ? API_URL_V1+this.state.companyInfo.logo : require("assets/images/logo-colored.png")}/>
              }
            </div>
            <h1 style={{color: "#444"}}>Forgot Your Password ?</h1>
            <p><IntlMessages id="app.userAuth.forgot"/></p>
          </div>

        </div>
        <div className="gx-app-login-main-content">
          <div className="gx-app-login-content">
          <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">

            <FormItem>
              {getFieldDecorator('email', {
                rules: [{
                  type: 'email', message: 'The input is not valid E-mail!',
                }, {
                  required: true, message: 'Please input your E-mail!',
                }],
              })(
                <Input type="email" placeholder="E-Mail Address" size="large"/>
              )}
            </FormItem>

            <FormItem>
              <Button type="primary" className="gx-mb-0" htmlType="submit" block size="large">
                <IntlMessages id="app.userAuth.send"/>
              </Button>
              <div style={{textAlign: "center"}}><IntlMessages id="app.userAuth.or"/></div> <div style={{textAlign: "center"}}><Link to="/signin"><IntlMessages
              id="app.userAuth.signIn"/></Link></div>
            </FormItem>
          </Form>
          </div>
          </div>
        </div>
        {showMessage && this.state.status &&
        message.success(alertMessage)}
        {showMessage && !this.state.status &&
        message.error(alertMessage)}
      </div>
    );
  }
}

const WrappedForgotPasswordForm = Form.create()(ForgotPassword);

const mapStateToProps = ({auth}) => {
  const {alertMessage, showMessage} = auth;
  return {alertMessage, showMessage}
};

export default connect(mapStateToProps, {
  showAuthMessage,
  hideMessage,
})(WrappedForgotPasswordForm);
