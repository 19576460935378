import React, {Component} from "react";
import {Modal, Form, Input, Button, Radio, Row, Col, Select} from 'antd';

const { TextArea } = Input;
const { Option } = Select;
const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends Component {
    state={
      value: this.props.value && this.props.value.days && this.props.value.days < 0 ? 2 : 1,
      days: this.props.value && this.props.value.days ? this.props.value.days : 0
    }

    onChange = e => {
      this.setState({
        ...this.state,
        value: e.target.value,
      }, () => {
        this.props.form.setFieldsValue({
          days: this.state.days*(-1),
        });
        this.setState({...this.state, days: (this.state.days*(-1))})
      });
    };

    dayChange = e => {
      this.setState({...this.state, days: e.target.value})
    }

    render() {
      const formItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 6},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 18},
        },
      };
      const inlineItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 12},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 12},
        },
      };
      const { getFieldDecorator } = this.props.form;
      return (
        <Modal
        visible={this.props.visible}
        title={this.props.title}
        onCancel={this.props.onCancel}
        onOk={this.props.onCreate}
        footer={[
          <Button key="submit" loading={this.props.confirmLoading} onClick={this.props.onCreate}>
            {this.props.title}
          </Button>,
          <Button key="cancel" onClick={this.props.onCancel}>
            Cancel
          </Button>
        ]}
        >
          <Form >
            <Row gutter={24}>
              <Col span={12} style={{ display:'block'}}>
                <Form.Item
                {...inlineItemLayout}
                label="Year">
                  {getFieldDecorator('year', {
                    initialValue: this.props.value && this.props.value.year ? this.props.value.year.toString() : this.props.year,
                  })(
                    <Select placeholder="Select Year">
                      {this.props.yearList}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12} style={{ display:'block'}}>
                <Form.Item
                {...inlineItemLayout}
                label="Days">
                  {getFieldDecorator('days', {
                    initialValue: this.state.days,
                    rules: [{ required: true, message: 'Please input the Days number!' }],
                  })(
                    <Input type="number" placeholder="Days" onChange={this.dayChange}/>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
            {...formItemLayout}
            label="Type">
              {getFieldDecorator('type', {
                initialValue: this.state.value,
              })(
                <Radio.Group onChange={this.onChange}>
                  <Radio value={1}>Add Days</Radio>
                  <Radio value={2}>Subtract Days</Radio>
                </Radio.Group>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Description">
              {getFieldDecorator('description', {
                initialValue: this.props.value ? this.props.value.description : '',
              })(
                <TextArea rows={3} placeholder="Leave Description"/>
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

class AdjustLeaveModal extends React.Component {

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onOk(values, this.props.value.id);
    });
  }

  render() {
    return (
      <div>

      <CollectionCreateForm
      title={this.props.title}
      wrappedComponentRef={this.saveFormRef}
      visible={this.props.visible}
      onCancel={this.props.onCancel}
      onCreate={this.handleCreate}
      value={this.props.value}
      yearList={this.props.yearList}
      year={this.props.year}
      confirmLoading={this.props.confirmLoading}
      />

      </div>
    );
  }
}

export default AdjustLeaveModal;
