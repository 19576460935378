import React, {Component} from "react";
import {connect} from "react-redux";
import Form from "react-jsonschema-form";
import {DatePicker, Select, Upload, Button, Icon, message} from "antd";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../../constants";
import axios from 'axios';
import moment from 'moment';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import Auxiliary from "../../../../util/Auxiliary";
import {resetJoyride} from "../../../../appRedux/actions/Company";

const Option = Select.Option;
let formData={};
let workspace_id;
let customFieldsName = [], customFieldsType = [], countriesOptions = [], nationalityOptions = [];
let fieldData = {}, customFieldId, fieldNameArray={};

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {

  const isLt2M = file.size / 1024 / 1024 < 1;
  if (!isLt2M) {
    message.error('Image must smaller than 1MB!');
  }
  return isLt2M;
}

class EmployeePersonelForm extends Component {

  state={
    loading: true,
  };

  custom = (options: any) => {
    console.log(customFieldId);
    const employee_id = this.props.employee.id;
    const {authUser} = this.props;
    const data= new FormData();

    data.append("_method", "POST");
    data.append("value", options.file);
    data.append("custom_field_type_id", customFieldId);
    var config = {
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
      },
      headers: {
      'Authorization': 'Bearer '+authUser
      }
    };
    axios.post(`${API_URL_V1}/employees/${employee_id}/custom_fields`, data,
      config
    )
    .then(userData =>
      {options.onSuccess(userData.data, options.file)
        message.success("File Uploaded");
      }
    )
    .catch(error => error);

  }

  componentDidMount() {
    formData = {
      firstName: this.props.employee.first_name,
      lastName: this.props.employee.last_name,
      personalEmail: this.props.employee.email,
      gender: this.props.employee.gender ? this.props.employee.gender.charAt(0).toUpperCase()+this.props.employee.gender.slice(1):undefined,
      nationality: this.props.employee.nationality ? this.props.employee.nationality : undefined,
      dob: this.props.employee.birthday_on ? this.props.employee.birthday_on : undefined,
      phone: this.props.employee.phone_number ? this.props.employee.phone_number : undefined,
      address1: this.props.employee.address_1 ? this.props.employee.address_1 : undefined,
      address2: this.props.employee.address_2 ? this.props.employee.address_2 : undefined,
      city: this.props.employee.city ? this.props.employee.city : undefined,
      zip: this.props.employee.postal_code ? this.props.employee.postal_code : undefined,
      state: this.props.employee.state ? this.props.employee.state : undefined,
      country: this.props.employee.country ? this.props.employee.country : undefined,
    };
    const {authUser} = this.props;
    const employee_id = this.props.employee.id;
    workspace_id = localStorage.getItem('id');
    countriesOptions = []; nationalityOptions = [];
    if(countriesOptions.length ==0 && nationalityOptions.length ==0) {
      console.log((this.props.countries[0]))
      for(let i = 0; i< (this.props.countries).length; i++) {
        countriesOptions.push(<Option key={this.props.countries[i].id} value={(this.props.countries[i].country_code).toString()}>{this.props.countries[i].name}</Option>);
        nationalityOptions.push(<Option key={this.props.countries[i].id} value={(this.props.countries[i].country_code).toString()}>{this.props.countries[i].name+" ("+this.props.countries[i].citizenship+")"}</Option>);
      }
    }
    const url = `${API_URL_V1}/workspaces/${workspace_id}/custom_field_types`;
    axios.all([
      axios.get(url, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/employees/${employee_id}/custom_fields`, {
        headers: {
          Authorization: 'Bearer '+authUser
        }
      })
    ])
    .then(axios.spread((userData, customFieldsData) => {
      fieldData={};
      fieldNameArray={};
      for(let i=0; i< customFieldsData.data.data.length; i++) {
        fieldData[customFieldsData.data.data[i].custom_field_type_id]= customFieldsData.data.data[i].value
      }
      customFieldsName = []; customFieldsType = [];
      for (let i = 0; i< (userData.data.data).length; i++) {
        const name= userData.data.data[i].name;
        const id = userData.data.data[i].id;
        const type = userData.data.data[i].type;
        fieldNameArray[userData.data.data[i].id] = name.replace(/ +/g, "");
        customFieldsName.push(name.replace(/ +/g, ""));
        customFieldsType.push(userData.data.data[i].type);
        if(userData.data.data[i].category=="employee") {
          this.schema.properties.customFields = {type: "object", title: "Custom Fields"};
          if(type === "file"){
            let downloadName = name+"download";
            formData[name]= fieldData[id];
            customFieldsData.data.data.map(customData => {
              if(customData.custom_field_type_id === id) {
                formData[downloadName] = customData.document.download_url;
              };
            })
            this.schema.properties[name.replace(/ +/g, "")] = {type: "string", format: "file", title: name, id: id};
            this.uiSchema[name.replace(/ +/g, "")] = {"ui:widget": (props) => {
              customFieldId = props.schema.id;
              return (
                <div>
                <Upload onChange={this.handleChange} beforeUpload={beforeUpload} customRequest={this.custom} showUploadList={false}>
                  <Button>
                    <Icon type="upload" /> Click to Upload
                  </Button>
                </Upload>
                <a href={formData[downloadName]}>{formData[name]}</a>
                </div>
              );
            }}
          }
          if(type == "string") {
            this.schema.properties[name.replace(/ +/g, "")] = {type: "string", title: name, id: id};
            this.uiSchema[name.replace(/ +/g, "")] = {"ui:widget": this.InputWidget}
            formData[name.replace(/ +/g, "")] = fieldData[id];
          }
          if(type == "paragraph") {
            this.schema.properties[name.replace(/ +/g, "")] = {type: "string", title: name, id: id};
            this.uiSchema[name.replace(/ +/g, "")] = {"ui:widget": (props) => {
              return (
                <textarea className="ant-input" placeholder={name} value={props.value} onChange={(event) => props.onChange(event.target.value)}/>
              );
            }}
          }
          if(type == "date") {
            this.schema.properties[name.replace(/ +/g, "")] = {type: "string", title: name, id: id};
            this.uiSchema[name.replace(/ +/g, "")] = {"ui:widget": (props) => {
              return (
                <DatePicker className="gx-w-100"  placeholder={name} value={props.value ? (moment(props.value)) : ''} onChange={(date, dateString) => props.onChange(dateString)}/>
              );
            }}
            formData[name.replace(/ +/g, "")] = fieldData[id];
          }
          if(type == "numeric") {
            this.schema.properties[name.replace(/ +/g, "")] = {type: "integer", title: name, id: id};
            this.uiSchema[name.replace(/ +/g, "")] = {"ui:widget": (props) => {
              return (
                <input type="number" className="ant-input" placeholder={name} value={props.value} onChange={(event) => props.onChange(event.target.value)}/>
              );
            }}
            formData[name.replace(/ +/g, "")] = fieldData[id];
          }
        }
      }
      console.log(this.state)
      this.setState({...this.state, loading: false});
    }))
    .catch(error => error);
  }

  handleChange = (info) => {
    console.log(info);
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl => {
        this.setState({
        imageUrl,
        uiSchema: {}
      })
      let downloadName = fieldNameArray[info.file.response.data.custom_field_type_id]+"download";
      formData[fieldNameArray[info.file.response.data.custom_field_type_id]]= info.file.name;
      formData[downloadName]= info.file.response.data.document.download_url;
      this.setState({...this.state, uiSchema: this.uiSchema});
    });
    }
  }

  schema = {
    title: "General Information",
    type: "object",
    required: ["firstName", "lastName", "dob", "gender", "nationality", "phone", "personalEmail"],
    properties: {
      firstName: {type: "string", title: "First Name :"},
      lastName: {type: "string", title: "Last Name :"},
      dob: {type: "string", title: "Date of Birth"},
      gender: {type: "string", title: "Gender", enum: ["Male", "Female", "Others"]},
      nationality: {type: "string", title: "Nationality"},
      phone: {type: "string", title: "Phone"},
      personalEmail: {type: "string", title: "Personal Email"},
      address: {
        type: "object",
        title: "Address",
      },
      address1: {type: "string", title: "Address1"},
      address2: {type: "string", title: "Address2"},
      city: {type: "string", title: "City"},
      state: {type: "string", title: "State"},
      country: {type: "string", title: "Country"},
      zip: {type: "string", title: "Zip"},
    }
  };

  InputWidget = (props) => {
    return (
      <input type="text" className="ant-input" placeholder={props.schema.title} value={props.value} onChange={(event) => props.onChange(event.target.value)}/>
    );
  };

  Widgets = {
    inputWidget: this.InputWidget
  };

  uiSchema = {
    firstName: {"ui:widget": this.InputWidget},
    lastName: {"ui:widget": this.InputWidget},
    dob: {"ui:widget": (props) => {
      return (
        <DatePicker className="gx-w-100"  placeholder="Select Birthday" disabledDate={(current) => {return current > moment().endOf('day')}} value={props.value ? (moment(props.value)) : ''} onChange={(date, dateString) => props.onChange(dateString)}/>
      );
    }},
    gender: {"ui:widget": (props) => {
      return (
        <Select className="ant-select" placeholder="Select gender" value={props.value ? props.value.charAt(0).toUpperCase()+props.value.slice(1) : undefined} onChange={(value) => props.onChange(value)}>
          <Option value="Male">Male</Option>
          <Option value="Female">Female</Option>
          <Option value="Other">Other</Option>
        </Select>
      );
    }},
    nationality: {"ui:widget": (props) => {
      return (
        <Select className="ant-select" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} showSearch placeholder="Select Nationality" value={props.value} onChange={(value) => props.onChange(value)} optionFilterProp="children">
          {nationalityOptions}
        </Select>
      )
    }},
    phone: {"ui:widget": this.InputWidget},
    personalEmail: {"ui:widget": this.InputWidget},
    address1: {"ui:widget": this.InputWidget},
    address2: {"ui:widget": this.InputWidget},
    city: {"ui:widget": this.InputWidget},
    zip: {"ui:widget": this.InputWidget},
    state: {"ui:widget": this.InputWidget},
    country: {"ui:widget": (props) => {
      return (
        <Select className="ant-select" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} showSearch placeholder="Select Country" value={props.value} onChange={(value) => props.onChange(value)} optionFilterProp="children">
          {countriesOptions}
        </Select>
      )
    }},
  };

  CustomFieldTemplate = (props) => {
    if(props.schema.title == "City" || props.schema.title == "Country" || props.schema.title == "End-Date" || props.schema.title == "Date of Birth"){
      return props.displayLabel
        ? <div className="ant-row ant-form-item" style={{width: "67%", float: "left"}}>
            {props.required ?
            <label htmlFor={props.id} className="ant-form-item-label ant-form-item-required ant-col-xs-24 ant-col-sm-12">
              {props.label}
            </label> :
            <label htmlFor={props.id} className="ant-form-item-label ant-col-xs-24 ant-col-sm-12">
              {props.label}
            </label>}
            <div className="ant-form-item-control-wrapper ant-col-xs-24 ant-col-sm-12">
              {props.description}
              {props.children}
              {props.errors}
              {props.help}
            </div>
          </div>
        : <div className={props.classNames}>
            {props.children}
          </div>;
    } else if(props.schema.title == "Zip" || props.schema.title == "State" || props.schema.title == "Gender") {
      return props.displayLabel
        ? <div className="ant-row ant-form-item ant-second-row" style={{width: "35%"}}>
            {props.required ?
            <label htmlFor={props.id} className="ant-form-item-label ant-form-item-required ant-col-xs-24 ant-col-sm-12">
              {props.label}
            </label> :
            <label htmlFor={props.id} className="ant-form-item-label ant-col-xs-24 ant-col-sm-12">
              {props.label}
            </label>}
            <div className="ant-form-item-control-wrapper ant-col-xs-24 ant-col-sm-12">
              {props.description}
              {props.children}
              {props.errors}
              {props.help}
            </div>
          </div>
        : <div className={props.classNames}>
            {props.children}
          </div>;
    } else {
        return props.displayLabel
          ? <div className="ant-row ant-form-item" style={{width: "100%"}}>
              {props.required ?
              <label htmlFor={props.id} className="ant-form-item-label ant-form-item-required ant-col-xs-24 ant-col-sm-8">
                {props.label}
              </label> :
              <label htmlFor={props.id} className="ant-form-item-label ant-col-xs-24 ant-col-sm-8">
                {props.label}
              </label>}
              <div className="ant-form-item-control-wrapper ant-col-xs-24 ant-col-sm-16">
                {props.description}
                {props.children}
                {props.errors}
                {props.help}
              </div>
            </div>
          : <div className={props.classNames}>
              {props.children}
            </div>;
        }
  };

  onSubmit = (values) => {
    workspace_id = localStorage.getItem('id');
    const employee_id = this.props.employee.id;
    const {authUser} = this.props;
    axios.put(`${API_URL_V1}/workspaces/${workspace_id}/employees/${this.props.employee.id}`, {
        id: this.props.employee.id,
        role_id: (this.props.employee.role_id).toString(),
        first_name: values.formData.firstName,
        last_name: values.formData.lastName,
        phone_number: values.formData.phone,
        address_1: values.formData.address1,
        address_2: values.formData.address2,
        city: values.formData.city,
        state: values.formData.state,
        postal_code: values.formData.zip,
        country: values.formData.country,
        birthday_on: values.formData.dob,
        gender: values.formData.gender ? values.formData.gender.toLowerCase() : undefined,
        nationality: values.formData.nationality,
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
    .then(employeeData => {
      message.success("Information Updated");
    })
    .catch(error => error)
    for(let i = 0; i< customFieldsName.length; i++) {
      if(values.formData[customFieldsName[i]] && (customFieldsType[i] == "string" || customFieldsType[i] == "paragraph" || customFieldsType[i] == "date" || customFieldsType[i] == "numeric")) {
        const customFields_url = `${API_URL_V1}/employees/${employee_id}/custom_fields`;
        const custom_field_id = this.schema.properties[customFieldsName[i]].id;
        axios.post(customFields_url, {
          custom_field_type_id: custom_field_id,
          value: values.formData[customFieldsName[i]],
          },
          {
            headers: {
            'Authorization': 'Bearer '+authUser
          }
        })
        .then(userData => {
          console.log(userData);
        })
        .catch(error => error);
      }
    }
  }

  handleJoyrideCallback = data => {
    if(data.action === 'reset') {
      this.props.resetJoyride();
    }
  };

  render() {

    console.log(formData);
    return (
      <div>
      {this.state.loading ?
      <CircularProgress/> :
      <Auxiliary>
      {this.props.steps ?
      <Joyride
        steps={this.props.steps}
        callback={this.handleJoyrideCallback}
        styles={{
          options: {
            zIndex: 10000,
          },
        }}
      /> : null}
      <Form
        schema={this.schema}
        uiSchema={this.uiSchema}
        formData={formData}
        onSubmit={this.onSubmit}
        FieldTemplate={this.CustomFieldTemplate}
        showErrorList={false} >
        <div className="ant-row ant-form-item">
          <div className="ant-col-xs-24 ant-col-sm-8"></div>
          <div className="ant-col-xs-24 ant-col-sm-16">
            <button type="submit" className="ant-btn btn-primary">Save</button>
          </div>
        </div>
      </Form></Auxiliary>}
      </div>
    );
  }
}
const mapStateToProps = ({auth}) => {
  const {authUser} = auth;
  return {authUser}
};

export default connect(mapStateToProps, {resetJoyride})(EmployeePersonelForm);
