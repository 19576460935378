import React, {Component} from "react";
import {Modal, Form, Input, Select, Button} from 'antd';

const Option = Select.Option;

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends Component {

    render() {
      if(this.props.value){
      console.log(this.props.value)
    }
      let employees=[];
      employees.push(<Option value=''>Anyone</Option>)
      this.props.allEmployees.forEach(employee => {
        let name = `${employee.first_name} ${employee.last_name}`;
        employees.push(<Option value={(employee.id).toString()}>{name}</Option>);
      })
      const formItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 8},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 16},
        },
      };

      const { getFieldDecorator } = this.props.form;
      return (
        <Modal
          visible={this.props.visible}
          title={this.props.title}
          onCancel={this.props.onCancel}
          onOk={this.props.onCreate}
          footer={[
            <Button key="submit" loading={this.props.confirmLoading} onClick={this.props.onCreate}>
              {this.props.title}
            </Button>,
            <Button key="cancel" onClick={this.props.onCancel}>
              Cancel
            </Button>
          ]}
        >
          <Form >
            <Form.Item
            {...formItemLayout}
            label="Title">
              {getFieldDecorator('title', {
                initialValue: this.props.value.title,
                rules: [{ required: true, message: 'Please Enter the Title!' }],
              })(
                <Input placeholder="Title"/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Assign To">
              {getFieldDecorator('employee_id', {
                initialValue: this.props.value.employee_id ? (this.props.value.employee_id).toString() : undefined,
              })(
                <Select placeholder="Select Employee" showAction={['click', 'focus']} filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} showSearch optionFilterProp="children">
                  {employees}
                </Select>
              )}
            </Form.Item>
            {this.props.value ?
              <Form.Item
              {...formItemLayout}
              label="Status">
                {getFieldDecorator('status', {
                  initialValue: this.props.value.status ? (this.props.value.status).toString() : undefined,
                })(
                  <Select placeholder="Select Status" showAction={['click', 'focus']}>
                    <Option value="TODO" >ToDo</Option>
                    <Option value="IN PROGRESS" >In Progress</Option>
                    <Option value="DONE" >Done</Option>
                  </Select>
                )}
              </Form.Item> : ''
            }
          </Form>
        </Modal>
      );
    }
  }
);

class AddProjectTaskModal extends React.Component {

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onOk(values, this.props.value.id);
    });
  }

  render() {
    return (
      <div>
        <CollectionCreateForm
          title={this.props.title}
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          onCreate={this.handleCreate}
          value={this.props.value}
          allEmployees={this.props.employees}
          confirmLoading={this.props.confirmLoading}
        />
      </div>
    );
  }
}

export default AddProjectTaskModal;
