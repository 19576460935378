import React, {Component} from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import {Button, Card, Table, Modal, Divider, Select, message, Row, Col, Form, Spin} from "antd";
import Auxiliary from "../../../util/Auxiliary";
import TimeOffModal from "./TimeOffModal";
import AdjustLeaveModal from "./AdjustLeaveModal";
import CircularProgress from "components/CircularProgress/index";
import axios from "axios";
import {API_URL_V1} from "../../../constants";
import TimeoffStats from "./TimeoffStats";
import _ from "lodash";

const Option = Select.Option;
let data = [];
let workspace_id, leaveTypesOptions=[], leaveTypes=[];
let disabled;

class TimeOff extends Component {

  constructor(props){
    super(props)
    this.state = {
      visible: false,
      title: '',
      adjustLeaveTitle: '',
      loading: true,
      adjustLeaveVisible: false,
      loadingStats: true,
      leaveStats: {},
      leavesAdjustmentData: [],
      editData: '',
      editAdjustData: '',
      leaveExpiryMonth: 0,
      leaves: [],
      pagination: {current: 1, pageSize: 15, total: 0},
      confirmLoading: false,
      approveLoading: false,
      rejectLoading: false,
      deleteVisible: false,
      deleteLoading: false,
      deleteAdjustmentVisible: false,
      deleteAdjustmentLoading: false,
      adjustLoading: false,
      record: '',
      adjustmentRecord: '',
      year: '',
    };
  }

  getLeaveStats(params) {
    this.setState(state => {return {...state, loadingStats: true}});

    let employee_id = params;
    const {authUser, currentCompany} = this.props;
    axios.all([
      axios.get(`${API_URL_V1}/employees/${employee_id}/leave-stats`, {
        params: {
          year: this.state.year,
        },
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/employees/${employee_id}/leave-adjustment`, {
        params: {
          year: this.state.year,
        },
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
    ])
    .then(axios.spread((response, leavesAdjustmentData) => {
      this.setState(state => {return {...state, loadingStats: false, leaveStats: response.data.data, leavesAdjustmentData: leavesAdjustmentData.data}});
    })).catch(error => {
      message.error("Failed to get leaves details");
      console.log(error);
    })
  }

  getLeaves(params) {
    data=[];
    let employee_id = params;
    leaveTypesOptions=[];
    const {authUser, currentCompany} = this.props;
    workspace_id = currentCompany.id;
    axios.all([
      axios.get(`${API_URL_V1}/workspaces/${workspace_id}/leaves/types`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/employees/${employee_id}/leaves`, {
        params: {
          include: 'document,type',
          page: this.state.pagination.current, per_page: this.state.pagination.pageSize,
        },
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
    ])
    .then(axios.spread((leaveTypesData, leavesData) => {
      const pagination = { ...this.state.pagination };
      pagination.pageSize = 15;
      pagination.total =leavesData.data.total;
      leaveTypesOptions = [];
      for(let i = 0; i < (leaveTypesData.data.data).length; i++) {
        leaveTypes[leaveTypesData.data.data[i].id]=leaveTypesData.data.data[i].name;
        leaveTypesOptions.push(<Option value={(leaveTypesData.data.data[i].id).toString()}>{leaveTypesData.data.data[i].name}</Option>);
      }
      let leaveName;
      this.setState({...this.state, pagination ,leaves: leavesData.data.data, loading: false});
    }))
  }

  getLeavePolicy = params => {
    let employee_id = this.props.match.params.id ? this.props.match.params.id : this.props.currentEmployee.id;
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/leaves/policies/${params}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(policyData => {
      let thisYear = ((new Date().getMonth())+1) <= policyData.data.data.expire_in_months && policyData.data.data.expire_in_months !== 12 ? (new Date()).getFullYear()-1 : (new Date()).getFullYear();
      this.setState({...this.state, leaveExpiryMonth: policyData.data.data.expire_in_months, year: thisYear}, () => {
        this.getLeaves(employee_id);
        this.getLeaveStats(employee_id);
      });
    })
    .catch(error => error)
  }

  // difference = (object, base) => {
  // 	function changes(object, base) {
  // 		return _.transform(object, function(result, value, key) {
  // 			if (!_.isEqual(value, base[key])) {
  // 				result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
  // 			}
  // 		});
  // 	}
  // 	return changes(object, base);
  // }
  //
  // componentWillReceiveProps(nextProps) {
  //   const change = this.difference(nextProps, this.props);
  //   if(Object.keys(change).length !== 0 && this.props.currentEmployee.contract) {
  //     this.setState({...this.state, loading: true});
  //     let employee_id = nextProps.match.params.id ? nextProps.match.params.id : nextProps.currentEmployee.id;
  //     let leavePolicyId = this.props.location && this.props.location.state ? this.props.location.state.leavePolicyId : this.props.currentEmployee.time_off_policy_id;
  //     this.getLeavePolicy(leavePolicyId);
  //     this.getLeaves(employee_id);
  //     this.getLeaveStats(employee_id);
  //   }
  // }

  componentDidMount () {
    if(this.props.currentEmployee) {
       let employee_id = this.props.match.params.id ? this.props.match.params.id : this.props.currentEmployee.id;
       let leavePolicyId = this.props.location && this.props.location.state ? this.props.location.state.leavePolicyId : this.props.currentEmployee.time_off_policy_id;
       this.getLeavePolicy(leavePolicyId);
      //  this.getLeaves(employee_id);
      // this.getLeaveStats(employee_id);
    } else {
      this.props.history.push('/');
    }
  }

  requestLeave = () => {
    if(disabled) {
      this.setState({...this.state, visible: true, title: "Add Leave", editData: ''});
    } else {
      this.setState({...this.state, visible: true, title: "Request Leave", editData: ''});
    }
  }

  handleCancel = () => {
    this.setState({...this.state, visible: false});
  }

  edit = (record, e) => {
    e.preventDefault();
      let edit = Object.assign({}, record, this.state.leaveStats);
      console.log(edit);
      this.setState({...this.state, visible: true, title: "Edit Leave", editData: edit});
  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser} = this.props;
    const employee_id = this.props.match.params.id ? this.props.match.params.id : this.props.currentEmployee.id;
    axios.delete(`${API_URL_V1}/employees/${employee_id}/leaves/${record.id}`, {
      params: {
        employee_id: employee_id,
        leave_id: record.id,
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(leavesData => {
      message.success("Leave Deleted Successfully");
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      this.getLeaves(employee_id);
      this.getLeaveStats(employee_id);
    })
    .catch(error => {
      message.error("Failed to Delete");
    })
  }

  handleOk = (values, id, buttonVal) => {
    this.setState({...this.state, confirmLoading: true});
    const {authUser} = this.props;
    const formData = new FormData();
    formData.append('start_on', values.date ? (values.date).format('YYYY-MM-DD') : (values.range[0]).format('YYYY-MM-DD'));
    formData.append('finish_on', values.date ? (values.date).format('YYYY-MM-DD') : (values.range[1]).format('YYYY-MM-DD'));
    formData.append('description', values.description);
    formData.append('leave_type_id', values.leave_type);
    formData.append('half_day', (values.duration == 'first_half' || values.duration == 'second_half') ? values.duration : '');
    if(values.document && values.document.fileList){
        formData.append('document', values.document.fileList[0].originFileObj);
    }
    const employee_id = this.props.match.params.id ? this.props.match.params.id : this.props.currentEmployee.id;
    workspace_id = localStorage.getItem('id');
    if(this.state.title === "Request Leave" || this.state.title === "Add Leave") {
      axios.post(`${API_URL_V1}/employees/${employee_id}/leaves`, formData,
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(leavesData => {
        let leaves = this.state.leaves;
        leaves.push(leavesData.data.data);
        this.getLeaveStats(employee_id);
        message.success("New Leave Requested");
        this.setState({...this.state, leaves: leaves, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Something went wrong");
        this.setState({...this.state, confirmLoading: false});
      })
    } else {
      let url, params, method;
      if(buttonVal === "Approve Leave"){
        this.setState({...this.state, approveLoading: true});
      }
      if (buttonVal === "Reject Leave") {
        this.setState({...this.state, rejectLoading: true});
      }
      if(buttonVal) {
        url = `${API_URL_V1}/employees/${employee_id}/leaves/${id}/approve`;
        method= 'post';
        if (buttonVal === "Approve Leave") {
          params = {
            approved: true,
            rejected: false,
          };
        } else {
          params = {
            approved: false,
            rejected: true,
          }
        }
      } else {
        formData.append('_method', 'PUT');
        method= 'post';
        url = `${API_URL_V1}/employees/${employee_id}/leaves/${id}`;
        params={
          start_on: values.date ? (values.date).format('YYYY-MM-DD') : (values.range[0]).format('YYYY-MM-DD'),
          finish_on: values.date ? (values.date).format('YYYY-MM-DD') : (values.range[1]).format('YYYY-MM-DD'),
          description: values.description,
          leave_type_id: values.leave_type,
          half_day: (values.duration == 'first_half' || values.duration == 'second_half') ? values.duration : null,
        }
      }
      axios({method: method, url: url, data: buttonVal ? params : formData,
        headers: {
        'Authorization': 'Bearer '+authUser
        }
      })
      .then(leavesData => {
        this.getLeaveStats(employee_id);
        let leaves = this.state.leaves;
        leaves.forEach((leave, index) => {
          if(leave.id == leavesData.data.data.id){
            leaves[index] = {...leave, ...leavesData.data.data};
          }
        })
        if(buttonVal === "Approve Leave"){
          message.success("Leave Approved");
        } else if (buttonVal === "Reject Leave") {
          message.success("Leave Rejected");
        } else {
          message.success("Leave Editted Successfully");
        }
        this.setState({...this.state, leaves: leaves, visible: !this.state.visible, approveLoading: false, rejectLoading: false, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Edit");
        this.setState({...this.state, confirmLoading: false});
      })
    }
  }

  onAdjustLeave = () => {
    this.setState({...this.state, adjustLeaveTitle: "Add timeoff Adjustment", editAdjustData: '', adjustLeaveVisible: true})
  }

  handleTimeoffAdjustment = (values, id) => {
    this.setState({...this.state, adjustLoading: true});
    const {authUser} = this.props;
    const employee_id = this.props.match.params.id ? this.props.match.params.id : this.props.currentEmployee.id;
    if(!id) {
      axios.post(`${API_URL_V1}/employees/${employee_id}/leave-adjustment`, {
        year: values.year,
        days: values.days,
        description: values.description,
      }, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(leavesAdjustmentData => {
        this.getLeaveStats(employee_id);
        this.setState({...this.state, adjustLoading: false, adjustLeaveVisible: false})
      })
      .catch(error => {
        this.setState({...this.state, adjustLoading: false})
        message.error('Something went wrong');
      })
    } else {
      axios.put(`${API_URL_V1}/employees/${employee_id}/leave-adjustment/${id}`, {
        year: values.year,
        days: values.days,
        description: values.description,
      }, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(leavesAdjustmentData => {
        this.getLeaveStats(employee_id);
        this.setState({...this.state, adjustLoading: false, adjustLeaveVisible: false})
      })
      .catch(error => {
        this.setState({...this.state, adjustLoading: false})
        message.error('Something went wrong');
      })
    }
  }

  editAdjustment = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, adjustLeaveVisible: true, adjustLeaveTitle: "Edit timeoff Adjustment", editAdjustData: record});
  }

  deleteAdjustment = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteAdjustmentLoading: true});
    const {authUser} = this.props;
    const employee_id = this.props.match.params.id ? this.props.match.params.id : this.props.currentEmployee.id;
    axios.delete(`${API_URL_V1}/employees/${employee_id}/leave-adjustment/${record.id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(leavesData => {
      message.success("Adjustment Deleted Successfully");
      this.setState({...this.state, deleteAdjustmentVisible: false, deleteAdjustmentLoading: false},() => {
        this.getLeaveStats(employee_id);
      })
    })
    .catch(error => {
      message.error("Failed to Delete");
    })
  }

  handleTableChange = (paginationData) => {
    let employee_id = this.props.match.params.id ? this.props.match.params.id : this.props.currentEmployee.id;
    this.setState({...this.state, pagination: paginationData}, () => {this.getLeaves(employee_id)});
  }

  onYearChange = value => {
    let employee_id = this.props.match.params.id ? this.props.match.params.id : this.props.currentEmployee.id;
    this.setState({...this.state, year: value}, () => {
      this.getLeaveStats(employee_id);
    });
  }

  render() {
    if(this.props.currentEmployee && this.props.currentEmployee.role && this.props.currentEmployee.role.permissions) {
      disabled = (this.props.currentEmployee.role.permissions.includes("edit_company")  || this.props.currentEmployee.role.permissions.includes("manage_company"));
    }
    const columns = [{
        title: 'Name',
        width: "40%",
        render: (text, record) => (
          <span>
            {record.type.name}
            <br/>
            {record.start_on == record.finish_on ?
                <span>On {record.start_on}</span> :
                <span>From: {record.start_on} To: {record.finish_on}</span>
            }

          </span>
        )
      }, {
        title: 'Status',
        render: (text, record) => (
          <span className={`gx-badge gx-hover gx-mb-0`} style={{backgroundColor: (record.approved) ? "#00875a" : (record.rejected) ? "#f5b3b6" : "#dfe1e6", color: (record.approved) ? "#ffffff" : (record.rejected) ? "#f5352d" : "#425275", fontSize: "12px" }}>
            {record.approved ? "Approved" : record.rejected ? "Rejected" : "Pending"}
          </span>
        ),
      }, {
        title: 'Manage',
        key: 'x',
        width: "15%",
        render: (text, record) => (
          <span>
            <span className="gx-link" onClick={(e) => {this.edit(record, e)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span>
            <Divider type="vertical"/>
              <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span>
            {record.document ?
            <span>
            <Divider type="vertical"/>
            <span className="gx-link" ><a target={"_blank"} href={record.document.download_url}>Download</a></span></span> : ''}
          </span>
        )
      }];
      const leavesAdjustmentColumn = [{
          title: 'Days',
          width: "40%",
          render: (text, record) => (
            <span>
              {record.days}
            </span>
          )
        }, {
          title: 'Description',
          render: (text, record) => (
            <span>
            {record.description}
            </span>
          ),
        }, {
          title: 'Manage',
          key: 'x',
          width: "15%",
          render: (text, record) => (
            <span>
            {disabled ?
              <span>
              <span className="gx-link" onClick={(e) => {this.editAdjustment(record, e)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span>
              <Divider type="vertical"/>
              <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteAdjustmentVisible: true, adjustmentRecord: record})}}><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span>
              </span>
            : null}
            </span>
          )
        }];
    let leaveStats = this.state.leaveStats;
    let minOffset = 0, maxOffset = 5;
    let thisYear = ((new Date().getMonth())+1) <= this.state.leaveExpiryMonth && this.state.leaveExpiryMonth !== 12 ? (new Date()).getFullYear()-1 : (new Date()).getFullYear();
    let allYears = [];
    for(let x = 0; x < maxOffset; x++) {
      allYears.push(thisYear + x)
    }
    let yearList = allYears.map((x) => {return(<Option value={x}>{x}</Option>)});

    return (
      <div>
      <Modal
        visible={this.state.deleteVisible}
        title="Delete Leave?"
        onOk={(e) => this.deleteRecord(this.state.record, e)}
        onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
        confirmLoading={this.state.deleteLoading}
        footer={[
          <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
            OK
          </Button>,
          <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
            Cancel
          </Button>
        ]}
      >
        <p>Are you Sure you want to delete this Leave?</p>
      </Modal>
      {this.state.adjustLeaveVisible ?
        <AdjustLeaveModal
          visible={this.state.adjustLeaveVisible}
          title={this.state.adjustLeaveTitle}
          value={this.state.editAdjustData}
          onOk={this.handleTimeoffAdjustment}
          onCancel={() => {this.setState({...this.state, adjustLeaveVisible: false})}}
          confirmLoading={this.state.adjustLoading}
          yearList={yearList}
          year={this.state.year}
        /> : null}
        {this.state.deleteAdjustmentVisible ?
          <Modal
            visible={this.state.deleteAdjustmentVisible}
            title="Delete Leave Adjustment?"
            onOk={(e) => this.deleteAdjustment(this.state.adjustmentRecord, e)}
            onCancel={(e) => {this.setState({...this.state, deleteAdjustmentVisible: false})}}
            confirmLoading={this.state.deleteAdjustmentLoading}
            footer={[
              <Button key="submit" loading={this.state.deleteAdjustmentLoading} onClick={(e) => this.deleteAdjustment(this.state.adjustmentRecord, e)}>
                OK
              </Button>,
              <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteAdjustmentVisible: false})}}>
                Cancel
              </Button>
            ]}
          >
            <p>Are you Sure you want to delete this Leave Adjustment?</p>
          </Modal>
          :null}
      {this.state.loading ?
      <CircularProgress/> :
      <div>
      {this.state.visible ?
        <TimeOffModal
          token={this.props.authUser}
          currentEmployeeId={this.props.match.params.id ? this.props.match.params.id : this.props.currentEmployee.id}
          title={this.state.title}
          leaveStats={this.state.leaveStats}
          leaveTypes={leaveTypesOptions}
          value={this.state.editData}
          visible={this.state.visible}
          onOk={this.handleOk}
          onCancel={this.handleCancel}
          permissions={this.props.currentEmployee.role.permissions}
          confirmLoading={this.state.confirmLoading}
          approveLoading={this.state.approveLoading}
          rejectLoading={this.state.rejectLoading}
        /> : null }
      <Auxiliary>
      <Row>
        <Col xl={24} lg={24} md={24} sm={24} xs={24}>
          {!disabled ?
          <div style={{paddingBottom: "1rem"}}>
          <Select placeholder="Select Year" value={this.state.year} onChange={this.onYearChange}>
            {yearList}
          </Select>
            <Button style={{float: "right"}} onClick={this.requestLeave} value="Add">Request Leave</Button>
          </div>:
          <div style={{paddingBottom: "1rem"}}>
          <Select placeholder="Select Year" value={this.state.year} onChange={this.onYearChange}>
            {yearList}
          </Select>
            <span style={{float: 'right'}}>

              <Button style={{marginLeft: "15px"}} onClick={this.requestLeave} value="Add">Add Leave</Button>
              <Button onClick={this.onAdjustLeave} value="Adjust">Adjust Leaves</Button>
            </span>
          </div>
          }
        </Col>
      </Row>

        <TimeoffStats loading={this.state.loadingStats} stats={this.state.leaveStats}/>

        <Table className="gx-table-responsive" columns={columns} dataSource={this.state.leaves} pagination={this.state.pagination} onChange={this.handleTableChange}/>
        <div className="gx-mb-2 gx-font-weight-medium">Leaves Adjustments</div>
        <Table className="gx-table-responsive" columns={leavesAdjustmentColumn} dataSource={this.state.leavesAdjustmentData} pagination={false}/>
      </Auxiliary>
      </div>}
      </div>
    )
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentEmployee, currentCompany} = company;
  return {authUser, currentEmployee, currentCompany}
};

export default withRouter(connect(mapStateToProps)(TimeOff));
