import React, {Component} from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router';
import {Button, Col, Row, Table, Spin, Modal, Divider, message} from "antd";
import {API_URL_V1} from "../../../constants";
import ApplicantTrackingForm from "./ApplicantTrackingForm";
import CircularProgress from "components/CircularProgress/index";
import Auxiliary from "../../../util/Auxiliary";
import axios from "axios";
import moment from "moment";
import $ from "jquery";

class ApplicantTracking extends Component {
  state= {
    visible: false,
    loading: true,
    openings: [],
    country: '',
    confirmLoading: false,
    deleteVisible: false,
    deleteLoading: false,
    pagination: {current: 1, pageSize: 15, total: 0},
  }

  getOpenings = () => {
    this.setState({...this.state, spinning: true});
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/ats/openings`, {
      params: {
        page: this.state.pagination.current, per_page: this.state.pagination.pageSize,
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(openingsData => {
      const pagination = { ...this.state.pagination };
      pagination.pageSize = 15;
      pagination.total = openingsData.data.total;
      this.setState({...this.state, openings: openingsData.data.data, pagination: pagination, loading: false});
    })
    .catch(error => error)
  }

  getCountries = () => {
    axios.get(`${API_URL_V1}/data/countries`)
    .then(countriesData => {
      let country = countriesData.data.data.filter((country, index) => this.props.currentCompany.address.country === country.country_code);
      this.setState({...this.state, country: country[0].name});
    })
    .catch(error => error)
  }

  componentDidMount() {
    this.getOpenings();
    this.getCountries();
  }

  onClick = () => {
    this.props.history.push({pathname: `/ats/add`, state: {value: '', country: this.state.country}});
  }

  edit = (record, e) => {
    this.props.history.push({pathname: `/ats/${record.id}/detail`, state: {value: record}});
  }

  showApplicants = (record, e) => {
    this.props.history.push({pathname: `/ats/${record.id}/detail`, state: {value: record, showApplicants: true}});
  }

  handleOk = (values, content, id) => {
    const {authUser, currentCompany} = this.props;
    this.setState({...this.state, confirmLoading: true});
    if(!id) {
      axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/ats/openings`, {
          title: values.title,
          description: content,
          position_type: values.position_type.toString(),
          is_remote_allowed: values.is_remote_allowed,
          stages: values.stages,
          location: {
            city: values.city,
            country: values.country,
          }
        },
        {
          headers: {
            'Authorization': 'Bearer '+authUser
        }
      })
      .then(openingsData => {
        let openings = this.state.openings;
        openings.push(openingsData.data);
        message.success("New Opening Added");
        this.setState({...this.state, openings: openings, editData: openingsData.data, title: "Edit Opening", confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Add Opening");
        this.setState({...this.state, confirmLoading: false});
      })
    } else {
      axios.put(`${API_URL_V1}/workspaces/${currentCompany.id}/ats/openings/${id}`, {
        title: values.title,
        description: content,
        position_type: values.position_type.toString(),
        is_remote_allowed: values.is_remote_allowed,
        stages: values.stages,
        location: {
          city: values.city,
          country: values.country,
          }
        },
        {
          headers: {
          'Authorization': 'Bearer '+authUser
          }
        }
      )
      .then(openingsData => {
        let openings = this.state.openings;
        openings.forEach((opening, index) => {
          if(opening.id == openingsData.data.id){
            openings[index] = {...opening, ...openingsData.data};
          }
        })
        message.success("Opening Editted Successfully");
        this.setState({...this.state, openings: openings, confirmLoading: false},() => {this.edit(openingsData.data)});
      })
      .catch(error => {
        message.error("Failed to Edit");
        this.setState({...this.state, confirmLoading: false});
      })
    }
  }

  handleCancel = () => {
    this.setState({...this.state, visible: false});
  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser, currentCompany} = this.props;
    axios.delete(`${API_URL_V1}/workspaces/${currentCompany.id}/ats/openings/${record.id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(openingsData => {
      message.success("Opening Deleted Successfully");
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      this.getOpenings();
    })
    .catch(error => error)
  }

  copy = (record, e) => {
    e.preventDefault();
    let url = window.location.host+"/openings/"+record.company_id+"/job/"+record.id;
    let dummy = $('<input>').val(url).appendTo('body').select()
    document.execCommand("copy");
    message.success("Link copied to clipboard");
  }

  handleTableChange = (paginationData) => {
    this.setState({...this.state, pagination: paginationData}, () => {this.getOpenings()});
  }

  render() {
    const {currentEmployee} = this.props;
    let canManage = currentEmployee && currentEmployee.role && (currentEmployee.role.permissions.includes("edit_company") || currentEmployee.role.permissions.includes("manage_company"));
    const columns = [{
        title: 'Job Title',
        render: (text, record) => (
          <span className="gx-link" onClick={(e) => {this.showApplicants(record, e)}}>
          {record.title}
          </span>
        )
      }, {
        title: '#Applications',
        render: (text, record) => (
          <span>
            {record.candidates_count}
          </span>
        )
      }, {
        title: 'Created',
        render: (text, record) => (
          <span>
            {moment.utc(record.created_at).local().format("YYYY-MM-DD")}
          </span>
        )
      }, {
        title: 'Type',
        render: (text, record) => (
          <span>
            {record.position_type}
          </span>
        ),
      }, {
        title: 'Location',
        render: (text, record) => (
          <span>
            {record.location.city}{record.location.country ? ", "+record.location.country : ""}
          </span>
        ),
      }, {
        title: 'Actions',
        render: (text, record) => (
          <span>
          <span className="gx-link" onClick={(e) => {this.copy(record, e)}}><i className="icon icon-forward" title="Share" style={{verticalAlign: "middle"}}/></span>
          {canManage ?
          <span className="gx-link" onClick={(e) => {this.edit(record, e)}}><Divider type="vertical"/><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span> : <span></span>}
          {canManage ?
              <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><Divider type="vertical"/><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span> :
              <span></span>
          }
          {canManage ?
              <span className="gx-link" onClick={(e) => {this.showApplicants(record, e)}}><Divider type="vertical"/><i className="fa fa-users" title="Applicants" style={{verticalAlign: "middle"}}/></span> :
              <span></span>
          }
          </span>
        )
      }];
    return (
      <div>
      {this.state.loading ?
      <CircularProgress /> :
      <Auxiliary>
        <Modal
          visible={this.state.deleteVisible}
          title="Delete Opening?"
          onOk={(e) => this.deleteRecord(this.state.record, e)}
          onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
          confirmLoading={this.state.deleteLoading}
          footer={[
            <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
              OK
            </Button>,
            <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
              Cancel
            </Button>
          ]}
        >
          <p>Are you Sure you want to delete this Job opening?</p>
        </Modal>
        <div className="gx-profile-content">
          {this.state.visible ?
            <ApplicantTrackingForm value={this.state.editData} onCancel={this.handleCancel} onOk={this.handleOk} loading={this.state.confirmLoading}/>
            :

          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div style={{paddingBottom: "2.5rem"}}>
                { canManage &&
                  <Button style={{float: "right"}} disabled={!canManage} onClick={this.onClick} value="Run">Add Opening</Button>
                }
                </div>
                <Table className="gx-table-responsive" columns={columns} dataSource={this.state.openings} pagination={this.state.pagination} onChange={this.handleTableChange}/>
            </Col>
          </Row>}
        </div>
      </Auxiliary>}
      </div>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, currentCompany, currentEmployee}
};

export default withRouter(connect(mapStateToProps)(ApplicantTracking));
