import React, {Component} from "react";
import {Modal, Form, Input, Select, DatePicker, Button, Upload, Icon} from 'antd';
import moment from 'moment';

const {RangePicker} = DatePicker;
const {TextArea} = Input;
const Option = Select.Option;
let buttonVal;
const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends Component {
    state={
      fileList: []
    }

    render() {
      const { fileList } = this.state;
      buttonVal="";
      let disabled = (this.props.permissions.includes("edit_company") || this.props.permissions.includes("manage_company"));
      const formItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 8},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 16},
        },
      };

      const { getFieldDecorator } = this.props.form;
      const props = {
                onRemove: (file) => {
                    this.setState((state) => {
                        return {
                            fileList: [],
                        };
                    });
                },
                beforeUpload: (file) => {
                    this.setState(state => ({
                        fileList: [file],
                    }));
                    return false;
                },
                fileList,
            };
      return (
        <Modal
          visible={this.props.visible}
          title={this.props.title}
          okText={this.props.title}
          onCancel={this.props.onCancel}
          footer={!(disabled && this.props.title !== "Add Expense") ? [
            <Button key="submit" loading={this.props.confirmLoading} onClick={this.props.onCreate}>
              {this.props.title}
            </Button>,
            <Button key="back" onClick={this.props.onCancel}>Cancel</Button>,
            ] :
            [
            <Button key="submit" loading={this.props.approveLoading} onClick={() => {buttonVal="Approve Expense"; this.props.onCreate()}}>
              Approve Expense
            </Button>,
            <Button key="submit" loading={this.props.rejectLoading} onClick={() => {buttonVal="Reject Expense"; this.props.onCreate()}}>
              Reject Expense
            </Button>,
            <Button key="back" onClick={this.props.onCancel}>Cancel</Button>,
            ]
          }
        >
          <Form >
            <Form.Item
            {...formItemLayout}
            label="Expense Name"
            >
              {getFieldDecorator('expense_name', {
                rules: [{ required: true, message: 'Enter the expense name!' }],
                initialValue: this.props.value.name,
              })(
                <Input placeholder="Expense Name"/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Expense Type"
            >
              {getFieldDecorator('type', {
                rules: [{ required: true, message: 'Please Select the expense type'}],
                initialValue: this.props.value.category,
              })(
                <Select placeholder= "Expense Type" showAction={['click', 'focus']}>
                  <Option value="travelling">Travelling</Option>
                  <Option value="meals">Meals</Option>
                  <Option value="stuff">Stuff</Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Description"
            >
              {getFieldDecorator('description', {
                initialValue: this.props.value.description,
                rules: [{ required: true, message: 'Please enter the description!' }],
              })(
                <TextArea placeholder= "Description"/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Cost"
            >
              {getFieldDecorator('cost', {
                rules: [{ required: true, message: 'Please Enter the Cost in Numeric Value!' }],
                initialValue: this.props.value.amount,
              })(
                <Input type="number" placeholder="Cost"/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Date"
            >
              {getFieldDecorator('date', {
                rules: [{ required: true, message: 'Enter the Date for expense!' }],
                initialValue: this.props.value ? moment(this.props.value.date) : undefined,
              })(
                <DatePicker className="gx-w-100" placeholder="Select Date"/>
              )}
            </Form.Item>
            <Form.Item
                {...formItemLayout}
                label="Expense Invoice">
                {getFieldDecorator('document', {
                })(
                    <Upload {...props}>
                        <Button>
                            <Icon type="upload" /> Add Invoice
                        </Button>
                    </Upload>
                )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

class ExpenseModal extends React.Component {

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onOk(values, this.props.value.id, buttonVal);
    });
  }

  render() {
    return (
      <div>

        <CollectionCreateForm
          title={this.props.title}
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          onCreate={this.handleCreate}
          value={this.props.value}
          leaveTypes={this.props.leaveTypes}
          permissions={this.props.permissions}
          confirmLoading={this.props.confirmLoading}
          approveLoading={this.props.approveLoading}
          rejectLoading={this.props.rejectLoading}
        />

      </div>
    );
  }
}

export default ExpenseModal;
