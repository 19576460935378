import React from "react";
import {Card, Col, Row, Spin} from "antd";
import Auxiliary from "util/Auxiliary";

const ExpenseStats = ({stats, loading}) => {
  return (
    <Auxiliary>
      <Card >
        <Spin spinning={loading}>
          {stats ?
            <Row type="flex" justify="space-around" align="middle">
              <Col className={'text-center'}> <h2>{stats.total ? (Math.round(stats.total) !== stats.total) ? (stats.total).toFixed(2) : stats.total : 0}</h2> <br/>TOTAL EXPENSES</Col>
              <Col className={'text-center'}> <h2>{stats.pending ? (Math.round(stats.pending) !== stats.pending) ? (stats.pending).toFixed(2) : stats.pending : 0}</h2> <br/>PENDING</Col>
              <Col className={'text-center'}> <h2>{stats.approved ? (Math.round(stats.approved) !== stats.approved) ? (stats.approved).toFixed(2) : stats.approved : 0}</h2> <br/>APPROVED</Col>
            </Row>
          : null }
        </Spin>
      </Card>
    </Auxiliary>
  );
};

export default ExpenseStats;
