import {
  SWITCH_COMPANY, SET_DEFAULT_COMPANY, GET_CURRENT_EMPLOYEE_SUCCESS, SET_CURRENT_TIMER,
  GET_CURRENT_TIMER, GET_CURRENT_TIMER_SUCCESS, GET_CURRENT_TIMER_FAILED,
  SET_TIMER_DESCRIPTION_REQUIRED, GET_COMPANY_LINKS, GET_COMPANY_LINKS_SUCCESS, SET_JOYRIDE, RESET_JOYRIDE,
  GET_EMPLOYEE_OPTION, GET_EMPLOYEE_OPTION_SUCCESS, SET_EMPLOYEE_OPTION, SET_EMPLOYEE_OPTION_SUCCESS, SET_ATTENDANCE_TASK
} from "../../constants/ActionTypes";

export const switchCompany = (company) => {
  return {
    type: SWITCH_COMPANY,
    payload: company
  };
};

export const setDefaultCompany = (id) => {
  return {
    type: SET_DEFAULT_COMPANY,
    payload: id
  };
};

export const getCurrentEmployeeComplete = (employee) => {
  return {
    type: GET_CURRENT_EMPLOYEE_SUCCESS,
    payload: employee
  };
};

export const setCurrentTimer = (timer) => {
  return {
    type: SET_CURRENT_TIMER,
    payload: timer
  };
};

export const getCurrentTimer = (employee) => {
  return {
    type: GET_CURRENT_TIMER,
    payload: employee
  }
}

export const getCurrentTimerSuccess = (employee) => {
  return {
    type: GET_CURRENT_TIMER_SUCCESS,
    payload: employee
  };
};

export const getCurrentTimerFailed = () => {
  return {
    type: GET_CURRENT_TIMER_FAILED,
  };
};

export const getCompanyLinks = (companyId) => {
  return {
    type: GET_COMPANY_LINKS,
    payload: companyId
  }
}

export const getCompanyLinksSuccess = (links) => {
  return {
    type: GET_COMPANY_LINKS_SUCCESS,
    payload: links
  };
};

export const setTimerDescriptionRequired = (value) => {
  return {
    type: SET_TIMER_DESCRIPTION_REQUIRED,
    payload: value
  };
};

export const setJoyride = (key) => {
  return {
    type: SET_JOYRIDE,
    payload: key,
  }
}

export const resetJoyride = () => {
  return {
    type: RESET_JOYRIDE,
  }
}

export const getEmployeeOptions = (employeeId) => {
  return {
    type: GET_EMPLOYEE_OPTION,
    payload: employeeId,
  }
}

export const getEmployeeOptionsSuccess = (employeeOptions) => {
  return {
    type: GET_EMPLOYEE_OPTION_SUCCESS,
    payload: employeeOptions,
  }
}

export const setEmployeeOptions = (employeeId) => {
  return {
    type: SET_EMPLOYEE_OPTION,
    payload: employeeId,
  }
}

export const setEmployeeOptionsSuccess = () => {
  return {
    type: SET_EMPLOYEE_OPTION_SUCCESS,
  }
}

export const setAttendanceTask = (value) => {
  return {
    type: SET_ATTENDANCE_TASK,
    payload: value
  };
};
