import {
  HIDE_MESSAGE,
  HIDE_SIGNUP_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SHOW_SIGNUP_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  JOIN_USER_SUCCESS,
  GET_CURRENT_USER_SUCCESS,
  GET_ROLES_SUCCESS,
  GET_USER_WORKSPACE_DATA_SUCCESS
} from "constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  initURL: '',
  authUser: localStorage.getItem('user_id'),
  status: false,
  user: null,
  roles: null,
  userWorkspaceData: null,
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: '/',
        loader: false
      }
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case JOIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }

    case GET_ROLES_SUCCESS: {
      return {
        ...state,
        loader: false,
        roles: action.payload
      }
    }

    case GET_USER_WORKSPACE_DATA_SUCCESS: {
      return {
        ...state,
        loader: false,
        userWorkspaceData: action.payload
      }
    }

    case GET_CURRENT_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        user: action.payload
      }
    }

    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      localStorage.removeItem('user_id');
      return {
        ...state,
        authUser: null,
        initURL: '/',
        loader: false,
        user: null
      }
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }

    case SHOW_SIGNUP_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
        status: true
      }
    }

    case HIDE_SIGNUP_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false,
        status: false
      }
    }

    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    default:
      return state;
  }
}
