import React, {Component} from "react";
import {connect} from "react-redux";
import Form from "react-jsonschema-form";
import {DatePicker, Select, message} from "antd";
import {API_URL_V1} from "../../../constants";
import axios from 'axios';
import CircularProgress from "components/CircularProgress/index";

const Option = Select.Option;
let managerOptions =[],
leavePolicyOptions=[],
officeOptions=[],
rolesOptions=[],
departmentOptions=[];

class ProfileForm extends Component {

  constructor(props){
    super(props)
    this.state = {
      email: '',
      manager: undefined,
      timeOffManager: undefined,
      timeOffPolicy: undefined,
      loading: true,
      role: '',
      uiSchema: {},
      department: undefined,
      canUpdate: this.props.workspaceData.role.permissions.includes("edit_company"),
      oid: '',
      role_id: '',
    };
  }

  componentDidMount() {
    departmentOptions = [];
    rolesOptions=[];
    const {authUser, allEmployee} = this.props;
    const id = this.props.workspaceData.company_id;
    const url = `${API_URL_V1}/workspaces/${id}/me`;
    managerOptions =[]; leavePolicyOptions=[]; officeOptions=[];
    allEmployee.forEach(employee => {
      let name = `${employee.first_name} ${employee.last_name}`;
      managerOptions.push(<Option value={(employee.id).toString()}>{name}</Option>);
    })
    for(let i = 0; i < (this.props.leavePolicy).length; i++) {
      leavePolicyOptions.push(<Option value={(this.props.leavePolicy[i].id).toString()}>{this.props.leavePolicy[i].name}</Option>);
    }
    for(let i = 0; i < (this.props.location).length; i++) {
      officeOptions.push(<Option value={(this.props.location[i].id).toString()}>{this.props.location[i].name}</Option>);
    }
    for(let i = 0; i < (this.props.roles).length; i++) {
      rolesOptions.push(<Option value={(this.props.roles[i].id).toString()}>{this.props.roles[i].name}</Option>);
    }
    if(departmentOptions.length ==0) {
      for(let i = 0; i< (this.props.department).length; i++) {
        departmentOptions.push(<Option value={(this.props.department[i].id).toString()}>{this.props.department[i].name}</Option>);
      }
    }
   this.setState({...this.state, email: this.props.formData.email, manager: this.props.workspaceData.manager_id ? (this.props.workspaceData.manager_id).toString() : undefined,
     timeOffManager: this.props.workspaceData.time_off_manager_id ? (this.props.workspaceData.time_off_manager_id).toString() : undefined,
     timeOffPolicy: this.props.workspaceData.time_off_policy_id ? (this.props.workspaceData.time_off_policy_id).toString() : undefined,
     office: this.props.workspaceData.location_id ? (this.props.workspaceData.location_id).toString() : undefined,
     department: this.props.workspaceData.department_id ? (this.props.workspaceData.department_id.toString()) : undefined,
     oid: this.props.workspaceData.oid ? this.props.workspaceData.oid : '',
     role_id: this.props.workspaceData.role_id ? (this.props.workspaceData.role_id.toString()) : undefined,
     loading: false});
  }

  schema = {
    type: "object",
    required: ["email"],
    properties: {
      manager: {type: "string", title: "Manager"},
      email: {type: "string", title: "E-mail :"},
      timeOffManager: {type: "string", title: "Time-Off Manager"},
      oid: {type: "string", title: "Employee Id"},
      role_id: {type: "string", title: "Role"},
      office: {type: "string", title: "Office"},
      timeOffPolicy: {type: "string", title: "Time Off Policy"},
      department: {type: "string", title: "Department"},
    }
  };

 uiSchema = {
    manager: {"ui:widget": (props) => {
      return (
        <Select className="ant-select" showSearch disabled={!this.state.canUpdate} value={props.value} placeholder="Manager Name" value={props.value} onChange={(value) => props.onChange(value)} optionFilterProp="children">
          {managerOptions}
        </Select>
      );
    }},
    email: {"ui:widget": (props) => {
      return (
        <input type="email" disabled className="ant-input ant-input-disabled" placeholder="E-mail" value={props.value} onChange={(event) => props.onChange(event.target.value)}/>
      );
    }},
    timeOffManager: {"ui:widget": (props) => {
      return (
        <Select className="ant-select" showSearch disabled={!this.state.canUpdate} value={props.value} placeholder="Manager Name" value={props.value} onChange={(value) => props.onChange(value)} optionFilterProp="children">
          {managerOptions}
        </Select>
      );
    }},
    oid: {"ui:widget": (props) => {
      return (
        <input type="text" className={!(this.props.workspaceData.role.permissions.includes("edit_company") || this.props.workspaceData.role.permissions.includes("edit_manager")) ? "ant-input-disabled ant-input" : "ant-input"} placeholder="Employee Id" disabled={!this.state.canUpdate} value={props.value} onChange={(event) => props.onChange(event.target.value)}/>
      );
    }},
    role_id: {"ui:widget": (props) => {
      return (
        <Select className="ant-select" disabled={!this.state.canUpdate} value={props.value} placeholder="Select..." onChange={(value) => props.onChange(value)}>
          {rolesOptions}
        </Select>
      );
    }},
    office: {"ui:widget": (props) => {
      return (
        <Select className="ant-select" disabled={!this.state.canUpdate} value={props.value} placeholder="Select..." onChange={(value) => props.onChange(value)}>
          {officeOptions}
        </Select>
      );
    }},
    timeOffPolicy: {"ui:widget": (props) => {
      return (
        <Select className="ant-select" disabled={!this.state.canUpdate} value={props.value} placeholder="Select Policy" onChange={(value) => props.onChange(value)}>
          {leavePolicyOptions}
        </Select>
      );
    }},
    department: {"ui:widget": (props) => {
      return (
        <Select className="ant-select" showSearch disabled={!this.state.canUpdate} value={props.value} placeholder="Select Department" value={props.value} onChange={(value) => props.onChange(value)} optionFilterProp="children">
          {departmentOptions}
        </Select>
      );
    }},
  };

  CustomFieldTemplate(props) {
    return props.displayLabel
      ? <div className="ant-row ant-form-item">
          {props.required ?
          <label htmlFor={props.id} className="ant-form-item-label ant-form-item-required ant-col-xs-24 ant-col-sm-8">
            {props.label}
          </label> :
          <label htmlFor={props.id} className="ant-form-item-label ant-col-xs-24 ant-col-sm-8">
            {props.label}
          </label>}
          <div className="ant-form-item-control-wrapper ant-col-xs-24 ant-col-sm-16">
            {props.description}
            {props.children}
            {props.errors}
            {props.help}
          </div>
        </div>
      : <div className={props.classNames}>
          {props.children}
        </div>;
  };

  onSubmit = (values) => {
    console.log(values.formData);
    const id = this.props.workspaceData.company_id;
    const {authUser} = this.props;
    const url = `${API_URL_V1}/workspaces/${id}/me`;
    axios.post(`${API_URL_V1}/me`, {
      email: values.formData.email,
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(userData => {
    })
    .catch(error => {
    });
    if(id){
      axios.put(url, {
        manager_id: values.formData.manager,
        time_off_manager_id: values.formData.timeOffManager,
        time_off_policy_id: values.formData.timeOffPolicy,
        location_id: values.formData.office,
        department_id: values.formData.department,
        role_id: values.formData.role_id,
        ...((this.state.oid && this.state.oid == values.formData.oid) ? {} : {oid: values.formData.oid}),
        },
        {
          headers: {
            'Authorization': 'Bearer '+authUser
          }
        }
      ).then(userData => {
          message.success("Information Updated");
          this.setState({...this.state, manager: userData.data.data.manager_id ? (userData.data.data.manager_id).toString() : undefined,
            timeOffManager: userData.data.data.time_off_manager_id ? (userData.data.data.time_off_manager_id).toString() : undefined,
            timeOffPolicy: userData.data.data.time_off_policy_id ? (userData.data.data.time_off_policy_id).toString() : undefined,
            office: userData.data.data.location_id ? (userData.data.data.location_id).toString() : undefined,
            department: userData.data.data.department_id ? (userData.data.data.department_id).toString() : undefined,
            role_id: userData.data.data.role_id ? (userData.data.data.role_id).toString() : undefined,
            oid: userData.data.data.oid ? userData.data.data.oid : '',
          });
      })
      .catch(error => {
      });
    }
  }

  render() {
    console.log(this.props.workspaceData.role);
    return (
      <div>
        {this.state.loading ?
          <div className="gx-loader-view">
            <CircularProgress/>
          </div> :
            <Form
              schema={this.schema}
              uiSchema={this.uiSchema}
              formData={this.state}
              onSubmit={this.onSubmit}
              FieldTemplate={this.CustomFieldTemplate}
              showErrorList={false} >
              <div className="ant-row ant-form-item">
                <div className="ant-col-xs-24 ant-col-sm-8"></div>
                <div className="ant-col-xs-24 ant-col-sm-16">
                  <button type="submit" disabled={!this.state.canUpdate} className="ant-btn btn-primary">Save</button>
                </div>
              </div>
            </Form>}
      </div>
    );
  }
}

const mapStateToProps = ({auth}) => {
  const {authUser, roles} = auth;
  return {authUser, roles}
};

export default connect(mapStateToProps)(ProfileForm);
