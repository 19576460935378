import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Card, Row, Col, message} from "antd";
import Breadcrumbs from "components/Breadcrumbs/index";
import Auxiliary from "../../../util/Auxiliary";
import {API_URL_V1} from "../../../constants";
import axios from "axios";

class InvoicePreview extends Component {
  state={
    previewData: '',
  };

  componentDidMount () {
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/invoices/${this.props.match.params.id}/preview`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(previewData => {
      this.setState({...this.state, previewData: previewData.data})
    })
    .catch(error => {
      message.error("Something went wrong. Please try again.")
    })
  }

  onClick = () => {
    let content = document.getElementById("preview-image");
    let pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  }

  render () {
    return (
      <Auxiliary>
        <div className="gx-profile-content">
        <Breadcrumbs />
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card className="gx-card" title="Preview">
                <iframe id="ifmcontentstoprint" style={{display: "none", height: "0px", width: "0px", position: "absolute"}}></iframe>
                <div className="gx-mb-5">
                  <Button style={{float: "right"}} onClick={(e) => {this.onClick()}} value="Add">Print Invoice</Button>
                </div>
                <div id="preview-image" dangerouslySetInnerHTML={{__html: this.state.previewData}} />
              </Card>
            </Col>
          </Row>
        </div>
      </Auxiliary>
    )
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany} = company;
  return {authUser, currentCompany}
};

export default connect(mapStateToProps)(InvoicePreview);
