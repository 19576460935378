import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Card, Table, Select, Divider, message, Form, Input, Icon} from "antd";
import Auxiliary from "@/util/Auxiliary";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "@/constants";
import axios from "axios";
import moment from "moment";

let data = [];
let workspace_id, disabled;

const Option = Select.Option;

function hasErrors(fieldsError) {
  return Object.keys(fieldsError).some(field => fieldsError[field]);
}

class FilterForm extends React.Component {
  componentDidMount() {
    // To disabled submit button at the beginning.
    this.props.form.validateFields();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
      	this.props.onSubmit(values);
      }
    });
  };

  render() {
    const { getFieldDecorator, getFieldsError, getFieldError, isFieldTouched } = this.props.form;

    // Only show error after a field is touched.
    const countryError = isFieldTouched('country') && getFieldError('country');
    const yearError = isFieldTouched('year') && getFieldError('year');

    const countrySelect = [];
    this.props.countries.forEach(item => {
      countrySelect.push(<Option value={item.country_code} key={item.country_code}>{item.name}</Option>);
    })
    return (
      <Form layout="inline" onSubmit={this.handleSubmit}>
        <Form.Item validateStatus={countryError ? 'error' : ''} help={countryError || ''}>
          {getFieldDecorator('country', {
          	initialValue: 'IN',
            rules: [{ required: true, message: 'Please select Country!' }],
          })(
            <Select
              showSearch
              style={{ width: 200 }}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
		      {countrySelect}
		    </Select>,
          )}
        </Form.Item>
        <Form.Item validateStatus={yearError ? 'error' : ''} help={yearError || ''}>
          {getFieldDecorator('year', {
          	initialValue: moment().year(),
            rules: [{ required: true, message: 'Please select year!' }],
          })(
            <Select style={{ width: 120 }}>
		      <Option value={moment().year() - 1}>{moment().year() - 1}</Option>
          <Option value={moment().year()}>{moment().year()}</Option>
          <Option value={moment().year() + 1}>{moment().year() + 1}</Option>
		    </Select>,
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={hasErrors(getFieldsError())}>
            Fetch Holidays
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedHorizontalFilterForm = Form.create({ name: 'horizontal_filter' })(FilterForm);


class ImportHolidays extends Component {

  constructor(props){
    super(props)
    this.state = {
      importing: false,
      loading: false,
      holidays: [],
      countries: [],
      selectedDays: [],
    };
  }


  getCountries = () => {
    this.setState({...this.state, loading: true})
    const {authUser} = this.props;

      axios.get(`${API_URL_V1}/public-holidays-countries`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(response => {
        this.setState({...this.state, countries: response.data.data, loading: false})
      })
      .catch(error => error);
  };

  getHolidays = (params) => {
  	this.setState({...this.state, loading: true})
    const {authUser} = this.props;

      axios.get(`${API_URL_V1}/public-holidays`, {
        params: params,
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(response => {
        this.setState({...this.state, holidays: response.data.data, loading: false})
      })
      .catch(error => error);
  }

  componentDidMount() {
      let office = this.props.office;
      this.getCountries();
    }

  addHolidays = e => {
	this.setState({...this.stete, importing: true})

  	let workspace_id = this.props.office.company_id;
  	let location_id = this.props.office.id;
  	let authUser = this.props.authUser;

    axios.post(`${API_URL_V1}/workspaces/${workspace_id}/locations/${location_id}/import-holidays`, {
        holidays: this.state.selectedDays
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(response => {
          message.success("Holidays imported successfully");
          	this.setState({...this.stete, importing: false})

      })
      .catch(error => {
      		this.setState({...this.stete, importing: false})
        message.error("Failed to import holidays");
      })
  };

  onSelectChange = (selectedKey, selectedDays) => {
  	this.setState({...this.stete, selectedDays})
  }

  render() {
    disabled = (this.props.currentEmployee && this.props.currentEmployee.role) ? this.props.currentEmployee.role.permissions.includes("edit_company") : '';
    const columns = [{
        title: 'Name',
        dataIndex: 'name',
      }, {
        title: 'Date',
        dataIndex: 'date'

      }];

      const rowSelection = {
		  onChange: this.onSelectChange,
      getCheckboxProps: record => ({
          disabled: this.props.addedHolidays.filter(day => day.date == record.date).length > 0, // Column configuration not to be checked
        })
		};

    const hasSelected = this.state.selectedDays.length > 0;


    return(
      <div>
      <Divider>Import Holidays</Divider>
      <WrappedHorizontalFilterForm onSubmit={this.getHolidays} countries={this.state.countries}/>
      <Divider />
      {this.state.loading ?
      <CircularProgress/> :
      <div>
      <Auxiliary>
      		<div style={{ marginBottom: 16 }}>
	          <Button type="primary" onClick={this.addHolidays} disabled={!hasSelected} loading={this.state.importing}>
	            Add Selected Holidays
	          </Button>
	          <span style={{ marginLeft: 8 }}>
	            {hasSelected ? `Selected ${this.state.selectedDays.length} days` : ''}
	          </span>
	        </div>
          <Table className="gx-table-responsive" rowSelection={rowSelection} key="id" className="gx-table-responsive" columns={columns} dataSource={this.state.holidays} pagination={false}/>
      </Auxiliary>
      </div>}
      </div>
    );
  };
}
const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentEmployee, currentCompany} = company;
  return {authUser, currentEmployee, currentCompany}
};

export default connect(mapStateToProps)(ImportHolidays);
