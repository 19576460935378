import React, {Component} from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import {Button, Card, Table, Divider, message,  Row, Col, Alert} from "antd";
import Auxiliary from "../../../util/Auxiliary";
import Breadcrumbs from "components/Breadcrumbs/index";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../constants";
import axios from "axios";
import service from '../../../requests';


let data = [];
let workspace_id, disabled;

let plans = [
  {
    name: 'Monthly Subscription',
    plan_id: 'monthly',
    billing_cycle: 'Monthly',
    price: 3.00
  },
    {
        name: 'Yearly Subscription',
        plan_id: 'yearly',
        billing_cycle: 'Yearly',
        price: 2.00
    }
]

const
    PriceItem = ({plan, styleName, headerStyle, itemStyle, footerStyle, planSelected}) => {
        return (
            <div className={`${styleName}`}>
                <div className={`${headerStyle}`}>
                    <h2 className="gx-price"><i className="icon icon-halfstar"/>${plan.price}</h2>/User/Month
                    <p className="gx-letter-spacing-base gx-text-white gx-text-uppercase gx-mb-0">{plan.name}</p>
                </div>

                <div className={`${itemStyle}`}>
                    <ul className="gx-package-items">
                        <li>
                            <i className="icon icon-hotel-booking"/>
                            <span>Billed {plan.billing_cycle}</span>
                        </li>
                        <li>
                            <i className="icon icon-timepicker"/>
                            <span>Time Tracking</span>
                        </li>
                        <li>
                            <i className="icon icon-tasks"/>
                            <span>Projects and Clients Management</span>
                        </li>
                        <li>
                            <i className="icon icon-error"/>
                            <span>Minimum Subscription for 5 Users</span>
                        </li>
                    </ul>
                    <div className="gx-package-footer">
                        <Button type="primary" className={`${footerStyle}`} onClick={() => planSelected(plan)}>Subscribe Now</Button>
                    </div>
                </div>


            </div>
        )
    };

class Billing extends Component {
  constructor(props){
    super(props)
    this.chargebeeInstance = window.Chargebee.init({
      site: "baseapp-test"
    });
    this.chargebeeInstance.setPortalSession(function() {
        return service.get('workspaces/'+props.currentCompany.id+'/billing/portal-session').then(res => res.data.portal_session)
      });

    this.state = {
      loading: false,
      subscribing: false,
      cardComplete: false,
      subscribed: false,
      card_last_four: '',
      subscription: null,
      cardError: null,
        plan_id: 'monthly',
    };
  }
  cardRef = React.createRef()

  onSubmit = (e) => {
        if(e) e.preventDefault()
      this.setState({...this.state, subscribing: true})
        this.cardRef.current.tokenize()
        .then((data) => {
          console.log('chargebee token', data.token)
            service.post('workspaces/'+this.props.currentCompany.id+'/billing/subscribe', {
                token: data.token,
                plan_id: this.state.plan_id
            }).then(res => {

            }).catch(error => {
                console.log(error)
            }).then(() => {
                this.setState({...this.state, subscribing: false})
            })
        }).catch(error => {
            console.log(error.message);
            this.setState({...this.state, subscribing: false})
        });
    }

  onChange = (status) => {
    // console.log(status);
    this.setState({
      ...this.state,
        cardComplete: status.complete,
      cardError: status.error ? status.error.message : '',
    })
  }

  getHostedPage() {
    const {authUser, currentCompany} = this.props;
    return service.get('workspaces/'+currentCompany.id+'/billing/checkout-portal', {params: {plan_id: this.state.plan_id}})
    .then(res => res.data.hosted_page)
  }

  getSubscriptions() {
    this.setState({...this.state, loading: true})
    const {authUser, currentCompany} = this.props;
    return service.get('workspaces/'+currentCompany.id+'/billing/subscriptions')
    .then(res => {
        console.log(res.data);
        this.setState({...this.state, ...res.data.data, loading: false})
    }).catch(error => {
      console.log(error);
      this.setState({...this.state, loading: false})
    })
  }

  openCheckout = () => {
    let that = this;
    const {authUser, currentCompany} = this.props;
    this.chargebeeInstance.openCheckout({
      hostedPage: function() {
        // We will discuss on how to implement this end point in the next step.
        return that.getHostedPage();
      },
      loaded: function() {
        // Optional
        // will be called once checkout page is loaded
      },
      error: function(error) {
        // Optional
        // will be called if the promise passed causes an error
      },
      step: function(step) {
        // Optional
        // will be called for each step involved in the checkout process
      },
      success: function(hostedPageId) {
        console.log(hostedPageId);
        service.post('workspaces/'+currentCompany.id+'/billing/checkout-success', {token: hostedPageId})
          .then(res => {
            console.log(res.data)
          })
          .catch(error => {
            console.log(error);
          })
      },
      close: function() {
        that.getSubscriptions();
        that.chargebeeInstance.logout();
      }
    });
  }

  portalOptions = {
      loaded: function() {
        // Optional
        // called when chargebee portal is loaded
      },
      close: () => {
        this.getSubscriptions()
        this.chargebeeInstance.logout();
      },
      visit: function(sectionName) {
        // Optional
        // called whenever the customer navigates across different sections in portal
      },
      paymentSourceAdd: function() {
        // Optional
        // called whenever a new payment source is added in portal
      },
      paymentSourceUpdate: function() {
        // Optional
        // called whenever a payment source is updated in portal
      },
      paymentSourceRemove: function() {
        // Optional
        // called whenever a payment source is removed in portal.
      },
      subscriptionChanged: function(data) {
        // Optional
        // called whenever a subscription is changed
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionCustomFieldsChanged: function(data) {
        // Optional
        // called whenever a subscription custom fields are changed
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionCancelled: function(data) {
        // Optional
        // called when a subscription is cancelled
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page

      },
      subscriptionPaused: function(data) {
        // Optional
        // called when a subscription is Paused.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionResumed: function(data) {
        // Optional
        // called when a paused subscription is resumed.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      scheduledPauseRemoved: function(data) {
        // Optional
        // called when the schedule to pause a subscription is removed for that subscription.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      },
      subscriptionReactivated: function(data) {
        // Optional
        // called when a cancelled subscription is reactivated.
        // data.subscription.id will give you the subscription id
        // Make sure you whitelist your domain in the checkout settings page
      }
    }

  openPortal = () => {    
    var chargebeePortalInstance = this.chargebeeInstance.createChargebeePortal();
    chargebeePortalInstance.open(this.portalOptions)
  }

  openPortalPayments = () => {
    var chargebeePortalInstance = this.chargebeeInstance.createChargebeePortal();
    chargebeePortalInstance.openSection({
      sectionType: window.Chargebee.getPortalSections().BILLING_HISTORY
    })
  }

  componentDidMount() {
    this.getSubscriptions();
  }

  planSelected = plan => {
      this.setState({...this.state, plan_id: plan.plan_id}, () => this.openCheckout())
  }


  render() {
    
        return(
          <div>
          {this.state.loading ?
            <CircularProgress/> :
            <div>
            
              <Auxiliary>
                <div className="gx-profile-content">
                <Breadcrumbs />
                  <Row>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>

                      <Card className="gx-card" title="Billing">



                      {this.state.subscribed ? <div>
                          Subscribed
                          <br/>
                          Card Details: **** {this.state.card_last_four}

                          <div>
                          Next Billing: {this.state.subscription.next_billing_at}
                          </div>

                          <br/>
                          <Button onClick={e => this.openPortal()}>Manage Subscription</Button>
                          <Button onClick={e => this.openPortalPayments()}>Invoices</Button>
                        </div> :

                          <div className="gx-price-tables gx-pt-classic">
                              <Row>
                                  <Col xl={12} lg={12} md={12} xs={24}>
                                      <PriceItem
                                          plan={plans[0]}
                                          styleName="gx-bg-amber-light gx-package"
                                          headerStyle="gx-package-header gx-bg-amber gx-text-black"
                                          itemStyle="gx-package-body"
                                          footerStyle=""
                                          selectedPlan={this.state.plan}
                                          planSelected={this.planSelected}
                                      />
                                  </Col>

                                  <Col xl={12} lg={12} md={12} xs={24}>
                                      <PriceItem
                                          plan={plans[1]}
                                          styleName="gx-bg-primary-light gx-package gx-highlight"
                                          headerStyle="gx-package-header gx-bg-primary gx-text-white"
                                          itemStyle="gx-package-body gx-text-white"
                                          footerStyle="gx-btn-primary"
                                          selectedPlan={this.state.plan}
                                          planSelected={this.planSelected}
                                      />
                                  </Col>
                              </Row>
                          </div>
                      }
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Auxiliary>
              </div>}
              </div>
            );
          };
        }
        const mapStateToProps = ({auth, company}) => {
          const {authUser} = auth;
          const {currentEmployee, currentCompany} = company;
          return {authUser, currentEmployee, currentCompany}
        };

        export default withRouter(connect(mapStateToProps)(Billing));
