import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Col, Row} from "antd";
import Auxiliary from "../../../util/Auxiliary";
import {API_URL_V1} from "../../../constants";
import AttendanceReport from "./AttendanceReport";
import CircularProgress from "components/CircularProgress/index";
import Breadcrumbs from "components/Breadcrumbs/index";
import axios from "axios";

class AttendanceReports extends Component {
  state={
    employeeData: [],
    employeeCount: 0,
    page: 1,
    per_page: 50,
    loading: true,
  }

  getEmployees = () => {
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/employees`, {
      params: {
        page: this.state.page, per_page: this.state.per_page
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(employeeData => {
      let employees = this.state.employeeData;
      employeeData.data.data.forEach((employee, index) => {
        employees.push(employee);
      })
      this.setState({...this.state, employeeData:employees, page: this.state.page + 1, employeeCount: this.state.employeeCount+this.state.per_page, loading: false},() => {if(this.state.employeeCount < employeeData.data.meta.total) {return(this.getEmployees())}})
    })
    .catch(error => error)
  }

  componentDidMount () {
    this.getEmployees();
  }

  render() {
    return(
      <Auxiliary>
        <Breadcrumbs />
        {(this.props.currentEmployee && this.props.currentEmployee.role && this.props.currentEmployee.role.permissions) ?
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Card className="gx-card" title="Attendance Report">
            {this.state.loading ? <CircularProgress /> :
              <AttendanceReport employees={this.state.employeeData}/>
            }
            </Card>
          </Col>
        </Row> : <CircularProgress />}
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, currentCompany, currentEmployee}
};

export default connect(mapStateToProps)(AttendanceReports);
