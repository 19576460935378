import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Card, Col, Divider, Input, Modal, Row, Table, message} from "antd";
import Auxiliary from "../../../../util/Auxiliary";
import CircularProgress from "components/CircularProgress/index";
import EmployeeCustomFieldsModal from "./EmployeeCustomFieldsModal";
import {API_URL_V1} from "../../../../constants";
import axios from "axios";

class EmployeeCustomFields extends Component {
  state={
    visible: false,
    loading: true,
    title: '',
    customFields: [],
    editData: '',
    confirmLoading: false,
    deleteVisible: false,
    deleteLoading: false,
    record: '',
  };

  values = {
    string: 'Text',
    paragraph: 'Paragraph',
    date: 'Date',
    numeric: 'Number',
    file: 'File',
  }

  getCustomFields = () => {
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/custom_field_types`,{
      params: {
        category: "employee",
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      },
    })
    .then(customFieldsData => {
      this.setState({...this.state, customFields: customFieldsData.data.data, loading: false})
    })
    .catch(error => error);
  }

  componentDidMount() {
    this.getCustomFields();
  }

  onClick = () => {
    this.setState({...this.state, visible: true, title: "Add Custom Field", editData: ''});
  }

  edit = (record, e) => {
    e.preventDefault();
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/custom_field_types/${record.id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(editData => {
      this.setState({...this.state, visible: true, title: "Edit Custom Field", editData: editData.data.data});
    })
    .catch(error => error);
  }

  handleCancel = () => {
    this.setState({...this.state, visible: false});
  }

  handleOk = (values, id) => {
    this.setState({...this.state, confirmLoading: true});
    const {authUser, currentCompany} = this.props;
    if(!id){
      axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/custom_field_types`, {
        name: values.customfield_name,
        type: values.customfield_type,
        description: values.description,
        type: values.customfield_type,
        editable: values.editable,
        visible_to: values.visible_to,
        mandatory: values.required=='1' ? true : false,
        company_id: currentCompany.id,
        category: "employee",
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(customFieldData => {
        let customFields = this.state.customFields;
        customFields.push(customFieldData.data.data);
        message.success("New Custom Field Added");
        this.setState({...this.state, customFields: customFields, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Add Custom Field");
        this.setState({...this.state, confirmLoading: false});
      })
    } else {
      axios.put(`${API_URL_V1}/workspaces/${currentCompany.id}/custom_field_types/${id}`, {
        name: values.customfield_name,
        type: values.customfield_type,
        description: values.description,
        type: values.customfield_type,
        editable: values.editable,
        visible_to: values.visible_to,
        mandatory: values.required=='1' ? true : false,
        company_id: currentCompany.id,
        category: "employee",
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(customFieldData => {
        let customFields = this.state.customFields;
        customFields.forEach((customField, index) => {
          if(customField.id === customFieldData.data.data.id) {
            customFields[index] = {...customField, ...customFieldData.data.data};
          }
        })
        message.success("Custom Field Editted Successfully");
        this.setState({...this.state, customFields: customFields, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Edit");
        this.setState({...this.state, confirmLoading: false});
      })
    }
  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser, currentCompany} = this.props;
    axios.delete(`${API_URL_V1}/workspaces/${currentCompany.id}/custom_field_types/${record.id}`, {
      params: {
        workspace_id: currentCompany.id,
        field_id: record.id,
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(customfieldtypesData => {
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      this.getCustomFields();
      message.success("Custom Field Type Deleted Successfully");
    })
    .catch(error => {
      message.error("Failed to Delete");
    })
  }


  render() {
    const columns = [{
        title: 'Field Name',
        dataIndex: 'name',
      }, {
        title: 'Type',
        dataIndex: 'type'
      }, {
        title: 'Actions',
        key: 'x',
        render: (text, record) => (
          <span>

            <span className="gx-link" onClick={(e) => {this.edit(record, e)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span>
            <Divider type="vertical"/>
            <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span>
          </span>
        )
      }];
    return(
      <div>
      <Modal
        visible={this.state.deleteVisible}
        title="Delete Custom Field?"
        onOk={(e) => this.deleteRecord(this.state.record, e)}
        onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
        confirmLoading={this.state.deleteLoading}
        footer={[
          <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
            OK
          </Button>,
          <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
            Cancel
          </Button>
        ]}
      >
        <p>Are you Sure you want to delete this Custom Field?</p>
      </Modal>
      {this.state.visible ?
      <EmployeeCustomFieldsModal title={this.state.title} value={this.state.editData} visible={this.state.visible} onCancel={this.handleCancel} onOk={this.handleOk} confirmLoading={this.state.confirmLoading}/> : null}
      {this.state.loading ?
      <CircularProgress /> :
      <Auxiliary>
        <div className="gx-profile-content">
          <div style={{paddingBottom: "2.5rem"}}>
            <Button style={{float: "right"}} onClick={this.onClick} value="Add">Add Custom Field</Button>
          </div>
          <Table className="gx-table-responsive" columns={columns} dataSource={this.state.customFields} onChange={this.handleTableChange}/>
        </div>
      </Auxiliary>}
      </div>
    );
  };
}

const mapStateToProps = ({auth, company}) => {
  const {authUser, userWorkspaceData} = auth;
  const {currentCompany} = company;
  return {authUser, userWorkspaceData, currentCompany}
};
export default connect(mapStateToProps)(EmployeeCustomFields);
