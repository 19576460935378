import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {Button, Form, Input, Select, Row, Col, DatePicker, Icon, Checkbox, Skeleton, Divider, message} from "antd";
import Auxiliary from "../../../util/Auxiliary";
import {API_URL_V1} from "../../../constants";
import CircularProgress from "components/CircularProgress/index";
import moment from "moment";
import axios from "axios";

const {Option} = Select;
let id = 0, initVal=[], valLength=0, allKeys;
const { TextArea } = Input;

class InvoiceForm extends Component {

  state={
    invoiceItems: [],
    editInvoice: this.props.editInvoice,
    checkTax1: false,
    checkTax2: false,
    checkDiscount: false,
    loading: this.props.editInvoice,
    itemLoading: false,
    total: 0,
    finalTotal: 0,
  }

  componentDidMount () {
    if(this.props.editInvoice) {
      id=0;
      const {authUser, currentCompany} = this.props;
      axios.get(`${API_URL_V1}/invoices/${this.props.editInvoice.id}/items`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(invoiceItemsData => {
        let checkTax1 = false, checkTax2 = false, checkDiscount: false, total = 0, finalTotal = 0;
        valLength = invoiceItemsData.data.length;
        invoiceItemsData.data.map((item, index) => {
          total = total+ +item.amount;
          let tot = 0+ +item.amount;
          if(item.discount === true) {
            checkDiscount= true;
            if(this.state.editInvoice.discount_rate) {
              tot = tot - ((this.state.editInvoice.discount_rate/100)*tot)
            }
          }
          if(item.tax1 === true) {
            checkTax1= true;
            if(this.state.editInvoice.tax_rate1) {
              tot = tot + ((this.state.editInvoice.tax_rate1/100)*tot)
            }
          }
          if(item.tax2 === true) {
            checkTax2= true;
            if(this.state.editInvoice.tax_rate2) {
              tot = tot + ((this.state.editInvoice.tax_rate2/100)*tot)
            }
          }
          finalTotal = finalTotal+ +tot;
        })
        this.setState({...this.state, invoiceItems: invoiceItemsData.data, checkTax1: checkTax1, checkTax2: checkTax2, checkDiscount: checkDiscount, total: total, finalTotal: finalTotal, loading: false})
      })
      .catch(error => error)
    } else {
      valLength = 0;
    }
  }

  remove = k => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
  };

  add = () => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(valLength + id++ );
    form.setFieldsValue({
      keys: nextKeys,
    });
  };

  handleSubmit = (e, k, id) => {
    e.preventDefault();
    const {authUser, currentCompany} = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({...this.state, itemLoading: true});
        values.keys.forEach((k, index) => {
        let id = this.state.invoiceItems && this.state.invoiceItems[k] ? this.state.invoiceItems[k].id : '';
        if(!id) {
          axios.post(`${API_URL_V1}/invoices/${this.state.editInvoice.id}/items`, {
            description: values.description[k],
            quantity: values.quantity[k],
            unit_price: values.unit_price[k],
            amount: values.amount[k],
            tax1: values.tax1[k] ? values.tax1[k] : false,
            tax2: values.tax2[k] ? values.tax2[k] : false,
            discount: values.discount[k] ? values.discount[k] : false,
          },{
            headers: {
              'Authorization': 'Bearer '+authUser
            }
          })
          .then(invoicesData => {
            let checkTax1 = false, checkTax2 = false, checkDiscount: false;
            let invoiceItems = this.state.invoiceItems;
            let finalTotal = this.state.finalTotal;
            invoiceItems.push(invoicesData.data);
            if(invoicesData.data.tax1 === true) {
              checkTax1= true;
              if(this.state.editInvoice.tax_rate1 || values.tax_rate1) {
                let tax_rate1 = values.tax_rate1 ? values.tax_rate1 : this.state.editInvoice.tax_rate1;
                finalTotal = finalTotal + (((tax_rate1/100)*invoicesData.data.amount)+ +invoicesData.data.amount)
              }
            }
            if(invoicesData.data.tax2 === true) {
              checkTax2= true;
              if(this.state.editInvoice.tax_rate2 || values.tax_rate2) {
                let tax_rate2 = values.tax_rate2 ? values.tax_rate2 : this.state.editInvoice.tax_rate2;
                finalTotal = finalTotal + (((tax_rate2/100)*invoicesData.data.amount)+ +invoicesData.data.amount)
              }
            }
            if(invoicesData.data.discount === true) {
              checkDiscount= true;
              if(this.state.editInvoice.discount_rate || values.discount_rate) {
                let discount_rate = values.discount_rate ? values.discount_rate : this.state.editInvoice.discount_rate;
                finalTotal = finalTotal + (invoicesData.data.amount - ((discount_rate/100)*invoicesData.data.amount))
              }
            }
            if(!invoicesData.data.tax1 && !invoicesData.data.tax2 && !invoicesData.data.discount) {
              finalTotal = finalTotal+ +invoicesData.data.amount;
            }
            this.setState({...this.state, invoiceItems: invoiceItems, checkTax1: checkTax1, checkTax2: checkTax2, finalTotal: finalTotal, checkDiscount: checkDiscount},() => {if(index === values.keys.length-1) {this.submitInvoice()}});
          })
          .catch(error => error)
        } else {
          axios.put(`${API_URL_V1}/invoices/${this.state.editInvoice.id}/items/${id}`, {
            description: values.description[k],
            quantity: values.quantity[k],
            unit_price: values.unit_price[k],
            amount: values.amount[k],
            tax1: values.tax1[k] ? values.tax1[k] : false,
            tax2: values.tax2[k] ? values.tax2[k] : false,
            discount: values.discount[k] ? values.discount[k] : false,
          },{
            headers: {
              'Authorization': 'Bearer '+authUser
            }
          })
          .then(invoicesData => {
            let {checkTax1, checkTax2, checkDiscount, finalTotal} = this.state;
            let totalAmount = invoicesData.data.amount;
            if(invoicesData.data.tax1 === true) {
              checkTax1= true;
              if(this.state.editInvoice.tax_rate1 || values.tax_rate1) {
                let tax_rate = values.tax_rate1 ? values.tax_rate1 : this.state.editInvoice.tax_rate1;
                totalAmount = ((tax_rate/100)*totalAmount)+ +totalAmount;
              }
            }
            if(invoicesData.data.tax2 === true) {
              checkTax2= true;
              if(this.state.editInvoice.tax_rate2 || values.tax_rate2) {
                let tax_rate = values.tax_rate1 ? values.tax_rate1 : this.state.editInvoice.tax_rate2;
                totalAmount = ((tax_rate/100)*totalAmount)+ +totalAmount;
              }
            }
            if(invoicesData.data.discount === true) {
              checkDiscount= true;
              if(this.state.editInvoice.discount_rate || values.discount_rate) {
                let discount_rate = values.discount_rate ? values.discount_rate : this.state.editInvoice.discount_rate;
                totalAmount = totalAmount - ((discount_rate/100)*totalAmount);
              }
            }
            let invoiceItems = this.state.invoiceItems;
            let deducableAmount = 0;
            invoiceItems.forEach((invoiceItem, index) => {
              if(invoiceItem.id == invoicesData.data.id){
                deducableAmount = invoiceItem.amount;
                if(invoiceItem.tax1 && this.state.editInvoice.tax_rate1) {
                  deducableAmount = ((this.state.editInvoice.tax_rate1/100)*deducableAmount)+ +deducableAmount;
                }
                if(invoiceItem.tax2 && this.state.editInvoice.tax_rate2) {
                  deducableAmount = ((this.state.editInvoice.tax_rate2/100)*deducableAmount)+ +deducableAmount;
                }
                if(invoiceItem.discount && this.state.editInvoice.discount_rate) {
                  deducableAmount = deducableAmount - ((this.state.editInvoice.discount_rate/100)*deducableAmount);
                }
                invoiceItems[index] = {...invoiceItem, ...invoicesData.data};
              }
            })
            finalTotal = finalTotal+ +totalAmount - deducableAmount;
            console.log(finalTotal)
            this.setState({...this.state, invoiceItems: invoiceItems, checkTax1: checkTax1, checkTax2: checkTax2, finalTotal: finalTotal, checkDiscount: checkDiscount},() => {if(index === values.keys.length-1) {this.submitInvoice()}});
          })
          .catch(error => error)
        }})
        if(values.keys.length === 0) {
          this.submitInvoice();
        }
      }
    })
  }

  submitInvoice = (val) => {
    console.log(this.state.finalTotal)
    console.log('yess')

    const {authUser, currentCompany} = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        if(!this.state.editInvoice) {
          axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/invoices`, {
            client_id: values.client_id,
            invoice_number: values.invoice_number,
            invoice_date: moment(values.invoice_date).format("YYYY-MM-DD"),
            due_date: moment(values.due_date).format("YYYY-MM-DD"),
            public_notes: values.public_notes,
            tax_name1: values.tax_name1,
            tax_rate1: values.tax_rate1,
            tax_name2: values.tax_name2,
            tax_rate2: values.tax_rate2,
            discount_name: values.discount_name,
            discount_rate: values.discount_rate,
            amount: this.state.finalTotal,
          },{
            headers: {
              'Authorization': 'Bearer '+authUser
            }
          })
          .then(invoicesData => {
            if(!val) {
              message.success("Invoice Saved");
            }
            this.setState({...this.state, editInvoice: invoicesData.data, itemLoading: false});
          })
          .catch(error => error)
        } else {
          console.log(this.state.finalTotal)
          axios.put(`${API_URL_V1}/workspaces/${currentCompany.id}/invoices/${this.state.editInvoice.id}`, {
            client_id: values.client_id,
            invoice_number: values.invoice_number,
            invoice_date: moment(values.invoice_date).format("YYYY-MM-DD"),
            due_date: moment(values.due_date).format("YYYY-MM-DD"),
            public_notes: values.public_notes,
            tax_name1: values.tax_name1,
            tax_rate1: values.tax_rate1,
            tax_name2: values.tax_name2,
            tax_rate2: values.tax_rate2,
            discount_name: values.discount_name,
            discount_rate: values.discount_rate,
            amount: this.state.finalTotal,
          },{
            headers: {
              'Authorization': 'Bearer '+authUser
            }
          })
          .then(invoicesData => {
            if(!val) {
              message.success("Invoice Saved");
            }
            this.setState({...this.state, itemLoading: false});
          })
          .catch(error => error)
        }
      }
    });
  }

  deleteRecord = (id, k) => {
    const {authUser} = this.props;
    axios.delete(`${API_URL_V1}/invoices/${this.state.editInvoice.id}/items/${id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(data => {
      let invoiceItems = this.state.invoiceItems;
      let total = this.state.total;
      let finalTotal = this.state.finalTotal;
      let totalAmount = 0;
      invoiceItems.forEach((item, index) => {

        if(item.id === id) {
          total = total-item.amount;
          if(item.tax1) {
            totalAmount = (((this.state.editInvoice.tax_rate1/100)*item.amount)+ +item.amount);
          }
          if(item.tax2) {
            totalAmount = (((this.state.editInvoice.tax_rate2/100)*item.amount)+ +item.amount);
          }
          if(item.discount) {
            totalAmount = (item.amount - ((this.state.editInvoice.discount_rate/100)*item.amount));
          }
          finalTotal = finalTotal - totalAmount;
          invoiceItems.splice(index, 1);
        }
      })
      this.setState({...this.state, invoiceItems: invoiceItems, total: total, finalTotal: finalTotal},() => {this.remove(k); this.submitInvoice("delete")});
      message.success("Item Deleted Successfully");
    })
    .catch(error => error)
  }


  onChange = (e) => {
    let numId = e.target.id.match(/\d+/)[0];
    let amount = document.getElementById(`amount[${numId}]`).value;
    let total = this.state.total - amount;
    let finalTotal = this.state.finalTotal;
    if(document.getElementById(`tax1[${numId}]`).checked) {
      finalTotal = finalTotal - (((this.state.editInvoice.tax_rate1/100)*amount)+ +amount);
    }
    if(document.getElementById(`tax2[${numId}]`).checked) {
      finalTotal = finalTotal - (((this.state.editInvoice.tax_rate2/100)*amount)+ +amount);
    }
    if(document.getElementById(`discount[${numId}]`).checked) {
      finalTotal = finalTotal - (amount - ((this.state.editInvoice.discount_rate/100)*amount));
    }
    if(e.target.placeholder === "Quantity") {
      let unit_price = document.getElementById(`unit_price[${numId}]`).value;
      amount = unit_price == 0 || e.target.value == 0 ? '0' : unit_price*e.target.value;
    }
    if(e.target.placeholder === "Unit Price") {
      let quantity = document.getElementById(`quantity[${numId}]`).value;
      amount = quantity == 0 || e.target.value == 0 ? '0' : quantity*e.target.value;
    }
    total = total+ +amount;
    if(document.getElementById(`tax1[${numId}]`).checked) {
      finalTotal = finalTotal + (amount + ((this.state.editInvoice.tax_rate1/100)*amount));
    }
    if(document.getElementById(`tax2[${numId}]`).checked) {
      finalTotal = finalTotal + (amount + ((this.state.editInvoice.tax_rate2/100)*amount));
    }
    if(document.getElementById(`discount[${numId}]`).checked) {
      finalTotal = finalTotal + (amount - ((this.state.editInvoice.discount_rate/100)*amount));
    }
    console.log(finalTotal)
    this.setState({...this.state, [`amount[${numId}]`]: amount, total: total, finalTotal: finalTotal});
  }

  preview = () => {
    this.props.history.push({pathname: `/invoice/${this.state.editInvoice.id}/preview`, state: {invoiceData: this.state.editInvoice}})
  }

  onTax1Change = e => {
    let val = false;
    allKeys.forEach((key, index) => {
      if(document.getElementById(`tax1[${key}]`).checked) {
        val = true;
      }
    })
    this.setState({...this.state, checkTax1: val})
  }

  onTax2Change = e => {
    let val = false;
    allKeys.forEach((key, index) => {
      if(document.getElementById(`tax2[${key}]`).checked) {
        val = true;
      }
    })
    this.setState({...this.state, checkTax2: val})
  }

  onDiscountChange = e => {
    let val = false;
    allKeys.forEach((key, index) => {
      if(document.getElementById(`discount[${key}]`).checked) {
        val = true;
      }
    })
    this.setState({...this.state, checkDiscount: val})
  }

  render() {
    console.log(this.state.invoiceItems)
    const inlineItemLayout = {
      labelCol: {
        xs: {span: 24},
        sm: {span: 10},
        md: {span: 8},
        lg: {span: 6},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 14},
        md: {span: 16},
        lg: {span: 18},
      },
    };
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
      },
    };
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 18, offset: 6 },
      },
    };

    initVal = [];
    this.state.invoiceItems.forEach((item, index) => {
      initVal.push(index);
    })

    const { getFieldDecorator, getFieldValue } = this.props.form;
    getFieldDecorator('keys', { initialValue: this.state.itemLoading ? [] : initVal });
    let keys = getFieldValue('keys');
    allKeys = getFieldValue('keys');
    let formItems = keys.map((k, index) => (
      <div>
        <Row>
          <Col span={6}>
            <Form.Item
              {...(index === 0 ? formItemLayout : formItemLayout)}
              label={index === 0 ? 'Description' : ''}
              required={false}
              key={k}
              labelAlign="left"
            >
              {getFieldDecorator(`description[${k}]`, {
                initialValue: this.state.invoiceItems && this.state.invoiceItems[k] ? this.state.invoiceItems[k].description : this.state.invoiceItems && this.state.invoiceItems[index] ? this.state.invoiceItems[index].description : '',
                validateTrigger: ['onChange', 'onBlur'],
                rules: [
                  {
                    required: true,
                    whitespace: true,
                    message: "Please input the data or delete this field.",
                  },
                ],
              })(<Input placeholder="Enter Description" style={{ width: '100%', marginRight: 8, display: "inline" }} />)}
            </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                {...(index === 0 ? formItemLayout : formItemLayout)}
                label={index === 0 ? 'Quantity' : ''}
                required={false}
                key={k}
                labelAlign="left"
              >
                {getFieldDecorator(`quantity[${k}]`, {
                  initialValue: this.state.invoiceItems && this.state.invoiceItems[k] ? (this.state.invoiceItems[k].quantity).toString() : this.state.invoiceItems && this.state.invoiceItems[index] ? this.state.invoiceItems[index].quantity.toString() : '',
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input the valid data.",
                    },
                  ],
                })(<Input placeholder="Quantity" type="number"  onChange={this.onChange} style={{ width: '100%', marginRight: 8, display: "inline"}} />)}
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                {...(index === 0 ? formItemLayout : formItemLayout)}
                label={index === 0 ? 'Unit Price' : ''}
                required={false}
                key={k}
                labelAlign="left"
              >
                {getFieldDecorator(`unit_price[${k}]`, {
                  initialValue: this.state.invoiceItems && this.state.invoiceItems[k] ? this.state.invoiceItems[k].unit_price : this.state.invoiceItems && this.state.invoiceItems[index] ? this.state.invoiceItems[index].unit_price : '',
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input the valid data.",
                    },
                  ],
                })(<Input placeholder="Unit Price" type="number" onChange={this.onChange} style={{ width: '100%', marginRight: 8, display: "inline"}} />)}
              </Form.Item>
            </Col>
            <Col span={3}>
              <Form.Item
                {...(index === 0 ? formItemLayout : formItemLayout)}
                label={index === 0 ? 'Amount' : ''}
                required={false}
                key={k}
                labelAlign="left"
              >
                {getFieldDecorator(`amount[${k}]`, {
                  initialValue: this.state[`amount[${k}]`] ? this.state[`amount[${k}]`].toString() : this.state.invoiceItems && this.state.invoiceItems[k] ? this.state.invoiceItems[k].amount : '',
                  validateTrigger: ['onChange', 'onBlur'],
                  rules: [
                    {
                      required: true,
                      whitespace: true,
                      message: "Please input the data or delete this field.",
                    },
                  ],
                })(<Input placeholder="Amount" type="number" onChange={this.onChange} style={{ width: '100%', marginRight: 8, display: "inline"}} />)}
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item
                {...(index === 0 ? formItemLayout : formItemLayout)}
                label={index === 0 ? 'Tax 1' : ''}
                required={false}
                key={k}
                labelAlign="left"
              >
                {getFieldDecorator(`tax1[${k}]`, {
                  initialValue: this.state.invoiceItems && this.state.invoiceItems[k] ? this.state.invoiceItems[k].tax1 : this.state.invoiceItems && this.state.invoiceItems[index] ? this.state.invoiceItems[index].tax1 : false,
                  valuePropName: 'checked'
                })(<Checkbox onChange={this.onTax1Change}/>)}
              </Form.Item>
            </Col>
            <Col span={2}>
              <Form.Item
                {...(index === 0 ? formItemLayout : formItemLayout)}
                label={index === 0 ? 'Tax 2' : ''}
                required={false}
                key={k}
                labelAlign="left"
              >
                {getFieldDecorator(`tax2[${k}]`, {
                  initialValue: this.state.invoiceItems && this.state.invoiceItems[k] ? this.state.invoiceItems[k].tax2 : this.state.invoiceItems && this.state.invoiceItems[index] ? this.state.invoiceItems[index].tax2 : false,
                  valuePropName: 'checked'
                })(<Checkbox onChange={this.onTax2Change}/>)}
              </Form.Item>
            </Col>
            <Col span={4}>
              <Form.Item
                {...(index === 0 ? formItemLayout : formItemLayout)}
                label={index === 0 ? 'Discount' : ''}
                required={false}
                key={k}
                labelAlign="left"
              >
                {getFieldDecorator(`discount[${k}]`, {
                  initialValue: this.state.invoiceItems && this.state.invoiceItems[k] ? this.state.invoiceItems[k].discount : this.state.invoiceItems && this.state.invoiceItems[index] ? this.state.invoiceItems[index].discount : false,
                  valuePropName: 'checked'
                })(<Checkbox onChange={this.onDiscountChange} style={{ width: '70%', marginRight: 60, display: "inline"}} />)}
                {keys.length > 0 ? (
                  <Icon
                    className="dynamic-submit-button gx-mr-2"
                    type="check-circle"
                    theme="twoTone"
                    twoToneColor="#52c41a"
                    style={{display: 'none'}}
                    onClick={(e) => this.handleSubmit(e, k, (this.state.invoiceItems && this.state.invoiceItems[k] ? this.state.invoiceItems[k].id : ''))}
                  />
                ) : null}
                {keys.length > 0 ? (
                  <Icon
                    className="dynamic-delete-button"
                    type="delete"
                    theme="twoTone"
                    twoToneColor="#f44336"
                    onClick={() => (this.state.invoiceItems && this.state.invoiceItems[k]) ? this.deleteRecord(this.state.invoiceItems[k].id, k) : (this.state.invoiceItems && this.state.invoiceItems[index]) ? this.deleteRecord(this.state.invoiceItems[index].id, index) : this.remove(k)}
                  />
                ) : null}
              </Form.Item>
            </Col>
        </Row>
      </div>
    ));

    const {currentEmployee} = this.props;
    let disabled = currentEmployee && currentEmployee.role && (currentEmployee.role.permissions.includes("edit_company") || currentEmployee.role.permissions.includes("manage_company"));
    return (
      <Auxiliary>
        { disabled && <div style={{marginBottom: "3.5rem"}}>
          <span style={{float: 'right'}}>
            <Button style={{ verticalAlign: "baseline"}} onClick={this.props.onBack}>Go Back</Button>
            {this.state.editInvoice ?
            <Button style={{ verticalAlign: "baseline"}} onClick={this.preview}>Preview</Button> : ''}
            <Button form="invoiceForm" key="submit" htmlType="submit" onClick={this.handleSubmit} style={{ verticalAlign: "baseline"}} disabled={!disabled}>Save Invoice</Button>
          </span>
        </div> }
        {this.state.loading ? <CircularProgress /> :
          <Form id="invoiceForm" onSubmit={this.calculateFinalAmount}>
            <Row gutter={24}>
              <Col span={12} style={{ display:'block'}}>
                <Form.Item
                {...inlineItemLayout}
                label="Bill To">
                  {getFieldDecorator('client_id', {
                    initialValue: this.state.editInvoice ? this.state.editInvoice.client_id.toString() : undefined,
                    rules: [{ required: true, message: 'Please select the Client name!' }],
                  })(
                    <Select placeholder="Client Name" showAction={['click', 'focus']}>
                      {this.props.clients}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12} style={{ display:'block'}}>
                <Form.Item
                {...inlineItemLayout}
                label="Invoice No.">
                  {getFieldDecorator('invoice_number', {
                    initialValue: this.state.editInvoice.invoice_number,
                    rules: [{ required: true, message: 'Please input the invoice number'}],
                  })(
                    <Input placeholder="Invoice No." />
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12} style={{ display:'block'}}>
                <Form.Item
                {...inlineItemLayout}
                label="Date of Issue">
                  {getFieldDecorator('invoice_date', {
                    initialValue: this.state.editInvoice ? moment(this.state.editInvoice.invoice_date) : '',
                    rules: [{ required: true, message: 'Please select the Issue Date!' }],
                  })(
                    <DatePicker className="gx-w-100" placeholder="Date of Issue"/>
                  )}
                </Form.Item>
              </Col>
              <Col span={12} style={{ display:'block'}}>
                <Form.Item
                {...inlineItemLayout}
                label="Due Date">
                  {getFieldDecorator('due_date', {
                    initialValue: this.state.editInvoice ? moment(this.state.editInvoice.due_date) : '',
                    rules: [{ required: true, message: 'Please select Due Date'}],
                  })(
                    <DatePicker className="gx-w-100" placeholder="Due Date" />
                  )}
                </Form.Item>
              </Col>
            </Row>
            {this.state.itemLoading ? <Skeleton active paragraph={{ rows: 2 }}/> :
            <div>
            {formItems}
            {this.state.editInvoice ?
            <Form.Item {...formItemLayoutWithOutLabel}>
              <Button type="dashed" onClick={this.add} style={{ width: '60%' }}>
                <Icon type="plus" /> Add Item
              </Button>
            </Form.Item>:''}
            </div>
            }
            <Divider />
            <Row gutter={24}>
              <Col span={12} style={{ display:'block'}}>
                <Form.Item
                {...inlineItemLayout}
                label="Notes">
                  {getFieldDecorator('public_notes', {
                    initialValue: this.state.editInvoice.public_notes,
                  })(
                    <TextArea className="gx-w-100" placeholder="Notes" autosize={{minRows: 6}}/>
                  )}
                </Form.Item>
              </Col>
              <Col span={12} style={{ display:'block'}}>
                <Row>
                  <Col span={16}>
                    <Form.Item
                    {...inlineItemLayout}
                    label=" "
                    colon={false}>
                      <span>Total :</span>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                    {...formItemLayoutWithOutLabel}
                    label="">
                        {this.state.total}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={16}>
                    <Form.Item
                    {...inlineItemLayout}
                    label="Tax 1">
                      {getFieldDecorator('tax_name1', {
                        initialValue: this.state.editInvoice.tax_name1,
                        rules: [{required: this.state.checkTax1, message: 'Please enter Tax Name'}]
                      })(
                        <Input placeholder="Tax1 Name"/>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                    {...formItemLayoutWithOutLabel}
                    label="">
                      {getFieldDecorator('tax_rate1', {
                        initialValue: this.state.editInvoice.tax_rate1,
                        rules: [{required: this.state.checkTax1, message: 'Please enter Tax Value'}]
                      })(
                        <Input placeholder="Tax1 Value" addonAfter="%"/>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={16}>
                    <Form.Item
                    {...inlineItemLayout}
                    label="Tax 2">
                      {getFieldDecorator('tax_name2', {
                        initialValue: this.state.editInvoice.tax_name2,
                        rules: [{required: this.state.checkTax2, message: 'Please enter Tax Name'}]
                      })(
                        <Input placeholder="Tax2 Name"/>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                    {...formItemLayoutWithOutLabel}
                    label="">
                      {getFieldDecorator('tax_rate2', {
                        initialValue: this.state.editInvoice.tax_rate2,
                        rules: [{required: this.state.checkTax2, message: 'Please enter Tax Value'}]
                      })(
                        <Input placeholder="Tax2 Value" addonAfter="%"/>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={16}>
                    <Form.Item
                    {...inlineItemLayout}
                    label="Discount">
                      {getFieldDecorator('discount_name', {
                        initialValue: this.state.editInvoice.discount_name,
                        rules: [{required: this.state.checkDiscount, message: 'Please enter Discount Name'}]
                      })(
                        <Input placeholder="Discount"/>
                      )}
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                    {...formItemLayoutWithOutLabel}
                    label="">
                      {getFieldDecorator('discount_rate', {
                        initialValue: this.state.editInvoice.discount_rate,
                        rules: [{required: this.state.checkDiscount, message: 'Please enter Discount Value'}]
                      })(
                        <Input placeholder="Discount Value"  addonAfter="%"/>
                      )}
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col span={16}>
                    <Form.Item
                    {...inlineItemLayout}
                    label=" "
                    colon={false}>
                      <span>Final Total :</span>
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                    {...formItemLayoutWithOutLabel}
                    label="">
                        {this.state.finalTotal.toFixed(2)}
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        }
      </Auxiliary>
    )
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, currentCompany, currentEmployee}
};


export default withRouter(connect(mapStateToProps)(Form.create()(InvoiceForm)));
