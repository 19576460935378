import React, {Component} from "react";
import {Modal, Form, Input, Select, DatePicker, Checkbox, Button} from 'antd';
import moment from "moment";

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends Component {
    render() {
      const formItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 8},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 16},
        },
      };

      const { getFieldDecorator } = this.props.form;
      return (
        <Modal
          visible={this.props.visible}
          title={this.props.title}
          onCancel={this.props.onCancel}
          onOk={this.props.onCreate}
          footer={[
            <Button className="add-project-form-button" key="submit" loading={this.props.confirmLoading} onClick={this.props.onCreate}>
              {this.props.title}
            </Button>,
            <Button key="cancel" onClick={this.props.onCancel}>
              Cancel
            </Button>
          ]}
        >
          <Form className="project-form">
            <Form.Item
            {...formItemLayout}
            label="Project Name">
              {getFieldDecorator('project_name', {
                initialValue: this.props.value.name,
                rules: [{ required: true, message: 'Please input the Project Name!' }],
              })(
                <Input placeholder= "Project Name"/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Client">
              {getFieldDecorator('client', {
                initialValue: this.props.value.client_id ? (this.props.value.client_id).toString() : undefined,
                rules: [{ required: true, message: 'Please choose the Client!' }],
              })(
                <Select placeholder="Select Client" showAction={['click', 'focus']} showSearch optionFilterProp="children" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {this.props.clients}
                </Select>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Hours">
              {getFieldDecorator('estimated_hours', {
                initialValue: this.props.value.estimated_hours,
              })(
                <Input type="number" placeholder="Estimated Hours"/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Due date">
              {getFieldDecorator('due_date', {
                initialValue: (this.props.value && this.props.value.due_date) ? moment(this.props.value.due_date) : undefined,
              })(
                <DatePicker className="gx-w-100" placeholder="Due Date"/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Rate">
              {getFieldDecorator('rate', {
                initialValue: this.props.value.rate,
              })(
                <Input type="number" placeholder="Hourly Rate"/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Billable">
              {getFieldDecorator('billable', {
                valuePropName: 'checked',
                initialValue: this.props.value ? this.props.value.billable : false,
              })(
                <Checkbox />
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

class ProjectModal extends React.Component {

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onOk(values, this.props.value.id);
    });
  }

  render() {
    return (
      <div>
        <CollectionCreateForm
          title={this.props.title}
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          onCreate={this.handleCreate}
          value={this.props.value}
          clients={this.props.clients}
          confirmLoading={this.props.confirmLoading}
        />
      </div>
    );
  }
}

export default ProjectModal;
