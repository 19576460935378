import React, {Component} from "react";
import {connect} from "react-redux";
import {Row, Col} from "antd";
import Welcome from "./Welcome";
import Tasks from "./Tasks";
import LeaveRequests from './LeaveRequests';
import UpcomingEvents from './UpcomingEvents';
import TeamCalendar from "./TeamCalendar";
import CompanyEvents from "./CompanyEvents";
import Auxiliary from "util/Auxiliary";
import {API_URL_V1} from "../../../constants";
import _ from "lodash";
import axios from "axios";

class Dashboard extends Component {
    state = {
      confirmDirty: false,
      autoCompleteResult: [],
      designations: '',
    };

    difference = (object, base) => {
    	function changes(object, base) {
    		return _.transform(object, function(result, value, key) {
    			if (!_.isEqual(value, base[key])) {
    				result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
    			}
    		});
    	}
    	return changes(object, base);
    }

    componentDidMount () {
      if(this.props.currentCompany && this.props.authUser) {
        const {authUser, currentCompany} = this.props;
        axios.all([
          axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/designations`, {
            headers: {
              'Authorization': 'Bearer '+authUser
            }
          }),
        ])
        .then(axios.spread((designationData) => {
          this.setState({...this.state, designations: designationData.data.data, loading: false})
        }))
        .catch(error => error)
      }
    }

    componentWillReceiveProps(nextProps) {
      const change = this.difference(nextProps, this.props)
      if(Object.keys(change).length !== 0) {
        if(nextProps.currentCompany && nextProps.authUser) {
          const {authUser, currentCompany} = nextProps;
          axios.all([
            axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/designations`, {
              headers: {
                'Authorization': 'Bearer '+authUser
              }
            }),
          ])
          .then(axios.spread((designationData) => {
            this.setState({...this.state, designations: designationData.data.data, loading: false})
          }))
          .catch(error => error)
        }
      }
    }
    render() {
      return (
        <Auxiliary>
          <Row>
            {this.props.currentEmployee &&
              <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                <Welcome employeeData={this.props.currentEmployee} designations={this.state.designations}/>
              </Col>
            }
            {this.props.currentEmployee &&
              <Col xl={12} lg={24} md={24} sm={24} xs={24}>
                  <TeamCalendar />
              </Col>
            }
          </Row>
          <Row>
            {this.props.currentEmployee && this.props.currentCompany &&
            <Col xl={12} lg={24} md={24} sm={24} xs={24} className="gx-order-lg-1">
                <Tasks employee={this.props.currentEmployee} company={this.props.currentCompany}/>
            </Col>
            }
            {this.props.currentEmployee && this.props.currentCompany &&
            <Col xl={12} lg={24} md={24} sm={24} xs={24} className="gx-order-sm-2">
                <CompanyEvents employee={this.props.currentEmployee} company={this.props.currentCompany}/>
            </Col>
            }
          </Row>
          <Row>
            {this.props.currentEmployee &&
            <Col xl={12} lg={24} md={24} sm={24} xs={24} className="gx-order-lg-1">
                <LeaveRequests employee={this.props.currentEmployee}/>
            </Col>
            }
            {this.props.currentEmployee && this.props.currentCompany &&
            <Col xl={12} lg={24} md={24} sm={24} xs={24} className="gx-order-sm-2">
                <UpcomingEvents employee={this.props.currentEmployee} company={this.props.currentCompany}/>
            </Col>
            }
          </Row>
    </Auxiliary>
      );
    }
  }
  const mapStateToProps = ({auth, company}) => {
    const {authUser} = auth;
    const {currentEmployee, currentCompany} = company;
    return {authUser, currentEmployee, currentCompany}
  };
  export default connect(mapStateToProps)(Dashboard);
