import React, {Component} from "react";
import {Modal, Form, Input, Checkbox, DatePicker, TimePicker, Select, Button} from 'antd';
import moment from 'moment';
import Auxiliary from "../../../util/Auxiliary";

const {RangePicker} = DatePicker;
const {TextArea} = Input;
const Option = Select.Option;
const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends Component {
    state={
      type: this.props.value && this.props.value.end_date ? 'several_days' : 'single_day',
    };

    onChange = value => {
      this.setState({...this.state, type: value});
    }

    render() {
      const formItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 8},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 16},
        },
      };
      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      };
      const { getFieldDecorator } = this.props.form;
      return (
        <Modal
          visible={this.props.visible}
          title={this.props.title}
          onCancel={this.props.onCancel}
          onOk={this.props.onCreate}
          footer={[
            <Button className="add-event-form-button" key="submit" loading={this.props.loading} onClick={this.props.onCreate}>
              {this.props.title}
            </Button>,
            <Button key="cancel" onClick={this.props.onCancel}>
              Cancel
            </Button>
          ]}
        >
          <Form className="event-form">
            <Form.Item
            {...formItemLayout}
            label="Event Name">
              {getFieldDecorator('event_name', {
                initialValue: this.props.value.title,
                rules: [{ required: true, message: 'Please Enter the Event Name!' }],
              })(
                <Input placeholder= "Event Name"/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Description">
              {getFieldDecorator('description', {
                initialValue: this.props.value.description,
              })(
                <TextArea placeholder= "Description"/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Duration"
            >
              {getFieldDecorator('duration', {
                initialValue: this.state.type,
                rules: [{ required: true, message: 'Please Select the Duration!' }],
              })(
                <Select placeholder= "Please Select Duration" onChange={this.onChange} showAction={['click', 'focus']}>
                  <Option value="single_day">Single Day</Option>
                  <Option value="several_days">Several Days</Option>
                </Select>
              )}
            </Form.Item>
            {this.state.type === 'single_day' ?
            <Auxiliary>
            <Form.Item
            {...formItemLayout}
            label="Event Date">
              {getFieldDecorator('event_on', {
                initialValue: this.props.value.event_on ? moment(this.props.value.event_on) : undefined,
                rules: [{ required: true, message: 'Please Select the Event Date!' }],
              })(
                <DatePicker placeholder= "Event Date" className="gx-mb-3 gx-w-100"/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Event Time">
              {getFieldDecorator('event_time', {
                initialValue: this.props.value.event_time ? moment(this.props.value.event_time, 'HH:mm') : undefined,
                rules: [{ required: true, message: 'Please Select the Event Time!' }],
              })(
                <TimePicker placeholder= "Event Time" className="gx-mb-3 gx-w-100" defaultOpenValue={moment('00:00:00', 'HH:mm')} format={'HH:mm'}/>
              )}
            </Form.Item></Auxiliary> :
            <Form.Item
            {...formItemLayout}
            label="Date Range"
            >
              {getFieldDecorator('date_range', {
                initialValue: [this.props.value ? moment(this.props.value.event_on) : undefined, this.props.value && this.props.value.end_date ? moment(this.props.value.end_date) : undefined],
                rules: [{ required: true, message: 'Please Select the Date Range!' }],
              })(
                <RangePicker />
              )}
            </Form.Item>
            }
            <Form.Item
            {...formItemLayout}
            label="Location">
              {getFieldDecorator('location', {
                initialValue: this.props.value.location,
                rules: [{ required: true, message: 'Please Enter the location!' }],
              })(
                <Input placeholder= "Location for the Event"/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Office">
              {getFieldDecorator('location_id', {
                initialValue: this.props.value.location_id ? (this.props.value.location_id).toString() : undefined,
                rules: [{ required: true, message: 'Please select the Office!' }],
              })(
                <Select placeholder="Select Office" showAction={['click', 'focus']} showSearch optionFilterProp="children" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {this.props.offices}
                </Select>
              )}
            </Form.Item>
            <Form.Item
            {...tailFormItemLayout}>
              {getFieldDecorator('notify', {
              valuePropName: 'checked',
              initialValue: this.props.value ? this.props.value.notify : true,
              })(
                <Checkbox>Notify</Checkbox>
              )}
            </Form.Item>

          </Form>
        </Modal>
      );
    }
  }
);

class EventsModal extends React.Component {

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onOk(values, this.props.value.id);
    });
  }

  render() {
    return (
      <div>

        <CollectionCreateForm
          title={this.props.title}
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          onCreate={this.handleCreate}
          value={this.props.value}
          offices={this.props.offices}
          loading={this.props.loading}
        />

      </div>
    );
  }
}

export default EventsModal;
