import axios from "axios"
import {API_URL_V1} from "./constants";

const service = axios.create({
    baseURL: API_URL_V1,
});

function getToken(){
    return localStorage.getItem("user_id");
}

service.interceptors.request.use(
    config => {
        // Check if already have a token
        config.headers.authorization = "Bearer "+ getToken();
        return config
    },
    error => {
        console.log(error); // for debug
        Promise.reject(error)
    }
);

export default service;
