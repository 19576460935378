import React, {Component} from "react";
import {Modal, Form, Input, Select, Button} from 'antd';

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends Component {
    state = {
      q: null,
    };

    render() {

      const formItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 8},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 16},
        },
      };

      const { getFieldDecorator } = this.props.form;
      return (
        <Modal
          visible={this.props.visible}
          title={this.props.title}
          onCancel={this.props.onCancel}
          onOk={this.props.onCreate}
          footer={[
            <Button key="submit" loading={this.props.confirmLoading} onClick={this.props.onCreate}>
              {this.props.title}
            </Button>,
            <Button key="cancel" onClick={this.props.onCancel}>
              Cancel
            </Button>
          ]}
        >
          <Form >
            <Form.Item
            {...formItemLayout}
            label="Employees">
              {getFieldDecorator('employee_name', {
                initialValue: undefined,
                rules: [{ required: true, message: 'Please Select the Employee!' }],
              })(
                <Select placeholder="Select Employees" showAction={['click', 'focus']} showSearch optionFilterProp="children" onSearch={(input, option) => {this.props.setSearchQuery(input)}}>
                  {this.props.allEmployees}
                </Select>
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

class AddProjectEmployeesModal extends React.Component {

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onOk(values);
    });
  }

  render() {
    return (
      <div>
        <CollectionCreateForm
          title={this.props.title}
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          onCreate={this.handleCreate}
          allEmployees={this.props.employees}
          confirmLoading={this.props.confirmLoading}
          setSearchQuery={this.props.setSearchQuery}
        />
      </div>
    );
  }
}

export default AddProjectEmployeesModal;
