import React from "react";
import {Button, Checkbox, Form, Input, message, Popover} from "antd";
import {connect} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import languageData from "./Topbar/languageData";
import CustomScrollbars from "util/CustomScrollbars";
import {switchLanguage} from "../appRedux/actions/Setting";
import backgroundImage from "../assets/images/signIn.svg";
import {API_URL, API_URL_V1} from "../constants";
import axios from 'axios';
import {
  hideMessage,
  showAuthLoader,
  userSignIn,
  getRoles,
} from "appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";

const FormItem = Form.Item;

class SignIn extends React.Component {

  state={
    companyInfo: '',
    loading: true,
  };

  languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
            this.props.switchLanguage(language)
          }>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
            <span className="gx-language-text">{language.name}</span>
          </li>
        )}
      </ul>
    </CustomScrollbars>);

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.showAuthLoader();
        this.props.getRoles();
        this.props.userSignIn(values);
      }
    });
  };

  onClose = (e) => {
    e.preventDefault();
    this.props.hideMessage();
  }

  componentDidMount() {
    let urlParts = window.location.host.split('.');
    let subdomain = urlParts[0];
    if(!(subdomain === 'app' || subdomain === 'localhost:3000')) {
      axios.get(`${API_URL}/${subdomain}/v1/public/info`)
      .then(companyData => {
        this.setState({...this.state, companyInfo: companyData.data, loading: false});
      })
      .catch(error => {
        this.setState({...this.state, loading: false});
      })
    } else {
      this.setState({...this.state, loading: false});
    }
  }

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const {showMessage, loader, alertMessage, authUser} = this.props;
    const {locale} = this.props;
    if (authUser){
      return (
      <Redirect to={{
        pathname: '/'
      }}/>
    )
    }

    return (
      <div className="gx-app-login-wrap" style={{background: `url(${backgroundImage})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundColor: "#fff"}}>
        <div className="gx-app-login-container">
        <div className="gx-app-logo-content">
          <div className="gx-app-logo-wid">
          <div className="gx-app-logo gx-mb-2">
            {this.state.loading ? '' :
              <img height="30px" alt="logo" src={this.state.companyInfo ? API_URL_V1+this.state.companyInfo.logo : require("assets/images/logo-colored.png")}/>
            }
          </div>
            <h1 style={{color: "#444", fontSize: "22px"}}><IntlMessages id="app.userAuth.signIn"/></h1>
            <p><IntlMessages id="app.userAuth.bySigning"/></p>
            <p><IntlMessages id="app.userAuth.getAccount"/></p>
          </div>

        </div>
          <div className="gx-app-login-main-content">
            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">

                <FormItem>
                  {getFieldDecorator('email', {
                    rules: [{
                      required: true, type: 'email', message: 'The input is not valid E-mail!',
                    }],
                  })(
                    <Input placeholder="Email" size="large"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [{required: true, message: 'Please input your Password!'}],
                  })(
                    <Input type="password" placeholder="Password" size="large"/>
                  )}
                </FormItem>
                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit" block size="large">
                    <IntlMessages id="app.userAuth.signIn"/>
                  </Button>
                  <div style={{textAlign: "center"}}><IntlMessages id="app.userAuth.or"/></div> <div style={{textAlign: "center"}}><Link to="/signup"><IntlMessages
                  id="app.userAuth.signUp"/></Link></div>
                  <span style={{float: 'right'}}>
                  <Link to="/forgot-password">
                    <IntlMessages id="app.userAuth.forgotPassword"/>
                  </Link>
                  </span>
                </FormItem>
              </Form>
            </div>
            {loader ?
              <div className="gx-loader-view">
                <CircularProgress/>
              </div> : null}
            {showMessage ?
              message.error(alertMessage.toString()) : null}
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({auth, settings}) => {
  const {loader, alertMessage, showMessage, authUser} = auth;
  const {locale} = settings;
  return {loader, alertMessage, showMessage, authUser, locale}
};

export default connect(mapStateToProps, {
  userSignIn,
  getRoles,
  hideMessage,
  showAuthLoader,
  switchLanguage
})(WrappedNormalLoginForm);
