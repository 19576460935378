import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Col, Row, Tabs} from "antd";
import Auxiliary from "../../../util/Auxiliary";
import PersonelForm from "./PersonelForm";
import ProfileForm from "./ProfileForm";
import Contracts from "./Contracts";
import Documents from "../../yourCompany/employees/EmployeeProfile/EmployeeDocuments";
import Attendance from "./Attendance";
import Breadcrumbs from "components/Breadcrumbs/index";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../constants";
import axios from "axios";
import './Settings.less';

const TabPane = Tabs.TabPane;

class MyProfile extends Component {

  state = {
    tabkey: 'Help for Profile',
    formData: {},
    workspaceData: {},
    allEmployee: '',
    locations: '',
    leavePolicy: '',
    designationData: '',
    departmentData: '',
    countryData: '',
    loading: true,
  };

  callback = (key) => {
    this.setState({
      ...this.state,
      tabkey: 'Help for '+key,
    });
  };

  componentDidMount() {
    const {authUser, company} = this.props;
    const id = company.id;
    axios.all([
      axios.get(`${API_URL_V1}/me`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/workspaces/${id}/me`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/workspaces/${id}/employees`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/workspaces/${id}/locations`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/workspaces/${id}/leaves/policies`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/workspaces/${id}/designations`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/workspaces/${id}/departments`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/data/countries`),
   ])
   .then(axios.spread((formData, workspaceData, allEmployee, locations, leavePolicy, designationData, departmentData, countryData) => {
     this.setState({...this.state, formData: formData.data.data, workspaceData: workspaceData.data.data, allEmployee: allEmployee.data.data, locations: locations.data.data, leavePolicy: leavePolicy.data.data,
        designationData: designationData.data.data, departmentData: departmentData.data.data, countryData: countryData.data.data, loading: false});
   }))
   .catch(error => error);
  }

  render() {
    return(
      <Auxiliary>

        {this.state.loading ?
          <div className="gx-loader-view">
            <CircularProgress/>
          </div> :
        <div className="gx-profile-content">
          <Breadcrumbs />
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card className="gx-card" title="My Account">
                <Tabs onChange={this.callback} type="card">
                  <TabPane tab="Personal" key="personal">
                    <PersonelForm formData={this.state.formData} workspaceData={this.state.workspaceData} countries={this.state.countryData}/>
                  </TabPane>
                  <TabPane tab="Work" key="work">
                    <ProfileForm allEmployee={this.state.allEmployee} formData={this.state.formData} location={this.state.locations} leavePolicy={this.state.leavePolicy} workspaceData={this.state.workspaceData} department={this.state.departmentData}/>
                  </TabPane>
                  <TabPane tab="Contract" key="contract">
                    <Contracts designation={this.state.designationData} workspaceData={this.state.workspaceData}/>
                  </TabPane>
                  <TabPane tab="Documents" key="documents">
                    <Documents employee={this.state.workspaceData} workspaceData={this.props.currentEmployee}/>
                  </TabPane>
                  <TabPane tab="Attendance" key="attendance">
                    <Attendance employee={this.state.workspaceData} workspaceData={this.props.currentEmployee}/>
                  </TabPane>
                </Tabs>
              </Card>
            </Col>
          </Row>
        </div> }
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentEmployee} = company;
  return {authUser, currentEmployee}
};

export default connect(mapStateToProps)(MyProfile);
