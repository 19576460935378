import {
  SWITCH_COMPANY,
  SET_DEFAULT_COMPANY,
  GET_CURRENT_EMPLOYEE_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SET_CURRENT_TIMER,
  GET_CURRENT_TIMER_SUCCESS,
  GET_CURRENT_TIMER_FAILED,
  SET_TIMER_DESCRIPTION_REQUIRED,
  GET_COMPANY_LINKS_SUCCESS,
  SET_JOYRIDE,
  RESET_JOYRIDE,
  GET_EMPLOYEE_OPTION_SUCCESS,
  SET_EMPLOYEE_OPTION_SUCCESS,
  SET_ATTENDANCE_TASK
} from "constants/ActionTypes";

const INIT_STATE = {
  currentCompany: null,
  defaultCompany: localStorage.getItem('default_company_id'),
  currentEmployee: null,
  currentTimer: null,
  timerHitCount: 0,
  timer_desc_required: false,
  companyLinks: '',
  joyride: '',
  employeeOptions: null
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SWITCH_COMPANY: {
      return {
        ...state,
        currentCompany: action.payload,
        currentEmployee: action.payload.employee,
        timer_desc_required: action.payload.settings ? action.payload.settings.timer_desc_required : false,
      }
    }
    case SET_DEFAULT_COMPANY: {
    	localStorage.setItem('default_company_id', action.payload)
      return {
        ...state,
        defaultCompany: action.payload,
        timer_desc_required: action.payload.settings ? action.payload.settings.timer_desc_required : false,
      }
    }
    case GET_CURRENT_EMPLOYEE_SUCCESS: {
      return {
        ...state,
          currentEmployee: action.payload
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
          currentEmployee: null,
          currentCompany: null
      }
    }
    case SET_CURRENT_TIMER: {
      return {
        ...state,
          currentTimer: action.payload,
          timerHitCount: 1,
      }
    }
    case GET_CURRENT_TIMER_SUCCESS: {
      return {
        ...state,
        currentTimer: action.payload,
      }
    }
    case GET_CURRENT_TIMER_FAILED: {
      return {
        ...state,
        timerHitCount: 1,
      }
    }
    case GET_COMPANY_LINKS_SUCCESS: {
      return {
        ...state,
        companyLinks: action.payload,
      }
    }
    case SET_TIMER_DESCRIPTION_REQUIRED: {
      return {
        ...state,
        timer_desc_required: action.payload,
      }
    }
    case SET_JOYRIDE: {
      return {
        ...state,
        joyride: action.payload,
      }
    }
    case RESET_JOYRIDE: {
      return {
        ...state,
        joyride: '',
      }
    }
    case SET_ATTENDANCE_TASK: {
      const company_settings = state.currentCompany.settings;
      company_settings.enable_attendance_task = action.payload;
      return state;
    }
    case GET_EMPLOYEE_OPTION_SUCCESS: {
      return {
        ...state,
        employeeOptions: action.payload
      }
    }
    case SET_EMPLOYEE_OPTION_SUCCESS: {
      return {
        ...state,
        employeeOptions: null
      }
    }
    break;
    default:
      return state;
  }
}
