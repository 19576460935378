import React from "react";
import {Button, Checkbox, Form, Input, Popover} from "antd";
import {Link} from "react-router-dom";
import languageData from "./Topbar/languageData";
import CustomScrollbars from "util/CustomScrollbars";
import {switchLanguage} from "../appRedux/actions/Setting";
import ReCaptcha from './ReCaptcha'
import backgroundImage from "../assets/images/signIn.svg";

import {connect} from "react-redux";
import {
  hideMessage,
  hideSignUpMessage,
  showAuthLoader,
  userSignUp,
} from "appRedux/actions/Auth";

import IntlMessages from "util/IntlMessages";
import {message} from "antd/lib/index";
import CircularProgress from "components/CircularProgress/index";

const FormItem = Form.Item;

class SignUp extends React.Component {
  state = {
    token: null
  };
  languageMenu = () => (
    <CustomScrollbars className="gx-popover-lang-scroll">
      <ul className="gx-sub-popover">
        {languageData.map(language =>
          <li className="gx-media gx-pointer" key={JSON.stringify(language)} onClick={(e) =>
            this.props.switchLanguage(language)
          }>
            <i className={`flag flag-24 gx-mr-2 flag-${language.icon}`}/>
            <span className="gx-language-text">{language.name}</span>
          </li>
        )}
      </ul>
    </CustomScrollbars>);

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.showAuthLoader();
        this.props.userSignUp({...values, token:this.state.token});
      }
    });
  };

  componentDidUpdate() {
    if (this.props.showMessage && !this.props.status) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.showMessage && this.props.status) {
      setTimeout(() => {
        setTimeout(() => {
          this.props.history.push('/');
        }, 10000);
        this.props.hideSignUpMessage();
        this.props.history.push('/thank_you');
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }

  onLoadRecaptcha = () => {
    if (this.captchaDemo) {
      this.captchaDemo.reset();
    }
  }
  verifyCallback = recaptchaToken => {
    // Here you will get the final recaptchaToken!!!
    this.setState({...this.state, token: recaptchaToken});
    console.log(recaptchaToken, "<= your recaptcha token")
  }

  componentDidMount() {
    // loadReCaptcha();
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const {showMessage, loader, alertMessage, status} = this.props;
    const {locale} = this.props;

    return (
      <div className="gx-app-login-wrap" style={{background: `url(${backgroundImage})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundColor: "#fff"}}>
        <div className="gx-app-login-container">

          <div className="gx-app-login-main-content">
            <div className="gx-app-logo-content">
              <div className="gx-app-logo-wid">
                <div className="gx-app-logo gx-mb-2">
                  <img height="30px" alt="logo" src={require("assets/images/logo-colored.png")}/>
                </div>
                <h1 style={{color: "#444", fontSize: "22px"}}><IntlMessages id="app.userAuth.signUp"/></h1>
                <p><IntlMessages id="app.userAuth.bySigning"/><br/><b>No hidden costs, no credit card required.</b></p>
                <p><IntlMessages id="app.userAuth.getAccount"/></p>
              </div>
            </div>

            <div className="gx-app-login-content">
              <Form onSubmit={this.handleSubmit} className="gx-signup-form gx-form-row0">
                <FormItem>
                  {getFieldDecorator('firstName', {
                    rules: [{required: true, message: 'Please input your first name!'}],
                  })(
                    <Input placeholder="First Name" size="large"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('lastName', {
                    rules: [{required: true, message: 'Please input your last name!'}],
                  })(
                    <Input placeholder="Last Name" size="large"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('email', {
                    rules: [{
                      required: true, type: 'email', message: 'The input is not valid E-mail!',
                    }],
                  })(
                    <Input placeholder="Email" size="large"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [{required: true, message: 'Please input your Password!'}],
                  })(
                    <Input type="password" placeholder="Password" size="large"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('confirm_password', {
                    rules: [{required: true, message: 'Password does not match!'}],
                  })(
                    <Input type="password" placeholder="Confirm Password" size="large"/>
                  )}
                </FormItem>
                <ReCaptcha
                    ref={(el) => {this.captchaDemo = el;}}
                    size="normal"
                    data-theme="dark"
                    render="explicit"
                    sitekey="6Lccj6oUAAAAAI6zkI4HtUza5Kq_UdRsQzGtezy4"
                    onLoad={this.onLoadRecaptcha}
                    onSuccess={this.verifyCallback}
                />
                <FormItem>
                  {getFieldDecorator('remember', {
                    valuePropName: 'checked',
                    initialValue: true,
                  })(
                    <Checkbox><IntlMessages id="appModule.iAccept"/></Checkbox>
                  )}
                  <a href="https://www.cutehr.io/terms-and-conditions" target="_blank"><span className="gx-link gx-signup-form-forgot"><IntlMessages
                    id="appModule.termAndCondition"/></span></a>
                </FormItem>

                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit" block>
                    <IntlMessages id="app.userAuth.signUp"/>
                  </Button>
                  <div style={{textAlign: "center"}}><IntlMessages id="app.userAuth.or"/></div> <div style={{textAlign: "center"}}><Link to="/signin"><IntlMessages
                  id="app.userAuth.signIn"/></Link></div>
                </FormItem>
              </Form>
            </div>
            {loader &&
            <div className="gx-loader-view">
              <CircularProgress/>
            </div>
            }
            {showMessage && status &&
            message.success(alertMessage)}
            {showMessage && !status &&
            message.error(alertMessage)}
          </div>
        </div>
      </div>
    );
  }

}

const WrappedSignUpForm = Form.create()(SignUp);

const mapStateToProps = ({auth, settings}) => {
  const {loader, alertMessage, showMessage, authUser, status} = auth;
  const {locale} = settings;

  return {locale, loader, alertMessage, showMessage, authUser, status}
};

export default connect(mapStateToProps, {
  userSignUp,
  hideMessage,
  hideSignUpMessage,
  showAuthLoader,
  switchLanguage
})(WrappedSignUpForm);
