import React, {Component} from "react";
import axios from "axios";
import {connect} from "react-redux";
import {API_URL_V1} from '../../../constants';

import {
    Modal, Form, Input
} from 'antd';

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
    // eslint-disable-next-line
    class extends Component {
        render() {
            const formItemLayout = {
                labelCol: {
                    xs: {span: 24},
                    sm: {span: 8},
                },
                wrapperCol: {
                    xs: {span: 24},
                    sm: {span: 16},
                },
            };
            const {
                visible, onCancel, onCreate, form,
            } = this.props;
            const { getFieldDecorator } = form;
            return (
                <Modal
                    visible={visible}
                    title="Basic Information"
                    okText="Submit"
                    onCancel={onCancel}
                    onOk={onCreate}
                >
                    <Form >
                        <Form.Item
                            {...formItemLayout}
                            label="Company Name">
                            {getFieldDecorator('company_name', {
                                rules: [{ required: true, message: 'Please input the Company Name!' }],
                            })(
                                <Input placeholder= "Company Name"/>
                            )}
                        </Form.Item>
                    </Form>
                </Modal>
            );
        }
    }
);

class CreateWorkspace extends React.Component {
    handleCreate = () => {
        const form = this.formRef.props.form;
        const {authUser} = this.props;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }

            axios.post(`${API_URL_V1}/workspaces`, {
                    name: values.company_name
                },
                {
                    headers: {
                        'Authorization': 'Bearer '+authUser
                    }
                })
                .then(response => {this.props.onCreate(response.data.data)})
                .catch(error => {console.log(error)});
            form.resetFields();
        });
    }

    saveFormRef = (formRef) => {
        this.formRef = formRef;
    }

    render() {
        let {visible, handleCancel} = this.props;
        return (
            <div>
                {visible ?
                    <CollectionCreateForm
                        wrappedComponentRef={this.saveFormRef}
                        visible={visible}
                        onCancel={handleCancel}
                        onCreate={this.handleCreate}
                    /> : null
                }
            </div>
        );
    }
}
const mapStateToProps = ({auth}) => {
    const {authUser} = auth;
    return {authUser}
};

export default connect(mapStateToProps)(CreateWorkspace);
