import React, {Component} from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router';
import Form from "react-jsonschema-form";
import TerminateModal from "./TerminateModal";
import {Select, Divider, Button, message} from "antd";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../../constants";
import {Link} from "react-router-dom";
import axios from 'axios';

const Option = Select.Option;
let formData=[];
let workspace_id;
let managerOptions =[],
oid = '',
leavePolicyOptions=[],
officeOptions=[],
rolesOptions=[],
departmentOptions=[];

class EmployeeProfileForm extends Component {

  state={
    loading: true,
    terminateModal: false,
    title: '',
    confirmLoading: false,
    showTerminate: false,
    resendInvite: false,
    inviteLoading: false,
  };

  schema = {
    type: "object",
    required: ["email"],
    properties: {
      manager_id: {type: "string", title: "Manager"},
      email: {type: "string", title: "E-mail :"},
      timeOffManager: {type: "string", title: "Time-Off Manager"},
      oid: {type: "string", title: "Employee Id"},
      role_id: {type: "string", title: "Role"},
      office: {type: "string", title: "Office"},
      time_off_policy_id: {type: "string", title: "Time Off Policy"},
      department: {type: "string", title: "Department"},
    }
  };

  uiSchema = {
    manager_id: {"ui:widget": (props) => {
      return (
        <Select className="ant-select" showSearch disabled={!this.props.workspaceData.role.permissions.includes("edit_company")} placeholder="Manager Name" value={props.value} onChange={(value) => props.onChange(value)} optionFilterProp="children">
          {managerOptions}
        </Select>
      );
    }},
    email: {"ui:widget": (props) => {
      return (
        <input type="email" className="ant-input ant-input-disabled" disabled placeholder="E-mail" value={props.value} onChange={(event) => props.onChange(event.target.value)}/>
      );
    }},
    timeOffManager: {"ui:widget": (props) => {
      return (
        <Select className="ant-select" showSearch disabled={!this.props.workspaceData.role.permissions.includes("edit_company")} placeholder="Manager Name" value={props.value} onChange={(value) => props.onChange(value)} optionFilterProp="children">
          {managerOptions}
        </Select>
      );
    }},
    oid: {"ui:widget": (props) => {
      return (
        <input type="text" className={!(this.props.workspaceData.role.permissions.includes("edit_company") || this.props.workspaceData.role.permissions.includes("edit_manager")) ? "ant-input-disabled ant-input" : "ant-input"} placeholder="Employee Id" disabled={!this.props.workspaceData.role.permissions.includes("edit_company")} value={props.value} onChange={(event) => props.onChange(event.target.value)}/>
      );
    }},
    role_id: {"ui:widget": (props) => {
      return (
        <Select className="ant-select" disabled={!this.props.workspaceData.role.permissions.includes("edit_company")} placeholder="Select..." value={props.value} onChange={(value) => props.onChange(value)}>
          {rolesOptions}
        </Select>
      );
    }},
    office: {"ui:widget": (props) => {
      return (
        <Select className="ant-select" disabled={!this.props.workspaceData.role.permissions.includes("edit_company")} placeholder="Select..." value={props.value} onChange={(value) => props.onChange(value)}>
          {officeOptions}
        </Select>
      );
    }},
    time_off_policy_id: {"ui:widget": (props) => {
      return (
        <Select className="ant-select" disabled={!this.props.workspaceData.role.permissions.includes("edit_company")} placeholder="Select Policy" value={props.value} onChange={(value) => props.onChange(value)}>
          {leavePolicyOptions}
        </Select>
      );
    }},
    department: {"ui:widget": (props) => {
      return (
        <Select className="ant-select" showSearch disabled={!this.props.workspaceData.role.permissions.includes("edit_company")} placeholder="Select Department" value={props.value} onChange={(value) => props.onChange(value)} optionFilterProp="children">
          {departmentOptions}
        </Select>
      );
    }},
  };

  CustomFieldTemplate = (props) => {
    return props.displayLabel
      ? <div className="ant-row ant-form-item">
          {props.required ?
          <label htmlFor={props.id} className="ant-form-item-label ant-form-item-required ant-col-xs-24 ant-col-sm-8">
            {props.label}
          </label> :
          <label htmlFor={props.id} className="ant-form-item-label ant-col-xs-24 ant-col-sm-8">
            {props.label}
          </label>}
          <div className="ant-form-item-control-wrapper ant-col-xs-24 ant-col-sm-16">
            {props.description}
            {props.children}
            {props.errors}
            {props.help}
          </div>
        </div>
      : <div className={props.classNames}>
          {props.children}
        </div>;
  };

  componentDidMount() {
    departmentOptions=[];
    rolesOptions=[];
    workspace_id = localStorage.getItem('id');
    managerOptions =[]; leavePolicyOptions=[]; officeOptions=[];
    for(let i = 0; i < (this.props.allEmployee).length; i++) {
      let name = this.props.allEmployee[i].first_name +' '+this.props.allEmployee[i].last_name;
      managerOptions.push(<Option value={(this.props.allEmployee[i].id).toString()}>{name}</Option>);
    }
    for(let i = 0; i < (this.props.leavePolicy).length; i++) {
      leavePolicyOptions.push(<Option value={(this.props.leavePolicy[i].id).toString()}>{this.props.leavePolicy[i].name}</Option>);
    }
    for(let i = 0; i < (this.props.locations).length; i++) {
      officeOptions.push(<Option value={(this.props.locations[i].id).toString()}>{this.props.locations[i].name}</Option>);
    }
    for(let i = 0; i < (this.props.roles).length; i++) {
      rolesOptions.push(<Option value={(this.props.roles[i].id).toString()}>{this.props.roles[i].name}</Option>);
    }
    if(departmentOptions.length ==0) {
      for(let i = 0; i< (this.props.department).length; i++) {
        departmentOptions.push(<Option value={(this.props.department[i].id).toString()}>{this.props.department[i].name}</Option>);
      }
    }
    oid = this.props.employee.oid;
    console.log(this.props.locations)
    this.setState({...this.state, resendInvite: this.props.employee.user_id ? false : true, loading: false})
  }

  onSubmit = (values) => {
    console.log(values.formData.oid)
    const {authUser} = this.props;
    axios.all([
      axios.put(`${API_URL_V1}/workspaces/${workspace_id}/employees/${this.props.employee.id}`, {
        manager_id: values.formData.manager_id,
        time_off_manager_id: values.formData.timeOffManager,
        time_off_policy_id: values.formData.time_off_policy_id,
        location_id: values.formData.office,
        department_id: values.formData.department,
        role_id: values.formData.role_id,
        ...((oid && oid == values.formData.oid) ? {} : {oid: values.formData.oid}),
      }, {
        headers: {
          'Authorization': 'Bearer '+authUser,
        }
      }),
      axios.put(`${API_URL_V1}/workspaces/${workspace_id}/locations/${values.formData.office}`, {
        location_id: values.formData.office,
      }, {
        headers: {
          'Authorization': 'Bearer '+authUser,
        }
      })
    ])
    .then(axios.spread((employeeData, officeData) => {
      oid = employeeData.data.data.oid;
      message.success("Information Updated");
    }))
    .catch(error => {
      let display = '';
      if(error.response.data.errors.oid) {
        display = (error.response.data.errors.oid).toString();
      } else {
        display = error.response.data.message;
      }
      message.error(display)
    })
  }

  onOk = (values) => {
    const {authUser} = this.props;
    this.setState({...this.state, confirmLoading: true});
    axios.post(`${API_URL_V1}/employees/${this.props.employee.id}/terminate`, {
      terminated_on: (values.date).format("YYYY-MM-DD"),
    }, {
      headers: {
        'Authorization': 'Bearer '+authUser,
      }
    })
    .then(employeeData => {
      this.setState({...this.state, confirmLoading: false, terminateModal: false},() => {this.props.history.push('/employees')})
    })
    .catch(error => error);
  }

  resendInvite = () => {
    this.setState({...this.state, inviteLoading: true});
    const {authUser} = this.props;
    axios.post(`${API_URL_V1}/employees/${this.props.employee.id}/invite`, {}, {
      headers: {
        'Authorization': 'Bearer '+authUser,
      }
    })
    .then(inviteData => {
      this.setState({...this.state, inviteLoading: false});
      message.success("Invite Sent Successfully");
    })
    .catch(error => {
      this.setState({...this.state, inviteLoading: false});
      message.error("Failed to Send Invite.")
    })
  }

  render() {
    formData = {
      email: this.props.employee.email,
      manager_id: this.props.employee.manager_id ? (this.props.employee.manager_id).toString() : undefined,
      time_off_policy_id: this.props.employee.time_off_policy_id ? (this.props.employee.time_off_policy_id).toString() : undefined,
      timeOffManager: this.props.employee.time_off_manager_id ? (this.props.employee.time_off_manager_id).toString() : undefined,
      office: this.props.employee.location_id ? (this.props.employee.location_id).toString() : undefined,
      department: this.props.employee.department_id ? (this.props.employee.department_id).toString() : undefined,
      oid: oid ? oid : '',
      role_id: this.props.employee.role_id ? (this.props.employee.role_id).toString() : undefined,
    };
    return (
      <div>
        {this.state.terminateModal ?
        <TerminateModal
          visible={this.state.terminateModal}
          title={this.state.title}
          onCancel={(e) => {this.setState({...this.state, terminateModal: false})}}
          onOk={this.onOk}
          confirmLoading={this.state.confirmLoading}
        /> : null}
        <div>
          {this.state.loading ?
            <CircularProgress/> :
          <div>
          <Form
            schema={this.schema}
            uiSchema={this.uiSchema}
            formData={formData}
            onSubmit={this.onSubmit}
            FieldTemplate={this.CustomFieldTemplate}
            showErrorList={false} >
            <div className="ant-row ant-form-item">
              <div className="ant-col-xs-24 ant-col-sm-8"></div>
              <div className="ant-col-xs-24 ant-col-sm-16">
                <button type="submit" disabled={!this.props.workspaceData.role.permissions.includes("edit_company")} className="ant-btn btn-primary">Save</button>
                {this.state.resendInvite ?
                <Button type="submit" loading={this.state.inviteLoading} onClick={(e) => {e.preventDefault(); this.resendInvite(e)}} disabled={!this.props.workspaceData.role.permissions.includes("edit_company")} className="ant-btn ant-btn-primary">Resend Invite</Button> : null}
              </div>
            </div>
          </Form>
          <div className="ant-row ant-form-item">
            <div className="ant-col-xs-24 ant-col-sm-8"></div>
            <div className="ant-col-xs-24 ant-col-sm-16">
              <span className="gx-link" onClick={(e) => {this.setState({...this.state, showTerminate: !this.state.showTerminate})}}>{this.state.showTerminate ? "Hide Advance Option" : "Show Advance Option"}</span>
            </div>
          </div>
          </div>}
          {!(this.props.workspaceData.role.permissions.includes("edit_company") && this.state.showTerminate) ? null :
          <div className="ant-row ant-form-item">
          <Divider/>
            <div className="ant-col-xs-24 ant-col-sm-8"></div>
            <div className="ant-col-xs-24 ant-col-sm-16">
                <Button type="danger" onClick={(e) => {this.setState({...this.state, terminateModal: true, title: this.props.employee.first_name+" "+this.props.employee.last_name})}}>Terminate {this.props.employee.first_name+ " "+this.props.employee.last_name}</Button>
              </div>
            </div>
          }
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({auth}) => {
  const {authUser, roles} = auth;
  return {authUser, roles}
};

export default withRouter(connect(mapStateToProps)(EmployeeProfileForm));
