import React, {Component} from "react";
import {connect} from "react-redux";
import InvoiceForm from "./InvoiceForm";
import {Card, Button, Row, Col, Table, Input, Select, Divider, Modal, message} from "antd";
import Auxiliary from "../../../util/Auxiliary";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../constants";
import _ from "lodash";
import moment from "moment";
import axios from "axios";

const Search = Input.Search;
const {Option} = Select;

class InvoiceTable extends Component {
  state={
    invoices: [],
    showForm: false,
    editInvoice: '',
    client_id: '',
    pagination: {current: 1, pageSize: 15, total: 0},
    q: null,
    deleteVisible: false,
    deleteLoading: false,
    showSendModal: false,
    isEmailSet: false,
    sendLoading: false,
    loading: true,
  }

  getInvoices = () => {
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/invoices`, {
      params: {
        page: this.state.pagination.current, per_page: this.state.pagination.pageSize,
        "filter[invoice_number]" : this.state.q,
        "filter[client_id]" : this.state.client_id,
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(invoicesData => {
      const pagination = { ...this.state.pagination };
      pagination.pageSize = 15;
      pagination.total =invoicesData.data.total;
      this.setState({...this.state, invoices: invoicesData.data.data, pagination: pagination, loading: false})
    })
    .catch(error => error)
  }

  componentDidMount() {
    this.getInvoices();
  }

  setSearchQuery = _.debounce((value) => {
    this.setState({...this.state, pagination: {...this.state.pagination, current: 1}, q: value}, () => {this.getInvoices()})
  }, 500)

  onClick = () => {
    this.setState({...this.state, showForm: true, editInvoice: ''});
  }

  handleTableChange = (paginationData) => {
    this.setState({...this.state, pagination: paginationData}, () => {this.getInvoices()});
  }

  edit = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, showForm: true, editInvoice: record});
  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser, currentCompany} = this.props;
    axios.delete(`${API_URL_V1}/workspaces/${currentCompany.id}/invoices/${record.id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(eventsData => {
      message.success("Invoice Deleted Successfully");
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      this.getInvoices();
    })
    .catch(error => error)
  }

  onFilterClient = (value) => {
    console.log(value)
    this.setState({...this.state, client_id: value},() => {this.getInvoices()});
  }

  sendEmail = (record) => {
    this.setState({...this.state, sendLoading: true});
    const {authUser} = this.props;
    axios.post(`${API_URL_V1}/invoices/${record.id}/email`, {
    },{
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(previewData => {
      this.getInvoices();
      message.success("Email has been sent");
      this.setState({...this.state, showSendModal: false, sendLoading: false});
    })
    .catch(error => {
      message.error("Something went wrong. Please try again.")
    })
  }

  render() {
    const {currentEmployee} = this.props;
    let canManage = currentEmployee && currentEmployee.role && (currentEmployee.role.permissions.includes("edit_company") || currentEmployee.role.permissions.includes("manage_company"));
    const columns = [{
        title: 'Invoice #',
        render: (text, record) => (
          <span>
            {record.invoice_number}
          </span>
        )
      }, {
        title: 'Client',
        render: (text, record) => (
          this.props.allClients.map((client, index) => {
            if(client.id === record.client_id) {
              return client.name
            }
          })
        )
      }, {
        title: 'Issue Date',
        render: (text, record) => (
          <span>
            {record.invoice_date}
          </span>
        )
      }, {
        title: 'Days Old',
        render: (text, record) => (
          <span>
            {moment().diff(moment(record.invoice_date), 'days')}
          </span>
        )
      }, {
        title: 'Total',
        render: (text, record) => (
          <span>
            {record.amount}
          </span>
        )
      }, {
        title: 'Paid',
        render: (text, record) => (
          <span>
            {record.amount - record.balance}
          </span>
        )
      }, {
        title: 'Status',
        render: (text, record) => (
          <span>
            {
              record.invoice_status_id === 1 ? "Draft" : record.invoice_status_id === 2 ? "Sent" : record.invoice_status_id === 3 ? "Viewed" : record.invoice_status_id === 4 ? "Approved" :
              record.invoice_status_id === 5 ? "Partial" : record.invoice_status_id === 6 ? "Paid" : record.invoice_status_id === -1 ? "Overdue" : record.invoice_status_id === -2 ? "Unpaid" : ""
            }
          </span>
        )
      }, {
        title: 'Actions',
        key: 'x',
        render: (text, record) => {
          let email = '';
          this.props.allClients.map((client, index) => {
            if(client.id === record.client_id) {
              email = client.contact_email;
            }
          })
          return(
          <span>
            {canManage ?
            <span className="gx-link" onClick={(e) => {this.edit(record, e)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span> : <span></span>}

            {canManage ?
                <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><Divider type="vertical"/><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span> :
                <span></span>
            }

            {canManage ?
                <span className="gx-link" onClick={(e) => {this.setState({...this.state, showSendModal: true, isEmailSet: email, record: record})}}><Divider type="vertical"/><i className="icon icon-sent" title="Send Email" style={{verticalAlign: "middle"}}/></span> :
                <span></span>
            }
          </span>
        )}
      }];
    return (
      <Auxiliary>
        <Modal
          visible={this.state.showSendModal}
          title="Send Email"
          onOk={(e) => {this.state.isEmailSet ? this.sendEmail(this.state.record) : this.setState({...this.state, showSendModal: false})}}
          onCancel={(e) => {this.setState({...this.state, showSendModal: false, isEmailSet: false})}}
          confirmLoading={this.state.sendLoading}
          footer={[
            <Button key="submit" loading={this.state.sendLoading} onClick={(e) => {this.state.isEmailSet ? this.sendEmail(this.state.record) : this.setState({...this.state, showSendModal: false})}}>
              OK
            </Button>,
            <Button key="cancel" onClick={(e) => {this.setState({...this.state, showSendModal: false})}}>
              Cancel
            </Button>
          ]}
        >
          {this.state.isEmailSet ?
            <p>Click "OK" button to send email to client</p> :
            <div>
              <p>Client Email not set.</p>
              <p>Please go to "Clients" menu and set the email for the client.</p>
            </div>
          }
        </Modal>
        <Modal
          visible={this.state.deleteVisible}
          title="Delete Invoice?"
          onOk={(e) => this.deleteRecord(this.state.record, e)}
          onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
          confirmLoading={this.state.deleteLoading}
          footer={[
            <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
              OK
            </Button>,
            <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
              Cancel
            </Button>
          ]}
        >
          <p>Are you Sure you want to delete this Invoice?</p>
        </Modal>
        <div className="gx-profile-content" id="profile-content">
          {this.state.showForm ?
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <InvoiceForm onBack={() => {this.setState({...this.state, showForm: false},() => {this.getInvoices()})}} clients={this.props.clients} editInvoice={this.state.editInvoice}/>
              </Col>
            </Row>
            :
            <div>
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <span style={{marginBottom: "4.2rem", marginRight: "1rem"}}>
                  <Search
                    className = "employee-search"
                    placeholder="Search Invoice #"
                    defaultValue={this.state.q}
                    onChange={(e) => {this.setSearchQuery(e.target.value)}}
                    style={{width: 150}}
                  />
                </span>
                <span>
                  <Select placeholder="Select Client" style={{ width: 150 }} onChange={this.onFilterClient}>
                    <Option value=''>All Clients</Option>
                    {this.props.clients}
                  </Select>
                </span>
                { canManage && <div style={{display: "inline", marginBottom: "4.2rem"}}>
                  <span style={{float: 'right'}}>
                    <Button style={{ verticalAlign: "baseline", marginLeft: "15px" }} disabled={!canManage} onClick={this.onClick} value="Add">Create Invoice</Button>
                  </span>
                </div> }
                </Col>
              </Row>
              {this.state.loading ?
              <CircularProgress height="400px"/> :
              <Table className="gx-table-responsive" columns={columns} dataSource={this.state.invoices} pagination={this.state.pagination} onChange={this.handleTableChange}/>}
            </div>
          }

        </div>
      </Auxiliary>
    )
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, currentCompany, currentEmployee}
};

export default connect(mapStateToProps)(InvoiceTable);
