import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {Card, Button, Form, Input, Switch, Select, Row, Col, Upload, Icon, Avatar, message} from "antd";
import Auxiliary from "../../../util/Auxiliary";
import Breadcrumbs from "components/Breadcrumbs/index";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../constants";
import axios from "axios";

const {TextArea} = Input;
const { Option } = Select;
let fileDownload = require('js-file-download');

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

class CandidateDetail extends Component {
  state={
    formFields: [],
    formDetails: [],
    candidate: (this.props.location && this.props.location.state && this.props.location.state.candidate) ? this.props.location.state.candidate : '',
    name: (this.props.location && this.props.location.state && this.props.location.state.value) ? this.props.location.state.value.title : '',
    resume: [],
    loading: true,
  };

  componentDidMount () {
    this.setState({...this.state, loading: false});
    const {authUser} = this.props;
    const {id} = this.props.location.state.candidate;
    axios.all([
      axios.get(`${API_URL_V1}/ats/openings/${this.props.location.state.value.id}/form-fields`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/ats/openings/${this.props.location.state.value.id}/candidates/${id}/resume`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
    ])
    .then(axios.spread((formFieldsData, resumeData) => {
      let formFields = [];
      formFieldsData.data.forEach((formField, index) => {
        let formData = formField;
        this.props.location.state.candidate.profile_data.forEach((data, index) => {
          if(data.name === formField.name) {
            formData.value = data.value;
          }
        })
        formFields.push(formData);
      })
      console.log(resumeData);
      this.setState({...this.state, formDetails: formFields, resume: resumeData.data, loading: false});
    }))
    .catch(error => error)
  }

  downloadResume = (e) => {
    e.preventDefault();
    const {id} = this.props.location.state.candidate;
    const {authUser} = this.props;
    axios.get(`${API_URL_V1}/ats/openings/${this.props.location.state.value.id}/candidates/${id}/resume`, {
      responseType: 'blob',
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(resumeData => {
      let type = resumeData.headers["content-type"].split(" ");
      console.log(type)
      let ext = '', type1='';
      if(type[0] === "text/plain;") {
        ext = "txt";
      } else if(type[0] === "text/html;") {
        ext = "html";
      } else if(type[0] === "application/msword" || type[0] === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || type[0] === "application/doc" || type[0] === "application/ms-doc") {
        ext = "docx";
      } else {
        type1 = type[0].split("/");
        ext = type1[1];
      }
      console.log(resumeData.data)
      fileDownload(resumeData.data, `resume.${ext}`);
      // let filename = `resume.${ext}`;
      // let element = document.createElement('a');
      // element.setAttribute('href', `data:${resumeData.headers["content-type"]},` + encodeURIComponent(resumeData.data));
      // element.setAttribute('download', filename);
      //
      // element.style.display = 'none';
      // document.body.appendChild(element);
      //
      // element.click();
      //
      // document.body.removeChild(element);
    })
    .catch(error => error)
  }

  sendMessage = (e) => {
    e.preventDefault();
    this.props.history.push({pathname: `/ats/${this.props.location.state.value.id}/detail/candidate_detail/${this.state.candidate.id}/messaging`, state: {candidate: this.state.candidate, opening: this.props.location.state.value}});
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { fileList } = this.state;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
      },
    };
    const inlineItemLayout = {
      labelCol: {
        xs: {span: 24},
        sm: {span: 8},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 16},
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 20,
          offset: 4,
        },
      },
    };
    return (
      <Auxiliary>
        <Breadcrumbs />
        {this.state.loading ? <CircularProgress /> :
          <Card className="gx-opening-card" title={`Applied for ${this.state.name}`}>
            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
              <Row gutter={24}>
                <Col span={12} style={{ display:'block'}}>
                  <Form.Item
                  {...inlineItemLayout}
                  label="First Name">
                    {getFieldDecorator('first_name', {
                      initialValue: this.state.candidate ? this.state.candidate.first_name : '',
                    })(
                      <Input placeholder= "First Name" disabled/>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12} style={{ display:'block'}}>
                  <Form.Item
                  {...inlineItemLayout}
                  label="Last Name">
                    {getFieldDecorator('last_name', {
                      initialValue: this.state.candidate ? this.state.candidate.last_name : '',
                    })(
                      <Input placeholder= "Last Name" disabled/>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12} style={{ display:'block'}}>
                  <Form.Item
                  {...inlineItemLayout}
                  label="Email Address">
                    {getFieldDecorator('email', {
                      initialValue: this.state.candidate ? this.state.candidate.email : '',
                    })(
                      <Input placeholder= "Email Address" disabled/>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12} style={{ display:'block'}}>
                  <Form.Item
                  {...inlineItemLayout}
                  label="Phone No.">
                    {getFieldDecorator('phone', {
                      initialValue: this.state.candidate ? this.state.candidate.phone : '',
                    })(
                      <Input placeholder= "Phone Number" disabled/>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12} style={{ display:'block'}}>
                  <Form.Item
                  {...inlineItemLayout}
                  label="Gender">
                    {getFieldDecorator('gender', {
                      initialValue: this.state.candidate ? this.state.candidate.gender.toString() : '',
                    })(
                      <Select placeholder= "Select Gender" showAction={['click', 'focus']} disabled>
                        <Option value="male">Male</Option>
                        <Option value="female">Female</Option>
                        <Option value="other">Other</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              {this.state.formDetails.map((formDetail, index) =>
               <Form.Item label={formDetail.display_name}>
                 {getFieldDecorator(`${formDetail.name}`, {
                   initialValue: formDetail.value ? formDetail.type === "select" ? formDetail.value.toString() : formDetail.value : undefined,
                 })(
                    formDetail.type === 'text' ?
                    <Input placeholder="Not provided" disabled/>
                    : formDetail.type === 'textarea' ?
                    <TextArea placeholder="Not provided" disabled/>
                    : formDetail.type === "switch" ?
                    <Switch disabled/>
                    : formDetail.type === "select" ?
                    <Select placeholder="Not provided" disabled>
                    {formDetail.choices.map((choice, index) => (
                      <Option value={choice}>{choice}</Option>
                    ))}
                    </Select>
                    : <Select placeholder="Not provided" mode="multiple" disabled>
                        {formDetail.choices.map((choice, index) => (
                          <Option value={choice}>{choice}</Option>
                        ))}
                      </Select>
                 )}
               </Form.Item>
             )}
             <Form.Item {...formItemLayout} label="Resume">
               <Button type="primary" onClick={(e) => {this.downloadResume(e)}} download>
                 Download Resume
               </Button>
             </Form.Item>
             <Form.Item {...tailFormItemLayout} label="">
               <Button type="primary" onClick={(e) => {this.sendMessage(e)}}>
                 Message Candidate
               </Button>
             </Form.Item>
            </Form>
          </Card>
        }
      </Auxiliary>
    )
  }
}

const WrappedCandidateDetail = Form.create()(CandidateDetail);

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, currentCompany, currentEmployee}
};

export default withRouter(connect(mapStateToProps)(WrappedCandidateDetail));
