import React from "react";
import {Bar, BarChart, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import moment from "moment";

function CustomTooltip({ payload, label, active, resolution }) {
  if (active) {
    return (
      <div>
      {payload[0].payload.seconds ?
        <div className="custom-tooltip" style={{backgroundColor: "#ffffff", borderRadius: "8px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.3)"}}>
          <div style={{margin: "5%"}}>
            <p className="day" style={{fontWeight: "bold"}}>{resolution === 'day' ? moment(payload[0].payload.label).format("dddd, Do MMM") : resolution === 'week' ? moment((payload[0].payload.label).split('/').join('-'), "YYYY-WW").format("YYYY-Wo") : resolution === 'month' ? moment((payload[0].payload.label).split('/').join('-')).format("MMM-YYYY") : payload[0].payload.label}</p>
            <p className="label">Total : {Math.floor(moment.duration(payload[0].payload.seconds*1000).asHours()) + moment.utc(payload[0].payload.seconds*1000).format(":mm:ss")}</p>
          </div>
        </div> : ''}
      </div>
    );
  }

  return null;
}

const tickDayFormatter = label => {
  return (
    moment(label).format('ddd,Do MMM')
  );
};

const tickWeekFormatter = label => {
  let newLabel = label.split('/').join('-');
  return (
    moment(newLabel, "YYYY-WW").format('YYYY/Wo')
  );
};

const tickMonthFormatter = label => {
  let newLabel = label.split('/').join('-');
  return (
    moment(newLabel).format('MMM-YYYY')
  );
};

const ReportChart = (data) => {
  let resolution = data.resolution;
  data.data.forEach((data, index) => {
    data.hours = data.seconds/(3600);
  })

  const formatter = (value) => `${value}h`;
  return (
    <ResponsiveContainer width="100%" height={200}>
      <BarChart data={data.data}
                margin={{top: 10, right: 0, left: -15, bottom: 0}}>
        <XAxis dataKey="label" tickFormatter={resolution === 'day' ? tickDayFormatter : resolution === 'week' ? tickWeekFormatter : resolution === 'month' ? tickMonthFormatter : ''} axisLine={false}/>
        <YAxis type="number" tickFormatter={formatter}/>
        <CartesianGrid strokeDasharray="3 3"/>
        <Tooltip content={<CustomTooltip resolution={resolution}/>}/>
        <Bar type="monotone" dataKey="hours" barSize={50} fill="#6accf9"/>
      </BarChart>
    </ResponsiveContainer>
  )
};

export default ReportChart;
