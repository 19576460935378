import {all, call, fork, put, takeEvery, select} from "redux-saga/effects";
import { push } from 'react-router-redux';
import {
  SWITCH_COMPANY,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_TIMER,
  GET_COMPANY_LINKS,
  GET_EMPLOYEE_OPTION,
  SET_EMPLOYEE_OPTION
} from "constants/ActionTypes";

import request from "../../requests";
import {API_URL_V1} from '../../constants';
import {showAuthMessage, getCurrentEmployeeComplete, switchCompany, getCurrentTimerSuccess, getCurrentTimerFailed, getCompanyLinksSuccess, getEmployeeOptionsSuccess, setEmployeeOptionsSuccess} from "../actions";

const getCompanyId = (state) => state.company.defaultCompany;


const getCurrentEmployee = async (workspace) =>
    await  request.get(`${API_URL_V1}/workspaces/${workspace.id}/me`)
        .then(response => {
        console.log(response.data)
          return response.data;
        });

const getWorkspace = async (id) =>
    await  request.get(`${API_URL_V1}/workspaces/${id}`)
        .then(response => response.data);

const getCurrentTimer = async (employee) =>
    await request.get(`${API_URL_V1}/workspaces/${localStorage.getItem('id')}/time_entries`,{
      employee_id: employee.id,
    })
        .then(response => response.data)

const getCompanyLinks = async (companyId) =>
    await request.get(`${API_URL_V1}/workspaces/${companyId}/settings`,{
    })
        .then(response => response.data.data.links)

const getEmployeeOptionsRequest = async (employeeId) =>
    await request.get(`${API_URL_V1}/employees/${employeeId}/options`,{
    })
        .then(response => response.data)

const setEmployeeOptionsRequest = async (employeeId) =>
    await request.post(`${API_URL_V1}/employees/${employeeId}/options`,{
      name: "is_new",
      value: "0",
    })
        .then(response => response.data)

function* getDefaultWorkspace({payload}) {
  try {
  	const id = yield select(getCompanyId);
  	if (id){
  		const workspaceResponse= yield call(getWorkspace, id);
	    console.log(workspaceResponse);
	    if (workspaceResponse.data) {
	      yield put(switchCompany(workspaceResponse.data));
	    } else {
	      yield put(showAuthMessage('Invalid Data, Contact sys admin!'));
	    }
  	} else {
  		yield put(push('/workspaces'));
  	}

  } catch (error) {
    console.log(error);
    yield put(push('/workspaces'));
  }
}

function* getCurrentUserWithWorkspace({payload}) {
  try {
    const currentEmployeeResponse= yield call(getCurrentEmployee, payload);
    // console.log(currentEmployeeResponse);
    if (currentEmployeeResponse.data) {
      yield put(getCurrentEmployeeComplete(currentEmployeeResponse.data));
    } else {
      yield put(showAuthMessage('Invalid Data'));
    }
  } catch (error) {
    console.log(error);
  }
}

function* getActiveTimer({payload}) {
  try {
    const currentTimerResponse = yield call(getCurrentTimer, payload);
    let activeEntry = currentTimerResponse.data.find(entry => ! entry.end)
    if(activeEntry) {
      yield put(getCurrentTimerSuccess(activeEntry))
    } else {
      yield put(getCurrentTimerFailed())
    }
  } catch (error) {
    console.log(error)
  }
}

function* getActiveCompanyLinks({payload}) {
  try {
    const companyLinksResponse = yield call(getCompanyLinks, payload);
    yield put(getCompanyLinksSuccess(companyLinksResponse))
  } catch (error) {
    console.log(error)
  }
}

function* getCurrentEmployeeOption({payload}) {
  try {
    const employeeOptionResponse = yield call(getEmployeeOptionsRequest, payload);
    yield put(getEmployeeOptionsSuccess(employeeOptionResponse))
  } catch (error) {
    console.log(error)
  }
}

function* setCurrentEmployeeOption({payload}) {
  try {
    const employeeOptionSetResponse = yield call(setEmployeeOptionsRequest, payload);
    yield put(setEmployeeOptionsSuccess())
  } catch (error) {
    console.log(error)
  }
}

export function* currentUserListener() {
  yield takeEvery(GET_CURRENT_USER_SUCCESS, getDefaultWorkspace);
}

export function* currentEmployeeListener() {
  yield takeEvery(SWITCH_COMPANY, getCurrentUserWithWorkspace);
}

export function* currentTimerListener() {
  yield takeEvery(GET_CURRENT_TIMER, getActiveTimer);
}

export function* companyLinksListener() {
  yield takeEvery(GET_COMPANY_LINKS, getActiveCompanyLinks);
}

export function* employeeOptionsListener() {
  yield takeEvery(GET_EMPLOYEE_OPTION, getCurrentEmployeeOption);
}

export function* employeeOptionsSetterListener() {
  yield takeEvery(SET_EMPLOYEE_OPTION, setCurrentEmployeeOption);
}

export default function* rootSaga() {
  yield all([
      fork(currentEmployeeListener),
      fork(currentUserListener),
      fork(currentTimerListener),
      fork(companyLinksListener),
      fork(employeeOptionsListener),
      fork(employeeOptionsSetterListener)
  ]);
}
