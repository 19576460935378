import React, {Component} from "react";
import {connect} from "react-redux";
import Form from "react-jsonschema-form";
import {DatePicker, Select, Switch, Button, Modal, Upload, Icon, Checkbox, TimePicker, message} from "antd";
import PayrollAllowances from "./PayrollAllowances";
import PayrollDeductions from "./PayrollDeductions";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../constants";
import WorkDays from "./WorkDays";
import moment from 'moment';
import axios from "axios";
import _ from "lodash";

const CheckboxGroup = Checkbox.Group;
const defaultCheckedList = [];

const Option = Select.Option;
let designationOptions=[];
let activeContractOptions=[];
let formData = {};
let contract =[], contractDate, workDays;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {

  const isLt2M = file.size / 1024 / 1024 < 1;
  if (!isLt2M) {
    message.error('Image must smaller than 1MB!');
  }
  return isLt2M;
}

class ContractForm extends Component {
  state={
    toggle: this.props.contract.end_date ? true : false,
    loading: true,
    value: 0,
    del: false,
    updated: false,
    uiSchema: this.uiSchema,
    contract: this.props.contract,
    saveNewVisible: false,
    checkedList: defaultCheckedList,
    indeterminate: true,
    checkAll: false,
    allowances: '',
    deductions: '',
    adjustments: '',
    adjustmentChangeMarker: false,
    adjustmentChange: false,
    values: '',
  };

  schema1 = {
    title: "",
    type: "object",
    required: [],
    properties: {
        contractDate: {type: "string", title: "Contract Date"},
        designation: {type: "string", title: "Designation"},
        roleDescription: {type: "string", title: "Role Description"},
        startDate: {type: "string", title: "Start-Date"},
        endDateSwitch: {type: "boolean", title: "End-Date"},
        workDays: {type: "array", title: "Work Days", items: {type: "string", enum: ["Mon","Tue","Wed","Thu","Fri","Sat", "Sun"]},uniqueItems: true, disabled: true},
        workHours: {type: "number", title: "Work Hours"},
        workHoursType: {type: "string", title: "Work Hours Type"},
        startTime: {type: "string", title: "Start-Time"},
        grossSalary: {type: "number", title: "Basic Salary"},
        grossSalaryType: {type: "string", title: "Gross Salary Type"},
    },
  };

  schema2 = {
    title: "",
    type: "object",
    required: [],
    properties: {
        contractDate: {type: "string", title: "Contract Date"},
        designation: {type: "string", title: "Designation"},
        roleDescription: {type: "string", title: "Role Description"},
        startDate: {type: "string", title: "Start-Date"},
        endDateSwitch: {type: "boolean", title: "End-Date"},
        endDate: {type: "string", title: "End Date"},
        workDays: {type: "array", title: "Work Days", items: {type: "string", enum: ["Mon","Tue","Wed","Thu","Fri","Sat", "Sun"]},uniqueItems: true},
        workHours: {type: "number", title: "Work Hours"},
        workHoursType: {type: "string", title: "Work Hours Type"},
        startTime: {type: "string", title: "Start-Time"},
        grossSalary: {type: "number", title: "Basic Salary"},
        grossSalaryType: {type: "string", title: "Gross Salary Type"},
    },
  };

  uiSchema = {
    contractDate: {"ui:widget": (props) => {
      return (
        <DatePicker className="gx-w-100" disabled={!(this.props.workspaceData.role.permissions.includes("edit_company") || this.props.workspaceData.role.permissions.includes("edit_manager"))} placeholder="Select Contract Date" value={props.value ? (moment(props.value)) : ''} onChange={(date, dateString) => props.onChange(dateString)}/>
      );
    }},
    designation: {"ui:widget": (props) => {
      return (
        <Select className="ant-select" disabled={!(this.props.workspaceData.role.permissions.includes("edit_company") || this.props.workspaceData.role.permissions.includes("edit_manager"))} placeholder="Select Designation..." value={props.value} onChange={(value) => {props.onChange(value)}}>
          {designationOptions}
        </Select>
      );
    }},
    roleDescription: {"ui:widget": (props) => {
      return (
        <textarea className={!(this.props.workspaceData.role.permissions.includes("edit_company") || this.props.workspaceData.role.permissions.includes("edit_manager")) ? "ant-input-disabled ant-input" : "ant-input"} disabled={!(this.props.workspaceData.role.permissions.includes("edit_company") || this.props.workspaceData.role.permissions.includes("edit_manager"))} placeholder="Role Description" value={props.value} onChange={(event) => props.onChange(event.target.value)}/>
      );
    }},
    startDate: {"ui:widget": (props) => {
      return (
        <DatePicker className="gx-w-100"  disabled={!(this.props.workspaceData.role.permissions.includes("edit_company") || this.props.workspaceData.role.permissions.includes("edit_manager"))} placeholder="Select Start Date" value={props.value ? (moment(props.value)) : ''} onChange={(date, dateString) => props.onChange(dateString)}/>
      );
    }},
    endDateSwitch: {"ui:widget": (props) => {
      return (
        <div>
        <Switch value={props.value} disabled={!(this.props.workspaceData.role.permissions.includes("edit_company") || this.props.workspaceData.role.permissions.includes("edit_manager"))} defaultChecked={this.state.toggle} onClick={(checked) => {props.onChange(!props.value); this.toggle(checked)}}/>
        </div>
      );
    }
    },
    endDate: {"ui:widget": (props) => {
      return (
        <div>
        <DatePicker className="ant-select" disabled={!(this.props.workspaceData.role.permissions.includes("edit_company") || this.props.workspaceData.role.permissions.includes("edit_manager"))} placeholder="Select End Date" value={props.value ? (moment(props.value)) : ''} onChange={(date, dateString) => props.onChange(dateString)}/>
        </div>
      );
    }},
    workDays: {"ui:widget": (props) => {
      return(
      <WorkDays values={this.values} workDaysData={this.props.contract.working_days !== null ? this.props.contract.working_days : []} disabled={!(this.props.workspaceData.role.permissions.includes("edit_company") || this.props.workspaceData.role.permissions.includes("edit_manager"))}/>
      )
    }
    },
    workHours: {"ui:widget": (props) => {
      return (
        <input type="text" className={!(this.props.workspaceData.role.permissions.includes("edit_company") || this.props.workspaceData.role.permissions.includes("edit_manager")) ? "ant-input-disabled ant-input" : "ant-input"} disabled={!(this.props.workspaceData.role.permissions.includes("edit_company") || this.props.workspaceData.role.permissions.includes("edit_manager"))} placeholder="Work Hours" value={props.value} onChange={(event) => props.onChange(event.target.value)}/>
      );
    }},
    workHoursType: {"ui:widget": (props) => {
      return (
        <Select className="ant-select" disabled={!(this.props.workspaceData.role.permissions.includes("edit_company") || this.props.workspaceData.role.permissions.includes("edit_manager"))} placeholder="Select..." value={props.value} onChange={(value) => props.onChange(value)}>
          <Option value="day">Per Day</Option>
          <Option value="week">Per Week</Option>
          <Option value="month">Per Month</Option>
        </Select>
      );
    }},
    startTime: {"ui:widget": (props) => {
      return (
        <TimePicker className="gx-w-100"  disabled={!(this.props.workspaceData.role.permissions.includes("edit_company") || this.props.workspaceData.role.permissions.includes("edit_manager"))} placeholder="Select Office Start Time" value={props.value ? (moment(props.value)) : ''} onChange={(time, timeString) => props.onChange(time ? time.toString() : '')} format={"HH:mm"}/>
      );
    }},
    grossSalary: {"ui:widget": (props) => {
      return (
        <input type="text" className={!(this.props.workspaceData.role.permissions.includes("edit_company") || this.props.workspaceData.role.permissions.includes("edit_manager")) ? "ant-input-disabled ant-input" : "ant-input"} disabled={!(this.props.workspaceData.role.permissions.includes("edit_company") || this.props.workspaceData.role.permissions.includes("edit_manager"))} placeholder="Gross Salary" value={props.value} onChange={(event) => props.onChange(event.target.value)}/>
      );
    }},
    grossSalaryType: {"ui:widget": (props) => {
      return (
        <Select className="ant-select" disabled={!(this.props.workspaceData.role.permissions.includes("edit_company") || this.props.workspaceData.role.permissions.includes("edit_manager"))} placeholder="Select..." value={props.value} onChange={(value) => props.onChange(value)}>
          <Option value="hourly">Per Hour</Option>
          <Option value="weekly">Per Week</Option>
          <Option value="monthly">Per Month</Option>
          <Option value="yearly">Per Year</Option>
        </Select>
      );
    }},
    signedCopy: {"ui:widget": (props) => {
      return(
        <Upload onChange={this.handleChange} beforeUpload={beforeUpload} customRequest={this.custom} showUploadList={false}>
          <Button>
            <Icon type="upload" /> Click to Upload
          </Button>
        </Upload>
      );
    }}
  };

  CustomFieldTemplate = (props) => {
    if(props.schema.title == "Work Hours" || props.schema.title == "Basic Salary" || props.schema.title == "End-Date"){
      return props.displayLabel
        ? <div className="ant-row ant-form-item" style={{width: "67%", float: "left"}}>
            {props.required ?
            <label htmlFor={props.id} className="ant-form-item-label ant-form-item-required ant-col-xs-24 ant-col-sm-12">
              {props.label}
            </label> :
            <label htmlFor={props.id} className="ant-form-item-label ant-col-xs-24 ant-col-sm-12">
              {props.label}
            </label>}
            <div className="ant-form-item-control-wrapper ant-col-xs-24 ant-col-sm-12">
              {props.description}
              {props.children}
              {props.errors}
              {props.help}
            </div>
          </div>
        : <div className={props.classNames}>
            {props.children}
          </div>;
    }
    else if(props.schema.title == "Work Hours Type" || props.schema.title == "Gross Salary Type" || props.schema.title == "End Date") {
      return props.displayLabel
        ? <div className="ant-row ant-form-item ant-second-row" style={{width: "35%"}}>
            <div className="ant-form-item-control-wrapper ant-col-xs-24 ant-col-sm-24 gx-mb-3">
              {props.description}
              {props.children}
              {props.errors}
              {props.help}
            </div>
          </div>
        : <div className={props.classNames}>
            {props.children}
          </div>;
    }
    else {
    return props.displayLabel
      ? <div className="ant-row ant-form-item" style={{width: "100%"}}>
          {props.required ?
          <label htmlFor={props.id} className="ant-form-item-label ant-form-item-required ant-col-xs-24 ant-col-sm-8">
            {props.label}
          </label> :
          <label htmlFor={props.id} className="ant-form-item-label ant-col-xs-24 ant-col-sm-8">
            {props.label}
          </label>}
          <div className="ant-form-item-control-wrapper ant-col-xs-24 ant-col-sm-16">
            {props.description}
            {props.children}
            {props.errors}
            {props.help}
          </div>
        </div>
      : <div className={props.classNames}>
          {props.children}
        </div>;}
  };

  values = (values) => {
    workDays = values.checkedList ? values.checkedList : values;
  }

  handleChange = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl => {
        this.setState({
        imageUrl,
        uiSchema: {}
      })
      this.setState({...this.state, uiSchema: this.uiSchema});
    });
    }
  }

  custom = (options: any) => {
    const employee_id = this.props.workspaceData.id;
    const {authUser} = this.props;
    const data= new FormData();
    let url;
    if(this.state.contractData) {
      url = `${API_URL_V1}/employees/${this.props.contract[0].employee_id}/contracts/${this.state.contractData[this.state.value].id}`;
    }
    else {
      url = `${API_URL_V1}/employees/${this.props.contract[0].employee_id}/contracts/${this.props.contract[this.state.value].id}`;
    }
    data.append("_method", "POST");
    data.append("value", options.file);
    var config = {
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
      },
      headers: {
      'Authorization': 'Bearer '+authUser
      }
    };
    axios.put(url, data,
      config
    )
    .then(userData =>
      {options.onSuccess(userData.data, options.file)
        message.success("File Uploaded");
      }
    )
    .catch(error => error);

  }

  getPayrollAdjustments = () => {
    this.setState({...this.state, loading: true});
    const {authUser, currentCompany} = this.props;
    const employee_id = this.props.employee ? this.props.employee.id : this.props.currentEmployee.id;
    axios.get(`${API_URL_V1}/employees/${employee_id}/contracts/${this.props.contract.id}/payroll/adjustments`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(adjustmentData => {
      console.log(adjustmentData.data.data);
      let allowanceCount = 0, deductionCount = 0;
      adjustmentData.data.data.forEach((adjustment, index) => {
        if(adjustment.type === 'deduction') {
          deductionCount++;
        }
        if(adjustment.type === 'allowance') {
          allowanceCount++;
        }
      })
      this.setState({...this.state, adjustments: adjustmentData.data.data, allowanceCount: allowanceCount, deductionCount: deductionCount, loading: false});
    })

  }

  componentDidMount () {
    const {authUser, currentCompany} = this.props;
    const employee_id = this.props.employee ? this.props.employee.id : this.props.currentEmployee.id;
    axios.all([
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/categories/deductions/data`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/categories/allowances/data`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
    ])
    .then(axios.spread((deductionData, allowanceData) => {
      this.setState({...this.state, allowances: allowanceData.data.data, deductions: deductionData.data.data},() => {this.getPayrollAdjustments()});
    }))
    .catch(error => error)
    contractDate= this.formData.contractDate;
    contract =[];
    activeContractOptions=[]; designationOptions=[];
    for(let i = 0; i< (this.props.contract).length; i++) {
      contract.push({key: this.props.contract[i].id, val: this.props.contract[i].effective_on});
    }
    contract = contract.sort(function (a, b) {
      return a.val.localeCompare( b.val );
    });
    for (let i = 0; i< (contract.length); i++) {
      activeContractOptions.push(<Option key={contract[i].key} value={contract[i].key}>{contract[i].val}</Option>);
    }
    if(designationOptions.length ==0) {
      for(let i = 0; i< (this.props.designation).length; i++) {
        designationOptions.push(<Option value={(this.props.designation[i].id).toString()}>{this.props.designation[i].name}</Option>);
      }
    }
    this.setState({...this.state, uiSchema: this.uiSchema});
  }

  toggle = (value) => {
    this.setState({...this.state, toggle: value});
  }

  onSubmit = (values) => {
    console.log(values);
    if(this.state.adjustmentChangeMarker) {
      this.setState({...this.state, adjustmentChange: true, values: values});
    } else {
      this.formData.workDays = workDays;
      const {authUser} = this.props;
      let url = `${API_URL_V1}/employees/${this.props.contract.employee_id}/contracts/${this.props.contract.id}`;
      axios.put(url, {
        effective_on: values.formData.contractDate,
        start_date: values.formData.startDate,
        end_date: this.state.toggle ? values.formData.endDate : '',
        designation_id: values.formData.designation,
        role_description: values.formData.roleDescription,
        base_compensation_amount: values.formData.grossSalary,
        base_compensation_type: values.formData.grossSalaryType,
        working_days: this.formData.workDays,
        working_hours: values.formData.workHours,
        working_period_unit: values.formData.workHoursType,
        shift_start_at: values.formData.startTime ? moment(values.formData.startTime).format("HH:mm:00") : '',
        },
        {
          headers: {
            'Authorization': 'Bearer '+authUser
          }
        })
        .then(contractData => {
          axios.get(`${API_URL_V1}/employees/${this.props.contract.employee_id}/contracts`, {
            headers: {
              'Authorization': 'Bearer '+authUser
            }
          })
          .then(contractData => {
            contract =[];
            activeContractOptions=[];
            for(let i = 0; i< (contractData.data.data).length; i++) {
              contract.push({key: contractData.data.data[i].id, val: contractData.data.data[i].effective_on});
            }
            contract = contract.sort(function (a, b) {
              return a.val.localeCompare( b.val );
            });
            for (let i = 0; i< (contract.length); i++) {
              activeContractOptions.push(<Option key={contract[i].key} value={contract[i].key}>{contract[i].val}</Option>);
            }
            this.setState({...this.state, uiSchema: {}, contractData: contractData.data.data});
            this.setState({...this.state, uiSchema: this.uiSchema});
          })
          message.success("Information Updated");
          this.props.getAllContracts();
        })
        .catch(error => {
          message.error(error.response.data.message);
        })
      }
  }

  onChange = (values) => {
    let newValues = _.omit(values.formData, "workDays");
    if(contractDate !== values.formData.contractDate) {
      this.formData = newValues;
      this.setState({...this.state, saveNewVisible: true});
    }
    else {
      this.formData = newValues;
      this.setState({...this.state, saveNewVisible: false});
    }
    this.formData = newValues;
  }

  saveNewContract = () => {
    const {authUser} = this.props;
    let employee_id = this.props.contract ? this.props.contract.employee_id : this.props.workspaceData.id;
    axios.post(`${API_URL_V1}/employees/${employee_id}/contracts`, {
      effective_on: this.formData.contractDate,
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(contractData => {
      const contractId = contractData.data.data.id;
      axios.put(`${API_URL_V1}/employees/${employee_id}/contracts/${contractId}`, {
        id: contractId,
        start_date: this.formData.startDate,
        end_date: this.state.toggle ? this.formData.endDate : '',
        designation_id: this.formData.designation,
        role_description: this.formData.roleDescription,
        base_compensation_amount: this.formData.grossSalary,
        base_compensation_type: this.formData.grossSalaryType,
        working_days: this.formData.workDays,
        working_hours: this.formData.workHours,
        working_period_unit: this.formData.workHoursType,
        },
        {
          headers: {
            'Authorization': 'Bearer '+authUser
          }
        }
      ).then(contractData => {
         message.success('New Version Created');
         this.formData.activeContract = contractData.data.data.effective_on;
         this.props.getAllContracts();
      })
    })
  }

  deleteContractModal = () => {
    this.setState({...this.state, del: true})
  }

  deleteContract = () => {
    this.props.deleteRecord(this.props.contract);
  }

  formData = {
    contractDate: this.props.contract.effective_on,
    designation: this.props.contract.designation_id ? (this.props.contract.designation_id).toString() : undefined,
    roleDescription: this.props.contract.role_description ? this.props.contract.role_description : '',
    endDateSwitch: true,
    startDate: this.props.contract.start_date ? this.props.contract.start_date : undefined,
    endDate: this.props.contract.end_date ? this.props.contract.end_date : undefined,
    workHours: this.props.contract.working_hours ? this.props.contract.working_hours : undefined,
    workHoursType: this.props.contract.working_period_unit ? this.props.contract.working_period_unit : undefined,
    startTime: this.props.contract.shift_start_at ? moment(this.props.contract.shift_start_at, 'HH:mm').toString() : undefined,
    grossSalary: this.props.contract.base_compensation_amount ? this.props.contract.base_compensation_amount : undefined,
    grossSalaryType: this.props.contract.base_compensation_type ? this.props.contract.base_compensation_type : undefined,
  };

  handleModalCancel = (e) => {
    this.setState({...this.state, del: false});
  }
  componentWillReceiveProps(nextProps){
    this.formData = {
      contractDate: nextProps.contract.effective_on,
      designation: nextProps.contract.designation_id ? (nextProps.contract.designation_id).toString() : undefined,
      roleDescription: nextProps.contract.role_description ? nextProps.contract.role_description : '',
      endDateSwitch: true,
      startDate: nextProps.contract.start_date ? nextProps.contract.start_date : undefined,
      endDate: nextProps.contract.end_date ? nextProps.contract.end_date : undefined,
      workHours: nextProps.contract.working_hours ? nextProps.contract.working_hours : undefined,
      workHoursType: nextProps.contract.working_period_unit ? nextProps.contract.working_period_unit : undefined,
      startTime: nextProps.contract.shift_start_at ? moment(nextProps.contract.shift_start_at, 'HH:mm').toString() : undefined,
      grossSalary: nextProps.contract.base_compensation_amount ? nextProps.contract.base_compensation_amount : undefined,
      grossSalaryType: nextProps.contract.base_compensation_type ? nextProps.contract.base_compensation_type : undefined,
    };
    this.setState({...this.state, toggle: nextProps.contract.end_date ? true : false, uiSchema: ''},() => this.setState({...this.state, uiSchema: this.uiSchema},() => {this.getPayrollAdjustments()}));
  }

  adjustmentChange = () => {
    this.setState({...this.state, adjustmentChangeMarker: true});
  }

  render() {
    return (
      <div>
      {this.state.loading ?
        <div className="gx-loader-view">
          <CircularProgress/>
        </div> :
        <div>
          <Modal
            title="Delete?"
            visible={this.state.del}
            onOk={this.deleteContract}
            onCancel={this.handleModalCancel}
            footer={[
              <Button key="submit" loading={this.props.deleteLoading} onClick={this.deleteContract}>
                OK
              </Button>,
              <Button key="cancel" onClick={this.handleModalCancel}>
                Cancel
              </Button>
            ]}
          >
          <p>Are you Sure?</p>
          </Modal>
          <Modal
            title="Alert"
            visible={this.state.adjustmentChange}
            onOk={() => {this.setState({...this.state, adjustmentChange: false, adjustmentChangeMarker: false}, () => {this.onSubmit(this.state.values)})}}
            onCancel={() => {this.setState({...this.state, adjustmentChange: false, adjustmentChangeMarker: false})}}
            footer={[
              <Button key="submit" onClick={() => {this.setState({...this.state, adjustmentChange: false, adjustmentChangeMarker: false}, () => {this.onSubmit(this.state.values)})}}>
                OK
              </Button>,
              <Button key="cancel" onClick={() => {this.setState({...this.state, adjustmentChange: false, adjustmentChangeMarker: false})}}>
                Cancel
              </Button>
            ]}
          >
          <p>Make sure you click the green tick against the allowance and deduction to add it. Otherwise they will not be saved.</p>
          </Modal>
          <Form
            schema={this.state.toggle ? this.schema2 : this.schema1}
            uiSchema={this.state.uiSchema}
            formData={this.formData}
            onChange={({formData})=>this.onChange({formData})}
            onSubmit={this.onSubmit}
            FieldTemplate={this.CustomFieldTemplate}
            showErrorList={false} >
            <div className="ant-row ant-form-item">
              <div className="ant-col-xs-24 ant-col-sm-24" style={{marginLeft: -10}}>
                {this.state.allowances && this.state.deductions && <PayrollAllowances allowances={this.state.allowances} employee={this.props.employee} contract={this.props.contract} adjustments={this.state.adjustments} adjustmentChange={this.adjustmentChange}/>}
              </div>
              <div className="ant-col-xs-24 ant-col-sm-24" style={{marginLeft: -10}}>
                {this.state.allowances && this.state.deductions && <PayrollDeductions deductions={this.state.deductions} employee={this.props.employee} contract={this.props.contract} adjustments={this.state.adjustments} adjustmentChange={this.adjustmentChange}/>}
              </div>
              <div className="ant-col-xs-24 ant-col-sm-8"></div>
              <div className="ant-col-xs-24 ant-col-sm-16">
                <button type="submit" disabled={!(this.props.workspaceData.role.permissions.includes("edit_company") || this.props.workspaceData.role.permissions.includes("edit_manager"))} className="ant-btn btn-primary">Save</button>
                {this.state.saveNewVisible ?
                <button onClick={(e)=> {e.preventDefault(); this.saveNewContract()}} disabled={!(this.props.workspaceData.role.permissions.includes("edit_company") || this.props.workspaceData.role.permissions.includes("edit_manager"))} className="ant-btn btn-primary">Save New Version</button>
                : null}
                {this.props.contract ?
                <span>
                {this.props.contract.can_be_deleted ?
                <button onClick={(e) => {e.preventDefault(); this.deleteContractModal()}} disabled={!(this.props.workspaceData.role.permissions.includes("edit_company") || this.props.workspaceData.role.permissions.includes("edit_manager"))} className="ant-btn ant-btn-danger">Delete</button>: null
              }</span> : null}
              </div>
            </div>
          </Form>
        </div>}
      </div>
    );
  }
}
const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentEmployee} = company;
  return {authUser, currentEmployee}
};

export default connect(mapStateToProps)(ContractForm);
