import React, {Component} from "react";
import request from '../../../requests';
import {API_URL_V1} from '../../../constants';
import TaskList from "components/dashboard/CRM/TaskList";
import axios from "axios";

class Tasks extends Component {
    state = {
      loading: false,
      tasks: [],
      projects: [],
    };

    componentDidMount(){
      this.getTasks();
    }

    getTasks = () => {
      this.setState({...this.state, loading: true})
      let id = this.props.employee.id;
      axios.all([
        request.get(`${API_URL_V1}/employees/${id}/tasks`),
        request.get(`${API_URL_V1}/workspaces/${this.props.company.id}/projects`),
      ])
      .then(axios.spread((response, projects) => {
        this.setState({...this.state, tasks: response.data.data, projects: projects.data.data, loading: false})
      }))
      .catch(error => {
        this.setState({...this.state, loading: false})
      })
    }

    render() {
      return (
        <TaskList taskList={this.state.tasks} refresh={this.getTasks} projects={this.state.projects} loader={this.state.loading}/>
      );
    }
  }
export default Tasks;
