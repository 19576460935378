import React, {Component} from "react";
import {Select, DatePicker} from "antd";
import Auxiliary from "../../../util/Auxiliary";
import moment from "moment";

let employees=[];
const {Option} = Select;
const {RangePicker} = DatePicker;

class AttendanceFilter extends Component {
  state={
    employees: [],
    dateRange: [],
    filterData: {
      employee_id: '',
      date: '',
      created_at: '',
    },
  }

  componentDidMount () {
    employees=[];
    for(let i = 0; i < (this.props.employees).length; i++) {
      let disabled = (this.props.employees[i].manager_id === this.props.currentEmployee.id || this.props.employees[i].id === this.props.currentEmployee.id);
      if(this.props.currentEmployee.role.permissions.includes("edit_company")) {
        disabled = true;
      }
      employees.push(<Option value={(this.props.employees[i].id).toString()} disabled={!disabled}>{this.props.employees[i].first_name} {this.props.employees[i].last_name}</Option>);
    }
    let thisYear = (new Date()).getFullYear();
    let startDay = new Date("1/1/" + thisYear);
    let endDay = new Date("12/31/" + thisYear);
    let start = moment(startDay.valueOf());
    let end = moment(endDay.valueOf());
    this.setState({...this.state, employees: employees, dateRange: [start, end]});
  }

  handleChange = (value) => {
    let filterData = this.state.filterData;
    filterData.employee_id = value;
    this.props.employees.map((employee, index) => {
      if(employee.id == value) {
        filterData.created_at = moment(employee.created_at).format('YYYY-MM-DD');
      }
    })
    this.setState({...this.state, filterData: filterData}, () => {this.props.filterAttendanceData(this.state.filterData)});
  }

  dateChange = (date, dateString) => {
    let filterData = this.state.filterData;
    filterData.date = dateString;
    this.setState({...this.state, filterData: filterData, dateRange: date},() => {this.props.filterAttendanceData(this.state.filterData)});
  }


  render() {
    return(
      <div className="gx-mb-2">
        <span>Filter By: </span>
        <Select
          placeholder="Please User"
          style={{ marginRight: 20, width: 200 }}
          defaultValue={this.props.currentEmployee.id.toString()}
          onChange={this.handleChange}
        >
          {this.state.employees}
        </Select>
        <RangePicker
          placeholder="Date Filter"
          value={this.state.dateRange}
          allowClear={false}
          suffixIcon={" "}
          onChange={this.dateChange}
        />
      </div>
    )
  }
}

export default AttendanceFilter;
