import React, {Component} from "react";
import {Layout} from "antd";

import Sidebar from "../Sidebar/index";
import HorizontalDefault from "../Topbar/HorizontalDefault/index";
import HorizontalDark from "../Topbar/HorizontalDark/index";
import InsideHeader from "../Topbar/InsideHeader/index";
import AboveHeader from "../Topbar/AboveHeader/index";
import BelowHeader from "../Topbar/BelowHeader/index";

import Topbar from "../Topbar/index";
import {footerText} from "util/config";
import App from "routes/index";
import Customizer from "containers/Customizer";
import {connect} from "react-redux";
import {
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE
} from "../../constants/ThemeSetting";
import NoHeaderNotification from "../Topbar/NoHeaderNotification/index";
import {getCurrentUser, getRoles, getUserWorkspaceData} from "appRedux/actions/Auth";
import EmailVerification from "./EmailVerification";
import {Online, Offline} from "react-detect-offline";
import {API_URL} from "../../constants";
import moment from "moment";

const {Content, Footer} = Layout;

let time=0, status;
export class MainApp extends Component {

  getContainerClass = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DARK_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_DEFAULT_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL:
        return "gx-container-wrap";
      case NAV_STYLE_BELOW_HEADER:
        return "gx-container-wrap";
      case NAV_STYLE_ABOVE_HEADER:
        return "gx-container-wrap";
      default:
        return '';
    }
  };
  getNavStyles = (navStyle) => {
    switch (navStyle) {
      case NAV_STYLE_DEFAULT_HORIZONTAL :
        return <HorizontalDefault/>;
      case NAV_STYLE_DARK_HORIZONTAL :
        return <HorizontalDark/>;
      case NAV_STYLE_INSIDE_HEADER_HORIZONTAL :
        return <InsideHeader/>;
      case NAV_STYLE_ABOVE_HEADER :
        return <AboveHeader/>;
      case NAV_STYLE_BELOW_HEADER :
        return <BelowHeader/>;
      case NAV_STYLE_FIXED :
        return <Topbar/>;
      case NAV_STYLE_DRAWER :
        return <Topbar/>;
      case NAV_STYLE_MINI_SIDEBAR :
        return <Topbar/>;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR :
        return <NoHeaderNotification/>;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR :
        return <NoHeaderNotification/>;
      default :
        return null;
    }
  };

  getSidebar = (navStyle, width) => {
    if (width < TAB_SIZE) {
      return <Sidebar/>;
    }
    switch (navStyle) {
      case NAV_STYLE_FIXED :
        return <Sidebar/>;
      case NAV_STYLE_DRAWER :
        return <Sidebar/>;
      case NAV_STYLE_MINI_SIDEBAR :
        return <Sidebar/>;
      case NAV_STYLE_NO_HEADER_MINI_SIDEBAR :
        return <Sidebar/>;
      case NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR:
        return <Sidebar/>;
      default :
        return null;
    }
  };

  componentDidMount() {
    if (!this.props.user) {
    this.props.getCurrentUser(this.props.authUser);
    }
  }

  title = () => {
    if(this.props.currentTimer) {
      let time = moment.utc() - moment.utc(this.props.currentTimer.start);
      time = time+1000;
      let timer = ((Math.floor(moment.duration(time).asHours()) < 10) ? `0${Math.floor(moment.duration(time).asHours())}` : Math.floor(moment.duration(time).asHours())) + moment.utc(time).format(":mm:ss");
      let description = '';
      if(this.props.currentTimer.description) {
        description = " - " +this.props.currentTimer.description;
      }
      document.title = timer + description;
      status = setTimeout(this.title, 1000);
    }
  }

  render() {
    const {match, width, navStyle, authUser, getCurrentUser, user, getRoles, roles, userWorkspaceData, getUserWorkspaceData} = this.props;
    if (!user) {
      return (<div className="loader loading"><img src="/loader.gif" alt="loader"/></div>);
    }
    if (!roles) {
      getRoles();
    }
    if(this.props.currentTimer) {
      this.title();
    } else {
      clearTimeout(status);
      document.title= "CuteHR"
      time = 0;
    }


    // if(!userWorkspaceData) {
    //   getUserWorkspaceData(authUser);
    // }
    console.log(process.env.REACT_APP_BUILD_TIME)
    return (
      <Layout className="gx-app-layout">
        {this.getSidebar(navStyle, width)}
        <Layout>
          {this.getNavStyles(navStyle)}
          <EmailVerification/>
          <Offline polling={{ interval: 15000, url: `${API_URL}/ping` }}>
          <div style={{padding: '16px 32px', background: '#f42e34', color: '#ffffff'}}>
              It seems you have lost internet connectivity. Check the connectivity and try again.
          </div>
          </Offline>
          <Content className={`gx-layout-content ${ this.getContainerClass(navStyle)} `}>
            <App match={match}/>
            <Footer>
              <div className="gx-layout-footer-content">
                {footerText}
                <i className="icon icon-circle gx-fs-xxl" style={{color: "#fffffe", float: "right"}} title={!process.env.REACT_APP_BUILD_TIME ? null : process.env.REACT_APP_BUILD_TIME}/>
              </div>
            </Footer>
          </Content>
        </Layout>
        <Customizer/>
      </Layout>
    )
  }
}

const mapStateToProps = ({auth, settings, company}) => {
  const {width, navStyle} = settings;
  const {authUser, user, roles, userWorkspaceData} = auth;
  const {currentEmployee, currentTimer} = company;
  return {width, navStyle, authUser, user, roles, userWorkspaceData, currentEmployee, currentTimer}
};
export default connect(mapStateToProps, {getCurrentUser, getUserWorkspaceData, getRoles})(MainApp);
