import React, {Component} from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router';
import {Button, Card, Col, Divider, Input, Row, Table, Select, message, List, Avatar, Icon} from "antd";
import Auxiliary from "../../../util/Auxiliary";
import Breadcrumbs from "components/Breadcrumbs/index";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../constants";
import {FaRegCalendarAlt} from "react-icons/fa";
import axios from "axios";


class Event extends Component {

    state = {
        loading: true,
        event: null,
        location: null,
    };

    getLocation = (id) => {
        const {authUser, currentCompany, match} = this.props;
        axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/locations/${id}`, {
                headers: {
                    'Authorization': 'Bearer '+authUser
                }
            })
            .then(officeData => {
                this.setState({...this.state, location: officeData.data.data, loading: false});
            })
            .catch(error => error)
    }

    getEvent = (id) => {
        const {authUser, currentCompany, match} = this.props;
        console.log(match);
        axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/events/${id}`, {
                headers: {
                    'Authorization': 'Bearer '+authUser
                }
            })
            .then(eventsData  => {
                let event= eventsData.data.data;

                this.setState({...this.state, event: event, loading: false}, () => {this.getLocation(event.location_id)});
            })
            .catch(error => error)
    }

    componentDidMount() {
        this.getEvent(this.props.match.params.id);
    }

    render() {
        let event = this.state.event;
        return (
            <div>
                {this.state.loading ?
                    <CircularProgress /> :
                    <div>
                        <Auxiliary>
                            <div className="gx-profile-content">
                                <Breadcrumbs />
                                <Row>
                                    <Col xl={17} lg={14} md={14} sm={24} xs={24}>
                                        <Card className="gx-card">
                                            <div className="gx-text-center gx-pt-sm-3">
                                                <h2 className="gx-mb-3 gx-mb-sm-4">{event.title}</h2>

                                                <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                                                    <div className="gx-media-body">
                                                        <h6 className="gx-mb-1 gx-text-grey">On</h6>
                                                        {event.event_on} {event.event_time}
                                                    </div>
                                                </div>
                                                <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                                                    <div className="gx-media-body">
                                                        <h6 className="gx-mb-1 gx-text-grey">At</h6>
                                                        {event.location}
                                                    </div>
                                                </div>
                                                {this.state.location && this.state.location.id == event.location_id ?
                                                    <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                                                        <div className="gx-media-body">
                                                            <h6 className="gx-mb-1 gx-text-grey">Office</h6>
                                                            {this.state.location.name}
                                                        </div>
                                                    </div>
                                                    : null}
                                                <p className="gx-mt-lg-4">{event.description}</p>
                                            </div>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>
                        </Auxiliary>
                    </div> }
            </div>
        );
    }
}

const mapStateToProps = ({auth, company}) => {
    const {authUser, userWorkspaceData} = auth;
    const {currentCompany, currentEmployee} = company;
    return {authUser, userWorkspaceData, currentCompany, currentEmployee}
};

export default withRouter(connect(mapStateToProps)(Event));
