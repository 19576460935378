import React, {Component} from "react";
import {connect} from "react-redux";
import URLSearchParams from 'url-search-params'
import {Redirect, Route, Switch} from "react-router-dom";
import {LocaleProvider} from "antd";
import {IntlProvider} from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignInAs from "../SignInAs";
import SignUp from "../SignUp";
import ForgotPassword from "../ForgotPassword";
import ResetPassword from "../ResetPassword";
import ClientInvoice from "../ClientInvoice";
import MonthlyFeedback from "../MonthlyFeedback";
import FeedbackThankyou from "../FeedbackThankYou";
import Openings from "../Openings";
import OpeningDetails from "../OpeningDetails";
import ThanksForApplying from "../ThanksForApplying";
import CandidateMessaging from "../CandidateMessaging";
import isElectron from "is-electron";
import Thankyou from "../Thankyou";
import Join from "../Join";
import { Offline, Online } from "react-detect-offline";
import Auxiliary from "../../util/Auxiliary";
import {message} from "antd";
import {setInitUrl} from "appRedux/actions/Auth";
import {getCurrentTimer, getCompanyLinks, getEmployeeOptions} from "appRedux/actions/Company";
import {onLayoutTypeChange, onNavStyleChange, setThemeType} from "appRedux/actions/Setting";
import bugsnag from '@bugsnag/js'
import bugsnagReact from '@bugsnag/plugin-react'
import _ from "lodash";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL
} from "../../constants/ThemeSetting";

const RestrictedRoute = ({component: Component, authUser, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: {from: props.location}
          }}
        />}
  />;

let bugsnagClient = bugsnag('e93419ae1a261c5b8d69b9986a551a04');
bugsnagClient.use(bugsnagReact, React);
var ErrorBoundary = bugsnagClient.getPlugin('react');
class App extends Component {

  setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  setNavStyle = (navStyle) => {
    if (navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
      navStyle === NAV_STYLE_DARK_HORIZONTAL ||
      navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
      navStyle === NAV_STYLE_ABOVE_HEADER ||
      navStyle === NAV_STYLE_BELOW_HEADER) {
      document.body.classList.add('full-scroll');
      document.body.classList.add('horizontal-layout');
    } else {
      document.body.classList.remove('full-scroll');
      document.body.classList.remove('horizontal-layout');
    }
  };

  componentWillMount() {
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
    const params = new URLSearchParams(this.props.location.search);

    if (params.has("theme")) {
      this.props.setThemeType(params.get('theme'));
    }
    if (params.has("nav-style")) {
      this.props.onNavStyleChange(params.get('nav-style'));
    }
    if (params.has("layout-type")) {
      this.props.onLayoutTypeChange(params.get('layout-type'));
    }
  }

  componentWillReceiveProps (nextProps) {
    if(nextProps.currentEmployee && !this.props.currentTimer && this.props.timerHitCount ===0) {
      this.props.getCurrentTimer(this.props.currentEmployee);
      this.props.getCompanyLinks(nextProps.currentCompany.id);
      this.props.getEmployeeOptions(nextProps.currentEmployee.id);
    }
  }

  render() {
    const {match, location, layoutType, navStyle, locale, authUser, initURL} = this.props;

    if (location.pathname === '/') {
      if (authUser === null) {
        return ( <Redirect to={'/signin'}/> );
      } else {
        return ( <Redirect to={isElectron() ? '/timer' : '/dashboard'}/> );
      }
    }
    this.setLayoutType(layoutType);

    this.setNavStyle(navStyle);

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <ErrorBoundary>
      <Auxiliary>
      {window.navigator.onLine === true ? '' : message.error("No Internet Connection")}
      <LocaleProvider locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>

          <Switch>
            <Route exact path='/signin' component={SignIn}/>
            <Route exact path='/signin-as/:id' component={SignInAs}/>
            <Route exact path='/signup' component={SignUp}/>
            <Route exact path="/forgot-password" component={ForgotPassword}/>
            <Route exact path="/reset-password" component={ResetPassword}/>
            <Route path="/join" component={Join}/>
            <Route path="/thank_you" component={Thankyou}/>
            <Route path="/public/invoices/:id" component={ClientInvoice}/>
            <Route exact path="/feedback/thank_you" component={FeedbackThankyou}/>
            <Route path="/feedback/:id/survey/:survey_id/:key" component={MonthlyFeedback}/>
            <Route exact path="/openings/:id" component={Openings}/>
            <Route path="/ats/candidates/:id/messages/:key" component={CandidateMessaging}/>
            <Route path="/openings/:id/job/:job_id" component={OpeningDetails}/>
            <Route path="/thanks_for_applying" component={ThanksForApplying}/>
            <RestrictedRoute path={`${match.url}`} authUser={authUser}
                             component={MainApp}/>
          </Switch>
        </IntlProvider>
      </LocaleProvider>
      </Auxiliary>
        </ErrorBoundary>
    )
  }
}

const mapStateToProps = ({settings, auth, company}) => {
  const {locale, navStyle, layoutType} = settings;
  const {authUser, initURL} = auth;
  const {currentEmployee, currentTimer, timerHitCount, currentCompany} = company;
  return {locale, navStyle, layoutType, authUser, initURL, currentEmployee, currentTimer, timerHitCount, currentCompany}
};
export default connect(mapStateToProps, {setInitUrl, setThemeType, onNavStyleChange, onLayoutTypeChange, getCurrentTimer, getCompanyLinks, getEmployeeOptions})(App);
