import React, {Component} from "react";
import {connect} from "react-redux";
import {Breadcrumb} from "antd";
import {Link} from 'react-router-dom';
import axios from "axios";
import {API_URL_V1} from "../../constants";

class Breadcrumbs extends Component {
  state={
    projectData: [],
    value: [],
    candidate: [],
  }

  componentDidMount () {
    let add = window.location.pathname;
    add = add.split("/").filter(st => st);
    if(add.length > 3 && add[3] === "task_detail") {
      const {authUser, currentCompany} = this.props;
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/projects/${add[1]}`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(projectData => {
        this.setState({...this.state, projectData: projectData.data.data});
      })
      .catch(error => error)
    }
    if(add.length > 3 && add[3] === "candidate_detail") {
      const {authUser, currentCompany} = this.props;
      axios.all([
        axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/ats/openings/${add[1]}`, {
          headers: {
            'Authorization': 'Bearer '+authUser
          }
        }),
        (add.length > 5 && add[5] === "messaging") ?
        axios.get(`${API_URL_V1}/ats/openings/${add[1]}/candidates/${add[4]}`, {
          headers: {
            'Authorization': 'Bearer '+authUser
          }
        }) : '',
      ])
      .then(axios.spread((openingData, candidateData) => {
        console.log(openingData)
        this.setState({...this.state, value: openingData.data, candidate: candidateData.data},() => console.log(this.state.value));
      }))
      .catch(error => error)
    }
  }

  render() {
    let add = window.location.pathname;
    let url= [], data='';
    if(add) {
      add = add.split("/").filter(st => st);
      switch(add[0]) {
        case "my_profile":
          url[0]= "My Profile";
        break;
        case "requests":
          url[0]= "Request";
          break;
        case "calendar":
          url[0]= "Calendar";
          break;
        case "projects":
          url[0]= "Projects";
          break;
        case "employees":
          url[0]="Employees";
          break;
        case "clients":
          url[0]="Clients";
          break;
        case "general":
          url[0]="General";
          break;
        case "timeoff":
          url[0]="Time Off";
          break;
        case "custom_fields":
          url[0]="Custom Fields";
          break;
        case "events":
          url[0]= "Events";
          break;
        case "payroll":
          url[0]= "Payroll";
          break;
        case "invoice":
          url[0]= "Invoice";
          break;
        case "payroll_settings":
          url[0]= "Payroll Settings";
          break;
        case "offices":
          url[0]= "Offices";
          data="offices";
          add[0]="general";
          break;
        case "work_reports":
          url[0]="Work Reports";
          break;
        case "attendance_report":
          url[0]="Attendance Report";
          break;
        case "biometrics":
          url[0]="Biometrics"
          break;
        case "custom_status":
          url[0]="Custom Status"
          break;
        case "employee_satisfaction":
          url[0]="Employee Satisfaction"
          break;
        case "survey":
          url[0]="Survey"
          break;
        case "ats":
          url[0]="ATS"
          break;
        case "subscription":
          url[0]="Subscription"
          break;
      }
      switch(add[2]) {
        case "profile":
          url[2]="Employee Data"
          break;
        case "requests":
          url[2]="Requests"
          break;
        case "project_detail":
          url[2]="Project Detail"
          break;
        case "survey_detail":
          url[2]="Survey Detail"
          break;
        case "event_detail":
          url[2]="Event Detail"
          break;
        case "holidays":
          url[2]="Holidays"
          break;
        case "tasks":
          url[2]="Tasks"
          break;
        case "preview":
          url[2]="Preview"
          break;
        case "detail":
          url[2]="Detail"
          break;
      }
    }
    switch(add[3]) {
      case "task_detail":
        url[3]="Task Detail"
        break;
      case "candidate_detail":
        url[3]="Candidate Detail"
        break;
    }
    switch(add[5]) {
      case "messaging":
        url[5]="Messaging"
        break;
    }
    const breadcrumb =[];
    for(let i=0; i < add.length; i++) {
      if(i == 1 || i == 4) {
        continue;
      }
      breadcrumb.push(((window.location.pathname).split("/").filter(st => st)[i] === add[i] && i === ((add.length)-1)) ?
      <Breadcrumb.Item ><span>{url[i]}</span></Breadcrumb.Item> :
      (i === 2) ?
      <Breadcrumb.Item ><Link to={{pathname: "/"+add[i-2]+"/"+add[i-1]+"/"+add[i], state: add[i] === "detail" ? {value: this.state.value} : {projectData: this.state.projectData}}}><span className="gx-link">{url[i]}</span></Link></Breadcrumb.Item> :
      (i === 3) ?
      <Breadcrumb.Item ><Link to={{pathname: "/"+add[i-3]+"/"+add[i-2]+"/"+add[i-1]+"/"+add[i], state: {value: this.state.value, candidate: this.state.candidate}}}><span className="gx-link">{url[i]}</span></Link></Breadcrumb.Item>:
      <Breadcrumb.Item ><Link to={{pathname: "/"+add[i], state: {tab: data}}}><span className="gx-link">{url[i]}</span></Link></Breadcrumb.Item>)
    }
    return(
      <Breadcrumb separator=">" style={{height: '30px'}}>
        <Breadcrumb.Item ><Link to={"/"}><span className="gx-link">Home</span></Link></Breadcrumb.Item>
        {breadcrumb}
      </Breadcrumb>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {initURL, authUser} = auth;
  const {currentCompany} = company;
  return {initURL, authUser, currentCompany}
};

export default connect(mapStateToProps)(Breadcrumbs);
