import React, {Component} from "react";
import {connect} from "react-redux";
import BiometricDevices from "./BiometricDevices";
import Auxiliary from "../../../../util/Auxiliary";
import CircularProgress from "components/CircularProgress/index"
import {API_URL_V1} from "../../../../constants";
import axios from "axios";

class Biometrics extends Component {

  state={
    offices: [],
    loading: true,
  };

  componentDidMount () {
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/locations`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(locationData => {
      this.setState({...this.state, offices: locationData.data.data, loading: false});
    })
  }
  render() {
    return (
      <Auxiliary>
          {this.state.loading ? <CircularProgress /> :
          <BiometricDevices offices={this.state.offices}/>}
      </Auxiliary>
    );
  }
}
const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentEmployee, currentCompany} = company;
  return {authUser, currentEmployee, currentCompany}
};

export default connect(mapStateToProps)(Biometrics);
