import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Modal, Table, Divider, message} from "antd";
import AllowanceModal from "./AllowanceModal";
import Auxiliary from "../../../../util/Auxiliary";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../../constants";
import axios from "axios";

let disabled;
class Allowances extends Component {
  state={
    allowances: [],
    title: '',
    visible: false,
    editData: '',
    loading: true,
    confirmLoading: false,
    deleteVisible: false,
    deleteLoading: false,
    record: '',
  }

  getAllowances = () => {
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/categories/allowances/data`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(allowancesData => {
      this.setState({...this.state, allowances: allowancesData.data.data, loading: false})
    })
    .catch(error => error);
  }

  componentDidMount () {
    this.getAllowances();
  }

  onClick = () => {
    this.setState({...this.state, visible: true, title: "Add Allowance", editData: ''});
  }

  handleCancel = () => {
    this.setState({...this.state, visible: false});
  }

  edit = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, visible: true, title: "Edit Allowance", editData: record});
  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser, currentCompany} = this.props;
    axios.delete(`${API_URL_V1}/workspaces/${currentCompany.id}/categories/allowances/data/${record.id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(departmentData => {
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      this.getAllowances();
      message.success("Allowance Deleted Successfully");
    })
    .catch(error => {
      message.error("Failed to Delete");
    })
  }

  handleOk = (values, id) => {
    const {authUser, currentCompany} = this.props;
    this.setState({...this.state, confirmLoading: true});
    if(!id){
      axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/categories/allowances/data`, {
        name: values.allowance_name,
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(allowanceData => {
        let allowances = this.state.allowances;
        allowances.push(allowanceData.data);
        message.success("New Allowance Added");
        this.setState({...this.state, allowances: allowances, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Add Allowance");
        this.setState({...this.state, confirmLoading: false});
      })
    } else {
      axios.put(`${API_URL_V1}/workspaces/${currentCompany.id}/categories/allowances/data/${id}`, {
        name: values.allowance_name,
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(allowanceData => {
        let allowances = this.state.allowances;
        allowances.forEach((allowance, index) => {
          if(allowance.id == allowanceData.data.id){
            allowances[index] = {...allowance, ...allowanceData.data};
          }
        })
        message.success("Allowance Editted Successfully");
        this.setState({...this.state, allowances: allowances, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Edit");
        this.setState({...this.state, confirmLoading: false});
      })
    }
  }

  render () {
    disabled = (this.props.currentEmployee && this.props.currentEmployee.role) ? this.props.currentEmployee.role.permissions.includes("edit_company") : '';
    const columns = [{
        title: 'Allowance Name',
        dataIndex: 'name',
        //sorter: (a, b) => {return a.name.localeCompare(b.name)},
      }, {
        title: 'Actions',
        key: 'x',
        render: (text, record) => (
          <span>
            {disabled ?
              <span className="gx-link" onClick={(e) => {this.edit(record, e)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span> : <span></span>}

            {disabled ?
                <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><Divider type="vertical"/><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span> :
                <span></span>
            }
          </span>
        )
      }];
    return (
      <div>
      <Modal
        visible={this.state.deleteVisible}
        title="Delete Allowance?"
        onOk={(e) => this.deleteRecord(this.state.record, e)}
        onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
        confirmLoading={this.state.deleteLoading}
        footer={[
          <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
            OK
          </Button>,
          <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
            Cancel
          </Button>
        ]}
      >
        <p>Are you Sure you want to delete this Allowance?</p>
      </Modal>
      {this.state.loading ?
      <CircularProgress/> :
      <div>
      {this.state.visible ?
        <AllowanceModal title={this.state.title} value={this.state.editData} visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel} confirmLoading={this.state.confirmLoading}/> : null }
      <Auxiliary>
        <div style={{paddingBottom: "2.5rem"}}>
          <Button style={{float: "right"}} disabled={!disabled} onClick={this.onClick} value="Add">Add Allowance</Button>
        </div>
        <Table className="gx-table-responsive" columns={columns} dataSource={this.state.allowances} pagination={false}/>
      </Auxiliary>
      </div>}
      </div>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentEmployee, currentCompany} = company;
  return {authUser, currentEmployee, currentCompany}
};

export default connect(mapStateToProps)(Allowances);
