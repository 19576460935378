import React from "react";
import {Card} from "antd";
import {Link} from "react-router-dom";
import IntlMessages from "util/IntlMessages";

const ThanksForApplying = () => (
  <Card style={{height: "100%"}}>
      <div className="gx-page-error-content" style={{verticalAlign: "middle"}}>
        <Card className="gx-card" >
          <div className="gx-mb-4"><h2>Thank You for your Application. We will contact you soon.</h2><p>All the Best!!!</p></div>
          <p className="gx-text-left">
            <Link className="gx-btn gx-btn-primary" to="/"><IntlMessages id="extraPages.goHome"/></Link>
          </p>
        </Card>
      </div>
  </Card>
);

export default ThanksForApplying;
