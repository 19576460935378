import React, {Component} from 'react';
import {connect} from "react-redux";
import {API_URL_V1} from "../../../../constants";
import {message} from "antd";
import axios from "axios";
import {CardElement, Elements} from '@stripe/react-stripe-js';

class StripeCheckout extends Component {

  state = {
    buttonLoading: false,
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    const {stripe, elements, authUser, currentCompany} = this.props;
    const {error, paymentMethod} = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardElement),
    });
    if(error) {
      message.error(error.message);
    }
    if(paymentMethod) {
      this.setState({...this.state, buttonLoading: true});
      axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/billing/subscribe`, {
        product_id: this.props.planId,
        payment_method: paymentMethod.id,
      }, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(subscribeData => {
        this.setState({...this.state, buttonLoading: false},() => {
          this.props.subscriptionInitiated();
        });
        message.success('Subscribed Successfully');
      })
      .catch(error => {
        this.setState({...this.state, buttonLoading: false});
        message.error('Something went wrong');
      })
    }
  };

  render() {
    const {stripe} = this.props;
    console.log(this.props.planId);
    return (
      <div>
        <form onSubmit={this.handleSubmit} className="gx-mt-5">
          <label>
            Card Detail
          </label>
          <CardElement
            options={{
              style: {
                base: {
                  fontSize: '16px',
                  color: '#424770',
                  '::placeholder': {
                    color: '#aab7c4',
                  },
                },
                invalid: {
                  color: '#9e2146',
                },
              },
            }}
          />
          <button className={`ant-btn ant-btn-primary ant-btn-block ${this.state.buttonLoading ? 'ant-btn-loading' : ''}`} type="submit" disabled={!stripe}>
            Pay
          </button>
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentEmployee, currentCompany} = company;
  return {authUser, currentEmployee, currentCompany}
};

export default connect(mapStateToProps)(StripeCheckout);
