import React, {Component} from "react";
import Form from "react-jsonschema-form";
import languageData from "../../../containers/Topbar/languageData";
import {switchLanguage} from "../../../appRedux/actions/Setting";
import {connect} from "react-redux";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Select, Alert, Card, Row, Col, Spin} from "antd";
import CircularProgress from "components/CircularProgress/index";
import Cookies from "universal-cookie";
import axios from "axios";
import Auxiliary from "../../../util/Auxiliary";
import Avatar from "./Avatar";
import {getCurrentEmployeeComplete} from "appRedux/actions/Company";
import {API_URL_V1} from '../../../constants';

const Option = Select.Option;
const schema = {
  type: "object",
  required: [],
  properties: {
    language: {type: "string", title: "Language :"},
    password: {type: "string", title: "Password :"},
    confirm_password: {type: "string", title: "Confirm Password :"},
  }
};

const uiSchema = {
  language: {"ui:widget": (props) => {
    return (
      <Select className="ant-select" placeholder="Select Language" value={props.value} onChange={(value) => props.onChange(value)}>
        <Option value="English">English</Option>
        <Option value="Chinese">Chinese</Option>
        <Option value="Spanish">Spanish</Option>
        <Option value="French">French</Option>
        <Option value="Italian">Italian</Option>
        <Option value="Arabic">Arabic</Option>
      </Select>
    );
  }},
  password: {"ui:widget": (props) => {
    return (
      <input type="password" className="ant-input" placeholder="Password" value={props.value} onChange={(event) => props.onChange(event.target.value)}/>
    );
  }},
  confirm_password: {"ui:widget": (props) => {
    return (
      <input type="password" className="ant-input" placeholder="Confirm Password" value={props.value} onChange={(event) => props.onChange(event.target.value)}/>
    );
  }},
};

const log = (type) => console.log.bind(console, type);

function CustomFieldTemplate(props) {
  return props.displayLabel
    ? <div className="ant-row ant-form-item">
        {props.required ?
        <label htmlFor={props.id} className="ant-form-item-label ant-form-item-required ant-col-xs-24 ant-col-sm-8">
          {props.label}
        </label> :
        <label htmlFor={props.id} className="ant-form-item-label ant-col-xs-24 ant-col-sm-8">
          {props.label}
        </label>}
        <div className="ant-form-item-control-wrapper ant-col-xs-24 ant-col-sm-16">
          {props.description}
          {props.children}
          {props.errors}
          {props.help}
        </div>
      </div>
    : <div className={props.classNames}>
        {props.children}
      </div>;
};

function validate(formData, errors){
  if (formData.password !== formData.confirm_password) {
    errors.confirm_password.addError("Passwords doesn't match");
  }
  return errors;
}

class MyAccount extends Component {

  state={
    errormsg: '',
    successmsg: '',
  };

  onSubmit = (formData) => {
    const lang = (formData.formData.language).toLowerCase();
    const {authUser} = this.props;
    const cookies = new Cookies();
    cookies.set('language', lang, { path: '/' });
    this.languageMenus(lang);
    axios.post(`${API_URL_V1}/me`, {
      password: formData.formData.password,
      password_confirmation: formData.formData.confirm_password
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(userData => {
      if(formData.formData.password == null)
      {
        this.setState({...this.state, successmsg: "Language Changed Successfully", errormsg: ''});
      } else {
          this.setState({...this.state, successmsg: "Password Changed Successfully", errormsg: ''});
        }
    })
    .catch(error => {
      this.setState({...this.state, errormsg: error.response.data.errors.password, successmsg: ''});
    });
  }

  componentDidMount () {
    const cookies = new Cookies();
    const lang = cookies.get('language') ? cookies.get('language') : 'english';
    this.languageMenus(lang);
  }

  languageMenus(lang) {
    languageData.map(language => {
      if(lang == language.languageId ) {
        lang = language;
      }
      this.props.switchLanguage(lang);
    })
  };

  validate = (formData, errors) => {
    if (formData.password !== formData.confirm_password) {
      errors.confirm_password.addError("Passwords doesn't match");
    }
    return errors;
  }
  onClose = () => {
    this.setState({...this.state, successmsg: "", errormsg: ""});
  }

  avatarUpdated = (avatar) => {
    let timestamp = new Date().getTime();
    this.props.getCurrentEmployeeComplete({...this.props.currentEmployee, avatar: `${avatar}&${timestamp}`})
  }

  render() {
    let {name} = this.props.locale;
    const formData = {language: name};

    let avatarUrl = "";
    if (this.props.currentEmployee && this.props.currentEmployee.avatar) {
      console.log(this.props.currentEmployee);
      avatarUrl = `${API_URL_V1}${this.props.currentEmployee.avatar}`;
    }
    console.log(avatarUrl)

    return (
      <Auxiliary>
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Card className="gx-card" title="My Account">
              <p>Manage your core account settings here.</p>
              {this.props.currentEmployee && this.props.currentEmployee ?
                <div style={{width: "90%"}}>
                  <div style={{paddingBottom : "10px"}}>
                  {this.state.successmsg ?
                    <Alert message={this.state.successmsg} type="success" closable style={{marginLeft: "35%"}} onClose={this.onClose} /> : null
                  }
                  {this.state.errormsg ?
                    <Alert message={this.state.errormsg} type="error" closable style={{marginLeft: "35%"}} onClose={this.onClose} /> : null
                  }
                  </div>
                  <div style={{marginLeft: "35%"}}>
                     <Avatar token={this.props.authUser} avatarUrl={avatarUrl} onUpdate={this.avatarUpdated} currentCompany={this.props.currentCompany}/>
                  </div>
                  <Form
                    schema={schema}
                    uiSchema={uiSchema}
                    formData={formData}
                    onSubmit={this.onSubmit}
                    validate={validate}
                    FieldTemplate={CustomFieldTemplate}
                    onError={log("errors")}
                    showErrorList={false} >
                    <div className="ant-row ant-form-item">
                      <div className="ant-col-xs-24 ant-col-sm-8"></div>
                      <div className="ant-col-xs-24 ant-col-sm-16">
                        <button type="submit" className="ant-btn btn-primary">Submit</button>
                      </div>
                    </div>
                  </Form>
                </div> : <CircularProgress />}
            </Card>
          </Col>
        </Row>
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({settings, auth, company}) => {
  const {locale} = settings;
  const {currentCompany, currentEmployee} = company;
  const {authUser, user} = auth;
  return {locale, authUser, currentEmployee, user, currentCompany}
};
export default connect(mapStateToProps, {
  switchLanguage, getCurrentEmployeeComplete
})(MyAccount);
