import React, {Component} from "react";
import {Modal, Form, Input, Checkbox, DatePicker, TimePicker, Select, Button} from 'antd';
import moment from 'moment';

const {TextArea} = Input;

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends Component {
    render() {
      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      };
      const { getFieldDecorator } = this.props.form;
      return (
        <Modal
          visible={this.props.visible}
          title={this.props.title}
          onCancel={this.props.onCancel}
          onOk={this.props.onCreate}
          width={450}
          footer={[
            <Button key="submit" loading={this.props.loading} onClick={this.props.onCreate}>
              Approve
            </Button>,
            <Button key="cancel" onClick={this.props.onCancel}>
              Cancel
            </Button>
          ]}
        >
          <Form >
            <Form.Item
            {...tailFormItemLayout}>
              {getFieldDecorator('notify_employee', {
              valuePropName: 'checked',
              initialValue: true,
              })(
                <Checkbox>Notify Employees</Checkbox>
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

class ApproveModal extends React.Component {

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onOk(values);
    });
  }

  render() {
    return (
      <div>

        <CollectionCreateForm
          title={this.props.title}
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          onCreate={this.handleCreate}
          loading={this.props.loading}
        />

      </div>
    );
  }
}

export default ApproveModal;
