import React, {Component} from "react";
import {connect} from "react-redux";
import {Select, Row, Col, Card} from "antd";
import {API_URL_V1} from "../../../constants";
import AttendanceFilter from "./AttendanceFilter";
import Calendar from "react-calendar";
import CircularProgress from "components/CircularProgress/index";
import _ from "lodash";
import Auxiliary from "util/Auxiliary";
import axios from "axios";
import moment from "moment";

class AttendanceReport extends Component {
  state={
    date: new Date(),
    month: [],
    year: [],
    employee_id: '',
    start: '',
    end: '',
    attendance: [],
    attendanceStats: {
      working_days: '',
      present: '',
    },
    created_at: '',
    grandTotal: 0,
    trackedTotal: 0,
    loading: true,
  };

  getAttendanceData = (filterData) => {
    this.setState({...this.state, attendance: [], loading: true});
    let {authUser, currentCompany} = this.props;
    let employee_id = filterData && filterData.employee_id ? filterData.employee_id : this.props.currentEmployee.id;
    if(filterData) {
      if(filterData.date) {
        let timeValues = [], year = [];
        let start = moment(filterData.date[0]);
        let end = moment(filterData.date[1]);
        while (end > start || start.format('M') === end.format('M')) {
           timeValues.push(start.format('MM'));
           year.push(start.format('YYYY'));
           start.add(1,'month');
        }
        this.setState({...this.state, month: timeValues, year: year});
      }
    }
    axios.all([
      axios.get(`${API_URL_V1}/employees/${employee_id}/attendances-custom`, {
        params: {
          from: filterData && filterData.date ? filterData.date[0] : moment(this.state.start).format('YYYY-MM-DD'),
          to: filterData && filterData.date ? filterData.date[1] : moment(this.state.end).format('YYYY-MM-DD'),
        },
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/reports/tracker/totals`, {
          start_date: filterData && filterData.date ? filterData.date[0] : moment(this.state.start).format('YYYY-MM-DD'),
          end_date: filterData && filterData.date ? filterData.date[1] : moment(this.state.end).format('YYYY-MM-DD'),
          employee_ids: employee_id,
        }, {
          headers: {
            'Authorization': 'Bearer '+authUser
          }
      }),
    ])
    .then(axios.spread((attendanceData, trackedData) => {
      let grandTotal = 0, trackedTotal = 0;
      let attendanceArray = [], attendanceTime=[], monthlyAttendanceArray={}, attendanceStats = this.state.attendanceStats;
      let attendanceByDay =_.groupBy(attendanceData.data.data.attendance, data => moment(data.date).format("YYYY-MM-DD"));
      trackedData.data.graph.map((track, index) => {
        trackedTotal = trackedTotal+(track.seconds*1000);
      })
      Object.keys(attendanceByDay).map((attendance, index) => {
        let total=0, totalTime=0;
        attendanceByDay[attendance].forEach((value, i) => {
          total = total + moment(value.out_at).diff(value.in_at);
          if(value.out_at) {
            totalTime = totalTime + moment(value.out_at).diff(value.in_at);
          }
        })
        attendanceTime[attendance] = total;
        grandTotal = grandTotal + totalTime;
      })
      let result = Object.keys(attendanceData.data.data.all_days).map(function(key) {
        return attendanceData.data.data.all_days[key];
      });
      attendanceStats.working_days=0; attendanceStats.present=0;
      result.map((res, index) => {
        if((res.is_working || res.type === 'leave') && moment(res.date).diff(moment(new Date())) <= 0 && moment(res.date).diff(moment(this.state.created_at)) >= 0) {
          attendanceStats.working_days++;
        }
        res.present = false;
        res.total = 0;
        attendanceArray.push(res);
        Object.keys(attendanceData.data.data.attendance).map((attendance) => {
          if(res.date == attendanceData.data.data.attendance[attendance].date) {
            attendanceArray[index].present=true;
            attendanceArray[index].total=attendanceTime[attendanceData.data.data.attendance[attendance].date];
          }
        })
      })
      attendanceArray.forEach((attendance, index) => {
        if(attendance.present && moment(attendance.date).diff(moment(new Date())) <= 0 && moment(attendance.date).diff(moment(this.state.created_at)) >= 0) {
          attendanceStats.present++;
        }
        monthlyAttendanceArray[moment(attendance.date).format("YYYY-MM")] = [];
      })
      attendanceArray.forEach((attendance, index) => {
        monthlyAttendanceArray[moment(attendance.date).format("YYYY-MM")].push(attendance);
      })
      console.log(trackedTotal)
      this.setState({...this.state, attendance: monthlyAttendanceArray, attendanceStats: attendanceStats, grandTotal: grandTotal, trackedTotal: trackedTotal, loading: false});
    }))
    .catch(error => error)
  }

  componentDidMount () {
    let timeValues =[], year = [];
    let thisYear = (new Date()).getFullYear();
    let startDay = new Date("1/1/" + thisYear);
    let endDay = new Date("12/31/" + thisYear);
    let start = moment(startDay.valueOf());
    let startPoint = moment(startDay.valueOf());
    let end = moment(endDay.valueOf());
    while (end > start || start.format('M') === end.format('M')) {
       timeValues.push(start.format('MM'));
       year.push(thisYear);
       start.add(1,'month');
    }
    this.props.employees.map((employee, index) => {
      if(this.props.currentEmployee.id === employee.id) {
        this.setState({...this.state, month: timeValues, year: year, start: startPoint, end: end, created_at: moment(employee.created_at).format('YYYY-MM-DD')},() => {this.getAttendanceData()})
      }
    })

  }

  filterAttendanceData = (filterData) => {
    this.setState({...this.state, created_at: filterData.created_at ? filterData.created_at : this.state.created_at, loading: true},() => {this.getAttendanceData(filterData)});
  }

  tileClassName = ({date, view}) => {
    let className=[];
    this.state.attendance[moment(date).format('YYYY-MM')].map((attendance, index) => {
      if(moment(date).format("YYYY-MM-DD") === (moment(attendance.date).format("YYYY-MM-DD")) && moment(date).diff(moment(new Date())) < 0 && moment(date).diff(moment(this.state.created_at)) >= 0) {
        if(attendance.present === true && attendance.total >= 28800000) {
          className.push("react-calendar__present");
        } else if(attendance.present === true && attendance.total <= 28800000) {
          className.push("react-calendar__undertime");
        } else if(attendance.type === 'weekend' || attendance.type === 'holiday') {
          className.push("react-calendar__holiday");
        } else {
          className.push("react-calendar__absent");
        }
      }
    })
    return className;
  }

  tileDisabled = (activeStartDate, date) => {
    return true;
  }

  render() {
    return (
      <div>
      <AttendanceFilter
        employees={this.props.employees}
        currentEmployee={this.props.currentEmployee}
        filterAttendanceData={this.filterAttendanceData}
      />
      {this.state.loading ? <CircularProgress /> :
      <Auxiliary>
        <Card>
          <Row type="flex" justify="space-around" align="middle">
            <Col className={'text-center'}> <h2>{this.state.attendanceStats ? this.state.attendanceStats.working_days : "--"}</h2> WORKING DAYS</Col>
            <Col className={'text-center'}> <h2>{this.state.attendanceStats ? this.state.attendanceStats.present : "--"}</h2> PRESENT</Col>
            <Col className={'text-center'}> <h2>{this.state.attendanceStats ? this.state.attendanceStats.working_days - this.state.attendanceStats.present : "--"}</h2> ABSENT</Col>
          </Row>
          <br/>
          <Row type="flex" justify="space-around" align="middle">
            <Col className={'text-center'}> <h2>{this.state.grandTotal ? ((Math.floor(moment.duration(this.state.grandTotal).asHours()) < 10) ? `0${Math.floor(moment.duration(this.state.grandTotal).asHours())}` : Math.floor(moment.duration(this.state.grandTotal).asHours())) + moment.utc(this.state.grandTotal).format(":mm:ss") : "00:00:00"}</h2> TOTAL WORKING HOURS</Col>
            <Col className={'text-center'}> <h2>{this.state.grandTotal ? ((Math.floor(moment.duration(this.state.grandTotal/this.state.attendanceStats.present).asHours()) < 10) ? `0${Math.floor(moment.duration(this.state.grandTotal/this.state.attendanceStats.present).asHours())}` : Math.floor(moment.duration(this.state.grandTotal/this.state.attendanceStats.present).asHours())) + moment.utc(this.state.grandTotal/this.state.attendanceStats.present).format(":mm:ss") : "00:00:00"}</h2> AVG. WORKING HOURS</Col>
            <Col className={'text-center'}> <h2>{this.state.trackedTotal ? ((Math.floor(moment.duration(this.state.trackedTotal).asHours()) < 10) ? `0${Math.floor(moment.duration(this.state.trackedTotal).asHours())}` : Math.floor(moment.duration(this.state.trackedTotal).asHours())) + moment.utc(this.state.trackedTotal).format(":mm:ss") : "00:00:00"}</h2> TRACKED HOURS</Col>
            <Col className={'text-center'}> <h2>{this.state.trackedTotal ? ((Math.floor(moment.duration(this.state.trackedTotal/this.state.attendanceStats.present).asHours()) < 10) ? `0${Math.floor(moment.duration(this.state.trackedTotal/this.state.attendanceStats.present).asHours())}` : Math.floor(moment.duration(this.state.trackedTotal/this.state.attendanceStats.present).asHours())) + moment.utc(this.state.trackedTotal/this.state.attendanceStats.present).format(":mm:ss") : "00:00:00"}</h2> AVG. TRACKED HOURS</Col>
          </Row>
        </Card>
        <Row type="flex" justify="space-around" align="middle" gutter={8}>
        {this.state.month.map((item, index) => {
          return(
            <div className="gx-mb-2 attendance-report">
              <div style={{display: "flex", justifyContent: "center"}}><b>{moment(item,'MM').format('MMMM')}</b></div>
              <Col className={'text-center attendance-report'}>
                <Calendar
                  onChange={this.onChange}
                  value={new Date(item+'/1/'+this.state.year[index])}
                  showNavigation={false}
                  showNeighboringMonth={false}
                  tileClassName={this.tileClassName}
                  tileDisabled={this.tileDisabled}
                />
              </Col>
            </div>
        )})}
        </Row>
      </Auxiliary>}
      </div>
    )
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentEmployee, currentCompany} = company;
  return {authUser, currentEmployee, currentCompany}
};

export default connect(mapStateToProps)(AttendanceReport);
