import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import {List, Button, Alert, Card, Row, Col} from "antd";
import axios from "axios";
import {switchCompany, setDefaultCompany} from "appRedux/actions/Company";
import {API_URL_V1} from '../constants';

class Adaptor extends Component {

    state={
        errormsg: '',
        successmsg: '',
        companiesLoading: false,
        redirectToReferrer: false,
        showCreate: false
    };

    getCompany = (id) => {
        console.log(id);
        const {authUser} = this.props;
        axios.get(`${API_URL_V1}/workspaces/${id}`, {
            headers: {
                'Authorization': 'Bearer '+authUser
            }
        })
            .then(({data}) => {
                const company = data.data;
                localStorage.setItem('id', company.id);
                    this.props.switchCompany(company);
                    this.props.setDefaultCompany(company.id);
                    this.setState({...this.state, companiesLoading: false, redirectToReferrer: true})
                    })
            .catch(error => {
                console.log(error);
                this.setState({...this.state, companiesLoading: false, redirectToReferrer: true})
            });
    }



    componentDidMount () {
        let params = new URLSearchParams(this.props.location.search);
        this.getCompany(params.get('_c'));
    }

    showCreateModel = () => {
        console.log("Show model")
        this.setState({...this.state,
            showCreate: true
        });
    }

    hideCreateModel = () => {
        this.setState({...this.state,
            showCreate: false
        });
    }


    render() {
        let { redirectToReferrer } = this.state;
        let params = new URLSearchParams(this.props.location.search);
        let next = params.has('next')? params.get('next') : '/dashboard';
        if (redirectToReferrer) return <Redirect to={next} />;
        return (
            <Row>
            </Row>
        );
    }
}

const mapStateToProps = ({auth, company}) => {
    const {authUser, user} = auth;
    return {authUser, user}
};
export default connect(mapStateToProps, {
    switchCompany,
    setDefaultCompany,
})(Adaptor);
