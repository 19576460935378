import React, {Component} from "react";
import {connect} from "react-redux";
import {Input, Row, Col, DatePicker, Dropdown, Menu, Card, Popover, Divider, Modal, Button, message} from "antd";
import {FaDollarSign} from "react-icons/fa";
import moment from 'moment';
import {API_URL_V1} from "../../../constants";
import axios from "axios";

const { RangePicker } = DatePicker;

class TimeEntryItems extends Component {
  constructor(props) {
    super(props);
    this.descriptionRef = React.createRef();
    this.state = {
      visible: false,
      billable: this.props.data.billable,
      selectedProject: this.props.data.project_id,
      value: this.props.data.description,
      rangeValue: [moment.utc(this.props.data.start).local(), moment.utc(this.props.data.end).local()],
      width: (this.props.data.description !==null) ? ((this.props.data.description).length * 8) : 200,
      duration: this.props.data.duration,
      restrictModal: false,
      update: false
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      visible: false,
      billable: nextProps.data.billable,
      selectedProject: nextProps.data.project_id,
      value: nextProps.data.description,
      rangeValue: [moment.utc(nextProps.data.start).local(), moment.utc(nextProps.data.end).local()],
      width: (this.props.data.description !==null) ? ((this.props.data.description).length * 8) : 200,
      duration: nextProps.data.duration,
    });
  }

  hide = () => {
    this.setState({...this.state, visible: false })
  }

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  }

  onClick = () => {
    this.setState({...this.state, billable: !this.state.billable}, () => {message.success(this.state.billable ? "Time Entry marked as Billable" : "Time Entry marked as Non-Billable")});
  }

  changeProject = (e, id) => {
    let projectId = e.key;
    if(e.key == 0) {
      projectId = '';
    }
    this.setState({...this.state, selectedProject: e.key});
    this.props.handleInputChange(projectId, id, this.state.value);
  }

  change = (e) => {
    //console.log(this.state.width);
    let width = document.getElementById("projContent").offsetWidth;
    console.log(width);
    this.setState({...this.state, value: e.target.value, width: (((e.target.value).length +1)*10)})
  }

  onDateChange = (v) => {
    this.setState({...this.state, rangeValue: v})
  }

  onOk = (e, id) => {
    this.props.updateTime(e, id);
  }

  playClicked = () => {
    if(this.props.timer_desc_required && this.descriptionRef.current.input.value === '') {
      this.setState({...this.state, update: false, restrictModal: true});
    } else {
      this.props.resumeEntry(this.props.data);
    }
  }

  handleRestrictCancel = () => {
    this.setState({...this.state, restrictModal: false});
  }

  render() {
    const {description, id, start, end, billable} = this.props.data;
    let project = this.props.projectData.filter(project => project.id == this.state.selectedProject);
    const menu = (
      <Menu onClick={(e) => this.changeProject(e, id)}>
        {this.props.projectOptions}
      </Menu>
    );
    const menuOptions = (
      <ul className="gx-user-popover">
        <li onClick={(e) => {this.props.onAddEntry(this.props.data); this.hide()}}>Duplicate</li>
        <li onClick={(e) => {this.props.deleteEntry(id); this.hide()}}>Delete</li>
      </ul>
    );
    return (
      <div>
        <Modal
          title="Not Allowed"
          visible={this.state.restrictModal}
          onCancel={this.handleRestrictCancel}
          footer={[
            <Button key="ok" onClick={this.handleRestrictCancel}>
              OK
            </Button>
          ]}
        >
          <p>{this.state.update ? "You cannot leave description blank" : "You cannot start timer without description"}</p>
        </Modal>
        <div className="timer-container">
          <Row gutter={8}>
            <Col lg={14} md={12} sm={12} xs={22}>
              <Input ref={this.descriptionRef} id="projContent" className="gx-border-0" placeholder="Add Description" size="small" value={this.state.value} onChange={this.change} onPressEnter={() => {
                if(this.props.timer_desc_required && this.descriptionRef.current.input.value === '') {
                  this.setState({...this.state, restrictModal: true, update: true});
                } else {
                  this.props.handleInputChange(this.state.selectedProject, id, this.state.value)
                }}} style={{minWidth: "150px", maxWidth: "300px", width: this.state.width}}/>
              <span> </span>
              <Dropdown overlay={menu} trigger={['click']}>
                <span className={(project.length > 0) ? "" : "timer-component"} style={{ userSelect: 'none', fontSize: "16px", cursor: 'pointer', color: (project.length > 0) ? "#1ad6a0":"#5e5cdb" }}>{(project.length > 0) ? project[0].name : <span><i className="icon icon-add-circle gx-pointer gx-fs-lg" title="Add Project" style={{position: "relative", top: "3px"}}/> Add Project</span>}</span>
              </Dropdown>
            </Col>
            <Col lg={1} md={12} sm={12} xs={2}>
              <FaDollarSign className={`gx-mb-2 ${this.state.billable ? "" : "timer-component"}`} size="1.5em" style={{color: this.state.billable ? "#038fde" : "#bfbfbc", cursor: "pointer"}} onClick={()=>{this.onClick()}}/>
            </Col>
            <Col lg={5} md={18} sm={15} xs={0}>
            <div className="rangepicker-parent">
              <RangePicker
                className="gx-mb-2"
                placeholder={['Start Time', 'End Time']}
                value={this.state.rangeValue}
                onChange={(v) => {this.onDateChange(v)}}
                showTime = {{ format: 'HH:mm' }}
                suffixIcon={" "}
                allowClear={false}
                format="hh:mm A"
                size="small"
                style={{width: "95%"}}
                onOk={(e) => {this.onOk(e, id)}}
              />
              </div>
            </Col>
            <Col lg={2} md={3} sm={5} xs={12}>
              <div style={{padding: "2px 5px"}}>{moment.utc((this.state.duration)*1000).format('HH:mm:ss')}</div>
            </Col>
            <Col lg={1} md={2} sm={3} xs={11}>
              <span className="timer-component" style={{position: "absolute", margin: 3, paddingLeft: 10, cursor: 'pointer'}} onClick={this.playClicked}>
                <i className="icon icon-menu-right" title="Continue Timer"/>
              </span>
            </Col>
            <Col lg={1} md={1} sm={1} xs={1}>
            <Popover placement="leftTop" content={menuOptions} trigger="click" visible={this.state.visible} onVisibleChange={this.handleVisibleChange}>
            <span className="gx-link ant-dropdown-link gx-text-grey" style={{position: "absolute", margin: 2}}>
              <i className="icon icon-ellipse-v"/>
            </span>
          </Popover>
          </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee, timer_desc_required} = company;
  return {authUser, currentCompany, currentEmployee, timer_desc_required}
};

export default connect(mapStateToProps)(TimeEntryItems);
