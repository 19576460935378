import React from "react";
import {Divider, Row, Col, Icon} from "antd";
import { Link } from 'react-router-dom'
import Widget from "components/Widget";
import TaskItem from "./TaskItem";
import CircularProgress from "components/CircularProgress/index";

class TaskList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {taskList: props.taskList}
  }

  componentWillReceiveProps(nextProps) {
    this.setState({...this.state, taskList: nextProps.taskList});
  }

  render() {
    return (
      <Widget title={
          <h2 className="h4 gx-text-capitalize gx-mb-0">
            Task List</h2>
        } styleName="gx-card-tabs"
          extra={<span className="gx-ml-2 gx-search-icon" onClick={() => {this.props.refresh()}}><i
            className="icon icon-frequent gx-pointer gx-text-primary gx-fs-xxl"/></span>}  height="92.5%">
        { this.props.loader ? <CircularProgress height="250px"/> :
          ((this.state.taskList).length !== 0) ? this.state.taskList.map((task, index) =>
          (index < 7) ?
          <TaskItem key={index} data={task} projects={this.props.projects}/> : (index === 8) ?
          <div>
          <Divider type="horizontal" style={{marginTop: 0}}/>
          <Link className="gx-text-primary gx-text-truncate gx-mt-auto gx-mb-0 gx-pointer" style={{float: "right"}} to={{pathname: `/projects`, state: {tab: 'tasks'}}}>See all Tasks
          <i className={`icon icon-long-arrow-right gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle`}/></Link></div> : '' ) :
          <div>
            <Row type="flex" justify="center" className="gx-mb-3">
              <Col xs={18} lg={9} className="text-center">
              <div className="gx-mt-4">
                <Icon type="coffee" style={{fontSize: "40px", color: "#52c41a"}}/>
              </div>
              <div className="gx-mt-4">
                No Pending Tasks
              </div>
              </Col>
            </Row>
          </div>
        }
      </Widget>
    );
  }
}


export default TaskList;
