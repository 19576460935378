import React, {Component} from "react";
import {Card, Form, Input, Button, Slider, Icon, Row, Col, message} from "antd";
import Auxiliary from "../util/Auxiliary";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../constants";
import axios from "axios";

const { TextArea } = Input;
class MonthlyFeedback extends Component{
  state = {
    questions: [],
    value: 0,
    name: '',
    privacy: '',
    loading: true,
  }

  getQuestions = () => {
    let companyId = this.props.match.params.id;
    let survey_id = this.props.match.params.survey_id;
    let params = new URLSearchParams(this.props.location.search);
    let key = this.props.match.params.key;
    axios.get(`${API_URL_V1}/public/workspace/${companyId}/es/survey/${survey_id}/questions/${key}`, {
    })
    .then(questionsData => {
      this.setState({...this.state, questions: questionsData.data.questions, name: questionsData.data.name, privacy: questionsData.data.privacy, loading: false});
    })
    .catch(error => {
      if(key) {
        message.error("Something went wrong. Please try again.")
      } else {
        message.error("Invalid Key");
      }
    })
  }

  componentDidMount () {
    this.getQuestions();
  }

  handleChange = value => {
    this.setState({ ...this.state, value: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    let companyId = this.props.match.params.id;
    let survey_id = this.props.match.params.survey_id;
    let key = this.props.match.params.key;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let answers = [];
        Object.keys(values).map((value, index) => {
          let data = {};
          data.es_question_id = value.match(/\d+/)[0];
          data.answer = values[value].toString();
          answers.push(data);
        })
        axios.post(`${API_URL_V1}/public/workspace/${companyId}/es/survey/${survey_id}/feedback`, {
          answers,
          key: key
        })
        .then(feedbackData => {
          this.props.history.push('/feedback/thank_you');
        })
        .catch(error => {
          message.error("Something went wrong");
        })
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
      },
    };
    let marks={1: <Icon style={{ color: "#ff0000" }} type="frown-o" />,
               2: '2', 3: <Icon style={{ color: "#b3b300" }} type="meh-o" />,
               4: '4', 5: <Icon style={{ color: "#00ff00" }} type="smile-o" />}
               console.log(this.state.questions)
    return(
      <Auxiliary>
        <Card className="gx-card" title=<div style={{fontSize: "24px", textAlign: "center", wordWrap: "break-word", whiteSpace: "initial"}}>{this.state.name}</div> style={{height: "100%", overflow: "scroll"}}>
          <Row>
            <Col xs={{offset: 0, span: 24}} md={{offset: 6, span: 12}} >
            <div style={{fontSize: "16px", textAlign:'center'}}>This survey is {this.state.privacy}</div>
            {this.state.loading ? <CircularProgress /> :
                <Form {...formItemLayout} onSubmit={this.handleSubmit} labelAlign="left" colon={false}>
                  {this.state.questions.map((question, index) =>
                   <Form.Item label={question.question}>
                     {getFieldDecorator(`item_${question.id}`, {
                       initialValue: question.answer_type === 'text' ? '' : 1,
                       rules: [{required: true, message: question.answer_type === 'text' ? 'This field is required' : 'Please rate on scale of 1-5'}],
                     })(
                        question.answer_type === 'text' ?
                        <TextArea placeholder="Please answer this question here" />
                        : <Slider min={1} max={5} onChange={this.handleChange} marks={marks} dots/>
                     )}
                   </Form.Item>
                 )}
                 <Form.Item {...tailFormItemLayout}>
                   <Button type="primary" htmlType="submit">
                     Submit Feedback
                   </Button>
                 </Form.Item>
                </Form>
              }
            </Col>
          </Row>
        </Card>
      </Auxiliary>
    );
  }
}
const WrappedFeedbackForm = Form.create()(MonthlyFeedback);

export default WrappedFeedbackForm;
