import React, {Component} from "react";
import {Checkbox, Table} from "antd";

const CheckboxGroup = Checkbox.Group;
const checkboxArray = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
const plainOptions = ['1', '2', '3', '4', '5'];

class WorkDays extends Component {
  state={
    checkedList: {
      Sun: (this.props.workDaysData && this.props.workDaysData.Sun) ? this.props.workDaysData.Sun : this.props.workDaysData.includes('Sun') ? plainOptions : [],
      Mon: (this.props.workDaysData && this.props.workDaysData.Mon) ? this.props.workDaysData.Mon : this.props.workDaysData.includes('Mon') ? plainOptions : [],
      Tue: (this.props.workDaysData && this.props.workDaysData.Tue) ? this.props.workDaysData.Tue : this.props.workDaysData.includes('Tue') ? plainOptions : [],
      Wed: (this.props.workDaysData && this.props.workDaysData.Wed) ? this.props.workDaysData.Wed : this.props.workDaysData.includes('Wed') ? plainOptions : [],
      Thu: (this.props.workDaysData && this.props.workDaysData.Thu) ? this.props.workDaysData.Thu : this.props.workDaysData.includes('Thu') ? plainOptions : [],
      Fri: (this.props.workDaysData && this.props.workDaysData.Fri) ? this.props.workDaysData.Fri : this.props.workDaysData.includes('Fri') ? plainOptions : [],
      Sat: (this.props.workDaysData && this.props.workDaysData.Sat) ? this.props.workDaysData.Sat : this.props.workDaysData.includes('Sat') ? plainOptions : [],
    },
    indeterminate: {
      Sun: (this.props.workDaysData && this.props.workDaysData.Sun) ? (this.props.workDaysData.Sun.length < 5 && this.props.workDaysData.Sun.length !== 0): false,
      Mon: (this.props.workDaysData && this.props.workDaysData.Mon) ? (this.props.workDaysData.Mon.length < 5 && this.props.workDaysData.Mon.length !== 0): false,
      Tue: (this.props.workDaysData && this.props.workDaysData.Tue) ? (this.props.workDaysData.Tue.length < 5 && this.props.workDaysData.Tue.length !== 0): false,
      Wed: (this.props.workDaysData && this.props.workDaysData.Wed) ? (this.props.workDaysData.Wed.length < 5 && this.props.workDaysData.Wed.length !== 0): false,
      Thu: (this.props.workDaysData && this.props.workDaysData.Thu) ? (this.props.workDaysData.Thu.length < 5 && this.props.workDaysData.Thu.length !== 0): false,
      Fri: (this.props.workDaysData && this.props.workDaysData.Fri) ? (this.props.workDaysData.Fri.length < 5 && this.props.workDaysData.Fri.length !== 0): false,
      Sat: (this.props.workDaysData && this.props.workDaysData.Sat) ? (this.props.workDaysData.Sat.length < 5 && this.props.workDaysData.Sat.length !== 0): false,
    },
    checkAll: {
      Sun: (this.props.workDaysData && this.props.workDaysData.Sun) ? (this.props.workDaysData.Sun.length === 5): this.props.workDaysData.includes('Sun') ? true : false,
      Mon: (this.props.workDaysData && this.props.workDaysData.Mon) ? (this.props.workDaysData.Mon.length === 5): this.props.workDaysData.includes('Mon') ? true : false,
      Tue: (this.props.workDaysData && this.props.workDaysData.Tue) ? (this.props.workDaysData.Tue.length === 5): this.props.workDaysData.includes('Tue') ? true : false,
      Wed: (this.props.workDaysData && this.props.workDaysData.Wed) ? (this.props.workDaysData.Wed.length === 5): this.props.workDaysData.includes('Wed') ? true : false,
      Thu: (this.props.workDaysData && this.props.workDaysData.Thu) ? (this.props.workDaysData.Thu.length === 5): this.props.workDaysData.includes('Thu') ? true : false,
      Fri: (this.props.workDaysData && this.props.workDaysData.Fri) ? (this.props.workDaysData.Fri.length === 5): this.props.workDaysData.includes('Fri') ? true : false,
      Sat: (this.props.workDaysData && this.props.workDaysData.Sat) ? (this.props.workDaysData.Sat.length === 5): this.props.workDaysData.includes('Sat') ? true : false,
    },
    advanceSetting: false,
  }

  onCheckboxChange = (checkedList, val) => {
    this.setState({
      ...this.state,
      checkedList: {
        ...this.state.checkedList,
        [val]: checkedList,
      },
      indeterminate: {
        ...this.state.indeterminate,
        [val]: !!checkedList.length && checkedList.length < plainOptions.length,
      },
      checkAll: {
        ...this.state.checkAll,
        [val]: checkedList.length === plainOptions.length,
      },
    }, () => {this.props.values(this.state)});
  };

  onCheckAllChange = (e, check) => {
    this.setState({
      ...this.state,
      checkedList: {
        ...this.state.checkedList,
        [e.target.value]: e.target.checked ? plainOptions : [],
      },
      indeterminate: {
        ...this.state.indeterminate,
        [e.target.value]: false,
      },
      checkAll: {
        ...this.state.checkAll,
        [e.target.value]: e.target.checked,
      }
    }, () => {this.props.values(this.state)});
  };

  render() {
    return(
      <div style={{marginTop: "10px"}}>
        <div style={{ borderBottom: this.state.advanceSetting ? '1px solid #E9E9E9' : '' , paddingBottom: '5px'}}>
        {checkboxArray.map((check, index) => {
          return (
            <Checkbox
                value={check}
                indeterminate={this.state.indeterminate[check]}
                onChange={(e) => {this.onCheckAllChange(e)}}
                checked={this.state.checkAll[check]}
                disabled={this.props.disabled}
              >
                {check}
            </Checkbox>
          )
        })}
        </div>
        <br />
        <div className="gx-link" onClick={(e) => {this.setState({...this.state, advanceSetting: !this.state.advanceSetting})}}>
          {this.state.advanceSetting ? "Hide Advance Settings" : "Show Advance Settings"}
        </div>
        <br />
        {this.state.advanceSetting ?
        <div style={{overflowY: "hidden"}}>
        <table style={{borderCollapse: "collapse"}}>
          <tr>
        {checkboxArray.map((check, index) => {
          return (
            <td style={{border: "1px solid #dddddd", textAlign: "left"}}>
            <div style={{display: "inline-block", width: "64px"}}>
              <div style={{padding: "5px 10px", marginBottom: "5px", backgroundColor: "#E0E0E0"}}>{check}</div>
              <div style={{paddingLeft: "5px"}}>
                <CheckboxGroup
                  options={plainOptions}
                  value={this.state.checkedList[check]}
                  onChange={(e) => {this.onCheckboxChange(e, check)}}
                  disabled={this.props.disabled}
                />
              </div>
            </div>
            </td>
          )
        })}
        </tr>
        </table>
        </div> : '' }
      </div>
    );
  }
}

export default WorkDays;
