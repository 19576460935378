import React, {Component} from "react";
import {connect} from "react-redux";
import { Link } from 'react-router-dom'
import {Button, Card, Table, Modal, Divider, Avatar, Checkbox, message} from "antd";
import Auxiliary from "../../../../util/Auxiliary";
import AddProjectTaskModal from "./AddProjectTaskModal";
import {API_URL_V1} from "../../../../constants";
import CircularProgress from "components/CircularProgress/index";
import axios from "axios";

let disabled;

class ProjectTasks extends Component {
  state = {
    tasks: [],
    loading: true,
    visible: false,
    title: '',
    confirmLoading: false,
    deleteVisible: false,
    deleteLoading: false,
    record: '',
  }

  getProjectTasks () {
    const {authUser, currentCompany} = this.props;
    const project_id = this.props.projectData.id;
    axios.all([
      axios.get(`${API_URL_V1}/projects/${project_id}/tasks`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
    ])
    .then(axios.spread((taskData, employeeData) => {
      this.setState({...this.state, tasks: taskData.data.data, loading: false});
    }))
    .catch(error => error)
  }

  componentDidMount () {
    this.getProjectTasks();
  }

  onClick = () => {
    const {authUser, currentCompany} = this.props;
    this.setState({...this.state, visible: true, title: 'Add Task', editData: ''});
  }

  handleCancel = () => {
    this.setState({...this.state, visible: false});
  }

  edit = (record, e) => {
    e.preventDefault();
    const {authUser} = this.props;
    const project_id = this.props.projectData.id;
    axios.get(`${API_URL_V1}/projects/${project_id}/tasks/${record.id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(taskData => {
      this.setState({...this.state, title: "Edit Task", editData: taskData.data.data, visible: true});
    })
  }

  handleOk = (values, id) => {
    const {authUser} = this.props;
    const project_id = this.props.projectData.id;
    this.setState({...this.state, confirmLoading: true});
    if(!id) {
      axios.post(`${API_URL_V1}/projects/${project_id}/tasks`, {
        title: values.title,
        employee_id: values.employee_id,
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(projectTasksData => {
        this.getProjectTasks();
        this.setState({...this.state, visible: !this.state.visible, confirmLoading: false});
        message.success("Task Added Successfully")
      })
      .catch(error => {
        message.error("Failed to Add Task");
        this.setState({...this.state, confirmLoading: false});
      })
    } else {
      axios.put(`${API_URL_V1}/projects/${project_id}/tasks/${id}`, {
        title: values.title,
        employee_id: values.employee_id,
        status: values.status,
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(projectTasksData => {
        let tasks = this.state.tasks;
        tasks.forEach((task, index) => {
          if(task.id == projectTasksData.data.data.id){
            console.log(typeof(projectTasksData.data.data.employee_id));
            tasks[index] = {...task, ...projectTasksData.data.data};
          }
        })
        message.success("Task Editted Successfully");
        this.setState({...this.state, tasks: tasks, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Edit Task");
        this.setState({...this.state, confirmLoading: false});
      })
    }
  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser} = this.props;
    const project_id = this.props.projectData.id;
    axios.delete(`${API_URL_V1}/projects/${project_id}/tasks/${record.id}`, {
      params: {
        task_id: record.id,
      },

      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(projectData => {
      this.getProjectTasks();
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      message.success("Task Deleted Successfully");
    })
    .catch(error => error)
  }

  onChange = (record, e) => {
    console.log(e.target.checked);
    const {authUser} = this.props;
    const project_id = this.props.projectData.id;
    axios.put(`${API_URL_V1}/projects/${project_id}/tasks/${record.id}`, {
      status: (e.target.checked === true) ? "DONE" : "TODO",
    },
    {
      headers: {
      'Authorization': 'Bearer '+authUser
    }
    })
    .then(projectTasksData => {
      let tasks = this.state.tasks;
      tasks.forEach((task, index) => {
        if(task.id == projectTasksData.data.data.id){
          tasks[index] = {...task, ...projectTasksData.data.data};
        }
      })
      message.success((e.target.checked === true) ? "Task Marked as Completed" : "Task Marked as ToDo");
      this.setState({...this.state, tasks: tasks});
    })
    .catch(error => {
      message.error("Failed to Edit Task");
    })
  }

  render() {
    let filterData = [];
    const employees = this.props.employees;
    if(employees.length > 0) {
      employees.forEach((employee,index) => {
        filterData.push({
          text: employee.first_name+' '+employee.last_name,
          value: employee.id,
        })
      });
    }
    disabled = (this.props.currentEmployee && this.props.currentEmployee.role) ? this.props.currentEmployee.role.permissions.includes("edit_company") : '';
    const columns = [{
        title: 'Status',
        render: (text, record) => (
          <span><Checkbox checked={record.status === "DONE" ? true : false} indeterminate={record.status === "IN PROGRESS" ? true : false} onChange={(e) => {this.onChange(record, e)}}/></span>
        ),
      },{
        title: 'Name',
        render: (text, record) => (
          <span>
            <Link to={{pathname: `/projects/${this.props.projectData.id}/project_detail/task_detail`, state: {taskId: record.id, employees: this.props.employees}}}>
              <span className={(record.status === "DONE") ? 'gx-text-strikethrough' : 'gx-text-hover'}>
                {record.title}
              </span>
            </Link><br/>
            <span className={`gx-badge gx-hover gx-mb-0`} style={{backgroundColor: (record.status === "DONE") ? "#e3fcef" : (record.status==="IN PROGRESS") ? "#deebff" : "#dfe1e6", color: (record.status === "DONE") ? "#236644" : (record.status==="IN PROGRESS") ? "#0d47a6" : "#425275", fontSize: "12px" }}>
              {record.status}
            </span>
          </span>
        ),
        sorter: (a, b) => {return a.title.localeCompare(b.title)},
      }, {
        title: 'Assigned To',
        dataIndex: 'employee_id',
        render: (text, record) => {
          let employee = this.props.employees && this.props.employees.filter( employee => employee.id === record.employee_id);
          if (employee.length > 0){
            return (<span>{employee[0].first_name} {employee[0].last_name}</span>)
          }
          return (

          <span>
          </span>
        )},
        filters: filterData,
        onFilter: (value, record) => record.employee_id ? (record.employee_id).toString().indexOf(value) === 0 : null,
      }, {
        title: 'Actions',
        key: 'x',
        render: (text, record) => (
          <span>
            {disabled ?
              <span className="gx-link" onClick={(e) => {this.edit(record, e)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span>: <span></span>}

            {disabled ?
              <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><Divider type="vertcal"/><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span> :
              <span></span>
            }
          </span>
        )
      }];
    return(
      <div>
      <Modal
        visible={this.state.deleteVisible}
        title="Delete Task?"
        onOk={(e) => this.deleteRecord(this.state.record, e)}
        onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
        confirmLoading={this.state.deleteLoading}
      >
        <p>Are you Sure you want to delete this Task?</p>
      </Modal>
      {this.state.loading ?
      <CircularProgress/> :
      <div>
      {this.state.visible ?
        <AddProjectTaskModal title={this.state.title} employees={this.props.employees} value={this.state.editData} visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel} confirmLoading={this.state.confirmLoading}/> : null }
      <Auxiliary>
        <div><b>Tasks</b></div><div>All Tasks of Project</div>
        <div className="gx-mb-5">
          <Button style={{float: "right"}} disabled={!disabled} onClick={this.onClick} value="Add">Add Task</Button>
        </div>
        <Table className="gx-table-responsive" columns={columns} dataSource={this.state.tasks} pagination={false}/>
      </Auxiliary>
      </div>}
      </div>
    );
  };
}
const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentEmployee} = company;
  return {authUser, currentEmployee}
};

export default connect(mapStateToProps)(ProjectTasks);
