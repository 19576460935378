import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Col, Row, Tabs} from "antd";
import Allowances from "./Allowances";
import Deductions from "./Deductions";
import Auxiliary from "../../../../util/Auxiliary";
import Breadcrumbs from "components/Breadcrumbs/index";
import CircularProgress from "components/CircularProgress/index";

const TabPane = Tabs.TabPane;
class Payroll extends Component {
  state={

  };

  render() {
    return(
      <Auxiliary>
      {this.state.loading ?
        <div className="gx-loader-view">
          <CircularProgress/>
        </div> :
        <div className="gx-profile-content">
          <Breadcrumbs />
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card className="gx-card" title="Payroll Settings">
                <Tabs type="card">
                  <TabPane tab="Allowances" key="allowances">
                    <Allowances />
                  </TabPane>
                  <TabPane tab="Deductions" key="deductions">
                    <Deductions />
                  </TabPane>
                </Tabs>
              </Card>
            </Col>
          </Row>
        </div>}
      </Auxiliary>
    );
  }
}
const mapStateToProps = ({auth}) => {
  const {authUser} = auth;
  return {authUser}
};

export default connect(mapStateToProps)(Payroll);
