import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import {setCurrentTimer} from "appRedux/actions/Company";
import { withRouter } from 'react-router';
import {API_URL_V1} from "../../constants";

class UserProfile extends Component {

  render() {

    let user = this.props.currentEmployee ? this.props.currentEmployee : this.props.user;

    let avatar = <Avatar className="gx-size-40 gx-mr-3">{user.first_name}</Avatar>;

    if (this.props.currentEmployee && this.props.currentEmployee.avatar) {
      let avatarUrl = `${API_URL_V1}${this.props.currentEmployee.avatar}`;
      avatar = <Avatar src={avatarUrl} className="gx-size-40 gx-mr-3" alt=""/>;
    }

    return (
        <div className="gx-flex-row gx-align-items-center gx-mb-2 gx-avatar-row">
          <span>
            {avatar}
          </span>
          <span className="gx-avatar-name" style={{cursor: 'auto'}}>
              {user.first_name} {user.last_name}
              <br/>
              <span className="text-muted" style={{fontSize: "14px"}}>
                {this.props.currentCompany ? this.props.currentCompany.name : ''}
              </span>
          </span>
        </div>
    )
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser, user} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, user, currentCompany, currentEmployee}
};

export default withRouter(connect(mapStateToProps, {userSignOut, setCurrentTimer})(UserProfile));
