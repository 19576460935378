import React, {Component} from "react";
import {connect} from "react-redux";
import Calendar from 'react-calendar';
import {Tooltip} from "antd";
import {FaBirthdayCake, FaRegCalendarAlt, FaRegCalendarCheck} from "react-icons/fa";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../constants";
import _ from "lodash";
import axios from "axios";
import moment from "moment";

let events = [], tileContent, days = [ 'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat' ];
let from;
class TeamCalendarData extends Component {

  state = {
    loading: true,
    defaultDate : new Date(),
    events: '',
  };

  getEvents = (from, to) => {
    this.setState({...this.state, loading: true});
    events=[];
    const {authUser, currentCompany} = this.props;
    const workspace_id = currentCompany.id;
    axios.get(`${API_URL_V1}/workspaces/${workspace_id}/calendar_events`, {
      params: {
        weekend: 'weekend',
        from: from,
        to: to,
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      },
    })
    .then(eventsData => {
      this.setState({...this.state, loading: false, events: eventsData.data});
    })
    .catch(error => error)
  }

  tileContent = ({date, view}) => {
    let eventOnDate, color, allData=[], eventCount = [];

    let allEvents = _.groupBy(this.state.events, events => events.type);
    Object.keys(allEvents).map((events, index) => {
      let title='', data, count = 0;
      eventCount[moment(date).format("YYYY-MM-DD")]=0;
      (allEvents[events]).forEach((event, index) => {
          const tileDate = moment(date).format("YYYY-MM-DD");
          if(tileDate == event.date) {
            eventCount[tileDate]++;
          }
          if(tileDate == event.date ||
            (event.type === "leave" &&
            (new Date(tileDate).getTime() >= new Date(event.metadata.start_on).getTime() && new Date(event.metadata.finish_on).getTime() >= new Date(tileDate).getTime())) ||
            (event.type === "event" && event.metadata.end_date &&
            (new Date(tileDate).getTime() >= new Date(event.metadata.event_on).getTime() && new Date(event.metadata.end_date.split(' ')[0]).getTime() >= new Date(tileDate).getTime())))
           {
            count++;
            eventOnDate = event.type
            color = (eventOnDate==="birthday") ? " #ffa500" : (eventOnDate==="event") ? "#52c41a" : (eventOnDate==="holiday") ? "" : "#8980e7";
            if(count < 3) {
              title = (eventOnDate==="birthday" && event.metadata.first_name) ? title+event.metadata.first_name+" "+event.metadata.last_name+"'s, " :
                      eventOnDate==="event" ? title+event.metadata.title+", " :
                      eventOnDate==="holiday" ? title+event.metadata.name+"'s, " :
                      eventOnDate==="weekend" ? '' : (eventOnDate==="leave" && event.metadata.employee.first_name) ? title+event.metadata.employee.first_name+" "+event.metadata.employee.last_name+", " : '';
            }
            if(count >= 3 && eventCount[tileDate] === (allEvents[events].filter((event) => event.date === tileDate)).length) {
              title = title.replace(/,\s*$/, "")+" ";
              if(title.includes("+")) {
                title = title.substr(0, title.lastIndexOf("+"));
                title = title+" ";
              }
              title = title+"+"+(count-2)+" more";
            }
            data = (eventOnDate==='weekend' || (eventOnDate==="leave" && !event.metadata.employee.first_name) || (eventOnDate==="birthday" && !event.metadata.first_name)) ? '' :<Tooltip title={title.replace(/,\s*$/, "")+" "+ ((eventOnDate === "birthday") ? event.type : (eventOnDate === "leave") ? " on "+event.type : (eventOnDate === "event") ? " Event" : event.type)}><i className={`icon icon-circle gx-fs-xxs`} style={{marginRight: "2px", color: color}}/></Tooltip>
          }
        })
        allData.push(data);
    })
    return (eventOnDate ? <p>{(allData.length > 3) ? allData.slice() : allData}</p> : null);
  }

  tileClassName = ({date, view}) => {
    let className=[];
    if(moment(date).format("YYYY-MM-DD") === (moment(new Date()).format("YYYY-MM-DD"))) {
      className.push("class__today");
    }
    (this.state.events).forEach((event, index) => {
      if(moment(date).format("YYYY-MM-DD") === event.date && (event.type === "holiday" || event.type === "weekend")) {
        className.push("react-calendar__month-view__days__day--weekend holiday");
      }
    })
    return className;
  }

  componentDidMount() {
    from='';
    this.getEvents();
  }

  dateChange = ({ activeStartDate, view }) => {
    if(view === "month") {
      from = moment(activeStartDate).local().format("YYYY-MM-DD");
      let month = moment(from).month();
      let to = moment(activeStartDate).local().endOf('month').format("YYYY-MM-DD");
      this.getEvents(from, to);
    }
  }

  render() {
    return(
      <div className="tean-calendar">
        {this.state.loading ? <CircularProgress height="300px"/> :
          <Calendar
              activeStartDate={new Date()}
              className={this.props.classes}
              showNavigation={true}
              showNeighboringMonth={false}
              tileContent={this.tileContent}
              tileClassName={this.tileClassName}
              onActiveDateChange={this.dateChange}
              onDrillDown={this.dateChange}
              value={from ? new Date(from) : null}
              onClickDay={(value) => console.log("Clicked: ", value)}
          />
        }
      </div>
    )
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany} = company;
  return {authUser, currentCompany}
};

export default connect(mapStateToProps)(TeamCalendarData);
