import React, {Component} from "react";
import {Modal, Form, Col, Checkbox, Input, Button} from 'antd';

const FormItem = Form.Item;
const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends Component {
    render() {
      const formItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 4},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 20},
        },
      };

      const { getFieldDecorator } = this.props.form;
      return (
        <Modal
          visible={true}
          title="Add Employee"
          onCancel={this.props.onCancel}
          onOk={this.props.onCreate}
          confirmLoading={this.props.confirmLoading}
          footer={[
            <Button className="add-employee-form-button" key="submit" loading={this.props.confirmLoading} onClick={this.props.onCreate}>
              Add Employee
            </Button>,
            <Button key="cancel" onClick={this.props.onCancel}>
              Cancel
            </Button>
          ]}
        >
          <Form className="employee-form">
            <Form.Item
            {...formItemLayout}
            label="Name"
            required={true}
            >
              <div className="ant-row gx-form-row0">
                <Col xs={24} sm={12}>
                  <FormItem>
                    {getFieldDecorator('first_name', {
                      rules: [{ required: true, message: 'First Name Required!' }],
                    })(
                      <Input placeholder= "First Name" />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={12}>
                  <FormItem>
                    {getFieldDecorator('last_name', {
                      rules: [{ required: true, message: 'Last Name Required!' }],
                    })(
                      <Input placeholder= "Last Name" />
                    )}
                  </FormItem>
                </Col>
              </div>
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Email"
            >
              {getFieldDecorator('email', {
                rules: [{
                  type: 'email', message: 'The input is not valid E-mail!',
                },{ required: true, message: 'Email Required!' }],
              })(
                <Input placeholder= "Email" />
              )}
            </Form.Item>
            <FormItem {...formItemLayout}>
              {getFieldDecorator('invite', {
                valuePropName: 'checked',
                initialValue: true,
              })(
                <Checkbox>Invite to Cutehr (Will Send Email)</Checkbox>
              )}
            </FormItem>
          </Form>
        </Modal>
      );
    }
  }
);

class EmployeeModal extends React.Component {

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleCreate = () => {

    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.onOk(values);
    });
  }

  render() {
    return (
      <div>

        <CollectionCreateForm
          title="Basic"
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          onCreate={this.handleCreate}
          confirmLoading={this.props.confirmLoading}
        />

      </div>
    );
  }
}

export default EmployeeModal;
