import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Row, Col, Select, Tabs, message} from "antd";
import Breadcrumbs from "components/Breadcrumbs/index";
import Auxiliary from "../../../util/Auxiliary";
import Projects from "./Projects";
import ActiveTasks from "./ActiveTasks";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../constants";
import axios from "axios";
import _ from "lodash"

let clientOptions=[], disabled;
const Option = Select.Option;
const TabPane = Tabs.TabPane;

class CompanyProjects extends Component {

  state = {
    project: [],
    loading: true,
    clients: '',
    defaultKey: 'projects',
    pagination: {current: 1, pageSize: 15, total: 0},
  };

  getProjects() {
    if(this.props.currentCompany !== null) {
      const {authUser, currentCompany} = this.props;
      axios.all([
        axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/projects`, {
          params: {
            "filter[active]" : 1,
            page: this.state.pagination.current, per_page: this.state.pagination.pageSize,
          },
          headers: {
            'Authorization': 'Bearer '+authUser
          }
        }),
        axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/clients`, {
          headers: {
            'Authorization': 'Bearer '+authUser
          }
        })
      ])
      .then(axios.spread((projectData, clientData) => {
        const pagination = { ...this.state.pagination };
        pagination.pageSize = 15;
        pagination.total = projectData.data.total;
        clientOptions = [];
        for(let i = 0; i < (clientData.data.data).length; i++) {
          clientOptions.push(<Option value={(clientData.data.data[i].id).toString()}>{clientData.data.data[i].name}</Option>);
        }
        this.setState({...this.state, pagination, project: projectData.data.data, clients: clientData.data.data, defaultKey: (this.props.location && this.props.location.state && this.props.location.state.tab) ? this.props.location.state.tab : 'projects', loading: false});
      }))
      .catch(error => error)
    }
  }

  componentDidMount() {
    if(this.props.currentCompany !== null) {
      this.getProjects();
    }
  }

  difference = (object, base) => {
  	function changes(object, base) {
  		return _.transform(object, function(result, value, key) {
  			if (!_.isEqual(value, base[key])) {
  				result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
  			}
  		});
  	}
  	return changes(object, base);
  }

  componentWillReceiveProps(nextProps) {
    const change = this.difference(nextProps, this.props)
    if(Object.keys(change).length !== 0) {
      this.getProjects();
    }
  }

  render() {
    disabled = (this.props.currentEmployee && this.props.currentEmployee.role) ? this.props.currentEmployee.role.permissions.includes("edit_company") : '';

    return (
      <Auxiliary>
        <div className="gx-profile-content">
          <Breadcrumbs />
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card className="gx-card" title="Projects">
                {this.state.loading ? <CircularProgress/> :
                  <Tabs type="card" defaultActiveKey={this.state.defaultKey}>
                    <TabPane tab="Projects" key="projects">
                      <Projects dashboard={this.props.location.state && this.props.location.state.dashboard} project={this.state.project} clients={clientOptions} pagination={this.state.pagination}/>
                    </TabPane>
                    <TabPane tab="Active Tasks" key="tasks">
                      <ActiveTasks currentEmployee={this.props.currentEmployee} projects={this.state.project}/>
                    </TabPane>
                  </Tabs>
                }
              </Card>
            </Col>
          </Row>
        </div>
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, currentCompany, currentEmployee}
};

export default connect(mapStateToProps)(CompanyProjects);
