import React, {Component} from "react";
import {Modal, Form, Input, Col, Select, Row, Button} from 'antd';

const {TextArea} = Input;
const Option = Select.Option;
const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends Component {
    render() {
      const formItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 6},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 18},
        },
      };
      const inlineItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 12},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 12},
        },
      };

      const { getFieldDecorator } = this.props.form;
      return (
        <Modal
          visible={this.props.visible}
          title={this.props.title}
          onCancel={this.props.onCancel}
          onOk={this.props.onCreate}
          width= {600}
          footer={[
            <Button key="submit" loading={this.props.confirmLoading} onClick={this.props.onCreate}>
              {this.props.title}
            </Button>,
            <Button key="cancel" onClick={this.props.onCancel}>
              Cancel
            </Button>
          ]}
        >
          <Form >
            <Form.Item
            {...formItemLayout}
            label="Policy Name">
              {getFieldDecorator('leavespolicy_name', {
                initialValue: this.props.value.name,
                rules: [{ required: true, message: 'Please input the Policy Name!' }],
              })(
                <Input placeholder= "Policy Name"/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Policy Description">
              {getFieldDecorator('policy_description', {
                initialValue: this.props.value.description,
                rules: [{ required: true, message: 'Please input the Description!' }],
              })(
                <TextArea placeholder= "Policy Description"/>
              )}
            </Form.Item>
            <Row gutter={24}>
              <Col span={12} style={{ display:'block'}}>
                <Form.Item
                {...inlineItemLayout}
                label="Holidays Allowed">
                  {getFieldDecorator('holiday_allowance', {
                    initialValue: this.props.value.holiday_allowance,
                    rules: [{ required: true, message: 'Must be numeric value!' }],
                  })(
                    <Input type="number" placeholder= "Holidays Allowed"/>
                  )}
                </Form.Item>
              </Col>
              <Col span={12} style={{ display:'block'}}>
                <Form.Item
                {...inlineItemLayout}
                label="Days Type">
                  {getFieldDecorator('days_type', {
                    initialValue: this.props.value.days_type,
                    rules: [{ required: true, message: 'Please Select Days Type!' }],
                  })(
                    <Select placeholder="Please Select" showAction={['click', 'focus']}>
                      <Option value="calendar_days">Calendar Days</Option>
                      <Option value="working_days">Working Days</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12} style={{ display:'block'}}>
                <Form.Item
                {...inlineItemLayout}
                label="Accured Days">
                  {getFieldDecorator('accured_days', {
                    initialValue: this.props.value.carry_over_days,
                    rules: [{ required: true, message: 'Must be numeric value!' }],
                  })(
                    <Input type="number" placeholder= "Accured Days"/>
                  )}
                </Form.Item>
              </Col>
              <Col span={12} style={{ display:'block'}}>
                <Form.Item
                {...inlineItemLayout}
                label="Expires In">
                  {getFieldDecorator('expires_in', {
                    initialValue: this.props.value.expire_in_months ? this.props.value.expire_in_months.toString() : undefined,
                    rules: [{ required: true, message: 'Please Select Expiry Month!' }],
                  })(
                    <Select placeholder="Please Select" showAction={['click', 'focus']}>
                      <Option value="1">January</Option>
                      <Option value="2">February</Option>
                      <Option value="3">March</Option>
                      <Option value="4">April</Option>
                      <Option value="5">May</Option>
                      <Option value="6">June</Option>
                      <Option value="7">July</Option>
                      <Option value="8">August</Option>
                      <Option value="9">September</Option>
                      <Option value="10">October</Option>
                      <Option value="11">November</Option>
                      <Option value="12">December</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      );
    }
  }
);

class LeavesPolicyModal extends React.Component {

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onOk(values, this.props.value.id);
    });
  }

  render() {
    return (
      <div>

        <CollectionCreateForm
          title={this.props.title}
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          onCreate={this.handleCreate}
          value={this.props.value}
          confirmLoading={this.props.confirmLoading}
        />

      </div>
    );
  }
}

export default LeavesPolicyModal;
