import React, {Component} from "react";
import {Scrollbars} from "react-custom-scrollbars";

const Scrolls = React.forwardRef((props, ref) => {
  return(
      <Scrollbars  {...props} autoHide ref={ref}
                                      renderView={props => (
                                          <div {...props} style={{ ...props.style, overflowX: 'hidden' }} />)}
                                       renderTrackHorizontal={props => <div {...props}
                                                                            style={{display: 'none'}}
                                                                            className="track-horizontal"/>}/>)});
export default Scrolls;
