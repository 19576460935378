import React, {Component} from "react";
import {Modal, Form, Input, Button, Select} from 'antd';

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends Component {
    render() {
      const formItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 8},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 16},
        },
      };

      const { getFieldDecorator } = this.props.form;
      return (
        <Modal
          visible={this.props.visible}
          title={this.props.title}
          onCancel={this.props.onCancel}
          onOk={this.props.onCreate}
          footer={[
            <Button key="submit" loading={this.props.confirmLoading} onClick={this.props.onCreate}>
              {this.props.title}
            </Button>,
            <Button key="cancel" onClick={this.props.onCancel}>
              Cancel
            </Button>
          ]}
        >
          <Form >
            <Form.Item
            {...formItemLayout}
            label="Device Name">
              {getFieldDecorator('name', {
                initialValue: this.props.value.name,
                rules: [{ required: true, message: 'Please input the Device Name!' }],
              })(
                <Input placeholder= "Device Name"/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Serial No.">
              {getFieldDecorator('serial_no', {
                initialValue: this.props.value.serial_no,
                rules: [{ required: true, message: 'Please input the Serial Number!' }],
              })(
                <Input placeholder= "Serial Number" disabled={this.props.value}/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Office">
              {getFieldDecorator('location_id', {
                initialValue: this.props.value.location_id ? (this.props.value.location_id).toString() : undefined,
                rules: [{ required: true, message: 'Please select the Office!' }],
              })(
                <Select placeholder="Select Office" showAction={['click', 'focus']} showSearch optionFilterProp="children" filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {this.props.offices}
                </Select>
              )}
            </Form.Item>
          </Form>

        </Modal>
      );
    }
  }
);

class DeviceModal extends React.Component {

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onOk(values, this.props.value.id);
    });
  }

  render() {
    return (
      <div>

        <CollectionCreateForm
          title={this.props.title}
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          onCreate={this.handleCreate}
          value={this.props.value}
          confirmLoading={this.props.confirmLoading}
          offices={this.props.offices}
        />

      </div>
    );
  }
}

export default DeviceModal;
