import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Table, Spin, Button, Select, Row, Col, message} from "antd";
import {API_URL_V1} from "../../../constants";
import Auxiliary from "../../../util/Auxiliary";
import AttendanceModal from "./AttendanceModal";
import SelectEntry from "./SelectEntry";
import axios from "axios";
import moment from "moment";

let disabled, entries=[];
const Option = Select.Option;

class MonthlyAttendance extends Component {
  state={
    month: this.props.month,
    year: this.props.year,
    attendance: [],
    spinning: true,
    modalVisible: false,
    selectEntry: false,
    selectLoading: false,
    confirmLoading: false,
    startDate: '',
    endDate: '',
    workTrack: [],
    attendanceStats: null,
    grandTotal: 0,
    trackedTotal: 0,
  }

  getMonthlyAttendance = () => {
    const {authUser, currentEmployee, currentCompany} = this.props;
    let employee_id = (this.props.employee) ? this.props.employee.id : currentEmployee.id;
    axios.all([
      axios.get(`${API_URL_V1}/employees/${employee_id}/attendances-monthly`, {
        params: {
          month: this.props.month,
          year: this.props.year,
        },
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/reports/tracker/details`, {
          start_date: this.state.startDate.format('YYYY-MM-DD'),
          end_date: this.state.endDate.format('YYYY-MM-DD'),
          employee_ids: employee_id,
          per_page: 1000,
        }, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
    ])
    .then(axios.spread((attendanceData, trackerData) => {
      let grandTotal = 0, trackedTotal = 0;
      let attendanceArray = [];
      trackerData.data.data.map((track, index) => {
        trackedTotal = trackedTotal+(track.duration*1000);
      });
      Object.keys(attendanceData.data.data.all_days).forEach((attendance) => {
        attendanceData.data.data.all_days[attendance].date = attendance;
      })
      var result = Object.keys(attendanceData.data.data.all_days).map(function(key) {
        return [attendanceData.data.data.all_days[key]];
      });
      Object.keys(attendanceData.data.data.attendance).forEach((attendance) => {
        if(!result[new Date(attendanceData.data.data.attendance[attendance].date).getDate() -1][0].in_at) {
          attendanceData.data.data.attendance[attendance].is_working = attendanceData.data.data.all_days[attendanceData.data.data.attendance[attendance].date].is_working;
          result[new Date(attendanceData.data.data.attendance[attendance].date).getDate() -1][0] = (attendanceData.data.data.attendance[attendance]);
        } else {
          attendanceData.data.data.attendance[attendance].is_working = attendanceData.data.data.all_days[attendanceData.data.data.attendance[attendance].date].is_working;
          result[new Date(attendanceData.data.data.attendance[attendance].date).getDate() -1].push(attendanceData.data.data.attendance[attendance]);
        }
      })

      result.map((res, index) => {
        let total=0, totalTime=0;
        attendanceArray.push(res);
        res.forEach((value, i) => {
          total = total + moment(value.out_at).diff(value.in_at);
          if(value.out_at) {
            totalTime = totalTime + moment(value.out_at).diff(value.in_at);
          }
        })
        result[index] = total;
        grandTotal = grandTotal + totalTime;
      })
      this.setState({...this.state, attendance: attendanceArray, attendanceStats: this.props.attendanceStats, grandTotal: grandTotal, trackedTotal: trackedTotal, workTrack: trackerData.data.data, spinning: false});
    }))
    .catch(error => error)
  }

  componentDidMount () {
    let startDate = moment([this.props.year || moment().format('YYYY'), this.props.month - 1]);
    let endDate = moment(startDate).endOf('month');
    this.setState({...this.state, startDate: startDate, endDate: endDate},() => {
      this.getMonthlyAttendance();
    });
  }

  edit = (record) => {
    if(record.length > 1) {
      entries =[];
      for(let i = 0; i < record.length; i++) {
        entries.push(<Option value={(record[i].id).toString()}>{record[i].in_at ? moment.utc(record[i].in_at).local().format("hh:mm A") : '--'} to {record[i].out_at ? moment.utc(record[i].out_at).local().format("hh:mm A") : '--'} </Option>);
      }
      this.setState({...this.state, selectEntry: true, title: "Select Entry", editData: record});
    } else {
      this.setState({...this.state, modalVisible: true, editData: record[0], title: "Edit Attendance"});
    }
  }

  handleOk = (values, id) => {
    const {authUser, currentCompany, currentEmployee} = this.props;
    this.setState({...this.state, confirmLoading: true});
    let employee_id = (this.props.employee) ? this.props.employee.id : currentEmployee.id;
    let in_at = (moment.utc(values.in_date).format("YYYY-MM-DD")+" "+moment.utc(values.in_at).format("HH:mm:ss"));
    let out_at = (moment.utc(values.out_date).format("YYYY-MM-DD")+" "+moment.utc(values.out_at).format("HH:mm:ss"));
    if(!id) {
      let date = moment.utc(values.date).format("YYYY-MM-DD");
      axios.post(`${API_URL_V1}/employees/${employee_id}/attendances`, {
        in_at: moment.utc(in_at).format('YYYY-MM-DD HH:mm:ss'),
        out_at: moment.utc(out_at).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(attendanceData => {
        this.setState({...this.state, modalVisible: false, confirmLoading: false});
        message.success("Attendance Updated Successfully");
        this.getMonthlyAttendance();
      })
      .catch(error => error)
    } else {
      axios.put(`${API_URL_V1}/employees/${employee_id}/attendances/${id}`, {
        in_at: moment.utc(in_at).format('YYYY-MM-DD HH:mm:ss'),
        out_at: moment.utc(out_at).format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(attendanceData => {
        this.setState({...this.state, modalVisible: false, confirmLoading: false});
        message.success("Attendance Updated Successfully");
        this.getMonthlyAttendance();
      })
      .catch(error => error)
    }
  }

  handleCancel = () => {
    this.setState({...this.state, modalVisible: false});
  }

  onSelectCancel = () => {
    this.setState({...this.state, selectEntry: false});
  }

  selectOk = (values) => {
    this.setState({...this.state, selectLoading: true});
    let data = [];
    this.state.editData.forEach((record, index) => {
      if(record.id == values.entry) {
        data.push(record);
      }
    });
    this.setState({...this.state, selectEntry: false, selectLoading: false},(e) => {this.edit(data, e)});
  }

  render() {
    disabled = (this.props.currentEmployee && this.props.currentEmployee.role) ? this.props.currentEmployee.role.permissions.includes("edit_company") : '';
    const columns = [{
        title: 'Day',
        render: (text, record) => (
          <span>
            {record[0].date ? moment(record[0].date).format("DD MMM"): ''}<br/>
            {record[0].date ? moment(record[0].date).format("dddd") : ''}
          </span>
        )
      }, {
        title: 'Worked Shifts',
        render: (text, record) => (
          record.map((eachrecord) => {
              return <span>{eachrecord.in_at ? moment.utc(eachrecord.in_at).local().format("hh:mm A") : '--'} to {eachrecord.out_at ? moment.utc(eachrecord.out_at).local().format("hh:mm A") : '--'}<br/></span>
          })
        ),
      }, {
        title: 'Total Hours',
        render: (text, record) => {
          let totalTimeDiff='';
          record.map((eachrecord) => {
            let timeDiff='';
            const diff = Math.floor(moment.duration(moment(eachrecord.out_at,"YYYY-MM-DD HH:mm:ss").diff(moment(eachrecord.in_at,"YYYY-MM-DD HH:mm:ss"))).asHours()) + moment.utc(moment(eachrecord.out_at,"HH:mm:ss").diff(moment(eachrecord.in_at,"HH:mm:ss"))).format(":mm:ss");
            timeDiff = Math.floor(moment.duration(timeDiff).add(moment.duration(diff)));
            timeDiff = Math.trunc(moment.duration(timeDiff).asHours()) + ":" +("0"+(moment.duration(timeDiff).minutes())).slice(-2) +":"+ ("0"+(moment.duration(timeDiff).seconds())).slice(-2);
            totalTimeDiff = (moment.duration(totalTimeDiff).add(timeDiff));
          })
          return (
            <span>
              {record[0].in_at ? (Math.floor(totalTimeDiff.asHours()) + moment.utc(totalTimeDiff.asMilliseconds()).format(":mm:ss")) : "00:00:00"}
            </span>
          )},
      }, {
        title: 'Tracked Hours',
        render: (text, record) => {
          let totalTrackedTime='';
          record.map((eachrecord) => {
            totalTrackedTime = '';
            this.state.workTrack.map((work, index) => {
              if(eachrecord.date === moment(work.start).format('YYYY-MM-DD')) {
                let timeDiff='';
                const diff = Math.floor(moment.duration(moment(work.end,"YYYY-MM-DD HH:mm:ss").diff(moment(work.start,"YYYY-MM-DD HH:mm:ss"))).asHours()) + moment.utc(moment(work.end,"HH:mm:ss").diff(moment(work.start,"HH:mm:ss"))).format(":mm:ss");
                timeDiff = Math.floor(moment.duration(timeDiff).add(moment.duration(diff)));
                timeDiff = Math.trunc(moment.duration(timeDiff).asHours()) + ":" +("0"+(moment.duration(timeDiff).minutes())).slice(-2) +":"+ ("0"+(moment.duration(timeDiff).seconds())).slice(-2);
                totalTrackedTime = (moment.duration(totalTrackedTime).add(timeDiff));
              }
            })
          })
          return (
            <span>
              {totalTrackedTime ? (Math.floor(totalTrackedTime.asHours()) + moment.utc(totalTrackedTime.asMilliseconds()).format(":mm:ss")) : "00:00:00"}
            </span>
          );
        },
      }, {
        title: disabled ? 'Actions' : '',
        key: 'x',
        render: (text, record) => (
          disabled ?
          <span>
            <span className="gx-link" onClick={(e) => {this.edit(record, e)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span>
          </span> : ''
        )
      }];
    return(
      <Auxiliary>
        {this.state.modalVisible ?
          <AttendanceModal visible={this.state.modalVisible} value={this.state.editData} title={this.state.title} onCancel={this.handleCancel} onOk={this.handleOk} loading={this.state.confirmLoading}/>
          : null
        }
        {this.state.selectEntry ?
          <SelectEntry value={this.state.editData} entries={entries} visible={this.state.selectEntry} title={this.state.title} onCancel={this.onSelectCancel} onOk={this.selectOk} loading={this.state.selectLoading}/>
          : null
        }
        <Spin spinning={this.state.spinning}>
          <Button onClick={this.props.onYearView}>Back To Year View</Button>
          <Card>
            <Row type="flex" justify="space-around" align="middle">
              <Col className={'text-center'}> <h2>{this.state.attendanceStats ? this.state.attendanceStats.working_days : "--"}</h2> WORKING DAYS</Col>
              <Col className={'text-center'}> <h2>{this.state.attendanceStats ? this.state.attendanceStats.attendance : "--"}</h2> PRESENT</Col>
              <Col className={'text-center'}> <h2>{this.state.attendanceStats ? this.state.attendanceStats.working_days - this.state.attendanceStats.attendance : "--"}</h2> ABSENT</Col>
            </Row>
            <br/>
            <Row type="flex" justify="space-around" align="middle">
              <Col className={'text-center'}> <h2>{this.state.grandTotal ? ((Math.floor(moment.duration(this.state.grandTotal).asHours()) < 10) ? `0${Math.floor(moment.duration(this.state.grandTotal).asHours())}` : Math.floor(moment.duration(this.state.grandTotal).asHours())) + moment.utc(this.state.grandTotal).format(":mm:ss") : "00:00:00"}</h2> TOTAL WORKING HOURS</Col>
              <Col className={'text-center'}> <h2>{this.state.grandTotal ? ((Math.floor(moment.duration(this.state.grandTotal/this.state.attendanceStats.attendance).asHours()) < 10) ? `0${Math.floor(moment.duration(this.state.grandTotal/this.state.attendanceStats.attendance).asHours())}` : Math.floor(moment.duration(this.state.grandTotal/this.state.attendanceStats.attendance).asHours())) + moment.utc(this.state.grandTotal/this.state.attendanceStats.attendance).format(":mm:ss") : "00:00:00"}</h2> AVG. WORKING HOURS</Col>
              <Col className={'text-center'}> <h2>{this.state.trackedTotal ? ((Math.floor(moment.duration(this.state.trackedTotal).asHours()) < 10) ? `0${Math.floor(moment.duration(this.state.trackedTotal).asHours())}` : Math.floor(moment.duration(this.state.trackedTotal).asHours())) + moment.utc(this.state.trackedTotal).format(":mm:ss") : "00:00:00"}</h2> TRACKED HOURS</Col>
              <Col className={'text-center'}> <h2>{this.state.trackedTotal ? ((Math.floor(moment.duration(this.state.trackedTotal/this.state.attendanceStats.attendance).asHours()) < 10) ? `0${Math.floor(moment.duration(this.state.trackedTotal/this.state.attendanceStats.attendance).asHours())}` : Math.floor(moment.duration(this.state.trackedTotal/this.state.attendanceStats.attendance).asHours())) + moment.utc(this.state.trackedTotal/this.state.attendanceStats.attendance).format(":mm:ss") : "00:00:00"}</h2> AVG. TRACKED HOURS</Col>
            </Row>
          </Card>
          <Table className="gx-table-responsive" dataSource={this.state.attendance} columns={columns} pagination={false}
          rowClassName={(record, index) => {
            let classNames = "";
            record.forEach((rec, index) => {
              if(!rec.is_working) {
                classNames = "gx-text-red";
              }
            })
            return classNames;
          }}
          />
        </Spin>
      </Auxiliary>
    )
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentEmployee, currentCompany} = company;
  return {authUser, currentEmployee, currentCompany}
};

export default connect(mapStateToProps)(MonthlyAttendance);
