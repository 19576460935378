import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Card, Table, Modal, Divider, message} from "antd";
import Auxiliary from "../../../../util/Auxiliary";
import DepartmentModal from "./DepartmentModal";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../../constants";
import axios from "axios";

let disabled;

class Departments extends Component {

  constructor(props){
    super(props)
    this.state = {
      visible: false,
      title: '',
      departments: [],
      loading: true,
      editData: '',
      confirmLoading: false,
      deleteVisible: false,
      deleteLoading: false,
      record: '',
    };
  }

  getDepartments = () => {
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/departments`, {
      params: {
        employee_count: true,
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(departmentsData => {
      this.setState({...this.state, departments: departmentsData.data.data, loading: false})
    })
    .catch(error => error);
  }

  componentDidMount() {
    this.getDepartments();

  }

  onClick = () => {
    this.setState({...this.state, visible: true, title: "Add Department", editData: ''});
  }

  handleCancel = () => {
    this.setState({...this.state, visible: false});
  }

  edit = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, visible: true, title: "Edit Department", editData: record});
  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser, currentCompany} = this.props;
    axios.delete(`${API_URL_V1}/workspaces/${currentCompany.id}/departments/${record.id}`, {
      params: {
        workspace_id: currentCompany.id,
        department_id: record.id,
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(departmentData => {
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      this.getDepartments();
      message.success("Department Deleted Successfully");
    })
    .catch(error => {
      message.error("Failed to Delete");
    })
  }

  handleOk = (values, id) => {
    const {authUser, currentCompany} = this.props;
    this.setState({...this.state, confirmLoading: true});
    if(!id){
      axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/departments`, {
        name: values.department_name,
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(departmentData => {
        let departments = this.state.departments;
        departments.push(departmentData.data.data);
        message.success("New Department Added");
        this.setState({...this.state, departments: departments, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Add Department");
        this.setState({...this.state, confirmLoading: false});
      })
    } else {
      axios.put(`${API_URL_V1}/workspaces/${currentCompany.id}/departments/${id}`, {
        name: values.department_name,
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(departmentData => {
        let departments = this.state.departments;
        departments.forEach((department, index) => {
          if(department.id === departmentData.data.data.id){
            departments[index] = {...department, ...departmentData.data.data};
          }
        })
        message.success("Department Editted Successfully");
        this.setState({...this.state, departments: departments, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Edit");
        this.setState({...this.state, confirmLoading: false});
      })
    }
  }

  render() {
    disabled = (this.props.currentEmployee && this.props.currentEmployee.role) ? this.props.currentEmployee.role.permissions.includes("edit_company") : '';
    const columns = [{
        title: 'Name',
        dataIndex: 'name',
        sorter: (a, b) => {return a.name.localeCompare(b.name)},
      }, {
        title: 'People',
        dataIndex: 'employees_count',
      }, {
        title: 'Actions',
        key: 'x',
        render: (text, record) => (
          <span>
            {disabled ?
              <span className="gx-link" onClick={(e) => {this.edit(record, e)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span> : <span></span>}

            {disabled ?
                <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><Divider type="vertical"/><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span> :
                <span></span>
            }
          </span>
        )
      }];
    return(
      <div>
      <Modal
        visible={this.state.deleteVisible}
        title="Delete Department?"
        onOk={(e) => this.deleteRecord(this.state.record, e)}
        onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
        confirmLoading={this.state.deleteLoading}
        footer={[
          <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
            OK
          </Button>,
          <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
            Cancel
          </Button>
        ]}
      >
        <p>Are you Sure you want to delete this Department?</p>
      </Modal>
      {this.state.loading ?
      <CircularProgress/> :
      <div>
      {this.state.visible ?
        <DepartmentModal title={this.state.title} value={this.state.editData} visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel} confirmLoading={this.state.confirmLoading}/> : null }
      <Auxiliary>
        <div style={{paddingBottom: "2.5rem"}}>
          <Button style={{float: "right"}} disabled={!disabled} onClick={this.onClick} value="Add">Add Department</Button>
        </div>
        <Table className="gx-table-responsive" columns={columns} dataSource={this.state.departments} pagination={false}/>
      </Auxiliary>
      </div>}
      </div>
    );
  };
}
const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentEmployee, currentCompany} = company;
  return {authUser, currentEmployee, currentCompany}
};

export default connect(mapStateToProps)(Departments);
