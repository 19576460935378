import React, {Component} from "react";
import {connect} from "react-redux";
import {Table} from "antd";
import CircularProgress from "components/CircularProgress/index";
import Auxiliary from "../../../../../util/Auxiliary";
import {API_URL_V1} from "../../../../../constants";
import axios from "axios";

class SatisfactionAnswers extends Component {

  state={
    employess: [],
    answers: [],
    questions: this.props.questions.data,
    loading: true,
  }

  getAnswers = () => {
    const {authUser, currentCompany} = this.props;
    axios.all([
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/es/survey/${this.props.survey_id}/answers`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
    ])
    .then(axios.spread((answersData) => {
      console.log(answersData)
      let data = [];
      Object.keys(answersData.data).map((answers, index) => {
        data.push(answersData.data[answers])
      })
      this.setState({...this.state, answers: data, loading: false});
    }))
  }
  componentDidMount() {
    this.getAnswers();
  }

  render() {
    let newColumns = this.state.questions.map((ques, index) => ({
      title: ques.question,
      render: (text, record) => {
        return(
        <span>
        {record.map((rec, index) => {
          if(rec.es_question_id === ques.id) {
            return rec.answer
          }
        })}
        </span>
      )}
    }));
    let columns = [{
        title: 'Survey Type',
        render: (text, record) => {
          let type = "Confidential";
          record.map((rec, index) => {
            if(rec.employee) {
              type = rec.employee.first_name+" "+rec.employee.last_name;
            } else {
              type = "Anonymous";
            }
          })
          return (
            <span>
              {type}
            </span>
          )}
      }];
      newColumns.forEach((column, index) => {
        columns.push(column)
      })
    return (
      <Auxiliary>
        {this.state.loading ? <CircularProgress /> :
          <Table className="gx-table-responsive" columns={columns} dataSource={this.state.answers}/>
        }
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, currentCompany, currentEmployee}
};

export default connect(mapStateToProps)(SatisfactionAnswers);
