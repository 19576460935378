import React, {Component} from "react";
import {connect} from "react-redux";
import {List, Rate} from "antd";
import CircularProgress from "components/CircularProgress/index";
import Auxiliary from "../../../../../util/Auxiliary";
import {API_URL_V1} from "../../../../../constants";
import axios from "axios";

class SatisfactionReports extends Component {

  state={
    answers: [],
    questions: this.props.questions.data,
    loading: true,
  };

  getAnswers = () => {
    const {authUser, currentCompany} = this.props;
    axios.all([
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/es/survey/${this.props.survey_id}/answers`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
    ])
    .then(axios.spread((answersData) => {
      let data = [];
      Object.keys(answersData.data).map((answers, index) => {
        data.push(answersData.data[answers])
      })
      this.setState({...this.state, answers: data, loading: false});
    }))
  }
  componentDidMount() {
    this.getAnswers();
  }

  render() {
    return (
      <Auxiliary>
      {this.state.loading ? <CircularProgress/> :
        <div>
          {this.state.questions.map((ques, index) => {
            let sum = 0, avg, count=0, data=[];
            return (
              <Auxiliary>
                <div className="gx-mb-2">
                  <b>{ques.question}</b>
                </div>
                <div  className="gx-mb-3">
                  {this.state.answers.map((answer, index) => {
                    ques.answer_type === 'rating' ?
                    answer.forEach((ans, i) => {
                      if(ques.id === ans.es_question_id) {
                        sum = sum + +ans.answer;
                        count++;
                      }
                    }) :
                    answer.forEach((ans, i) => {
                      if(ques.id === ans.es_question_id) {
                        data.push(ans)
                      }
                    })
                  })}
                  {ques.answer_type === 'rating' ? <span>Avg. Rating: <Rate allowHalf disabled defaultValue={(sum/count).toFixed(1)} value={(sum/count).toFixed(1)}/>
                              {(sum/count).toFixed(2)+" ("+count+"response(s))"}
                  </span>
                   : <List
                    itemLayout="horizontal"
                    dataSource={data}
                    renderItem={item => (
                      <List.Item>
                        <List.Item.Meta
                          title={item.employee ? item.employee.first_name + " "+item.employee.last_name : 'Anonymous'}
                          description={item.answer}
                        />
                      </List.Item>
                    )}
                  />}
                </div>
              </Auxiliary>
            )
          })}
        </div>
      }
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, currentCompany, currentEmployee}
};

export default connect(mapStateToProps)(SatisfactionReports);
