import React from "react";
import {Col, Row} from "antd";
import ContainerHeader from "components/ContainerHeader/index";
import IntlMessages from "util/IntlMessages";
import TimeEntryData from "./TimeEntryData";
import _ from "lodash";
import moment from "moment";

function TimeEntry({allData, deleteEntry, projectData, projectOptions, handleInputChange, updateTime, onAddEntry, resumeEntry}) {
  allData = allData.filter(data => data.end != null);
  allData = _.groupBy(allData, data => moment.utc(data.start).local().format("YYYY-MM-DD"));
  return (
    <div className="gx-main-content gx-pb-sm-4">
      <Row>
        <Col span={24}>
          {Object.keys(allData).map((data, index) =>
            <TimeEntryData key={index} allData={allData[data]} styleName="gx-card-list" deleteEntry={deleteEntry} projectData={projectData} projectOptions={projectOptions} handleInputChange={handleInputChange} updateTime={updateTime} onAddEntry={onAddEntry} resumeEntry={resumeEntry}/>
          )}
        </Col>
      </Row>
    </div>
  )
}

export default TimeEntry;
