import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Col, Row, Tabs} from "antd";
import Auxiliary from "../../../../util/Auxiliary";
import ProfileForm from "./ProfileForm";
import Offices from "./Offices";
import Departments from "./Departments";
import Designations from "./Designations";
import Config from "./Config";
import Breadcrumbs from "components/Breadcrumbs/index";
import CircularProgress from "components/CircularProgress/index";

const TabPane = Tabs.TabPane;

class CompanySettings extends Component {

  render() {
    console.log(this.props);
    let tab = this.props.location.state ? this.props.location.state.tab : "profile";
    return(
      <div>
        {!(this.props.currentEmployee && this.props.currentEmployee.role && this.props.currentEmployee.role.permissions) ?
        <CircularProgress/> :
        <Auxiliary>
          <div className="gx-profile-content">
            <Breadcrumbs />
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Card className="gx-card" title="Company Settings">
                  <Tabs onChange={this.callback} type="card" defaultActiveKey={tab}>
                    <TabPane tab="Profile" key="profile">
                      <ProfileForm />
                    </TabPane>
                    <TabPane tab="Offices" key="offices">
                      <Offices />
                    </TabPane>
                    <TabPane tab="Departments" key="departments">
                      <Departments />
                    </TabPane>
                    <TabPane tab="Designations" key="designations">
                      <Designations />
                    </TabPane>
                    {(this.props.currentEmployee && this.props.currentEmployee.role) && this.props.currentEmployee.role.permissions.includes("edit_company") ?
                    <TabPane tab="Settings" key="settings">
                      <Config />
                    </TabPane> : ''}
                  </Tabs>
                </Card>
              </Col>
            </Row>
          </div>
        </Auxiliary>}
      </div>
    );
  }
}

const mapStateToProps = ({company}) => {
  const {currentEmployee} = company;
  return {currentEmployee}
};

export default connect(mapStateToProps)(CompanySettings);
