import React from "react";
import {Avatar} from "antd";
import {Link, Redirect} from "react-router-dom";
import {API_URL_V1} from '../../../constants';
import CircularProgress from "components/CircularProgress";
import Aux from "util/Auxiliary";
import moment from 'moment';

const getStatus = (type) => {
  return <Aux>
    <span className="gx-nonhover">
      <i className={`icon icon-circle gx-fs-sm`} style={{color: "#"+type.color}}/>
    </span>
    <span className={`gx-badge gx-hover gx-mb-0 gx-text-white`} style={{backgroundColor: "#"+type.color}}>
      {type.name}
    </span>
    </Aux>
};

const LeaveItem = ({data}) => {

  const {id, description, start_on, finish_on, created_at, type, employee, employee_id} = data;
  const {first_name, last_name, avatar} = data.employee;
  return (
    <div key={"LeaveItem" + id} className="gx-media gx-task-list-item gx-flex-nowrap">
      <Avatar className="gx-mr-3 gx-size-36" src={`${API_URL_V1}${avatar}`}/>
      <div className="gx-media-body gx-task-item-content">
        <div className="gx-task-item-content-left">
          <Link to={`/employees/${employee_id}/requests`}>
            <h5 className="gx-text-truncate gx-task-item-title">{first_name} {last_name}</h5>
          </Link>
          <p key={id} className="gx-text-grey gx-fs-sm gx-mb-0"><span className="gx-text-grey"><b>From:</b></span> {start_on}<span className="gx-text-grey"> <b>To:</b></span> {finish_on}</p>
        </div>
        <div className="gx-task-item-content-right">
          {getStatus(type)}
        </div>
      </div>
    </div>
  );
};

export default LeaveItem;
