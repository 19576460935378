import React, {Component} from "react";
import {Button, Form, Input, message} from "antd";
import {Link} from "react-router-dom";
import IntlMessages from "util/IntlMessages";
import {API_URL, API_URL_V1} from "../constants";
import axios from "axios";
import backgroundImage from "../assets/images/signIn.svg";

const FormItem = Form.Item;


class ResetPassword extends Component {

  state={
    companyInfo: '',
    loading: true,
  };

  componentDidMount() {
    let urlParts = window.location.host.split('.');
    let subdomain = urlParts[0];
    if(!(subdomain === 'app' || subdomain === 'localhost:3000')) {
      axios.get(`${API_URL}/${subdomain}/v1/public/info`)
      .then(companyData => {
        this.setState({...this.state, companyInfo: companyData.data, loading: false});
      })
      .catch(error => {
        this.setState({...this.state, loading: false});
      })
    } else {
      this.setState({...this.state, loading: false});
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        const url = new URL(window.location.href);
        const token = url.searchParams.get("token");
        if(!token) {
          message.error("No token was Provided");
        } else {
          axios.post(`${API_URL_V1}/auth/password/reset`, {
            token: token,
            email: values.email,
            password: values.password,
            password_confirmation: values.confirm,
          })
          .then(resetpassData => {
            message.success(resetpassData.data.status);
            this.props.history.push('/signin');
          })
          .catch(error => {
            if(error.response.data.email) {
              message.error(error.response.data.email);
            } else if (error.response.data.token) {
              message.error(error.response.data.token);
            } else if (error.response.data.errors.password) {
              message.error(error.response.data.errors.password[0]);
            } else {}
          });
        }
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.props.confirmDirty) {
      form.validateFields(['confirm'], {force: true});
    }
    callback();
  };

  render() {
    const {getFieldDecorator} = this.props.form;

    return (
      <div className="gx-app-login-wrap" style={{background: `url(${backgroundImage})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundColor: "#fff"}}>
      <div className="gx-app-login-container">
        <div className="gx-app-logo-content">
            <div className="gx-app-logo-wid">
              <div className="gx-app-logo gx-mb-2">
                {this.state.loading ? '' :
                  <img height="30px" alt="logo" src={this.state.companyInfo ? API_URL_V1+this.state.companyInfo.logo : require("assets/images/logo-colored.png")}/>
                }
              </div>
              <h1 style={{color: "#444"}}>Reset Password</h1>
              <p><IntlMessages id="appModule.enterPasswordReset"/></p>
            </div>

        </div>
        <div className="gx-app-login-main-content">
          <div className="gx-app-login-content">
          <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">

            <FormItem>
              {getFieldDecorator('email', {
                rules: [{
                  required: true, type: 'email', message: 'The input is not valid E-mail!',
                }],
              })(
                <Input placeholder="Email" size="large"/>
              )}
            </FormItem>

            <FormItem>
              {getFieldDecorator('password', {
                rules: [{
                  required: true, message: 'Please input your password!',
                }, {
                  validator: this.validateToNextPassword,
                }],
              })(
                <Input type="password" placeholder="New Password" size="large"/>
              )}
            </FormItem>

            <FormItem>
              {getFieldDecorator('confirm', {
                rules: [{
                  required: true, message: 'Please confirm your password!',
                }, {
                  validator: this.compareToFirstPassword,
                }],
              })(
                <Input placeholder="Retype New Password" type="password" onBlur={this.handleConfirmBlur} size="large"/>
              )}
            </FormItem>

            <FormItem>
              <Button type="primary" className="gx-mb-0" htmlType="submit" size="large" block>
                <IntlMessages id="app.userAuth.reset"/>
              </Button>
              <div style={{textAlign: "center"}}><IntlMessages id="app.userAuth.or"/></div> <div style={{textAlign: "center"}}><Link to="/signin"><IntlMessages
              id="app.userAuth.signIn"/></Link></div>
            </FormItem>
          </Form>
          </div>


        </div>
      </div>
      </div>
    );
  }
}

const WrappedResetPasswordForm = Form.create()(ResetPassword);

export default (WrappedResetPasswordForm);
