import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Col, Row, Tabs} from "antd";
import Auxiliary from "../../../../util/Auxiliary";
import EmployeeProfileForm from "./EmployeeProfileForm";
import EmployeePersonelForm from "./EmployeePersonelForm";
import EmployeeContractForm from "../../../you/myProfile/Contracts";
import EmployeeAttendance from "../../../you/myProfile/Attendance";
import EmployeeDocuments from "./EmployeeDocuments";
import Breadcrumbs from "components/Breadcrumbs/index";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../../constants";
import axios from "axios";

const TabPane = Tabs.TabPane;
let workspace_id;

class EmployeeProfile extends Component {

  state = {
    loading: true,
    employeeData: '',
    designationData: '',
    allEmployee: '',
    locationData: '',
    leavePolicyData: '',
    countryData: '',
    workspaceData: '',
    departmentData: '',
  };

  callback = (key) => {


  };

  componentDidMount() {
    workspace_id = localStorage.getItem('id');
    const employee = (window.location.pathname).split("/");
    const {authUser} = this.props;
    axios.all([
      axios.get(`${API_URL_V1}/workspaces/${workspace_id}/employees/${employee[2]}`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/workspaces/${workspace_id}/designations`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/workspaces/${workspace_id}/employees`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/workspaces/${workspace_id}/locations`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/workspaces/${workspace_id}/leaves/policies`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/data/countries`),
      axios.get(`${API_URL_V1}/workspaces/${workspace_id}/departments`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/workspaces/${workspace_id}/me`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
   ])
   .then(axios.spread((employeeData, designationData, allEmployee, locationData, leavePolicyData, countryData, departmentData, workspaceData) => {
     this.setState({...this.state, employeeData: employeeData.data.data, designationData: designationData.data.data, allEmployee: allEmployee.data.data,
       locationData: locationData.data.data, leavePolicyData: leavePolicyData.data.data, countryData: countryData.data.data,
       departmentData: departmentData.data.data, workspaceData: workspaceData.data.data, loading: false})
   }))
   .catch(error => error);
  }

  render() {
    return (
      <div>
      {this.state.loading ?
      <CircularProgress /> :
        <Auxiliary>
          <div className="gx-profile-content">
            <Breadcrumbs />
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Card className="gx-card" title="Employee Data">
                  <Tabs onChange={this.callback} type="card">
                    <TabPane tab="Personal" key="personal">
                      <EmployeePersonelForm employee={this.state.employeeData} countries={this.state.countryData} steps={this.props.location && this.props.location.state ? this.props.location.state.steps : null}/>
                    </TabPane>
                    <TabPane tab="Work" key="work">
                      <EmployeeProfileForm employee={this.state.employeeData} allEmployee={this.state.allEmployee} locations={this.state.locationData} leavePolicy={this.state.leavePolicyData} workspaceData={this.state.workspaceData} department={this.state.departmentData}/>
                    </TabPane>
                    <TabPane tab="Contract" key="contract">
                      <EmployeeContractForm designation={this.state.designationData} workspaceData={this.state.workspaceData} employee={this.state.employeeData}/>
                    </TabPane>
                    <TabPane tab="Documents" key="document">
                      <EmployeeDocuments employee={this.state.employeeData} workspaceData={this.state.workspaceData}/>
                    </TabPane>
                    <TabPane tab="Attendance" key="attendance">
                      <EmployeeAttendance employee={this.state.employeeData}/>
                    </TabPane>
                  </Tabs>
                </Card>
              </Col>
            </Row>
          </div>
        </Auxiliary> }
      </div>
    );
  }
}
const mapStateToProps = ({auth}) => {
  const {authUser} = auth;
  return {authUser}
};

export default connect(mapStateToProps)(EmployeeProfile);
