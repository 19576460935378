import React, {Component} from "react";
import {Card, Tabs} from "antd";
import SatisfactionQuestion from "./SatisfactionQuestion";
import SatisfactionSettings from "./SatisfactionSettings";
import SatisfactionAnswers from "./SatisfactionAnswers";
import SatisfactionReports from "./SatisfactionReports";
import Auxiliary from "../../../../../util/Auxiliary";
import CircularProgress from "components/CircularProgress/index";
import Breadcrumbs from "components/Breadcrumbs/index";

const TabPane = Tabs.TabPane;
class EmployeeSatisfaction extends Component {
  state = {
    survey_id: '',
    questions: [],
    loading: true,
  }

  componentDidMount() {
    let survey_id = this.props.location.state.surveyData.id;
    this.setState({...this.state, survey_id: survey_id, loading: false});
  }

  getData = (data) => {
    this.setState({...this.state, questions: data});
  }

  render () {
    return (
      <Auxiliary>
        <Breadcrumbs />
        <div className="gx-profile-content">
          <Card className="gx-card" title={this.props.location.state.surveyData.name}>
            {this.state.loading ? <CircularProgress/> :
            <Tabs type="card">
              <TabPane tab="Questions" key="questions">
                <SatisfactionQuestion survey_id={this.state.survey_id} getData={this.getData}/>
              </TabPane>
              <TabPane tab="Settings" key="settings">
                <SatisfactionSettings survey_id={this.state.survey_id}/>
              </TabPane>
              <TabPane tab="Answers" key="answers">
                <SatisfactionAnswers survey_id={this.state.survey_id} questions={this.state.questions}/>
              </TabPane>
              <TabPane tab="Report" key="report">
                <SatisfactionReports survey_id={this.state.survey_id} questions={this.state.questions}/>
              </TabPane>
            </Tabs>}
          </Card>
        </div>
      </Auxiliary>
    )
  }
}

export default EmployeeSatisfaction;
