import React, {Component} from "react";
import {connect} from "react-redux";
import BigCalendar from "react-big-calendar";
import {Card, Modal, Spin} from "antd";
import CircularProgress from "components/CircularProgress/index";
import Auxiliary from "../../../util/Auxiliary";
import {FaBirthdayCake, FaRegCalendarAlt, FaRegCalendarCheck} from "react-icons/fa";
import {API_URL_V1} from "../../../constants";
import axios from "axios";
import moment from "moment";

let allViews = Object.keys(BigCalendar.Views).map(k => BigCalendar.Views[k]);

BigCalendar.setLocalizer(
  BigCalendar.momentLocalizer(moment)
);

let events = [];

function info(event) {
  Modal.info({
    title: (event.type === 'leave') ? (event.title) : (event.type).toUpperCase(),
    centered: true,
    content: (
      <div>
        {event.type == 'birthday' && <p><FaBirthdayCake className="icon"/><span style={{verticalAlign: "middle"}}> {event.metadata.first_name} {event.metadata.last_name}'s {event.type}</span></p>}
        {event.type == 'leave' && <div><p><FaRegCalendarAlt className="icon"/><span style={{verticalAlign: "middle"}}> From {event.metadata.start_on} to {event.metadata.finish_on}</span></p></div>}
        {event.type == 'holiday' && <p><FaRegCalendarCheck className="icon"/><span style={{verticalAlign: "middle"}}> {event.metadata.name}'s {event.type}</span></p>}
        {event.type == 'event' && <div><p>{event.metadata.title}</p><p><FaRegCalendarAlt className="icon"/><span style={{verticalAlign: "middle"}}> {event.date} {event.metadata.end_date ? " to "+event.metadata.end_date.split(' ')[0] : ''}</span></p></div>}
      </div>
    ),
    onOk() {
    },
  });
}

class Calendar extends Component {

  state = {
    loading: true,
    defaultDate : new Date(),
    defaultView: 'month',
    spinning: true,
  };

  getEvents = (params) => {
    this.setState({...this.state, spinning: true});
    events=[];
    const {authUser, currentCompany} = this.props;
    const workspace_id = currentCompany.id;
    axios.get(`${API_URL_V1}/workspaces/${workspace_id}/calendar_events`, {
      params: {
        from: params.from,
        to: params.to,
        weekend: 'weekend',
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      },
    })
    .then(eventsData => {
      for(let i = 0; i< (eventsData.data).length; i++) {
        if(eventsData.data[i].type === "holiday") {
          events.push({
            ...eventsData.data[i],
            "title": eventsData.data[i].metadata.name + "'s " + eventsData.data[i].type,
            "start": new Date(eventsData.data[i].date),
            "end": new Date(eventsData.data[i].date),
            "allDay": true
          });
        }
        else if(eventsData.data[i].type === "leave") {
          events.push({
            ...eventsData.data[i],
            "title": eventsData.data[i].metadata.employee.first_name + " " + eventsData.data[i].metadata.employee.last_name + "'s " + eventsData.data[i].type,
            "start": new Date(eventsData.data[i].metadata.start_on),
            "end": new Date(eventsData.data[i].metadata.finish_on),
            "allDay": true
          });
        }
        else if(eventsData.data[i].type === "birthday"){
          events.push({
            ...eventsData.data[i],
            "title": eventsData.data[i].metadata.first_name + " " + eventsData.data[i].metadata.last_name + "'s " + eventsData.data[i].type,
            "start": new Date(eventsData.data[i].date),
            "end": new Date(eventsData.data[i].date),
            "allDay": true
          });
        }
        else {
          events.push({
            ...eventsData.data[i],
            "title": eventsData.data[i].metadata.title,
            "start": new Date(eventsData.data[i].date),
            "end": eventsData.data[i].metadata.end_date ? new Date(eventsData.data[i].metadata.end_date.split(' ')[0]): new Date(eventsData.data[i].date),
            "allDay": true
          });
        }
      }
      this.setState({...this.state, spinning: false, loading: false});
    })
  }

  componentDidMount() {
    this.getEvents({});
  }

  doubleClick = (event, e) => {
    console.log(event)
    info(event);
  }

  onNavigate = (event, e) => {
    events=[];
    let momentDate = moment(event);
    let startDate = (e === 'month' || e === 'week') ? momentDate.startOf(e).format('YYYY-MM-DD') : (e === 'work_week') ? momentDate.startOf('isoWeek').format('YYYY-MM-DD') : (e === 'agenda') ? momentDate.format('YYYY-MM-DD') : momentDate.format('YYYY-MM-DD');
    let endDate = (e === 'month' || e === 'week') ? momentDate.endOf(e).format('YYYY-MM-DD') : (e === 'work_week') ? momentDate.endOf('isoWeek').format('YYYY-MM-DD') : (e === 'agenda') ? momentDate.add(1, 'month').format('YYYY-MM-DD') : momentDate.format('YYYY-MM-DD');
    let params = {
        from: startDate,
        to: endDate,
      }
      this.setState({...this.state, defaultDate: event, defaultView: e}, () =>{this.getEvents(params)});
  }

  onView = (e) => {
    let momentDate = moment(this.state.defaultDate);
    let startDate = (e === 'month' || e === 'week') ? momentDate.startOf(e).format('YYYY-MM-DD') : (e === 'work_week') ? momentDate.startOf('isoWeek').format('YYYY-MM-DD') : (e === 'agenda') ? momentDate.format('YYYY-MM-DD') : momentDate.format('YYYY-MM-DD');
    let endDate = (e === 'month' || e === 'week') ? momentDate.endOf(e).format('YYYY-MM-DD') : (e === 'work_week') ? momentDate.endOf('isoWeek').format('YYYY-MM-DD') : (e === 'agenda') ? momentDate.add(1, 'month').format('YYYY-MM-DD') : momentDate.format('YYYY-MM-DD');
    let params = {
        from: startDate,
        to: endDate,
      }
      this.setState({...this.state, defaultView: e},() => {this.getEvents(params)});
  }

  customDayPropGetter = date => {
    let isHoliday = events.find(event => {
      return event.start.getDate() == date.getDate() && event.start.getMonth() == date.getMonth() && (event.type === 'holiday' || event.type === 'weekend');
    })
    if (isHoliday)
      return {
        className: 'rbc-off-range-bg-weekend',
        style: {backgroundImage: "linear-gradient(135deg, #dddddd 3.13%, #ffffff 3.13%, #ffffff 50%, #dddddd 50%, #dddddd 53.13%, #ffffff 53.13%, #ffffff 100%)", backgroundSize: "22.63px 22.63px"}
      }
    else return {}
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    let backgroundColor = (event.type === 'leave') ? "#ffc34d" : (event.type === 'birthday') ? "#66ff66" : "#"+event.hexColor;
    let style = {
        backgroundColor: backgroundColor,
        borderRadius: '8px',
        opacity: 0.8,
        color: 'black',
        border: '0px',
        display: (event.type!=="weekend") ? 'block' : 'none',
        minHeight: '16px'
    };
    return {
        style: style
    };
  }

  render() {
    return (
      <div>
        <Auxiliary>
          <Card className="gx-card" title={<span style={{fontSize: '16px'}}>Events Calendar</span>}>
            <div style={{height: "30px"}}>All Events of the Company</div>
            <div className="gx-main-content">
              <div className="gx-rbc-calendar">
              {this.state.loading ?
                <CircularProgress /> :
                <Spin spinning={this.state.spinning}>
                <BigCalendar
                  popup
                  events={events}
                  onNavigate={this.onNavigate}
                  views={allViews}
                  onView={this.onView}
                  onDoubleClickEvent={this.doubleClick}
                  defaultDate={this.state.defaultDate}
                  defaultView={this.state.defaultView}
                  showMultiDayTimes={true}
                  dayPropGetter={this.customDayPropGetter}
                  eventPropGetter={(this.eventStyleGetter)}
                  components={{
                    header: ({date, label}) => {
                      return (
                        <div style={{height: "40px", float: "right", paddingTop: "12px", paddingRight: "2px"}}>{label}</div>
                      )
                    },
                    month: {
                      dateHeader: ({ date, label }) => {
                        let highlightDate =
                          events.find(event => (event.type === "weekend" || event.type === "holiday") && moment(date).format("YYYY-MM-DD") === moment(event.date).format('YYYY-MM-DD')
                          ) != undefined;
                        return (
                          <span style={highlightDate ? { color: "#b1b6b9"} : null}>{label}</span>
                        );
                      }
                    }
                  }}
                  step={60}/>
                  </Spin>
                }
              </div>
            </div>
          </Card>
        </Auxiliary>
      </div>
    );
  }
};
const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany} = company;
  return {authUser, currentCompany}
};

export default connect(mapStateToProps)(Calendar);
