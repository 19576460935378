import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Col, Row, Tabs} from "antd";
import Auxiliary from "../../../../util/Auxiliary";
import CompanyCustomFields from "./CompanyCustomFields";
import EmployeeCustomFields from "./EmployeeCustomFields";
import Breadcrumbs from "components/Breadcrumbs/index";

const TabPane = Tabs.TabPane;

class CompanySettings extends Component {

  render() {
    return(
      <Auxiliary>
        <div className="gx-profile-content">
          <Breadcrumbs />
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card className="gx-card" title="Custom Fields">
                <Tabs onChange={this.callback} type="card">
                  <TabPane tab="Company" key="company">
                    <CompanyCustomFields />
                  </TabPane>
                  <TabPane tab="Employee" key="employee">
                    <EmployeeCustomFields/>
                  </TabPane>
                </Tabs>
              </Card>
            </Col>
          </Row>
        </div>
      </Auxiliary>
    );
  }
}
export default CompanySettings;
