import React, {Component} from "react";
import {Modal, Form, DatePicker, Button} from 'antd';
import moment from "moment";

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends Component {
    disabledDate = (current) => {
      return current && current > moment().endOf('day');
    }
    render() {
      const formItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 8},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 16},
        },
      };

      const { getFieldDecorator } = this.props.form;
      return (
        <Modal
          visible={this.props.visible}
          title={"Terminate "+this.props.title}
          onCancel={this.props.onCancel}
          onOk={this.props.onCreate}
          footer={[
            <Button key="submit" loading={this.props.confirmLoading} onClick={this.props.onCreate}>
              {"Terminate"}
            </Button>,
            <Button key="cancel" onClick={this.props.onCancel}>
              Cancel
            </Button>
          ]}
        >
        <p><b>Are you sure you want to terminate {this.props.title}</b><br/>Terminating the employee will remove it from all projects, manager's post, timeOff manager's post.</p>
          <Form >
            <Form.Item
            {...formItemLayout}
            label="Date">
              {getFieldDecorator('date', {
                rules: [{ required: true, message: 'Please select date of termination!' }],
              })(
                <DatePicker className="gx-w-100" placeholder= "Termination Date" disabledDate={this.disabledDate}/>
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

class TerminateModal extends React.Component {

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onOk(values);
    });
  }

  render() {
    return (
      <div>

        <CollectionCreateForm
          title={this.props.title}
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          onCreate={this.handleCreate}
          confirmLoading={this.props.confirmLoading}
        />

      </div>
    );
  }
}

export default TerminateModal;
