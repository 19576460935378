import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Card, Table, Modal, Divider, message} from "antd";
import Auxiliary from "@/util/Auxiliary";
import HolidaysModal from "../settings/TimeOff/HolidaysModal";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "@/constants";
import Breadcrumbs from "components/Breadcrumbs/index";
import axios from "axios";

import ImportHolidays from "./ImportHolidays";

let workspace_id, disabled;

class Holidays extends Component {

  constructor(props){
    super(props)
    this.state = {
      visible: false,
      title: '',
      loading: true,
      editData: '',
      holidays: [],
      office: '',
      showImport: false,
      confirmLoading: false,
      deleteVisible: false,
      deleteLoading: false,
      record: '',
    };
  }

  toggleImportView = e => {
    if(this.state.showImport) {
      let officeId = this.props.match.params.office_id;
      this.getHolidays(officeId);
    }
    this.setState({...this.state, showImport: !this.state.showImport});
  }

  getOffice(id) {
    workspace_id = this.props.currentCompany.id;
    const {authUser} = this.props;

      axios.get(`${API_URL_V1}/workspaces/${workspace_id}/locations/${id}`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(response => {
        console.log(response.data.data)
        this.setState(state => { return {...state, office: response.data.data}})
      })
      .catch(error => error);
  }

  getHolidays(id) {
    workspace_id = this.props.currentCompany.id;
    const {authUser} = this.props;

      axios.get(`${API_URL_V1}/workspaces/${workspace_id}/leaves/holidays`, {
        params: {
          location_id: id,
        },
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(response => {
        this.setState({...this.state, holidays: response.data.data, loading: false})
      })
      .catch(error => error);
  }

  componentDidMount() {
      let officeId = this.props.match.params.office_id;
      this.getHolidays(officeId);
      this.getOffice(officeId);
    }


  onClick = () => {
    this.setState({...this.state, visible: true, title: "Add Holiday", editData: ''});
  }

  handleCancel = () => {
    this.setState({...this.state, visible: false});
  }

  edit = (record, e) => {
    e.preventDefault();
    const {authUser} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${workspace_id}/leaves/holidays/${record.id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(editData => {
      this.setState({...this.state, visible: true, title: "Edit Holiday", editData: editData.data.data});
    })
    .catch(error => error);
  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser} = this.props;
    axios.delete(`${API_URL_V1}/workspaces/${workspace_id}/leaves/holidays/${record.id}`, {
      params: {
        workspace_id: workspace_id,
        holiday_id: record.id,
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(holidayData => {
      message.success("Holiday Deleted Successfully");
      this.setState({...this.state, deleteVisible: false, deleteLoading: false}, this.getHolidays(record.location_id))
    })
    .catch(error => {
      message.error("Failed to Delete");
    })
  }

  handleOk = (values, id, location_id) => {
    let officeId = this.props.match.params.office_id;

    this.setState({...this.state, confirmLoading: true});
    const {authUser} = this.props;
    workspace_id = this.props.currentCompany.id;
    if(!id){
      axios.post(`${API_URL_V1}/workspaces/${workspace_id}/leaves/holidays`, {
        name: values.holiday_name,
        date: (values.holiday_date).format('YYYY-MM-DD'),
        location_id: officeId,
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(holidayData => {
        let holidays = this.state.holidays;
        holidays.push(holidayData.data.data);
        message.success("New Holiday Added");
        this.setState({...this.state, holidays: holidays, visible: false, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Add Holiday");
        this.setState({...this.state, confirmLoading: false});
      })
    } else {
      axios.put(`${API_URL_V1}/workspaces/${workspace_id}/leaves/holidays/${id}`, {
        name: values.holiday_name,
        date: (values.holiday_date).format('YYYY-MM-DD'),
        location_id: officeId,
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(response => {
          let holidays = this.state.holidays;
          holidays = holidays.map(day => day.id == response.data.data.id ? response.data.data : day);
          message.success("Holiday Editted Successfully");
        this.setState({...this.state, visible: false, holidays: holidays, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Edit");
        this.setState({...this.state, confirmLoading: false});
      })
    }
  }

  render() {
    disabled = (this.props.currentEmployee && this.props.currentEmployee.role) ? this.props.currentEmployee.role.permissions.includes("edit_company") : '';
    const columns = [{
        title: 'Name',
        dataIndex: 'name',
      }, {
        title: 'Date',
        dataIndex: 'date',
        sorter: (a, b) => (a < b ? 1 : -1),
        sortOrder: 'ascend',
        sortDirections: 'ascend'

      }, {
        title: 'Actions',
        key: 'x',
        render: (text, record) => (
          <span>
            {disabled ?
            <span className="gx-link" onClick={(e) => {this.edit(record, e)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span> : <span></span>}

            {disabled ?
              <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><Divider type="vertical"/><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span> :
              <span></span>
            }
          </span>
        )
      }];
    return(
      <div>
      <Modal
        visible={this.state.deleteVisible}
        title="Delete Holiday?"
        onOk={(e) => this.deleteRecord(this.state.record, e)}
        onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
        confirmLoading={this.state.deleteLoading}
        footer={[
          <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
            OK
          </Button>,
          <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
            Cancel
          </Button>
        ]}
      >
        <p>Are you Sure you want to delete {this.state.record.name} Holiday?</p>
      </Modal>
      {this.state.loading ?
      <CircularProgress/> :
      <div>
      {this.state.visible ?
        <HolidaysModal title={this.state.title} value={this.state.editData} visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel} confirmLoading={this.state.confirmLoading}/> : null }
      <Auxiliary>
        <Breadcrumbs />
        <Card>
          <div><b>Holidays</b></div>
          {this.state.office && this.state.office.name ? <div>Holidays for {this.state.office.name}</div> :null}
          {this.state.showImport ?
            <div className="gx-mb-2 gx-mt-2">
              <Button icon="arrow-left" disabled={!disabled} onClick={this.toggleImportView} value="import">Back to Holidays</Button>
            </div> :
          <div className="gx-mb-5">
            <Button style={{float: "right", marginRight: "0"}} disabled={!disabled} onClick={this.onClick} value="Add">Add Holiday</Button>
            <Button style={{float: "right", marginRight: "5px"}} disabled={!disabled} onClick={this.toggleImportView} value="import">Import Holiday</Button>
          </div>}
          {this.state.showImport ?
            <ImportHolidays office={this.state.office} addedHolidays={this.state.holidays}/> :
            <Table className="gx-table-responsive" key="id" className="gx-table-responsive" columns={columns} dataSource={this.state.holidays} pagination={false}/>
            }
        </Card>
      </Auxiliary>
      </div>}
      </div>
    );
  };
}
const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentEmployee, currentCompany} = company;
  return {authUser, currentEmployee, currentCompany}
};

export default connect(mapStateToProps)(Holidays);
