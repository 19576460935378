import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Spin, Divider, message} from "antd";
import Messages from "components/Messaging/index";
import {Scrollbars} from "react-custom-scrollbars";
import Auxiliary from "../../../util/Auxiliary";
import Breadcrumbs from "components/Breadcrumbs/index";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../constants";
import axios from "axios";

class CandidateMessaging extends Component {
  state={
    messages: [],
    selectedUser: this.props.location.state.candidate,
    startMessagingContent: 'No Messages found. Start Messaging',
    spinning: false,
    message: '',
    loading: true,
  }

  getMessages = () => {
    this.setState({...this.state, spinning: true});
    const {authUser} = this.props;
    axios.all([
      axios.get(`${API_URL_V1}/ats/candidates/${this.props.location.state.candidate.id}/messages`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
    ])
    .then(axios.spread((messagesData) => {
        messagesData.data.data.sort(function(a, b){
          return (a.created_at > b.created_at) ? 1 : ((b.created_at > a.created_at) ? -1 : 0);
        })
        this.setState({...this.state, messages: messagesData.data.data, spinning: false, loading: false});
    }))
    .catch(error => error)
  }

  componentDidMount() {
    this.getMessages();
  }

  _handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      if(this.state.message === "\n") {
        message.error("Can't send empty message");
        this.setState({...this.state, message: ''});
      } else {
        this.submitComment();
      }
    }
  };

  updateMessageValue(evt) {
    this.setState({
      message: evt.target.value
    });
  }

  submitComment = () => {
    if(!this.state.message) {
      message.error("Can't send empty message");
    } else {
      let message = this.state.message;
      this.setState({...this.state, message: ''});
      const {authUser} = this.props;
      axios.post(`${API_URL_V1}/ats/candidates/${this.props.location.state.candidate.id}/messages`, {
          subject: "Message regarding job post",
          body: this.state.message,
        },
        {
          headers: {
            'Authorization': 'Bearer '+authUser
        }
      })
      .then(messageData => {
        let messages = this.state.messages;
        messages.push(messageData.data);
        this.setState({...this.state, message: '', messages: messages});
      })
      .catch(error => {
        message.error("Something went wrong");
        this.setState({...this.state, message: message});
      })
    }
  }

  displayMail = (messages, startMessagingContent) => {
    return (<div className="gx-module-box-column">
      {messages.length === 0 ?
          <div className="gx-module-list gx-mail-list gx-module-content-scroll gx-text-light gx-align-items-center">
            <i className="icon icon-chat gx-text-muted" style={{fontSize: "40px"}}/>
            <h1 className="gx-text-muted">{startMessagingContent}</h1>
          </div>
          :
          <Messages messages={messages} ref="scrollbars"/>
        }
    </div>)
  };

  componentDidUpdate() {
    this.refs && this.refs.scrollbars && this.refs.scrollbars.scrollToBottom();
  }

  render () {
    return (
      <Auxiliary>
        <Breadcrumbs />
        <Card title="Candidate Messaging" bodyStyle={{padding: "1px 15px"}} extra={<span className="gx-ml-2 gx-link" onClick={() => {this.getMessages()}}>Refresh</span>}>

          {this.state.loading ? <CircularProgress /> :
            <Spin spinning={this.state.spinning}>
              <div className="gx-chat-main-content">
                <div className="gx-module-box-content">
                  <div className="gx-module-box-topbar">
                    <span className="gx-sender-name">
                      {this.props.location.state.candidate.first_name + " " + this.props.location.state.candidate.last_name}
                    </span>
                    <Divider type="vertical"/>
                    <span className="gx-text">{"Applied for : "+this.props.location.state.opening.title}</span>
                    <div classID="toolbar-separator"/>
                  </div>

                  {this.state.loading ?
                    <div className="gx-loader-view">
                      <CircularProgress/>
                    </div>
                    : this.displayMail(this.state.messages, this.state.startMessagingContent)}
                </div>
                <div className="gx-chat-main-footer">
                  <div className="gx-flex-row gx-align-items-center" style={{maxHeight: 51}}>
                    <div className="gx-col">
                      <div className="gx-form-group">
                                      <textarea
                                        id="required" className="gx-border-0 ant-input gx-chat-textarea"
                                        autoFocus
                                        onKeyUp={this._handleKeyPress.bind(this)}
                                        onChange={this.updateMessageValue.bind(this)}
                                        value={this.state.message}
                                        placeholder="Type and hit enter to send message"
                                      />
                      </div>
                    </div>
                    <i className="gx-icon-btn icon icon-sent" onClick={this.submitComment.bind(this)}/>
                  </div>
                </div>
              </div>
            </Spin>
          }
        </Card>
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, currentCompany, currentEmployee}
};

export default connect(mapStateToProps)(CandidateMessaging);
