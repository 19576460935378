import React, {Component} from "react";
import {Select} from "antd";
import Auxiliary from "../../../util/Auxiliary";
import moment from "moment";

let employees=[];
const {Option} = Select;

class WorkCalendarFilter extends Component {
  state={
    employees: [],
    filterData: {
      employee_id: '',
    },
  }

  componentDidMount () {
    employees=[];
    for(let i = 0; i < (this.props.employees).length; i++) {
      let disabled = (this.props.employees[i].manager_id === this.props.currentEmployee.id || this.props.employees[i].id === this.props.currentEmployee.id);
      if(this.props.currentEmployee.role.permissions.includes("edit_company")) {
        disabled = true;
      }
      employees.push(<Option value={(this.props.employees[i].id).toString()} disabled={!disabled}>{this.props.employees[i].first_name} {this.props.employees[i].last_name}</Option>);
    }
    this.setState({...this.state, employees: employees});
  }

  handleChange = (value) => {
    let filterData = this.state.filterData;
    filterData.employee_id = value;
    this.setState({...this.state, filterData: filterData}, () => {this.props.filterWorkCalendar(this.state.filterData)});
  }


  render() {
    return(
      <div className="gx-mb-2">
        <span>Filter By: </span>
        <Select
          placeholder="Please User"
          style={{ marginRight: 20, width: 200 }}
          defaultValue={this.props.currentEmployee.id.toString()}
          onChange={this.handleChange}
        >
          {this.state.employees}
        </Select>
      </div>
    )
  }
}

export default WorkCalendarFilter;
