import {
  GET_USER_ID_SUCCESS,
  GET_DEPARTMENTS_SUCCESS,
} from "constants/ActionTypes";

const INIT_STATE = {
  employee_id: '',
  department_data: {},
};
export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_USER_ID_SUCCESS: {
      return {
        ...state,
        employee_id: action.payload,
      }
    }
    case GET_DEPARTMENTS_SUCCESS: {
      return {
        ...state,
        department_data: action.payload,
      }
    }
    default:
      return state;
  }
}
