import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Col, Row, Tabs} from "antd";
import Auxiliary from "../../../util/Auxiliary";
import {API_URL_V1} from "../../../constants";
import SummaryReport from "./SummaryReport";
import WeeklyReport from "./WeeklyReport";
import WorkCalendarReport from "./WorkCalendarReport";
import CircularProgress from "components/CircularProgress/index";
import Breadcrumbs from "components/Breadcrumbs/index";
import axios from "axios";

const TabPane = Tabs.TabPane;

class WorkReports extends Component {
  state={
    projectData: [],
    clientData: [],
    employeeData: [],
    employeeCount: 0,
    page: 1,
    per_page: 50,
    loading: true,
  }

  getEmployees = () => {
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/employees`, {
      params: {
        page: this.state.page, per_page: this.state.per_page
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(employeeData => {
      let employees = this.state.employeeData;
      employeeData.data.data.forEach((employee, index) => {
        employees.push(employee);
      })
      this.setState({...this.state, employeeData:employees, page: this.state.page + 1, employeeCount: this.state.employeeCount+this.state.per_page},() => {if(this.state.employeeCount < employeeData.data.meta.total) {return(this.getEmployees())}})
    })
    .catch(error => error)
  }

  getProjectData = () => {
    const {authUser, currentCompany} = this.props;
    axios.all([
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/projects`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/clients`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
    ])
    .then(axios.spread((projectData, clientData) => {
      this.setState({...this.state, projectData: projectData.data.data, clientData: clientData.data.data, loading: false});
    }))
    .catch(error => error)
  }

  componentDidMount () {
    this.getProjectData();
    this.getEmployees();
  }

  render() {
    return(
      <Auxiliary>
        <Breadcrumbs />
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Card className="gx-profile-content gx-card" title="Work Report">
            {this.state.loading ? <CircularProgress /> :
              <Tabs type="card">
                <TabPane tab="Summary" key="summary">
                  <SummaryReport projectData={this.state.projectData} clientData={this.state.clientData} employees={this.state.employeeData}/>
                </TabPane>
                <TabPane tab="Weekly" key="weekly">
                  <WeeklyReport projectData={this.state.projectData} clientData={this.state.clientData} employees={this.state.employeeData}/>
                </TabPane>
                <TabPane tab="Work Calendar" key="work_calendar">
                  <WorkCalendarReport projects={this.state.projectData} employees={this.state.employeeData}/>
                </TabPane>
              </Tabs>
            }
            </Card>
          </Col>
        </Row>
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany} = company;
  return {authUser, currentCompany}
};

export default connect(mapStateToProps)(WorkReports);
