import React, {Component} from 'react';
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import Auxiliary from "../../../util/Auxiliary";
import {Table, message} from "antd";
import axios from "axios";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../constants";

let fileDownload = require('js-file-download');
class Payslips extends Component {
  state = {
    payslips: [],
    pagination: {current: 1, pageSize: 15, total: 0},
    download: '',
    loading: true,
  }

  getPayslips = (params) => {
    const {authUser, currentEmployee} = this.props;
    axios.all([
      axios.get(`${API_URL_V1}/employees/${params ? params : currentEmployee.id}/payslips`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
    ])
    .then(axios.spread((payslipsData) => {
      const pagination = { ...this.state.pagination };
      pagination.pageSize = 15;
      pagination.total =payslipsData.data.total;
      this.setState({...this.state, payslips: payslipsData.data.data, pagination, loading: false});
    }))
    .catch(error => error)
  }

  componentDidMount() {
    let employee_id = this.props.match.params.id ? this.props.match.params.id : this.props.currentEmployee.id;
    this.getPayslips(employee_id);
  }

  download = (record, e) => {
    const {authUser} = this.props;
    let employee_id = this.props.match.params.id ? this.props.match.params.id : this.props.currentEmployee.id;
    axios.get(`${API_URL_V1}/employees/${employee_id}/payslips/${record.id}/download`, {
      responseType: 'blob',
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(downloadData => {
      fileDownload(downloadData.data, 'payslip.pdf');
    })
    .catch(error => {
      message.error("Something went wrong");
    })
  }

  handleTableChange = (paginationData) => {
    let employee_id = this.props.match.params.id ? this.props.match.params.id : this.props.currentEmployee.id;
    this.setState({...this.state, pagination: paginationData}, () => {this.getPayslips(employee_id)});
  }

  render() {
    const columns = [{
        title: 'Period',
        render: (text, record) => (
          <span className='gx-text'>
            {record.payrun.start} to {record.payrun.end}
          </span>
        )
      }, {
        title: 'Gross Amount',
        render: (text, record) => (
          <span>
            {record.gross_pay}
          </span>
        ),
      },{
        title: 'Net Amount',
        render: (text, record) => (
          <span>
            {record.net_pay}
          </span>
        ),
      }, {
        title: 'Manage',
        key: 'x',
        render: (text, record) => (
          <span className="gx-link" onClick={(e) => {this.download(record, e)}}>
            Download
          </span>
        )
      }];
    return (
      <Auxiliary>
      {this.state.loading ? <CircularProgress /> :
        <Table className="gx-table-responsive" dataSource={this.state.payslips} columns={columns} pagination={this.state.pagination} onChange={this.handleTableChange}/>
      }
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentEmployee, currentCompany} = company;
  return {authUser, currentEmployee, currentCompany}
};

export default withRouter(connect(mapStateToProps)(Payslips));
