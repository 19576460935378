import React, {Component} from "react";
import request from '../../../requests';
import {API_URL_V1} from '../../../constants';
import CompanyEventsUpdate from "../../../components/dashboard/CRM/CompanyEventsUpdate";

class CompanyEvents extends Component {
    state = {
      loading: false,
      requests: [],
    };

    componentDidMount(){
      this.getAllEvents();
    }

    getAllEvents = () => {
      this.setState({...this.state, loading: true})
      let today = new Date(), nextweek = new Date(today.getTime() + 7 * 24 * 60 * 60 * 1000);
      let dd = ("0"+today.getDate()).slice(-2), mm = ("0"+(today.getMonth()+1)).slice(-2), yyyy = today.getFullYear(),
      nextweekdd = ("0"+nextweek.getDate()).slice(-2), nextweekmm = ("0"+(nextweek.getMonth()+1)).slice(-2), nextweekyyyy = nextweek.getFullYear();
      today = yyyy+'-'+mm+'-'+dd;
      nextweek = nextweekyyyy+'-'+nextweekmm+'-'+nextweekdd;
      let workspace_id = this.props.company.id
      request.get(`${API_URL_V1}/workspaces/${workspace_id}/calendar_events`, {
        params: {
          from: today,
          to: nextweek,
        }
      })
      .then(response => {
        console.log(response.data);
        this.setState({...this.state, requests: response.data, loading: false})
      })
      .catch(error => {
        console.log(error);
        this.setState({...this.state, loading: false})
      })
    }

    render() {
      return (
        <CompanyEventsUpdate recentList={this.state.requests} refresh={this.getAllEvents} loader={this.state.loading}/>
      );
    }
  }
export default CompanyEvents;
