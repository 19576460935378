import React, {Component} from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import {Button, Card, Table, Divider, message,  Row, Col, Modal} from "antd";
import Auxiliary from "util/Auxiliary";
import Breadcrumbs from "components/Breadcrumbs/index";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../../constants";
import axios from "axios";
import service from '../../../../requests';
import moment from 'moment';
import {loadStripe} from '@stripe/stripe-js';
import {
  Elements, ElementsConsumer
} from '@stripe/react-stripe-js';
import StripeCheckout from "./StripeCheckout";


let data = [];
let workspace_id, disabled;

let plans = [
  {
    name: 'Monthly Subscription',
    plan_id: 'price_1GuuwzIw4RVm1bu7vwELeqy1',
    billing_cycle: 'Monthly',
    price: 3.00
  },
  {
      name: 'Yearly Subscription',
      plan_id: 'price_1H0Lp7Iw4RVm1bu7nbLk539l',
      billing_cycle: 'Yearly',
      price: 2.00
  }
]

const
    PriceItem = ({plan, styleName, headerStyle, itemStyle, footerStyle, planSelected}) => {
        return (
            <div className={`${styleName}`}>
                <div className={`${headerStyle}`}>
                    <h2 className="gx-price"><i className="icon icon-halfstar"/>${plan.price}</h2>/User/Month
                    <p className="gx-letter-spacing-base gx-text-white gx-text-uppercase gx-mb-0">{plan.name}</p>
                </div>

                <div className={`${itemStyle}`}>
                    <ul className="gx-package-items">
                        <li>
                            <i className="icon icon-hotel-booking"/>
                            <span>Billed {plan.billing_cycle}</span>
                        </li>
                        <li>
                            <i className="icon icon-error"/>
                            <span>Minimum Subscription for 5 Users</span>
                        </li>
                        <li>
                            <i className="icon icon-timepicker"/>
                            <span>Time Tracking Tool</span>
                        </li>
                        <li>
                            <i className="icon icon-revenue-new"/>
                            <span>Payroll Management</span>
                        </li>
                        <li>
                            <i className="icon icon-feedback"/>
                            <span>Employee Onboarding</span>
                        </li>
                        <li>
                            <i className="icon icon-calendar-new"/>
                            <span>Attendance and Leave Manager</span>
                        </li>
                        <li>
                            <i className="icon icon-map-street-view"/>
                            <span>Biometric (On Request)</span>
                        </li>
                        <li>
                            <i className="icon icon-user"/>
                            <span>Employee and Request Management</span>
                        </li>
                        <li>
                            <i className="icon icon-tasks"/>
                            <span>Projects, Documents and Clients Management</span>
                        </li>
                        <li>
                            <i className="icon icon-chart-line"/>
                            <span>Reports and Analytics</span>
                        </li>
                        <li>
                            <i className="icon icon-profile"/>
                            <span>Applicant Tracking System</span>
                        </li>
                        <li>
                            <i className="icon icon-profile2"/>
                            <span>Dedicated Support and much more...</span>
                        </li>
                    </ul>
                    <div className="gx-package-footer">
                        <Button type="primary" className={`${footerStyle}`} onClick={() => planSelected(plan)}>Subscribe Now</Button>
                    </div>
                </div>
            </div>
        )
    };

const stripePromise = loadStripe('pk_test_AzMRzJ5dAwacKUF46Ty37lGg00xeWbkcyI');

class Subscription extends Component {
  constructor(props){
    super(props)
    this.chargebeeInstance = window.Chargebee.init({
      site: "baseapp-test"
    });
    this.chargebeeInstance.setPortalSession(function() {
        return service.get('workspaces/'+props.currentCompany.id+'/billing/portal-session').then(res => res.data.portal_session)
      });

    this.state = {
      loading: true,
      subscribed: false,
      subscription: null,
      checkout: false,
      plan_id: 'monthly',
    };
  }

  getSubscriptions = () => {
    this.setState({...this.state, loading: true});
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/billing/subscriptions`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(subscriptionData => {
      this.setState({...this.state, subscribed: subscriptionData.data.data.subscribed, subscription: subscriptionData.data.data, loading: false});
    })
    .catch(error => error)
  }

  componentDidMount() {
    this.getSubscriptions();
  }

  planSelected = plan => {
      this.setState({...this.state, plan_id: plan.plan_id, checkout: true})
  }

  subscriptionInitiated = () => {
    this.setState({...this.state, checkout: false},() => {
      this.getSubscriptions();
    })
  }

  unsubscribe = e => {
    e.preventDefault();
    const {authUser, currentCompany} = this.props;
    this.setState({...this.state, cancelSubscriptionLoading: true});
    axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/billing/cancel`, {
    }, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(cancelSubscriptionData => {
        this.setState({...this.state, cancelSubscriptionLoading: false, cancelModal: false},() => {
          this.getSubscriptions();
        });
        message.success('Unsubscribed Successfully');
    })
    .catch(error => {
        this.setState({...this.state, cancelSubscriptionLoading: false});
        message.error('Something went wrong');
    })
  }

  render() {
    return(
      <div>
      <Modal
        visible={this.state.cancelModal}
        title="Unsubscribe?"
        onOk={(e) => this.unsubscribe(e)}
        onCancel={(e) => {this.setState({...this.state, cancelModal: false})}}
        confirmLoading={this.state.cancelSubscriptionLoading}
        footer={[
            <Button key="submit" loading={this.state.cancelSubscriptionLoading} onClick={(e) => this.unsubscribe(e)}>
              OK
            </Button>,
            <Button key="cancel" onClick={(e) => {this.setState({...this.state, cancelModal: false})}}>
              Cancel
            </Button>
          ]}
      >
        <p>Are you Sure you want to unsubscribe from CuteHR?</p>
      </Modal>

          <Auxiliary>
            <div className="gx-profile-content">
            <Breadcrumbs />
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Card className="gx-card" title="Subscription">
                  {this.state.loading ?
                    <CircularProgress/> :
                    <div>
                    {this.state.checkout ?
                      <Elements stripe={stripePromise}>
                      <ElementsConsumer>
                      {({stripe, elements}) => (
                        <StripeCheckout stripe={stripe} elements={elements} planId={this.state.plan_id} subscriptionInitiated={this.subscriptionInitiated}/>
                      )}
                      </ElementsConsumer>
                      </Elements> :
                      <div>
                    {this.state.subscribed ? <div className="gx-mt-4 gx-text-center">
                        <h2 style={{color: '#545454'}}>Subscribed</h2>
                        <br/>
                        <div className="gx-mb-1">
                          <b>Plan:</b> {plans.map((plan, index) => {
                            if(plan.plan_id === this.state.subscription.subscription.stripe_plan) {
                              return plan.name;
                            }
                          })}
                        </div>
                        <div className="gx-mb-1">
                          <b>Amount:</b> {plans.map((plan, index) => {
                            if(plan.plan_id === this.state.subscription.subscription.stripe_plan) {
                              return '$'+plan.price+'/user/month';
                            }
                          })}
                        </div>
                        <div className="gx-mb-1">
                          <b>Ends On:</b> {this.state.subscription.subscription.ends_at ? moment(this.state.subscription.subscription.ends_at).format('DD/MM/YYYY') : '--'}
                        </div>
                        {this.state.subscription.cancelled ?
                        <div className="gx-mb-1">
                          <b className="gx-text-red">Plan Cancelled</b>
                        </div>
                        : null
                        }
                        {this.state.subscription.cancelled ?
                        <Button className="gx-mt-2" type="primary" onClick={e => this.setState({...this.state, subscribed: false, subscription: null})}>View Plans</Button> : null }
                        <Button className="gx-mt-2" disabled={this.state.subscription.cancelled} onClick={e => this.setState({...this.state, cancelModal: true})}>{this.state.subscription.cancelled ? 'Cancelled' : 'Cancel Subscription'}</Button>
                      </div> :

                        <div className="gx-price-tables gx-pt-classic gx-mt-4">
                            <Row>
                                <Col xl={12} lg={12} md={12} xs={24}>
                                    <PriceItem
                                        plan={plans[0]}
                                        styleName="gx-bg-amber-light gx-package"
                                        headerStyle="gx-package-header gx-bg-amber gx-text-black"
                                        itemStyle="gx-package-body"
                                        footerStyle=""
                                        selectedPlan={this.state.plan}
                                        planSelected={this.planSelected}
                                    />
                                </Col>

                                <Col xl={12} lg={12} md={12} xs={24}>
                                    <PriceItem
                                        plan={plans[1]}
                                        styleName="gx-bg-primary-light gx-package gx-highlight"
                                        headerStyle="gx-package-header gx-bg-primary gx-text-white"
                                        itemStyle="gx-package-body gx-text-white"
                                        footerStyle="gx-btn-primary"
                                        selectedPlan={this.state.plan}
                                        planSelected={this.planSelected}
                                    />
                                </Col>
                            </Row>
                        </div>
                    }</div>}
                  </div>}
                  </Card>
                </Col>
              </Row>
            </div>
          </Auxiliary>
          </div>
        );
  };
}
const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentEmployee, currentCompany} = company;
  return {authUser, currentEmployee, currentCompany}
};

export default withRouter(connect(mapStateToProps)(Subscription));
