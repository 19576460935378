import React, {Component} from "react";
import {connect} from "react-redux";
import { Link } from 'react-router-dom'
import {Button, Col, Divider, Row, Table, Modal, message} from "antd";
import Auxiliary from "../../../../util/Auxiliary";
import StatusModal from "./StatusModal";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../../constants";
import axios from "axios";

let disabled;

class CompanyEvents extends Component {

  state = {
    visible: false,
    loading: true,
    title: '',
    statusTypes: [],
    locations: '',
    editData: '',
    confirmLoading: false,
    deleteVisible: false,
    deleteLoading: false,
    record: '',
  };

  componentDidMount() {
    this.setState({...this.state, statusTypes: this.props.statusType, loading: false});
  }

  onClick = () => {
    this.setState({...this.state, visible: true, title: "Add Status", editData: ''});
  }

  handleCancel = () => {
    this.setState({...this.state, visible: false});
  }

  edit = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, title: "Edit Status", editData: record, visible: true});
  }

  handleOk = (values, id) => {
    const {authUser, currentCompany} = this.props;
    const project_id = this.props.projectData.id;
    this.setState({...this.state, confirmLoading: true});
    if(!id) {
      axios.post(`${API_URL_V1}/projects/${project_id}/status-types`, {
          name: values.name,
          complete: values.complete,
          default: values.default,
          weight: values.weight,
          color: values.color ? values.color.hex : '',
        },
        {
          headers: {
            'Authorization': 'Bearer '+authUser
        }
      })
      .then(statusTypeData => {
        let statusTypes = this.state.statusTypes;
        statusTypes.push(statusTypeData.data);
        message.success("New Status Added");
        this.setState({...this.state, statusTypes: statusTypes, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Add Status");
        this.setState({...this.state, confirmLoading: false});
      })
    } else {
      axios.put(`${API_URL_V1}/projects/${project_id}/status-types/${id}`, {
          name: values.name,
          complete: values.complete,
          default: values.default,
          weight: values.weight,
          color: values.color ? values.color.hex : '',
        },
        {
          headers: {
          'Authorization': 'Bearer '+authUser
          }
        }
      )
      .then(statusTypeData => {
        let statusTypes = this.state.statusTypes;
        statusTypes.forEach((statusType, index) => {
          if(statusType.id == statusTypeData.data.id){
            statusTypes[index] = {...statusType, ...statusTypeData.data};
          }
        })
        message.success("Status Editted Successfully");
        this.setState({...this.state, statusTypes: statusTypes, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Edit");
        this.setState({...this.state, confirmLoading: false});
      })
    }
  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser, currentCompany} = this.props;
    const project_id = this.props.projectData.id;
    axios.delete(`${API_URL_V1}/projects/${project_id}/status-types/${record.id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(statusTypeData => {
      message.success("Status Deleted Successfully");
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      this.getStatusTypes();
    })
    .catch(error => error)
  }

  render() {
    disabled = (this.props.currentEmployee && this.props.currentEmployee.role) ? this.props.currentEmployee.role.permissions.includes("edit_company") : '';
    const columns = [{
        title: 'Name',
        render: (text, record) => (
          <span>
            {record.name}
          </span>
        )
      }, {
        title: 'Complete',
        render: (text, record) => (
          <span>
            {(record.complete).toString()}
          </span>
        )
      }, {
        title: 'Default',
        render: (text, record) => (
          <span>
            {(record.default).toString()}
          </span>
        )
      }, {
        title: 'Color',
        render: (text, record) => (
          <span>
            {record.color ? <span style={{backgroundColor: record.color, height: "25px", width: "25px", borderRadius: "50%", display: "inline-block"}}></span> : "No Color"}
          </span>
        )
      }, {
        title: 'Actions',
        key: 'x',
        render: (text, record) => (
          <span>
            {disabled ?
            <span className="gx-link" onClick={(e) => {this.edit(record, e)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span> : <span></span>}

            {disabled ?
                <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><Divider type="vertical"/><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span> :
                <span></span>
            }
          </span>
        )
      }];
    return (
      <div>
        {this.state.loading ?
          <CircularProgress /> :
        <div>
        <Modal
          visible={this.state.deleteVisible}
          title="Delete Status?"
          onOk={(e) => this.deleteRecord(this.state.record, e)}
          onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
          confirmLoading={this.state.deleteLoading}
          footer={[
            <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
              OK
            </Button>,
            <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
              Cancel
            </Button>
          ]}
        >
          <p>Are you Sure you want to delete this Status?</p>
        </Modal>
          <Auxiliary>
          {this.state.visible ?
          <StatusModal visible={this.state.visible} value={this.state.editData} title={this.state.title} onCancel={this.handleCancel} onOk={this.handleOk} loading={this.state.confirmLoading}/> : null}
            <div className="gx-profile-content">
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                    <div className="gx-mb-5">
                      <Button style={{float: "right"}} disabled={!disabled} onClick={this.onClick} value="Add">Add Custom Status</Button>
                    </div>
                    <Table className="gx-table-responsive" columns={columns} dataSource={this.state.statusTypes}/>
                </Col>
              </Row>
            </div>
          </Auxiliary>
        </div> }
      </div>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, currentCompany, currentEmployee}
};

export default connect(mapStateToProps)(CompanyEvents);
