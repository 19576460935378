import {all} from "redux-saga/effects";
import authSagas from "./Auth";
import employeeDataSagas from "./EmployeeData";
import companyDataSagas from "./Company"

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    employeeDataSagas(),
      companyDataSagas(),
  ]);
}
