import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, DatePicker, Table, Select, Input, Menu, Dropdown, Spin, Modal, Row, Col, message} from "antd";
import Auxiliary from "../../../util/Auxiliary";
import {API_URL_V1} from "../../../constants";
import ApproveModal from "./ApproveModal";
import CircularProgress from "components/CircularProgress/index";
import moment from 'moment';
import axios from "axios";

const { RangePicker } = DatePicker;
const {Option} = Select;

class EmployeesPayroll extends Component {

  state = {
    start_date: '',
    end_date: '',
    pay_date: '',
    payrollEntries: [],
    record: '',
    loading: true,
    allowanceVisible: false,
    allowRecord: '',
    deductionRecord: '',
    draftButtonLoading: false,
    spinning: false,
    approvePayrollModal: false,
    approveLoading: false,
    changed: false,
  };

  componentDidMount() {
    if(this.props.payrun.id) {
      const {authUser, currentCompany} = this.props;
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/payroll/payruns/${this.props.payrun.id}/entries`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(payrunsEntriesData => {
        this.setState({...this.state, payrollEntries: payrunsEntriesData.data.data, loading: false});
      })
      .catch(error => error)
    } else {
      this.setState({...this.state, loading: false});
    }
  }

  rangeChange = (date, dateString) => {
    this.setState({...this.state, start_date: dateString[0], end_date: dateString[1]});
  }

  dateChange = (date, dateString) => {
    this.setState({...this.state, pay_date: dateString});
  }

  saveDraft = () => {
    if(this.state.start_date === '' || this.state.end_date === '' || this.state.pay_date === '') {
      message.error("Insufficient Data");
    } else {
      this.setState({...this.state, draftButtonLoading: true});
      const {authUser, currentCompany} = this.props;
      axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/payroll/payruns/draft`, {
        start: this.state.start_date,
        end: this.state.end_date,
        pay_date: this.state.pay_date,
      },{
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(paydraftData => {
        message.success("Draft Saved Successfully");
        this.setState({...this.state, draftButtonLoading: false},() => {this.props.onBack('data')});
      })
      .catch(error => error)
    }
  }

  dataModified = (data) => {
    let value = '', hour = 0;
    if(data === 'true' || data === 'false') {
      value = data;
      hour = document.getElementById(`hour${this.state.record.id}`).value;
    } else {
      hour = data.target.value;
    }
    this.setState({...this.state, changed: true, spinning: true});
    const {authUser, currentCompany} = this.props;
    axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/payroll/payruns/${this.state.record.payrun_id}/entries/${this.state.record.id}`, {
      should_pay: value === "true" ? true : value === 'false' ? false : this.state.record.should_pay,
      work_hours: hour !== 0 ? hour : this.state.record.work_hours,
    },{
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(payrollEntriesData => {

      let payrollEntries = this.state.payrollEntries;
      payrollEntries.forEach((payrollEntry, index) => {
        if(payrollEntry.id == payrollEntriesData.data.id){
          payrollEntries[index] = {...payrollEntry, ...payrollEntriesData.data};
        }
      })
      message.success("Pay Status Updated");
      this.setState({...this.state, payrollEntries: payrollEntries, spinning: false});
    })
    .catch(error => error)
  }

  onAllowanceVisibleChange = flag => {
    this.setState({...this.state, changed: true, spinning: true, ["allowance"+this.state.allowRecord.id]: flag});
    const {authUser, currentCompany} = this.props;
    let allowance = [];
    this.state.allowRecord.allowance.map((entry, index) => {
      let data = {};
      data.name = entry.name;
      data.amount = Number(document.getElementById(entry.adjustment_id).value);
      data.adjustment_id = entry.adjustment_id;
      allowance.push(data);
    })
    axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/payroll/payruns/${this.state.allowRecord.payrun_id}/entries/${this.state.allowRecord.id}`, {
      allowance
    },{
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(payrollEntriesData => {
      let payrollEntries = this.state.payrollEntries;
      payrollEntries.forEach((payrollEntry, index) => {
        if(payrollEntry.id == payrollEntriesData.data.id){
          payrollEntries[index] = {...payrollEntry, ...payrollEntriesData.data};
        }
      })
      message.success("Allowances Updated");
      this.setState({...this.state, payrollEntries: payrollEntries, spinning: false});
    })
    .catch(error => error)
  }

  onDeductionVisibleChange = flag => {
    this.setState({...this.state, changed: true, spinning: true, ["deduction"+this.state.deductionRecord.id]: flag})
    const {authUser, currentCompany} = this.props;
    let deductions = [];
    this.state.deductionRecord.deductions.map((entry, index) => {
      let data = {};
      data.name = entry.name;
      data.amount = Number(document.getElementById(entry.adjustment_id).value);
      data.adjustment_id = entry.adjustment_id;
      deductions.push(data);
    })
    axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/payroll/payruns/${this.state.deductionRecord.payrun_id}/entries/${this.state.deductionRecord.id}`, {
      deductions
    },{
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(payrollEntriesData => {
      let payrollEntries = this.state.payrollEntries;
      payrollEntries.forEach((payrollEntry, index) => {
        if(payrollEntry.id == payrollEntriesData.data.id){
          payrollEntries[index] = {...payrollEntry, ...payrollEntriesData.data};
        }
      })
      message.success("Deductions Updated");
      this.setState({...this.state, payrollEntries: payrollEntries, spinning: false})
    })
    .catch(error => error)
  }

  approvePayrollModal = () => {
    this.setState({...this.state, approvePayrollModal: true});
  }

  approvePayrollCancel = () => {
    this.setState({...this.state, approvePayrollModal: false});
  }

  approvePayroll = (values) => {
    this.setState({...this.state, approveLoading: true});
    const {authUser, currentCompany} = this.props;
    let entries = this.state.payrollEntries;
    axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/payroll/payruns/${this.props.payrun.id}/save`, {
      entries,
      notify_employee: values.notify_employee,
    },{
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(data => {
      message.success("Payroll Approved Successfully");
      this.setState({...this.state, approvePayrollModal: false, approveLoading: false},() => {this.props.onBack('data')})
    })
    .catch(error => error)
  }

  render() {
    const columns = [{
        title: 'Employee Name',
        render: (text, record) => (
          <span>
          {record.employee.first_name+" "+record.employee.last_name}
          </span>
        )
      }, {
        title: 'Pay',
        render: (text, record) => {
          return (
            <Select defaultValue={(record.should_pay).toString()} disabled={this.props.payrun.status === 'complete'} onChange={(value) => {this.setState({...this.state, record: record}, () => {this.dataModified(value)})}}>
              <Option value="true">Yes</Option>
              <Option value="false">No</Option>
            </Select>
          )
        }
      }, {
        title: 'Hours',
        render: (text, record) => (
          <span>
           <Input id={`hour${record.id}`} style={{width: 50}} disabled={this.props.payrun.status === 'complete'} defaultValue={record.work_hours} onPressEnter={(e) => {e.persist(); this.setState({...this.state, record: record}, () => {this.dataModified(e)})}}/>
          </span>
        ),
      }, {
        title: 'Gross Pay',
        render: (text, record) => {
          let gross_pay = 0;
          gross_pay = gross_pay + +record.basic_pay;
          const allowanceMenu = (
            <Menu>
            {record.allowance.length !== 0 ? record.allowance.map((allow, index) => {
              gross_pay = gross_pay + allow.amount;
              return <Menu.Item key={(allow.name)}><span style={{width: "150px"}}>{allow.name}:</span> <Input id={allow.adjustment_id} disabled={this.props.payrun.status === 'complete'} placeholder="value" defaultValue={allow.amount ? allow.amount.toFixed(2) : ''} style={{width: 100}}/></Menu.Item>
            }) : <Menu.Item key="1">No Allowance</Menu.Item>}
            {(record.allowance.length !== 0) ?
            <Menu.Item key="button"><Button block disabled={this.props.payrun.status === 'complete'} onClick={() => {this.setState({...this.state, allowRecord: record},() => {this.onAllowanceVisibleChange(false)})}}>Update</Button></Menu.Item> : ''}
            </Menu>
          );
          return(
          <Dropdown overlay={allowanceMenu} visible={this.state["allowance"+record.id]} onVisibleChange={(flag) => {this.setState({...this.state, ["allowance"+record.id]: flag})}} trigger={['click']}>
            <span className="gx-link">{gross_pay.toFixed(2)}</span>
          </Dropdown>
        )}
      }, {
        title: 'Deductions',
        render: (text, record) => {
          let totalDeduction = 0;
          const deductionMenu = (
            <Menu>
            {record.deductions.length !== 0 ? record.deductions.map((deduction, index) => {
              totalDeduction = totalDeduction + deduction.amount;
              return <Menu.Item key={(deduction.name)}><span style={{width: "150px"}}>{deduction.name}:</span> <Input id={deduction.adjustment_id} disabled={this.props.payrun.status === 'complete'} placeholder="value" defaultValue={deduction.amount ? deduction.amount.toFixed(2) : ''} style={{width: 100}}/></Menu.Item>
            }) : <Menu.Item key="1">No Deductions</Menu.Item>}
            {(record.deductions.length !== 0) ?
            <Menu.Item key="button"><Button block disabled={this.props.payrun.status === 'complete'} onClick={() => {this.setState({...this.state, deductionRecord: record},() => {this.onDeductionVisibleChange(false)})}}>Update</Button></Menu.Item> : ''}
            </Menu>
          );
          return (
            <Dropdown overlay={deductionMenu} visible={this.state["deduction"+record.id]} onVisibleChange={(flag) => {this.setState({...this.state, ["deduction"+record.id]: flag})}} trigger={['click']}>
              <span className="gx-link">{totalDeduction.toFixed(2)}</span>
            </Dropdown>
          )
        }
      }, {
        title: 'Total',
        dataIndex: 'net_pay'
      }];
    return (
      <Auxiliary>
        {this.state.approvePayrollModal ?
          <ApproveModal title="Approve Payroll" visible={this.state.approvePayrollModal} onCancel={this.approvePayrollCancel} onOk={this.approvePayroll} loading={this.state.approveLoading}/> : null}
        {this.state.loading ? <CircularProgress /> :
        <div style={{paddingTop: "30px"}}>
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <RangePicker
                className="gx-mb-3"
                placeholder={['From', 'To']}
                style={{marginRight: "15px", width: "300px"}}
                onChange={this.rangeChange}
                disabled={this.props.payrun.id}
                defaultValue={this.props.payrun ? [moment(this.props.payrun.start), moment(this.props.payrun.end)] : ''}
              />
              <DatePicker
                className="gx-mb-3"
                placeholder="Pay Date"
                style={{width: "300px"}}
                onChange={this.dateChange}
                disabled={this.props.payrun.id}
                defaultValue={this.props.payrun ? moment(this.props.payrun.pay_date) : ''}
              />


              <span style={{verticalAlign: "baseline", float: "right"}}>
                <Button type="link" onClick={() => {this.props.onBack(this.state.changed ? 'data' : 'nodata')}}>Back</Button>
                {this.props.payrun.id ? '' :
                <Button onClick={this.saveDraft} loading={this.state.draftButtonLoading}>Save Draft</Button>}
                {this.props.payrun.id ?
                <Button onClick={this.props.payrun.id ? this.approvePayrollModal : ''} disabled={this.props.payrun.status === 'complete'}>Approve Payroll</Button> : ''}
              </span>
            </Col>
          </Row>
          {this.props.payrun.id ?
          <Spin spinning={this.state.spinning}>
            <Table className="gx-table-responsive" dataSource={this.state.payrollEntries} columns={columns}/>
          </Spin> : ''}
        </div>
        }
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany} = company;
  return {authUser, currentCompany}
};

export default connect(mapStateToProps)(EmployeesPayroll);
