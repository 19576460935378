import React, {Component} from "react";
import {API_URL_V1} from "../../constants";
import { withRouter } from 'react-router';
import axios from "axios";
import {connect} from "react-redux";
import {switchCompany, setDefaultCompany} from "appRedux/actions/Company";


import {
  Modal, Form, Input, Button
} from 'antd';

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  // eslint-disable-next-line
  class extends Component {
    render() {
      const formItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 8},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 16},
        },
      };
      const {
        visible, onCancel, onCreate, form,
      } = this.props;
      const { getFieldDecorator } = form;
      return (
        <Modal
          visible={visible}
          title="Basic Information"
          okText="Submit"
          onCancel={onCancel}
          onOk={onCreate}
          footer={[
            <Button key="submit" onClick={onCreate}>
              Submit
            </Button>,
            <Button key="cancel" onClick={onCancel}>
              Cancel
            </Button>
          ]}
        >
          <Form >
            <Form.Item
            {...formItemLayout}
            label="Company Name">
              {getFieldDecorator('company_name', {
                rules: [{ required: true, message: 'Please input the Company Name!' }],
              })(
                <Input placeholder= "Company Name"/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Phone No."
            help={<span style={{color: "#8c8c8c"}}>Someone from our team will contact you.<br/>We won't spam you</span>}>
              {getFieldDecorator('phone_number', {
                rules: [{ required: true, message: 'Please input the Phone Number!' }],
              })(
                <Input placeholder= "Phone Number"/>
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

class Modalpopup extends React.Component {
  state = {
    visible: true,
  };

  showModal = () => {
    this.setState({ visible: true });
  }

  handleCancel = () => {
    this.setState({ visible: false });
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    const {authUser} = this.props;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }

      console.log('Received values of form: ', values);
      axios.post(`${API_URL_V1}/workspaces`, {
          name: values.company_name,
          phone_number: values.phone_number
        },
        {
          headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(response => {
        let company = response.data.data;
        localStorage.setItem('id', company.id);
        this.props.switchCompany(company);
        this.props.setDefaultCompany(company.id);
        this.props.history.push('/dashboard');

      })
      .catch(error => {console.log(error)});
      form.resetFields();
      this.setState({ visible: false });
    });
  }

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  render() {
    return (
      <div>
        {this.state.visible ?
        <CollectionCreateForm
          wrappedComponentRef={this.saveFormRef}
          visible={true}
          onCancel={this.handleCancel}
          onCreate={this.handleCreate}
        /> : null
        }
      </div>
    );
  }
}
const mapStateToProps = ({auth}) => {
  const {authUser} = auth;
  return {authUser}
};

export default withRouter(connect(mapStateToProps, {
  switchCompany,
  setDefaultCompany,
})(Modalpopup));
