import React, {Component} from "react";
import {Modal, Form, Input, Select, DatePicker, Button, Checkbox} from 'antd';
import moment from "moment";

const Option = Select.Option;
const {TextArea} = Input;

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends Component {

    state={
      startDate: moment(),
    }

    disabledDate = (current) => {
      return current && current < this.state.startDate.startOf('day');
    }

    onStartDateChange = (date, dateString) => {
      this.setState({...this.state, startDate: date});
    }

    render() {
      let employees=[];
      employees.push(<Option value=''>Anyone</Option>)
      this.props.allEmployees.forEach(employee => {
        let name = `${employee.first_name} ${employee.last_name}`;
        employees.push(<Option value={(employee.id).toString()}>{name}</Option>);
      })
      const formItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 8},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 16},
        },
      };
      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      };

      const { getFieldDecorator } = this.props.form;
      return (
        <Modal
          visible={this.props.visible}
          title={this.props.title}
          onCancel={this.props.onCancel}
          onOk={this.props.onCreate}
          footer={[
            <Button key="submit" loading={this.props.confirmLoading} onClick={this.props.onCreate}>
              {this.props.title}
            </Button>,
            <Button key="cancel" onClick={this.props.onCancel}>
              Cancel
            </Button>
          ]}
        >
          <Form >
            <Form.Item
            {...formItemLayout}
            label="Title">
              {getFieldDecorator('title', {
                initialValue: this.props.value ? this.props.value.title : '',
                rules: [{ required: true, message: 'Please Enter the Title!' }],
              })(
                <Input placeholder="Title"/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Description">
              {getFieldDecorator('description', {
                initialValue: this.props.value.description,
              })(
                <TextArea placeholder= "Description"/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Assign To">
              {getFieldDecorator('employee_id', {
                initialValue: this.props.value && this.props.value.employee_id ? (this.props.value.employee_id).toString() : undefined,
              })(
                <Select placeholder="Select Employee" showAction={['click', 'focus']} filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} showSearch optionFilterProp="children">
                  {employees}
                </Select>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Start Date">
              {getFieldDecorator('start_date', {
                initialValue: this.props.value ? this.props.value.start_date ? moment(this.props.value.start_date) : undefined : moment(),
              })(
                <DatePicker placeholder= "Start Date" className="gx-mb-3 gx-w-100" onChange={this.onStartDateChange}/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Due Date">
              {getFieldDecorator('due_date', {
                initialValue: this.state.startDate > moment() ? this.state.startDate : this.props.value ? this.props.value.due_date ? moment(this.props.value.due_date) : undefined : moment(),
              })(
                <DatePicker placeholder= "Due Date" className="gx-mb-3 gx-w-100" disabledDate={this.disabledDate}/>
              )}
            </Form.Item>
            {this.props.value ?
              <Form.Item
              {...formItemLayout}
              label="Status">
                {getFieldDecorator('status', {
                  initialValue: this.props.value.status_id ? (this.props.value.status_id).toString() : undefined,
                })(
                  <Select placeholder="Select Status" showAction={['click', 'focus']}>
                    {this.props.status}
                  </Select>
                )}
              </Form.Item> : ''
            }
            <Form.Item
            {...tailFormItemLayout}>
              {getFieldDecorator('notify', {
              valuePropName: 'checked',
              initialValue: true,
              })(
                <Checkbox>Notify</Checkbox>
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

class AddTaskModal extends React.Component {

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onOk(values, this.props.value.id);
    });
  }

  render() {
    return (
      <div>
        <CollectionCreateForm
          title={this.props.title}
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          onCreate={this.handleCreate}
          value={this.props.value}
          allEmployees={this.props.employees}
          status={this.props.status}
          confirmLoading={this.props.confirmLoading}
          defaultStatus={this.props.defaultStatus}
        />
      </div>
    );
  }
}

export default AddTaskModal;
