import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Card, Table, Modal, Divider, message} from "antd";
import Auxiliary from "../../../../util/Auxiliary";
import CircularProgress from "components/CircularProgress/index";
import LeavesTypesModal from "./LeavesTypesModal";
import {API_URL_V1} from "../../../../constants";
import axios from "axios";

let disabled;

class LeavesTypes extends Component {

  constructor(props){
    super(props)
    this.state = {
      visible: false,
      title: '',
      leaveTypes: [],
      loading: true,
      editData: '',
      confirmLoading: false,
      deleteVisible: false,
      deleteLoading: false,
      record: '',
    };
  }

  getLeaveTypes = () => {
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/leaves/types`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(leaveTypeData => {
      this.setState({...this.state, leaveTypes: leaveTypeData.data.data, loading: false})
    })
    .catch(error => error);
  }
  componentDidMount() {
    this.getLeaveTypes();
  }

  onClick = () => {
    this.setState({...this.state, visible: true, title: "Add Leave Type", editData: ''});
  }

  handleCancel = () => {
    this.setState({...this.state, visible: false});
  }

  edit = (record, e) => {
    e.preventDefault();
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/leaves/types/${record.id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(editData => {
      this.setState({...this.state, visible: true, title: "Edit Leave Policy", editData: editData.data.data});
    })
    .catch(error => error);
  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser, currentCompany} = this.props;
    axios.delete(`${API_URL_V1}/workspaces/${currentCompany.id}/leaves/types/${record.id}`, {
      params: {
        workspace_id: currentCompany.id,
        type_id: record.id,
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(leavestypesData => {
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      this.getLeaveTypes();
      message.success("Leave Type Deleted Successfully");
    })
    .catch(error => {
      message.error("Failed to Delete");
    })
  }

  handleOk = (values, id) => {
    this.setState({...this.state, confirmLoading: true});
    const {authUser, currentCompany} = this.props;
    if(!id){
      axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/leaves/types`, {
        name: values.leavestypes_name,
        approval_required: values.requires_approval=='true' ? true : false,
        accrues: values.leave_accrues=='true' ? true : false,
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(leavestypeData => {
        let leaveTypes = this.state.leaveTypes;
        leaveTypes.push(leavestypeData.data.data);
        message.success("New Leave Type Added");
        this.setState({...this.state, leaveTypes: leaveTypes, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Add Leave Type");
        this.setState({...this.state, confirmLoading: false});
      })
    } else {
      axios.put(`${API_URL_V1}/workspaces/${currentCompany.id}/leaves/types/${id}`, {
        name: values.leavestypes_name,
        approval_required: values.requires_approval=='true' ? true : false,
        accrues: values.leave_accrues=='true' ? true : false,
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(leavestypeData => {
        let leaveTypes = this.state.leaveTypes;
        leaveTypes.forEach((leaveType, index) => {
          if(leaveType.id === leavestypeData.data.data.id) {
            leaveTypes[index] = {...leaveType, ...leavestypeData.data.data};
          }
        })
        message.success("Leaves Type Editted Successfully");
        this.setState({...this.state, leaveTypes: leaveTypes, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Edit");
        this.setState({...this.state, confirmLoading: false});
      })
    }
  }

  render() {
    disabled = (this.props.currentEmployee && this.props.currentEmployee.role) ? this.props.currentEmployee.role.permissions.includes("edit_company") : '';
    const columns = [{
        title: 'Name',
        dataIndex: 'name',
      }, {
        title: 'Requires Approval',
        render: (text, record) => (
          <span>{(record.approval_required).toString()}</span>
        )
      }, {
        title: 'Actions',
        key: 'x',
        render: (text, record) => (
          <span>
            {disabled ?
            <span className="gx-link" onClick={(e) => {this.edit(record, e)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span> : <span></span>}

            {disabled ?
              <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><Divider type="vertical"/><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span> :
              <span></span>
            }
          </span>
        )
      }];
    return(
      <div>
      <Modal
        visible={this.state.deleteVisible}
        title="Delete Leave Type?"
        onOk={(e) => this.deleteRecord(this.state.record, e)}
        onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
        confirmLoading={this.state.deleteLoading}
        footer={[
          <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
            OK
          </Button>,
          <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
            Cancel
          </Button>
        ]}
      >
        <p>Are you Sure you want to delete this Leave Type?</p>
      </Modal>
      {this.state.loading ?
      <CircularProgress/> :
      <div>
      {this.state.visible ?
        <LeavesTypesModal title={this.state.title} value={this.state.editData} visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel} confirmLoading={this.state.confirmLoading}/> : null }
      <Auxiliary>
        <div style={{paddingBottom: "2.5rem"}}>
          <Button style={{float: "right"}} disabled={!disabled} onClick={this.onClick} value="Add">Add Leave Type</Button>
        </div>
        <Table className="gx-table-responsive" columns={columns} dataSource={this.state.leaveTypes} pagination={false}/>
      </Auxiliary>
      </div>}
      </div>
    );
  };
}
const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentEmployee, currentCompany} = company;
  return {authUser, currentEmployee, currentCompany}
};

export default connect(mapStateToProps)(LeavesTypes);
