import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Form, Input, message} from "antd";
import {
  showAuthLoader,
  userJoin,
  hideMessage,
} from "appRedux/actions/Auth";
import backgroundImage from "../assets/images/signIn.svg";

const FormItem = Form.Item;

class Join extends Component {

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.showAuthLoader();
        this.props.userJoin(values);
      }
    });
  };

  compareToFirstPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && value !== form.getFieldValue('password')) {
      callback('Two passwords that you enter is inconsistent!');
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const form = this.props.form;
    if (value && this.props.confirmDirty) {
      form.validateFields(['confirm'], {force: true});
    }
    callback();
  };

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/dashboard');
    }
  }

  render() {
    const {getFieldDecorator} = this.props.form;
    const {showMessage, loader, alertMessage} = this.props;

    return (
      <div className="gx-app-login-wrap" style={{background: `url(${backgroundImage})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundColor: "#fff"}}>
        <div className="gx-app-login-container">
          <div className="gx-app-logo-content">
            <div className="gx-app-logo-wid">
              <div className="gx-app-logo gx-mb-2">
                <img height="30px" alt="logo" src={require("assets/images/logo-colored.png")}/>
              </div>
              <h1 style={{color: "#444"}}>Join CuteHR</h1>
              <p>Create Password for CuteHR</p>
            </div>
          </div>
          <div className="gx-app-login-main-content">
          <div className="gx-app-login-content">
          <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">

            <FormItem>
              {getFieldDecorator('password', {
                rules: [{
                  required: true, message: 'Please input your password!',
                }, {
                  validator: this.validateToNextPassword,
                }],
              })(
                <Input type="password" placeholder="Password" size="large"/>
              )}
            </FormItem>

            <FormItem>
              {getFieldDecorator('confirm', {
                rules: [{
                  required: true, message: 'Please confirm your password!',
                }, {
                  validator: this.compareToFirstPassword,
                }],
              })(
                <Input placeholder="Retype Password" type="password" onBlur={this.handleConfirmBlur} size="large"/>
              )}
            </FormItem>

            <FormItem>
              <Button type="primary" htmlType="submit" size="large" block>
                Join
              </Button>
            </FormItem>
          </Form>
          </div>
          </div>
          {showMessage ?
            message.error(alertMessage.toString()) : null}
        </div>
      </div>
    );
  }
}

const WrappedJoin = Form.create()(Join);

const mapStateToProps = ({auth}) => {
  const {alertMessage, showMessage, authUser} = auth;
  return {alertMessage, showMessage, authUser}
};

export default connect(mapStateToProps, {
  showAuthLoader,
  userJoin,
  hideMessage,
})(WrappedJoin);
