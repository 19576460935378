import React, {Component} from "react";
import {Card, List, Button, Row, Col} from "antd";
import OpeningDetails from "./OpeningDetails";
import Auxiliary from "../util/Auxiliary";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../constants";
import axios from "axios";

class Openings extends Component {
  state={
    openings: [],
    openingDetail: [],
    detailVisible: false,
    loading: true,
  };

  componentDidMount () {
    let companyId = this.props.match.params.id;
    axios.get(`${API_URL_V1}/public/workspaces/${companyId}/ats/openings`, {
    })
    .then(openingsData => {
      this.setState({...this.state, openings: openingsData.data.data, loading: false});
    })
    .catch(error => error)
  }

  showDetail = (item, e) => {
    e.preventDefault();
    let opening = [];
    opening.push(item);
    this.setState({...this.state, openingDetail: opening, detailVisible: true});
  }

  goBack = () => {
    this.setState({...this.state, openingDetail: [], detailVisible: false});
  }

  render() {
    return(
      <Auxiliary>
        {this.state.loading ? <CircularProgress /> :
          <div style={{height: "100%", backgroundColor: "white"}}>
          <Row>
            <Col offset={5} span={14} >
          {this.state.detailVisible ? <OpeningDetails opening={this.state.openingDetail} goBack={this.goBack}/> :

              <Card className="gx-opening-card" title="Job Openings" bordered={false} style={{maxWidth: "970px", height: "100%"}}>
                <List
                  itemLayout="horizontal"
                  bordered
                  dataSource={this.state.openings}
                  renderItem={item => (
                    <List.Item
                      actions={[<span className="gx-link" onClick={(e) => {this.props.history.push({pathname: '/openings/'+item.company_id+'/job/'+item.id, state: {opening: item}})}} key="list-view">view</span>]}
                    >
                      <List.Item.Meta
                        title={
                          <div>
                            <div>{item.title}</div>
                            <div>
                              <span>Type: {item.position_type.toUpperCase().replace("_", " ")}</span>
                              <span style={{fontWeight: 400, marginLeft: "20px"}}><i className="icon icon-location" title="Location" style={{verticalAlign: "middle"}}/>{item.location.city}, {item.location.country}</span>
                            </div>
                          </div>
                        }
                      />
                    </List.Item>
                  )}
                />
              </Card>

          }
          </Col>
        </Row>
          </div>
        }
      </Auxiliary>
    );
  }
}

export default Openings;
