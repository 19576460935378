import React, {Component} from "react";
import {connect} from "react-redux";
import {Card} from "antd";
import {API_URL_V1} from "../../../constants";
import CircularProgress from "components/CircularProgress/index";
import ReportFilters from "./ReportFilters";
import WeeklyReportTable from "./WeeklyReportTable";
import axios from "axios";
import moment from "moment";

class WeeklyReport extends Component {
  state={
    weeklyReportData: [],
    start_date: moment().startOf('week').format("YYYY-MM-DD"),
    end_date: moment().endOf('week').format("YYYY-MM-DD"),
    employee_ids: '',
    client_ids: '',
    project_ids: '',
    tableLoading: true,
    loading: true,
  }

  getWeeklyData = () => {
    const {authUser, currentCompany} = this.props;
    axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/reports/tracker/weekly/entries`, {
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        employee_ids: this.state.employee_ids,
        project_ids: this.state.project_ids,
        client_ids: this.state.client_ids,
      }, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
    })
    .then(weeklyReportData => {
      this.setState({...this.state, weeklyReportData: weeklyReportData.data, tableLoading: false, loading: false});
    })
    .catch(error => error)
  }

  componentDidMount() {
    const {authUser, currentCompany} = this.props;
    this.getWeeklyData();
  }

  filterWeekReport = (params) => {
    this.setState({
      ...this.state,
      employee_ids: (params && params.employees && (params.employees).length > 0) ? params.employees : (this.state.employee_ids.length) ===0 ? '' : this.state.emplyee_ids,
      project_ids: (params && params.project && (params.project).length > 0) ? params.project : (this.state.project_ids.length) ===0 ? '' : this.state.project_ids,
      client_ids: (params && params.clients && (params.clients).length > 0) ? params.clients : (this.state.client_ids.length) ===0 ? '' : this.state.client_ids,
      start_date: (params && params.date) ? moment(params.date).startOf('week').format("YYYY-MM-DD") : this.state.start_date,
      end_date: (params && params.date) ? moment(params.date).endOf('week').format("YYYY-MM-DD") : this.state.end_date,
      tableLoading: true,
    },() => {this.getWeeklyData()});

  }

  render() {
    return(
        <div>
        {this.state.loading ?
        <CircularProgress /> :
        <div>
        <Card className="gx-card">
          <ReportFilters page="WeeklyReport" projects={this.props.projectData} clients={this.props.clientData} employees={this.props.employees} filterWeekReport={this.filterWeekReport}/>
        </Card>
        <WeeklyReportTable data={this.state.weeklyReportData} projectData={this.props.projectData} date={this.state.start_date} loading={this.state.tableLoading} employeeData={this.props.employeeData}/>
        </div>
        }
        </div>
    )
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany} = company;
  return {authUser, currentCompany}
};
export default connect(mapStateToProps)(WeeklyReport);
