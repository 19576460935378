import React, {Component} from "react";
import {connect} from "react-redux";
import {Row, Col, Card, Input, Button, Divider, Dropdown, Menu, Form, Spin, Modal, TimePicker, message} from "antd";
import Auxiliary from "util/Auxiliary";
import {FaDollarSign, FaTag} from "react-icons/fa";
import {API_URL_V1} from "../../../constants";
import CircularProgress from "components/CircularProgress/index";
import TimeEntry from "./TimeEntry";
import Tracker from "./Tracker";
import {setCurrentTimer} from "appRedux/actions/Company";
import moment from 'moment';
import axios from "axios";

let project, currentTimerId, entryTime="";
const InputGroup = Input.Group;
const FormItem = Form.Item;
class CurrentEntry extends Component {
  constructor(props) {
    super(props);
    this.descriptionRef = React.createRef();
  }
  state={
    billable: false,
    projectData: '',
    selectedProject: '',
    projectId: this.props.entry ? this.props.entry.project_id : '',
    loading: true,
    start: false,
    time: 0,
    timerId: '',
    value:'',
    iconLoading: false,
    spinning: true,
    restrictModal: false,
    visible: false,
    timeModal: false,
  }

  echo= null

  componentWillReceiveProps(nextProps) {
    if (this.props.entry != nextProps.entry) {
      this.descriptionRef.current.state.value = nextProps.entry ? nextProps.entry.description : '';
      this.setState({...this.state, projectId: nextProps.entry ? nextProps.entry.project_id : this.state.projectId});
    }
  }


  onClick = () => {
    this.setState({...this.state, billable: !this.state.billable}, () => {message.success(this.state.billable ? "Time Entry marked as Billable" : "Time Entry marked as Non-Billable")});
  }


  onStart = () => {
    this.setState({...this.state, iconLoading: true});
    const {authUser, entry, currentCompany} = this.props;
    const inputVal = this.descriptionRef.current.input.value;
    let url = entry ? `${API_URL_V1}/workspaces/${currentCompany.id}/time_entries/${entry.id}` : `${API_URL_V1}/workspaces/${currentCompany.id}/time_entries`;
    let method = entry ? 'put' : 'post';
    let data = entry ? {
      end: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
      } : {
      description: inputVal,
      project_id: this.state.projectId,
      task_id: '',
      billable: this.state.billable ? 1 : 0,
      start: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
    };
    axios({method: method, url: url, data: data,
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(timeData => {
      if(entry) {
        this.props.setCurrentTimer(null);
        this.setState({...this.state, billable: false, projectId: '', selectedProject: ''});
      } else {
        this.props.setCurrentTimer(timeData.data.data);
      }
      this.setState({...this.state, iconLoading: false, modalVisible: false});
      this.props.afterUpdate(timeData.data.data);
    })
    .catch(error => error)
  }

  onAddEntry = (data) => {
    const {authUser, currentCompany} = this.props;
    axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/time_entries`, {
      description: data.id ? data.description : '',
      project_id: data.id ? data.project_id : '',
      task_id: data.id ? data.task_id : '',
      billable: data.id ? data.billable : '',
      start: data.id ? moment(data.start).format("YYYY-MM-DD HH:mm:ss") : moment(data).format("YYYY-MM-DD HH:mm:ss"),
      end: data.id ? moment(data.end).format("YYYY-MM-DD HH:mm:ss") : moment(data).add(1, 'hours').format("YYYY-MM-DD HH:mm:ss"),
    },
    {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(timerData => {
      message.success(data.id ? "Entry Duplicated Successfully" : "Dummy Entry Added")
      this.getTimeData();
    })
    .catch(error => error);
  }

  handleMenuClick = (e) => {
    if(this.props.timer_desc_required && (this.descriptionRef.current.input.value === '' || !this.state.projectId)) {
      this.setState({...this.state, restrictModal: true});
    }
    else if(!this.props.entry && this.descriptionRef.current.input.value === '') {
      this.setState({...this.state, modalVisible: true});
    } else {
      if(e.key == 0) {
        if(this.props.entry) {
          this.setState({...this.state, projectId: ''}, () => {this.handleInputChange()})
        }
      } else {
        if(this.props.entry) {
          this.setState({...this.state, projectId: e.key},() => {this.handleInputChange(e.key, this.props.entry.id)})
        } else {
          this.setState({...this.state, selectedProject: e.item.props.children, projectId: e.key}, () => !this.state.start ? this.handleInputChange() : '');
        }
      }
    }
  }

  handleInputChange = (projectId, id, value) => {
    if(this.props.timer_desc_required && (this.descriptionRef.current.input.value === '' || !this.state.projectId)) {
      this.setState({...this.state, restrictModal: true});
    }
    else if(!this.props.entry && this.descriptionRef.current.input.value === '') {
      this.setState({...this.state, modalVisible: true});
    }
    else if(!this.props.entry && !id) {
      this.onStart();
    } else {
      const {authUser, currentCompany} = this.props;
      const url = `${API_URL_V1}/workspaces/${currentCompany.id}/time_entries/${id ? id : this.props.entry.id}`;
      const method = 'put';
      const data = {
        description: this.descriptionRef.current.input.value,
        project_id: (id && projectId) ? projectId : this.state.projectId !==null ? this.state.projectId : this.props.entry.project_id ? this.props.entry.project_id : '',
        task_id: '',
        billable: this.state.billable ? 1 : 0,
      }
      this.props.setData(method, url, data);
    }
  }

  handleRestrictCancel = () => {
    this.setState({...this.state, restrictModal: false});
  }

  checkInput = () => {
    if(this.props.timer_desc_required && (this.descriptionRef.current.input.value === '' || !this.state.projectId)) {
      this.setState({...this.state, restrictModal: true});
    }
    else if(!this.props.entry && this.descriptionRef.current.input.value === '') {
      this.setState({...this.state, modalVisible: true});
    } else {
      this.onStart();
    }
  }

  handleCancel = () => {
    this.setState({...this.state, modalVisible: false});
  }

  onVisibleChange = flag => {
    let duration = moment.duration(moment(new Date(), "HH:mm:ss").diff(moment(entryTime, "HH:mm:ss"))).asMilliseconds();
    if(duration < 0) {
      this.setState({...this.state, timeModal: true});
    } else {
      if(this.props.entry) {
        this.setState({...this.state, visible: flag});
        if(flag === false && entryTime) {
          const {authUser, currentCompany} = this.props;
          const url = `${API_URL_V1}/workspaces/${currentCompany.id}/time_entries/${this.props.entry.id}`;
          const method = 'put';
          const data = {
            description: this.descriptionRef.current.input.value,
            project_id: this.props.entry.project_id,
            task_id: '',
            billable: this.state.billable ? 1 : 0,
            start: moment(entryTime, "HH:mm:ss").utc().format("YYYY-MM-DD HH:mm:ss"),
          }
          this.props.setData(method, url, data);
        }
      }
    }
  }

  changeTime = (time, timeString) => {
    entryTime= timeString;
  }

  getDisabledHours = () => {
    let hours = [];
    for(let i =23; i > moment().hour(); i--){
      hours.push(i);
    }
    return hours;
  }

  getDisabledMinutes = (selectedHour) => {
    var minutes= [];
    if (selectedHour === moment().hour()){
      for(var i =59; i > moment().minute(); i--){
        minutes.push(i);
      }
    }
    return minutes;
  }

  getDisabledSeconds = (selectedHour, selectedMinutes) => {
    var seconds= [];
    if (selectedHour === moment().hour()){
      if (selectedMinutes === moment().minute()) {
        for(var i =59; i > moment().seconds(); i--){
          seconds.push(i);
        }
      }
    }
    return seconds;
  }

  handleTimeCancel = () => {
    this.setState({...this.state, timeModal: false});
  }

  changeTimeByEnter = (e) => {
    let code = e.keyCode || e.which;
    let isValid = /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/.test(e.target.value);
    if(code === 13) {
      if(isValid) {
        const {authUser, currentCompany} = this.props;
        const url = `${API_URL_V1}/workspaces/${currentCompany.id}/time_entries/${this.props.entry.id}`;
        const method = 'put';
        const data = {
          description: this.descriptionRef.current.input.value,
          project_id: this.props.entry.project_id,
          task_id: '',
          billable: this.state.billable ? 1 : 0,
          start: moment(e.target.value, "HH:mm:ss").utc().format("YYYY-MM-DD HH:mm:ss"),
        }
        this.setState({...this.state, visible: false});
        this.changeTime()
        this.props.setData(method, url, data);
      } else {
        message.error("Invalid Time String");
      }
    }
  }

  render() {
    const menu = (
      <Menu onClick={(e) => {
        if(e.key != 0) {
          this.setState({...this.state, projectId: e.key},() => {this.handleMenuClick(e)})
        } else {
          if(this.props.timer_desc_required) {
            this.setState({...this.state, restrictModal: true});
          } else {
            this.setState({...this.state, projectId: e.key},() => {this.handleMenuClick(e)})
          }
        }
      }}>
      {this.props.projects}
      </Menu>
    );
    const timermenu = (
      <Menu>
        <Menu.Item key="0">
        <span onKeyPress={(e) => {this.changeTimeByEnter(e)}}>Start Time:   <TimePicker popupStyle={{display: this.state.visible ? '' : "none"}} placeholder="Set Time" onChange={this.changeTime} defaultOpenValue={moment('00:00', 'HH:mm')} format={"HH:mm"} disabledHours={this.getDisabledHours} disabledMinutes={this.getDisabledMinutes} /></span>
        </Menu.Item>
      </Menu>
    )
    let entry = this.props.entry;
    let selectedProject = null;
    if (entry && this.props.projectData) {
      selectedProject = this.props.projectData.find(p => p.id == entry.project_id);
    }
    return(
      <div>
      {this.props.loading ? <CircularProgress/> :
        <Auxiliary>
          <Modal
            title="No description provided"
            visible={this.state.modalVisible}
            onOk={this.onStart}
            onCancel={this.handleCancel}
            confirmLoading={this.state.iconLoading}
            footer={[
              <Button key="submit" loading={this.state.iconLoading} onClick={this.onStart}>
                Yes
              </Button>,
              <Button key="cancel" onClick={this.handleCancel}>
                No
              </Button>
            ]}
          >
            <p>Are you sure you want to start timer without any description?</p>
          </Modal>
          <Modal
            title="Not Allowed"
            visible={this.state.restrictModal}
            onCancel={this.handleRestrictCancel}
            footer={[
              <Button key="ok" onClick={this.handleRestrictCancel}>
                OK
              </Button>
            ]}
          >
            <p>You cannot start timer without description and project</p>
          </Modal>
          <Modal
            title="Future Time Entry"
            visible={this.state.timeModal}
            onCancel={this.handleTimeCancel}
            footer={[
              <Button key="ok" type="primary" onClick={this.handleTimeCancel}>
                OK
              </Button>
            ]}
          >
            <p>You cannot select future time</p>
          </Modal>
          <Card size="small">
            <Row>
              <Col lg={12} md={12} sm={12} xs={24}>
                <Input ref={this.descriptionRef} id="project-description" className="ant-input gx-border-0" type="text" placeholder="What are you working on?" defaultValue={entry ? entry.description : ''} onPressEnter={this.handleInputChange}/>
              </Col>
              <Col lg={12} md={12} sm={12} xs={24} style={{textAlign: "right"}}>
                <Dropdown overlay={menu} trigger={['click']}>
                  <span style={{ userSelect: 'none', fontSize: "16px", cursor: 'pointer', color: selectedProject ? "#f44336":"#5e5cdb" }}>{selectedProject ? selectedProject.name : <span><i className="icon icon-add-circle gx-pointer gx-fs-lg" title="Add Project" style={{position: "relative", top: "3px"}}/> Project</span>}</span>
                </Dropdown>
                <Divider type="vertical"/>
                <FaDollarSign size="1.5em" style={{color: this.state.billable ? "#5e5cdb" : "#bfbfbc", cursor: "pointer"}} onClick={()=>{this.onClick()}}/>

                <div style={{display: "inline"}}>
                  <Divider type="vertical"/>
                  <Dropdown overlay={timermenu} visible={this.state.visible} onVisibleChange={this.onVisibleChange} trigger={['click']}>
                    <span onKeyPress={(e) => {console.log(e)}}>
                      <Tracker start={this.props.entry} time={this.props.entry ? (moment.utc() - moment.utc(entry ? entry.start : null)) : 0}  spinning={this.props.spinning} iconLoading={this.props.iconLoading}/>
                    </span>
                  </Dropdown>
                </div>
                <Divider type="vertical"/>
                <Button style={{marginBottom: "0" , backgroundColor: this.props.entry ? "#f44336" : "#5e5cdb", color: "#fff"}} loading={this.state.iconLoading} onClick={this.checkInput}>{!this.props.entry ? "Start" : "Stop"}</Button>
              </Col>
            </Row>
          </Card>
        </Auxiliary>}
      </div>
      );
    }
  }

  const mapStateToProps = ({auth, company}) => {
    const {authUser} = auth;
    const {currentCompany, currentEmployee, timer_desc_required} = company;
    return {authUser, currentCompany, currentEmployee, timer_desc_required}
  };

  export default connect(mapStateToProps, {setCurrentTimer})(CurrentEntry);
