import React from "react";
import {Card, Col, Row, Spin} from "antd";
import Auxiliary from "util/Auxiliary";

const TimeoffStats = ({stats, loading}) => {
  return (
    <Auxiliary>
      <Card >
        <Spin spinning={loading}>
          {stats ?
            <Row type="flex" justify="space-around" align="middle">
              <Col className={'text-center'}> <h2>{stats.days_all ? stats.days_all : 0}</h2> <br/>TOTAL DAYS</Col>
              <Col className={'text-center'}> <h2 className={stats.days_available < 0 ? 'text-danger': ''}>{stats.days_available ? stats.days_available : 0}</h2> <br/>AVAILABLE DAYS</Col>
              <Col className={'text-center'}> <h2>{stats.days_taken ? stats.days_taken : 0}</h2> <br/>TAKEN DAYS</Col>
            </Row>
          : null }
        </Spin>
      </Card>
    </Auxiliary>
  );
};

export default TimeoffStats;
