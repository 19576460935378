import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
  SIGNIN_USER,
  SIGNOUT_USER,
  SIGNUP_USER,
  JOIN_USER,
  GET_CURRENT_USER,
  GET_ROLES,
  GET_USER_WORKSPACE_DATA,
} from "constants/ActionTypes";
import {
  showAuthMessage, showSignUpMessage, userSignInSuccess, userSignOutSuccess, getRolesSuccess, getUserWorkspaceDataSuccess,
  userSignUpSuccess, userJoinSuccess, getCurrentUserSuccess
} from "../../appRedux/actions/Auth";
import {API_URL_V1} from "../../constants";
import axios from 'axios';

const createUserWithEmailPasswordRequest = async (email, password, firstName, lastName, confirm_password, token) =>
  await  axios.post(`${API_URL_V1}/auth/register`, {
    first_name: firstName,
    last_name: lastName,
    email: email,
    password: password,
    password_confirmation: confirm_password,
    token: token
  })
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithEmailPasswordRequest = async (email, password) =>
  await  axios.post(`${API_URL_V1}/auth/login`, {
    email: email,
    password: password,
  })
    .then(authUser => authUser)
    .catch(error => error);

const signOutRequest = async (token) =>
  await  axios.post(`${API_URL_V1}/auth/logout`, {}, {
    headers: {
      'Authorization': 'Bearer '+ token
    }
  })
    .then(authUser => authUser)
    .catch(error => error);

const getAllUserRolesRequest = async () =>
  await axios.get(`${API_URL_V1}/data/roles`)
      .then(roleData => roleData)
      .catch(error => error);

const getCurrentUserWorkspaceDataRequest = async (authUser, workspace_id) =>
  await axios.get(`${API_URL_V1}/workspaces/${workspace_id}/me`, {
    headers: {
      'Authorization': 'Bearer '+ authUser,
    }
  })
  .then(workspaceData => workspaceData)
  .catch(error => error)

const joinUserWithTokenRequest = async (password, confirm, id, token) =>
  await axios.post(`${API_URL_V1}/auth/join/${id}`, {
    confirmation_token: token,
    password: password,
    password_confirmation: confirm,
  })
  .then(authUser => authUser)
  .catch(error => error);

const getCurrentUserRequest = async (token) =>
  await  axios.get(`${API_URL_V1}/me`, {
    headers: {
      'Authorization': 'Bearer '+ token,
      'WWW-Authenticate': 'Bearer'
    }
  })
    .then(authUser => authUser)
    .catch(error => Promise.reject(error));

function* createUserWithEmailPassword({payload}) {
  const {email, password, firstName, lastName, confirm_password, token} = payload;
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, email, password, firstName, lastName, confirm_password, token);
    if (signUpUser.message) {
      if(signUpUser.response.data.errors.email) {
        yield put(showAuthMessage(signUpUser.response.data.errors.email[0]));
      }
      else if(signUpUser.response.data.errors.password) {
        yield put(showAuthMessage(signUpUser.response.data.errors.password[0]));
      }
    } else {
      yield put(showSignUpMessage(signUpUser.data.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({payload}) {
  const {email, password} = payload;
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
    if (signInUser.message) {
      yield put(showAuthMessage('Incorrect Email or Password'));
    } else {
      localStorage.setItem('user_id', signInUser.data.token);
      yield put(userSignInSuccess(signInUser.data.token));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* getAllUserRoles() {
  const getRoles = yield call(getAllUserRolesRequest);
  try {
    if (getRoles.message) {
      yield put(showAuthMessage('Failed to get roles'));
    }else {
      localStorage.setItem('roles', getRoles.data.data);
      yield put(getRolesSuccess(getRoles.data.data));
    }
  } catch(error){}
}

function* getUserCurrentWorkspaceData({payload}) {
  const workspace_id = localStorage.getItem('id');
  const authUser = payload
  const getUserWorkspaceData = yield call(getCurrentUserWorkspaceDataRequest, authUser, workspace_id);
  try {
    if (getUserWorkspaceData.message) {
      //yield put(showAuthMessage('Failed to get roles'));
    }else {
      localStorage.setItem('workspaceData', getUserWorkspaceData.data.data);
      yield put(getUserWorkspaceDataSuccess(getUserWorkspaceData.data.data));
    }
  } catch(error){}
}

function* joinUserWithToken({payload}) {
  const {password, confirm} = payload;
  const url = new URL(window.location.href);
  const id = url.searchParams.get("id");
  const token = url.searchParams.get("token");
  try {
    const joinUserWithToken = yield call(joinUserWithTokenRequest, password, confirm, id, token);
    console.log(joinUserWithToken);
    if(joinUserWithToken.message) {
      yield put(showAuthMessage(joinUserWithToken.response.data.errors.password));
    } else {
      localStorage.setItem('user_id', joinUserWithToken.data.token);
      yield put(userJoinSuccess(joinUserWithToken.data.token));
    }
  } catch(error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut({payload}) {
  try {
    const signOutUser = yield call(signOutRequest, payload);
    if (signOutUser.data.success === true) {
      localStorage.removeItem('user_id');
      yield put(userSignOutSuccess(signOutUser));
    } else {
      yield put(showAuthMessage(signOutUser.message));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* getCurrentUserWithToken({payload}) {
  const token = payload;
  try {
    const currentUserResponse = yield call(getCurrentUserRequest, token);
    yield put(getCurrentUserSuccess(currentUserResponse.data.data));
  } catch(error) {
    console.log(error);
    if(error.request){
        yield put(userSignOutSuccess())
    } else {
      yield put(showAuthMessage(error));
    }
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export function* joinUser() {
  yield takeEvery(JOIN_USER, joinUserWithToken);
}

export function* getCurrentUser() {
  yield takeEvery(GET_CURRENT_USER, getCurrentUserWithToken);
}

export function* getAllRoles() {
  yield takeEvery(GET_ROLES, getAllUserRoles);
}

export function* getCurrentUserWorkspaceData() {
  yield takeEvery(GET_USER_WORKSPACE_DATA, getUserCurrentWorkspaceData);
}

export default function* rootSaga() {
  yield all([fork(signInUser),
    fork(getAllRoles),
    fork(getCurrentUserWorkspaceData),
    fork(createUserAccount),
    fork(joinUser),
    fork(signOutUser),
    fork(getCurrentUser)
    ]);
}
