import React, {Component} from "react";

class Customizer extends Component {
  constructor(props) {
    super(props);
    let initialValue = {
      '@primary-color': '#5e5cdb',
      '@secondary-color': '#fa8c16',
      '@text-color': '#545454',
      '@heading-color': '#535353',
      '@nav-dark-bg': '#003366',
      '@nav-dark-text-color': '#038fdd',
      '@header-text-color': '#262626',
      '@layout-header-background': '#5e5cdb',
      '@layout-footer-background': '#fffffd',
      '@body-background': '#f5f5f5',
      '@hor-nav-text-color': '#fffffd'
    };
    let vars = {};

    try {
      vars = Object.assign({}, initialValue);
    } finally {
      this.state = {vars, initialValue};
      window.less
        .modifyVars(vars)
        .then(() => {
        })
        .catch(error => error);
    }
  }

  render() {
    return (
      null
    );
  }
}

export default Customizer;
