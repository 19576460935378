import React, {Component} from "react";
import {connect} from "react-redux";
import {Modal} from "antd";
import BigCalendar from "react-big-calendar";
import Auxiliary from "../../../../util/Auxiliary";
import CircularProgress from "components/CircularProgress/index";
import axios from "axios";
import moment from "moment";
import {API_URL_V1} from "../../../../constants";

class ProjectCalendar extends Component {
  state = {
    defaultDate: new Date(),
  }

  doubleClick = (event, e) => {
    this.info(event);
  }

  info = (event) => {
    Modal.info({
      title: "Title: " + event.title,
      centered: true,
      content: (
        <div>
          <b>Description:</b> {event.description ? event.description : "No Description"}<br/>
          <b>Status:</b> {event.status}
        </div>
      ),
      onOk() {
      },
    });
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    let color='';
    this.props.statusType.forEach((status, index) => {
      if(status.id === event.status_id) {
        color=status.color;
      }
    })
    let style = {
        borderRadius: '8px',
        opacity: 0.8,
        color: 'white',
        border: '0px',
        display: 'block',
        minHeight: '16px',
        backgroundColor: color
    };
    return {
        style: style
    };
  }

  render() {
    return (
      <div>
        <Auxiliary>
            <div className="gx-main-content">
              <div className="gx-rbc-calendar">
              {this.props.loading ?
                <CircularProgress /> :
                <BigCalendar
                  popup
                  events={this.props.events}
                  views={['month', 'week', 'day']}
                  onDoubleClickEvent={this.doubleClick}
                  defaultDate={this.state.defaultDate}
                  eventPropGetter={(this.eventStyleGetter)}
                  showMultiDayTimes={true}
                  />
                }
              </div>
            </div>
        </Auxiliary>
      </div>
    );
  }
}

const mapStateToProps = ({company, auth}) => {
  const {authUser} = auth;
  const {currentCompany} = company;
  return {currentCompany, authUser}
};

export default connect(mapStateToProps)(ProjectCalendar);
