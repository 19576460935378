import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Modal} from "antd";
import WorkCalendarFilter from "./WorkCalendarFilter";
import BigCalendar from "react-big-calendar";
import CircularProgress from "components/CircularProgress/index";
import Auxiliary from "../../../util/Auxiliary";
import {API_URL_V1} from "../../../constants";
import axios from "axios";
import moment from "moment";

BigCalendar.setLocalizer(
  BigCalendar.momentLocalizer(moment)
);

class WorkCalendarReport extends Component {
  state = {
    events: [],
    start_date: '',
    end_date: '',
    employee_id: '',
    loading: true,
    defaultDate: new Date(),
    defaultView: 'month',
    min: ''
  }

  getEvents = () => {
    this.setState({...this.state, loading: true});
    const {authUser, currentCompany} = this.props;
    axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/reports/tracker/details`, {
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        employee_ids: this.state.employee_id ? this.state.employee_id : this.props.currentEmployee.id,
        per_page: "1000",
      }, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
    })
    .then(reportData => {
      let events = [];
      reportData.data.data.forEach((report, index) => {
        events.push({
          ...report,
          "title": report.description ? report.description : "No Description",
          "start": new Date(moment.utc(report.start).local()),
          "end": new Date(moment.utc(report.end).local()),
        })
      })
      this.setState({...this.state, events: events, loading: false});
    })
    .catch(error => error)
  }

  componentDidMount() {
    let min = this.state.defaultDate;
    min.setHours(8, 0, 0);
    this.setState({...this.state, min: min, start_date: moment().startOf('month').local().format("YYYY-MM-DD"), end_date: moment().endOf('month').local().format("YYYY-MM-DD")},() => {this.getEvents()});
  }

  doubleClick = (event, e) => {
    this.info(event);
  }

  info = (event) => {
    let project = event.project_id ? this.props.projects.filter((project, index) => project.id === event.project_id) : '';
    Modal.info({
      title: "Project: " + (project ? project[0].name : "No Project"),
      centered: true,
      content: (
        <div>
          Description: {event.description ? event.description : "No Description"}
        </div>
      ),
      onOk() {
      },
    });
  }

  onNavigate = (event, e) => {
    let momentDate = moment(event);
    let startDate = (e === 'month' || e === 'week') ? momentDate.startOf(e).format('YYYY-MM-DD') : momentDate.format('YYYY-MM-DD');
    let endDate = (e === 'month' || e === 'week') ? momentDate.endOf(e).format('YYYY-MM-DD') : momentDate.format('YYYY-MM-DD');
    this.setState({...this.state, defaultDate: event, defaultView: e, start_date: startDate, end_date: endDate}, () =>{this.getEvents()});
  }

  onView = (e) => {
    let momentDate = moment(this.state.defaultDate);
    let startDate = (e === 'month' || e === 'week') ? momentDate.startOf(e).format('YYYY-MM-DD') : momentDate.format('YYYY-MM-DD');
    let endDate = (e === 'month' || e === 'week') ? momentDate.endOf(e).format('YYYY-MM-DD') : momentDate.format('YYYY-MM-DD');
    this.setState({...this.state, defaultView: e, start_date: startDate, end_date: endDate},() => {this.getEvents()});
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    let style = {
        borderRadius: '8px',
        opacity: 0.8,
        color: 'white',
        border: '0px',
        display: (event.type!=="weekend") ? 'block' : 'none',
        minHeight: '16px'
    };
    return {
        style: style
    };
  }

  filterWorkCalendar = (employee_id) => {
    console.log(employee_id);
    this.setState({...this.state, employee_id: employee_id}, () => {this.getEvents()})
  }

  render() {
    return (
      <Auxiliary>
        <WorkCalendarFilter employees={this.props.employees} currentEmployee={this.props.currentEmployee} filterWorkCalendar={this.filterWorkCalendar}/>
        <div className="gx-main-content">
          <div className="gx-rbc-calendar">
          {this.state.loading ?
            <CircularProgress /> :
            <BigCalendar
              popup
              events={this.state.events}
              onNavigate={this.onNavigate}
              onView={this.onView}
              views={['month', 'week', 'day']}
              defaultView={this.state.defaultView}
              onDoubleClickEvent={this.doubleClick}
              defaultDate={this.state.defaultDate}
              eventPropGetter={(this.eventStyleGetter)}
              showMultiDayTimes={true}
              min={this.state.min}
              //max={max}
              steps={30}/>
            }
          </div>
        </div>
      </Auxiliary>
    )
  }
};

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, currentCompany, currentEmployee}
};

export default connect(mapStateToProps)(WorkCalendarReport);
