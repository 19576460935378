import React, {Component} from "react";
import {Layout, Popover, message} from "antd";
import {Link} from "react-router-dom";
import { withRouter } from 'react-router';

import CustomScrollbars from "util/CustomScrollbars";
import {setJoyride, resetJoyride, setEmployeeOptions} from "../../appRedux/actions/Company";
import {toggleCollapsedSideNav, switchLanguage} from "../../appRedux/actions/Setting";
import languageData from "./languageData";
import SearchBox from "components/SearchBox";
import UserInfo from "components/UserInfo";
import Auxiliary from "util/Auxiliary";
import Cookies from "universal-cookie";
import Breadcrumbs from "../../components/Breadcrumbs/index";
import Timer from "components/Timer/index";
import {NAV_STYLE_DRAWER, NAV_STYLE_FIXED, NAV_STYLE_MINI_SIDEBAR, TAB_SIZE} from "../../constants/ThemeSetting";
import {connect} from "react-redux";
import {FaQuestionCircle} from "react-icons/fa";
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import {API_URL_V1} from "../../constants";
import axios from 'axios';

const {Header} = Layout;

class Topbar extends Component {

  state = {
    searchText: '',
    visible: false,
    steps: [
      {
        target: '.get-help',
        content: 'You can get help by clicking here',
        disableBeacon: true
      }
    ]
  };


  updateSearchChatUser = (evt) => {
    this.setState({
      searchText: evt.target.value,
    });
  };

  languageMenus(lang) {
    languageData.map(language => {
      if(lang == language.languageId ) {
        lang = language;
      }
      this.props.switchLanguage(lang);
    })
  };

  componentDidMount () {
    const cookies = new Cookies();
    const lang = cookies.get('language') ? cookies.get('language') : 'english';
    this.languageMenus(lang);
  }

  hide = () => {
    this.setState({
      visible: false,
    });
  }

  handleVisibleChange = (visible) => {
    this.setState({ visible });
  }

  handleJoyrideCallback = data => {
    if(data.type === 'tour:end') {
      this.props.setEmployeeOptions(this.props.currentEmployee.id);
    }
  }

  render() {
    const {currentEmployee} = this.props;
    let canManage = currentEmployee && currentEmployee.role && (currentEmployee.role.permissions.includes("edit_company") || currentEmployee.role.permissions.includes("manage_company"));
    let optionValue = false;
    if(this.props.employeeOptions && this.props.employeeOptions.length === 0) {
      optionValue = true;
    }
    this.props.employeeOptions && this.props.employeeOptions.map((option, index) => {
      if(option.name === "is_new" && option.value === '1') {
        optionValue = true;
      }
    })
    const {locale, width, navCollapsed, navStyle} = this.props;
    const helpMenuOptions = (
      <ul className="gx-user-popover">
        <li onClick={() => {if(this.props.joyride) {this.props.resetJoyride();} setTimeout(() => {this.props.setJoyride('.employees');}, 100); this.hide()}}>Adding Employee</li>
        <li onClick={() => {if(this.props.joyride) {this.props.resetJoyride();} setTimeout(() => {this.props.setJoyride('.projects');}, 100); this.hide()}}>Adding Project</li>
        <li onClick={() => {if(this.props.joyride) {this.props.resetJoyride();} setTimeout(() => {this.props.setJoyride('.clients');}, 100); this.hide()}}>Adding Client</li>
        <li onClick={() => {if(this.props.joyride) {this.props.resetJoyride();} setTimeout(() => {this.props.setJoyride('.events');}, 100); this.hide()}}>Adding Event</li>
      </ul>
    );
    return (
      <Auxiliary>
        {canManage && optionValue ?
        <Joyride
          steps={this.state.steps}
          callback={this.handleJoyrideCallback}
          spotlightClicks={true}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        /> : null}
        <Header>
          {navStyle === NAV_STYLE_DRAWER || ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) && width < TAB_SIZE) ?
            <div className="gx-linebar gx-mr-3">
              <i className="gx-icon-btn icon icon-menu"
                 onClick={() => {
                   this.props.toggleCollapsedSideNav(!navCollapsed);
                 }}
              />
            </div> : null}
          <Link to="/dashboard" className="gx-d-block gx-d-lg-none gx-pointer">
            <img alt="" src={require("assets/images/w-logo.png")}/></Link>

          <ul className="gx-header-notifications gx-ml-auto">
            <Auxiliary>
            {this.props.currentEmployee ? <li className="gx-mr-4"><Timer currentEmployee={this.props.currentEmployee} currentCompany={this.props.currentCompany} authUser={this.props.authUser}/></li> : null}
            {canManage ?
            <li className="gx-link gx-mr-4 get-help" style={{verticalAlign: ''}}>
              <Popover placement="bottomRight" content={helpMenuOptions} trigger="click" visible={this.state.visible} onVisibleChange={this.handleVisibleChange}>
                <FaQuestionCircle style={{color: '#fff', fontSize: "35px", verticalAlign: "middle"}}/>
              </Popover>
            </li> : null}
            <li className="gx-user-nav"><UserInfo/></li>
            </Auxiliary>
          </ul>
        </Header>
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({auth, settings, company}) => {
  const {authUser} = auth;
  const {locale, navStyle, navCollapsed, width} = settings;
  const {currentCompany, currentEmployee, joyride, employeeOptions} = company;
  return {locale, navStyle, navCollapsed, width, authUser, currentCompany, currentEmployee, joyride, employeeOptions}
};

export default withRouter(connect(mapStateToProps, {toggleCollapsedSideNav, switchLanguage, setJoyride, resetJoyride, setEmployeeOptions})(Topbar));
