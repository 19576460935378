import React, {Component} from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import {Button, Card, Table, Divider, message, Row, Col, Select, Dropdown, Menu, Tag, Modal} from "antd";
import Auxiliary from "../../../../util/Auxiliary";
import CircularProgress from "components/CircularProgress/index";
import FormOffices from "./FormOffices";
import {API_URL_V1} from "../../../../constants";
import axios from "axios";
import { Link } from 'react-router-dom'
import Widget from "@/components/Widget/index";

class OfficeWidget extends Component {
  state = {
    deleteVisible: false,
  }

  render() {
    return (
      <Col xs={24} lg={12}>
      <Modal
        visible={this.state.deleteVisible}
        title="Delete Office?"
        onOk={(e) => this.props.deleteOffice(this.props.office, e)}
        onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
        confirmLoading={this.props.deleteLoading}
        footer={[
          <Button key="submit" loading={this.props.deleteLoading} onClick={(e) => this.props.deleteOffice(this.props.office, e)}>
            OK
          </Button>,
          <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
            Cancel
          </Button>
        ]}
      >
        <p>Are you Sure you want to delete this Office?</p>
      </Modal>
      <Widget
          styleName="gx-card-full gx-text-center ant-card-bordered" extra={ this.props.showIcons ?
        <ul className="gx-list-inline gx-ml-auto gx-mb-0 gx-text-grey">
          <li><Dropdown
                  overlay={<Menu>
                              <Menu.Item key="0">
                                <a onClick={(e) => {this.props.edit(this.props.office, e)}}>Edit</a>
                              </Menu.Item>
                              {this.props.office.main ? '' :
                              <Menu.Item key="1">
                                  <a onClick={(e) => {this.setState({...this.state, deleteVisible: true})}}>Delete</a>
                              </Menu.Item>}
                            </Menu>}

              trigger={['click']}>
            <span className="gx-link ant-dropdown-link gx-text-grey gx-mr-2">
              <i className="icon icon-setting"/>
            </span>
          </Dropdown></li>
        </ul> : null
      }>
        <div className="gx-pt-4 gx-px-3">
          <h3 className="gx-mb-3">{this.props.office.name}</h3>
          <div className="gx-mb-2">
            Employees: {this.props.office.employees_count}
          </div>

          <div>
            {this.props.office.address_line_1} <br/>
            {this.props.office.address_line_2} <br/>
            {this.props.office.city+","} {this.props.office.state} <br/>
            {this.props.office.country+","} {this.props.office.postal_code} <br/>
            Phone: {this.props.office.phone_number}
          </div>
        </div>

        <Link to={`/offices/${this.props.office.id}/holidays`}><Button type="primary"
                className="gx-mt-sm-4 gx-fs-sm gx-btn-block gx-mb-0 gx-text-uppercase gx-border-radius-top-left-0 gx-border-radius-top-right-0"
                size="large" htmlType="submit" block>
          Holidays
        </Button></Link>

      </Widget>
      </Col>
    );
  }
}

let data = [], countriesOptions=[];
let workspace_id, disabled;
const Option = Select.Option;

class Offices extends Component {
  constructor(props){
    super(props)
    this.state = {
      task: '',
      loading: true,
      formData: '',
      officeId: '',
      countryData: '',
      visible: false,
      confirmLoading: false,
      deleteLoading: false,
    };
  }

  componentDidMount() {
    workspace_id = localStorage.getItem('id');
    data = [];
    const {authUser} = this.props;
    axios.all([
    axios.get(`${API_URL_V1}/workspaces/${workspace_id}/locations`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    }),
    axios.get(`${API_URL_V1}/data/countries`),
    ])
    .then(axios.spread((officesData, countryData) => {
      for (let i = 0; i < (officesData.data.data).length; i++) {
        data.push(officesData.data.data[i])
      }
      for(let i = 0; i< (countryData.data.data).length; i++) {
        countriesOptions.push(<Option value={(countryData.data.data[i].country_code).toString()}>{countryData.data.data[i].name}</Option>);
      }
      this.setState({...this.state, countryData: countriesOptions, loading: false})
    }))
    .catch(error => error);
  }

  onClick = () => {
    this.setState({...this.state, task: "Add Office", visible: true});
  }

  submit = (values, id) => {
    workspace_id = localStorage.getItem('id');
    this.setState({...this.state, confirmLoading: true});
    const {authUser} = this.props;
    if(!id) {
      axios.post(`${API_URL_V1}/workspaces/${workspace_id}/locations`, {
        name: values.officeName,
        main: values.main,
        address_line_1: values.address1,
        address_line_2: values.address2,
        phone_number: values.phone_number,
        city: values.city,
        state: values.state,
        postal_code: values.zip,
        country: values.country,
        },
        {
          headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(officeData => {
        message.success("Office Added Successfully");
        this.setState({...this.state, visible: !this.state.visible, confirmLoading: false});
        this.onOk(officeData.data);
      })
      .catch(error => {
        message.error("Failed to Add Office");
        this.setState({...this.state, confirmLoading: false});
      });
    } else {
      axios.put(`${API_URL_V1}/workspaces/${workspace_id}/locations/${id}`, {
        name: values.officeName,
        main: values.main,
        address_line_1: values.address1,
        address_line_2: values.address2,
        phone_number: values.phone_number,
        city: values.city,
        state: values.state,
        postal_code: values.zip,
        country: values.country,
        },
        {
          headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(officeData => {
        message.success("Office Editted Successfully");
        this.setState({...this.state, visible: !this.state.visible, confirmLoading: false});
        this.onOk(officeData.data);
      })
      .catch(error => {
        message.error("Failed to Edit");
        this.setState({...this.state, confirmLoading: false});
      });
    }
  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser} = this.props;
    axios.delete(`${API_URL_V1}/workspaces/${workspace_id}/locations/${record.id}`, {
      params: {
        workspace_id: workspace_id,
        location_id: record.id,
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(locationData => {
      data = [];
      axios.get(`${API_URL_V1}/workspaces/${workspace_id}/locations`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(locationsData => {
        data = locationsData.data.data;
        message.success("Office Deleted Successfully");
        this.setState({...this.state, loading: false, deleteLoading: false});
      })
      .catch(error => error);
    })
    .catch(error => {
      message.error(error.response.data.message);
    })
  }

  onOk =(value) => {
    if(this.state.task == "Add Office") {
      data.push(value.data)
    } else {
      for(let i =0; i< data.length; i++) {
        if(data[i].id == value.data.id) {
          data[i] = value.data;
        }
      }
    }
    this.setState({...this.state, task: ''});
  }

  onCancel = () => {
    this.setState({...this.state, task: '', visible: !this.state.visible, formData: ''});
  }

  edit = (record, e) => {
    e.preventDefault();
    const {authUser} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${workspace_id}/locations/${record.id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(locationsData => {
      this.setState({...this.state, task: 'Edit Office', formData: locationsData.data, officeId: record.id, visible: true});
    })
    .catch(error => error);
  }

  changeTab = (record, e, key) => {
    this.props.history.push({
      pathname: "/timeoff",
      state: {activeTab: key, loc_id: record.id},
    });
  }



  render() {
    disabled = (this.props.currentEmployee && this.props.currentEmployee.role) ? this.props.currentEmployee.role.permissions.includes("edit_company") : '';
    return(
      <div>
      <Row type="flex" justify="center" className="gx-mb-5">
        <Col xs={18} lg={9} className="text-center">
        <div className="gx-mt-2">
          <i className={"icon icon-company gx-fs-xlxl gx-text-orange"}/>
        </div>
        <div className="gx-mt-2">
          Manage here all {this.props.currentCompany.name}'s offices, including holidays.
        </div>
        </Col>
      </Row>
      <div>
      {this.state.visible ?
        <FormOffices title={this.state.task} visible={this.state.visible} onOk={this.submit} onCancel={this.onCancel} value={this.state.formData.data} id={this.state.officeId} countries={this.state.countryData} confirmLoading={this.state.confirmLoading}/> : null}
      {this.state.loading ?
      <CircularProgress/> :
      <div>
      <Auxiliary>
        <Row type="flex" justify="center">
        <div className="gx-mb-5">
          <Button style={{float: "right"}} disabled={!disabled} onClick={this.onClick}>Add Office</Button>
        </div>
      </Row>
        <Row type="flex" justify="center" gutter={16}>
          {data.map((office, index) => {
            return <OfficeWidget key={index} office={office} edit={this.edit} deleteOffice={this.deleteRecord} showIcons={disabled} deleteLoading={this.state.deleteLoading}/>
          })}
        </Row>
      </Auxiliary>
      </div>}
      </div>
      </div>
    );
  };
}
const mapStateToProps = ({auth, company}) => {
  const {authUser, userWorkspaceData} = auth;
  const {currentEmployee, currentCompany} = company;
  return {authUser, currentEmployee, currentCompany}
};

export default withRouter(connect(mapStateToProps)(Offices));
