import React, {Component} from "react";
import {Modal, Form, Input, DatePicker, TimePicker, Select, Button, Col} from 'antd';
import moment from 'moment';

const FormItem = Form.Item;
const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends Component {
    render() {
      const formItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 8},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 16},
        },
      };
      const { getFieldDecorator } = this.props.form;
      return (
        <Modal
          visible={this.props.visible}
          title={this.props.title}
          onCancel={this.props.onCancel}
          onOk={this.props.onCreate}
          footer={[
            <Button key="submit" loading={this.props.confirmLoading} onClick={this.props.onCreate}>
              {this.props.title}
            </Button>,
            <Button key="cancel" onClick={this.props.onCancel}>
              Cancel
            </Button>
          ]}
        >
          <Form >
            <Form.Item
            {...formItemLayout}
            label="In Time"
            required={true}
            >
              <div className="ant-row gx-form-row0">
                <Col xs={24} sm={12}>
                  <FormItem>
                    {getFieldDecorator('in_date', {
                      initialValue: this.props.value.in_at ? moment(moment.utc(this.props.value.in_at).local(), 'YYYY-MM-DD') : moment(moment.utc(this.props.value.date).local(), 'YYYY-MM-DD'),
                      rules: [{ required: true, message: 'Date Required!' }],
                    })(
                      <DatePicker className="gx-w-100" placeholder= "In Date" disabled />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={12}>
                  <FormItem>
                    {getFieldDecorator('in_at', {
                      initialValue: this.props.value.in_at ? moment(moment.utc(this.props.value.in_at).local(), 'HH:mm') : moment(moment.utc(this.props.value.date).local(), 'HH:mm'),
                      rules: [{ required: true, message: 'Time Required!' }],
                    })(
                      <TimePicker className="gx-w-100" placeholder= "In Time" format={'HH:mm'}/>
                    )}
                  </FormItem>
                </Col>
              </div>
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Out Time"
            required={true}
            >
              <div className="ant-row gx-form-row0">
                <Col xs={24} sm={12}>
                  <FormItem>
                    {getFieldDecorator('out_date', {
                      initialValue: this.props.value.out_at ? moment(moment.utc(this.props.value.out_at).local(), 'YYYY-MM-DD') : moment(moment.utc(this.props.value.date).local(), 'YYYY-MM-DD'),
                      rules: [{ required: true, message: 'Date Required!' }],
                    })(
                      <DatePicker className="gx-w-100" placeholder= "Out Date" />
                    )}
                  </FormItem>
                </Col>
                <Col xs={24} sm={12}>
                  <FormItem>
                    {getFieldDecorator('out_at', {
                      initialValue: this.props.value.out_at ? moment(moment.utc(this.props.value.out_at).local(), 'HH:mm') : moment(moment.utc(this.props.value.date).local(), 'HH:mm'),
                      rules: [{ required: true, message: 'Time Required!' }],
                    })(
                      <TimePicker className="gx-w-100" placeholder= "Out Time" format={'HH:mm'}/>
                    )}
                  </FormItem>
                </Col>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

class AttendanceModal extends React.Component {

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onOk(values, this.props.value.id);
    });
  }

  render() {
    return (
      <div>

        <CollectionCreateForm
          title={this.props.title}
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          onCreate={this.handleCreate}
          value={this.props.value}
          offices={this.props.offices}
          loading={this.props.loading}
        />

      </div>
    );
  }
}

export default AttendanceModal;
