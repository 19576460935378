import React, {Component} from "react";
import PropertiesCard from "../../../components/dashboard/Listing/PropertiesCard";
import request from '../../../requests';
import {API_URL_V1} from '../../../constants';
import LeaveList from "../../../components/dashboard/CRM/LeaveList";

class LeaveRequests extends Component {
    state = {
      loading: false,
      requests: [],
    };

    componentDidMount(){
      this.getLeaveRequests();
    }

    getLeaveRequests = () => {
      this.setState({...this.state, loading: true})
      let id = this.props.employee.id;
      request.get(`${API_URL_V1}/employees/${id}/leave-requests`)
      .then(response => {
        console.log(response.data);
        this.setState({...this.state, requests: response.data.data, loading: false})
      })
      .catch(error => {
        console.log(error);
        this.setState({...this.state, loading: false})
      })
    }

    render() {
      return (
        <LeaveList data={this.state.requests} refresh={this.getLeaveRequests} loader={this.state.loading}/>
      );
    }
  }
export default LeaveRequests;
