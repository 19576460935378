import React, {Component} from "react";
import {Dropdown, Divider, Menu, Icon, DatePicker, Badge} from "antd";
import Auxiliary from "../../../util/Auxiliary";
import moment from "moment";

let projectOptions=[], clients=[], employees=[];
const {RangePicker, WeekPicker} = DatePicker;

class ReportFilters extends Component {
  state = {
    projectVisible: false,
    clientVisible: false,
    employeeVisible: false,
    dateRange: [moment.utc().startOf('isoWeek'), moment.utc().endOf('isoWeek')],
    week: moment(),
    filterData: {
      project: [],
      clients: [],
      employees: [],
      date: '',
    },
  }

  componentDidMount () {
    projectOptions=[]; clients=[]; employees=[];
    for(let i = 0; i < (this.props.projects).length; i++) {
      projectOptions.push(<Menu.Item key={(this.props.projects[i].id).toString()}>{this.props.projects[i].name}</Menu.Item>);
    }
    for(let i = 0; i < (this.props.clients).length; i++) {
      clients.push(<Menu.Item key={(this.props.clients[i].id).toString()}>{this.props.clients[i].name}</Menu.Item>);
    }
    for(let i = 0; i < (this.props.employees).length; i++) {
      employees.push(<Menu.Item key={(this.props.employees[i].id).toString()}>{this.props.employees[i].first_name} {this.props.employees[i].last_name}</Menu.Item>);
    }
  }

  handleEmployeeVisibleChange = flag => {
    this.setState({ employeeVisible: flag });
    if(flag === false) {
      if(this.props.page === "WeeklyReport") {
        this.props.filterWeekReport(this.state.filterData)
      } else {
        this.props.filterData(this.state.filterData)
      }
    }
  }

  handleProjectVisibleChange = flag => {
    this.setState({ projectVisible: flag });
    if(flag === false) {
      if(this.props.page === "WeeklyReport") {
        this.props.filterWeekReport(this.state.filterData)
      } else {
        this.props.filterData(this.state.filterData)
      }
    }
  }

  handleClientVisibleChange = flag => {
    this.setState({ clientVisible: flag });
    if(flag === false) {
      if(this.props.page === "WeeklyReport") {
        this.props.filterWeekReport(this.state.filterData)
      } else {
        this.props.filterData(this.state.filterData)
      }
    }
  }
  onEmployeeSelect = (e) => {
    let filterData = this.state.filterData;
    filterData.employees.push(e.key);
    this.setState({...this.state, filterData: filterData});
  }

  onEmployeeDeselect = (e) => {
    let filterData = this.state.filterData;
    filterData.employees.forEach((project, index) => {
      if(project === e.key) {
        filterData.employees.splice(index, 1);
      }
    })
    this.setState({...this.state, filterData: filterData});
  }

  onProjectSelect = (e) => {
    let filterData = this.state.filterData;
    filterData.project.push(e.key);
    this.setState({...this.state, filterData: filterData});
  }

  onProjectDeselect = (e) => {
    let filterData = this.state.filterData;
    filterData.project.forEach((project, index) => {
      if(project === e.key) {
        filterData.project.splice(index, 1);
      }
    })
    this.setState({...this.state, filterData: filterData});
  }

  onClientSelect = (e) => {
    let filterData = this.state.filterData;
    filterData.clients.push(e.key);
    this.setState({...this.state, filterData: filterData});
  }

  onClientDeselect = (e) => {
    let filterData = this.state.filterData;
    filterData.clients.forEach((client, index) => {
      if(client === e.key) {
        filterData.clients.splice(index, 1);
      }
    })
    this.setState({...this.state, filterData: filterData});
  }

  dateChange = (date, dateString) => {
    let filterData = this.state.filterData;
    filterData.date = this.props.page==="WeeklyReport" ? date.format("YYYY-MM-DD") : dateString;
    this.setState({...this.state, filterData: filterData, dateRange: date, week: moment(date)},
      () => {
        if(this.props.page === "WeeklyReport") {
          this.props.filterWeekReport(this.state.filterData)
        } else {
          this.props.filterData(this.state.filterData);
        }
      })
  }

  render() {
    const projectMenu = (
      <Menu onSelect={this.onProjectSelect} multiple selectable onDeselect={this.onProjectDeselect}>
        {projectOptions}
      </Menu>
    );
    const clientMenu = (
      <Menu onSelect={this.onClientSelect} multiple selectable onDeselect={this.onClientDeselect}>
        {clients}
      </Menu>
    );
    const employeeMenu = (
      <Menu onSelect={this.onEmployeeSelect} multiple selectable onDeselect={this.onEmployeeDeselect}>
        {employees}
      </Menu>
    );

    return (
      <Auxiliary>
        <span style={{verticalAlign: "super"}}>Filter By: </span>
        <Dropdown.Button
          overlay={employeeMenu}
          trigger={["click"]}
          icon={<Icon type="down" style={{verticalAlign: "middle"}}/>}
          onVisibleChange={this.handleEmployeeVisibleChange}
          visible={this.state.employeeVisible}
          size={'large'}
        >
          <Badge count={(this.state.filterData.employees).length} offset={[-50, -10]}>
            Users
          </Badge>
        </Dropdown.Button>
        <Dropdown.Button
          overlay={projectMenu}
          trigger={["click"]}
          icon={<Icon type="down" style={{verticalAlign: "middle"}}/>}
          onVisibleChange={this.handleProjectVisibleChange}
          visible={this.state.projectVisible}
          size={'large'}
        >
          <Badge count={(this.state.filterData.project).length} offset={[-60, -10]}>
            Projects
          </Badge>
        </Dropdown.Button>
        <Dropdown.Button
          overlay={clientMenu}
          trigger={["click"]}
          icon={<Icon type="down" style={{verticalAlign: "middle"}}/>}
          onVisibleChange={this.handleClientVisibleChange}
          visible={this.state.clientVisible}
        >
        <Badge count={(this.state.filterData.clients).length} offset={[-50, -10]}>
          Clients
        </Badge>
        </Dropdown.Button>
        {this.props.page === "WeeklyReport" ?
          <WeekPicker
            placeholder="Week Filter"
            value={this.state.week}
            style={{verticalAlign: "super"}}
            allowClear={false}
            suffixIcon={" "}
            onChange={this.dateChange}
          /> :
          <RangePicker
            placeholder="Date Filter"
            value={this.state.dateRange}
            style={{verticalAlign: "super"}}
            allowClear={false}
            suffixIcon={" "}
            onChange={this.dateChange}
          />
        }
      </Auxiliary>
    );
  }
}

export default ReportFilters;
