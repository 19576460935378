import React, {Component} from "react";
import {connect} from "react-redux";
import { Link } from 'react-router-dom'
import {Checkbox, Modal, Tag, Divider, Collapse, Icon, Button, Select, Spin, message} from "antd";
import AddProjectTaskListModal from "./AddProjectTaskListModal";
import AddTaskModal from "./AddTaskModal";
import Auxiliary from "../../../../util/Auxiliary";
import CircularProgress from "components/CircularProgress/index";
import {setCurrentTimer} from "appRedux/actions/Company";
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from "axios";
import moment from "moment";
import {API_URL_V1} from "../../../../constants";

const { Panel } = Collapse;
const { Option } = Select;
const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: grid,
    width: "100%"
});
const grid = 0;
const getItemStyle = (isDragging, draggableStyle) => ({
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? 'lightgrey' : 'white',
    ...draggableStyle
});

let statusOptions = [], final, defaultStatus, employeeOptions = [];

class ProjectTasksList extends Component {

  state={
    tasksList: [],
    visible: false,
    title: '',
    taskTitle: '',
    loading: true,
    taskModalVisible: false,
    editTaskData: '',
    taskProjectId: '',
    deleteVisible: false,
    employee_id: '',
    deleteTaskListVisible: false,
    listAddLoading: false,
    confirmLoading: false,
    record: '',
    taskListRecord: '',
    timerAlert: false,
    show: 'active',
    spinning: false,
  }

  getProjectTasksLists () {
    const {authUser, currentCompany} = this.props;
    const project_id = this.props.projectData.id;
    axios.all([
      axios.get(`${API_URL_V1}/projects/${project_id}/task-list`, {
        params: {
          order: "-sort_weight",
          employee_id: this.state.employee_id,
        },
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
    ])
    .then(axios.spread((taskListData) => {
      taskListData.data.data.forEach((tasks, index) => {
        tasks.tasks.sort((a, b) => {
          if(a.completed_at && b.completed_at ) {
            return new Date(b.completed_at) - new Date(a.completed_at);
          }
        })
      })
      this.setState({...this.state, tasksList: []},() => {this.setState({...this.state, tasksList: taskListData.data.data, loading: false, spinning: false})})

    }))
    .catch(error => error)
  }

  componentDidMount () {
    statusOptions =[]; employeeOptions=[];
    this.props.statusType.forEach((status, index) => {
      if(status.complete) {
        final=status.id;
      }
      if(status.default) {
        defaultStatus=status.id;
      }
      statusOptions.push(<Option value={(status.id).toString()}>{status.name}</Option>);
    })
    employeeOptions.push(<Option value="all">All</Option>)
    this.props.employees.forEach((employee, index) => {
      employeeOptions.push(<Option value={(employee.id).toString()}>{employee.first_name+" "+employee.last_name}</Option>)
    })
    this.getProjectTasksLists();
  }

  addNewList = () => {
    this.setState({...this.state, title: "Add New Task List", visible: true, editData: '' });
  }

  handleOk = (values, id) => {
    this.setState({...this.state, listAddLoading: true});
    const {authUser, currentCompany} = this.props;
    const project_id = this.props.projectData.id;
    if(!id) {
      axios.post(`${API_URL_V1}/projects/${project_id}/tasks`, {
        title: values.title,
        description: values.description,
        type: "list",
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(taskListData => {
        message.success("New Tasklist Added Successfully");
        this.getProjectTasksLists()
        this.setState({...this.state, visible: false, listAddLoading: false})
      })
      .catch(error => error)
    } else {
      axios.put(`${API_URL_V1}/projects/${project_id}/tasks/${id}`, {
        title: values.title,
        description: values.description,
        type: "list",
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(taskListData => {
        message.success("Tasklist Editted Successfully");
        this.getProjectTasksLists()
        this.setState({...this.state, visible: false, listAddLoading: false})
      })
      .catch(error => error)
    }
  }

  addNewTask = (listId) => {
    this.setState({...this.state, editTaskData: '', taskProjectId: listId, taskTitle: "Add Task", taskModalVisible: true});
  }

  editTask = (record, listId) => {
    this.setState({...this.state, editTaskData: record, taskProjectId: listId, taskTitle: "Edit Task", taskModalVisible: true});
  }

  handleTaskOk = (values, id) => {
    this.setState({...this.state, confirmLoading: true});
    const {authUser, currentCompany} = this.props;
    const project_id = this.props.projectData.id;
    if(!id) {
      axios.post(`${API_URL_V1}/projects/${project_id}/tasks`, {
        title: values.title,
        description: values.description,
        employee_id: values.employee_id,
        type: "task",
        start_date: values.start_date ? (values.start_date).format('YYYY-MM-DD') : '',
        due_date: values.due_date ? (values.due_date).format('YYYY-MM-DD') : '',
        task_list_id: this.state.taskProjectId,
        status_id: defaultStatus,
        ...(values.notify ? {notify: values.notify} : {}),
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(projectTasksData => {
        this.getProjectTasksLists();
        this.setState({...this.state, taskModalVisible: false, confirmLoading: false});
        message.success("Task Added Successfully")
      })
      .catch(error => {
        message.error("Failed to Add Task");
        this.setState({...this.state, confirmLoading: false});
      })
    } else {
      axios.put(`${API_URL_V1}/projects/${project_id}/tasks/${id}`, {
        title: values.title,
        description: values.description,
        employee_id: values.employee_id,
        type: "task",
        start_date: values.start_date ? (values.start_date).format('YYYY-MM-DD') : '',
        due_date: values.due_date ? (values.due_date).format('YYYY-MM-DD') : '',
        status_id: values.status,
        ...(values.notify ? {notify: values.notify} : {}),
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(projectTasksData => {
        this.getProjectTasksLists();
        this.setState({...this.state, taskModalVisible: false, confirmLoading: false});
        message.success("Task Editted Successfully")
      })
      .catch(error => {
        message.error("Failed to Edit Task");
        this.setState({...this.state, confirmLoading: false});
      })
    }
  }

  deleteTask = (record) => {
    this.setState({...this.state, deleteLoading: true});
    const {authUser} = this.props;
    const project_id = this.props.projectData.id;
    axios.delete(`${API_URL_V1}/projects/${project_id}/tasks/${record.id}`, {
      params: {
        task_id: record.id,
      },

      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(projectData => {
      this.getProjectTasksLists();
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      message.success("Task Deleted Successfully");
    })
    .catch(error => error)
  }

  onChange = (record, e) => {
    const {authUser} = this.props;
    const project_id = this.props.projectData.id;
    axios.put(`${API_URL_V1}/projects/${project_id}/tasks/${record.id}`, {
      status_id: (e.target.checked === true) ? final : defaultStatus,
    },
    {
      headers: {
      'Authorization': 'Bearer '+authUser
    }
    })
    .then(projectTasksData => {
      let tasksList = this.state.tasksList;
      tasksList.map((tasks, index) => {
        tasks.tasks.forEach((task, i) => {
          if(task.id === projectTasksData.data.data.id) {
            task.status_id = projectTasksData.data.data.status_id;
          }
        })
      })
      message.success((e.target.checked === true) ? "Task Marked as Completed" : "Task Marked as ToDo");
      this.setState({...this.state, tasksList: []},() => {this.setState({...this.state, tasksList: tasksList})});
    })
    .catch(error => error)
  }

  editTaskList = (tasks) => {
    this.setState({...this.state, title: "Edit Task List", editData: tasks, visible: true});
  }

  deleteTaskList = (tasks) => {
    const {authUser, currentCompany} = this.props;
    const project_id = this.props.projectData.id;
    this.setState({...this.state, deleteLoading: true})
    axios.delete(`${API_URL_V1}/projects/${project_id}/tasks/${tasks.id}`, {
      params: {
        task_id: tasks.id,
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(taskListData => {
      message.success("Tasklist deleted Successfully");
      this.getProjectTasksLists()
      this.setState({...this.state, deleteTaskListVisible: false, deleteLoading: false})
    })
    .catch(error => error)
  }

  playTimer = (task) => {
    if(task.employee_id === this.props.currentEmployee.id) {
      const {authUser, currentCompany} = this.props;
      const inputVal = task.title;
      let url = `${API_URL_V1}/workspaces/${currentCompany.id}/time_entries`;
      let method = 'post';
      let data = {
        description: inputVal,
        project_id: task.project_id,
        task_id: '',
        billable: this.state.billable ? 1 : 0,
        start: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
      };
      axios({method: method, url: url, data: data,
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(timeData => {
        this.props.setCurrentTimer(timeData.data.data);
        message.success("Timer started");
      })
      .catch(error => error)
    } else {
      this.setState({...this.state, timerAlert: true})
    }
  }

  onDragEnd = (result) => {
    console.log(result);
    const {source, destination} = result;
    if(source.droppableId !== destination.droppableId) {
      const {authUser} = this.props;
      const project_id = this.props.projectData.id;
      axios.put(`${API_URL_V1}/projects/${project_id}/tasks/${result.draggableId}`, {
        task_list_id: destination.droppableId,
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(projectTasksData => {
        message.success("Task Updated");
        this.setState({...this.state, spinning: true},() => {this.getProjectTasksLists()});
      })
      .catch(error => error)
    } else {
      if(source.index !== destination.index) {
        const {authUser} = this.props;
        const project_id = this.props.projectData.id;
        let sort_weight = 0;
        this.state.tasksList.map((tasks, index) => {
          if(tasks.tasks[result.destination.index] && tasks.id === result.destination.droppableId) {
            sort_weight = tasks.tasks[result.destination.index].sort_weight != null ? ++tasks.tasks[result.destination.index].sort_weight : 0;
          }
        })
        axios.put(`${API_URL_V1}/projects/${project_id}/tasks/${result.draggableId}`, {
          sort_weight: sort_weight,
        },
        {
          headers: {
          'Authorization': 'Bearer '+authUser
        }
        })
        .then(projectTasksData => {
          message.success("Tasks Sorted");
          this.setState({...this.state, spinning: true},() => {this.getProjectTasksLists()});
        })
        .catch(error => error)
      }
    }
  }

  employeeSelect = (value) => {
    let id = '';
    if(value !== 'all') {
      id = value;
    }
    this.setState({...this.state, employee_id: id},() => {this.getProjectTasksLists()})
  }

  render () {
    const genExtra = (tasks) => (
      <span className="task-list-components">
        <span className="gx-link gx-mb-3" onClick={(e) => {e.stopPropagation(); this.addNewTask(tasks.id)}}>Add New Task</span>
        <Divider className="task-list-component" type="vertical"/>
        <span className="gx-link task-list-component" onClick={(e) => {e.stopPropagation(); this.editTaskList(tasks)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span>
        <Divider className="task-list-component" type="vertical"/>
        <span className="gx-link task-list-component" onClick={(e) => {e.stopPropagation(); this.setState({...this.state, deleteTaskListVisible: true, taskListRecord: tasks})}}><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span>
      </span>
    );

    const customPanelStyle = {
      border: 0,
    };

    return (
      <div>
      <Modal
        visible={this.state.deleteVisible}
        title="Delete Task?"
        onOk={(e) => this.deleteTask(this.state.record, e)}
        onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
        confirmLoading={this.state.deleteLoading}
        footer={[
          <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteTask(this.state.record, e)}>
            OK
          </Button>,
          <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
            Cancel
          </Button>
        ]}
      >
        <p>Are you Sure you want to delete this Task?</p>
      </Modal>
      <Modal
        visible={this.state.timerAlert}
        title="Not Assigned to you"
        onOk={() => {this.setState({...this.state, timerAlert: false})}}
        onCancel={() => {this.setState({...this.state, timerAlert: false})}}
        footer={[
          <Button key="submit" onClick={() => {this.setState({...this.state, timerAlert: false})}}>
            OK
          </Button>
        ]}
      >
        <p>This task is not Assigned to you. Assign task to yourself first.</p>
      </Modal>
      <Modal
        visible={this.state.deleteTaskListVisible}
        title="Delete Task List?"
        onOk={(e) => this.deleteTaskList(this.state.taskListRecord, e)}
        onCancel={(e) => {this.setState({...this.state, deleteTaskListVisible: false})}}
        confirmLoading={this.state.deleteLoading}
        footer={[
          <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteTaskList(this.state.taskListRecord, e)}>
            OK
          </Button>,
          <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteTaskListVisible: false})}}>
            Cancel
          </Button>
        ]}
      >
        <p>Are you Sure you want to delete this Task List?</p>
      </Modal>

      <Auxiliary>
        {this.state.visible ?
        <AddProjectTaskListModal visible={this.state.visible} title={this.state.title} value={this.state.editData} onOk={this.handleOk} onCancel={() => {this.setState({...this.state, visible: false})}} confirmLoading={this.state.listAddLoading}/> : null}
        {this.state.taskModalVisible ?
        <AddTaskModal visible={this.state.taskModalVisible} defaultStatus={defaultStatus} status={statusOptions} title={this.state.taskTitle} employees={this.props.employees} value={this.state.editTaskData} onOk={this.handleTaskOk} onCancel={() => {this.setState({...this.state, taskModalVisible: false})}} confirmLoading={this.state.confirmLoading}/> : null }
        <div className="task-lists">
          <div style={{display: "inline"}}><b>Project Tasks List</b></div>
          <div style={{display: "inline", float: 'right'}}>
            <Select defaultValue={'all'} style={{ minWidth: 120, marginBottom: "5px" }} onChange={this.employeeSelect}>
              {employeeOptions}
            </Select>
            <Select defaultValue={'active'} style={{ minWidth: 50, marginLeft: "10px", marginRight: "10px",marginBottom: "5px" }} onChange={(value) => {this.setState({...this.state, loading: true, show: value},() => {this.setState({...this.state, loading: false})})}}>
              <Option value="active">Active</Option>
              <Option value="all">All</Option>
            </Select>
            <Button style={{ verticalAlign: "baseline" }} onClick={(e) => {this.addNewList()}}>Add New Task List</Button>
          </div>
        </div>
        {this.state.loading ? <CircularProgress /> :
        <div>
        <Spin spinning={this.state.spinning}>
          <div>

          <DragDropContext onDragEnd={this.onDragEnd}>
          {this.state.tasksList.map((tasks, index) => {
            let count = 0;
            return (
            <Droppable droppableId={tasks.id}>
              {(provided, snapshot) => (
                <div className="task-list-container" ref={provided.innerRef}>
                {tasks.tasks.forEach((task, index) => {
                  if(task.status_id !== final) {
                    count++;
                  }
                })}
                <Collapse defaultActiveKey={(count > 0 || this.state.show === 'all') ? [`${tasks.id}`] : ''} bordered={false} expandIcon={({ isActive }) => <Icon type="caret-right" rotate={isActive ? 90 : 0}/>} style={{border: '0px'}}>

                  <Panel header={tasks.due_date ? <b>{tasks.title} ({tasks.due_date})</b> : <b>{tasks.title}</b>} key={tasks.id} extra={genExtra(tasks)} style={customPanelStyle}>

                    {tasks.tasks.map((task, i) => (
                      <Draggable
                        key={task.id}
                        draggableId={task.id}
                        index={i}>
                        {(provided, snapshot) => (
                          <div className="gx-mb-3" ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps} style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}>
                          {task.status_id !== final ?
                          <div className="gx-media gx-task-list-item gx-flex-nowrap timer-container" style={{marginLeft: 20}} >
                            <div className="gx-mr-3">
                              <Checkbox checked={task.status_id === final ? true : false} indeterminate={task.status_id !== final && task.status_id !== defaultStatus ? true : false} onChange={(e) => {this.onChange(task, e)}}/>
                            </div>
                            <div className="gx-media-body gx-task-item-content">
                              <div className="gx-task-item-content-left">
                                <Link className={`gx-text-truncate gx-mb-0 ${task.status_id === final ? 'gx-text-strikethrough' : 'gx-text-hover'}`}
                                to={{pathname: `/projects/${this.props.projectData.id}/project_detail/task_detail`, state: {taskId: task.id, employees: this.props.employees}}}
                                >
                                {task.title}
                                </Link>

                                {task.employee_id ?
                                <Tag className={"gx-text-grey gx-ml-3 gx-mr-3 gx-mb-0 gx-rounded-xxl " + ((this.props.currentEmployee.id === task.employee_id) ? "ant-tag-green" : "gx-bg-grey")}>
                                  {this.props.employees.map((employee, index) => {
                                    if(task.employee_id === employee.id) {
                                      return employee.first_name+" "+employee.last_name;
                                    }
                                  })}
                                </Tag> :
                                <Tag className="gx-bg-grey gx-text-grey gx-ml-3 gx-mr-3 gx-mb-0 gx-rounded-xxl">Not Assigned</Tag>
                                }
                                <div className="timer-component">
                                  <span className="gx-fs-sm gx-text-grey gx-mr-3">{task.due_date ? task.due_date : "No Due Date"}</span>
                                  <span className="gx-fs-lg gx-text-grey gx-mr-3" style={{verticalAlign: 'middle', cursor: 'pointer'}} onClick={() => {this.playTimer(task)}}>
                                    <i className="icon icon-menu-right" title="Continue Timer"/>
                                  </span>
                                  <span className="gx-link" onClick={(e) => {this.editTask(task, tasks.id)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span>
                                  <Divider type="vertical"/>
                                  <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: task})}}><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span>
                                </div>
                              </div>
                            </div>
                          </div> : ''}
                        </div>)}
                      </Draggable>
                    ))}
                    {tasks.tasks.map((task, i) => (
                      (task.status_id === final && this.state.show === "all") ?
                      <div className="gx-media gx-task-list-item gx-flex-nowrap timer-container" style={{marginLeft: 20}}>
                        <div className="gx-mr-3">
                          <Checkbox checked={task.status_id === final ? true : false} indeterminate={(task.status_id !== final && task.status_id !== defaultStatus) ? true : false} onChange={(e) => {this.onChange(task, e)}}/>
                        </div>
                        <div className="gx-media-body gx-task-item-content">
                          <div className="gx-task-item-content-left">
                            <Link className={`gx-text-truncate gx-mb-0 ${task.status_id === final ? 'gx-text-strikethrough' : 'gx-text-hover'}`}
                            to={{pathname: `/projects/${this.props.projectData.id}/project_detail/task_detail`, state: {taskId: task.id, employees: this.props.employees}}}
                            >
                            {task.title}
                            </Link>

                            {task.employee_id ?
                            <Tag className={"gx-text-grey gx-ml-3 gx-mr-3 gx-mb-0 gx-rounded-xxl " + ((this.props.currentEmployee.id === task.employee_id) ? "ant-tag-green" : "gx-bg-grey")}>
                              {this.props.employees.map((employee, index) => {
                                if(task.employee_id === employee.id) {
                                  return employee.first_name+" "+employee.last_name;
                                }
                              })}
                            </Tag> :
                            <Tag className="gx-bg-grey gx-text-grey gx-ml-3 gx-mr-3 gx-mb-0 gx-rounded-xxl">Not Assigned</Tag>
                            }
                            <div className="timer-component">
                              <span className="gx-fs-sm gx-text-grey gx-mr-3">{task.due_date ? task.due_date : "No Due Date"}</span>
                              <span className="gx-fs-lg gx-text-grey gx-mr-3" style={{verticalAlign: 'middle', cursor: 'pointer'}} onClick={() => {this.playTimer(task)}}>
                                <i className="icon icon-menu-right" title="Continue Timer"/>
                              </span>
                              <span className="gx-link" onClick={(e) => {this.editTask(task, tasks.id)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span>
                              <Divider type="vertical"/>
                              <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: task})}}><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span>
                            </div>
                          </div>
                        </div>
                      </div> : ''
                    ))}
                  </Panel>

                </Collapse>

                </div>
              )}
            </Droppable>

          )})}
          </DragDropContext>

          </div>
          </Spin>
        </div>}
      </Auxiliary>
      </div>
    );
  }
}
const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentEmployee} = company;
  return {authUser, currentEmployee}
};

export default connect(mapStateToProps, {setCurrentTimer})(ProjectTasksList);
