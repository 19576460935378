import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Card, Table, Modal, Divider, message} from "antd";
import Auxiliary from "../../../../util/Auxiliary";
import HolidaysModal from "./HolidaysModal";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../../constants";
import axios from "axios";

let data = [];
let workspace_id, disabled;

class Holidays extends Component {

  constructor(props){
    super(props)
    this.state = {
      visible: false,
      title: '',
      loading: true,
      editData: '',
      confirmLoading: false,
      deleteVisible: false,
      deleteLoading: false,
      record: '',
    };
  }

  componentDidMount() {
    workspace_id = localStorage.getItem('id');
    data = [];
    const {authUser} = this.props;
    if(this.props.loc_id) {
      let location_name;
      for (let i = 0; i < (this.props.locationData).length; i++) {
        if(this.props.locationData[i].id == this.props.loc_id) {
          location_name = this.props.locationData[i].name;
        }
      }
      axios.get(`${API_URL_V1}/workspaces/${workspace_id}/leaves/holidays`, {
        params: {
          location_id: this.props.loc_id,
        },
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(holidaysData => {
        for (let i = 0; i < (holidaysData.data.data).length; i++) {
          data.push({
            id: holidaysData.data.data[i].id,
            name: holidaysData.data.data[i].name,
            office_name: location_name
          })
        }
        this.setState({...this.state, loading: false})
      })
      .catch(error => error);
    } else {
      axios.get(`${API_URL_V1}/workspaces/${workspace_id}/leaves/holidays`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(holidaysData => {
        let location_name = [];
        for (let i = 0; i < (holidaysData.data.data).length; i++) {
          for(let j = 0; j < (this.props.locationData).length; j++) {
            if(this.props.locationData[j].id == holidaysData.data.data[i].location_id) {
              location_name[i] = this.props.locationData[j].name;
            }
          }
          data.push({
            id: holidaysData.data.data[i].id,
            name: holidaysData.data.data[i].name,
            office_name: location_name[i]
          })
        }
        console.log(data);
        this.setState({...this.state, loading: false})
      })
      .catch(error => error);
    }
  }

  onClick = () => {
    this.setState({...this.state, visible: true, title: "Add Holiday", editData: ''});
  }

  handleCancel = () => {
    this.setState({...this.state, visible: false});
  }

  edit = (record, e) => {
    e.preventDefault();
    const {authUser} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${workspace_id}/leaves/holidays/${record.id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(editData => {
      this.setState({...this.state, visible: true, title: "Edit Holiday", editData: editData.data.data});
    })
    .catch(error => error);
  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser} = this.props;
    axios.delete(`${API_URL_V1}/workspaces/${workspace_id}/leaves/holidays/${record.id}`, {
      params: {
        workspace_id: workspace_id,
        holiday_id: record.id,
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(holidayData => {
      data = [];
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      axios.get(`${API_URL_V1}/workspaces/${workspace_id}/leaves/holidays`, {
        params: {
          location_id: this.props.loc_id ? this.props.loc_id : undefined,
        },
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(holidaysData => {
        let location_name = [];
        for (let i = 0; i < (holidaysData.data.data).length; i++) {
          for(let j = 0; j < (this.props.locationData).length; j++) {
            if(this.props.locationData[j].id == holidaysData.data.data[i].location_id) {
              location_name[i] = this.props.locationData[j].name;
            }
          }
          data.push({
            id: holidaysData.data.data[i].id,
            name: holidaysData.data.data[i].name,
            office_name: location_name[i]
          })
        }
        message.success("Holiday Deleted Successfully");
        this.setState({...this.state, loading: false})
      })
      .catch(error => error);
    })
    .catch(error => {
      message.error("Failed to Delete");
    })
  }

  handleOk = (values, id, location_id) => {
    this.setState({...this.state, confirmLoading: true});
    const {authUser} = this.props;
    const workspace_id = localStorage.getItem('id');
    if(!id){
      axios.post(`${API_URL_V1}/workspaces/${workspace_id}/leaves/holidays`, {
        name: values.holiday_name,
        date: (values.holiday_date).format('YYYY-MM-DD'),
        location_id: this.props.loc_id ? this.props.loc_id : this.props.location_id,
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(holidayData => {
        let location_name;
        for(let i = 0; i< (this.props.locationData).length; i++) {
          if(this.props.locationData[i].id == holidayData.data.data.location_id) {
            location_name = this.props.locationData[i].name;
          }
        }
        data.push({
          id: holidayData.data.data.id,
          name: holidayData.data.data.name,
          office_name: location_name,
        })
        message.success("New Holiday Added");
        this.setState({...this.state, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Add Holiday");
        this.setState({...this.state, confirmLoading: false});
      })
    } else {
      axios.put(`${API_URL_V1}/workspaces/${workspace_id}/leaves/holidays/${id}`, {
        name: values.holiday_name,
        date: (values.holiday_date).format('YYYY-MM-DD'),
        location_id: this.props.loc_id ? this.props.loc_id : location_id,
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(holidayData => {
          let location_name;
          for(let i = 0; i< (this.props.locationData).length; i++) {
            console.log(this.props.locationData[i].id)
            if(this.props.locationData[i].id == location_id) {
              location_name = this.props.locationData[i].name;
            }
          }
          console.log(location_name, id);
          for(let i =0; i< data.length; i++) {
            if(data[i].id == id) {
              data[i].name = holidayData.data.data.name;
              data[i].office_name = location_name;
            }
          }
          message.success("Holiday Editted Successfully");
        this.setState({...this.state, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Edit");
        this.setState({...this.state, confirmLoading: false});
      })
    }
  }

  render() {
    disabled = (this.props.currentEmployee && this.props.currentEmployee.role) ? this.props.currentEmployee.role.permissions.includes("edit_company") : '';
    const columns = [{
        title: 'Name',
        dataIndex: 'name',
      }, {
        title: 'Office Name',
        dataIndex: 'office_name',

      }, {
        title: 'Actions',
        key: 'x',
        render: (text, record) => (
          <span>
            {disabled ?
            <span className="gx-link" onClick={(e) => {this.edit(record, e)}}>Edit</span> : <span></span>}

            {disabled ?
              <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><Divider type="vertical"/><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span> :
              <span></span>
            }
          </span>
        )
      }];
    return(
      <div>
      <Modal
        visible={this.state.deleteVisible}
        title="Delete Holiday?"
        onOk={(e) => this.deleteRecord(this.state.record, e)}
        onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
        confirmLoading={this.state.deleteLoading}
        footer={[
          <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
            OK
          </Button>,
          <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
            Cancel
          </Button>
        ]}
      >
        <p>Are you Sure you want to delete this Holiday?</p>
      </Modal>
      {this.state.loading ?
      <CircularProgress/> :
      <div>
      {this.state.visible ?
        <HolidaysModal title={this.state.title} value={this.state.editData} visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel} confirmLoading={this.state.confirmLoading}/> : null }
      <Auxiliary>
        <div><b>Holidays</b></div><div>Holidays for {this.props.location_name}</div>
        <div className="gx-mb-5">
          <Button style={{float: "right", marginRight: "0"}} disabled={!disabled} onClick={this.onClick} value="Add">Add Holiday</Button>
          <Button style={{float: "right", marginRight: "5px"}} disabled={!disabled} value="import">Import Holiday</Button>
        </div>
        <Table className="gx-table-responsive" columns={columns} dataSource={data} pagination={false}/>
      </Auxiliary>
      </div>}
      </div>
    );
  };
}
const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentEmployee} = company;
  return {authUser, currentEmployee}
};

export default connect(mapStateToProps)(Holidays);
