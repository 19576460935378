import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Col, Row, Tabs, message} from "antd";
import ApplicantTrackingOpening from "./ApplicantTrackingOpening";
import ApplicantTrackingFormFields from "./ApplicantTrackingFormFields";
import ApplicantTrackingCandidates from "./ApplicantTrackingCandidates";
import Breadcrumbs from "components/Breadcrumbs/index";
import Auxiliary from "../../../util/Auxiliary";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../constants";
import axios from "axios";

const TabPane = Tabs.TabPane;

class ApplicantTrackingForm extends Component {

  state={
    disabled:(this.props.location && this.props.location.state) ? this.props.location.state.value ? true : false : false,
    value: (this.props.location && this.props.location.state) ? this.props.location.state.value : '',
    country: (this.props.location && this.props.location.state) ? this.props.location.state.country : '',
    activeTab: (this.props.location && this.props.location.state) ? this.props.location.state.showApplicants ? 'applicants' : 'opening' : 'opening',
    loading: false
  }

  componentWillReceiveProps(nextProps) {
    this.setState({...this.state,
      disabled:(nextProps.location && nextProps.location.state) ? nextProps.location.state.value ? true : false : false,
      value: (nextProps.location && nextProps.location.state) ? nextProps.location.state.value : '',
      loading: false
    });
  }

  changeTab = (key) => {
    this.setState({...this.state, activeTab: key});
  }

  handleOk = (values, content, id) => {
    const {authUser, currentCompany} = this.props;
    this.setState({...this.state, confirmLoading: true});
    if(!id) {
      axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/ats/openings`, {
          title: values.title,
          description: content,
          position_type: values.position_type.toString(),
          is_remote_allowed: values.is_remote_allowed,
          stages: values.stages,
          location: {
            city: values.city,
            country: values.country,
          }
        },
        {
          headers: {
            'Authorization': 'Bearer '+authUser
        }
      })
      .then(openingsData => {
        message.success("New Opening Added");
        this.setState({...this.state, loading: true});
        this.props.history.push({pathname: `/ats/${openingsData.data.id}/detail`, state: {value: openingsData.data}});
      })
      .catch(error => {
        message.error("Failed to Add Opening");
        this.setState({...this.state, loading: false});
      })
    } else {
      axios.put(`${API_URL_V1}/workspaces/${currentCompany.id}/ats/openings/${id}`, {
        title: values.title,
        description: content,
        position_type: values.position_type.toString(),
        is_remote_allowed: values.is_remote_allowed,
        stages: values.stages,
        location: {
          city: values.city,
          country: values.country,
          }
        },
        {
          headers: {
          'Authorization': 'Bearer '+authUser
          }
        }
      )
      .then(openingsData => {
        message.success("Opening Editted Successfully");
        this.setState({...this.state, value: openingsData.data, loading: false});
      })
      .catch(error => {
        message.error("Failed to Edit");
        this.setState({...this.state, loading: false});
      })
    }
  }

  AddOpening = (values, content, id) => {
    this.setState({...this.state, loading: true}, () => {
      this.handleOk(values, content, id);
      this.setState({...this.state, disabled: true});
    })
  }

  render() {
    return(
      <Auxiliary>
      {this.state.loading ? <CircularProgress /> :
        <div className="gx-profile-content">
          <Breadcrumbs />
          <Card className="gx-card" title="Opening Details">
            <Row>
              <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <Tabs type="card" activeKey={this.state.activeTab} onChange={this.changeTab}>
                  <TabPane tab="Opening" key="opening">
                    <ApplicantTrackingOpening value={this.state.value} country={this.state.country} currentCompany={this.props.currentCompany} onCancel={this.props.onCancel} onOk={(values, content, id) => {this.AddOpening(values, content, id)}} loading={this.state.loading}/>
                  </TabPane>
                  <TabPane tab="Fields" key="fields" disabled={!this.state.disabled}>
                    <ApplicantTrackingFormFields value={this.state.value}/>
                  </TabPane>
                  <TabPane tab="Applicants" key="applicants" disabled={!this.state.disabled}>
                    <ApplicantTrackingCandidates value={this.state.value}/>
                  </TabPane>
                </Tabs>
              </Col>
            </Row>
          </Card>
        </div>}
      </Auxiliary>
    )
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, currentCompany, currentEmployee}
};

export default connect(mapStateToProps)(ApplicantTrackingForm);
