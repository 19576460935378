import React, {Component} from "react";
import {Modal, Form, Button, Upload, Icon, message} from 'antd';

const {Dragger} = Upload;
const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends Component {
    state = {
        fileList: []
    }
    render() {
      const { fileList } = this.state;

      const formItemLayout = {
        labelCol: {
          xs: {span: 0},
          sm: {span: 0},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 24},
        },
      };
      const props = {
          onRemove: (file) => {
              this.setState((state) => {
                  return {
                      fileList: [],
                  };
              });
          },
          beforeUpload: (file) => {
              const isxlsx = file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
              if(!isxlsx) {
                message.error("Invalid file");
              } else {
              this.setState(state => ({
                  fileList: [file],
              }));}
              return false;
          },
          fileList,
      };

      const { getFieldDecorator } = this.props.form;
      return (
        <Modal
          visible={this.props.visible}
          title={this.props.title}
          onCancel={this.props.onCancel}
          onOk={this.props.onCreate}
          footer={[
            <Button key="submit" loading={this.props.confirmLoading} onClick={this.props.onCreate}>
              {this.props.title}
            </Button>,
            <Button key="cancel" onClick={this.props.onCancel}>
              Cancel
            </Button>
          ]}
        >
          <p>Download our Excel template, fill it out with your employee's data and when you’re done upload the file here to import your team.</p>
          <Button type="primary" block onClick={() => {this.props.getFormat()}}>Download Template</Button>
          <Form >
            <Form.Item
                {...formItemLayout}
                label="Import File">
                {getFieldDecorator('import_file', {
                    rules: [{ required: true, message: 'Please select a file!' }],
                })(
                    <Dragger {...props}>
                      <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                      </p>
                      <p className="ant-upload-text">Click or drag template with your employees to this area to upload</p>
                    </Dragger>
                )}
            </Form.Item>
          </Form>

        </Modal>
      );
    }
  }
);

class ImportEmployees extends React.Component {

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onOk(values);
    });
  }

  render() {
    return (
      <div>

        <CollectionCreateForm
          title={this.props.title}
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          onCreate={this.handleCreate}
          getFormat={this.props.getFormat}
        />

      </div>
    );
  }
}

export default ImportEmployees;
