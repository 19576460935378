import React from "react";
import {Avatar, Checkbox, Tag, Tooltip} from "antd";
import { Link } from 'react-router-dom'
import Aux from "util/Auxiliary";
import moment from "moment";

const getStatus = (status) => {
  let color = "#425275", r,g,b;
  if(status.color) {
    color = +("0x" + status.color.slice(1).replace(
      status.color.length < 5 && /./g, '$&$&'
    ));
    r = color >> 16;
    g = color >> 8 & 255;
    b = color & 255;
    let hsp = Math.sqrt(
      0.299 * (r * r) +
      0.587 * (g * g) +
      0.114 * (b * b)
    );
    if (hsp>127.5) {
      color = "#000000";
    } else {
      color = "#ffffff";
    }
  }
  return <Aux>
    <span className={`gx-badge gx-hover gx-mb-0`} style={{backgroundColor: status.color ? status.color : "#dfe1e6", color: color }}>
      {status.name}
    </span>
    </Aux>
};

const TaskItem = ({data, projects}) => {
  const {id, title, task_status, employee_id, project_id, start_at} = data;
  let project = projects.filter((project, index) => project.id == project_id);
  return (
    <Aux>
      <div className="gx-media gx-task-list-item gx-flex-nowrap">
        <div className="gx-mr-3">
          <i className="icon icon-circle gx-fs-xs" style={{color: '#5e5cdb', cursor: 'auto'}} title={"Task Id: "+id}/>
        </div>
        <div className="gx-media-body gx-task-item-content">
          <div className="gx-task-item-content-left">
            <Link className={`gx-text-truncate gx-mb-0`} to={{pathname: `/projects/${project_id}/project_detail`, state: {taskId: id, projectData: project[0]}}}>{title}</Link>
          </div>
          <div className="gx-task-item-content-right">
            <Tag
              className="gx-bg-grey gx-text-black gx-ml-3 gx-mr-0 gx-mb-0 gx-rounded-xxl gx-order-sm-2" style={{overflow: "hidden"}}>{projects.map(project => {
                if(project.id === project_id) {
                  return project.name;
                }
              })}</Tag>
            <ul className="gx-dot-list gx-mb-0 gx-order-sm-1 gx-ml-2">
              {task_status ? getStatus(task_status) : ''}
            </ul>
            <span className="gx-fs-sm gx-text-grey gx-ml-0 gx-task-date gx-order-sm-3">{data.start_date ? moment(data.start_date).format("DD MMM") : ""}</span>
          </div>
        </div>
      </div>
    </Aux>
  );
};

export default TaskItem;
