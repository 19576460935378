import React from "react";
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";

const COLORS = ['#e85b7a', '#ff6486', '#ff809c', '#ffc0ce'];

const AttendanceChart = (attendance) => {
  let attendanceArray = (attendance.data).reduce(function(attendanceItem, item) {
    Object.keys(item).forEach(function(key) {
      attendanceItem[key] = (attendanceItem[key] || 0) + item[key];
    });
    return attendanceItem;
  }, {});

  let result = Object.keys(attendanceArray).map(function(key) {
    if(key === "attendance") {
      return {name: "Present", value: attendanceArray[key]}
    }
    if(key === "month" || key === "working_days") {
      return 0
    }
    return {name: key, value: attendanceArray[key]};
  });
  return (
      <div className="gx-py-3">
        <ResponsiveContainer width="100%" height={85}>
          <PieChart>
            <Tooltip wrapperStyle={{backgroundColor: '#ccc', zIndex: 1, opacity: 0.8}}/>
            <Pie
              data={result}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={20}
              outerRadius={40}
            >
              {
                result.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
              }
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
  );
};
export default AttendanceChart;
