import React from "react";
import {Avatar, Row, Col, Tooltip} from "antd";
import {API_URL_V1} from '../../../constants';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CircularProgress from "components/CircularProgress/index";
import Widget from "components/Widget/index";
import moment from "moment";

function compare(a,b) {
  if (a.date < b.date)
    return -1;
  if (a.date > b.date)
    return 1;
  return 0;
}

function getRandomColor() {
  let letters = '0123456789ABCDEF';
  let color = "#";
  for (let i= 0; i< 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

function getName(metadata, shape) {
  if(metadata.avatar !== undefined) {
    if (metadata.avatar === '' || metadata.avatar === null) {
      let first_nameSplit = metadata.first_name.split(" ");
      let last_nameSplit = metadata.last_name.split(" ");
      if (metadata.first_name.split(" ").length === 1) {
        const initials = first_nameSplit[0].charAt(0).toUpperCase() + last_nameSplit[0].charAt(0).toUpperCase();
        return <Tooltip title={metadata.first_name+" "+metadata.last_name}><Avatar shape={shape} className="gx-size-60" style={{backgroundColor: getRandomColor()}}>{initials}</Avatar></Tooltip>
      } else {
        const initials = first_nameSplit[0].charAt(0).toUpperCase() + first_nameSplit[1].charAt(0).toUpperCase();
        return <Tooltip title={metadata.first_name+" "+metadata.last_name}><Avatar shape={shape} className="gx-size-60" style={{backgroundColor: getRandomColor()}}>{initials}</Avatar></Tooltip>
      }
    } else {
      return <Tooltip title={metadata.first_name+" "+metadata.last_name}><Avatar shape={shape} className="gx-size-60" src={`${API_URL_V1}${metadata.avatar}`}/></Tooltip>;
    }
  } else if(metadata.employee !== undefined) {
    if (metadata.employee.avatar === '' || metadata.employee.avatar === null) {
      let first_nameSplit = metadata.employee.first_name.split(" ");
      let last_nameSplit = metadata.employee.last_name.split(" ");
      if (metadata.employee.first_name.split(" ").length === 1) {
        const initials = first_nameSplit[0].charAt(0).toUpperCase() + last_nameSplit[0].charAt(0).toUpperCase();
        return <Tooltip title={metadata.employee.first_name+" "+metadata.employee.last_name}><Avatar shape={shape} className="gx-size-60" style={{backgroundColor: getRandomColor()}}>{initials}</Avatar></Tooltip>
      } else {
        const initials = first_nameSplit[0].charAt(0).toUpperCase() + first_nameSplit[1].charAt(0).toUpperCase();
        return <Tooltip title={metadata.employee.first_name+" "+metadata.employee.last_name}><Avatar shape={shape} className="gx-size-60" style={{backgroundColor: getRandomColor()}}>{initials}</Avatar></Tooltip>
      }
    } else {
      return <Tooltip title={metadata.employee.first_name+" "+metadata.employee.last_name}><Avatar shape={shape} className="gx-size-60" src={`${API_URL_V1}${metadata.employee.avatar}`}/></Tooltip>;
    }
  } else {
    return <Avatar shape={shape} className="gx-size-60" src={''}/>;
  }
}

class CompanyEventsUpdate extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      recentList: [],
      shape: '',
      limit: 2,
      reload: 0
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentWillMount() {
    this.setState({
      height: window.innerHeight + 'px',
      width: window.innerWidth + 'px',
      recentList: this.props.recentList,
      shape: 'circle'
    });
    if (window.innerWidth < 575) {
      this.setState({limit: 1});
    }

  }

  componentDidUpdate() {
    if((this.props.recentList).length === 0 && this.state.limit !== 0 && this.state.reload === 0) {
      this.setState({...this.state, limit: 0});
    }
    if((this.props.recentList).length !== 0 && this.state.limit === 0 && this.state.reload === 0) {
      this.setState({...this.state, limit: ((this.props.recentList).length < 2) ? ((this.props.recentList).length) : 2, reload: 1});
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({width: window.innerWidth, height: window.innerHeight});
  }

  onLoadMore() {
    this.setState((previousState) => ({
      limit: previousState.limit + 1,
    }));
  }

  onLoadLess() {
    this.setState((previousState) => ({
      limit: previousState.limit - 1,
    }));
  }

  render() {
    const options = {
      dots: true,
      infinite: false,
      speed: 500,
      marginLeft: 20,
      marginRight: 20,
      slidesToShow: 5,
    };
    const {shape, limit} = this.state;
    const {recentList} = this.props;
    let birthdayList = [], leaveList = [];
    let month = ("0" + (new Date().getMonth() + 1)).slice(-2)
    let day = ("0" + new Date().getDate()).slice(-2)
    let year = new Date().getFullYear();
    let date = year + "-" + month + "-" + day;
    recentList.sort(compare);
    recentList.forEach((list) => {
      if(list.type === "birthday") {
        birthdayList.push(list)
      }
      if(list.type === "leave" && list.date === date) {
        leaveList.push(list)
      }
    })
    const recentLists=[];
    return (
      <div className="gx-entry-sec">
      <Widget title={<h2 className="h4 gx-text-capitalize gx-mb-0">Company Updates</h2>} extra={<span className="gx-ml-2 gx-search-icon" onClick={() => {this.props.refresh()}}><i
        className="icon icon-frequent gx-pointer gx-text-primary gx-fs-xxl"/></span>} height="400px">
        {this.props.loader ?
        <CircularProgress height="250px"/>:
        <div>
        <h4 className="gx-timeline-info-day">Upcoming Birthdays</h4>
        {(birthdayList.length > 4) ?
        <ul className="gx-list-inline gx-customers-list" style={{textAlign: "center"}}>
        <Slider {...options}>
          {birthdayList.map((events, index) =>
            <li className="gx-ml-2">
              {getName(events.metadata)}<br/>
              <div className="gx-timeline-info-day">
                {events.metadata.first_name}<br/>
                {(events.metadata.birthday_on === date) ? "Today" : moment(events.metadata.birthday_on).format("Do MMM")}
              </div>
            </li>)}
            </Slider>
          </ul> :
          <ul className="gx-list-inline gx-customers-list">
            {birthdayList.map((events, index) =>
              <li className="gx-ml-2" style={{textAlign: "center"}}>
                {getName(events.metadata)}<br/>
                <div className="gx-timeline-info-day">
                  {events.metadata.first_name}<br/>
                  {(events.metadata.birthday_on === date) ? "Today" : moment(events.metadata.birthday_on).format("Do MMM")}
                </div>
              </li>)}
            </ul>
          }
          {birthdayList.length === 0 && <Row type="flex" justify="center" className="gx-mb-0">
            <Col xs={18} lg={9} className="text-center">
            <div className="gx-mt-0">
              <i className={"icon icon-birthday-new gx-fs-xlxl gx-text-green"}/>
            </div>
            <div className="gx-mt-0">
              No Upcoming Birthday
            </div>
            </Col>
          </Row>}
          <h4 className="gx-timeline-info-day">People who are out of office</h4>
          {(leaveList.length > 4) ?
          <ul className="gx-list-inline gx-customers-list" style={{textAlign: "center"}}>
          <Slider {...options}>
            {leaveList.map((events, index) =>
              <li className="gx-ml-2">
                {getName(events.metadata)}<br/>
                <div className="gx-timeline-info-day">
                  {events.metadata.employee.first_name}<br/>
                </div>
              </li>)}
              </Slider>
            </ul> :
            <ul className="gx-list-inline gx-customers-list">
              {leaveList.map((events, index) =>
                <li className="gx-ml-2" style={{textAlign: "center"}}>
                  {getName(events.metadata)}<br/>
                  <div className="gx-timeline-info-day">
                    {events.metadata.employee.first_name}<br/>
                  </div>
                </li>)}
              </ul>
            }
            {leaveList.length === 0 && <Row type="flex" justify="center" className="gx-mb-0">
              <Col xs={18} lg={9} className="text-center">
              <div className="gx-mt-0">
                <i className={"icon icon-hotel-booking gx-fs-xlxl gx-text-green"}/>
              </div>
              <div className="gx-mt-0">
                No One on Leave Today
              </div>
              </Col>
            </Row>}
        </div>}
        </Widget>
      </div>
    );
  }
}

export default CompanyEventsUpdate;
