import React, {Component} from "react";
import {Select} from "antd";
import Auxiliary from "../../../util/Auxiliary";

const { Option } = Select;
class GroupingFilters extends Component {
  state={
    group: "client_id",
    subgroup: "project_id",
  }

  onGroupChange = (value) => {
    this.setState({...this.state, group: value},() => {this.props.filterData(this.state)});
  }

  onSubgroupChange = (value) => {
    this.setState({...this.state, subgroup: value},() => {this.props.filterData(this.state)});
  }

  render() {
    return (
      <Auxiliary>
        <span>Group By: </span>
        <Select style={{ width: 100, marginBottom: 10, marginRight: 5 }} placeholder="Group By" defaultValue={this.state.group} onChange={this.onGroupChange}>
          <Option value="project_id">Project</Option>
          <Option value="employee_id">User</Option>
          <Option value="client_id">Client</Option>
        </Select>
        <span>and </span>
        <Select style={{ width: 100, marginBottom: 10, marginRight: 5 }} placeholder="SubGroup" defaultValue={this.state.subgroup} onChange={this.onSubgroupChange}>
          {(this.state.group === "client_id" || this.state.group === "project_id") ? <Option value="employee_id">User</Option> : ''}
          {this.state.group === "employee_id" ? <Option value="client_id">Client</Option> : ''}
          {this.state.group !== "project_id" ? <Option value="project_id">Project</Option> : ''}
        </Select>
      </Auxiliary>
    );
  }
}

export default GroupingFilters;
