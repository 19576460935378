import React, {Component} from "react";
import {Modal, Form, Input, Row, Col, Select, Checkbox, Button} from 'antd';

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends Component {
    render() {

      const formItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 6},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 18},
        },
      };
      const inlineItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 12},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 12},
        },
      };
      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 6,
          },
        },
      };

      const { getFieldDecorator } = this.props.form;
      return (
        <Modal
          visible={this.props.visible}
          title={this.props.title}
          onCancel={this.props.onCancel}
          onOk={this.props.onCreate}
          footer={[
            <Button key="submit" loading={this.props.confirmLoading} onClick={this.props.onCreate}>
              {this.props.title}
            </Button>,
            <Button key="cancel" onClick={this.props.onCancel}>
              Cancel
            </Button>
          ]}
        >
          <Form >
            <Form.Item
            {...formItemLayout}
            label="Office Name">
              {getFieldDecorator('officeName', {
                initialValue: this.props.value ? this.props.value.name : '',
                rules: [{ required: true, message: 'Please Enter the Office Name!' }],
              })(
                <Input placeholder= "Office Name"/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Address Line 1">
              {getFieldDecorator('address1', {
                initialValue: this.props.value ? this.props.value.address_line_1 : '',
                rules: [{ required: true, message: 'Please enter the Address!' }],
              })(
                <Input placeholder= "Address Line 1"/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Address Line 2">
              {getFieldDecorator('address2', {
                initialValue: this.props.value ? this.props.value.address_line_2 : '',
                rules: [{ required: true, message: 'Please enter the Address!' }],
              })(
                <Input placeholder= "Address Line 2"/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Phone No.">
              {getFieldDecorator('phone_number', {
                initialValue: this.props.value ? this.props.value.phone_number : '',
              })(
                <Input placeholder= "Phone No."/>
              )}
            </Form.Item>
            <Row gutter={24}>
              <Col span={12} style={{ display:'block'}}>
                <Form.Item
                {...inlineItemLayout}
                label="City">
                  {getFieldDecorator('city', {
                    initialValue: this.props.value ? this.props.value.city : '',
                  })(
                    <Input placeholder= "City"/>
                  )}
                </Form.Item>
              </Col>
              <Col span={12} style={{ display:'block'}}>
                <Form.Item
                {...inlineItemLayout}
                label="State">
                  {getFieldDecorator('state', {
                    initialValue: this.props.value ? this.props.value.state : '',
                  })(
                    <Input placeholder= "State"/>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12} style={{ display:'block'}}>
                <Form.Item
                {...inlineItemLayout}
                label="Country">
                  {getFieldDecorator('country', {
                    initialValue: (this.props.value && this.props.value.country) ? (this.props.value.country).toString() : undefined,
                  })(
                    <Select placeholder="Select Country" showAction={['click', 'focus']} filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0} showSearch optionFilterProp="children">
                      {this.props.countries}
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12} style={{ display:'block'}}>
                <Form.Item
                {...inlineItemLayout}
                label="Zip">
                  {getFieldDecorator('zip', {
                    initialValue: this.props.value ? this.props.value.postal_code : '',
                  })(
                    <Input placeholder= "Zip"/>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
            {...tailFormItemLayout}>
              {getFieldDecorator('main', {
              valuePropName: 'checked',
              initialValue: this.props.value ? this.props.value.main : false,
              })(
                <Checkbox>Mark as main office</Checkbox>
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

class FormOffice extends React.Component {

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onOk(values, (this.props.value ? this.props.value.id : ''));
    });
  }

  render() {
    return (
      <div>

        <CollectionCreateForm
          title={this.props.title}
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          onCreate={this.handleCreate}
          value={this.props.value}
          countries={this.props.countries}
          confirmLoading={this.props.confirmLoading}
        />

      </div>
    );
  }
}

export default FormOffice;
