import React from "react";
import {Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import _ from "lodash";
import moment from "moment";

function CustomTooltip({ payload, label, active, resolution }) {
  if (active) {
    return (
      <div>
      {(payload[0].payload.created || payload[0].payload.completed) ?
        <div className="custom-tooltip" style={{backgroundColor: "#ffffff", borderRadius: "8px", boxShadow: "0 4px 8px 0 rgba(0,0,0,0.3)", minWidth: 100}}>
          <div style={{margin: "5%"}}>
            <p className="day" style={{fontWeight: "bold"}}>{resolution === 'day' ? moment(payload[0].payload.date).format("dddd, Do MMM") : resolution === 'week' ? moment((payload[0].payload.date).split('/').join('-'), "YYYY-WW").format("YYYY-Wo") : resolution === 'month' ? moment((payload[0].payload.date).split('/').join('-')).format("MMM-YYYY") : payload[0].payload.date}</p>
            <p className="label">Created : {payload[0].payload.created ? payload[0].payload.created : 0}</p>
            <p className="label">Completed : {payload[0].payload.completed ? payload[0].payload.completed : 0}</p>
          </div>
        </div> : ''}
      </div>
    );
  }

  return null;
}

const tickDayFormatter = label => {
  return (
    moment(label).format('ddd,Do MMM')
  );
};

const tickWeekFormatter = label => {
  let newLabel = label.split('/').join('-');
  return (
    moment(newLabel, "YYYY-WW").format('YYYY/Wo')
  );
};

const tickMonthFormatter = label => {
  let newLabel = label.split('/').join('-');
  return (
    moment(newLabel).format('MMM-YYYY')
  );
};

const TaskReportChart = (taskReportData) => {
  let resolution = taskReportData.resolution;
  delete taskReportData.data.resolution;
  let chartData = _(taskReportData.data)
                .values()
                .flatten()
                .groupBy('date')
                .map(function(values) {
                  return _.merge.apply(_, [{}].concat(values));
                })
                .value();
  return (
    <ResponsiveContainer width="100%" height={200}>
      <BarChart data={chartData}
                margin={{top: 10, right: 0, left: -15, bottom: 0}}>
        <XAxis dataKey="date" tickFormatter={resolution === 'day' ? tickDayFormatter : resolution === 'week' ? tickWeekFormatter : resolution === 'month' ? tickMonthFormatter : ''} axisLine={false}/>
        <YAxis />
        <CartesianGrid strokeDasharray="3 3"/>
        <Tooltip content={<CustomTooltip resolution={resolution}/>}/>
        <Bar dataKey="created" barSize={50} maxBarSize={50} fill="#6accf9"/>
        <Bar dataKey="completed" barSize={50} maxBarSize={50} fill="#4bc800"/>
      </BarChart>
    </ResponsiveContainer>
)};

export default TaskReportChart;
