import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {Input, Table, Divider, Select, Modal, Button, message} from "antd";
import ApplicantTrackingCandidatesModal from "./ApplicantTrackingCandidatesModal";
import {FaDownload} from "react-icons/fa";
import {API_URL_V1} from "../../../constants";
import CircularProgress from "components/CircularProgress/index";
import Auxiliary from "../../../util/Auxiliary";
import _ from "lodash";
import axios from "axios";

const {Option} = Select;
const { Search } = Input;
let fileDownload = require('js-file-download');

let stageOptions=[];
class ApplicantTrackingCandidates extends Component {
  state={
    candidates: [],
    title: '',
    editData: '',
    record:'',
    deleteVisible: false,
    visible: false,
    q: null,
    confirmLoading: false,
    loading: true,
  }

  getCandidates = () => {
    const {authUser} = this.props;
    axios.get(`${API_URL_V1}/ats/openings/${this.props.value.id}/candidates`, {
      params: {
        search: this.state.q,
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(candidatesData => {
      stageOptions=[];
      this.props.value.stages.forEach((stage, index) => {
        stageOptions.push(<Option value={stage}>{stage}</Option>);
      })
      this.setState({...this.state, candidates: candidatesData.data.data, loading: false});
    })
    .catch(error => error)
  }

  setSearchQuery = _.debounce((value) => {
    this.setState({...this.state, pagination: {...this.state.pagination, current: 1}, q: value}, () => {this.getCandidates()})
  }, 500)

  componentDidMount () {
    this.getCandidates();
  }

  edit = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, visible: true, title: "Edit Stage", editData: record});
  }

  onCancel = () => {
    this.setState({...this.state, visible: false});
  }

  download = (record, e) => {
    const {authUser} = this.props;
    axios.get(`${API_URL_V1}/ats/openings/${this.props.value.id}/candidates/${record.id}/resume`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(resumeData => {
      let type = resumeData.headers["content-type"].split(" ");
      let ext = '', type1='';
      if(type[0] === "text/plain;") {
        ext = "txt";
      } else if(type[0] === "text/html;") {
        ext = "html";
      } else if(type[0] === "application/msword" || type[0] === "application/vnd.openxmlformats-officedocument.wordprocessingml.document" || type[0] === "application/doc" || type[0] === "application/ms-doc") {
        ext = "docx";
      } else {
        type1 = type[0].split("/");
        ext = type1[1];
      }
      fileDownload(resumeData.data, `resume.${ext}`);
    })
    .catch(error => error)
  }

  handleOk = (values, id) => {
    const {authUser} = this.props;
    this.setState({...this.state, confirmLoading: true});
    axios.put(`${API_URL_V1}/ats/openings/${this.props.value.id}/candidates/${id}`, {
        first_name: this.state.editData.first_name,
        last_name: this.state.editData.last_name,
        email: this.state.editData.email,
        phone: this.state.editData.phone,
        stage: values.stage,
      },
      {
        headers: {
          'Authorization': 'Bearer '+authUser
      }
    })
    .then(candidateData => {
      let candidates = this.state.candidates;
      candidates.forEach((candidate, index) => {
        if(candidate.id == candidateData.data.id){
          candidates[index] = {...candidate, ...candidateData.data};
        }
      })
      message.success('Updated Stage');
      this.setState({...this.state, candidates: candidates, visible: false, confirmLoading: false});
    })
    .catch(error => {
      message.error('Something went wrong');
      this.setState({...this.state, confirmLoading: false});
    })
  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser, currentCompany} = this.props;
    axios.delete(`${API_URL_V1}/ats/openings/${this.props.value.id}/candidates/${record.id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(candidateData => {
      message.success("Candidate Deleted Successfully");
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      this.getCandidates();
    })
    .catch(error => error)
  }

  showCandidate = (record, e) => {
    this.props.history.push({pathname: `/ats/${this.props.value.id}/detail/candidate_detail`, state: {value: this.props.value, candidate: record}});
  }

  render() {
    const {currentEmployee} = this.props;
    let canManage = currentEmployee && currentEmployee.role && (currentEmployee.role.permissions.includes("edit_company") || currentEmployee.role.permissions.includes("manage_company"));
    const columns = [{
        title: 'Name',
        render: (text, record) => (
          <span className="gx-link" onClick={(e) => {this.showCandidate(record, e)}}>
            {record.first_name} {record.last_name}
          </span>
        ),
        sorter: (a, b) => {return a.first_name.localeCompare(b.first_name)},
      }, {
        title: 'Email',
        render: (text, record) => (
          <span>
            {record.email}
          </span>
        )
      }, {
        title: 'Phone',
        render: (text, record) => (
          <span>
            {record.phone}
          </span>
        )
      }, {
        title: 'Stage',
        render: (text, record) => (
          <span>
            {record.stage}
          </span>
        )
      }, {
        title: 'Actions',
        render: (text, record, index) => (
          <span>
          {canManage ?
          <span className="gx-link" onClick={(e) => {this.download(record, e)}}><i className="fa fa-download" title="Download Resume" style={{verticalAlign: "middle"}}/></span> : <span></span>}
          {canManage ?
          <span className="gx-link" onClick={(e) => {this.edit(record, e)}}><Divider type="vertical"/><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span> : <span></span>}
          {canManage ?
              <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><Divider type="vertical"/><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span> :
              <span></span>
          }
          </span>
        )
      }];
    return(
      <div>
      {this.state.loading ? <CircularProgress /> :
        <Auxiliary>
        <Modal
          visible={this.state.deleteVisible}
          title="Delete Opening?"
          onOk={(e) => this.deleteRecord(this.state.record, e)}
          onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
          confirmLoading={this.state.deleteLoading}
          footer={[
            <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
              OK
            </Button>,
            <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
              Cancel
            </Button>
          ]}
        >
          <p>Are you Sure you want to delete {this.state.record.first_name} Candidate?</p>
        </Modal>
        {this.state.visible ? <ApplicantTrackingCandidatesModal title={this.state.title} value={this.state.editData} stages={stageOptions} visible={this.state.visible} onCancel={this.onCancel} onOk={this.handleOk} loading={this.state.confirmLoading}/> : null}
        <Search
          placeholder="Search Candidate or Email"
          defaultValue={this.state.q}
          onChange={(e) => {this.setSearchQuery(e.target.value)}}
          style={{width: 220}}
        />
          <Table className="gx-table-responsive" columns={columns} dataSource={this.state.candidates} />
        </Auxiliary>
      }
      </div>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, currentCompany, currentEmployee}
};

export default withRouter(connect(mapStateToProps)(ApplicantTrackingCandidates));
