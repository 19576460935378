import React, {Component} from "react";
import {Modal, Form, Input, Select, Button, Switch, Row, Col} from 'antd';
import CKEditor from "react-ckeditor-component";

const Option = Select.Option;
const {TextArea} = Input;

class ApplicantTrackingOpening extends React.Component {

  constructor(props) {
    super(props);
    this.updateContent = this.updateContent.bind(this);
    this.state = {
      content: this.props.value.description
    }
  }
  updateContent(newContent) {
    this.setState({
      content: newContent
    })
  }
  onChange(evt) {
    const newContent = evt.editor.getData();
    this.setState({
      content: newContent
    })
  }

  onBlur(evt) {
    console.log('onBlur event called with event info: ', evt);
  }

  afterPaste(evt) {
    console.log('afterPaste event called with event info: ', evt);
  }

  render() {
    const formItemLayout = {
      labelCol: {
        xs: {span: 24},
        sm: {span: 6},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
      },
    };
    const inlineItemLayout = {
      labelCol: {
        xs: {span: 24},
        sm: {span: 12},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 12},
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 18,
          offset: 6,
        },
      },
    };
    const { getFieldDecorator } = this.props.form;
    return (
      <Form
      onSubmit={(e) => {
        e.preventDefault(); this.props.form.validateFieldsAndScroll((err, values) => {
          if (!err) {
            this.props.onOk(values, this.state.content, this.props.value.id);
          }
        });
      }}>
        <Form.Item
        {...formItemLayout}
        label="Name">
          {getFieldDecorator('title', {
            initialValue: this.props.value.title,
            rules: [{ required: true, message: 'Please enter job title!' }],
          })(
            <Input placeholder= "Opening Title"/>
          )}
        </Form.Item>
        <Form.Item
        {...formItemLayout}
        label="Description">
          {getFieldDecorator('description', {
            initialValue: this.props.value.description,
          })(
            <CKEditor
              activeClass="p10"
              content={this.state.content}
              events={{
                'blur': this.onBlur.bind(this),
                'afterPaste': this.afterPaste.bind(this),
                'change': this.onChange.bind(this)
              }}
            />
          )}
        </Form.Item>
        <Form.Item
        {...formItemLayout}
        label="Stages" extra="Use comma(,) as separator">
          {getFieldDecorator('stages', {
            initialValue: this.props.value.stages,
            rules: [{ required: true, message: 'Please enter stages!' }],
          })(
            <Select mode="tags" style={{ width: '100%' }} tokenSeparators={[',']}>
              {}
            </Select>
          )}
        </Form.Item>
        <Row gutter={24}>
          <Col span={12} style={{ display:'block'}}>
            <Form.Item
            {...inlineItemLayout}
            label="Position">
              {getFieldDecorator('position_type', {
                initialValue: this.props.value.position_type ? (this.props.value.position_type).toString() : undefined,
                rules: [{ required: true, message: 'Please select the job type!' }],
              })(
                <Select placeholder="Select Type" showAction={['click', 'focus']}>
                  <Option value="full_time">Full Time</Option>
                  <Option value="part_time">Part Time</Option>
                  <Option value="contract">Contractual</Option>
                </Select>
              )}
            </Form.Item>
          </Col>
          <Col span={12} style={{ display:'block'}}>
            <Form.Item
            {...inlineItemLayout}
            label="Remote">
              {getFieldDecorator('is_remote_allowed', {
                initialValue: this.props.value.is_remote_allowed,
                valuePropName: 'checked',
              })(
                <Switch />
              )}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col span={12} style={{ display:'block'}}>
            <Form.Item
            {...inlineItemLayout}
            label="City">
              {getFieldDecorator('city', {
                initialValue: this.props.value && this.props.value.location ? this.props.value.location.city : this.props.currentCompany.address.city,
                rules: [{ required: true, message: 'Please enter the city name!' }],
              })(
                <Input placeholder="Enter City"/>
              )}
            </Form.Item>
          </Col>
          <Col span={12} style={{ display:'block'}}>
            <Form.Item
            {...inlineItemLayout}
            label="Country">
              {getFieldDecorator('country', {
                initialValue: this.props.value && this.props.value.location ? this.props.value.location.country : this.props.country,
                rules: [{ required: true, message: 'Please enter the country name!' }],
              })(
                <Input placeholder="Enter Country"/>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit">
            {this.props.value ? "Edit Opening" : "Add Opening"}
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedApplicantTrackingOpening = Form.create({ name: 'opening_form' })(ApplicantTrackingOpening);
export default WrappedApplicantTrackingOpening;
