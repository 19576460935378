import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Row, Col, Skeleton, Divider} from "antd";
import Auxiliary from "../../../../util/Auxiliary";
import CircularProgress from "components/CircularProgress/index";
import Breadcrumbs from "components/Breadcrumbs/index";
import {API_URL_V1} from "../../../../constants";
import moment from "moment";
import axios from "axios";

class TaskDetail extends Component {

  state={
    loading: true,
    taskData: '',
  }

  getTask = (projectId, taskId) => {
    const {authUser} = this.props;
    axios.get(`${API_URL_V1}/projects/${projectId}/tasks/${taskId}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(taskData => {
      console.log(taskData);
      this.setState({...this.state, taskData: taskData.data.data, loading: false});
    })
  }

  componentDidMount() {
    console.log(this.props);
    this.getTask(this.props.match.params.id, this.props.history.location.state.taskId);
  }
  render() {
    let task = this.state.taskData;
    let employees = this.props.history.location.state.employees ? this.props.history.location.state.employees : '';
    let employee;
    if(employees) {
      employee = employees.filter( employee => employee.id == task.employee_id);
      if (employee.length > 0){
        employee = employee[0].first_name +" "+ employee[0].last_name;
      }
    }
    return (
      <div>
        <div>
          <Auxiliary>
            <Breadcrumbs/>
            <div className="gx-profile-content">
              <Row>
                <Col xl={17} lg={14} md={14} sm={24} xs={24}>
                  <Card className="gx-card">
                    <Skeleton loading={this.state.loading} active>
                    <div className="gx-pt-sm-3">
                      <h2 className="gx-mb-3 gx-mb-sm-4"># {task.title}</h2>
                      <Divider orientation="left">Description</Divider>
                      <p>
                        {task.description ? task.description : <span className="gx-text-grey">No Description Provided</span>}
                      </p>
                        <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                          <div className="gx-media-body">
                            <h6 className="gx-mb-1 gx-text-grey">Status</h6>
                            {task.status}
                          </div>
                        </div>
                        <Divider orientation="left">People</Divider>

                        {employee ?
                        <div className="gx-media gx-flex-nowrap gx-mt-3 gx-mt-lg-4 gx-mb-2">
                          <div className="gx-media-body">
                            <h6 className="gx-mb-1 gx-text-grey">Task Assigned To</h6>
                            {employee}
                          </div>
                        </div>: ''}
                        <Divider orientation="left">Dates</Divider>
                        <p>
                          Due Date: {task.due_date}
                          <br/>
                          Created On: {moment.utc(task.created_at).local().format("YYYY-MM-DD hh:mm:ss")}
                        </p>
                      </div>
                      </Skeleton>
                    </Card>
                  </Col>
                </Row>
              </div>
            </Auxiliary>
          </div>
      </div>
    )
  }
}

const mapStateToProps = ({auth, company}) => {
    const {authUser, userWorkspaceData} = auth;
    const {currentCompany, currentEmployee} = company;
    return {authUser, userWorkspaceData, currentCompany, currentEmployee}
};

export default connect(mapStateToProps)(TaskDetail);
