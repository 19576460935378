import {
  GET_USER_ID,
  GET_USER_ID_SUCCESS,
  GET_DEPARTMENTS,
  GET_DEPARTMENTS_SUCCESS,
} from "constants/ActionTypes";

export const getUserId = (authUser) => {
  return {
    type: GET_USER_ID,
    payload: authUser
  };
};

export const getUserIdSuccess = (authUser) => {
  return {
    type: GET_USER_ID_SUCCESS,
    payload: authUser
  };
};

export const getDepartments = (authUser) => {
  return {
    type: GET_DEPARTMENTS,
    payload: authUser
  };
};

export const getDepartmentsSuccess = (authUser) => {
  return {
    type: GET_DEPARTMENTS_SUCCESS,
    payload: authUser
  };
};
