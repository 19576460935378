import React, {Component} from "react";
import {Modal, Form, Input, Button} from 'antd';

const { TextArea } = Input;
const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends Component {
    render() {
      const formItemLayout = {
        labelCol: {
          xs: {span: 24},
        },
        wrapperCol: {
          xs: {span: 24},
        },
      };

      const { getFieldDecorator } = this.props.form;
      return (
        <Modal
          visible={this.props.visible}
          title={this.props.title}
          onCancel={this.props.onCancel}
          onOk={this.props.onCreate}
          maskClosable={false}
          footer={[
            <Button key="submit" loading={this.props.confirmLoading} onClick={this.props.onCreate}>
              Save
            </Button>,
            <Button key="cancel" onClick={this.props.onCancel}>
              Cancel
            </Button>
          ]}
        >
          <Form labelAlign="left">
            <Form.Item
            {...formItemLayout}
            label="What you did today">
              {getFieldDecorator('performedTask', {
                initialValue: this.props.taskData ? this.props.taskData.check_in_task && (this.props.taskData.employee_attendance_id === this.props.employeeAttendanceId) ? this.props.taskData.check_in_task : this.props.taskData.check_out_tomorrow_task : null,
                rules: [{ required: true, message: 'Required!' }],
              })(
                <TextArea placeholder= "What you did today?"/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Work Plan for next working day">
              {getFieldDecorator('nextTasks', {
                rules: [{ required: true, message: 'Required!' }],
              })(
                <TextArea placeholder= "Work Plan for next working day"/>
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

class ScheduleModal extends React.Component {

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onOk(values, 'checkout');
    });
  }

  render() {
    return (
        <CollectionCreateForm
          title={this.props.title}
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.visible}
          onCreate={this.handleCreate}
          onCancel={this.props.onCancel}
          employeeAttendanceId={this.props.employeeAttendanceId}
          taskData={this.props.taskData}
          confirmLoading={this.props.confirmLoading}
        />
    );
  }
}

export default ScheduleModal;
