import React, {Component} from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";

import Auxiliary from "util/Auxiliary";
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import UserProfile from "./UserProfile";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import {connect} from "react-redux";
import moment from 'moment';
import isElectron from 'is-electron';

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

let time = 0, timer='', status;
const Icon = ({src}) => (
  <img className="icon" src={src}  style={{marginRight: '20px', minWidth: '20px', width: 20}}/>
);

function importAll(r) {
  let images = {};
  r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
  return images;
}

const images = importAll(require.context('../../assets/images/icons/sidebar', false, /\.(png|jpe?g|svg)$/));

class SidebarContent extends Component {
  state={
    timer: '',
  }
  getNoHeaderClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      return "gx-no-header-notifications";
    }
    return "";
  };
  getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  timerTitle = () => {
    if(this.props.currentTimer) {
      time = moment.utc() - moment.utc(this.props.currentTimer.start);
      time = time+1000;
      timer = ((Math.floor(moment.duration(time).asHours()) < 10) ? `0${Math.floor(moment.duration(time).asHours())}` : Math.floor(moment.duration(time).asHours())) + moment.utc(time).format(":mm:ss");
      this.setState({...this.state, timer: timer});
      status = setTimeout(this.timerTitle, 1000);
    }
  }

  componentWillReceiveProps (nextProps) {
    if(nextProps.currentTimer) {
      this.timerTitle();
    } else {
      time=0;
      clearTimeout(status);
    }
    if(nextProps.joyride) {
      this.setState({...this.state,
        steps: [
          {
            target: nextProps.joyride,
            content: 'Click Here to continue',
            disableBeacon: true
          },
        ]
      })
    }
  }

  render() {
    let companyLinks = '';
    if(this.props.companyLinks) {
      companyLinks = this.props.companyLinks.split(',');
    }
    const {themeType, navStyle, pathname} = this.props;
    const selectedKeys = pathname.substr(1);
    let active = this.props.currentEmployee && this.props.currentEmployee.role && (this.props.currentEmployee.role.permissions.includes("edit_company") || this.props.currentEmployee.role.permissions.includes("manage_company"));
    const defaultOpenKeys = selectedKeys.split('/')[1];
    return (
      <Auxiliary>
        <SidebarLogo/>
        <div className="gx-sidebar-content">
          <div className={`gx-sidebar-notifications ${this.getNoHeaderClass(navStyle)}`}>
            <UserProfile/>
          </div>
          {isElectron() ? '' :
          <CustomScrollbars className="gx-layout-sider-scrollbar">
            {this.props.joyride ?
            <Joyride
              steps={this.state.steps}
              callback={this.handleJoyrideCallback}
              spotlightClicks={true}
              styles={{
                options: {
                  zIndex: 10000,
                },
              }}
            /> : null}
            <Menu
              defaultOpenKeys={[defaultOpenKeys]}
              selectedKeys={[selectedKeys]}
              theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
              mode="inline">
              <MenuItemGroup key="you" className="gx-menu-group" title="You">
                <Menu.Item key="dashboard">
                  <Link to="/dashboard"><Icon src={images['dashboard.svg']}/>
                  <IntlMessages id="sidebar.dashboard"/></Link>
                </Menu.Item>
                <Menu.Item key="requests">
                  <Link to="/requests"><Icon src={images['request.svg']}/>
                    <span>Requests</span></Link>
                </Menu.Item>
                <Menu.Item key="calendar">
                  <Link to="/calendar"><Icon src={images['calendar.svg']}/>
                  <IntlMessages id="sidebar.calendar"/></Link>
                </Menu.Item>
                {this.props.companyLinks ?
                  <SubMenu key="links" title={<span><Icon src={images['link.svg']}/><span>Links</span></span>}>
                    {companyLinks.map((link, index) => {
                      let data = link.split('|');
                      return (
                        <Menu.Item key={data[1] ? data[1] : ''}>
                          <a href={`${data[1] ? data[1].replace(/ /g,'') : ''}`} target="_blank" rel="noopener">{data[0]}</a>
                        </Menu.Item>
                      )
                    })}
                  </SubMenu>
                : ''}
              </MenuItemGroup>
              <MenuItemGroup key="your_app" className="gx-menu-group" title="Your Apps">
                <Menu.Item key="timer">
                  <Link to="/timer"><Icon src={images['timer.svg']}/>
                  <span>{(selectedKeys !== 'timer' && time !== 0) ? this.state.timer : 'Timer'}</span></Link>
                </Menu.Item>
                {active ?
                <Menu.Item key="invoice">
                  <Link to="/invoice"><Icon src={images['invoice.svg']}/>
                  <span>Invoice</span></Link>
                </Menu.Item> : ''}
                {active ?
                <Menu.Item key="payroll">
                  <Link to="/payroll"><Icon src={images['payroll.svg']}/>
                  <span>Payroll</span></Link>
                </Menu.Item> : ''}
                {active ?
                <Menu.Item key="ats">
                  <Link to="/ats"><Icon src={images['ats.svg']}/>
                  <span>Applicant Tracking</span></Link>
                </Menu.Item> : ''}
                {active ?
                <Menu.Item key="survey">
                  <Link to="/survey"><Icon src={images['survey.svg']}/>
                  <span>Survey</span></Link>
                </Menu.Item> : ''}
              </MenuItemGroup>
              <MenuItemGroup key="your_company" className="gx-menu-group" title="Your Company">
                <Menu.Item key="employees" className="employees">
                  <Link to="/employees"><Icon src={images['employee.svg']}/>
                  <span>Employees</span></Link>
                </Menu.Item>
                <Menu.Item key="projects" className="projects">
                  <Link to="/projects"><Icon src={images['project.svg']}/>
                  <span>Projects</span></Link>
                </Menu.Item>
                <Menu.Item key="clients" className="clients">
                  <Link to="/clients"><Icon src={images['client.svg']}/>
                  <span>Clients</span></Link>
                </Menu.Item>
                <Menu.Item key="events" className="events">
                  <Link to="/events"><Icon src={images['event.svg']}/>
                  <span>Events</span></Link>
                </Menu.Item>
                <SubMenu key="reports" title={<span><Icon src={images['report.svg']}/><span>Reports</span></span>}>
                  <Menu.Item key="work_reports">
                    <Link to="/work_reports">Work</Link>
                  </Menu.Item>
                  <Menu.Item key="attendance_report">
                    <Link to="/attendance_report">Attendance</Link>
                  </Menu.Item>
                </SubMenu>
                <SubMenu key="settings" title={<span><Icon src={images['setting.svg']}/><span>Settings</span></span>}>
                  <Menu.Item key="general">
                    <Link to="/general">General</Link>
                  </Menu.Item>
                  <Menu.Item key="timeoff">
                    <Link to="/timeoff">Time Off</Link>
                  </Menu.Item>
                  {active ?
                  <Menu.Item key="biometrics">
                    <Link to="/biometrics">Biometrics</Link>
                  </Menu.Item> : ''}
                  {active ?
                  <Menu.Item key="custom_fields">
                    <Link to="/custom_fields">Custom Fields</Link>
                  </Menu.Item> : ''}
                  {active ?
                  <Menu.Item key="payroll_settings">
                    <Link to="/payroll_settings">Payroll</Link>
                  </Menu.Item> : ''}
                </SubMenu>
              </MenuItemGroup>
              <MenuItemGroup key="support" className="gx-menu-group" title="Support">
                <Menu.Item key="knowledge_base">
                  <a href="http://knowledgebase.cutehr.io/" target="_blank"><Icon src={images['knowledgebase.svg']}/>
                  <span>Knowledge Base</span></a>
                </Menu.Item>
                <Menu.Item key="contact_us">
                  <a href="https://cutehr.io/contact" target="_blank"><Icon src={images['contact.svg']}/>
                  <span>Contact Us</span></a>
                </Menu.Item>
              </MenuItemGroup>
              {!process.env.REACT_APP_BUILD_TIME ? null :
                <MenuItemGroup key="dev-info" className="gx-menu-group" title="Build Info">
                  <Menu.Item key="version">
                    {process.env.REACT_APP_BUILD_TIME}
                  </Menu.Item>
                </MenuItemGroup>
              }
            </Menu>
          </CustomScrollbars>}
        </div>
      </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({settings, company}) => {
  const {currentEmployee, currentTimer, companyLinks, joyride} = company;
  const {navStyle, themeType, locale, pathname} = settings;
  return {currentEmployee, navStyle, themeType, locale, pathname, currentTimer, companyLinks, joyride}
};
export default connect(mapStateToProps)(SidebarContent);
