import React, {Component} from "react";
import {Modal, Form, Input, Select, Col, Row, Button} from 'antd';

const Option = Select.Option;
const {TextArea} = Input;
const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends Component {
    render() {
      const formItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 6},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 18},
        },
      };
      const inlineItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 12},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 12},
        },
      };

      const { getFieldDecorator } = this.props.form;
      return (
        <Modal
          visible={this.props.visible}
          title={this.props.title}
          onCancel={this.props.onCancel}
          onOk={this.props.onCreate}
          width={600}
          footer={[
            <Button key="submit" loading={this.props.confirmLoading} onClick={this.props.onCreate}>
              {this.props.title}
            </Button>,
            <Button key="cancel" onClick={this.props.onCancel}>
              Cancel
            </Button>
          ]}
        >
          <Form >
            <Form.Item
            {...formItemLayout}
            label="Field Name">
              {getFieldDecorator('customfield_name', {
                initialValue: this.props.value.name,
                rules: [{ required: true, message: 'Please input the Custom Field Name!' }],
              })(
                <Input placeholder= "Custom Field Name"/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Description">
              {getFieldDecorator('description', {
                initialValue: this.props.value.description,
              })(
                <TextArea placeholder= "Custom Field Description"/>
              )}
            </Form.Item>
            <Row gutter={24}>
              <Col span={12} style={{ display:'block'}}>
                <Form.Item
                {...inlineItemLayout}
                label="Field Type">
                  {getFieldDecorator('customfield_type', {
                    initialValue: this.props.value.type,
                    rules: [{ required: true, message: 'Please input the Custom Field Type!' }],
                  })(
                    <Select placeholder="Select Type" showAction={['click', 'focus']}>
                      <Option value="string">Text</Option>
                      <Option value="paragraph">Paragraph</Option>
                      <Option value="date">Date</Option>
                      <Option value="numeric">Number</Option>
                      <Option value="file">File</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12} style={{ display:'block'}}>
                <Form.Item
                {...inlineItemLayout}
                label="Required">
                  {getFieldDecorator('required', {
                    initialValue: (this.props.value.mandatory || this.props.value.mandatory == 0) ? (this.props.value.mandatory).toString() : undefined,
                    rules: [{ required: true, message: 'Select from below!' }],
                  })(
                    <Select placeholder="Select..." showAction={['click', 'focus']}>
                      <Option value="1">Yes</Option>
                      <Option value="0">No</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={24}>
              <Col span={12} style={{ display:'block'}}>
                <Form.Item
                {...inlineItemLayout}
                label="Visible To">
                  {getFieldDecorator('visible_to', {
                    initialValue: this.props.value.visible_to,
                    rules: [{ required: true, message: 'Select the visibility!' }],
                  })(
                    <Select placeholder="Select Visibility" showAction={['click', 'focus']}>
                      <Option value="everyone">Everyone</Option>
                      <Option value="admin">Admin</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12} style={{ display:'block'}}>
                <Form.Item
                {...inlineItemLayout}
                label="Editable">
                  {getFieldDecorator('editable', {
                    initialValue: this.props.value.editable,
                    rules: [{ required: true, message: 'Select Editable!' }],
                  })(
                    <Select placeholder="Select..." showAction={['click', 'focus']}>
                      <Option value="admin">Admin</Option>
                      <Option value="employee">Employee</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      );
    }
  }
);

class EmployeeCustomFieldsModal extends React.Component {

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onOk(values, this.props.value.id);
    });
  }

  render() {
    return (
      <div>

        <CollectionCreateForm
          title={this.props.title}
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          onCreate={this.handleCreate}
          value={this.props.value}
          confirmLoading={this.props.confirmLoading}
        />

      </div>
    );
  }
}

export default EmployeeCustomFieldsModal;
