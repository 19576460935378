import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import {List, Button, Alert, Card, Row, Col} from "antd";
import Cookies from "universal-cookie";
import axios from "axios";
import {switchCompany, setDefaultCompany, setTimerDescriptionRequired} from "appRedux/actions/Company";
import {getUserWorkspaceData} from "appRedux/actions/Auth";
import {API_URL_V1} from '../../../constants';
import CreateWorkspace from './CreateWorkspace'

const log = (type) => console.log.bind(console, type);

function validate(formData, errors){
  if (formData.password !== formData.confirm_password) {
    errors.confirm_password.addError("Passwords doesn't match");
  }
  return errors;
}

class Workspaces extends Component {

  state={
    errormsg: '',
    successmsg: '',
    companies: [],
    companiesLoading: false,
    redirectToReferrer: false,
    showCreate: false
  };

	getCompanies = () => {
      this.setState({...this.state, companiesLoading: true})
      const {authUser, currentCompany} = this.props;
      axios.get(`${API_URL_V1}/workspaces`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(({data}) => {
        const companies = data.data;
        if(!currentCompany && companies.length > 0){
          const company = companies[0];
          localStorage.setItem('id', company.id);
          this.props.switchCompany(company);
          this.props.setDefaultCompany(company.id);
          this.setState({...this.state, companiesLoading: false, redirectToReferrer: true})
          return;
        }
        this.setState({...this.state, companies: companies, companiesLoading: false})
      })
      .catch(error => {
          console.log(error);
          this.setState({...this.state, companiesLoading: false})
        });
  }
  switchCompany = (company) => {
    const {authUser} = this.props;
    console.log(company);
    localStorage.setItem('id', company.id);
    this.props.switchCompany(company);
    this.props.setDefaultCompany(company.id);
    this.setState({...this.state,
      showCreate: false,
      redirectToReferrer: true
    },() => {
      const {authUser, currentCompany} = this.props;
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/settings`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(settingData => {
        this.props.setTimerDescriptionRequired(settingData.data.data.timer_desc_required);
      })
    });
  }



  componentDidMount () {
  	this.getCompanies();
  }

  showCreateModel = () => {
	  console.log("Show model")
    this.setState({...this.state,
      showCreate: true
    });
  }

  hideCreateModel = () => {
    this.setState({...this.state,
      showCreate: false
    });
  }


  render() {
    let { from } = this.props.location.state || { from: { pathname: "/" } };
    let { redirectToReferrer } = this.state;
    if (redirectToReferrer) return <Redirect to={from} />;

    const companyItem = item => {

      let logo = "";
      if (item.logo) {
        logo = `${API_URL_V1}${item.logo}`;
      }
      let disabled = this.props.currentCompany && item.id == this.props.currentCompany.id;
      return (
        <List.Item
              actions={[<Button size="small" disabled={disabled} onClick ={() => {this.switchCompany(item)}}>Switch</Button>]}
              extra={<img width={80} alt="logo" src={logo} />}
              >
                <div><h2 className="h4 gx-mr-1 gx-mb-1">{item.name}</h2></div>
        </List.Item>
      )};

    return (
        <Row>
          <CreateWorkspace visible={this.state.showCreate} handleCancel={this.hideCreateModel} onCreate={this.switchCompany}/>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Card className="gx-card" title="Workspaces">
              <div className="gx-mb-5">
                <Button style={{float: "right"}} onClick={this.showCreateModel} value="Add">Add Workspace</Button>
              </div>
              <List className="gx-mb-4"
		          itemLayout="vertical"
		            dataSource={this.state.companies}
		            loading={this.state.companiesLoading}
		            renderItem={companyItem}
                    locale={{emptyText: "No Workspace, Create one"}}
		          />

            </Card>
          </Col>
        </Row>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser, user} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, user, currentCompany, currentEmployee}
};
export default connect(mapStateToProps, {
  switchCompany,
  getUserWorkspaceData,
  setDefaultCompany,
  setTimerDescriptionRequired,
})(Workspaces);
