import React from "react";
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";

const COLORS = ['#dee8f8', '#8980e7'];

const LeavesChart = (leaveStats) => {
  let result = Object.keys(leaveStats.data).map(function(key) {
    if(key === "days_all" || key === "days_adjusted") {
      return 0
    }
    return {name: key.replace(/_/g, ' '), value: leaveStats.data[key]};
  });
  return (
      <div className="gx-py-3">
        <ResponsiveContainer width="100%" height={85}>
          <PieChart>
            <Tooltip wrapperStyle={{backgroundColor: '#ccc', zIndex: 1, opacity: 0.8}}/>
            <Pie
              data={result}
              dataKey="value"
              cx="50%"
              cy="50%"
              innerRadius={20}
              outerRadius={40}
            >
              {
                result.map((entry, index) => <Cell key={index} fill={COLORS[index % COLORS.length]}/>)
              }
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
  );
};
export default LeavesChart;
