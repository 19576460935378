import {combineReducers} from "redux";
import {routerReducer} from "react-router-redux";
import Settings from "./Settings";
import Auth from "./Auth";
import EmployeeData from "./EmployeeData";
import Company from "./Company";


const reducers = combineReducers({
  routing: routerReducer,
  settings: Settings,
  auth: Auth,
  employeeData: EmployeeData,
  company: Company,
});

export default reducers;
