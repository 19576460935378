import React, {Component} from "react";
import {Table, Row, Col} from "antd";
import moment from "moment";

class WeeklyReportTable extends Component {

  bodyWrapper = (props) => {
    let totalTimeEntry = [0,0,0,0,0,0,0], totalTime = 0;
    this.props.data.forEach((entry, index) => {
      entry.seconds.forEach((time, index) => {
        totalTimeEntry[index] += time;
      })
    })
    totalTimeEntry.forEach((timeEntry, index) => {
      totalTime += timeEntry;
    })
    return (
      <tbody {...props}>
        <React.Fragment>
          {props.children}
          <tr className="ant-table-row">
            <td>Total</td>
            <td>{(totalTimeEntry[0] !== 0) ? Math.floor(moment.duration(totalTimeEntry[0]*1000).asHours()) + moment.utc(totalTimeEntry[0]*1000).format(":mm:ss") : ''}</td>
            <td>{(totalTimeEntry[1] !== 0) ? Math.floor(moment.duration(totalTimeEntry[1]*1000).asHours()) + moment.utc(totalTimeEntry[1]*1000).format(":mm:ss") : ''}</td>
            <td>{(totalTimeEntry[2] !== 0) ? Math.floor(moment.duration(totalTimeEntry[2]*1000).asHours()) + moment.utc(totalTimeEntry[2]*1000).format(":mm:ss") : ''}</td>
            <td>{(totalTimeEntry[3] !== 0) ? Math.floor(moment.duration(totalTimeEntry[3]*1000).asHours()) + moment.utc(totalTimeEntry[3]*1000).format(":mm:ss") : ''}</td>
            <td>{(totalTimeEntry[4] !== 0) ? Math.floor(moment.duration(totalTimeEntry[4]*1000).asHours()) + moment.utc(totalTimeEntry[4]*1000).format(":mm:ss") : ''}</td>
            <td>{(totalTimeEntry[5] !== 0) ? Math.floor(moment.duration(totalTimeEntry[5]*1000).asHours()) + moment.utc(totalTimeEntry[5]*1000).format(":mm:ss") : ''}</td>
            <td>{(totalTimeEntry[6] !== 0) ? Math.floor(moment.duration(totalTimeEntry[6]*1000).asHours()) + moment.utc(totalTimeEntry[6]*1000).format(":mm:ss") : ''}</td>
            <td>{Math.floor(moment.duration(totalTime*1000).asHours()) + moment.utc(totalTime*1000).format(":mm:ss")}</td>
          </tr>
        </React.Fragment>
      </tbody>
    );
  };
  render() {
    const columns = [{
        title: 'Title',
        render: (text, record) => {
          return (
          <span style={{color: record.project_id ? "#4bc800" : record.employee_id ? "#cecece" : "#535353"}}>
            {record.project_id ? this.props.projectData.map((project) => {
                if(project.id === record.project_id) {
                  return project.name;
                }
              }): record.employee_id ? "Without Project" : "Total"
            }
          </span>
        )},
        footer: "dffsdf",
        }, {
          title: moment(this.props.date).startOf('week').format("MM/DD"),
          render: (text, record) => {
            return(
              <span>
                {(record.seconds[0] !== 0) ? Math.floor(moment.duration(record.seconds[0]*1000).asHours()) + moment.utc(record.seconds[0]*1000).format(":mm:ss") : ''}
              </span>
            )
          }
        }, {
          title: moment(this.props.date).startOf('week').weekday(1).format("MM/DD"),
          render: (text, record) => {
            return(
              <span>
                {(record.seconds[1] !== 0) ? Math.floor(moment.duration(record.seconds[1]*1000).asHours()) + moment.utc(record.seconds[1]*1000).format(":mm:ss") : ''}
              </span>
            )
          }
        }, {
          title: moment(this.props.date).startOf('week').weekday(2).format("MM/DD"),
          render: (text, record) => {
            return(
              <span>
                {(record.seconds[2] !== 0) ? Math.floor(moment.duration(record.seconds[2]*1000).asHours()) + moment.utc(record.seconds[2]*1000).format(":mm:ss") : ''}
              </span>
            )
          }
        }, {
          title: moment(this.props.date).startOf('week').weekday(3).format("MM/DD"),
          render: (text, record) => {
            return(
              <span>
                {(record.seconds[3] !== 0) ? Math.floor(moment.duration(record.seconds[3]*1000).asHours()) + moment.utc(record.seconds[3]*1000).format(":mm:ss") : ''}
              </span>
            )
          }
        }, {
          title: moment(this.props.date).startOf('week').weekday(4).format("MM/DD"),
          render: (text, record) => {
            return(
              <span>
                {(record.seconds[4] !== 0) ? Math.floor(moment.duration(record.seconds[4]*1000).asHours()) + moment.utc(record.seconds[4]*1000).format(":mm:ss") : ''}
              </span>
            )
          }
        }, {
          title: moment(this.props.date).startOf('week').weekday(5).format("MM/DD"),
          render: (text, record) => {
            return(
              <span>
                {(record.seconds[5] !== 0) ? Math.floor(moment.duration(record.seconds[5]*1000).asHours()) + moment.utc(record.seconds[5]*1000).format(":mm:ss") : ''}
              </span>
            )
          }
        }, {
          title: moment(this.props.date).endOf('week').format("MM/DD"),
          render: (text, record) => {
            return(
              <span>
                {(record.seconds[6] !== 0) ? Math.floor(moment.duration(record.seconds[6]*1000).asHours()) + moment.utc(record.seconds[6]*1000).format(":mm:ss") : ''}
              </span>
            )
          }
        }, {
          title: "Total",
          render: (text, record) => {
            let totalTime = 0;
            record.seconds.forEach((time, index) => {
              totalTime += time;
            })
            return(
              <span>
                {Math.floor(moment.duration(totalTime*1000).asHours()) + moment.utc(totalTime*1000).format(":mm:ss")}
              </span>
            )
          }
        }
      ];
    return(
      <div>
        {(this.props.data.length > 0) ?
          <Table
            className="gx-table-responsive" 
            components={{ body: { wrapper: this.bodyWrapper } }}
            dataSource={this.props.data}
            columns={columns}
            pagination={false}
            loading={this.props.loading}
          /> :
          <span> No Data to display</span>
        }
      </div>
    )
  }
}

export default WeeklyReportTable;
