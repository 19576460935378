import React from "react";
import {Tag} from "antd";
import {API_URL_V1} from '../../../constants';
import moment from 'moment';

function PropertiesItemCard({data, approve}) {
  // console.log(data);
  const {id, user, employee_id, description, name, start_on, finish_on, type, more, approved, rejected, created_at} = data;
  let start = moment(start_on);
  let end = moment(finish_on);

  return (
    <div className="gx-media gx-featured-item">
      <div className="gx-featured-thumb">
        <img className="gx-rounded-lg" width="120" src={`${API_URL_V1}${user.avatar}`} alt="..."/>
      </div>
      <div className="gx-media-body gx-featured-content">
        <div className="gx-featured-content-left">

          <Tag className="gx-rounded-xs" color={`#${type.color}`}>{type.name}</Tag>
          <h4 className="gx-mb-2">{user.first_name} {user.last_name}</h4>

          <p className="gx-text-grey gx-mb-1">{description}</p>

          <div className="ant-row-flex">
            <p className="gx-mr-3 gx-mb-1"><span className="gx-text-grey">From:</span> {start_on}</p>
            <p className="gx-mr-3 gx-mb-1"><span className="gx-text-grey">To:</span> {finish_on}</p>
          </div>
          <div className="ant-row-flex">
            <p className="gx-text-grey gx-mb-1">
              <i className={`icon icon-datepicker gx-fs-xs gx-mr-2 gx-d-inline-flex gx-vertical-align-middle`}/>{moment.utc(created_at).fromNow()}
            </p>
          </div>
        </div>
        <div className="gx-featured-content-right">
          <div>
            <h2 className="gx-text-primary gx-mb-1 gx-font-weight-medium">{moment.duration(end.diff(start)).asDays() + 1}</h2>
            <p className="gx-text-grey gx-fs-sm">days</p>
          </div>
          {approved ? null :
          <span className="gx-text-primary gx-text-truncate gx-mt-auto gx-mb-0 gx-pointer" onClick={()=> {approve(data, {approved: true, rejected: false})}}>Approve <i
            className={`icon icon-check gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle`}/></span>
          }
          {rejected ? null : <span className="gx-text-danger gx-text-truncate gx-mt-auto gx-mb-0 gx-pointer" onClick={()=> {approve(data, {approved: false, rejected: true})}}>Reject <i
            className={`icon icon-close gx-fs-xxl gx-ml-2 gx-d-inline-flex gx-vertical-align-middle`}/></span>
          }
            </div>

      </div>
    </div>
  );
}

export default PropertiesItemCard;
