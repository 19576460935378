import React, {Component} from "react";
import {Modal, Form, Input, Button} from 'antd';

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends Component {
    render() {
      const formItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 8},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 16},
        },
      };

      const { getFieldDecorator } = this.props.form;
      return (
        <Modal
          visible={this.props.visible}
          title={this.props.title}
          onCancel={this.props.onCancel}
          onOk={this.props.onCreate}
          footer={[
            <Button key="submit" loading={this.props.confirmLoading} onClick={this.props.onCreate}>
              {this.props.title}
            </Button>,
            <Button key="cancel" onClick={this.props.onCancel}>
              Cancel
            </Button>
          ]}
        >
          <Form >
            <Form.Item
            {...formItemLayout}
            label="Designation Name">
              {getFieldDecorator('designation_name', {
                initialValue: this.props.value,
                rules: [{ required: true, message: 'Please input the Designation Name!' }],
              })(
                <Input placeholder= "Designation Name"/>
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

class DesignationModal extends React.Component {

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onOk(values, this.props.value.id);
    });
  }

  render() {
    return (
      <div>

        <CollectionCreateForm
          title={this.props.title}
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          onCreate={this.handleCreate}
          value={this.props.value.name}
          confirmLoading={this.props.confirmLoading}
        />

      </div>
    );
  }
}

export default DesignationModal;
