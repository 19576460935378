import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Card, Table, Modal, Divider, message} from "antd";
import Auxiliary from "../../../../util/Auxiliary";
import CircularProgress from "components/CircularProgress/index";
import LeavesPolicyModal from "./LeavesPolicyModal";
import {API_URL_V1} from "../../../../constants";
import axios from "axios";

let disabled;

class LeavesPolicy extends Component {

  constructor(props){
    super(props)
    this.state = {
      visible: false,
      title: '',
      leavePolicies: [],
      loading: true,
      editData: '',
      confirmLoading: false,
      deleteVisible: false,
      deleteLoading: false,
      record: '',
    };
  }

  getLeavePolicies = () => {
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/leaves/policies`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(leavePolicyData => {
      this.setState({...this.state, leavePolicies: leavePolicyData.data.data, loading: false})
    })
    .catch(error => error);

  }
  componentDidMount() {
    this.getLeavePolicies();
  }

  onClick = () => {
    this.setState({...this.state, visible: true, title: "Add Leave Policy", editData: ''});
  }

  handleCancel = () => {
    this.setState({...this.state, visible: false});
  }

  edit = (record, e) => {
    e.preventDefault();
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/leaves/policies/${record.id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(editData => {
      this.setState({...this.state, visible: true, title: "Edit Leave Policy", editData: editData.data.data});
    })
    .catch(error => error);
  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser, currentCompany} = this.props;
    axios.delete(`${API_URL_V1}/workspaces/${currentCompany.id}/leaves/policies/${record.id}`, {
      params: {
        workspace_id: currentCompany.id,
        department_id: record.id,
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(leavespolicyData => {
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      message.success("Leave Policy Deleted Successfully");
      this.getLeavePolicies();
    })
    .catch(error => {
      message.error("Failed to Delete");
    })
  }

  handleOk = (values, id) => {
    this.setState({...this.state, confirmLoading: true});
    const {authUser, currentCompany} = this.props;
    if(!id){
      axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/leaves/policies`, {
        name: values.leavespolicy_name,
        description: values.policy_description,
        holiday_allowance: values.holiday_allowance,
        unlimited: false,
        days_type: values.days_type,
        expire_in_months: values.expires_in,
        carry_over_days: values.accured_days,
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(leavespolicyData => {
        let leavePolicies = this.state.leavePolicies;
        leavePolicies.push(leavespolicyData.data.data);
        message.success("New Leave Policy Added");
        this.setState({...this.state, leavePolicies: leavePolicies, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Add Leave Policy");
        this.setState({...this.state, confirmLoading: false});
      })
    } else {
      axios.put(`${API_URL_V1}/workspaces/${currentCompany.id}/leaves/policies/${id}`, {
        name: values.leavespolicy_name,
        description: values.policy_description,
        holiday_allowance: values.holiday_allowance,
        unlimited: false,
        days_type: values.days_type,
        expire_in_months: values.expires_in,
        carry_over_days: values.accured_days,
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(leavespolicyData => {
          let leavePolicies = this.state.leavePolicies;
          leavePolicies.forEach((leavePolicy, index) => {
            if(leavePolicy.id === leavespolicyData.data.data.id) {
              leavePolicies[index] = {...leavePolicy, ...leavespolicyData.data.data};
            }
          })
          message.success("Leaves Policy Editted Successfully");
        this.setState({...this.state, leavePolicies: leavePolicies, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Edit");
        this.setState({...this.state, confirmLoading: true});
      })
    }
  }

  render() {
    disabled = (this.props.currentEmployee && this.props.currentEmployee.role) ? this.props.currentEmployee.role.permissions.includes("edit_company") : '';
    const columns = [{
        title: 'Name',
        dataIndex: 'name',
      }, {
        title: 'People',
        dataIndex: 'employees_count'
      }, {
        title: 'Actions',
        key: 'x',
        render: (text, record) => (
          <span>
            {disabled ?
            <span className="gx-link" onClick={(e) => {this.edit(record, e)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span> : <span></span>}

            {disabled ?
              <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><Divider type="vertical"/><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span> :
              <span></span>
            }
          </span>
        )
      }];
    return(
      <div>
      <Modal
        visible={this.state.deleteVisible}
        title="Delete Leave Policy?"
        onOk={(e) => this.deleteRecord(this.state.record, e)}
        onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
        confirmLoading={this.state.deleteLoading}
        footer={[
          <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
            OK
          </Button>,
          <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
            Cancel
          </Button>
        ]}
      >
        <p>Are you Sure you want to delete this Leave Policy?</p>
      </Modal>
      {this.state.loading ?
      <CircularProgress/> :
      <div>
      {this.state.visible ?
        <LeavesPolicyModal title={this.state.title} value={this.state.editData} visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel} confirmLoading={this.state.confirmLoading}/> : null }
      <Auxiliary>
        <div style={{paddingBottom: "2.5rem"}}>
          <Button style={{float: "right"}} disabled={!disabled} onClick={this.onClick} value="Add">Add Leave Policy</Button>
        </div>
        <Table className="gx-table-responsive" columns={columns} dataSource={this.state.leavePolicies} pagination={false}/>
      </Auxiliary>
      </div>}
      </div>
    );
  };
}
const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentEmployee, currentCompany} = company;
  return {authUser, currentEmployee, currentCompany}
};

export default connect(mapStateToProps)(LeavesPolicy);
