import React, {Component} from "react";
import {withRouter} from "react-router";
import {Card, Button, Form, Input, Switch, Select, Row, Col, Upload, Icon, message} from "antd";
import Auxiliary from "../util/Auxiliary";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../constants";
import axios from "axios";

const {TextArea} = Input;
const { Option } = Select;

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

class ApplyForm extends Component {
  state={
    jobDetails: [],
    formDetails: [],
    fileList: [],
    resume: '',
    loading: true,
  };

  componentDidMount () {
    axios.get(`${API_URL_V1}/public/workspaces/${this.props.job[0].company_id}/ats/openings/${this.props.job[0].id}`, {
    })
    .then(jobDetailsData => {
      this.setState({...this.state, jobDetails: jobDetailsData.data, formDetails: jobDetailsData.data.form_fields, loading: false});
    })
    .catch(error => error)
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        let profile_data = [];
        this.state.formDetails.map((formDetail, index) => {
          let data = {};
          data.name = formDetail.name;
          data.value = formDetail.type === "select" ? values[formDetail.name] ? (values[formDetail.name]).toString() : null : values[formDetail.name];
          profile_data.push(data);
        })
        this.setState({...this.state, formProcessing: true});
        axios.post(`${API_URL_V1}/public/ats/openings/${this.props.job[0].id}/apply`, {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          phone: values.phone,
          gender: values.gender ? values.gender.toString() : null,
          resume: {file_name: this.state.fileName, content: this.state.resume},
          profile_data,
        })
        .then(applyData => {
          message.success("Applied Successfully");
          this.setState({...this.state, formProcessing: false});
          this.props.history.push({pathname: '/thanks_for_applying'});
        })
        .catch(error => {
          message.error("Failed to process");
          this.setState({...this.state, formProcessing: false});
        })
      }
    });
  };

  handleChange = info => {
    console.log(info)
    getBase64(info.fileList[0].originFileObj, imageUrl =>
      this.setState({...this.state,
        fileName: info.fileList[0].name,
        resume: imageUrl,
      }),
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { fileList } = this.state;
    const props = {
          onRemove: (file) => {
            this.setState((state) => {
              return {
                fileList: [],
              };
            });
          },
          beforeUpload: (file) => {
            this.setState(state => ({
              fileList: [file],
            }));
            return false;
          },
          fileList,
        };
    const formItemLayout = {
      labelCol: {
        xs: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 20 },
      },
    };
    const inlineItemLayout = {
      labelCol: {
        xs: {span: 24},
        sm: {span: 8},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 16},
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 20,
          offset: 4,
        },
      },
    };
    return (
      <Auxiliary>
        {this.state.loading ? <CircularProgress /> :
          <Card className="gx-opening-card" title={`Apply for ${this.props.job[0].title}`} bordered={false} style={{height: "100%"}}>
            <Form {...formItemLayout} onSubmit={this.handleSubmit}>
              <Row gutter={24}>
                <Col span={12} style={{ display:'block'}}>
                  <Form.Item
                  {...inlineItemLayout}
                  label="First Name">
                    {getFieldDecorator('first_name', {
                      rules: [{ required: true, message: 'Please Enter the First Name!' }],
                    })(
                      <Input placeholder= "First Name"/>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12} style={{ display:'block'}}>
                  <Form.Item
                  {...inlineItemLayout}
                  label="Last Name">
                    {getFieldDecorator('last_name', {
                      rules: [{ required: true, message: 'Please Enter the Last Name!' }],
                    })(
                      <Input placeholder= "Last Name"/>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12} style={{ display:'block'}}>
                  <Form.Item
                  {...inlineItemLayout}
                  label="Email Address">
                    {getFieldDecorator('email', {
                      rules: [{type: 'email', message: 'The input is not valid E-mail!'},{ required: true, message: 'Please Enter the Email Address!' }],
                    })(
                      <Input placeholder= "Email Address"/>
                    )}
                  </Form.Item>
                </Col>
                <Col span={12} style={{ display:'block'}}>
                  <Form.Item
                  {...inlineItemLayout}
                  label="Phone No.">
                    {getFieldDecorator('phone', {
                      rules: [{ required: true, message: 'Please Enter the Phone Number!' }],
                    })(
                      <Input placeholder= "Phone Number"/>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col span={12} style={{ display:'block'}}>
                  <Form.Item
                  {...inlineItemLayout}
                  label="Gender">
                    {getFieldDecorator('gender', {
                    })(
                      <Select placeholder= "Select Gender" showAction={['click', 'focus']}>
                        <Option value="male">Male</Option>
                        <Option value="female">Female</Option>
                        <Option value="other">Other</Option>
                      </Select>
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                  {...formItemLayout}
                  label="Resume">
                  {getFieldDecorator('resume', {
                    rules: [{ required: true, message: 'Please Upload the resume!' }],
                  })(
                    <Upload {...props} onChange={this.handleChange}>
                      <Button>
                        <Icon type="upload" /> Upload Resume
                      </Button>
                    </Upload>
                  )}
              </Form.Item>

              {this.state.formDetails.map((formDetail, index) =>
               <Form.Item label={formDetail.display_name}>
                 {getFieldDecorator(`${formDetail.name}`, {
                   rules: [{required: formDetail.is_required, message: 'This field is required'}],
                 })(
                    formDetail.type === 'text' ?
                    <Input placeholder="Please answer this question here" />
                    : formDetail.type === 'textarea' ?
                    <TextArea placeholder="Please answer this question here" />
                    : formDetail.type === "switch" ?
                    <Switch />
                    : formDetail.type === "select" ?
                    <Select placeholder="Please Select">
                    {formDetail.choices.map((choice, index) => (
                      <Option value={choice}>{choice}</Option>
                    ))}
                    </Select>
                    : <Select placeholder="Please Select" mode="multiple">
                        {formDetail.choices.map((choice, index) => (
                          <Option value={choice}>{choice}</Option>
                        ))}
                      </Select>
                 )}
               </Form.Item>
             )}
             <Form.Item {...tailFormItemLayout}>
               <Button type="primary" htmlType="submit" loading={this.state.formProcessing}>
                 Apply for the Opening
               </Button>
               <Button type="default" onClick={() => {this.props.goBack()}}>
                Go Back
               </Button>
             </Form.Item>
            </Form>
          </Card>
        }
      </Auxiliary>
    )
  }
}

const WrappedApplyForm = Form.create()(ApplyForm);

export default withRouter(WrappedApplyForm);
