import React, {Component} from "react";
import {Table} from "antd";
import moment from "moment";

class TimeEntries extends Component {

  expandedRowRender = (record) => {
    const columns = [
      {
        title: 'Client',
        render: (text, record) => {
          return (
            this.props.subgroup === "client_id" ? record.id === "" ? "No Client" :
            this.props.clientData.map((client, index) => {
              if(client.id === record.id) {
                return client.name;
              }
            }) : this.props.subgroup === "employee_id" ? record.id === "" ? "No User" :
            this.props.employeeData.map((employee, index) => {
              if(employee.id === record.id) {
                return employee.first_name;
              }
            }) : this.props.subgroup === "project_id" ? record.id === "" ? "Without Project" :
            this.props.projectData.map((project, index) => {
              if(project.id === record.id) {
                return project.name;
              }
            }) : ''
          );
        },
        width: 399
      }, {
        title: 'Duration',
        render: (text, record) => {
          return (
            <span>
              {Math.floor(moment.duration(record.seconds*1000).asHours()) + moment.utc(record.seconds*1000).format(":mm:ss")}
            </span>
          )
        }
      }
    ];
    return <Table className="gx-table-responsive" columns={columns} dataSource={record} pagination={false}  showHeader={false}/>;
  };

  render() {
    const columns = [{
        title: 'Title',
        render: (text, record) => {
          return (
          <span style={{color: record.id ? "#4bc800" : "#cecece"}}>
            {this.props.group === "project_id" ? record.id ? this.props.projectData.map((project) => {
                if(project.id === record.id) {
                  return project.name;
                }
              }): "Without Project" : this.props.group === "client_id" ? record.id ? this.props.clientData.map((client) => {
                  if(client.id === record.id) {
                    return client.name;
                  }
                }) : "Without Client" : this.props.group === "employee_id" ? record.id ? this.props.employeeData.map((employee) => {
                    if(employee.id === record.id) {
                      return employee.first_name;
                    }
                  }) : "No User" : ""
              }


          </span>
        )},
        sorter: (a, b) => {
          let aName, bName;
          if (this.props.group === "project_id") {
            if(a.id) {
              this.props.projectData.map((project) => {
                if(project.id === a.id) {
                  aName = project.name;
                }
              })
            } else {
              aName = "Without Project";
            }
            if(b.id) {
              this.props.projectData.map((project) => {
                if(project.id === b.id) {
                  bName = project.name;
                }
              })
            } else {
              bName = "Without Project";
            }
          }
          if (this.props.group === "client_id") {
            if(a.id) {
              this.props.clientData.map((client) => {
                if(client.id === a.id) {
                  aName = client.name;
                }
              })
            } else {
              aName = "Without Client";
            }
            if(b.id) {
              this.props.clientData.map((client) => {
                if(client.id === b.id) {
                  bName = client.name;
                }
              })
            } else {
              bName = "Without Client";
            }
          }
          if (this.props.group === "employee_id") {
            if(a.id) {
              this.props.employeeData.map((employee) => {
                if(employee.id === a.id) {
                  aName = employee.first_name;
                }
              })
            } else {
              aName = "No User";
            }
            if(b.id) {
              this.props.employeeData.map((employee) => {
                if(employee.id === b.id) {
                  bName = employee.first_name;
                }
              })
            } else {
              bName = "No User";
            }
          }
          return aName.localeCompare(bName)
        },

        width: 400
      }, {
        title: 'Duration',
        render: (text, record) => {
          let total = 0;
          record.sub_groups.map((data, index) => {
            total = total + data.seconds;
          })
          return(
          <span>
            {Math.floor(moment.duration(total*1000).asHours()) + moment.utc(total*1000).format(":mm:ss")}
          </span>
        )},
        sorter: (a, b) => {
          let atotal = 0, btotal = 0;
          a.sub_groups.map((data, index) => {
            atotal = atotal + data.seconds;
          })
          b.sub_groups.map((data, index) => {
            btotal = btotal + data.seconds;
          })
          return atotal - btotal;
        },
      }];
    return (
      <Table
        className="gx-table-responsive" 
        columns={columns}
        dataSource={this.props.timeEntriesData}
        expandedRowRender={(record) => this.expandedRowRender(record.sub_groups)}
        pagination={false}/>
    );
  }
}

export default TimeEntries;
