import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Col, Row, Tabs} from "antd";
import LeavesPolicy from "./LeavesPolicy";
import Holidays from "./Holidays";
import LeavesTypes from "./LeavesTypes";
import Auxiliary from "../../../../util/Auxiliary";
import Breadcrumbs from "components/Breadcrumbs/index";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../../constants";
import axios from "axios";

const TabPane = Tabs.TabPane;
let workspace_id;
class CompanySettings extends Component {
  state={
    location_id: 1,
    locationData: '',
    activeTab: this.props.location.state ? this.props.location.state.activeTab : "policies",
    loading: true,
  };

  componentDidMount() {
    const {authUser} = this.props;
    workspace_id = localStorage.getItem('id');
    axios.get(`${API_URL_V1}/workspaces/${workspace_id}/me`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(userData => {
      this.setState({...this.state, location_id: userData.data.data.location_id});
      axios.get(`${API_URL_V1}/workspaces/${workspace_id}/locations/`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(locationData => {
        this.setState({...this.state, locationData: locationData.data.data, loading: false});
      })
    })
    .catch(error => error);

  }

  changeTab = activeKey => {
    console.log(activeKey);
    this.setState({
      activeTab: activeKey
    });
  };

  render() {
    return(
      <Auxiliary>
      {this.state.loading ?
        <div className="gx-loader-view">
          <CircularProgress/>
        </div> :
        <div className="gx-profile-content">
          <Breadcrumbs />
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card className="gx-card" title="Leaves Settings">
                <Tabs activeKey={this.state.activeTab} onChange={this.changeTab} type="card">
                  <TabPane tab="Policies" key="policies">
                    <LeavesPolicy changeTab={this.changeTab}/>
                  </TabPane>
                  <TabPane tab="Leave Types" key="leave_types">
                    <LeavesTypes />
                  </TabPane>
                </Tabs>
              </Card>
            </Col>
          </Row>
        </div>}
      </Auxiliary>
    );
  }
}
const mapStateToProps = ({auth}) => {
  const {authUser} = auth;
  return {authUser}
};

export default connect(mapStateToProps)(CompanySettings);
