import React, {Component} from "react";
import {Card} from "antd";
import EmployeeSurvey from "./EmployeeSurvey";
import Auxiliary from "../../../../util/Auxiliary";
import Breadcrumbs from "components/Breadcrumbs/index";

class Survey extends Component {
  render () {
    return (
      <Auxiliary>
        <Breadcrumbs />
        <div className="gx-profile-content">
          <Card className="gx-card" title="Survey">
            <EmployeeSurvey />
          </Card>
        </div>
      </Auxiliary>
    )
  }
}

export default Survey;
