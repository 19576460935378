import React, {Component} from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom'
import {Button, Card, Col, Divider, Input, Row, Table, Modal, Avatar, Badge, Select, Popover, Tag, message} from "antd";
import Auxiliary from "../../../util/Auxiliary";
import Breadcrumbs from "components/Breadcrumbs/index";
import CircularProgress from "components/CircularProgress/index";
import EmployeeModal from "./EmployeeModal";
import EnrollModal from "./EnrollEmployee";
import ImportEmployees from "./ImportEmployees";
import fingerprint from "../../../assets/images/fingerprint.gif"
import {FaCrown} from "react-icons/fa";
import axios from "axios";
import _ from "lodash";
import {API_URL_V1} from "../../../constants";
import moment from "moment";
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import {resetJoyride} from "../../../appRedux/actions/Company";

let fileDownload = require('js-file-download');
const { Option } = Select;
const Search = Input.Search;
let workspace_id;
let data =[], deviceOptions=[];

class CompanyEmployees extends Component {

  state = {
    visible: false,
    loading: true,
    pagination: {current: 1, pageSize: 15, total: 0},
    employees: [],
    designations: [],
    departments: [],
    q: null,
    confirmLoading: false,
    deleteVisible: false,
    deleteLoading: false,
    record: '',
    employee_status: '',
    enrollVisible: false,
    devices: [],
    enrollLoading: false,
    deviceCoordinate: false,
    coordinateLoading: false,
    importVisible: false,
    steps: [
        {
          target: '.add-employee-button',
          content: 'Click Here to add Employee',
        },
        {
          target: "body",
          content: "After clicking, modal will popup",
          placement: 'center',
          styles: {
            options: {
              zIndex: 9999999,
            },
          },
        },
        {
          target: ".employee-form",
          content: "Fill up the employee details",
          disableBeacon: true
        },
        {
          target: ".add-employee-form-button",
          content: "After filling the form, click here to add employee",
          disableBeacon: true
        },
      ],
  };

  getEmployees(params) {
    if(params !== "search") {
      this.setState({...this.state, loading: true});
    }
    const {authUser, currentCompany} = this.props;
    axios.all([
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/employees`, {
        params: {
          page: this.state.pagination.current, per_page: this.state.pagination.pageSize, q: this.state.q, ...params,
          employee_status: this.state.employee_status,
        },
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/designations`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/departments`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
    ])
    .then(axios.spread((employeeData, designationData, departmentData) => {
      const pagination = { ...this.state.pagination };
      pagination.pageSize = 15;
      pagination.total =employeeData.data.meta.total;
      this.setState({...this.state, pagination, employees: employeeData.data.data, designations: designationData.data.data, departments: departmentData.data.data, loading: false});
    }))
    .catch(error => error)
  }

  componentDidMount() {
    const {authUser, currentCompany, currentEmployee} = this.props;
    if(currentEmployee && currentEmployee.role && (currentEmployee.role.permissions.includes("edit_company") || currentEmployee.role.permissions.includes("manage_company"))) {
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/biometrics/devices`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(deviceData => {
        this.setState({...this.state, devices: deviceData.data.data});
      })
      .catch(error => error)
    }
    this.setState({...this.state, pagination: this.props.pagination, employees: this.props.employees, designations: this.props.designations, departments: this.props.departments, loading: false});
  }

  setSearchQuery = _.debounce((value) => {
    this.setState({...this.state, pagination: {...this.state.pagination, current: 1}, q: value}, () => {this.getEmployees('search')})
  }, 500)

  handleTableChange = (paginationData) => {
    this.setState({...this.state, pagination: paginationData}, () => {this.getEmployees()});
  }

  onClick = () => {
    this.setState({...this.state, visible: true});
  }

  handleOk = (values) => {
    this.setState({...this.state, confirmLoading: true});
    console.log(this.props.history);
    const {authUser, currentCompany} = this.props;
    axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/employees`, {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        invite: values.invite,
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(employeeData => {
      this.setState({...this.state, confirmLoading: false})
      let steps = [];
      if(this.props.joyride === ".employees") {
        steps=[{
          target: "body",
          content: "Congratulations, you have added employee successfully, now you can edit the details here",
          placement: 'center',
          disableBeacon: true
        }];
      }
      this.props.history.push({pathname: '/employees/'+employeeData.data.data.id + '/profile', state: {steps: steps}});
    })
  }

  handleCancel = () => {
    this.setState({...this.state, visible: false});
  }

  edit = (record, e) => {
    e.preventDefault();
    this.props.history.push('/employees/'+record.id+'/profile');
  }

  request = (record, e) => {
    e.preventDefault();
    this.props.history.push({pathname: '/employees/'+record.id+'/requests', state: {leavePolicyId: record.time_off_policy_id}});
  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser, currentCompany} = this.props;
    axios.delete(`${API_URL_V1}/workspaces/${currentCompany.id}/employees/${record.id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(employeeData => {
      message.success("Employee Deleted Successfully");
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      this.getEmployees();
    })
    .catch(error => {
      message.error("Failed to Delete");
    })
  }

  changeSelect = (value) => {
    this.setState({...this.state, employee_status: value},() => {this.getEmployees()});
  }

  unterminate = (record, e) => {
    e.preventDefault();
    console.log(record)
    const {authUser, currentCompany} = this.props;
    axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/employees/${record.id}/unterminate`, {}, {
      headers: {
        'Authorization': 'Bearer '+authUser,
      }
    })
    .then(employeeData => {
      this.getEmployees();
    })
    .catch(error => error);
  }

  showEnrollModal = (record, e) => {
    deviceOptions = [];
    this.state.devices.forEach((device, index) => {
      if(record.location_id == device.location_id) {
        deviceOptions.push(<Option value={(device.id).toString()}>{device.name}</Option>);
      }
    })
    this.setState({...this.state, ["popovervisible"+record.id]: false, record: record, enrollVisible: true});
  }

  handleEnrollOk = (values, employee_id) => {
    this.setState({...this.state, enrollLoading: true});
    const {authUser, currentCompany} = this.props;
    axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/biometrics/register-employee`, {
      employee_id: employee_id,
      device_id: values.device_id,
    },
    {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(registerData => {
      this.setState({...this.state, enrollLoading: false, enrollVisible: false, deviceCoordinate: true});
    })
    .catch(error => {
      message.error("Something went wrong");
      this.setState({...this.state, enrollLoading: false})
    })
  }

  handlePopoverChange = (record, visible) => {
    this.setState({...this.state, ["popovervisible"+record.id]: visible})
  }

  isEnrolled = () => {
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/biometrics/is-enrolled/${this.state.record.id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(isEnrolledData => {
      if (isEnrolledData.data === 1) {
        message.success("Enrolled Successfully");
        this.setState({...this.state, coordinateLoading: false, deviceCoordinate: false});
      } else {
        message.error("Not Enrolled at device. Please try again");
        this.setState({...this.state, coordinateLoading: false});
      }
    })
    .catch(error => {
      message.error("Something went wrong. Try Enrolling again");
      this.setState({...this.state, coordinateLoading: false});
    })
  }

  onImport = () => {
    this.setState({...this.state, importVisible: true});
  }

  handleImport = (values) => {
    const {authUser, currentCompany} = this.props;
    console.log(values);
    const formData = new FormData();
    formData.append('file', values.import_file.fileList[0].originFileObj);

    let url = `${API_URL_V1}/workspaces/${currentCompany.id}/employees/import`;
    axios.post(url, formData,
    {
        headers: {
            'Authorization': 'Bearer '+authUser,
            'Content-Type': 'multipart/form-data'
        },
        processData: false
    })
    .then(response => {
        this.setState({...this.state, importVisible: false},() => {
          this.getEmployees();
        });
    })
    .catch(error => {
        message.error("Failed to Add File");
    })
  }

  getFormat = () => {
    this.setState({...this.state, importVisible: false});
    window.open(`${API_URL_V1}/employees/import-format`);
  }

  handleJoyrideCallback = data => {
    if(data.index === 1) {
      let steps = [...this.state.steps];
        this.setState({...this.state, visible: true});
    }
    if(data.index === 2) {
      let steps = [...this.state.steps];
        this.setState({...this.state, steps: steps})
    }
    if(data.action === "skip") {
      this.props.resetJoyride();
    }
  };

  sendInvite = (record, e) => {
    e.preventDefault();
    const {authUser, currentCompany} = this.props;
    axios.post(`${API_URL_V1}/employees/${record.id}/invite`, {
    },
    {
      headers: {
      'Authorization': 'Bearer '+authUser
      }
    })
    .then(inviteData => {
      message.success('Invite has been sent');
      this.setState({...this.state, ["popovervisible"+record.id]: false});
    })
    .catch(error => {
      message.error('Something went wrong');
    })
  }

  render() {
    const {currentEmployee} = this.props;
    let canManage = currentEmployee && currentEmployee.role && (currentEmployee.role.permissions.includes("edit_company") || currentEmployee.role.permissions.includes("manage_company"));
    const {steps} = this.state;
    const columns = [{
        title: 'ID',
        render: (text, record) => (
          <span className={record.oid ? "gx-text" : "gx-text-grey"}>
              {record && record.oid ? record.oid : record.contract ? record.contract.employee_id : ''}
          </span>
        )
      }, {
        title: 'Name',
        render: (text, record) => {
          let avatarUrl = "";
          if (record.avatar) {
            avatarUrl = `${API_URL_V1}${record.avatar}`;
          }
          return (
            <div>
              {this.props.roles && this.props.roles.map((role, index) => {
                if(role.id == record.role_id && (role.permissions.includes("edit_company") && role.permissions.includes("manage_employee"))) {
                  return <FaCrown className="" color="#ffbb48" style={{transform: 'translate(0px, 5px) rotate(-30deg)'}}/>
                }
              })}
              <div className="gx-flex-row gx-align-items-center gx-avatar-row">
                <Badge style={{marginBottom: 0}} offset={[-20, 5]} dot={(record.leaves_count && record.leaves_count > 0) || (record.expenses_count && record.expenses_count > 0)}>
                  <Avatar src={avatarUrl}
                          className="gx-size-40 gx-mr-3" alt=""/>
                </Badge>
                <span className={(canManage && this.state.employee_status !== "terminated") ? "gx-link" : "gx-text"} onClick={(canManage && this.state.employee_status !== "terminated") ? (e) => {this.edit(record, e)} : ''}>

                {`${record.first_name} ${record.last_name}`}<br />
                <span className="gx-text-grey">
                  {this.state.designations.map((designation, index) => {
                    if(record.contract && (designation.id === record.contract.designation_id)) {
                      return designation.name;
                    }
                    return null;
                  })}
                </span>
                </span>
              </div></div>
          )
        }
      }, {
        title: 'Department',
        render: (text, record) => (
          <span>
            <span>
                {this.state.departments.map((department, index) => {
                  if(department.id === record.department_id) {
                    return department.name;
                  }
                  return null;
                })}<br/>
            </span><br/>
          </span>
        )
      }, {
        title: 'Contact',
        render: (text, record) => (
          <span>
              {record.phone_number ? record.phone_number : "No number"} <br />
              <span className="gx-text-grey">
                {record.email}
              </span>
          </span>
        )
      }, {
        title: canManage ? 'Requests' : '',
        render: (text, record) => (
          <span>
              {(canManage && record.leaves_count > 0) ? <Link className={`gx-text-truncate gx-mb-0`} to={{pathname: `/employees/${record.id}/requests`, state: {tab: "timeoff", leavePolicyId: record.time_off_policy_id}}}><Tag className="gx-link gx-text-white" color="#f50">{record.leaves_count}</Tag></Link> : <span style={{margin: "15px"}}></span>}
              {(canManage && record.expenses_count > 0) ? <Link className={`gx-text-truncate gx-mb-0`} to={{pathname: `/employees/${record.id}/requests`, state: {tab: "expense"}}}><Tag className="gx-link gx-text-white" color="#87d068">{record.expenses_count}</Tag></Link> : <span style={{margin: "15px"}}></span>}
          </span>
        )
      }, {
        title: 'Hire Date',
        render: (text, record) => (
          <span>
              {record.contract ? record.contract.start_date : ''}<br />
              <span className="gx-text-grey">
                {(record.contract && record.contract.end_date) ? (moment().diff(moment(record.contract.end_date).format("YYYY-MM-DD"))) < 0 ? "Active" : "Terminated" : ''}
              </span>
          </span>
        )
      }, {
        title: 'Actions',
        key: 'x',
        render: (text, record) => {
          const menuOptions = (
            <ul className="gx-user-popover">
              {this.state.employee_status === "terminated" ?
              <li className={canManage ? "gx-link" : ''} onClick={canManage ? (e) => {this.unterminate(record, e)} : ''}>Unterminate</li> :
              <li>
              {canManage || (currentEmployee && (currentEmployee.id == record.manager_id || currentEmployee.id == record.time_off_manager_id)) ?
              <Badge style={{marginBottom: 0}} offset={[0, 5]} dot={(record.leaves_count && record.leaves_count > 0) || (record.expenses_count && record.expenses_count > 0)}><div className="gx-link" style={{margin: '2px'}} onClick={(e) => {this.request(record, e)}}><i className="icon icon-email" title="Requests" style={{verticalAlign: "middle"}}/> Requests</div></Badge> : <div style={{margin: '2px'}}>Requests</div>}
              {canManage ?
              <div className="gx-link" style={{margin: '2px'}} onClick={(e) => {this.edit(record, e)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/> Edit</div> : <div style={{margin: '2px'}}>Edit</div>}
              {canManage ?
                <div className="gx-link" style={{margin: '2px'}} onClick={(e) => {this.showEnrollModal(record, e)}}><i className="icon icon-feedback" title="Enroll employee to Biometric" style={{verticalAlign: "middle"}}/> Enroll</div> :
                <div style={{margin: '2px'}}>Enroll</div>
              }
              {(canManage && !record.user_id) ?
                <div className="gx-link" style={{margin: '2px'}} onClick={(e) => {this.sendInvite(record, e)}}><i className="icon icon-sent" title="Resend Invite" style={{verticalAlign: "middle"}}/>{record.invited_on ? ' Resend Invite' : ' Send Invite'}</div> : ''
              }
              {canManage ?
                <div className="gx-link" style={{margin: '2px'}} onClick={(e) => {this.setState({...this.state, ["popovervisible"+record.id]: false, deleteVisible: true, record: record})}}><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/> Delete</div> :
                <div style={{margin: '2px'}}>Delete</div>
              }
              </li>}
            </ul>
          );
          return (
            <Popover placement="leftTop" content={menuOptions} trigger="click" visible={this.state["popovervisible"+record.id]} onVisibleChange={(e) => {this.handlePopoverChange(record, e)}}>
              <span className="gx-link ant-dropdown-link gx-text-grey" style={{marginLeft: "20px"}}>
                <i className="icon icon-ellipse-v"/>
              </span>
            </Popover>
          )
        }
      }];
    return (
      <div>
      <Modal
        visible={this.state.deleteVisible}
        title="Delete Employee?"
        onOk={(e) => this.deleteRecord(this.state.record, e)}
        onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
        confirmLoading={this.state.deleteLoading}
        footer={[
            <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
              OK
            </Button>,
            <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
              Cancel
            </Button>
          ]}
      >
        <p>Are you Sure you want to delete {`${this.state.record.first_name} ${this.state.record.last_name}`}?</p>
      </Modal>
      {this.state.deviceCoordinate ?
        <Modal
          visible={this.state.deviceCoordinate}
          title="Enroll Employee"
          onOk={(e) => {this.setState({...this.state, deviceCoordinate: false})}}
          onCancel={(e) => {this.setState({...this.state, deviceCoordinate: false})}}
          confirmLoading={this.state.coordinateLoading}
          footer={[
              <Button key="submit" loading={this.state.coordinateLoading} onClick={this.isEnrolled}>
                OK
              </Button>
            ]}
        >
          <p>Go to Device and Enroll</p>
          <div className={`fingerprint`}>
            <img src={fingerprint} alt="fingerprint"/>
          </div>
        </Modal>

      : null}
      {this.state.visible ?
      <EmployeeModal onCancel={this.handleCancel} onOk={this.handleOk} confirmLoading={this.state.confirmLoading}/> : null}
      {this.state.enrollVisible ?
      <EnrollModal
        visible={this.state.enrollVisible}
        value={this.state.record}
        title="Enroll Employee"
        onCancel={(e) => {this.setState({...this.state, enrollVisible: false})}}
        onOk={this.handleEnrollOk}
        confirmLoading={this.state.enrollLoading}
        devices={deviceOptions}/> : null
      }
      {this.state.importVisible ?
      <ImportEmployees visible={this.state.importVisible} title="Import Employees" onOk={this.handleImport} onCancel={() => {this.setState({...this.state, importVisible: false})}} getFormat={this.getFormat}/> : null}
      <Auxiliary>
        <div className="gx-profile-content">
          {this.props.joyride === ".employees" ?
          <Joyride
            steps={steps}
            callback={this.handleJoyrideCallback}
            showSkipButton={true}
            continuous
            styles={{
              options: {
                zIndex: 1000,
              }
            }}
          /> : null}
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <span style={{marginBottom: "4.2rem", marginRight: "1rem"}}>
                  <Search
                    className = "employee-search"
                    placeholder="Search Employee"
                    defaultValue={this.state.q}
                    onChange={(e) => {this.setSearchQuery(e.target.value)}}
                    style={{width: 200}}
                  />
                </span>
                <span>
                <Select defaultValue="active" style={{marginBottom: "1rem", width: 120 }} onChange={this.changeSelect}>
                  <Option value="active">Active</Option>
                  <Option value="terminated">Terminated</Option>
                </Select>
                </span>
                { canManage && <div style={{display: "inline", paddingBottom: "2.5rem"}}>
                  <span style={{float: 'right'}}>
                    <Button disabled={!canManage} className="add-employee-button" onClick={this.onClick} value="Add">Add Employee</Button>
                    <Button disabled={!canManage} onClick={this.onImport} value="Import">Import Employees</Button>
                  </span>
                </div> }
                </Col>
              </Row>
              {this.state.loading ?
              <CircularProgress height="400px"/> :
              <Table className="gx-table-responsive" columns={columns} dataSource={this.state.employees} pagination={this.state.pagination} onChange={this.handleTableChange}/>}
        </div>
      </Auxiliary>
      </div>
    );
  }
}
const mapStateToProps = ({auth, company}) => {
  const {authUser, userWorkspaceData, roles} = auth;
  const {currentCompany, currentEmployee, joyride} = company;
  return {authUser, userWorkspaceData, roles, currentCompany, currentEmployee, joyride}
};

export default withRouter(connect(mapStateToProps, {resetJoyride})(CompanyEmployees));
