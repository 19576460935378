import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Col, Row, Tabs} from "antd";
import Breadcrumbs from "components/Breadcrumbs/index";
import ApplicantTracking from "./ApplicantTracking";
import Auxiliary from "../../../util/Auxiliary";
import CircularProgress from "components/CircularProgress/index";

class ApplicantTrackingSystem extends Component {

  render() {
    const {currentEmployee} = this.props;
    return (
      <Auxiliary>
        <div className="gx-profile-content">
          <Breadcrumbs />
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card className="gx-card" title="Applicant Tracking">
              {this.props.currentCompany && this.props.currentEmployee && this.props.currentEmployee.role && this.props.currentEmployee.role.permissions ?
                <ApplicantTracking />
              : <CircularProgress />}
              </Card>
            </Col>
          </Row>
        </div>
      </Auxiliary>
    )
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, currentCompany, currentEmployee}
};

export default connect(mapStateToProps)(ApplicantTrackingSystem);
