import React, { Component } from 'react';
import {API_URL_V1} from "../../../constants";
import $ from 'jquery';
import 'orgchart/dist/js/jquery.orgchart.js';
import 'orgchart/dist/css/jquery.orgchart.css';
import 'orgchart/demo/css/font-awesome.min.css';
import defaultImage from "../../../assets/images/placeholder.jpg"

class OrgChart extends Component{

  list_to_tree = (list) => {
      var map = {}, node, roots = [], i, results = [];
      for (i = 0; i < list.length; i += 1) {
          map[list[i].id] = i;
          list[i].children = [];
      }
      for (i = 0; i < list.length; i += 1) {
          node = list[i];
          if (node.id === node.manager_id || node.manager_id === null) {
              roots.push(node);
          } else {
            list[map[node.manager_id]].children.push(node);
          }
      }
      return roots;
  }

  componentDidMount() {
    this.$el = $(this.el);
    let employees = [];
    this.props.employees.forEach((employee, index) => {
      let root = {};
      let designation = this.props.designations.filter((designation, index) => designation.id === employee.contract.designation_id);;
      root.id = employee.id;
      root.name = employee.first_name +" "+ employee.last_name;
      root.avatar = employee.avatar;
      root.children = [];
      root.manager_id = employee.manager_id;
      root.title = designation[0] ? designation[0].name : '';
      employees.push(root);
    })
    let dataSources = this.list_to_tree(employees);
    dataSources.forEach((dataSource, index) => {
      this.$el.orgchart({
        data : dataSource,
        visibleLevel: 3,
        nodeContent: 'title',
        nodeId: 'id',
        pan: true,
        zoom: true,
        createNode: function($node, data) {
          let secondMenu = `<div class="second-menu"><img class="avatar" src="${data.avatar ? API_URL_V1+data.avatar : defaultImage}"></div>`;
          $node.append(secondMenu);
        }
      });
    })
  }

  componentWillUnmount() {
    this.$el.empty();
  }

  render() {
    return (
      <div id="chart-container" ref={el => this.el = el}></div>
    );
  }

}

export default OrgChart;
