import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Col, Row, Tabs} from "antd";
import Breadcrumbs from "components/Breadcrumbs/index";
import Auxiliary from "../../../util/Auxiliary";
import CompanyEmployees from "./Employees";
import LiveView from "./LiveView";
import OrgChart from "./OrgChart";
import {API_URL_V1} from "../../../constants";
import CircularProgress from "components/CircularProgress/index";
import axios from "axios";

const TabPane = Tabs.TabPane;

class Employees extends Component {
  state={
    pagination: {current: 1, pageSize: 15, total: 0},
    employees: '',
    designations: '',
    departments: '',
    projects: '',
    q: null,
  }

  getEmployees(params) {
    if(params !== "search") {
      this.setState({...this.state, loading: true});
    }
    const {authUser, currentCompany} = this.props;
    axios.all([
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/employees`, {
        params: {
          live_data: true,
          page: this.state.pagination.current, per_page: this.state.pagination.pageSize, q: this.state.q, ...params
        },
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/designations`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/departments`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/projects`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
    ])
    .then(axios.spread((employeeData, designationData, departmentData, projectData) => {
      const pagination = { ...this.state.pagination };
      pagination.pageSize = 15;
      pagination.total =employeeData.data.meta.total;
      if(this.props.location.state && this.props.location.state.dashboard) {
        employeeData.data.data.sort((a, b) => (a.leaves_count+a.expenses_count) > (b.leaves_count+b.expenses_count) ? -1 : (b.leaves_count+b.expenses_count) > (a.leaves_count+a.expenses_count) ? 1 : 0);
      }
      this.setState({...this.state, pagination, employees: employeeData.data.data, designations: designationData.data.data, departments: departmentData.data.data, projects: projectData.data.data, loading: false});
    }))
    .catch(error => error)
  }

  componentDidMount() {
    this.getEmployees();
  }

  render() {
    const {currentEmployee} = this.props;
    let canManage = currentEmployee && currentEmployee.role && (currentEmployee.role.permissions.includes("edit_company") || currentEmployee.role.permissions.includes("manage_company"));
    return (
      <Auxiliary>
        <div className="gx-profile-content">
          <Breadcrumbs />
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card className="gx-card" title="Company Employees">
              {this.props.currentCompany && this.state.employees && this.props.currentEmployee && this.props.currentEmployee.role && this.props.currentEmployee.role.permissions ?
                <Tabs type="card">
                  <TabPane tab="Employees" key="employees">
                    <CompanyEmployees employees={this.state.employees} designations={this.state.designations} pagination={this.state.pagination} departments={this.state.departments} getEmployees={this.getEmployees}/>
                  </TabPane>
                  <TabPane tab="Live View" key="live-view" disabled={!canManage}>
                    <LiveView employees={this.state.employees} projects={this.state.projects} designations={this.state.designations} pagination={this.state.pagination} departments={this.state.departments} getEmployees={this.getEmployees}/>
                  </TabPane>
                  <TabPane tab="Org Chart" key="org-chart">
                    <OrgChart employees={this.state.employees} designations={this.state.designations} pagination={this.state.pagination} getEmployees={this.getEmployees}/>
                  </TabPane>
                </Tabs> : <CircularProgress />}
              </Card>
            </Col>
          </Row>
        </div>
      </Auxiliary>
    )
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser, userWorkspaceData} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, userWorkspaceData, currentCompany, currentEmployee}
};

export default connect(mapStateToProps)(Employees);
