import {
  HIDE_MESSAGE,
  HIDE_SIGNUP_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SHOW_SIGNUP_MESSAGE,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER,
  SIGNUP_USER_SUCCESS,
  JOIN_USER,
  JOIN_USER_SUCCESS,
  GET_CURRENT_USER,
  GET_CURRENT_USER_SUCCESS,
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_USER_WORKSPACE_DATA,
  GET_USER_WORKSPACE_DATA_SUCCESS,
} from "constants/ActionTypes";

export const getUserWorkspaceData = (authUser) => {
  return {
    type: GET_USER_WORKSPACE_DATA,
    payload: authUser,
  };
};

export const getUserWorkspaceDataSuccess = (workspaceData) => {
  return {
    type: GET_USER_WORKSPACE_DATA_SUCCESS,
    payload: workspaceData,
  };
};

export const getRoles = () => {
  return {
    type: GET_ROLES,
  };
};

export const getRolesSuccess = (roles) => {
  return {
    type: GET_ROLES_SUCCESS,
    payload: roles,
  };
};

export const getCurrentUser = (token) => {
  return {
    type: GET_CURRENT_USER,
    payload: token
  };
};

export const getCurrentUserSuccess = (user) => {
  return {
    type: GET_CURRENT_USER_SUCCESS,
    payload: user
  };
};

export const userSignUp = (user) => {
  return {
    type: SIGNUP_USER,
    payload: user
  };
};
export const userSignIn = (user) => {
  return {
    type: SIGNIN_USER,
    payload: user
  };
};
export const userSignOut = (authUser) => {
  return {
    type: SIGNOUT_USER,
    payload: authUser
  };
};
export const userJoin = (user) => {
  return {
    type: JOIN_USER,
    payload: user
  };
};
export const userSignUpSuccess = (authUser) => {
  return {
    type: SIGNUP_USER_SUCCESS,
    payload: authUser
  };
};

export const userSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser
  }
};
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  }
};
export const userJoinSuccess = (authUser) => {
  return {
    type: JOIN_USER_SUCCESS,
    payload: authUser
  }
};

export const showAuthMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message
  };
};

export const showSignUpMessage = (message) => {
  return {
    type: SHOW_SIGNUP_MESSAGE,
    payload: message
  };
};


export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};
export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideMessage = () => {
  return {
    type: HIDE_MESSAGE,
  };
};

export const hideSignUpMessage = () => {
  return {
    type: HIDE_SIGNUP_MESSAGE,
  };
};

export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};
