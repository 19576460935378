import React, {Component} from "react";
import {Modal, Form, Input, Select, DatePicker, Button, Upload, Icon} from 'antd';
import moment from 'moment';

const {RangePicker} = DatePicker;
const {TextArea} = Input;
const Option = Select.Option;
let buttonVal;
const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends Component {
    state={
      date: (this.props.title === 'Edit Leave')&&(this.props.value && (this.props.value.start_on === this.props.value.finish_on)) ? true : false,
      range: (this.props.title === 'Edit Leave')&&(this.props.value && (this.props.value.start_on !== this.props.value.finish_on)) ? true : false,
      fileList: []
    }

    onChange = (value) => {
      if(value === 'several_days') {
        this.setState({...this.state, range: true, date: false});
      } else {
        this.setState({...this.state, range: false, date: true});
      }
    }

    render() {
      const { fileList } = this.state;
      buttonVal="";
      let disabled = (this.props.permissions.includes("edit_company") || this.props.permissions.includes("manage_company"));
      const formItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 8},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 16},
        },
      };

      const { getFieldDecorator } = this.props.form;
      const props = {
                onRemove: (file) => {
                    this.setState((state) => {
                        return {
                            fileList: [],
                        };
                    });
                },
                beforeUpload: (file) => {
                    this.setState(state => ({
                        fileList: [file],
                    }));
                    return false;
                },
                fileList,
            };
      return (
        <Modal
          visible={this.props.visible}
          title={this.props.title}
          okText={this.props.title}
          onCancel={this.props.onCancel}
          width={550}
          footer={!(disabled && this.props.title !== "Add Leave") ? [
            <Button key="submit" loading={this.props.confirmLoading} onClick={this.props.onCreate}>
              {this.props.title}
            </Button>,
            <Button key="back" onClick={this.props.onCancel}>Cancel</Button>,
            ] :
            [
            <Button key="submit" loading={this.props.confirmLoading} onClick={this.props.onCreate}>
              {this.props.title}
            </Button>,
            <Button key="approve" loading={this.props.approveLoading} onClick={() => {buttonVal="Approve Leave"; this.props.onCreate()}}>
              Approve Leave
            </Button>,
            <Button key="reject" loading={this.props.rejectLoading} onClick={() => {buttonVal="Reject Leave"; this.props.onCreate()}}>
              Reject Leave
            </Button>,
            <Button key="back" onClick={this.props.onCancel}>Cancel</Button>,
            ]
          }
        >
          <Form >
            {((this.props.title !== "Request Leave") && (this.props.title !== "Add Leave")) ?
            <Form.Item
            {...formItemLayout}
            label="Leave Status"
            >
              {getFieldDecorator('leave_status', {
                initialValue: (this.props.value.approved === false && this.props.value.rejected === false) ? 'Pending' : (this.props.value.approved === true) ? 'Approved' : 'Rejected',
              })(
                <Input placeholder= "Leave Status" disabled/>
              )}
            </Form.Item> : null}
            <Form.Item
            {...formItemLayout}
            label="Leave Type"
            >
              {getFieldDecorator('leave_type', {
                initialValue: this.props.value.leave_type_id ? (this.props.value.leave_type_id).toString() : undefined,
                rules: [{ required: true, message: 'Please Select Leave Types!' }],
              })(
                <Select placeholder= "Leave Type" showAction={['click', 'focus']}>
                  {this.props.leaveTypes}
                </Select>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Available Leaves"
            >
              {getFieldDecorator('available_leaves', {
                initialValue: this.props.leaveStats ? (this.props.leaveStats.days_all - this.props.leaveStats.days_taken) : this.props.value ? (this.props.value.days_all - this.props.value.days_taken) : '',
              })(
                <Input placeholder= "Available Leaves" disabled/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Reason"
            >
              {getFieldDecorator('description', {
                initialValue: this.props.value.description,
                rules: [{ required: true, message: 'Please enter the description!' }],
              })(
                <TextArea placeholder= "Reason" autosize={{minRows: 4}}/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Duration"
            >
              {getFieldDecorator('duration', {
                initialValue: this.props.value.half_day ? this.props.value.half_day :
                (this.props.value&&(this.props.value.start_on === this.props.value.finish_on)) ? 'single_day' :
                (this.props.value.start_on !== this.props.value.finish_on) ? 'several_days' : undefined,
                rules: [{ required: true, message: 'Please Select the Duration!' }],
              })(
                <Select placeholder= "Please Select Duration" onChange={this.onChange} showAction={['click', 'focus']}>
                  <Option value="several_days">Several Days</Option>
                  <Option value="single_day">Single Day</Option>
                  <Option value="first_half">First Half</Option>
                  <Option value="second_half">Second Half</Option>
                </Select>
              )}
            </Form.Item>
            {this.state.date ?
            <Form.Item
            {...formItemLayout}
            label="Date"
            >
              {getFieldDecorator('date', {
                initialValue: this.props.value ? moment(this.props.value.start_on) : undefined,
                rules: [{ required: true, message: 'Please Select the Date!' }],
              })(
                <DatePicker className="gx-w-100"/>
              )}
            </Form.Item> : null }
            {this.state.range ?
            <Form.Item
            {...formItemLayout}
            label="Date Range"
            >
              {getFieldDecorator('range', {
                initialValue: this.props.value ? [moment(this.props.value.start_on), moment(this.props.value.finish_on)] : undefined,
                rules: [{ required: true, message: 'Please Select the Date Range!' }],
              })(
                <RangePicker />
              )}
            </Form.Item> : null }
            <Form.Item
                {...formItemLayout}
                label="Document File">
                {getFieldDecorator('document', {
                })(
                    <Upload {...props}>
                        <Button>
                            <Icon type="upload" /> Add Document
                        </Button>
                    </Upload>
                )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

class TimeOffModal extends React.Component {

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onOk(values, this.props.value.id, buttonVal);
    });
  }

  render() {
    return (
      <div>

        <CollectionCreateForm
          title={this.props.title}
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          onCreate={this.handleCreate}
          value={this.props.value}
          leaveTypes={this.props.leaveTypes}
          permissions={this.props.permissions}
          leaveStats={this.props.leaveStats}
          confirmLoading={this.props.confirmLoading}
          approveLoading={this.props.approveLoading}
          rejectLoading={this.props.rejectLoading}
        />

      </div>
    );
  }
}

export default TimeOffModal;
