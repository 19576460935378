import React, {Component} from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import {Button, Card, Table, Modal, Divider, Select, Popconfirm, Checkbox, message} from "antd";
import Auxiliary from "../../../util/Auxiliary";
import ExpenseModal from "./ExpenseModal";
import ExpenseStats from "./ExpenseStats";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../constants";
import _ from "lodash";
import axios from "axios";

const Option = Select.Option;
let disabled;

class Expense extends Component {

  constructor(props){
    super(props)
    this.state = {
      visible: false,
      title: '',
      loading: true,
      editData: '',
      expenses: [],
      pagination: {current: 1, pageSize: 15, total: 0},
      confirmLoading: false,
      approveLoading: false,
      rejectLoading: false,
      deleteVisible: false,
      deleteLoading: false,
      record: '',
      expenseStats: '',
      loadingStats: '',
    };
  }

  getExpensesStats(params) {
    this.setState(state => {return {...state, loadingStats: true}});

    let employee_id = params;
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/employees/${employee_id}/expenses-stats`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    }).then(response => {
      this.setState(state => {return {...state, loadingStats: false, expenseStats: response.data.data}});
    }).catch(error => {
      message.error("Failed to get expenses details");
    })
  }

  getExpenses(params) {
    const {authUser, currentEmployee} = this.props;
    axios.all([
      axios.get(`${API_URL_V1}/employees/${params ? params : currentEmployee.id}/expenses`, {
        params: {
          include: 'document',
          page: this.state.pagination.current, per_page: this.state.pagination.pageSize,
        },
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
    ])
    .then(axios.spread((expensesData) => {
      const pagination = { ...this.state.pagination };
      pagination.pageSize = 15;
      pagination.total =expensesData.data.total;
      this.setState({...this.state, expenses: expensesData.data.data, pagination, loading: false});
    }))
    .catch(error => error)
  }

  difference = (object, base) => {
    function changes(object, base) {
      return _.transform(object, function(result, value, key) {
        if (!_.isEqual(value, base[key])) {
          result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
        }
      });
    }
    return changes(object, base);
  }

  componentWillReceiveProps(nextProps) {
    const change = this.difference(nextProps, this.props)
    if(Object.keys(change).length !== 0) {
      this.setState({...this.state, loading: true});
      let employee_id = nextProps.match.params.id ? nextProps.match.params.id : nextProps.currentEmployee.id;
      this.getExpenses(employee_id);
      this.getExpensesStats(employee_id);
    }
  }

  componentDidMount() {
    let employee_id = this.props.match.params.id ? this.props.match.params.id : this.props.currentEmployee.id;
    this.getExpenses(employee_id);
    this.getExpensesStats(employee_id);
  }

  addExpense = () => {
    this.setState({...this.state, visible: true, title: "Add Expense", editData: ''});
  }

  handleCancel = () => {
    this.setState({...this.state, visible: false});
  }

  edit = (record, e) => {
    e.preventDefault();
    let employee_id = this.props.match.params.id ? this.props.match.params.id : this.props.currentEmployee.id;
    const {authUser, currentEmployee} = this.props;
    axios.get(`${API_URL_V1}/employees/${employee_id}/expenses/${record.id}`, {
      headers: {
       'Authorization': 'Bearer '+authUser
      }
    })
    .then(editData => {
      this.setState({...this.state, visible: !this.state.visible, title: "Edit Expense", editData: editData.data.data})
    })
  }

  handleOk = (values, id, buttonVal) => {
    this.setState({...this.state, confirmLoading: true});
    let employee_id = this.props.match.params.id ? this.props.match.params.id : this.props.currentEmployee.id;
    const {authUser, currentEmployee} = this.props;
    const formData = new FormData();
    formData.append('name', values.expense_name);
    formData.append('category', values.type);
    formData.append('description', values.description);
    formData.append('amount', values.cost);
    formData.append('date', (values.date).format('YYYY-MM-DD'));
    if(values.document && values.document.fileList){
        formData.append('document', values.document.fileList[0].originFileObj);
    }
    if(!id) {
      axios.post(`${API_URL_V1}/employees/${employee_id}/expenses`, formData,
      {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(expenseData => {
        let expenses = this.state.expenses;
        expenses.push(expenseData.data.data);
        this.getExpensesStats(employee_id);
        message.success("New Expense Added");
        this.setState({...this.state, expenses: expenses, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Add Expense");
        this.setState({...this.state, confirmLoading: false});
      })
    } else {
      let url, params, method;
      if(buttonVal === "Approve Expense"){
        this.setState({...this.state, approveLoading: true});
      }
      if (buttonVal === "Reject Expense") {
        this.setState({...this.state, rejectLoading: true});
      }
      if(buttonVal) {
        url = `${API_URL_V1}/employees/${employee_id}/expenses/${id}/approve`;
        method= 'post';
        if (buttonVal === "Approve Expense") {
          params = {
            approved: true,
            rejected: false,
          };
        } else {
          params = {
            approved: false,
            rejected: true,
          }
        }
      } else {
        formData.append('_method', 'PUT');
        method= 'post';
        url = `${API_URL_V1}/employees/${employee_id}/expenses/${id}`;
        params={
          name: values.expense_name,
          category: values.type,
          description: values.description,
          amount: values.cost,
          date: (values.date).format('YYYY-MM-DD'),
        }
      }
      axios({method: method, url: url, data: buttonVal ? params : formData,
        headers: {
        'Authorization': 'Bearer '+authUser
        }
      })
      .then(expensesData => {
        let expenses = this.state.expenses;
        this.getExpensesStats(employee_id);
        expenses.forEach((expense, index) => {
          if(expense.id == expensesData.data.data.id){
            expenses[index] = {...expense, ...expensesData.data.data};
          }
        })
        if(buttonVal === "Approve Expense"){
          message.success("Expense Approved");
        } else if (buttonVal === "Reject Expense") {
          message.success("Expense Rejected");
        } else {
          message.success("Expense Editted Successfully");
        }
        this.setState({...this.state, expenses: expenses, visible: !this.state.visible, approveLoading: false, rejectLoading: false, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Edit");
        this.setState({...this.state, confirmLoading: false});
      })

    }
  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    let employee_id = this.props.match.params.id ? this.props.match.params.id : this.props.currentEmployee.id;
    const {authUser, currentEmployee} = this.props;
    axios.delete(`${API_URL_V1}/employees/${employee_id}/expenses/${record.id}`,{
      params: {
        expense_id: record.id,
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(expenseData => {
      message.success("Expense Deleted Successfully");
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      this.getExpenses(employee_id);
      this.getExpensesStats(employee_id);
    })
  }

  handleTableChange = (paginationData) => {
    let employee_id = this.props.match.params.id ? this.props.match.params.id : this.props.currentEmployee.id;
    this.setState({...this.state, pagination: paginationData}, () => {this.getExpenses(employee_id)});
  }

  render() {
    disabled = (this.props.currentEmployee && this.props.currentEmployee.role) ? this.props.currentEmployee.role.permissions.includes("edit_company") : '';
    const columns = [{
        title: 'Name',
        render: (text, record) => (
          <span className='gx-text-hover'>
            {record.name}<br/>
            {record.date}
          </span>
        )
      }, {
        title: 'Amount',
        render: (text, record) => (
          <span>
            {record.amount}
          </span>
        ),
      },{
        title: 'Status',
        render: (text, record) => (
          <span className={`gx-badge gx-hover gx-mb-0`} style={{backgroundColor: (record.approved) ? "#00875a" : (record.rejected) ? "#f5b3b6" : "#dfe1e6", color: (record.approved) ? "#ffffff" : (record.rejected) ? "#f5352d" : "#425275", fontSize: "12px" }}>
            {record.approved ? "Approved" : record.rejected ? "Rejected" : "Pending"}
          </span>
        ),
      }, {
        title: 'Manage',
        key: 'x',
        render: (text, record) => (
          <span>
            <span className="gx-link" onClick={(e) => {this.edit(record, e)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span>
            <Divider type="vertical"/>
              <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span>
            {record.document ?
            <span>
            <Divider type="vertical"/>
            <span className="gx-link" ><a target={"_blank"} href={record.document.download_url}>Download</a></span></span> : ''}
          </span>
        )
      }];
    return(
      <div>
      <Modal
        visible={this.state.deleteVisible}
        title="Delete Request?"
        onOk={(e) => this.deleteRecord(this.state.record, e)}
        onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
        confirmLoading={this.state.deleteLoading}
        footer={[
          <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
            OK
          </Button>,
          <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
            Cancel
          </Button>
        ]}
      >
        <p>Are you Sure you want to delete this Request?</p>
      </Modal>
      {this.state.loading ? <CircularProgress /> :
      <div>
      {this.state.visible ?
        <ExpenseModal title={this.state.title} value={this.state.editData} visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel} permissions={this.props.currentEmployee.role.permissions} confirmLoading={this.state.confirmLoading} approveLoading={this.state.approveLoading} rejectLoading={this.state.rejectLoading}/> : null }
      <Auxiliary>
        <div style={{paddingBottom: "2.5rem"}}>
          <Button style={{float: "right"}} onClick={this.addExpense} value="Add">Add Expense</Button>
        </div>
        <ExpenseStats loading={this.state.loadingStats} stats={this.state.expenseStats}/>
        <Table className="gx-table-responsive" dataSource={this.state.expenses} columns={columns} pagination={this.state.pagination} onChange={this.handleTableChange}/>
      </Auxiliary>
      </div>}
      </div>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentEmployee, currentCompany} = company;
  return {authUser, currentEmployee, currentCompany}
};

export default withRouter(connect(mapStateToProps)(Expense));
