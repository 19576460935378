import React, {Component} from "react";
import {connect} from "react-redux";
import {Form, Select, Upload, Button, Icon, Table, message, Divider, Modal, Input, Spin} from "antd";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../../constants";
import axios from 'axios';
import moment from 'moment';
import Auxiliary from "../../../../util/Auxiliary";
import requests from "../../../../requests";

const Option = Select.Option;
let formData={};
let workspace_id;

const DocumentForm = Form.create({ name: 'form_in_modal' })(
    class extends Component {
        state = {
            fileList: []
        }
        render() {
            const { fileList } = this.state;

            const formItemLayout = {
                labelCol: {
                    xs: {span: 24},
                    sm: {span: 8},
                },
                wrapperCol: {
                    xs: {span: 24},
                    sm: {span: 16},
                },
            };

            const { getFieldDecorator } = this.props.form;
            const props = {
                onRemove: (file) => {
                    this.setState((state) => {
                        return {
                            fileList: [],
                        };
                    });
                },
                beforeUpload: (file) => {
                    this.setState(state => ({
                        fileList: [file],
                    }));
                    return false;
                },
                fileList,
            };
            return (
                <Modal
                    visible={this.props.visible}
                    title={this.props.title}
                    onCancel={this.props.onCancel}
                    onOk={this.props.onCreate}
                    footer={[
                      <Button key="submit" loading={this.props.loading} onClick={this.props.onCreate}>
                        {this.props.title}
                      </Button>,
                      <Button key="cancel" onClick={this.props.onCancel}>
                        Cancel
                      </Button>
                    ]}
                >
                    <Form >
                        <Form.Item
                            {...formItemLayout}
                            label="Document Name">
                            {getFieldDecorator('filename', {
                                initialValue: this.props.value.filename,
                                rules: [{ required: true, message: 'Please input the Document Name!' }],
                            })(
                                <Input placeholder= "Document Name"/>
                            )}
                        </Form.Item>

                        <Form.Item
                            {...formItemLayout}
                            label="Type">
                            {getFieldDecorator('type', {
                                initialValue: this.props.value.type,
                                rules: [{ required: true, message: 'Please input the Document type!' }],
                            })(
                                <Input placeholder= "Eg. Payslip"/>
                            )}
                        </Form.Item>
                        <Spin spinning={this.props.loading}>
                        <Form.Item
                            {...formItemLayout}
                            label="Document File">
                            {getFieldDecorator('document', {
                                rules: [{ required: !this.props.value.id, message: 'Please select a file!' }],
                            })(
                                <Upload {...props}>
                                    <Button>
                                        <Icon type="upload" /> Add Document
                                    </Button>
                                </Upload>
                            )}
                        </Form.Item>
                        </Spin>

                    </Form>
                </Modal>
            );
        }
    }
);


class DocumentModal extends React.Component {
    saveFormRef = (formRef) => {
        this.formRef = formRef;
    }
    handleCreate = () => {
        const form = this.formRef.props.form;
        form.validateFields((err, values) => {
            if (err) {
                return;
            }
            this.props.onOk(values, this.props.value.id);
        });
    }

    render() {
        return (
            <div>

                <DocumentForm
                    loading={this.props.loading}
                    title={this.props.title}
                    wrappedComponentRef={this.saveFormRef}
                    visible={this.props.visible}
                    onCancel={this.props.onCancel}
                    onCreate={this.handleCreate}
                    value={this.props.value}
                />

            </div>
        );
    }
}


class EmployeeDocuments extends Component {

    state={
        loading: true,
        visible: false,
        title: 'Add Document',
        editData: '',
        documents: [],
        deleteVisible: false,
        deleteLoading: false,
        record: '',
    };

    onClick = () => {
        this.setState({...this.state, visible: true, title: "Add Document", editData: ''});
    }

    handleCancel = () => {
        this.setState({...this.state, visible: false});
    }

    getDocuments = (options) => {
        const employee_id = this.props.employee.id;
        const {authUser} = this.props;

        var config = {
            onUploadProgress: function(progressEvent) {
                var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
            },
            headers: {
                'Authorization': 'Bearer '+authUser
            }
        };
        axios.get(`${API_URL_V1}/employees/${employee_id}/documents`, config
        )
            .then(response =>{
                console.log(response.data);
                this.setState({...this.state, loading: false, documents: response.data.data})
            }
        )
        .catch(error => {console.log(error)});

        }

    componentDidMount() {
        this.getDocuments();
    }

    handleChange = (info) => {

    }

    onSubmit = (values) => {

    }

    deleteRecord = (record, e) => {
        const {authUser, employee} = this.props;
        this.setState({...this.state, loading: true, deleteLoading: true});
        let url = `${API_URL_V1}/employees/${employee.id}/documents/${record.id}`;
        axios.delete(url,
            {
                headers: {
                    'Authorization': 'Bearer '+authUser,
                    'Content-Type': 'multipart/form-data'
                }
            })
            .then(response => {
                let documents = this.state.documents;
                documents = documents.filter(document => document.id != record.id);
                this.setState({...this.state, documents:documents, loading: false, deleteLoading: false, deleteVisible: false});
            })
            .catch(error => {
                message.error("Failed to Delete Document");
                this.setState({...this.state, loading: false});
            })
    }

    handleOk = (values, id) => {
        const {authUser, employee} = this.props;
        console.log(values);
        const formData = new FormData();
        formData.append('filename', values.filename);
        formData.append('type', values.type);
        if(values.document && values.document.fileList){
            formData.append('document', values.document.fileList[0].originFileObj);
        }

        this.setState({...this.state, loading: true});

        let url = `${API_URL_V1}/employees/${employee.id}/documents`;
        if(id){
            url = `${url}/${id}`;
            formData.append('_method', 'PUT');
        }
        axios.post(url, formData,
        {
            headers: {
                'Authorization': 'Bearer '+authUser,
                'Content-Type': 'multipart/form-data'
            },
            processData: false
        })
        .then(response => {
            let documents = this.state.documents;
            documents = documents.filter(document => document.id != id);
            documents.push(response.data.data);
            this.setState({...this.state, documents:documents, visible: false, loading: false});
        })
        .catch(error => {
            message.error("Failed to Add Document");
            this.setState({...this.state, loading: false});
        })

    }

    edit = (record, e) => {
        e.preventDefault();
        this.setState({...this.state, visible: true, title: "Edit Document", editData: record});
    }

    download = (record, e) => {
        let {employee} = this.props;
        requests.get(`${API_URL_V1}/employees/${employee.id}/documents/${record.id}`).then(response => {
            if(response.data.data || response.data.data.url){
                window.location.assign(response.data.data.url);
            }
        }).catch(error => {console.log(error)})
    }

    render() {
        let {employee, currentEmployee, currentCompany} = this.props;
        let disabled =  !(currentEmployee && currentEmployee.role && (currentEmployee.role.permissions.includes("edit_company") || currentEmployee.role.permissions.includes("manage_company")));

        const columns = [{
            title: 'File Name',
            dataIndex: 'filename',
            render: (text, record) => (
                <div>
                    <b>{record.filename}</b>
                    <br/>
                    <span className={'text-muted'}>
                        {moment(record.created_at).format('D MMM YYYY')}
                        {record.author? <span> - Uploaded by {record.author.first_name} {record.author.last_name}</span> : null}
                    </span>
                </div>
            )
        },
            {
                title: 'Document Type',
                dataIndex: 'type'
            },
            {
            title: 'Actions',
            key: 'x',
            render: (text, record) => (
                <span>
            {!disabled ?
                <span className="gx-link" onClick={(e) => {this.edit(record, e)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span> : <span></span>}
                    {!disabled ?
                        <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><Divider type="vertical"/><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span>
                        :<span></span>

                    }
                    <Divider type="vertical"/>
                <span class="gx-link" ><a target={"_blank"} href={record.download_url}>Download</a></span>
                </span>
            )
        }];
        return (
            <div>
            <Modal
              visible={this.state.deleteVisible}
              title="Delete Document?"
              onOk={(e) => this.deleteRecord(this.state.record, e)}
              onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
              confirmLoading={this.state.deleteLoading}
              footer={[
                <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
                  OK
                </Button>,
                <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
                  Cancel
                </Button>
              ]}
            >
              <p>Are you Sure you want to delete this event?</p>
            </Modal>
                {this.state.visible ?
                <DocumentModal loading={this.state.loading} title={this.state.title} value={this.state.editData} visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel}/>
                : null}
                    <Auxiliary>
                        <div><b>Documents</b></div>
                        <div>Employee Documents</div>
                        <div className="gx-mb-5">
                            <Button style={{float: "right"}} disabled={disabled} onClick={this.onClick} value="Add">Add Document</Button>
                        </div>
                        <Table className="gx-table-responsive" loading={this.state.loading} rowKey={'id'} columns={columns} dataSource={this.state.documents} pagination={false}/>

                    </Auxiliary>
            </div>
        );
    }
}
const mapStateToProps = ({auth, company}) => {
    const {authUser, userWorkspaceData} = auth;
    const {currentCompany, currentEmployee} = company;
    return {authUser, userWorkspaceData, currentCompany, currentEmployee}
};

export default connect(mapStateToProps)(EmployeeDocuments);
