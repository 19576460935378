import React, {Component} from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router';
import {Card, Table, Spin, Select, Button, Divider, Modal, message} from "antd";
import request from '../../../requests';
import TaskModal from "../projects/Projects/AddTaskModal";
import Auxiliary from "../../../util/Auxiliary";
import {API_URL_V1} from '../../../constants';
import axios from "axios";

const { Option } = Select;
let employeeOptions = [], disabled;
class ActiveTasks extends Component {
  state = {
    tasks: [],
    employee_id: this.props.currentEmployee.id,
    addTaskModal: false,
    spinning: true,
    employeeCount: 0,
    page: 1,
    per_page: 50,
    employeeData: [],
    title: '',
    editData: '',
    confirmLoading: false,
    record: '',
    deleteLoading: false,
    deleteVisible: false,
  }

  getTasks = () => {
    const {authUser} = this.props;
    request.get(`${API_URL_V1}/employees/${this.state.employee_id}/tasks`)
    .then(tasksData => {
      this.setState({...this.state, tasks: tasksData.data.data, spinning: false});
    })
    .catch(error => error)
  }

  getEmployees = () => {
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/employees`, {
      params: {
        page: this.state.page, per_page: this.state.per_page
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(employeeData => {
      employeeOptions = [];
      let employees = this.state.employeeData;
      employeeData.data.data.forEach((employee, index) => {
        employees.push(employee);
      });
      for(let i = 0; i < (employees).length; i++) {
        employeeOptions.push(<Option value={(employees[i].id).toString()}>{employees[i].first_name+" "+employees[i].last_name}</Option>);
      }
      this.setState({...this.state, employeeData: employees, page: this.state.page + 1, employeeCount: this.state.employeeCount+this.state.per_page},() => {if(this.state.employeeCount < employeeData.data.meta.total) {console.log('yess'); return(this.getEmployees())}})
    })
    .catch(error => error)
  }

  componentDidMount () {
    this.getTasks();
    this.getEmployees();
  }

  changeSelect = (value) => {
    this.setState({...this.state, employee_id: value},() => {this.getTasks()});
  }

  onCancel = () => {
    this.setState({...this.state, addTaskModal: false});
  }

  edit = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, title: "Edit Task", editData: record, addTaskModal: true});
  }

  handleOk = (values, id) => {
    const {authUser} = this.props;
    const project_id = this.state.editData.project_id;
    this.setState({...this.state, confirmLoading: true});
    axios.get(`${API_URL_V1}/projects/${project_id}/employees`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(employeeData => {
      let present = false;
      employeeData.data.data.map((employee, index) => {
        if(employee.id == values.employee_id || values.employee_id === '') {
          present = true;
        }
      })
      if (present) {
        axios.put(`${API_URL_V1}/projects/${project_id}/tasks/${id}`, {
          title: values.title,
          employee_id: values.employee_id,
          status: values.status,
          due_date: values.due_date ? (values.due_date).format('YYYY-MM-DD') : '',
        },
        {
          headers: {
          'Authorization': 'Bearer '+authUser
        }
        })
        .then(projectTasksData => {
          this.getTasks();
          message.success("Task Editted Successfully");
          this.setState({...this.state, addTaskModal: !this.state.addTaskModal, confirmLoading: false});
        })
        .catch(error => {
          message.error("Failed to Edit Task");
          this.setState({...this.state, confirmLoading: false});
        })
      } else {
        message.error("Employee not present in project. Please add first");
        this.setState({...this.state, confirmLoading: false, addTaskModal: false});
      }
    })

  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser} = this.props;
    const project_id = record.project_id;
    axios.delete(`${API_URL_V1}/projects/${project_id}/tasks/${record.id}`, {
      params: {
        task_id: record.id,
      },

      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(projectData => {
      this.getTasks();
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      message.success("Task Deleted Successfully");
    })
    .catch(error => error)
  }

  taskDetail = (record, e) => {
    e.preventDefault();
    this.props.history.push({
      pathname: `/projects/${record.project_id}/project_detail/task_detail`,
      state: {taskId: record.id},
    })
  }

  render() {
    disabled = (this.props.currentEmployee && this.props.currentEmployee.role) ? this.props.currentEmployee.role.permissions.includes("edit_company") : '';
    const columns = [{
        title: 'ID',
        dataIndex: 'id',
      }, {
        title: "Task Title",
        render: (text, record) => (
          <span className="gx-link" onClick={(e) => {this.taskDetail(record, e)}}>{record.title}</span>
        )
      }, {
        title: 'Project',
        render: (text, record) => (
          <span>
              {this.props.projects.map((project, index) => {
                  if(project.id == record.project_id) {
                    return project.name;
                  }
                }
              )}
          </span>
        )
      }, {
        title: "Due Date",
        render: (text, record) => (
          <span>
            {record.due_date ? record.due_date : "No due date"}
          </span>
        )
      }, {
        title: 'Actions',
        key: 'x',
        render: (text, record) => (
          <span>
          {disabled ?
              <span className="gx-link" onClick={(e) => {this.edit(record, e)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span> : <span></span>}

          {disabled ?
              <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><Divider type="vertical"/><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span>:
              <span></span>
          }
          </span>
        )
      }]
    return(
      <Auxiliary>
        <Modal
          visible={this.state.deleteVisible}
          title="Delete Task?"
          onOk={(e) => this.deleteRecord(this.state.record, e)}
          onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
          confirmLoading={this.state.deleteLoading}
          footer={[
            <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
              OK
            </Button>,
            <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
              Cancel
            </Button>
          ]}
        >
          <p>Are you Sure you want to delete this Task?</p>
        </Modal>
        {this.state.addTaskModal ?
        <TaskModal
          visible={this.state.addTaskModal}
          title={this.state.title}
          value={this.state.editData}
          onOk={this.handleOk}
          onCancel={this.onCancel}
          employees={this.state.employeeData}
          confirmLoading={this.state.confirmLoading}
        /> : null}
        <div>
          <p>Tasks by Employee</p>
          <span>Select Employee: </span>
          <Select defaultValue={this.props.currentEmployee.id.toString()} style={{ minWidth: 200 }} onChange={this.changeSelect}>
            {employeeOptions}
          </Select>
          <Spin spinning={this.state.spinning}>
            <Table className="gx-table-responsive" dataSource={this.state.tasks} columns={columns} pagination={false}/>
          </Spin>
        </div>
      </Auxiliary>
    )
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany} = company;
  return {authUser, currentCompany}
};

export default withRouter(connect(mapStateToProps, {})(ActiveTasks));
