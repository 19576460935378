import React from "react";
import {Route, Switch, Redirect} from "react-router-dom";
import {connect} from "react-redux";

import Dashboard from "./you/dashboard/index";
import MyProfile from "./you/myProfile/index";
import Requests from "./you/requests/index";
import MyAccount from "./you/myAccount/index";
import CompanySettings from "./yourCompany/settings/General/index";
import CompanyEmployees from "./yourCompany/employees/index";
import EmployeesPayroll from "./yourCompany/payroll/index";
import ApplicantTrackingSystem from "./yourCompany/ATS/index";
import ApplicantTrackingForm from "./yourCompany/ATS/ApplicantTrackingForm";
import CandidateDetail from "./yourCompany/ATS/CandidateDetail";
import CandidateMessaging from "./yourCompany/ATS/CandidateMessaging";
import EmployeeProfile from "./yourCompany/employees/EmployeeProfile/index";
import EmployeeTasks from "./yourCompany/employees/EmployeeTasks";
import ProjectDetail from "./yourApps/projects/Projects/index";
import Timer from "./yourApps/timer/index";
import TimeOff from "./yourCompany/settings/TimeOff/index";
import Workspaces from './you/workspaces/index';
import Calendar from './you/calendar/index';
import Projects from './yourApps/projects/index';
import CustomFields from './yourCompany/settings/CustomFields/index';
import EmployeeSatisfaction from "./yourCompany/settings/Survey/EmployeeSatisfaction/index";
import Survey from "./yourCompany/settings/Survey/index";
import Biometrics from './yourCompany/settings/Biometrics/index';
import CompanyEvents from './yourCompany/Events/index';
import Event from './yourCompany/Events/Event';
import Payroll from './yourCompany/settings/Payroll/index';
import TaskDetail from './yourApps/projects/Projects/TaskDetail';
import Clients from './yourCompany/clients';
import Invoice from "./yourCompany/invoice";
import InvoicePreview from "./yourCompany/invoice/preview";
import Adaptor from './switch';
import OfficeHolidays from './yourCompany/holidays/Holidays';
import WorkReports from './yourApps/workReports/index';
import AttendanceReports from './yourApps/attendanceReport/index';
import Billing from './yourCompany/billing';
import Subscription from './yourCompany/settings/General/Subscription';
import Error404 from './404';

let CompanyRoute = ({ component: Component, ...rest }) => (
  <Route {...rest} render={props => {
    console.log(props);
    if (rest.defaultCompany && !(rest.currentCompany || (rest.currentEmployee && rest.currentEmployee.time_off_policy_id))){
      return (<div>Loading...</div>)
    }
    return (
    (rest.currentCompany && rest.currentEmployee) && (rest.currentEmployee.time_off_policy_id || props.location.pathname !== '/requests') ? (
      <Component {...props} company={rest.currentCompany} employee={rest.currentEmployee}/>
    ) : (
      <Redirect to={{
        pathname: '/workspaces',
        state: { from: props.location }
      }}/>
    )
  )
  }
}/>
)

const mapStateToProps = ({company}) => {
  const {currentEmployee, currentCompany, defaultCompany} = company;
  return {currentEmployee, currentCompany, defaultCompany}
};

CompanyRoute = connect(mapStateToProps, {})(CompanyRoute);

const App = ({match, ...rest}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <CompanyRoute path={`${match.url}_next`} component={Adaptor}/>
      <Route path={`${match.url}dashboard`} component={Dashboard}/>
      <CompanyRoute path={`${match.url}my_profile`} component={MyProfile}/>
      <CompanyRoute path={`${match.url}requests`} component={Requests}/>
      <Route path={`${match.url}my_account`} component={MyAccount}/>
      <Route path={`${match.url}general`} component={CompanySettings}/>
      <CompanyRoute path={`${match.url}timeoff`} component={TimeOff}/>
      <CompanyRoute exact path={`${match.url}employees`} component={CompanyEmployees}/>
      <CompanyRoute exact path={`${match.url}payroll`} component={EmployeesPayroll}/>
      <CompanyRoute exact path={`${match.url}ats`} component={ApplicantTrackingSystem}/>
      <CompanyRoute exact path={`${match.url}ats/add`} component={ApplicantTrackingForm}/>
      <CompanyRoute exact path={`${match.url}ats/:id/detail`} component={ApplicantTrackingForm}/>
      <CompanyRoute exact path={`${match.url}ats/:id/detail/candidate_detail`} component={CandidateDetail}/>
      <CompanyRoute exact path={`${match.url}ats/:id/detail/candidate_detail/:candidate_id/messaging`} component={CandidateMessaging}/>
      <CompanyRoute path={`${match.url}employees/:id/profile`} component={EmployeeProfile}/>
      <CompanyRoute path={`${match.url}employees/:id/requests`} component={Requests}/>
      <CompanyRoute path={`${match.url}employees/:id/tasks`} component={EmployeeTasks}/>
      <Route path={`${match.url}workspaces`} component={Workspaces}/>
      <CompanyRoute path={`${match.url}calendar`} component={Calendar}/>
      <CompanyRoute exact path={`${match.url}projects`} component={Projects}/>
      <CompanyRoute path={`${match.url}custom_fields`} component={CustomFields}/>
      <CompanyRoute path={`${match.url}biometrics`} component={Biometrics}/>
      <CompanyRoute exact path={`${match.url}survey`} component={Survey}/>
      <CompanyRoute path={`${match.url}survey/:id/survey_detail`} component={EmployeeSatisfaction}/>
      <CompanyRoute exact path={`${match.url}events`} component={CompanyEvents}/>
      <CompanyRoute exact path={`${match.url}payroll_settings`} component={Payroll}/>
      <CompanyRoute path={`${match.url}events/:id`} component={Event}/>
      <CompanyRoute path={`${match.url}clients`} component={Clients}/>
      <CompanyRoute exact path={`${match.url}invoice`} component={Invoice}/>
      <CompanyRoute path={`${match.url}invoice/:id/preview`} component={InvoicePreview}/>
      <CompanyRoute path={`${match.url}projects/:id/project_detail/task_detail`} component={TaskDetail}/>
      <CompanyRoute path={`${match.url}projects/:id`} component={ProjectDetail}/>
      <CompanyRoute path={`${match.url}offices/:office_id/holidays`} component={OfficeHolidays} />
      <CompanyRoute path={`${match.url}timer`} component={Timer}/>
      <CompanyRoute path={`${match.url}work_reports`} component={WorkReports}/>
      <CompanyRoute path={`${match.url}attendance_report`} component={AttendanceReports}/>
      <CompanyRoute path={`${match.url}billing`} component={Billing}/>
      <CompanyRoute path={`${match.url}subscription`} component={Subscription}/>
      <CompanyRoute path={`${match.url}`} component={Error404}/>
    </Switch>
  </div>
);

export default App;
