import React, {Component} from "react";
import {Card, Col, Row, Tabs} from "antd";
import Auxiliary from "../../../util/Auxiliary";
import TimeOff from "./TimeOff";
import Expense from "./Expense";
import Payslips from "./Payslips";
import Breadcrumbs from "components/Breadcrumbs/index";
import CircularProgress from "components/CircularProgress/index";
import axios from "axios";

const TabPane = Tabs.TabPane;

class Requests extends Component {
  state={
    title: "Timeoff",
    defaultKey: 'timeoff',
    loading: true,
  }

  callback = activeKey => {
    this.setState({...this.state, title: activeKey});
  }

  componentDidMount () {
    this.setState({...this.state, defaultKey: (this.props.location && this.props.location.state && !this.props.location.state.leavePolicyId) ? this.props.location.state.tab : 'timeoff', loading: false});
  }

  render () {
    return (
      <Auxiliary>
      <div className="gx-profile-content">
        <Breadcrumbs />
        <Row>
          <Col xl={24} lg={24} md={24} sm={24} xs={24}>
            <Card className="gx-card" title={this.state.title}>
              {this.state.loading ? <CircularProgress /> :
                <Tabs onChange={this.callback} defaultActiveKey={this.state.defaultKey} type="card">
                  <TabPane tab="TimeOff" key="timeoff">
                    <TimeOff />
                  </TabPane>
                  <TabPane tab="Expense" key="expense">
                    <Expense />
                  </TabPane>
                  <TabPane tab="Payslips" key="payslips">
                    <Payslips />
                  </TabPane>
                </Tabs>
              }
            </Card>
          </Col>
        </Row>
      </div>
      </Auxiliary>
    );
  }
}

export default Requests;
