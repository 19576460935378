import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Switch, Select, Form, Row, Col, Input, Upload, Icon, message} from "antd";
import CircularProgress from "components/CircularProgress/index";
import Auxiliary from "../../../../../util/Auxiliary";
import {API_URL_V1} from "../../../../../constants";
import axios from "axios";

const { TextArea } = Input;
const Option = Select.Option;

class SatisfactionSettings extends Component {
  state={
    esSettings: [],
    loading: true,
  }

  getSettings = () => {
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/es/survey/${this.props.survey_id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(settingsData => {
      this.setState({...this.state, esSettings: settingsData.data, loading: false});
    })
    .catch(error => error)
  }

  componentDidMount () {
    this.getSettings();
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const {authUser, currentCompany} = this.props;
        axios.put(`${API_URL_V1}/workspaces/${currentCompany.id}/es/survey/${this.props.survey_id}`, {
          enabled: values.enabled,
          frequency: values.frequency,
          message: values.message,
        },{
          headers: {
            'Authorization': 'Bearer '+authUser
          }
        })
        .then(settingsData => {
          message.success("Settings Updated");
          this.setState({...this.state, esSettings: settingsData.data, loading: false});
        })
        .catch(error => {
          message.error("Something went wrong. Please try again.")
        })
      }
    });
  }

  render(){
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: {span: 24},
        sm: {span: 6},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {span: 24, offset: 0},
        sm: {span: 16, offset: 6},
      },
    };
    return(
      <Auxiliary>
        {this.state.loading ? <CircularProgress /> :
        <Form onSubmit={this.handleSubmit}>
          <Form.Item {...formItemLayout} label="Enabled">
            {getFieldDecorator('enabled', {
              initialValue: this.state.esSettings.enabled,
              valuePropName: 'checked'
            })(<Switch />)}
          </Form.Item>
          <Form.Item {...formItemLayout} label="Frequency">
            {getFieldDecorator('frequency', {
              initialValue: this.state.esSettings.frequency ? this.state.esSettings.frequency.toString() : undefined,
            })(
              <Select placeholder="Select frequency of the feedback">
                <Option value="monthly">At the end of every month</Option>
                <Option value="quarterly">Quarterly</Option>
                <Option value="halfyearly">Half-yearly</Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item
          {...formItemLayout}
          label="Message">
            {getFieldDecorator('message', {
              initialValue: this.state.esSettings.message,
            })(
              <TextArea placeholder="Please enter the message about the survey which employees will receive in email" autoSize={{ minRows: 3, maxRows: 6 }}/>
            )}
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>}
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, currentCompany, currentEmployee}
};

export default connect(mapStateToProps)(Form.create()(SatisfactionSettings));
