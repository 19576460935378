import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
  GET_USER_ID,
  GET_DEPARTMENTS,
} from "constants/ActionTypes";
import {
  getUserIdSuccess,
  getDepartmentsSuccess
} from "../../appRedux/actions/EmployeeData";
import {API_URL_V1} from "../../constants";
import axios from 'axios';

const getUserIdRequest = async (authUser) =>
  await  axios.get(`${API_URL_V1}/me`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(userData => userData)
    .catch(error => error);

const getDepartmentsRequest = async (authUser, workspace_id) =>
  await axios.get(`${API_URL_V1}/workspaces/${workspace_id}/departments`, {
    headers: {
      'Authorization': 'Bearer '+authUser
    }
  })
  .then(departmentsData => departmentsData)
  .catch(error => error);

function* getUserEmployeeId({payload}) {
  const authUser = payload;
  try {
    const getUserId = yield call(getUserIdRequest, authUser);
    if(getUserId.message) {

    } else {
      localStorage.setItem('employee_id', getUserId.data.data.id);
      yield put(getUserIdSuccess(getUserId.data.data.id));
    }
  } catch (error) {

  }
}

function* getAllDepartments({payload}) {
  const authUser = payload;
  const workspace_id = localStorage.getItem('id');
  console.log(workspace_id);
  try {
    const getDepartments = yield call(getDepartmentsRequest, authUser, workspace_id);
    if(getDepartments.message) {

    } else {
      yield put(getDepartmentsSuccess(getDepartments.data));
    }
  }
  catch(error){}
}

export function* getUserId() {
  yield takeEvery(GET_USER_ID, getUserEmployeeId)
}
export function* getDepartments() {
  yield takeEvery(GET_DEPARTMENTS, getAllDepartments)
}

export default function* rootSaga() {
  yield all([fork(getUserId),
  fork(getDepartments)]);
}
