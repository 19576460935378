import React, {Component} from "react";
import {Modal, Form, Input, Checkbox, DatePicker, TimePicker, Select, Button} from 'antd';
import moment from 'moment';

const {TextArea} = Input;

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends Component {
    render() {
      const formItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 8},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 16},
        },
      };
      const tailFormItemLayout = {
        wrapperCol: {
          xs: {
            span: 24,
            offset: 0,
          },
          sm: {
            span: 16,
            offset: 8,
          },
        },
      };
      const { getFieldDecorator } = this.props.form;
      return (
        <Modal
          visible={this.props.visible}
          title={this.props.title}
          onCancel={this.props.onCancel}
          onOk={this.props.onCreate}
          footer={[
            <Button key="submit" loading={this.props.loading} onClick={this.props.onCreate}>
              {this.props.title}
            </Button>,
            <Button key="cancel" onClick={this.props.onCancel}>
              Cancel
            </Button>
          ]}
        >
          <Form >
            <Form.Item
            {...formItemLayout}
            label="Select Entry">
              {getFieldDecorator('entry', {
                rules: [{ required: true, message: 'Please select the Entry!' }],
              })(
                <Select placeholder="Select Entry to edit">
                  {this.props.entries}
                </Select>
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

class SelectEntry extends React.Component {

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onOk(values);
    });
  }

  render() {
    return (
      <div>

        <CollectionCreateForm
          title={this.props.title}
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          onCreate={this.handleCreate}
          value={this.props.value}
          entries={this.props.entries}
          loading={this.props.loading}
        />

      </div>
    );
  }
}

export default SelectEntry;
