import React from "react";
import {connect} from "react-redux";
import {Link, Redirect} from "react-router-dom";
import axios from "axios";

import {
    hideMessage,
    showAuthLoader,
    userSignIn,
    getRoles,
    userSignInSuccess,
} from "appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../constants";

class SignInAs extends React.Component {
    login = (userId, key) => {
        axios.post(`${API_URL_V1}/auth/login-as/${userId}`, {key: key}).then(res => {
            this.props.userSignInSuccess(res.data.token);
            localStorage.setItem('user_id', res.data.token);
            this.props.getRoles();
            this.props.history.push({pathname: '/'})
        }).catch(err => {
            console.log(err);
        })
    }

    componentDidMount() {
        let userId = this.props.match.params.id;
        let params = new URLSearchParams(this.props.location.search);
        let key = params.get('k');
        this.login(userId, key);
    }

    render() {
        const {showMessage, loader, alertMessage, authUser} = this.props;
        const {locale} = this.props;
        if (authUser){
            console.log('Already logged in');
        }

        return (
            <div className="gx-app-login-wrap">
                <div className="gx-app-login-container">
                    <div className="gx-app-login-main-content">
                        <div className="gx-app-logo-content">
                            <div className="gx-app-logo">
                                <img height="60px" alt="example" src={require("assets/images/signin-logo.svg")}/>
                            </div>
                        </div>
                            <div className="gx-loader-view">
                                <CircularProgress/>
                            </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({auth, settings}) => {
    const {loader, alertMessage, showMessage, authUser} = auth;
    const {locale} = settings;
    return {loader, alertMessage, showMessage, authUser, locale}
};

export default connect(mapStateToProps, {
    userSignIn,
    getRoles,
    hideMessage,
    showAuthLoader,
    userSignInSuccess
})(SignInAs);
