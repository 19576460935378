import React from "react";
import {Card} from "antd";
import Widget from "components/Widget";
import TeamCalendarData from "components/dashboard/CRM/TeamCalendarData";

const TeamCalendar = () => {

  return (
    <Widget styleName="gx-card-ticketlist" height="92%">
      <TeamCalendarData classes="gx-w-100 gx-border-0"/>
    </Widget>
  );
};

export default TeamCalendar;
