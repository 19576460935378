import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Col, Row, Input, Tabs} from "antd";
import Auxiliary from "../../../../util/Auxiliary";
import ProjectEmployees from "./ProjectEmployees";
import ProjectTasks from "./ProjectTasks";
import ProjectTaskList from "./ProjectTaskList";
import ProjectCalendar from "./ProjectCalendar";
import CustomStatus from "./CustomStatus";
import CircularProgress from "components/CircularProgress/index";
import Breadcrumbs from "components/Breadcrumbs/index";
import {API_URL_V1} from "../../../../constants";
import axios from "axios";
import moment from "moment";

const TabPane = Tabs.TabPane;

class ProjectDetail extends Component {
  state = {
    employees:'',
    events: [],
    statusType: [],
    loading: true,
  };

  componentDidMount () {
    const project_id = this.props.location.state.projectData.id;
    const {authUser, currentCompany} = this.props;
    axios.all([
      axios.get(`${API_URL_V1}/projects/${project_id}/employees`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/projects/${project_id}/status-types`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
    ])
    .then(axios.spread((employeeData, statusTypeData) => {
      this.setState({...this.state, employees: employeeData.data.data, statusType: statusTypeData.data});
    }))
  }
  callback = (key) => {
    const project_id = this.props.location.state.projectData.id;
    const {authUser} = this.props;
    if(key === "tasklist") {
      axios.all([
        axios.get(`${API_URL_V1}/projects/${project_id}/employees`, {
          headers: {
            'Authorization': 'Bearer '+authUser
          }
        }),
        axios.get(`${API_URL_V1}/projects/${project_id}/status-types`, {
          headers: {
            'Authorization': 'Bearer '+authUser
          }
        })
      ])
      .then(axios.spread((employeeData, statusTypeData) => {
        this.setState({...this.state, employees: employeeData.data.data, statusType: statusTypeData.data});
      }))
    }
    if(key === "taskcalendar") {
      this.setState({...this.state, loading: true});
      axios.all([
        axios.get(`${API_URL_V1}/projects/${project_id}/task-list`, {
          params: {
            order: "-sort_weight",
          },
          headers: {
            'Authorization': 'Bearer '+authUser
          }
        }),
        axios.get(`${API_URL_V1}/projects/${project_id}/status-types`, {
          headers: {
            'Authorization': 'Bearer '+authUser
          }
        })
      ])
      .then(axios.spread((taskListData, statusTypeData) => {
        let events = [];
        let allTasks = [];
        taskListData.data.data.forEach((tasks, index) => {
          tasks.tasks.forEach((task, index) => {
            allTasks.push(task);
          })
        })
        allTasks.forEach((task, index) => {
          events.push({
            ...task,
            "title": task.title,
            "start": task.start_date ? new Date(moment.utc(task.start_date).local()) : '',
            "end": task.due_date ? new Date(moment.utc(task.due_date).local()) : new Date(moment.utc(task.start_date).local()),
          })
        })
        this.setState({...this.state, events: events, statusType: statusTypeData.data, loading: false})

      }))
    }
  }
  render() {
    return (
      <div>
      {this.props.currentCompany === null || !this.state.employees ? <CircularProgress/>:
      <Auxiliary>
        <div className="gx-profile-content">
          <Breadcrumbs />
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <Card className="gx-card" title={this.props.location.state.projectData.name}>
              <Tabs onChange={this.callback} type="card">
                <TabPane tab="Task List" key="tasklist">
                  <ProjectTaskList projectData={this.props.location.state.projectData} currentCompany={this.props.currentCompany} statusType={this.state.statusType} employees={this.state.employees}/>
                </TabPane>
                <TabPane tab="Employees" key="employees">
                  <ProjectEmployees projectData={this.props.location.state.projectData} currentCompany={this.props.currentCompany}/>
                </TabPane>
                <TabPane tab="Task Calendar" key="taskcalendar">
                  <ProjectCalendar projectData={this.props.location.state.projectData} currentCompany={this.props.currentCompany} statusType={this.state.statusType} employees={this.state.employees} events={this.state.events} loading={this.state.loading}/>
                </TabPane>
                <TabPane tab="Custom Status" key="customstatus">
                  <CustomStatus statusType={this.state.statusType} projectData={this.props.location.state.projectData}/>
                </TabPane>
              </Tabs>
              </Card>
            </Col>
          </Row>
        </div>
      </Auxiliary>}</div>
    );
  }
}

const mapStateToProps = ({company, auth}) => {
  const {authUser} = auth;
  const {currentCompany} = company;
  return {currentCompany, authUser}
};

export default connect(mapStateToProps)(ProjectDetail);
