import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Form, Row, Col, Input, Upload, Icon, message} from "antd";
import CircularProgress from "components/CircularProgress/index";
import Auxiliary from "../../../util/Auxiliary";
import {API_URL_V1} from "../../../constants";
import axios from "axios";

const { TextArea } = Input;

class InvoiceSettings extends Component {
  state={
    InvoiceSettings: [],
    loading: true,
  }

  componentDidMount () {
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/invoice-setting`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(invoiceSettingsData => {
      this.setState({...this.state, invoiceSettings: invoiceSettingsData.data, loading: false});
    })
    .catch(error => error)
  }

  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        const {authUser, currentCompany} = this.props;
        axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/invoice-setting`, {
          name: values.name,
          company_address: values.company_address,
          paypal_email: values.paypal_email,
          terms: values.terms,
          due_days: values.due_days,
        }, {
          headers: {
            'Authorization': 'Bearer '+authUser
          }
        })
        .then(invoiceSettingsData => {
          message.success("Information Updated");
          this.setState({...this.state, invoiceSettings: invoiceSettingsData.data});
        })
        .catch(error => error)
      }
    });
  }

  render(){
    const { getFieldDecorator } = this.props.form;

    const formItemLayout = {
      labelCol: {
        xs: {span: 24},
        sm: {span: 6},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 18},
      },
    };
    const inlineItemLayout = {
      labelCol: {
        xs: {span: 24},
        sm: {span: 12},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 12},
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {span: 24, offset: 0},
        sm: {span: 16, offset: 6},
      },
    };

    return(
      <Auxiliary>
        {this.state.loading ? <CircularProgress /> :
        <Form onSubmit={this.handleSubmit}>
          <Form.Item {...formItemLayout} label="Legal Name">
            {getFieldDecorator('name', {
              initialValue: this.state.invoiceSettings.name,
              rules: [{required: true, message: 'Please emter company\'s legal name'}],
            })(<Input placeholder="Company's Legal Name"/>)}
          </Form.Item>
          <Form.Item {...formItemLayout} label="Company Address">
            {getFieldDecorator('company_address', {
              initialValue: this.state.invoiceSettings.company_address,
              rules: [{required: true, message: 'Please enter company\'s address'}],
            })(<TextArea placeholder="Company's Address" autosize={{ minRows: 2 }}/>)}
          </Form.Item>
          <Row>
            <Col span={12}>
              <Form.Item {...inlineItemLayout} label="Paypal Email">
                {getFieldDecorator('paypal_email', {
                  initialValue: this.state.invoiceSettings.paypal_email,
                  rules: [{type: 'email', message: 'The input is not valid E-mail!'},
                          {required: true, message: 'Please enter paypal email'}],
                })(<Input placeholder="Paypal Email"/>)}
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item {...inlineItemLayout} label="Due Time">
                {getFieldDecorator('due_days', {
                  initialValue: this.state.invoiceSettings.due_days || 30,
                  rules: [{required: true, message: 'Please enter due time'}],
                })(<Input placeholder="Due Time (in Days)" addonAfter="Days"/>)}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item {...formItemLayout} label="Terms">
            {getFieldDecorator('terms', {
              initialValue: this.state.invoiceSettings.terms,
            })(<TextArea placeholder="Terms and Conditions" autosize={{ minRows: 2 }}/>)}
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </Form>}
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, currentCompany, currentEmployee}
};

export default connect(mapStateToProps)(Form.create()(InvoiceSettings));
