import React from "react";
import {Icon, Avatar, Divider, Spin} from "antd";
import { Link } from 'react-router-dom'
import {API_URL_V1} from '../../../constants';
import LeavesChart from "./LeavesChart";
import pendingrequest from '../../../assets/images/icons/other/pendingrequest.svg';
import duetask from '../../../assets/images/icons/other/duetask.svg';
import AttendanceChart from "./AttendanceChart";

const WelComeCard = (allData) => {
  const designation = allData.employeeData.contract ? Object.keys(allData.designations).map((designation) =>{
    if(allData.designations[designation].id === allData.employeeData.contract.designation_id) {
      return allData.designations[designation].name;
    }}
  ):'';
  let disabled = (allData.employeeData && allData.employeeData.role) ? allData.employeeData.role.permissions.includes("edit_company") : '';
  return (
    <div>
      <div className="gx-flex-row">
        <div className="gx-timeline-panel-header">
          <div className="gx-timeline-header-img gx-timeline-left">
            <Avatar size="large" className="gx-size-80 gx-rounded-circle" src={allData.employeeData.avatar ? `${API_URL_V1}${allData.employeeData.avatar}` : ''}/>
          </div>
          <div className="gx-timeline-heading">
            <h1 className="gx-mb-2">Welcome {allData.employeeData.first_name} {allData.employeeData.last_name}!</h1>
            <p className="gx-mb-0">EmployeeId: {allData.employeeData.oid || allData.employeeData.id} </p>
            <p className="gx-mb-0">Designation: {designation} </p>
          </div>
        </div>
      </div>
      <div className="gx-flex-row" style={{marginTop: "7%"}}>
        <div className="gx-timeline-body request-data" style={{width: "45%"}}>
          <p className="gx-fs-sm gx-text-uppercase">You Have</p>
          <Spin spinning={allData.spinning}>
            <ul className="gx-list-group" style={{fontSize: "14px"}}>
              <li>
                <img className="gx-mr-1" src={pendingrequest} width="14px"/>
                <Link className={`gx-text-truncate gx-mb-0`} to={{pathname: disabled ? `/employees` : `/requests`, state: {dashboard: true}}} style={{verticalAlign: "middle"}}>{allData.requestcount} Pending request(s)</Link>
              </li>
              <li>
                <img className="gx-mr-1" src={duetask} width="14px"/>
                <Link className={`gx-text-truncate gx-mb-0`} to={{pathname: `/projects`, state: {dashboard: true}}} style={{verticalAlign: "middle"}}>{allData.taskcount} Due task(s)</Link>
              </li>
            </ul>
          </Spin>
        </div>
        <Divider className="dashboard-separator-vertical" type="vertical" style={{height: "150px"}}/>
        <Divider className="dashboard-separator-horizontal" type="horizontal" style={{display: "none"}}/>
        <div className="gx-flex-row pie-chart-data">
          <div className="gx-body" style={{width: "50%"}}>
            <p className="gx-fs-sm gx-text-uppercase" style={{textAlign: "center"}}>Attendance</p>
            {allData.attendance ?
              <Spin spinning={allData.spinning}>
                <AttendanceChart data={allData.attendance}/>
              </Spin>:''
            }
          </div>
          <div className="gx-body" style={{width: "50%"}}>
            <p className="gx-fs-sm gx-text-uppercase"  style={{textAlign: "center"}}>Leaves</p>
            {allData.leaveStats ?
              <Spin spinning={allData.spinning}>
                <LeavesChart data={allData.leaveStats}/>
              </Spin>:''
            }
          </div>
        </div>
    </div>
</div>
  );
};

export default WelComeCard;
