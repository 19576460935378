import React, {Component} from "react";
import {Modal, Form, Input, Button} from 'antd';

const { TextArea } = Input;
const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends Component {
    render() {
      const formItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 8},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 16},
        },
      };

      const { getFieldDecorator } = this.props.form;
      return (
        <Modal
        visible={this.props.visible}
        title={this.props.title}
        onCancel={this.props.onCancel}
        onOk={this.props.onCreate}
        footer={[
          <Button className="add-client-form-button" key="submit" loading={this.props.confirmLoading} onClick={this.props.onCreate}>
            {this.props.title}
          </Button>,
          <Button key="cancel" onClick={this.props.onCancel}>
            Cancel
          </Button>
        ]}
        >
        <Form className="client-form">
          <Form.Item
          {...formItemLayout}
          label="Client Name">
            {getFieldDecorator('name', {
              initialValue: this.props.value.name,
              rules: [{ required: true, message: 'Please input the client Name!' }],
            })(
              <Input placeholder= "Name"/>
            )}
          </Form.Item>
          <Form.Item
          {...formItemLayout}
          label="Contact Name">
            {getFieldDecorator('contact_name', {
              initialValue: this.props.value.contact_name,
              rules: [{ required: true, message: 'Please input the Contact Person Name!' }],
            })(
              <Input placeholder= "Contact Name"/>
            )}
          </Form.Item>
          <Form.Item
          {...formItemLayout}
          label="Email">
            {getFieldDecorator('contact_email', {
              initialValue: this.props.value.contact_email,
              rules: [{type: 'email', message: 'The input is not valid E-mail!'}],
            })(
              <Input placeholder= "Email Address"/>
            )}
          </Form.Item>
          <Form.Item
          {...formItemLayout}
          label="Contact No.">
            {getFieldDecorator('contact_phone', {
              initialValue: this.props.value.contact_phone
            })(
              <Input placeholder= "Contact Number"/>
            )}
          </Form.Item>
          <Form.Item
          {...formItemLayout}
          label="Address">
            {getFieldDecorator('address', {
              initialValue: this.props.value.address,
            })(
              <TextArea placeholder= "Address"/>
            )}
          </Form.Item>

          <Form.Item
          {...formItemLayout}
          label="Hourly Rate">
            {getFieldDecorator('hrate', {
              initialValue: this.props.value.hrate,
            })(
              <Input placeholder= "Hourly Rate"/>
            )}
          </Form.Item>
        </Form>
        </Modal>
      );
    }
  }
);

class ClientForm extends React.Component {

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onOk(values, this.props.value.id);
    });
  }

  render() {
    let title = (this.props.value && this.props.value.id) ? 'Edit Client' : 'Add Client';
    return (
      <div>

      <CollectionCreateForm
      title={title}
      wrappedComponentRef={this.saveFormRef}
      visible={this.props.visible}
      onCancel={this.props.onCancel}
      onCreate={this.handleCreate}
      value={this.props.value}
      confirmLoading={this.props.confirmLoading}
      />

      </div>
    );
  }
}

export default ClientForm;
