import React, {Component} from "react";
import {message, Spin} from "antd";
import {connect} from "react-redux";
import request from "../../requests";
import {API_URL_V1} from "../../constants";


export class EmailVerification extends Component {
  state = {loading: false};
  resendVerification = () => {
  	this.setState({loading: true});
  	request.get(`${API_URL_V1}/email/resend`).then(response => {
  		// console.log(response.data);
  		this.setState({loading: false});
  		if(response.data && response.data.message){
  			message.success(response.data.message);
  		}

  	}).catch(error => {
  		console.log(error);
  		message.error('Failed to resend. Please try later.');
  		this.setState({loading: false});
  	});
  }

  render() {
    
  	const {user} = this.props;

  	if(user.email_verified_at) {
  		return null;
  	}

    return (
      <Spin spinning={this.state.loading}>
    	<div style={{padding: '16px 32px', background: '#fff1f0'}}>
      		Before proceeding, please check your email for a verification link. 
      		If you did not receive the email, <span class="gx-link" onClick={this.resendVerification}>click here to request another</span>.
    	</div>
    </Spin>)
  }
}

const mapStateToProps = ({auth}) => {
  const { user} = auth;
  return {user}
};
export default connect(mapStateToProps, {})(EmailVerification);
