import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Row, Col, Button, Table, Divider, Modal, Select, message} from "antd";
import Breadcrumbs from "components/Breadcrumbs/index";
import Auxiliary from "../../../../util/Auxiliary";
import {API_URL_V1} from "../../../../constants";
import DeviceModal from "./DeviceModal";
import CircularProgress from "components/CircularProgress/index";
import axios from "axios";
import moment from "moment";

let disabled='';
let officeOptions=[];
const Option = Select.Option;

class BiometricDevices extends Component {
  state = {
    devices: [],
    visible: false,
    editData: '',
    title: '',
    confirmLoading: false,
    deleteVisible: false,
    loading: true,
  };

  getDevices = () => {
    const {authUser, currentCompany} = this.props;
    axios.all([
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/biometrics/devices`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
    ])
    .then(axios.spread((biometricData) => {
      console.log(biometricData)
      this.setState({...this.state, devices: biometricData.data.data, loading: false});
    }))
    .catch(error => error)
  }

  componentDidMount () {
    officeOptions=[];
    for(let i = 0; i < (this.props.offices).length; i++) {
      officeOptions.push(<Option value={(this.props.offices[i].id).toString()}>{this.props.offices[i].name}</Option>);
    }
    this.getDevices();
  }

  onClick = () => {
    this.setState({...this.state, visible: true, title: "Add Device", editData: ''});
  }

  handleCancel = () => {
    this.setState({...this.state, visible: false});
  }

  handleOk = (values, id) => {
    this.setState({...this.state, confirmLoading: true});
    const {authUser, currentCompany} = this.props;
    if(!id) {
      axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/biometrics/devices`, {
        name: values.name,
        location_id: values.location_id.toString(),
        serial_no: values.serial_no,
      },
      {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(deviceData => {
        let devices = this.state.devices;
        devices.push(deviceData.data);
        message.success("New Device Added");
        this.setState({...this.state, devices: devices, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        Object.keys(values).forEach((key) => {
          if((error.response.data.errors).hasOwnProperty(key)) {
            message.error(error.response.data.errors[key])
          }
        })
        this.setState({...this.state, confirmLoading: false});
      })
    } else {
      axios.put(`${API_URL_V1}/workspaces/${currentCompany.id}/biometrics/devices/${id}`, {
        name: values.name,
        location_id: values.location_id.toString(),
        serial_no: values.serial_no,
        },
        {
          headers: {
          'Authorization': 'Bearer '+authUser
          }
        }
      )
      .then(deviceData => {
        let devices = this.state.devices;
        devices.forEach((device, index) => {
          if(device.id == deviceData.data.id){
            devices[index] = {...device, ...deviceData.data};
          }
        })
        message.success("Device Editted Successfully");
        this.setState({...this.state, devices: devices, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        Object.keys(values).forEach((key) => {
          if((error.response.data.errors).hasOwnProperty(key)) {
            message.error(error.response.data.errors[key])
          }
        })
        this.setState({...this.state, confirmLoading: false});
      })
    }
  }

  deploy = (record, e) => {
    const {authUser, currentCompany} = this.props;
    axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/biometrics/deploy`, {
      ...(record ? {id: record.id} : {})
    },
    {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(deviceData => {
      message.success(record ? "Device deployed" : "All Devices deployed");
    })
    .catch(error => {
      message.error("Something went wrong");
    })
  }

  edit = (record, e) => {
    this.setState({...this.state, title: "Edit Device", editData: record, visible: true});
  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser, currentCompany} = this.props;
    axios.delete(`${API_URL_V1}/workspaces/${currentCompany.id}/biometrics/devices/${record.id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(projectData => {
      message.success("Device Deleted Successfully");
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      this.getDevices();
    })
    .catch(error => error)
  }

  render() {
    disabled = (this.props.currentEmployee && this.props.currentEmployee.role) ? this.props.currentEmployee.role.permissions.includes("edit_company") : '';
    const columns = [{
        title: 'Serial Number',
        render: (text, record) => (
          <span className='gx-text-hover'>
            {record.serial_no}
          </span>
        )
      }, {
        title: 'Device Name',
        render: (text, record) => (
          <span>
            {record.name}
          </span>
        ),
      },{
        title: 'Office',
        render: (text, record) => (
          <span>
            {this.props.offices.map((office, index) => {
              if(office.id == record.location_id) {
                return office.name
              }
            })}
          </span>
        )
      },{
        title: 'Last Checkin',
        render: (text, record) => (
          <span>
            {record.last_checkin ? moment.utc(record.last_checkin).local().format("YYYY-MM-DD HH:mm:ss") : ''}
          </span>
        )
      },{
        title: 'Manage',
        key: 'x',
        render: (text, record) => (
          <span>
            {disabled ?
            <span>
            <span className="gx-link" onClick={(e) => {this.deploy(record, e)}}><i className="icon icon-upload" title="Deploy" style={{verticalAlign: "middle"}}/></span>
            <Divider type="vertical"/></span> : ''}
            {disabled ?
            <span className="gx-link" onClick={(e) => {this.edit(record, e)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span>:<span></span>}

            {disabled ?
            <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><Divider type="vertical"/><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span>:<span></span>}
          </span>
        )
      }];
    return (
      <div>
        {this.state.loading ?
          <CircularProgress /> :
        <div>
        <Modal
          visible={this.state.deleteVisible}
          title="Delete Device?"
          onOk={(e) => this.deleteRecord(this.state.record, e)}
          onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
          confirmLoading={this.state.deleteLoading}
          footer={[
            <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
              OK
            </Button>,
            <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
              Cancel
            </Button>
          ]}
        >
          <p>Are you Sure you want to delete this device?</p>
        </Modal>
        {this.state.visible ?
        <DeviceModal value={this.state.editData} visible={this.state.visible} title={this.state.title} onCancel={this.handleCancel} onOk={this.handleOk} offices={officeOptions} confirmLoading={this.state.confirmLoading}/> : null}
          <Auxiliary>
            <div className="gx-profile-content">
              <Breadcrumbs />
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Card className="gx-card" title="Biometrics">
                    <div className="gx-mb-5">
                      <div style={{float: 'right'}}>
                        <Button disabled={!disabled || (this.state.devices).length < 1} onClick={() => {this.deploy()}} value="Deploy">Deploy All</Button>
                        <Button disabled={!disabled} onClick={this.onClick} value="Add">Add Device</Button>
                      </div>
                    </div>
                    <Table className="gx-table-responsive" columns={columns} dataSource={this.state.devices} onChange={this.handleTableChange}/>
                  </Card>
                </Col>
              </Row>
            </div>
          </Auxiliary>
        </div> }
      </div>
    )
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, currentCompany, currentEmployee}
};

export default connect(mapStateToProps)(BiometricDevices);
