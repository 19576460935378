import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Card, Table, Modal, Divider, message} from "antd";
import Auxiliary from "../../../../util/Auxiliary";
import DesignationModal from "./DesignationModal";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../../constants";
import axios from "axios";

let data = [];
let workspace_id, disabled;

class Designations extends Component {

  constructor(props){
    super(props)
    this.state = {
      visible: false,
      title: '',
      designations: [],
      loading: true,
      editData: '',
      confirmLoading: false,
      deleteVisible: false,
      deleteLoading: false,
      record: '',
    };
  }

  getDesignations = () => {
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/designations`, {
      params: {
        employee_count: true,
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(designationsData => {
      this.setState({...this.state, designations: designationsData.data.data, loading: false})
    })
    .catch(error => error);
  }

  componentDidMount() {
    this.getDesignations();
  }

  onClick = () => {
    this.setState({...this.state, visible: true, title: "Add Designation", editData: ''});
  }

  handleCancel = () => {
    this.setState({...this.state, visible: false});
  }

  edit = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, visible: true, title: "Edit Designation", editData: record});
  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser, currentCompany} = this.props;
    axios.delete(`${API_URL_V1}/workspaces/${currentCompany.id}/designations/${record.id}`, {
      params: {
        workspace_id: currentCompany.id,
        department_id: record.id,
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(designationData => {
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      this.getDesignations();
      message.success("Designation Deleted Successfully");
    })
    .catch(error => {
      message.error("Failed to Delete");
    })
  }

  handleOk = (values, id) => {
    const {authUser, currentCompany} = this.props;
    this.setState({...this.state, confirmLoading: true});
    if(!id){
      axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/designations`, {
        name: values.designation_name,
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(designationData => {
        let designations = this.state.designations;
        designations.push(designationData.data.data);
        message.success("New Designation Added");
        this.setState({...this.state, designations: designations, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Add Designation");
        this.setState({...this.state, confirmLoading: false});
      })
    } else {
      axios.put(`${API_URL_V1}/workspaces/${currentCompany.id}/designations/${id}`, {
        name: values.designation_name,
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
      })
      .then(designationData => {
        let designations = this.state.designations;
        designations.forEach((designation, index) => {
          if(designation.id === designationData.data.data.id){
            designations[index] = {...designation, ...designationData.data.data};
          }
        })
        message.success("Designation Editted Successfully");
        this.setState({...this.state, designations: designations, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Edit");
        this.setState({...this.state, confirmLoading: false});
      })
    }
  }

  render() {
    disabled = (this.props.currentEmployee && this.props.currentEmployee.role) ? this.props.currentEmployee.role.permissions.includes("edit_company") : '';
    const columns = [{
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => {return a.name.localeCompare(b.name)},
      }, {
        title: 'People',
        dataIndex: 'employees_count',
      }, {
        title: 'Actions',
        key: 'x',
        render: (text, record) => (
          <span>
            {disabled ?
            <span className="gx-link" onClick={(e) => {this.edit(record, e)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span> : <span></span>}

            {disabled ?
                <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><Divider type="vertical"/><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span> :
                <span></span>
            }
          </span>
        )
      }];
    return(
      <div>
      <Modal
        visible={this.state.deleteVisible}
        title="Delete Designation?"
        onOk={(e) => this.deleteRecord(this.state.record, e)}
        onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
        confirmLoading={this.state.deleteLoading}
        footer={[
          <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
            OK
          </Button>,
          <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
            Cancel
          </Button>
        ]}
      >
        <p>Are you Sure you want to delete this Designation?</p>
      </Modal>
      {this.state.loading ?
      <CircularProgress/> :
      <div>
      {this.state.visible ?
        <DesignationModal title={this.state.title} value={this.state.editData} visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel} confirmLoading={this.state.confirmLoading}/> : null }
      <Auxiliary>
        <div style={{paddingBottom: "2.5rem"}}>
          <Button style={{float: "right"}} disabled={!disabled} onClick={this.onClick} value="Add">Add Designation</Button>
        </div>
        <Table className="gx-table-responsive" columns={columns} dataSource={this.state.designations} pagination={false}/>
      </Auxiliary>
      </div>}
      </div>
    );
  };
}
const mapStateToProps = ({auth,company}) => {
  const {authUser} = auth;
  const {currentEmployee, currentCompany} = company;
  return {authUser, currentEmployee, currentCompany}
};

export default connect(mapStateToProps)(Designations);
