import React, {Component} from "react";
import {connect} from "react-redux";
import { Form, Input, Icon, Button, Select, message } from 'antd';
import {API_URL_V1} from "../../../constants";
import axios from "axios";

let id = 0;
const { Option } = Select;
let initVal = [];
let deductionOptions=[];
class PayrollDeductions extends Component {
  state={
    deductions: [],
    adjustments: [],
  };

  componentDidMount () {
    let deductionAdjustments = [];
    this.props.adjustments.forEach((adjustment, index) => {
      if(adjustment.type == "deduction") {
        deductionAdjustments.push(adjustment);
      }
    })
    this.setState({...this.state, deductions: this.props.deductions, adjustments: deductionAdjustments});
  }

  remove = k => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    form.setFieldsValue({
      keys: keys.filter(key => key !== k),
    });
  };

  add = () => {
    const { form } = this.props;
    const keys = form.getFieldValue('keys');
    const nextKeys = keys.concat(initVal.length + id++ );
    form.setFieldsValue({
      keys: nextKeys,
    });
    this.props.adjustmentChange();
  };

  deleteRecord = (id, k) => {
    let employee_id = this.props.employee ? this.props.employee.id : this.props.currentEmployee.id;
    const {authUser} = this.props;
    axios.delete(`${API_URL_V1}/employees/${employee_id}/contracts/${this.props.contract.id}/payroll/adjustments/${id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(data => {
      message.success("Deduction Deleted Successfully");
      this.remove(k);
    })
    .catch(error => error)
  }

  handleSubmit = (e, k, id) => {
    let employee_id = this.props.employee ? this.props.employee.id : this.props.currentEmployee.id;
    e.preventDefault();
    const {authUser} = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        const { keys, names } = values;
        console.log('Received values of form: ', values);
        console.log('Merged values:', keys.map(key => names[key]));
      }
      if(id) {
        axios.put(`${API_URL_V1}/employees/${employee_id}/contracts/${this.props.contract.id}/payroll/adjustments/${id}`, {
            amount: values.value[k].includes("%") ? values.value[k].replace("%", "") : values.value[k],
            type: "deduction",
            category_id: values.names[k],
            calculation_type: values.value[k].includes("%") ? "percent" : "fixed",
          },
          {
          headers: {
            'Authorization': 'Bearer '+authUser
          }
        })
        .then(adjustmentData => {
          let adjustments = this.state.adjustments;
          adjustments.forEach((adjustment, index) => {
            if(adjustment.id == adjustmentData.data.id){
              adjustments[index] = {...adjustment, ...adjustmentData.data};
            }
          })
          message.success("Deduction Editted Successfully");
          this.setState({...this.state, adjustments: adjustments});
        })
        .catch(error => error)
      } else {
        axios.post(`${API_URL_V1}/employees/${employee_id}/contracts/${this.props.contract.id}/payroll/adjustments`, {
            amount: values.value[k].includes("%") ? values.value[k].replace("%", "") : values.value[k],
            type: "deduction",
            category_id: values.names[k],
            calculation_type: values.value[k].includes("%") ? "percent" : "fixed",
          },
          {
          headers: {
            'Authorization': 'Bearer '+authUser
          }
        })
        .then(adjustmentData => {
          let adjustments = this.state.adjustments;
          adjustments.push(adjustmentData.data);
          message.success("Deduction Added Successfully");
          this.setState({...this.state, adjustments: adjustments});
        })
        .catch(error => error)
      }
    });
  };

  render() {
    deductionOptions=[];
    const {currentEmployee} = this.props;
    let canManage = currentEmployee && currentEmployee.role && (currentEmployee.role.permissions.includes("edit_company") || currentEmployee.role.permissions.includes("manage_company"));
    this.state.deductions.forEach((deduction, index) => {
      deductionOptions.push(<Option key={deduction.id} value={(deduction.id).toString()}>{deduction.name}</Option>);
    })
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: {span: 24},
        sm: {span: 8},
      },
      wrapperCol: {
        xs: {span: 24},
        sm: {span: 16},
      },
    };
    const formItemLayoutWithOutLabel = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    initVal = [];
    this.state.adjustments.forEach((adjustment, index) => {
      initVal.push(index);
    })
    console.log(this.state.adjustments)
    getFieldDecorator('keys', { initialValue: initVal });
    const keys = getFieldValue('keys');
    const formItems = keys.map((k, index) => (
      <Form.Item
        {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
        label={index === 0 ? 'Deductions' : ''}
        required={false}
        key={k}
      >
        {getFieldDecorator(`names[${k}]`, {
          initialValue: this.state.adjustments && this.state.adjustments[k] ? (this.state.adjustments[k].category.id).toString() : undefined,
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Please add payroll adjustment name or delete this field.",
            },
          ],
        })(<Select placeholder="Payroll Adjustment" disabled={!canManage} style={{ width: '48%', marginRight: 16 }} >
            {deductionOptions}
          </Select>
        )}
        {getFieldDecorator(`value[${k}]`, {
          initialValue: this.state.adjustments && this.state.adjustments[k] ? this.state.adjustments[k].calculation_type == "percent" ? this.state.adjustments[k].amount +"%" : this.state.adjustments[k].amount : '',
          validateTrigger: ['onChange', 'onBlur'],
          rules: [
            {
              required: true,
              whitespace: true,
              message: "Value",
            },
          ],
        })(<Input placeholder="Value" disabled={!canManage} style={{ width: '43%', marginRight: 8 }} />)}
        {canManage ?
        <Icon
          className="dynamic-submit-button gx-mr-2"
          type="check-circle-o"
          theme="twoTone"
          twoToneColor="#52c41a"
          onClick={(e) => this.handleSubmit(e, k, (this.state.adjustments && this.state.adjustments[k]) ? this.state.adjustments[k].id : '')}
        /> : ''}
        { canManage ?
          <Icon
            className="dynamic-delete-button"
            type="minus-circle"
            theme="twoTone"
            twoToneColor="#f44336"
            onClick={() => (this.state.adjustments && this.state.adjustments[k]) ? this.deleteRecord(this.state.adjustments[k].id, k) : this.remove(k)}
          /> : ''
        }
      </Form.Item>
    ));
    return (
      <Form onSubmit={this.handleSubmit}>
        {formItems}
        <Form.Item {...formItemLayoutWithOutLabel}>
          <Button disabled={!canManage} type="dashed" onClick={this.add} style={{ width: '93%' }}>
            <Icon type="plus" /> Add Deductions
          </Button>
        </Form.Item>
      </Form>
    );
  }
}

const WrappedDynamicFieldSet = Form.create({ name: 'dynamic_form_item' })(PayrollDeductions);

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentEmployee, currentCompany} = company;
  return {authUser, currentEmployee, currentCompany}
};

export default connect(mapStateToProps)(WrappedDynamicFieldSet);
