import React, {Component} from "react";
import WelComeCard from "components/dashboard/CRM/WelcomeCard";
import CircularProgress from "components/CircularProgress";
import Widget from "components/Widget";
import request from '../../../requests';
import axios from "axios";
import {API_URL_V1} from '../../../constants';

class Welcome extends Component {
    state = {
      loading: false,
      leaveStats: [],
      spinning: true,
      taskcount: '',
      requestcount: '',
      attendanceData: [],
    };

    componentDidMount(){
      this.getLeaveStats();
    }

    getLeaveStats = () => {
      this.setState({...this.state, loading: true})
      let id = this.props.employeeData.id;
      axios.all([
        request.get(`${API_URL_V1}/employees/${id}/leave-stats`),
        request.get(`${API_URL_V1}/employees/${id}/tasks`),
        request.get(`${API_URL_V1}/employees/${id}/requests-to-approve`),
        request.get(`${API_URL_V1}/employees/${id}/attendances-yearly`),
      ])
      .then(axios.spread((response, taskData, requestData, attendanceData) => {
        this.setState({...this.state, leaveStats: response.data.data, taskcount: (taskData.data.data).length, requestcount: (requestData.data.data).length, attendanceData: attendanceData.data.data, loading: false})
      }))
      .catch(error => {
        this.setState({...this.state, loading: false})
      })
    }

    render() {
      return (
        <Widget height="92%">
          {(this.props.employeeData && this.props.employeeData.contract) ?
            <WelComeCard spinning={this.state.loading} employeeData={this.props.employeeData} leaveStats={this.state.leaveStats} taskcount={this.state.taskcount}
            requestcount={this.state.requestcount} designations={this.props.designations} attendance={this.state.attendanceData}/> : <CircularProgress height="300px"/>}
        </Widget>
      );
    }
  }
export default Welcome;
