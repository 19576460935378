import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import SurveyModal from "./SurveyModal";
import {Card, Table, Divider, Button, Modal, message} from "antd";
import CircularProgress from "components/CircularProgress/index";
import Auxiliary from "../../../../util/Auxiliary";
import {API_URL_V1} from "../../../../constants";
import axios from "axios";

class EmployeeSurvey extends Component {
  state = {
    surveys: [],
    visible: false,
    editData: '',
    record: '',
    loading: true,
    confirmLoading: false,
  }

  getSurveys = () => {
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/es/survey`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(surveysData => {
      this.setState({...this.state, surveys: surveysData.data.data, loading: false});
    })
    .catch(error => error)
  }

  componentDidMount () {
    this.getSurveys();
  }

  onClick = () => {
    this.setState({...this.state, visible: true, title: "Create Survey", editData: ''});
  }

  edit = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, visible: true, title: "Edit Survey", editData: record});
  }

  handleOk = (values, id) => {
    const {authUser, currentCompany} = this.props;
    this.setState({...this.state, confirmLoading: true});
    if(!id) {
      axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/es/survey`, {
          name: values.name,
          enabled: values.enabled,
          privacy: values.privacy,
          recurring: values.recurring,
        },
        {
          headers: {
            'Authorization': 'Bearer '+authUser
        }
      })
      .then(surveysData => {
        let surveys = this.state.surveys;
        surveys.push(surveysData.data);
        message.success("New Survey Added");
        this.setState({...this.state, surveys: surveys, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Add Question");
        this.setState({...this.state, confirmLoading: false});
      })
    } else {
      axios.put(`${API_URL_V1}/workspaces/${currentCompany.id}/es/survey/${id}`, {
        name: values.name,
        enabled: values.enabled,
        privacy: values.privacy,
        recurring: values.recurring,
        },
        {
          headers: {
          'Authorization': 'Bearer '+authUser
          }
        }
      )
      .then(surveysData => {
        let surveys = this.state.surveys;
        surveys.forEach((survey, index) => {
          if(survey.id == surveysData.data.id){
            surveys[index] = {...survey, ...surveysData.data};
          }
        })
        message.success("Survey Editted Successfully");
        this.setState({...this.state, surveys: surveys, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Edit");
        this.setState({...this.state, confirmLoading: false});
      })
    }
  }

  handleCancel = () => {
    this.setState({...this.state, visible: false});
  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser, currentCompany} = this.props;
    axios.delete(`${API_URL_V1}/workspaces/${currentCompany.id}/es/survey/${record.id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(eventsData => {
      message.success("Survey Deleted Successfully");
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      this.getSurveys();
    })
    .catch(error => error)
  }

  render () {
    const {currentEmployee} = this.props;
    let canManage = currentEmployee && currentEmployee.role && (currentEmployee.role.permissions.includes("edit_company") || currentEmployee.role.permissions.includes("manage_company"));
    const columns = [{
        title: 'Survey Name',
        render: (text, record) => (
          <span className="gx-link" onClick={(e) => {this.props.history.push({pathname: '/survey/'+record.id+'/survey_detail', state: {surveyData: record}})}}>{record.name}</span>
        )
      }, {
        title: 'Recurrence',
        render: (text, record) => (
          <span>
          {record.recurring ? "Recurring Survey" : "One Time Survey"}
          </span>
        )
      }, {
        title: 'Enabled',
        render: (text, record) => (
          <span>
          {<i className={`icon ${record.enabled ? "icon-check-circle-o" : "icon-close-circle"}`} title="Edit" style={{verticalAlign: "middle", color: record.enabled ? "green" : "red"}}/>}
          </span>
        )
      }, {
        title: 'Actions',
        key: 'x',
        render: (text, record) => {
          return(
          <span>
            {canManage ?
            <span className="gx-link" onClick={(e) => {this.edit(record, e)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span> : <span></span>}

            {canManage ?
                <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><Divider type="vertical"/><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span> :
                <span></span>
            }
          </span>
        )}
      }];
    return (
      <div>
        {this.state.loading ? <CircularProgress /> :
        <div>
          <Modal
            visible={this.state.deleteVisible}
            title="Delete Survey?"
            onOk={(e) => this.deleteRecord(this.state.record, e)}
            onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
            confirmLoading={this.state.deleteLoading}
            footer={[
              <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
                OK
              </Button>,
              <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
                Cancel
              </Button>
            ]}
          >
            <p>Are you Sure you want to delete this survey?</p>
          </Modal>
          {this.state.visible ?
          <SurveyModal visible={this.state.visible} value={this.state.editData} title={this.state.title} onCancel={this.handleCancel} onOk={this.handleOk} loading={this.state.confirmLoading}/> : null}
          <Auxiliary>
            { canManage && <div style={{paddingBottom: "2.5rem"}}>
              <Button style={{float: "right"}} onClick={this.onClick} value="Add">Create Survey</Button>
            </div> }
            <Table className="gx-table-responsive" columns={columns} dataSource={this.state.surveys} onChange={this.handleTableChange}/>
          </Auxiliary>
        </div>}
      </div>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, currentCompany, currentEmployee}
};

export default withRouter(connect(mapStateToProps)(EmployeeSurvey));
