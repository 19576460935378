import React from "react";
import {Link} from "react-router-dom";
import backgroundImage from "../assets/images/signIn.svg";

class Thankyou extends React.Component {

  state = {
    time: 10,
  }

  componentDidMount() {
    setInterval(() => this.setState({ time: this.state.time > 0 ? this.state.time-1 : 0 }), 1000);
  }

  render() {

    return (
      <div className="gx-app-login-wrap" style={{background: `url(${backgroundImage})`, backgroundSize: "contain", backgroundRepeat: "no-repeat", backgroundColor: "#fff"}}>
        <div className="gx-app-login-container">
          <div className="gx-login-content">

            <div className="gx-login-header">
              <img src={require("assets/images/logo-colored.png")} alt="CuteHR" title="CuteHR"/>
            </div>
            <div className="gx-mb-4">
              <h1>Thankyou for Signing Up</h1>
              <p>A verification email is sent to you email address, please verify.</p>
              <p>If not received, please check your spam folder also.</p>
              <p>Redirecting to <Link to="/signin">SignIn</Link> page {this.state.time} seconds</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Thankyou;
