import ReactGA from 'react-ga'

const options = {}

const trackPage = (page) => {
  ReactGA.set({
    page,
    ...options
  })
  ReactGA.pageview(page)
}

let currentPage = ''

export const googleAnalytics = store => next => action => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    
    const nextPage = `${action.payload.pathname}${action.payload.search}`

    if (currentPage !== nextPage) {
      currentPage = nextPage
      if (action.payload.pathname == '/signup' || action.payload.pathname == '/thank_you') {
        trackPage(nextPage);
      }      
    }
  }

  return next(action)
}