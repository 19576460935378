import React, {Component} from "react";
import {Card, List, Button, Row, Col} from "antd";
import ApplyForm from "./ApplyForm";
import Auxiliary from "../util/Auxiliary";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../constants";
import axios from "axios";

class OpeningDetails extends Component {
  state={
    opening: this.props.opening,
    showForm: false,
    loading: true,
  };

  componentDidMount () {
    let company_id = this.props.match.params.id;
    let opening_id = this.props.match.params.job_id;
    axios.get(`${API_URL_V1}/public/workspaces/${company_id}/ats/openings/${opening_id}`, {
    })
    .then(openingData => {
      let opening = [];
      opening.push(openingData.data)
      this.setState({...this.state, opening: opening, loading: false});
    })
    .catch(error => error)
  }

  apply = e => {
    e.preventDefault();
    this.setState({...this.state, showForm: true});
  }

  hideForm = () => {
    this.setState({...this.state, showForm: false});
  }

  goBack = () => {
    let company_id = this.props.match.params.id;
    this.props.history.push({pathname: '/openings/'+company_id});
  }

  render() {
    return(
      <Auxiliary>
        {this.state.loading ? <CircularProgress /> :
          <div style={{height: "100%", backgroundColor: "white"}}>
          <Row>
            <Col offset={5} span={14} >
            {this.state.showForm ? <ApplyForm job={this.state.opening} goBack={this.hideForm}/> :
              <Card className="gx-opening-card" title="Job Opening" bordered={false} style={{height: "100%"}}>
                <List
                  itemLayout="vertical"
                  bordered
                  dataSource={this.state.opening}
                  renderItem={item => (
                    <List.Item
                      actions={[<Button type="primary" key="button-apply" onClick={(e) => {this.apply(e)}}>Apply</Button>,<Button type="default" onClick={() => {this.goBack()}}>Go Back</Button>]}
                    >
                      <List.Item.Meta
                        title={
                          <div>
                            <div>{item.title}</div>
                            <div>
                              <span>Type: {item.position_type.toUpperCase().replace("_", " ")}</span>
                              <span style={{fontWeight: 400, marginLeft: "20px"}}><i className="icon icon-location" title="Location" style={{verticalAlign: "middle"}}/>{item.location.city}, {item.location.country}</span>
                            </div>
                          </div>
                        }
                        description={item.description ? <div id={`job-description${item.id}`} className="html-editor__content" dangerouslySetInnerHTML={{__html: item.description}} />
                        : "No description provided"}
                      />
                    </List.Item>
                  )}
                />
              </Card>
            }
            </Col>
          </Row>
          </div>
        }
      </Auxiliary>
    );
  }
}

export default OpeningDetails;
