import React, {Component} from "react";
import {connect} from "react-redux";
import QuestionModal from "./QuestionModal";
import {Card, Table, Divider, Button, Modal, message} from "antd";
import CircularProgress from "components/CircularProgress/index";
import Auxiliary from "../../../../../util/Auxiliary";
import {API_URL_V1} from "../../../../../constants";
import axios from "axios";

class SatisfactionQuestion extends Component {
  state = {
    esQuestions: [],
    visible: false,
    editData: '',
    record: '',
    loading: true,
    confirmLoading: false,
    sendModal: false,
    sendLoading: false,
  }

  getQuestions = (survey_id) => {
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/es/survey/${this.props.survey_id}/question`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(questionsData => {
      this.props.getData(questionsData);
      this.setState({...this.state, esQuestions: questionsData.data, loading: false});
    })
    .catch(error => error)
  }

  componentDidMount () {
    this.getQuestions();
  }

  onClick = () => {
    this.setState({...this.state, visible: true, title: "Add Question", editData: ''});
  }

  edit = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, visible: true, title: "Edit Question", editData: record});
  }

  handleOk = (values, id) => {
    const {authUser, currentCompany} = this.props;
    this.setState({...this.state, confirmLoading: true});
    if(!id) {
      axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/es/survey/${this.props.survey_id}/question`, {
          question: values.question,
          answer_type: values.answer_type,
          enabled: values.enabled,
        },
        {
          headers: {
            'Authorization': 'Bearer '+authUser
        }
      })
      .then(questionsData => {
        let questions = this.state.esQuestions;
        questions.push(questionsData.data);
        message.success("New Question Added");
        this.setState({...this.state, esQuestions: questions, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Add Question");
        this.setState({...this.state, confirmLoading: false});
      })
    } else {
      axios.put(`${API_URL_V1}/workspaces/${currentCompany.id}/es/survey/${this.props.survey_id}/question/${id}`, {
        question: values.question,
        answer_type: values.answer_type,
        enabled: values.enabled,
        },
        {
          headers: {
          'Authorization': 'Bearer '+authUser
          }
        }
      )
      .then(questionsData => {
        let questions = this.state.esQuestions;
        questions.forEach((question, index) => {
          if(question.id == questionsData.data.id){
            questions[index] = {...question, ...questionsData.data};
          }
        })
        message.success("Question Editted Successfully");
        this.setState({...this.state, esQuestions: questions, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Edit");
        this.setState({...this.state, confirmLoading: false});
      })
    }
  }

  handleCancel = () => {
    this.setState({...this.state, visible: false});
  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser, currentCompany} = this.props;
    axios.delete(`${API_URL_V1}/workspaces/${currentCompany.id}/es/survey/${this.props.survey_id}/question/${record.id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(eventsData => {
      message.success("Question Deleted Successfully");
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      this.getQuestions();
    })
    .catch(error => error)
  }

  sendSurvey = () => {
    const {authUser, currentCompany} = this.props;
    this.setState({...this.state, sendLoading: true});
    axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/es/survey/${this.props.survey_id}/send`, {},
      {
        headers: {
          'Authorization': 'Bearer '+authUser
      }
    })
    .then(sendData => {
      this.setState({...this.state, sendModal: false, sendLoading: false});
      message.success("Email sent Successfully");
    })
    .catch(error => {
      this.setState({...this.state, sendLoading: false});
      message.error("Failed to send Email");
    })
  }

  render () {
    const {currentEmployee} = this.props;
    let canManage = currentEmployee && currentEmployee.role && (currentEmployee.role.permissions.includes("edit_company") || currentEmployee.role.permissions.includes("manage_company"));
    const columns = [{
        title: 'Question',
        render: (text, record) => (
          <span>
          {record.question}
          </span>
        )
      }, {
        title: 'Type',
        render: (text, record) => (
          <span>
          {record.answer_type}
          </span>
        )
      }, {
        title: 'Enabled',
        render: (text, record) => (
          <span>
          {<i className={`icon ${record.enabled ? "icon-check-circle-o" : "icon-close-circle"}`} title="Edit" style={{verticalAlign: "middle", color: record.enabled ? "green" : "red"}}/>}
          </span>
        )
      }, {
        title: 'Actions',
        key: 'x',
        render: (text, record) => {
          return(
          <span>
            {canManage ?
            <span className="gx-link" onClick={(e) => {this.edit(record, e)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span> : <span></span>}

            {canManage ?
                <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><Divider type="vertical"/><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span> :
                <span></span>
            }
          </span>
        )}
      }];
    return (
      <div>
        {this.state.loading ? <CircularProgress /> :
        <div>
          <Modal
            visible={this.state.deleteVisible}
            title="Delete Question?"
            onOk={(e) => this.deleteRecord(this.state.record, e)}
            onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
            confirmLoading={this.state.deleteLoading}
            footer={[
              <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
                OK
              </Button>,
              <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
                Cancel
              </Button>
            ]}
          >
            <p>Are you Sure you want to delete this question?</p>
          </Modal>
          <Modal
            visible={this.state.sendModal}
            title="Send Survey?"
            onOk={(e) => this.sendSurvey()}
            onCancel={(e) => {this.setState({...this.state, sendModal: false})}}
            confirmLoading={this.state.sendLoading}
            footer={[
              <Button key="submit" loading={this.state.sendLoading} onClick={(e) => this.sendSurvey()}>
                OK
              </Button>,
              <Button key="cancel" onClick={(e) => {this.setState({...this.state, sendModal: false})}}>
                Cancel
              </Button>
            ]}
          >
            <p>Send this survey to all employees?</p>
          </Modal>
          {this.state.visible ?
          <QuestionModal visible={this.state.visible} value={this.state.editData} title={this.state.title} onCancel={this.handleCancel} onOk={this.handleOk} loading={this.state.confirmLoading}/> : null}
          <Auxiliary>
            <div style={{paddingBottom: "2.5rem"}}>
              <span style={{float: 'right'}}>
                <Button onClick={() => {this.setState({...this.state, sendModal: true})}} disabled={!(canManage && this.state.esQuestions.length !== 0)}>Send Now</Button>
                <Button onClick={this.onClick} disabled={!canManage} value="Add">Add Question</Button>
              </span>
            </div>
            <Table className="gx-table-responsive" columns={columns} dataSource={this.state.esQuestions} onChange={this.handleTableChange}/>
          </Auxiliary>
        </div>}
      </div>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, currentCompany, currentEmployee}
};

export default connect(mapStateToProps)(SatisfactionQuestion);
