import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Table, Spin} from "antd";
import request from '../../../requests';
import Auxiliary from "../../../util/Auxiliary";
import Breadcrumbs from "components/Breadcrumbs/index";
import {API_URL_V1} from '../../../constants';
import axios from "axios";

class EmployeeTasks extends Component {

  state = {
    employee: this.props.location.state.employee,
    tasks: [],
    projects: '',
    spinning: true,
  };

  componentDidMount () {
    const {authUser, currentCompany} = this.props;
    axios.all([
      request.get(`${API_URL_V1}/employees/${this.props.match.params.id}/tasks`),
      request.get(`${API_URL_V1}/workspaces/${currentCompany.id}/projects`),
    ])
    .then(axios.spread((tasks, projects) => {
      this.setState({...this.state, tasks: tasks.data.data, projects: projects.data.data, spinning: false})
    }))
  }

  taskDetail = (record, e) => {
    e.preventDefault();
    this.props.history.push({
      pathname: `/projects/${record.project_id}/project_detail/task_detail`,
      state: {taskId: record.id},
    })
  }

  render() {
    const columns = [{
        title: 'ID',
        dataIndex: 'id',
      }, {
        title: "Task Title",
        render: (text, record) => (
          <span className="gx-link" onClick={(e) => {this.taskDetail(record, e)}}>{record.title}</span>
        )
      }, {
        title: 'Project',
        render: (text, record) => (
          <span>
              {this.state.projects.map((project, index) => {
                  if(project.id == record.project_id) {
                    return project.name;
                  }
                }
              )}
          </span>
        )
      }, {
        title: "Due Date",
        render: (text, record) => (
          <span>
            {record.due_date ? record.due_date : "No due date"}
          </span>
        )
      }]
    return (
      <Auxiliary>
        <Breadcrumbs/>
        <Spin spinning={this.state.spinning}>
          <Card title={this.state.employee.first_name+" "+this.state.employee.last_name+"'s Tasks"}>
            <Table className="gx-table-responsive" dataSource={this.state.tasks} columns={columns} pagination={false}/>
          </Card>
        </Spin>
      </Auxiliary>
    )
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany} = company;
  return {authUser, currentCompany}
};

export default connect(mapStateToProps, {})(EmployeeTasks);
