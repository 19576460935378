import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Row, Col} from "antd";
import Auxiliary from "../../../util/Auxiliary";
import {API_URL_V1} from "../../../constants";
import ReportFilters from "./ReportFilters";
import ReportChart from "./ReportChart";
import TaskReportChart from "./TaskReportChart";
import TimeEntries from "./TimeEntries";
import SummaryReportChart from "./SummaryReportChart";
import GroupingFilters from "./GroupingFilters";
import CircularProgress from "components/CircularProgress/index";
import axios from "axios";
import moment from "moment";

class SummaryReport extends Component {

  state={
    chartData: [],
    timeEntriesData: [],
    projectData: [],
    clientData: [],
    employeeData: [],
    taskReportData: [],
    loading: true,
    employee_ids: '',
    client_ids: '',
    project_ids: '',
    grouping: "client_id",
    sub_grouping: "project_id",
    start_date: moment().startOf('isoWeek').format("YYYY-MM-DD"),
    end_date: moment().endOf('isoWeek').format("YYYY-MM-DD"),
    resolution: '',
  }

  getReportData = (params) => {
    const {authUser, currentCompany} = this.props;
    axios.all([
      axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/reports/tracker/totals`, {
          start_date: this.state.start_date,
          end_date: this.state.end_date,
          employee_ids: this.state.employee_ids,
          project_ids: this.state.project_ids,
          client_ids: this.state.client_ids,
        }, {
          headers: {
            'Authorization': 'Bearer '+authUser
          }
      }),
      axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/reports/tracker/time_entries`, {
          start_date: this.state.start_date,
          end_date: this.state.end_date,
          grouping: this.state.grouping,
          sub_grouping: this.state.sub_grouping,
          employee_ids: this.state.employee_ids,
          project_ids: this.state.project_ids,
          client_ids: this.state.client_ids,
        },{
          headers: {
            'Authorization': 'Bearer '+authUser
          }
      }),
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/tasks-report`, {
        params: {
          start_date: this.state.start_date,
          end_date: this.state.end_date,
          grouping: this.state.grouping,
          sub_grouping: this.state.sub_grouping,
          employee_ids: this.state.employee_ids,
          project_ids: this.state.project_ids,
          client_ids: this.state.client_ids,
        },
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
    ])
    .then(axios.spread((reportData, timeEntriesData, taskReportData, projectData, clientData) => {
      this.setState({...this.state, chartData: reportData.data.graph, resolution: reportData.data.resolution, timeEntriesData: timeEntriesData.data.groups, taskReportData: taskReportData.data, loading: false});
    }))
    .catch(error => error)
  }

  componentDidMount () {
    this.setState({...this.state, employeeData: this.props.employees, projectData: this.props.projectData, clientData: this.props.clientData});
    this.getReportData();
  }

  filterData = (params) => {
    console.log(params)
    this.setState({
      ...this.state,
      employee_ids : (params && params.employees && (params.employees).length > 0) ? params.employees : (this.state.employee_ids.length) ===0 ? '' : this.state.employee_ids,
      project_ids : (params && params.project && (params.project).length > 0) ? params.project : (this.state.project_ids.length) ===0 ? '' : this.state.project_ids,
      client_ids: (params && params.clients && (params.clients).length > 0) ? params.clients : (this.state.client_ids.length) ===0 ? '' : this.state.client_ids,
      grouping: (params && params.group) ? params.group : "client_id",
      sub_grouping: (params && params.subgroup) ? params.subgroup : "project_id",
      start_date: (params && params.date) ? moment(params.date[0]).format("YYYY-MM-DD") : this.state.start_date,
      end_date: (params && params.date) ? moment(params.date[1]).format("YYYY-MM-DD") : this.state.end_date,
    },() => this.getReportData(params))
  }

  render() {
    return (
      <Auxiliary>
        <Card className="gx-card">
        {this.state.loading ?
          <CircularProgress height="5%"/> :
          <ReportFilters projects={this.state.projectData} clients={this.state.clientData} employees={this.state.employeeData} filterData={this.filterData} page="SummaryReport"/>
        }
        </Card>
        <Card className="gx-card">
          {this.state.loading ?
            <CircularProgress height="200px"/> :
            <div className="reports-week">
              <ReportChart data={this.state.chartData} resolution={this.state.resolution}/>
            </div>
          }
        </Card>
        <Card className="gx-card">
        {this.state.loading ?
          <CircularProgress height="200px"/> :
          <div className="tasks-report">
            <span style={{fontWeight: 600}}>Task Reports</span>
            {(this.state.taskReportData.created).length !== 0 || (this.state.taskReportData.completed).length !== 0  ?
            <TaskReportChart data={this.state.taskReportData} resolution={this.state.resolution}/> : <div style={{textAlign: "center"}}>No Data to display</div>}
          </div>
        }
        </Card>
        <Card className="gx-card">
          {this.state.loading ?
            <CircularProgress height="200px"/> :
            <Row>
              <Col xl={17} lg={14} md={14} sm={24} xs={24}>
                {((this.state.timeEntriesData).length !== 0) ?
                <div>
                  <GroupingFilters filterData={this.filterData}/>
                  <TimeEntries timeEntriesData={this.state.timeEntriesData} projectData={this.state.projectData} employeeData={this.state.employeeData} clientData={this.state.clientData} group={this.state.grouping} subgroup={this.state.sub_grouping}/>
                </div> : "No Data to display"}
              </Col>
              <Col xl={7} lg={10} md={10} sm={24} xs={24}>
                <SummaryReportChart graphData={this.state.timeEntriesData} projectData={this.state.projectData} employeeData={this.state.employeeData} clientData={this.state.clientData} group={this.state.grouping}/>
              </Col>
            </Row>
          }
        </Card>
      </Auxiliary>
    );
  }
}
const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany} = company;
  return {authUser, currentCompany}
};

export default connect(mapStateToProps)(SummaryReport);
