import React, {Component} from "react";
import {connect} from "react-redux";
import {Row, Col, Card, Input, Button, Divider, Dropdown, Menu, Form, Spin, Pagination, message} from "antd";
import Auxiliary from "util/Auxiliary";
import AppModuleHeader from "components/AppModuleHeader/index";
import {FaDollarSign, FaTag} from "react-icons/fa";
import {API_URL_V1, WS_HOST} from "../../../constants";
import CircularProgress from "components/CircularProgress/index";
import TimeEntry from "./TimeEntry";
import Tracker from "./Tracker";
import {setCurrentTimer} from "appRedux/actions/Company";
import moment from 'moment';
import axios from "axios";
import io from "socket.io-client";
import Echo from "laravel-echo";
import CurrentEntry from './CurrentEntry';
import _ from "lodash";

let project, currentTimerId;
let projectOptions=[];
class Timer extends Component {
  constructor(props) {
    super(props);
    this.echo = new Echo({
      broadcaster: 'socket.io',
      host: WS_HOST,
      auth: {
        headers: {
          Authorization: 'Bearer '+ props.authUser
        }
      },
      namespace: 'CuteHr\\Events',
      client: io
    });
  }
  state={
    allEntries: [],
    projectData: '',
    loading: true,
    iconLoading: false,
    spinning: true,
    pagination: {current: 1, pageSize: 50, total: 0},
  }


  setupEcho(currentEmployee){
    // return;
    const {authUser, currentCompany} = this.props;
    if(!currentEmployee ) {
      return;
    }
    this.echo.private(`employee.${currentEmployee.id}`)
        .listen('TimeEntryUpdated', (e) => {
          this.updateEntry(e.entry);
        });
  }

  updateEntry = (entry) => {
    let entries = this.state.allEntries;
    let isNew = true;
    entries.forEach((item, index) => {
      if(item.id == entry.id) {
        entries[index] = {...item, ...entry}
        isNew = false;
      }
    })
    if (isNew) {
      entries.push(entry);
    }
    entries.sort(function(a, b) {
      return moment(b.start) - moment(a.start);
    });
    this.setState({...this.state, allEntries: entries})
  }

  getTimeData() {
    this.setState({...this.state, spinning: true});
    if(this.props.currentCompany !== null && this.props.currentEmployee !== null) {
      const {authUser, currentCompany, currentEmployee} = this.props;
      axios.all([
        axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/time_entries`, {
          params:{
            employee_id: currentEmployee.id,
            page: this.state.pagination.current, per_page: this.state.pagination.pageSize,
          },
          headers: {
            'Authorization': 'Bearer '+authUser
          }
        }),
        axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/projects`, {
          headers: {
            'Authorization': 'Bearer '+authUser
          }
        }),
      ])
      .then(axios.spread((allEntriesData, projectData) => {
        let start = false;
        let diff = 0, description='', projectId, projectName;
        projectOptions=[];
        projectOptions.push(<Menu.Item key={0}>{"No Project"}</Menu.Item>);
        for(let i = 0; i < (projectData.data.data).length; i++) {
          projectOptions.push(<Menu.Item key={(projectData.data.data[i].id).toString()}>{projectData.data.data[i].name}</Menu.Item>);
        }
        this.setState({...this.state, allEntries: allEntriesData.data.data, projectData: projectData.data.data, start: start, time: diff, value: description, projectId: projectId, selectedProject: (projectName && projectName.length > 0) ? projectName[0].name : '', spinning: false, loading: false, pagination:{...this.state.pagination, total: (allEntriesData.data.meta.total)}});
      }))
      .catch(error => error)
    }
  }

  componentDidMount() {
    this.getTimeData();
    if(this.props.currentEmployee) {
      this.setupEcho(this.props.currentEmployee);
    }
  }

  difference = (object, base) => {
  	function changes(object, base) {
  		return _.transform(object, function(result, value, key) {
  			if (!_.isEqual(value, base[key])) {
  				result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
  			}
  		});
  	}
  	return changes(object, base);
  }

  componentWillReceiveProps(nextProps) {
    const change = this.difference(nextProps, this.props)
    if(Object.keys(change).length !== 0) {
      this.getTimeData();
      if(!this.props.currentEmployee && nextProps.currentEmployee) {
        this.setupEcho(nextProps.currentEmployee);
      }
    }
  }

  onClick = () => {
    this.setState({...this.state, billable: !this.state.billable}, () => {message.success(this.state.billable ? "Time Entry marked as Billable" : "Time Entry marked as Non-Billable")});
  }

  handleMenuClick = (e) => {
    if(e.key == 0) {
      this.setState({...this.state, selectedProject: '', projectId: ''}, () => this.state.start ? this.handleInputChange() : '');
    } else {
      this.setState({...this.state, selectedProject: e.item.props.children, projectId: e.key}, () => this.state.start ? this.handleInputChange() : '');
    }
  }

  onStart = () => {
    this.setState({...this.state, iconLoading: true});
    const {authUser, currentCompany} = this.props;
    const inputVal = document.getElementById("project-description").value;
    let url = this.state.start ? `${API_URL_V1}/workspaces/${currentCompany.id}/time_entries/${this.state.timerId ? this.state.timerId : currentTimerId}/stop` : `${API_URL_V1}/workspaces/${currentCompany.id}/time_entries/start`;
    let method = this.state.start ? 'put' : 'post';
    let data = this.state.start ? {} : {
      description: inputVal,
      project_id: this.state.projectId,
      task_id: '',
      billable: this.state.billable ? 1 : 0,
    }
    axios({method: method, url: url, data: data,
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(timeData => {
      this.setState({...this.state, time: 0, start: !this.state.start, timerId: timeData.data.data.id, iconLoading: false});
      if(!this.state.start) {
        this.setState({...this.state, selectedProject: '', projectId: ''});
        document.getElementById("project-description").value = "";
        this.getTimeData();
      }
    })
    .catch(error => error)
  }

  delete = (id) => {
    const {authUser, currentCompany} = this.props;
    axios.delete(`${API_URL_V1}/workspaces/${currentCompany.id}/time_entries/${id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(timeEntryData => {
      message.success("Time Entry Deleted Successfully");
      this.getTimeData();
    })
    .catch(error => error)
  }

  onAddEntry = (data) => {
    const {authUser, currentCompany} = this.props;
    axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/time_entries`, {
      description: data.id ? data.description : '',
      project_id: data.id ? data.project_id : '',
      task_id: data.id ? data.task_id : '',
      billable: data.id ? data.billable : '',
      start: data.id ? moment(data.start).format("YYYY-MM-DD HH:mm:ss") : moment(data).format("YYYY-MM-DD HH:mm:ss"),
      end: data.id ? moment(data.end).format("YYYY-MM-DD HH:mm:ss") : moment(data).add(1, 'hours').format("YYYY-MM-DD HH:mm:ss"),
      },
      {
        headers: {
          'Authorization': 'Bearer '+authUser
      }
    })
    .then(timerData => {
      message.success(data.id ? "Entry Duplicated Successfully" : "Dummy Entry Added")
      this.getTimeData();
    })
    .catch(error => error);
  }

  resumeEntry = (data) => {
    const {authUser, currentCompany} = this.props;
    if(this.state.start) {
      axios.put(`${API_URL_V1}/workspaces/${currentCompany.id}/time_entries/${this.state.timerId ? this.state.timerId : currentTimerId}/stop`, {}, {
        headers: {
          "Authorization": "Bearer "+authUser
        }
      })
      .then(timerData => {
        this.setState({...this.state, start: !this.state.start});
        this.props.setCurrentTimer(null);
        this.play(data);
      })
      .catch(error => error)
    } else {
      this.play(data);
    }
  }

  play = (data) => {
    const {authUser, currentCompany} = this.props;
    axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/time_entries`, {
      description: data.description,
      project_id: data.project_id,
      task_id: '',
      billable: data.billable,
      start: moment.utc().format("YYYY-MM-DD HH:mm:ss"),
    },{
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(startTimerData => {
      let projectName= this.state.projectData.filter((project) => {
        if(data.project_id === project.id) {
          return project.name;
        }
      })
      this.setState({...this.state, time: 0, start: !this.state.start, timerId: startTimerData.data.data.id, selectedProject: (projectName.length > 0) ? projectName[0].name : '', projectId: startTimerData.data.data.project_id, value: startTimerData.data.data.description});
      this.props.setCurrentTimer(startTimerData.data.data)
      message.success('Timer Started');
      this.getTimeData();
    })
    .catch(error => error)
  }

  setData = (method, url, data) => {
    const {authUser} = this.props;
    axios({method: method, url: url, data: data,
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(timeData => {
      if(!timeData.data.data.end) {
        this.props.setCurrentTimer(timeData.data.data);
      }
      message.success("Timer Entry Updated");
      this.getTimeData();
    })
    .catch(error => error)

  }

  handleInputChange = (projectId, id, value) => {
    if(!this.state.start && !id) {
      this.onStart();
    } else {
      const {authUser, currentCompany} = this.props;
      const inputVal = (id && value) ? value : !id ? document.getElementById("project-description").value : '';
      const url = `${API_URL_V1}/workspaces/${currentCompany.id}/time_entries/${id ? id : this.state.timerId ? this.state.timerId : currentTimerId}`;
      const method = 'put';
      const data = {
        description: inputVal,
        project_id: (id && projectId) ? projectId : this.state.projectId ? this.state.projectId : '',
        task_id: '',
        billable: this.state.billable ? 1 : 0,
      }
      this.setData(method, url, data);
    }
  }

  updateTime = (e, id) => {
    const{authUser, currentCompany} = this.props;
    axios.put(`${API_URL_V1}/workspaces/${currentCompany.id}/time_entries/${id}`, {
      start: moment.utc(e[0]).format("YYYY-MM-DD HH:mm:ss"),
      end: moment.utc(e[1]).format("YYYY-MM-DD HH:mm:ss"),
    }, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(timeData => {
      message.success("Timer Entry Updated")
      this.getTimeData();
    })
    .catch(error => error)
  }

  change = (e) => {
    this.setState({...this.state, value: e.target.value})
  }

  pageChange = (page) => {
    this.setState({...this.state, pagination: {...this.state.pagination, current: page}}, () => this.getTimeData());
  }

  render() {
    const menu = (
      <Menu onClick={this.handleMenuClick}>
        {projectOptions}
      </Menu>
    );

    let activeEntry = this.state.allEntries.find(entry => ! entry.end)

    return(
        <div>
          {this.state.loading ? <CircularProgress/> :
              <Auxiliary>
                <CurrentEntry
                    projects={projectOptions}
                    projectData={this.state.projectData}
                    loading={this.state.loading}
                    entry={activeEntry}
                    afterUpdate={this.updateEntry}
                    setData={this.setData}
                    spinning={this.state.spinning}
                    iconLoading={this.state.iconLoading}
                />

                <Spin spinning={this.state.spinning} tip="Loading Data...">
                  <TimeEntry allData={this.state.allEntries} deleteEntry={this.delete} projectData={this.state.projectData} projectOptions={projectOptions} handleInputChange={this.handleInputChange} updateTime={this.updateTime} onAddEntry={this.onAddEntry} resumeEntry={this.resumeEntry}/>
                </Spin>
                <div style={{float: "right"}}>
                  <Pagination
                      defaultCurrent={1}
                      current={this.state.pagination.current}
                      pageSize={this.state.pagination.pageSize}
                      total={this.state.pagination.total}
                      hideOnSinglePage={true}
                      onChange={this.pageChange}
                  />
                </div>
              </Auxiliary>}
        </div>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, currentCompany, currentEmployee}
};

export default connect(mapStateToProps, {setCurrentTimer})(Timer);
