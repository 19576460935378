import React from "react";
import {Radio} from "antd";

import Widget from "components/Widget/index";
import CircularProgress from "components/CircularProgress";
import PropertiesItemCard from "./LeaveRequestItem";

// const popularData = [popularList, newJersy, colorado, albama];

const filterData = (data, type) => {
  switch(type){
    case 'approved': {
        return data.filter(item => item.approved)
    }
    case 'rejected': {
      return data.filter(item => item.rejected && !item.approved)
    }
    default: {
      return data.filter(item => !item.rejected && !item.approved)
    }
  }
}

class PropertiesCard extends React.Component {
  state = {
    loader: false,
    filter: 0
  };

  

  handleChange = (e) => {
    const value = e.target.value;
    
    this.setState({
      ...this.state, filter: value
    });
  };

  render() {
    const {loader, filter} = this.state;
    const popular = filterData(this.props.data, filter);
    return (
      <Widget>
        <div className="ant-row-flex gx-justify-content-between gx-mb-3 gx-mb-sm-4 gx-dash-search">
          <h2 className="h4 gx-mb-3 gx-mb-sm-1 gx-mr-2">Leave Requests</h2>
          <div className="gx-mx-sm-2">
            <Radio.Group className="gx-radio-group-link gx-radio-group-link-bg-light" defaultValue={0}
                         onChange={this.handleChange}>
              <Radio.Button value={0} className="gx-mb-2">New</Radio.Button>
              <Radio.Button value={'approved'} className="gx-mb-2">Approved</Radio.Button>
              <Radio.Button value={'rejected'} className="gx-mb-2">Rejected</Radio.Button>
            </Radio.Group>
          </div>
          <span className="gx-ml-2 gx-search-icon" onClick={() => {this.props.refresh()}}><i
            className="icon icon-frequent gx-pointer gx-text-primary gx-fs-xxl"/></span>
        </div>

        {this.props.loader ? <CircularProgress className="gx-loader-400"/> : popular.map((data, index) =>
          <PropertiesItemCard key={index} data={data} approve={(...data)=>{this.props.approve(...data)}}/>
        )}

      </Widget>
    );
  }
}

export default PropertiesCard;
