import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button, Table, Modal, Divider, Row, Col, Select, Progress, Spin, message} from "antd";
import Auxiliary from "../../../util/Auxiliary";
import ProjectModal from "./ProjectModal";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../constants";
import {FaArchive, FaTrashRestore} from "react-icons/fa";
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import axios from "axios";
import moment from "moment";
import _ from "lodash"

let clientOptions=[], disabled;
const Option = Select.Option;

class Projects extends Component {

  state = {
    project: [],
    loading: true,
    visible: false,
    title: '',
    editData: '',
    clients: '',
    confirmLoading: false,
    deleteVisible: false,
    deleteLoading: false,
    record: '',
    archiveLoading: false,
    show: 'active',
    pagination: {current: 1, pageSize: 15, total: 0},
    spinning: false,
    steps: [
        {
          target: '.add-project-button',
          content: 'Click Here to add Project',
        },
        {
          target: "body",
          content: "After clicking, modal will popup",
          placement: 'center',
          styles: {
            options: {
              zIndex: 9999999,
            },
          },
        },
        {
          target: ".project-form",
          content: "Fill up the Project details",
          disableBeacon: true
        },
        {
          target: ".add-project-form-button",
          content: "After filling the form, click here to add project",
          disableBeacon: true
        },
      ],
  };

  getProjects() {
    if(this.props.currentCompany !== null) {
      this.setState({...this.state, spinning: true});
      const {authUser, currentCompany} = this.props;
      axios.all([
        axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/projects`, {
          params: {
            "filter[active]" : this.state.show === 'active' ? 1 : this.state.show === 'archived' ? 0 : '',
            page: this.state.pagination.current, per_page: this.state.pagination.pageSize,
          },
          headers: {
            'Authorization': 'Bearer '+authUser
          }
        }),
        axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/clients`, {
          headers: {
            'Authorization': 'Bearer '+authUser
          }
        })
      ])
      .then(axios.spread((projectData, clientData) => {
        clientOptions = [];
        for(let i = 0; i < (clientData.data.data).length; i++) {
          clientOptions.push(<Option value={(clientData.data.data[i].id).toString()}>{clientData.data.data[i].name}</Option>);
        }
        this.setState({...this.state, project: projectData.data.data, clients: clientData.data.data, spinning: false, loading: false});
      }))
      .catch(error => error)
    }
  }

  componentDidMount() {
    clientOptions = this.props.clients;
    this.setState({...this.setState, pagination: this.props.pagination, project: this.props.project, clients: this.props.clients, loading: false});
  }

  difference = (object, base) => {
  	function changes(object, base) {
  		return _.transform(object, function(result, value, key) {
  			if (!_.isEqual(value, base[key])) {
  				result[key] = (_.isObject(value) && _.isObject(base[key])) ? changes(value, base[key]) : value;
  			}
  		});
  	}
  	return changes(object, base);
  }

  componentWillReceiveProps(nextProps) {
    const change = this.difference(nextProps, this.props)
    if(Object.keys(change).length !== 0) {
      this.getProjects();
    }
  }

  handleTableChange = (paginationData) => {
    this.setState({...this.state, pagination: paginationData}, () => {this.getProjects()});
  }

  onClick = () => {
    this.setState({...this.state, visible: true, title: "Add Project", editData: ''});
  }

  edit = (record, e) => {
    e.preventDefault();
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/projects/${record.id}`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(projectData => {
      this.setState({...this.state, title: "Edit Project", editData: projectData.data.data, visible: true});
    })
  }

  handleCancel = () => {
    this.setState({...this.state, visible: false});
  }

  handleOk = (values, id) => {
    this.setState({...this.state, confirmLoading: true});
    const {authUser, currentCompany} = this.props;
    if(!id) {
      axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/projects`, {
        name: values.project_name,
        client_id: values.client,
        estimated_hours: values.estimated_hours,
        billable: values.billable,
        due_date: values.due_date ? (values.due_date).format('YYYY-MM-DD') : '',
        rate: values.rate,
      },
      {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
      .then(projectData => {
        let project = this.state.project;
        project.push(projectData.data.data);
        message.success("New Project Added");
        this.setState({...this.state, project: project, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Add Project");
        this.setState({...this.state, confirmLoading: false});
      })
    } else {
      axios.put(`${API_URL_V1}/workspaces/${currentCompany.id}/projects/${id}`, {
        name: values.project_name,
        client_id: values.client,
        estimated_hours: values.estimated_hours,
        billable: values.billable,
        due_date: values.due_date ? (values.due_date).format('YYYY-MM-DD') : '',
        rate: values.rate,
        },
        {
          headers: {
          'Authorization': 'Bearer '+authUser
          }
        }
      )
      .then(projectData => {
        let projects = this.state.project;
        projects.forEach((project, index) => {
          if(project.id == projectData.data.data.id){
            projects[index] = {...project, ...projectData.data.data};
          }
        })
        message.success("Project Editted Successfully");
        this.setState({...this.state, project: projects, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Edit");
        this.setState({...this.state, confirmLoading: false});
      })
    }
  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser, currentCompany} = this.props;
    axios.delete(`${API_URL_V1}/workspaces/${currentCompany.id}/projects/${record.id}`, {
      params: {
        workspace_id: currentCompany.id,
        project_id: record.id,
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(projectData => {
      message.success("Project Deleted Successfully");
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      this.getProjects();
    })
    .catch(error => error)
  }

  select = (value) => {
    this.setState({...this.state, show: value},() => {this.getProjects()});
  }

  archive = (record, e) => {
    const {authUser, currentCompany} = this.props;
    this.setState({...this.state, archiveLoading: true});
    axios.put(`${API_URL_V1}/workspaces/${currentCompany.id}/projects/${record.id}`, {
      active: this.state.show === 'archived' ? true : false,
    },
    {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(projectData => {
      message.success(this.state.show === 'archived' ? "Project Restored" : "Project Archived");
      this.setState({...this.state, archiveLoading: false, archiveModal: false},() => {this.getProjects()});
    })
    .catch(error => error)
  }

  handleJoyrideCallback = data => {
    if(data.index === 1) {
      this.setState({...this.state, visible: true, title: "Add Project", editData: ''});
    }
    if(data.index === 2) {
      let steps = [...this.state.steps];
        this.setState({...this.state, steps: steps})
    }
    if(data.action === "skip") {
      this.props.resetJoyride();
    }
  };

  render() {
    disabled = (this.props.currentEmployee && this.props.currentEmployee.role) ? this.props.currentEmployee.role.permissions.includes("edit_company") : '';
    const columns = [
      // {
      //   title: 'Starred',
      //   render: (text, record) => (
      //     record.starred ?
      //     <span><i className="gx-icon-btn icon icon-star"/></span> :
      //     <span><i className="gx-icon-btn icon icon-star-o"/></span>
      //   ),
      // },
      {
        title: 'Name',
        render: (text, record) => (
          <span>
            <span className="gx-link" onClick={(e) => {this.props.history.push({pathname: '/projects/'+record.id+'/project_detail', state: {projectData: record}})}}>{text.name}</span><br />
            <span className="gx-text-grey">
              {this.state.clients.map((client, index) => {
                if(client.id == record.client_id) {
                  return client.name;
                }
              })
            }
            </span>
          </span>
        ),
        sorter: (a, b) => {return a.name.localeCompare(b.name)},
      }, {
        title: 'My Tasks',
        dataIndex: "my_tasks",
        defaultSortOrder: (this.props.dashboard) ? 'descend' : '',
        sorter: (a, b) => a.my_tasks - b.my_tasks,
      }, {
        title: 'Progress',
        render: (text, record) => (
          <span>
            <div style={{width: 150}}>
              <Progress percent={(record.completed_tasks*100)/record.tasks_count} size="small" showInfo={false}/>
            </div>
            <span className="gx-text-grey">{record.tasks_count ? ((record.completed_tasks*100)/record.tasks_count).toFixed(0) : 0}% complete</span>
          </span>
        )
      }, {
        title: 'Due Date',
        render: (text, record) => {
          let out=[];
          if(record.due_date) {
            let intervals = ['months', 'days'];
            let a = moment();
            let b = moment(record.due_date);
            for(let i=0; i<intervals.length; i++){
                let diff = a.diff(b, intervals[i]);
                b.add(diff, intervals[i]);
                if(diff !== 0 && Math.abs(diff) !== 1) {
                  out.push(Math.abs(diff) + ' ' + intervals[i]);
                }
                if(Math.abs(diff) === 1) {
                  out.push(Math.abs(diff) + ' ' + intervals[i].slice(0, -1));
                }
            }
          }
          return(
          <span>
            {record.due_date ? record.due_date : ""}<br />
            <span className="gx-text-grey">
              {record.due_date ? moment().diff(moment(record.due_date)) > 0 ? "Expired" : out.join(' ') : ''}
            </span>
          </span>
        )}
      }, {
        title: 'Actions',
        key: 'x',
        render: (text, record) => (
          <span>
          {disabled ?
              <span className="gx-link" onClick={(e) => {this.edit(record, e)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span> : <span></span>}
              <Divider type="vertical"/>
          {disabled && (this.state.show === 'all' && record.active === false) ?
              <span className="gx-disabled"><FaArchive title="Already archived"/></span> :
              disabled && (this.state.show !== 'archived') ?
              <span className="gx-link" onClick={(e) => {this.setState({...this.state, archiveModal: true, record: record})}}><FaArchive title="Archive"/></span> :
              disabled && this.state.show === 'archived' ?
              <span className="gx-link" onClick={(e) => {this.setState({...this.state, archiveModal: true, record: record})}}><FaTrashRestore title="Restore"/></span> :
              <span></span>}

          {disabled ?
              <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><Divider type="vertical"/><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span>:
              <span></span>
          }
          </span>
        )
      }];
    return (
      <div>
      {this.props.joyride === ".projects" ?
      <Joyride
        steps={this.state.steps}
        callback={this.handleJoyrideCallback}
        showSkipButton={true}
        continuous
        // run={this.state.run}
        styles={{
          options: {
            zIndex: 1000,
          }
        }}
      /> : null}
      <Modal
          visible={this.state.deleteVisible}
          title="Delete Project?"
          onOk={(e) => this.deleteRecord(this.state.record, e)}
          onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
          footer={[
            <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
              OK
            </Button>,
            <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
              Cancel
            </Button>
          ]}
      >
          <p>Are you Sure you want to delete {this.state.record.name} Project?</p>
      </Modal>
      <Modal
          visible={this.state.archiveModal}
          title= {this.state.show === 'archived' ? "Restore Project" : "Archive Project?"}
          onOk={(e) => this.archive(this.state.record, e)}
          onCancel={(e) => {this.setState({...this.state, archiveModal: false})}}
          footer={[
            <Button key="submit" loading={this.state.archiveLoading} onClick={(e) => this.archive(this.state.record, e)}>
              OK
            </Button>,
            <Button key="cancel" onClick={(e) => {this.setState({...this.state, archiveModal: false})}}>
              Cancel
            </Button>
          ]}
      >
          <p>Are you Sure you want to {this.state.show === 'archived' ? 'restore' : 'archive'} {this.state.record.name} Project?</p>
      </Modal>

      <div>
      {this.state.visible ?
      <ProjectModal title={this.state.title} value={this.state.editData} visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel} clients={clientOptions} confirmLoading={this.state.confirmLoading}/> : null }
      <Auxiliary>

        <div className="gx-profile-content">
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div style={{paddingBottom: "2.5rem"}}>
                  <span style={{float: 'right'}}>
                    <Select defaultValue={'active'} style={{ minWidth: 100 }} onChange={(value) => {this.select(value)}}>
                      <Option value="active">Active</Option>
                      <Option value="all">All</Option>
                      <Option value="archived">Archived</Option>
                    </Select>
                    <Button className="add-project-button" style={{ verticalAlign: "baseline", marginLeft: "15px" }} disabled={!disabled} onClick={this.onClick} value="Add">Add Project</Button>
                  </span>
                </div>
                {this.state.loading ?
                <CircularProgress/> :
                <Spin spinning={this.state.spinning}>
                  <Table className="gx-table-responsive" columns={columns} dataSource={this.state.project}  onChange={this.handleTableChange} pagination={this.state.pagination}/>
                </Spin>}
            </Col>
          </Row>
        </div>
      </Auxiliary>
      </div>
      </div>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee, joyride} = company;
  return {authUser, currentCompany, currentEmployee, joyride}
};

export default withRouter(connect(mapStateToProps)(Projects));
