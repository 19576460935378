import React, {Component} from "react";
import {connect} from "react-redux";
import {Drawer, Layout} from "antd";
import Auxiliary from "../../util/Auxiliary";
import {API_URL_V1} from "../../constants";
import axios from "axios";

import SidebarContent from "./SidebarContent";
import {toggleCollapsedSideNav, updateWindowWidth} from "appRedux/actions/Setting";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import Modalpopup from "./Modalpopup";

const {Sider} = Layout;

export class Sidebar extends Component {

  state = {
    visible: false,
  };

  onToggleCollapsedNav = () => {
    this.props.toggleCollapsedSideNav(!this.props.navCollapsed);
  };

  componentDidMount() {
    window.addEventListener('resize', () => {
      this.props.updateWindowWidth(window.innerWidth)
    });
    const {authUser} = this.props;
    axios.get(`${API_URL_V1}/workspaces`, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(userData => {
      if(userData.data.data.length == 0) {
        this.setState({...this.state, visible: true});
      } else {
        // localStorage.setItem('id', userData.data.data[0].id);
      }
    })
    .catch(error => {console.log(error)});
  }

  render() {
    const {themeType, navCollapsed, width, navStyle} = this.props;

    let drawerStyle = "gx-collapsed-sidebar";

    if (navStyle === NAV_STYLE_FIXED) {
      drawerStyle = "";
    } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      drawerStyle = "gx-mini-sidebar gx-mini-custom-sidebar";
    } else if (navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
      drawerStyle = "gx-custom-sidebar"
    } else if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
      drawerStyle = "gx-mini-sidebar";
    } else if (navStyle === NAV_STYLE_DRAWER) {
      drawerStyle = "gx-collapsed-sidebar"
    }
    if ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR
        || navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) && width < TAB_SIZE) {
      drawerStyle = "gx-collapsed-sidebar"
    }
    return (
      <Auxiliary>
        {this.state.visible ?
          <Modalpopup /> : null
        }
        <Sider
          className={`gx-app-sidebar ${drawerStyle} ${themeType !== THEME_TYPE_LITE ? 'gx-layout-sider-dark' : null}`}
          trigger={null}
          collapsed={(width < TAB_SIZE ? false : navStyle === NAV_STYLE_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR)}
          theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
          collapsible>
          {
            navStyle === NAV_STYLE_DRAWER || width < TAB_SIZE ?
              <Drawer
                wrapClassName={`gx-drawer-sidebar ${themeType !== THEME_TYPE_LITE ? 'gx-drawer-sidebar-dark' : null}`}
                placement="left"
                closable={false}
                onClose={this.onToggleCollapsedNav.bind(this)}
                visible={navCollapsed}>
                <SidebarContent/>
              </Drawer> :
              <SidebarContent/>
          }
        </Sider>
      </Auxiliary>
    )
  }
}

const mapStateToProps = ({settings, auth}) => {
  const {themeType, navStyle, navCollapsed, width, locale} = settings;
  const {authUser} = auth;
  return {themeType, navStyle, navCollapsed, width, locale, authUser}
};
export default connect(mapStateToProps, {toggleCollapsedSideNav, updateWindowWidth})(Sidebar);
