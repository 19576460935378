import React from "react";
import {Col, Row, Card} from "antd";
import ContainerHeader from "components/ContainerHeader/index";
import IntlMessages from "util/IntlMessages";
import TimeEntryItems from "./TimeEntryItems";
import _ from "lodash";
import moment from "moment";

function TimeEntryData({key, allData, style, deleteEntry, projectData, projectOptions, handleInputChange, updateTime, onAddEntry, resumeEntry}) {
  let cardTitle = '', timeDiff = '', date = '';
  allData.forEach((data, index) => {
    date = moment.utc(data.start).local().format("YYYY MM DD");
    cardTitle = (moment(data.start).year() === moment().year()) ? moment.utc(data.start).local().format("ddd, Do MMM") : moment.utc(data.start).local().format("ddd, Do MMM YYYY");
    if(moment.utc(data.start).local().format("YYYY MM DD") === moment().format("YYYY MM DD")) {
      cardTitle = "Today";
    } else if(moment.utc(data.start).local().format("YYYY MM DD") === moment().subtract(1, 'days').format("YYYY MM DD")) {
      cardTitle = "Yesterday";
    }
    const diff = Math.floor(moment.duration(moment(data.end,"YYYY-MM-DD HH:mm:ss").diff(moment(data.start,"YYYY-MM-DD HH:mm:ss"))).asHours()) + moment.utc(moment(data.end,"YYYY-MM-DD HH:mm:ss").diff(moment(data.start,"YYYY-MM-DD HH:mm:ss"))).format(":mm:ss");
    timeDiff = Math.floor(moment.duration(timeDiff).add(moment.duration(diff)));
    timeDiff = Math.trunc(moment.duration(timeDiff).asHours()) + ":" +("0"+(moment.duration(timeDiff).minutes())).slice(-2) +":"+ ("0"+(moment.duration(timeDiff).seconds())).slice(-2);
  })
  return (
    <div style={{boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)"}}>
      <Card title={cardTitle} extra={<div><span className="gx-mr-3" style={{color: "#5e5cdb"}}>Total : {timeDiff}</span><span className="" onClick={() => {onAddEntry(date)}}>
      <i className="icon icon-add-circle gx-pointer gx-fs-lg" title="Add 1 Hour" style={{position: "relative", top: "3px", right:"10px", color: "#5e5cdb"}}/></span></div>} headStyle={{backgroundColor: "#f9f9f9", color: "#5e5cdb"}} size="small" bodyStyle={{alignText: "center"}}>
        {allData.map((data, index) => (
          <TimeEntryItems key={index} data={data} styleName="gx-card-list" deleteEntry={deleteEntry} projectData={projectData} projectOptions={projectOptions} handleInputChange={handleInputChange} updateTime={updateTime} onAddEntry={onAddEntry} resumeEntry={resumeEntry}/>
        ))}
      </Card>
    </div>

  )
}

export default TimeEntryData;
