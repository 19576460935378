import React, {Component} from "react";
import {connect} from "react-redux";
import {Button, Card, Table, Modal, Divider, Select, Avatar, message} from "antd";
import Auxiliary from "../../../../util/Auxiliary";
import AddProjectEmployeesModal from "./AddProjectEmployeesModal";
import {API_URL_V1} from "../../../../constants";
import CircularProgress from "components/CircularProgress/index";
import _ from "lodash";
import axios from "axios";

let disabled;
const Option = Select.Option;

class ProjectEmployees extends Component {
  state = {
    projectEmployees: [],
    loading: true,
    visible: false,
    title: '',
    pagination: {current: 1, pageSize: 15, total: 0},
    q: null,
    confirmLoading: false,
    deleteVisible: false,
    deleteLoading: false,
    record: '',
  }

  getProjectEmployees () {
    const {authUser, currentCompany} = this.props;
    const project_id = this.props.projectData.id;
    axios.all([
      axios.get(`${API_URL_V1}/projects/${project_id}/employees`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/employees`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
    ])
    .then(axios.spread((projectEmployeeData, employeeData) => {
      let employees = [];
      employeeData.data.data.forEach(employee => {
        let name = `${employee.first_name} ${employee.last_name}`;
        employees.push(<Option value={(employee.id).toString()}>{name}</Option>);
      })
      this.setState({...this.state, employees: employees, projectEmployees: projectEmployeeData.data.data, loading: false});
    }))
    .catch(error => error)
  }

  searchProjectEmployee (params) {
    const {authUser, currentCompany} = this.props;
    axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/employees`, {
      params: params === "Add Employee" ? null : {
        page: this.state.pagination.current, per_page: this.state.pagination.pageSize, q: this.state.q, ...params
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(employeeData => {
      let employees = [];
      const pagination = { ...this.state.pagination };
      pagination.pageSize = 15;
      pagination.total =employeeData.data.meta.total;
      employeeData.data.data.forEach(employee => {
        let name = `${employee.first_name} ${employee.last_name}`;
        employees.push(<Option value={(employee.id).toString()}>{name}</Option>);
      })
      this.setState({...this.state, pagination, employees: employees, loading: false});
    })
  }

  setSearchQuery = _.debounce((value) => {
    console.log(value)
    this.setState({...this.state, pagination: {...this.state.pagination, current: 1}, q: value}, () => {this.searchProjectEmployee('search')})
  }, 500)

  componentDidMount () {
    this.getProjectEmployees();
  }

  onClick = () => {
    const {authUser, currentCompany} = this.props;
    this.searchProjectEmployee("Add Employee");
    this.setState({...this.state, visible: true, title: 'Add Employee'});
  }

  handleCancel = () => {
    this.setState({...this.state, visible: false});
  }

  handleOk = (values, id) => {
    console.log(values)
    const {authUser} = this.props;
    const project_id = this.props.projectData.id;
    this.setState({...this.state, confirmLoading: true});
    axios.post(`${API_URL_V1}/projects/${project_id}/employees`, {
      employee_id: values.employee_name,
    },
    {
      headers: {
      'Authorization': 'Bearer '+authUser
    }
    })
    .then(projectEmployeesData => {
      this.getProjectEmployees();
      this.setState({...this.state, visible: !this.state.visible, confirmLoading: false});
    })
    .catch(error => {
      message.error("Failed to Add Employee");
      this.setState({...this.state, confirmLoading: false});
    })
  }

  deleteRecord = (record, e) => {
    console.log(record);
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser} = this.props;
    const project_id = this.props.projectData.id;
    axios.post(`${API_URL_V1}/projects/${project_id}/remove-employees`, {
      employee_id: record.id,
    },
    {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(projectData => {
      this.getProjectEmployees();
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      message.success("Employee Removed Successfully");
    })
    .catch(error => error)
  }

  render() {
    disabled = (this.props.currentEmployee && this.props.currentEmployee.role) ? this.props.currentEmployee.role.permissions.includes("edit_company") : '';
    const columns = [{
        title: 'Name',
        render: (text, record) => {
          let avatarUrl = "";
          if(record) {
            if (record.avatar) {
              avatarUrl = `${API_URL_V1}${record.avatar}`;
            }

          return (
              <div className="gx-flex-row gx-align-items-center gx-avatar-row">
                <Avatar src={avatarUrl}
                        className="gx-size-40 gx-mr-3" alt=""/>
                <span className="gx-avatar-name">

                {`${record.first_name} ${record.last_name}`}
                </span>
              </div>
          )}
        },
        sorter: (a, b) => {return a.first_name.localeCompare(b.first_name)},
      }, {
        title: 'Actions',
        key: 'x',
        render: (text, record) => (
          <span>
            {disabled ?
              <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}>Remove</span> :
              <span>Remove</span>
            }
          </span>
        )
      }];
    return(
      <div>
      <Modal
        visible={this.state.deleteVisible}
        title="Remove Employee?"
        onOk={(e) => this.deleteRecord(this.state.record, e)}
        onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
        confirmLoading={this.state.deleteLoading}
        footer={[
          <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
            OK
          </Button>,
          <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
            Cancel
          </Button>
        ]}
      >
        <p>Are you Sure you want to remove {`${this.state.record.first_name} ${this.state.record.last_name}`} from project?</p>
      </Modal>
      {this.state.loading ?
      <CircularProgress/> :
      <div>
      {this.state.visible ?
        <AddProjectEmployeesModal title={this.state.title} employees={this.state.employees} value={this.state.editData} visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel} confirmLoading={this.state.confirmLoading} setSearchQuery={this.setSearchQuery}/> : null }
      <Auxiliary>
        <div style={{paddingBottom: "2.5rem"}}>
          <Button style={{float: "right"}} disabled={!disabled} onClick={this.onClick} value="Add">Add Employees</Button>
        </div>
        <Table className="gx-table-responsive" columns={columns} dataSource={this.state.projectEmployees} pagination={false}/>
      </Auxiliary>
      </div>}
      </div>
    );
  };
}
const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentEmployee} = company;
  return {authUser, currentEmployee}
};

export default connect(mapStateToProps)(ProjectEmployees);
