import React, {Component} from "react";
import {connect} from "react-redux";
import Timer from "react-compound-timer";
import {API_URL_V1} from "../../constants";
import Auxiliary from "util/Auxiliary";
import ScheduleModal from "./ScheduleModal";
import PlannedModal from "./PlannedModal";
import {Button, Icon, Modal, message} from "antd";
import {setCurrentTimer} from "appRedux/actions/Company";
import axios from "axios";

class CheckTimer extends Component {
  state={
    checkIn: false,
    time: 0,
    iconLoading: false,
    planModalVisible: false,
    taskModalVisible: false,
    planLoading: false,
    taskData: [],
    employeeAttendanceId: null,
    values: '',
    identifier: '',
  }

  componentDidMount() {
    const {authUser, currentEmployee} = this.props;
    axios.get(`${API_URL_V1}/employees/${currentEmployee.id}/attendances-current`, {
      headers: {
        'Authorization': 'Bearer '+authUser,
      }
    })
    .then(attendancesData => {
      if(attendancesData.data.data.out_at === null) {
        let date = (new Date().getTime());
        let diff = date - (new Date((attendancesData.data.data.in_at+"Z").replace(/ /g,"T")).getTime());
        this.setState({...this.state, taskData: attendancesData.data.data.attendance_task, employeeAttendanceId: attendancesData.data.data.id, checkIn: true, time: diff});
      }
    })
  }

  onClick = () => {
    if(!this.state.checkIn || !this.props.currentCompany.settings.enable_attendance_task) {
      this.getLocation();
    } else {
      this.setState({...this.state, taskModalVisible: true});
    }
  }

  getLocation = () => {
    if(navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(this.success, this.error);
    }
  }

  success = (location) => {
    this.request(location);
  }

  error = () => {
    this.request();
  }

  request = (location) => {
    this.setState({...this.state, iconLoading: true})
    let url;
    const {authUser, currentEmployee} = this.props;
    let loc = location ? {lat: location.coords.latitude, long: location.coords.longitude} : {};
    url = !this.state.checkIn ? `${API_URL_V1}/employees/${currentEmployee.id}/attendances-checkin` : `${API_URL_V1}/employees/${currentEmployee.id}/attendances-checkout`;
    axios.post(url, {
      in_location: !this.state.checkIn ? loc : null,
      out_location: this.state.checkIn ? loc : null,
    },
    {
      headers: {
        'Authorization': 'Bearer '+authUser,
      }
    })
    .then(checkinData => {
      if(this.state.checkIn) {
        console.log("yess")

        this.setState({...this.state, time: 0},() => {this.props.setCurrentTimer(null);
          if(this.props.currentCompany.settings.enable_attendance_task) {
            this.updateTasks(this.state.values, this.state.identifier);
          }
        });
      }
      else {
        this.setState({...this.state, planModalVisible: true, taskData: checkinData.data.lastTask, employeeAttendanceId: checkinData.data.data.id});
      }
      this.setState({...this.state, checkIn: !this.state.checkIn, iconLoading: false});
    })
    .catch(error => error)
  }

  updatePlannedTasks = (values, identifier) => {
    const {authUser, currentEmployee} = this.props;
    this.setState({...this.state, planLoading: true, values: values, identifier: identifier});
    if(this.state.checkIn && this.state.taskModalVisible) {
      this.getLocation();
    } else {
      this.updateTasks(values, identifier);
    }
  }

  updateTasks = (values, identifier) => {
    const {authUser, currentEmployee} = this.props;
    axios.put(`${API_URL_V1}/employees/${currentEmployee.id}/attendances-update-task-log`, {
      ...(identifier === "checkout" ? {
        check_out_done_task: values.performedTask,
        check_out_tomorrow_task: values.nextTasks,
      } : {
        check_in_task: values.checkInTask,
      }),
        employee_attendance_id: this.state.employeeAttendanceId,
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
        }
      }
    )
    .then(checkInTaskData => {
      message.success("Planned Task Updated");
      this.setState({...this.state, taskData: checkInTaskData.data.data, employeeAttendanceId: checkInTaskData.data.data.employee_attendance_id, planModalVisible: false, planLoading: false, taskModalVisible: false});
    })
    .catch(error => {
      message.error("Failed to Edit");
      this.setState({...this.state, planLoading: false});
    })
  }

  render() {
    return(
        <Auxiliary>
          {this.state.planModalVisible && this.props.currentCompany.settings.enable_attendance_task ? <PlannedModal title="Planned Tasks" visible={this.state.planModalVisible} taskData={this.state.taskData} onOk={this.updatePlannedTasks} onCancel={() => {this.setState({...this.state, planModalVisible: false})}} confirmLoading={this.state.planLoading}/> : null}
          {this.state.taskModalVisible && this.props.currentCompany.settings.enable_attendance_task ? <ScheduleModal title="Plans"  visible={this.state.taskModalVisible} taskData={this.state.taskData} onOk={this.updatePlannedTasks} onCancel={() => {this.setState({...this.state, taskModalVisible: false})}} confirmLoading={this.state.planLoading} employeeAttendanceId={this.state.employeeAttendanceId}/> : null}
          <Button onClick={this.onClick} loading={this.state.iconLoading} style={{marginBottom: "0" , backgroundColor: this.state.checkIn ? "#5cb85c" : "#ffffff", color: "#5e5cdb"}}>
            {this.state.checkIn ?
            <span style={{verticalAlign: "middle"}}>
              <Timer initialTime={this.state.time} lastUnit="h">
                  {({getTime}) => (
                  <div>
                    <Timer.Hours formatValue={value => `${(value < 10 ? `0${value}` : value)} : `} />
                    <Timer.Minutes formatValue={value => `${(value < 10 ? `0${value}` : value)} : `} />
                    <Timer.Seconds formatValue={value => `${(value < 10 ? `0${value}` : value)}`} />
                    {localStorage.setItem('time', getTime())}
                  </div>
                )}
              </Timer>
            </span> : "Check In"}
            {this.state.checkIn ? <Icon type="export" style={{verticalAlign: "middle"}}/> : ""}
          </Button>
        </Auxiliary>
    )
  }
}

export default connect(null, {setCurrentTimer})(CheckTimer);
