import React, {Component} from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom'
import {Button, Card, Col, Divider, Input, Row, Table, Select, Modal, message} from "antd";
import Auxiliary from "../../../util/Auxiliary";
import Breadcrumbs from "components/Breadcrumbs/index";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from "../../../constants";
import EventsModal from "./EventsModal";
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import {FaRegCalendarAlt} from "react-icons/fa";
import axios from "axios";
import moment from 'moment';

const Search = Input.Search;
const Option = Select.Option;
const confirm = Modal.confirm;

let officeOptions=[];
let disabled;

class CompanyEvents extends Component {

  state = {
    visible: false,
    loading: true,
    title: '',
    events: [],
    locations: '',
    editData: '',
    confirmLoading: false,
    deleteVisible: false,
    deleteLoading: false,
    record: '',
    steps: [
        {
          target: '.add-event-button',
          content: 'Click Here to add Event',
        },
        {
          target: "body",
          content: "After clicking, modal will popup",
          placement: 'center',
          styles: {
            options: {
              zIndex: 9999999,
            },
          },
        },
        {
          target: ".event-form",
          content: "Fill up the Event details",
          disableBeacon: true
        },
        {
          target: ".add-event-form-button",
          content: "After filling the form, click here to add event",
          disableBeacon: true
        },
      ],
  };

  getEvents = (params) => {
    const {authUser, currentCompany} = this.props;
    axios.all([
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/events`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/locations`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
    ])
    .then(axios.spread((eventsData, officeData) => {
      officeOptions=[];
      for(let i = 0; i < (officeData.data.data).length; i++) {
        officeOptions.push(<Option value={(officeData.data.data[i].id).toString()}>{officeData.data.data[i].name}</Option>);
      }
      this.setState({...this.state, events: eventsData.data.data, loading: false});
    }))
    .catch(error => error)
  }

  componentDidMount() {
    this.getEvents();
  }

  onClick = () => {
    this.setState({...this.state, visible: true, title: "Add Event", editData: ''});
  }

  handleCancel = () => {
    this.setState({...this.state, visible: false});
  }

  edit = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, title: "Edit Event", editData: record, visible: true});
  }

  handleOk = (values, id) => {
    const {authUser, currentCompany} = this.props;
    this.setState({...this.state, confirmLoading: true});
    if(!id) {
      axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/events`, {
          title: values.event_name,
          description: values.description,
          event_on: values.duration === "single_day" ? (values.event_on).format('YYYY-MM-DD') : (values.date_range[0]).format('YYYY-MM-DD'),
          event_time: values.event_time ? values.event_time.format('HH:mm') : null,
          end_date: values.date_range && values.date_range[1] ? values.date_range[1].format('YYYY-MM-DD') : null,
          location: values.location,
          location_id: values.location_id,
          notify: values.notify
        },
        {
          headers: {
            'Authorization': 'Bearer '+authUser
        }
      })
      .then(eventsData => {
        let events = this.state.events;
        events.push(eventsData.data.data);
        message.success("New Event Added");
        this.setState({...this.state, events: events, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Add Event");
        this.setState({...this.state, confirmLoading: false});
      })
    } else {
      axios.put(`${API_URL_V1}/workspaces/${currentCompany.id}/events/${id}`, {
        title: values.event_name,
        description: values.description,
        event_on: values.duration === "single_day" ? (values.event_on).format('YYYY-MM-DD') : (values.date_range[0]).format('YYYY-MM-DD'),
        event_time: values.event_time ? values.event_time.format('HH:mm') : null,
        end_date: values.date_range && values.date_range[1] ? values.date_range[1].format('YYYY-MM-DD') : null,
        location: values.location,
        location_id: values.location_id,
        notify: values.notify
        },
        {
          headers: {
          'Authorization': 'Bearer '+authUser
          }
        }
      )
      .then(eventsData => {
        let events = this.state.events;
        events.forEach((event, index) => {
          if(event.id == eventsData.data.data.id){
            events[index] = {...event, ...eventsData.data.data};
          }
        })
        message.success("Event Editted Successfully");
        this.setState({...this.state, events: events, visible: !this.state.visible, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Edit");
        this.setState({...this.state, confirmLoading: false});
      })
    }
  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser, currentCompany} = this.props;
    axios.delete(`${API_URL_V1}/workspaces/${currentCompany.id}/events/${record.id}`, {
      params: {
        workspace_id: currentCompany.id,
        event_id: record.id,
      },
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(eventsData => {
      message.success("Event Deleted Successfully");
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});
      this.getEvents();
    })
    .catch(error => error)
  }

  handleJoyrideCallback = data => {
    if(data.index === 1) {
      this.setState({...this.state, visible: true, title: "Add Event", editData: ''});
    }
    if(data.index === 2) {
      let steps = [...this.state.steps];
        this.setState({...this.state, steps: steps})
    }
    if(data.action === "skip") {
      this.props.resetJoyride();
    }
  };

  render() {
    disabled = (this.props.currentEmployee && this.props.currentEmployee.role) ? this.props.currentEmployee.role.permissions.includes("edit_company") : '';
    const columns = [{
        title: 'Name',
        render: (text, record) => (
          <span>
            <Link to={`/events/${record.id}/event_detail`}>{record.title}</Link>
            <br/>
            <span className="gx-text-grey">
            <FaRegCalendarAlt /> <span style={{verticalAlign: "middle"}}>{record.event_on}{record.end_date ? " to "+moment(record.end_date).format('YYYY-MM-DD') : null}</span>
            </span>
          </span>
        )
      }, {
        title: 'Location',
        render: (text, record) => (
          <span>
            {record.location}
          </span>
        )
      }, {
        title: 'Actions',
        key: 'x',
        render: (text, record) => (
          <span>
            {disabled ?
            <span className="gx-link" onClick={(e) => {this.edit(record, e)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span> : <span></span>}

            {disabled ?
                <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><Divider type="vertical"/><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span> :
                <span></span>
            }
          </span>
        )
      }];
    return (
      <div>
        {this.state.loading ?
          <CircularProgress /> :
        <div>
        <Modal
          visible={this.state.deleteVisible}
          title="Delete Event?"
          onOk={(e) => this.deleteRecord(this.state.record, e)}
          onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
          confirmLoading={this.state.deleteLoading}
          footer={[
            <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
              OK
            </Button>,
            <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
              Cancel
            </Button>
          ]}
        >
          <p>Are you Sure you want to delete this event?</p>
        </Modal>
        {this.state.visible ?
        <EventsModal value={this.state.editData} visible={this.state.visible} value={this.state.editData} title={this.state.title} onCancel={this.handleCancel} onOk={this.handleOk} offices={officeOptions} loading={this.state.confirmLoading}/> : null}
          <Auxiliary>
            <div className="gx-profile-content">
              <Breadcrumbs />
              {this.props.joyride === ".events" ?
              <Joyride
                steps={this.state.steps}
                callback={this.handleJoyrideCallback}
                showSkipButton={true}
                continuous
                // run={this.state.run}
                styles={{
                  options: {
                    zIndex: 1000,
                  }
                }}
              /> : null}
              <Row>
                <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                  <Card className="gx-card" title="Events">
                    <div className="gx-mb-5">
                      <Button className="add-event-button" style={{float: "right"}} disabled={!disabled} onClick={this.onClick} value="Add">Add Events</Button>
                    </div>
                    <Table className="gx-table-responsive" columns={columns} dataSource={this.state.events} onChange={this.handleTableChange}/>
                  </Card>
                </Col>
              </Row>
            </div>
          </Auxiliary>
        </div> }
      </div>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser, userWorkspaceData} = auth;
  const {currentCompany, currentEmployee, joyride} = company;
  return {authUser, userWorkspaceData, currentCompany, currentEmployee, joyride}
};

export default withRouter(connect(mapStateToProps)(CompanyEvents));
