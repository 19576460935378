import React, {Component} from "react";
import {connect} from "react-redux";
import {API_URL_V1} from '../../../../constants';
import Auxiliary from "../../../../util/Auxiliary";
import CircularProgress from "components/CircularProgress/index";
import {Form, Select, Button, Checkbox, TimePicker, message} from "antd";
import {setTimerDescriptionRequired, setAttendanceTask} from "appRedux/actions/Company";
import axios from "axios";
import moment from "moment";

const {Option} = Select;

class Config extends Component {
  state = {
    hideFrequency: true,
    notifyAfter: '',
    notifyFrequency: '',
    employeeVisibilityLevel: '',
    settingsData: [],
    disableTimePicker: false,
    loading: true,
  };

  componentDidMount() {
    const {authUser, currentCompany} = this.props;
    axios.all([
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/settings`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
    ])
    .then(axios.spread((companyData, settingData) => {
      this.setState({
        ...this.state,
        hideFrequency: !companyData.data.data.not_tracking_notify_after,
        notifyAfter: companyData.data.data.not_tracking_notify_after,
        notifyFrequency: companyData.data.data.not_tracking_notify_every,
        employeeVisibilityLevel: companyData.data.data.employee_visibility_level,
        settingsData: settingData.data.data,
        disableTimePicker: settingData.data.data.send_daily_work_report,
        loading: false});
    }))
    .catch(error => error)
  }

  handleChange = (value) => {
    if(value === "0") {
      this.setState({...this.state, hideFrequency: true}, () => {
        this.props.form.setFieldsValue({["not_tracking_notify_every"]: undefined})
      })
    }
    else {
      this.setState({...this.state, hideFrequency: false})
    }
  }

  handleSubmit = e => {
    e.preventDefault();
    const {authUser, currentCompany} = this.props;
    this.props.form.validateFields((err, values) => {
      if (!err) {
        console.log('Received values of form: ', values);
        console.log(values.daily_work_report_at)
        axios.all([
          axios.put(`${API_URL_V1}/workspaces/${currentCompany.id}`,{
            not_tracking_notify_after: values.not_tracking_notify_after,
            not_tracking_notify_every: values.not_tracking_notify_every,
            employee_visibility_level: values.employee_visibility_level,
          },
          {
            headers: {
              'Authorization': 'Bearer '+authUser
            }
          }),
          axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/settings`, {
            daily_work_report_at: moment(values.daily_work_report_at).utc().format("HH:mm"),
          }, {
            headers: {
              'Authorization': 'Bearer '+authUser
            }
          })
        ])
        .then(axios.spread((companyData, settingData) => {
          this.setState({...this.state, settingsData: settingData.data.data});
          message.success("Notification Configured");
        }))
        .catch(error => {
          message.error("Failed to Configure");
        })
      }
    });
  }

  onDailyLogsChange = (e) => {
    const {authUser, currentCompany} = this.props;
    axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/settings`, {
      enable_attendance_task: e.target.checked,
    }, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(settingData => {
      this.setState({...this.state, settingsData: settingData.data.data}, () => {
        this.props.setAttendanceTask(settingData.data.data.enable_attendance_task);
      })
      let display = settingData.data.data.enable_attendance_task ? "Daily Logs Enabled" : "Daily Logs Disabled";
      message.success(display);
    })
    .catch(error => {
      message.error("Failed to Change");
    })
  }

  onChange = (e) => {
    const {authUser, currentCompany} = this.props;
    axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/settings`, {
      timer_desc_required: e.target.checked,
    }, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(settingData => {
      this.setState({...this.state, settingsData: settingData.data.data})
      let display = settingData.data.data.timer_desc_required ? "Timer Description marked as Important" : "Timer Description marked as not Important";
      message.success(display);
      this.props.setTimerDescriptionRequired(e.target.checked);
    })
    .catch(error => {
      message.error("Failed to Change");
    })
  }

  onDailyWorkChange = (e) => {
    const {authUser, currentCompany} = this.props;
    axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/settings`, {
      send_daily_work_report: e.target.checked,
    }, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(settingData => {
      this.setState({...this.state, settingsData: settingData.data.data, disableTimePicker: settingData.data.data.send_daily_work_report})
      let display = settingData.data.data.send_daily_work_report ? "Daily report sending enabled" : "Daily report sending disabled";
      message.success(display);
    })
    .catch(error => {
      message.error("Failed to Change");
    })
  }

  render() {
    let disabled = (this.props.currentEmployee && this.props.currentEmployee.role) ? this.props.currentEmployee.role.permissions.includes("edit_company") : '';
    const { getFieldDecorator } = this.props.form;
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };
    return (
      <Auxiliary>
        {this.state.loading ? <CircularProgress /> :
        <div>
          <div><b>Attendance and Timer Configuration</b></div><div className="gx-mb-2">Get notified if checked in and timer not started</div>
          <Form {...formItemLayout} onSubmit={this.handleSubmit}>
            <Form.Item label="Notify after checkin">
              {getFieldDecorator('not_tracking_notify_after', {
                initialValue: this.state.notifyFrequency ? this.state.notifyAfter.toString() : undefined,
              })(
                <Select placeholder="Select Duration" onChange={this.handleChange} disabled={!disabled}>
                  <Option value="0">Do Not Notify</Option>
                  <Option value="15">After 15 Minutes</Option>
                  <Option value="30">After 30 Minutes</Option>
                  <Option value="60">After 60 Minutes</Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Frequency of notification">
              {getFieldDecorator('not_tracking_notify_every', {
                initialValue: (this.state.notifyFrequency && this.state.notifyFrequency !== null) ? this.state.notifyFrequency.toString() : undefined,
              })(
                <Select placeholder="Select Duration"  disabled={this.state.hideFrequency || !disabled}>
                  <Option value="0">Only Once</Option>
                  <Option value="15">Every 15 Minutes</Option>
                  <Option value="30">Every 30 Minutes</Option>
                  <Option value="45">Every 45 Minutes</Option>
                  <Option value="60">Every 60 Minutes</Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Employee Visibility">
              {getFieldDecorator('employee_visibility_level', {
                initialValue: (this.state.employeeVisibilityLevel  && this.state.employeeVisibilityLevel !== null) ? this.state.employeeVisibilityLevel.toString() : undefined,
              })(
                <Select placeholder="Select Visibility Level">
                  <Option value="company">Company Level</Option>
                  <Option value="office">Office Level</Option>
                  <Option value="department">Department Level</Option>
                  <Option value="project">Project Level</Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item
            {...tailFormItemLayout}>
              {getFieldDecorator('enable_daily_logs', {
                initialValue: this.state.settingsData.enable_attendance_task,
                valuePropName: 'checked',
              })(
                <Checkbox onChange={this.onDailyLogsChange}>Enable Daily Logs</Checkbox>
              )}
            </Form.Item>
            <Form.Item
            {...tailFormItemLayout}>
              {getFieldDecorator('timer_desc_required', {
                initialValue: this.state.settingsData.timer_desc_required,
                valuePropName: 'checked',
              })(
                <Checkbox onChange={this.onChange}>Force Timer Description and Project</Checkbox>
              )}
            </Form.Item>
            <Form.Item
            {...tailFormItemLayout}>
              {getFieldDecorator('send_daily_work_report', {
                initialValue: this.state.settingsData.send_daily_work_report,
                valuePropName: 'checked',
              })(
                <Checkbox onChange={this.onDailyWorkChange}>Send Daily Work Report</Checkbox>
              )}
            </Form.Item>
            <Form.Item label="Time for Sending Reports">
              {getFieldDecorator('daily_work_report_at', {
                initialValue: this.state.settingsData.daily_work_report_at ? moment.utc(this.state.settingsData.daily_work_report_at, "HH:mm").local() : undefined,
              })(
                <TimePicker className="gx-w-100" format="HH:mm" defaultOpenValue={moment('00:00', 'HH:mm')} disabled={!this.state.disableTimePicker}/>
              )}
            </Form.Item>
            <Form.Item {...tailFormItemLayout}>
              <Button type="primary" htmlType="submit" disabled={!disabled}>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
        }
      </Auxiliary>
    )
  }
}
const WrappedConfigForm = Form.create()(Config);

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, currentCompany, currentEmployee}
};

export default connect(mapStateToProps, {setTimerDescriptionRequired, setAttendanceTask})(WrappedConfigForm);
