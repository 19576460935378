import React, {Component} from "react";
import {connect} from "react-redux";
import Form from "react-jsonschema-form";
import {DatePicker, Select, Upload, Button, Icon, message, Divider} from "antd";
import CircularProgress from "components/CircularProgress/index";
import {API_URL_V1} from '../../../../constants';
import axios from "axios";
import {getCompanyLinks} from "appRedux/actions/Company";
import {Link} from "react-router-dom";

const Option = Select.Option;
let workspace_id;
let formData, timezoneOptions = [];
let disabled;
const uploadButton = (
  <div>
    <Icon type={'plus'} />
    <div className="ant-upload-text">Upload</div>
  </div>
);
function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {

  const isLt2M = file.size / 1024 / 1024 < 1;
  if (!isLt2M) {
    message.error('Image must smaller than 1MB!');
  }
  return isLt2M;
}

class ProfileForm extends Component{

  state = {
    loading: true,
    imageUrl: '',
    imgLoading: false,
    uiSchema: this.uiSchema,
  };

  componentDidMount() {
    workspace_id = localStorage.getItem('id');
    const {authUser} = this.props;
    axios.all([
      axios.get(`${API_URL_V1}/workspaces/${workspace_id}`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/data/timezones`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.get(`${API_URL_V1}/workspaces/${workspace_id}/settings`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
   ])
   .then(axios.spread((companyData, timeZonesData, settingsData) => {
      formData={
        companyName: companyData.data.data.name,
        companySubdomain: companyData.data.data.subdomain,
        language: companyData.data.data.locale ? companyData.data.data.locale : undefined,
        timeZone: companyData.data.data.timezone ? companyData.data.data.timezone : undefined,
        links: settingsData.data.data.links,
      }
      timeZonesData.data.forEach((timezone, index) => {
        timezoneOptions.push(<Option value={timezone}>{timezone}</Option>);
      })
      this.setState({...this.state, imageUrl: `${API_URL_V1}${companyData.data.data.logo}`, uiSchema: {}, loading: false});
      this.setState({...this.state, uiSchema: this.uiSchema});
    }))
    .catch(error => error);
  }

  schema = {
    title: "Company Profile",
    description: "Company related information",
    type: "object",
    required: ["companyName"],
    properties: {
      companyName: {type: "string", title: "Company Name"},
      companyLogo: {type: "string", format: "file", title: "Company Logo"},
      companySubdomain: {type: "string", title: "Sub Domain"},
      language: {type: "string", title: "Language"},
      timeZone: {type: "string", title: "Time Zone"},
      links: {type: "string", title: "Links"},
      financial: {
        type: "object",
        title: "Financial Information",
      },
      taxId: {type: "string", title: "Tax ID"}
    }
  };

  InputWidget = (props) => {
    if(props.schema.title == "Zip" || props.schema.title == "City" || props.schema.title == "State" ||
       props.schema.title == "Address Line 1" || props.schema.title == "Address Line 2"){
      return (
        <input type="text" className="ant-input ant-input-disabled" disabled placeholder={props.schema.title} value={props.value} onChange={(event) => props.onChange(event.target.value)}/>
      );
    } else{
    return (
      <input type="text" className={!disabled ? "ant-input-disabled ant-input" : "ant-input"} disabled={!disabled} placeholder={props.schema.title} value={props.value} onChange={(event) => props.onChange(event.target.value)}/>
    );}
  };

  Widgets = {
    inputWidget: this.InputWidget
  };

  custom = (options: any) => {
    const {authUser} = this.props;
    const data= new FormData();

    data.append("_method", "PUT");
    data.append("logo", options.file);
    var config = {
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
      },
      headers: {
      'Authorization': 'Bearer '+authUser
      }
    };
    axios.post(`${API_URL_V1}/workspaces/${workspace_id}`, data,
      config
    )
    .then(userData =>
      {options.onSuccess(userData.data, options.file)
        message.success("Company Logo Updated");
        this.setState({...this.state, uiSchema: {}});
        this.setState({...this.state, uiSchema: this.uiSchema});
      }
    )
    .catch(error => error);

  }

  uiSchema = {
    companyName: {"ui:widget": this.InputWidget},
    companyLogo: {"ui:widget": (props) => {
      return (
        <Upload id="root_companyLogo" disabled={!disabled} listType="picture-card" showUploadList={false} onChange={this.handleChange} beforeUpload={beforeUpload} customRequest={this.custom}>
          {this.state.imageUrl ? <img src={this.state.imageUrl} alt="companyLogo" /> : uploadButton}
        </Upload>
      );
    }},
    companySubdomain: {"ui:widget": this.InputWidget},
    language: {"ui:widget": (props) => {
      return (
        <Select className="ant-select" disabled={!disabled} placeholder="Select Language" value={props.value} onChange={(value) => props.onChange(value)}>
          <Option value="English">English</Option>
          <Option value="Chinese">Chinese</Option>
          <Option value="Spanish">Spanish</Option>
          <Option value="French">French</Option>
          <Option value="Italian">Italian</Option>
          <Option value="Arabic">Arabic</Option>
        </Select>
      );
    }},
    timeZone: {"ui:widget": (props) => {
      return (
        <Select className="ant-select" disabled={!disabled} placeholder="Select TimeZone" value={props.value} onChange={(value) => props.onChange(value)}>
          {timezoneOptions}
        </Select>
      );
    }},
    links: {"ui:widget": (props) => {
      return (
        <textarea className="ant-input" disabled={!disabled} placeholder={"Eg: \"Link1| https://www.example.com, Link2| https://www.example2.com\""} value={props.value} onChange={(event) => props.onChange(event.target.value)}/>
      );
    },"ui:help" : "Eg: \"Link1| https://www.example1.com, Link2| https://www.example2.com\" "},
    taxId: {"ui:widget": this.InputWidget},
  };

  CustomFieldTemplate(props) {
    return props.displayLabel
      ? <div className="ant-row ant-form-item" style={{width: "100%"}}>
          {props.required ?
          <label htmlFor={props.id} className="ant-form-item-label ant-form-item-required ant-col-xs-24 ant-col-sm-8">
            {props.label}
          </label> :
          <label htmlFor={props.id} className="ant-form-item-label ant-col-xs-24 ant-col-sm-8">
            {props.label}
          </label>}
          <div className="ant-form-item-control-wrapper ant-col-xs-24 ant-col-sm-16">
            {props.description}
            {props.children}
            {props.errors}
            {props.help}
          </div>
        </div>
      : <div className={props.classNames}>
          {props.children}
        </div>;
  };

  onSubmit = (values) => {
    const {authUser} = this.props;
    let fields = ['name', 'subdomain', 'locale', 'timezone'];
    axios.all([
      axios.put(`${API_URL_V1}/workspaces/${workspace_id}`,{
        id: workspace_id,
        name: values.formData.companyName,
        subdomain: values.formData.companySubdomain,
        admin: true,
        locale: values.formData.language,
        timezone: values.formData.timeZone,
      },
      {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
      axios.post(`${API_URL_V1}/workspaces/${workspace_id}/settings`, {
        links: values.formData.links,
      }, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
    ])
    .then(axios.spread((companyData, settingData) => {
      this.props.getCompanyLinks(workspace_id);
      message.success("Company Data Updated");
    }))
    .catch(error => {
      fields.forEach((key, index) => {
        if((error.response.data.errors).hasOwnProperty(key)) {
          message.error(error.response.data.errors[key]);
        }
      })
    })
  }

  handleChange = (info) => {
    if (info.file.status === 'uploading') {
      return;
    }
    if (info.file.status === 'done') {
      getBase64(info.file.originFileObj, imageUrl => {
        this.setState({
        imageUrl,
        uiSchema: {}
      })
      this.setState({...this.state, uiSchema: this.uiSchema});
    });
    }
  }

  render() {
    disabled = (this.props.currentEmployee && this.props.currentEmployee.role) ? this.props.currentEmployee.role.permissions.includes("edit_company") : '';
    return(
      <div>
        {this.state.loading ?
        <CircularProgress /> :
        <Form
          schema={this.schema}
          uiSchema={this.state.uiSchema}
          formData={formData}
          onSubmit={this.onSubmit}
          FieldTemplate={this.CustomFieldTemplate}
          widgets={this.Widgets}
          showErrorList={false} >
          <div className="ant-row ant-form-item">
            <div className="ant-col-xs-24 ant-col-sm-8"></div>
            <div className="ant-col-xs-24 ant-col-sm-16">
              <button type="submit" disabled={!disabled} className="ant-btn btn-primary">Save</button>
            </div>
          </div>
        </Form>}
      </div>
    );
  }
}
const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentEmployee} = company;
  return {authUser, currentEmployee}
};

export default connect(mapStateToProps, {getCompanyLinks})(ProfileForm);
