import React, {Component} from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import {Button, Card, Table, Divider, Modal, message, Row, Col} from "antd";
import Auxiliary from "../../../util/Auxiliary";
import Breadcrumbs from "components/Breadcrumbs/index";
import CircularProgress from "components/CircularProgress/index";
import ClientForm from "./ClientForm";
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import {API_URL_V1} from "../../../constants";
import axios from "axios";
import service from '../../../requests';
import _ from "lodash";
import DepartmentModal from "../settings/General/DepartmentModal";

let data = [];
let workspace_id, disabled;

class Clients extends Component {
  constructor(props){
    super(props)
    this.state = {
      visible: false,
      loading: true,
      formData: {},
      clients: [],
      confirmLoading: false,
      deleteVisible: false,
      deleteLoading: false,
      record: '',
      steps: [
          {
            target: '.add-client-button',
            content: 'Click Here to add Cleint',
            styles: {
              options: {
                zIndex: 9999999,
              },
            },
          },
          {
            target: "body",
            content: "After clicking, modal will popup",
            placement: 'center',
            styles: {
              options: {
                zIndex: 9999999,
              },
            },
          },
          {
            target: ".client-form",
            content: "Fill up the Client details",
            disableBeacon: true
          },
          {
            target: ".add-client-form-button",
            content: "After filling the form, click here to add client",
            disableBeacon: true
          },
        ],
    };
  }

  getClients() {
    this.setState({...this.state, loading: true})

    const {authUser, currentCompany} = this.props;
    service.get('workspaces/'+currentCompany.id+'/clients')
    .then(clientsResponse => {
      this.setState({...this.state, clients: clientsResponse.data.data, loading: false})
    })
    .catch(error => error);
  }

  componentDidMount() {
    this.getClients();
  }

  onShowModal = () => {
    this.setState({...this.state, formData: {}, visible: true});
  }

  deleteRecord = (record, e) => {
    e.preventDefault();
    this.setState({...this.state, deleteLoading: true});
    const {authUser, currentCompany} = this.props;
    service.delete(`${API_URL_V1}/workspaces/${currentCompany.id}/clients/${record.id}`)
    .then(locationData => {
      message.success("Client Deleted Successfully");
      let clients = this.state.clients;
      _.remove(clients, client =>  client.id == record.id)
      this.setState({...this.state, clients: clients, loading: false})
      this.setState({...this.state, deleteVisible: false, deleteLoading: false});

    })
    .catch(error => {
      console.log(error.response.data.message);
      message.error(error.response.data.message);
    })
  }

  handleOk =(value, id) => {
    const {authUser, currentCompany} = this.props;
    this.setState({...this.state, confirmLoading: true});
    if(id){
      service.put(`${API_URL_V1}/workspaces/${currentCompany.id}/clients/${id}`, value)
      .then(clientData => {
        let clients = this.state.clients;
        _.remove(clients, client =>  client.id == clientData.data.data.id)
        clients.push(clientData.data.data);
        message.success("Client Added Successfully");
        this.setState({...this.state, clients: clients, visible: false, formData: {}, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Add Client");
        this.setState({...this.state, confirmLoading: false});
      });
    } else {
      service.post(`${API_URL_V1}/workspaces/${currentCompany.id}/clients`, value)
      .then(clientData => {
        let clients = this.state.clients;
        clients.push(clientData.data.data);
        message.success("Client Editted Successfully");
        this.setState({...this.state, clients: clients, visible: false, formData: {}, confirmLoading: false});
      })
      .catch(error => {
        message.error("Failed to Edit");
        this.setState({...this.state, confirmLoading: false});
      });
    }
  }

  handleCancel = () => {
    this.setState({...this.state, visible: false});
  }

  edit = (record, e) => {
    e.preventDefault();
    console.log(record)
    this.setState({...this.state, formData: record, visible: true});
  }

  handleJoyrideCallback = data => {
    if(data.index === 1) {
      this.setState({...this.state, visible: true, title: "Add Client", editData: ''});
    }
    if(data.index === 2) {
      let steps = [...this.state.steps];
        this.setState({...this.state, steps: steps})
    }
    if(data.action === "skip") {
      this.props.resetJoyride();
    }
  };

  render() {
    disabled = (this.props.currentEmployee && this.props.currentEmployee.role) ? this.props.currentEmployee.role.permissions.includes("edit_company") : '';
    const columns = [{
      title: 'Name',
      dataIndex: 'name',
    },{
      title: 'Actions',
      key: 'x',
      render: (text, record) => (
        <span>
        {disabled ?
          <span className="gx-link" onClick={(e) => {this.edit(record, e)}}><i className="icon icon-edit" title="Edit" style={{verticalAlign: "middle"}}/></span> : <span></span>}

          {disabled ?
              <span className="gx-link" onClick={(e) => {this.setState({...this.state, deleteVisible: true, record: record})}}><Divider type="vertical"/><i className="icon icon-trash gx-text-danger" title="Delete" style={{verticalAlign: "middle"}}/></span> :
              <span></span>
          }
          </span>
          )
        }];
        return(
          <div>
          <Modal
              visible={this.state.deleteVisible}
              title="Delete Client?"
              onOk={(e) => this.deleteRecord(this.state.record, e)}
              onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
              confirmLoading={this.state.deleteLoading}
              footer={[
                <Button key="submit" loading={this.state.deleteLoading} onClick={(e) => this.deleteRecord(this.state.record, e)}>
                  OK
                </Button>,
                <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
                  Cancel
                </Button>
              ]}
          >
              <p>Are you Sure you want to delete {this.state.record.name} Client?</p>
          </Modal>
          {this.state.loading ?
            <CircularProgress/> :
            <div>
            {this.props.joyride === ".clients" ?
            <Joyride
              steps={this.state.steps}
              callback={this.handleJoyrideCallback}
              showSkipButton={true}
              continuous
              // run={this.state.run}
              styles={{
                options: {
                  zIndex: 1000,
                }
              }}
            /> : null}
            {this.state.visible ?
              <ClientForm value={this.state.formData} visible={this.state.visible} onOk={this.handleOk} onCancel={this.handleCancel} confirmLoading={this.state.confirmLoading}/> : null }

              <Auxiliary>
                <div className="gx-profile-content">
                <Breadcrumbs />
                  <Row>
                    <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                      <Card className="gx-card" title="Clients">
                        <div className="gx-mb-5">
                        <Button className="add-client-button" style={{float: "right"}} disabled={!disabled} onClick={this.onShowModal}>Add Client</Button>
                        </div>
                        <Table className="gx-table-responsive" columns={columns} dataSource={this.state.clients} pagination={false} rowKey={'id'}/>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </Auxiliary>
              </div>}
              </div>
            );
          };
        }
        const mapStateToProps = ({auth, company}) => {
          const {authUser} = auth;
          const {currentEmployee, currentCompany, joyride} = company;
          return {authUser, currentEmployee, currentCompany, joyride}
        };

        export default withRouter(connect(mapStateToProps)(Clients));
