import React, {Component} from "react";
import {Button, Card, message} from "antd";
import Auxiliary from "../util/Auxiliary";
import {API_URL_V1} from "../constants";
import axios from "axios";

class ClientInvoice extends Component {
  state={
    previewData: '',
    disabled: true,
  };

  componentDidMount () {
    let invoiceId = this.props.match.params.id;
    let params = new URLSearchParams(this.props.location.search);
    let key = params.get('key');
    axios.get(`${API_URL_V1}/public/invoices/${this.props.match.params.id}/preview`, {
      params: {
        "key": key,
      }
    })
    .then(previewData => {
      this.setState({...this.state, previewData: previewData.data, disabled: false});
    })
    .catch(error => {
      if(key) {
        message.error("Something went wrong. Please try again.")
      } else {
        message.error("Invalid Key");
      }
    })
  }

  onClick = () => {
    let content = document.getElementById("preview-image");
    let pri = document.getElementById("ifmcontentstoprint").contentWindow;
    pri.document.open();
    pri.document.write(content.innerHTML);
    pri.document.close();
    pri.focus();
    pri.print();
  }

  payNow = () => {
    let invoiceId = this.props.match.params.id;
    let params = new URLSearchParams(this.props.location.search);
    let key = params.get('key');
    window.location.href = `${API_URL_V1}/public/invoices/${this.props.match.params.id}/pay/paypal?key=${key}`;
  }

  render () {
    return (
      <Auxiliary>
        <div className="gx-profile-content" style={{height: "100%", overflow: "scroll", maxWidth: "900px", margin: "auto"}}>
          <Card className="gx-card" title="Preview">
            <iframe id="ifmcontentstoprint" style={{display: "none", height: "0px", width: "0px", position: "absolute"}}></iframe>
            <div className="gx-mb-5">
              <Button style={{float: "right"}} disabled={this.state.disabled} onClick={(e) => {this.onClick()}} value="Add">Print Invoice</Button>
              <Button style={{float: "right", marginRight: "1rem"}} disabled={this.state.disabled} onClick={(e) => {this.payNow()}} value="Add">Pay Now</Button>
            </div>
            <div id="preview-image" className="html-editor__content" dangerouslySetInnerHTML={{__html: this.state.previewData}} />
          </Card>
        </div>
      </Auxiliary>
    )
  }
}

export default ClientInvoice;
