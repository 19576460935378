import React, {Component} from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router';
import {Button, Card, Col, Divider, Input, Row, Table, Modal, message, Avatar, Badge} from "antd";
import {API_URL_V1} from "../../../constants";
import CircularProgress from "components/CircularProgress/index";
import {FaCrown} from "react-icons/fa";
import Auxiliary from "../../../util/Auxiliary";
import Timer from "react-compound-timer";
import _ from "lodash";
import axios from "axios";
import moment from "moment";

const Search = Input.Search;

class LiveView extends Component {
  state= {
    visible: false,
    loading: true,
    pagination: {current: 1, pageSize: 15, total: 0},
    employees: [],
    designations: [],
    departments: [],
    q: null,
  }

  getEmployees(params) {
    if(params !== "search") {
      this.setState({...this.state, loading: true});
    }
    const {authUser, currentCompany} = this.props;
    axios.all([
      axios.get(`${API_URL_V1}/workspaces/${currentCompany.id}/employees`, {
        params: {
          live_data: true,
          page: this.state.pagination.current, per_page: this.state.pagination.pageSize, q: this.state.q, ...params
        },
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
    ])
    .then(axios.spread((employeeData) => {
      const pagination = { ...this.state.pagination };
      pagination.pageSize = 15;
      pagination.total =employeeData.data.meta.total;
      this.setState({...this.state, pagination, employees: employeeData.data.data, loading: false});
    }))
    .catch(error => error)
  }

  componentDidMount() {
    this.setState({...this.state, pagination: this.props.pagination, employees: this.props.employees, designations: this.props.designations, departments: this.props.departments, loading: false});
  }

  handleTableChange = (paginationData) => {
    this.setState({...this.state, pagination: paginationData}, () => {this.getEmployees()});
  }

  setSearchQuery = _.debounce((value) => {
    this.setState({...this.state, pagination: {...this.state.pagination, current: 1}, q: value}, () => {this.getEmployees('search')})
  }, 500)

  edit = (record, e) => {
    e.preventDefault();
    this.props.history.push('/employees/'+record.id+'/profile');
  }

  tasks = (record, e) => {
    e.preventDefault();
    this.props.history.push({
      pathname: `employees/${record.id}/tasks`,
      state: {employee: record}
    });
  }

  render() {
    const {currentEmployee, userWorkspaceData} = this.props;
    let canManage = currentEmployee && currentEmployee.role && (currentEmployee.role.permissions.includes("edit_company") || currentEmployee.role.permissions.includes("manage_company"));
    const columns = [{
        title: 'ID',
        render: (text, record) => (
          <span>
              {record && record.oid ? record.oid : record.contract ? record.contract.employee_id : ''}
          </span>
        )
      }, {
        title: 'Name',
        render: (text, record) => {
          let avatarUrl = "";
          if (record.avatar) {
            avatarUrl = `${API_URL_V1}${record.avatar}`;
          }
          return (
            <div>
              {this.props.roles && this.props.roles.map((role, index) => {
                if(role.id == record.role_id && (role.permissions.includes("edit_company") && role.permissions.includes("manage_employee"))) {
                  return <FaCrown className="" color="#ffbb48" style={{transform: 'translate(0px, 5px) rotate(-30deg)', stroke: "#000000"}}/>
                }
              })}
              <div className="gx-flex-row gx-align-items-center gx-avatar-row">
                <Avatar src={avatarUrl}
                        className="gx-size-40 gx-mr-3" alt=""/>
                <span className={canManage ? "gx-link" : "gx-text"} onClick={canManage ? (e) => {this.edit(record, e)} : ''}>

                {`${record.first_name} ${record.last_name}`}<br />
                <span className="gx-text-grey">
                  {this.state.designations.map((designation, index) => {
                    if(designation.id === record.contract.designation_id) {
                      return designation.name;
                    }
                    return null;
                  })}
                </span>
                </span>
              </div>
              </div>
          )
        }
      }, {
        title: 'Tasks',
        render: (text, record) => (
          <span>
            <span className="gx-link" onClick={(e) => {this.tasks(record, e)}}>
              {record.tasks_count}<br/>
            </span><br/>
          </span>
        ),
      }, {
        title: 'Checked In',
        render: (text, record) => {
          let out=[];
          if(record.last_attendance) {
            let intervals = ['hours', 'minutes'];
            let a = moment().local();
            let b = moment.utc(record.last_attendance.in_at);
            for(let i=0; i<intervals.length; i++){
                let diff = a.diff(b, intervals[i]);
                b.add(diff, intervals[i]);
                if(diff !== 0 && Math.abs(diff) !== 1) {
                  out.push(Math.abs(diff) + ' ' + intervals[i]);
                }
                if(Math.abs(diff) === 1) {
                  out.push(Math.abs(diff) + ' ' + intervals[i].slice(0, -1));
                }
                if(Math.abs(diff) < 1 && intervals[i] === 'minutes') {
                  out.push('Just now')
                }
            }
          }
          let diff = 0;
          if(record.last_attendance && record.last_attendance.out_at) {
            diff = moment(record.last_attendance.out_at).diff(moment(record.last_attendance.in_at));
          }
          return(
          <span>
              {record.last_attendance && record.last_attendance.date === moment().format("YYYY-MM-DD") ?
              record.last_attendance.out_at ? <span><span>Checked Out</span><br/>
              <span className="gx-text-grey">
                {((Math.floor(moment.duration(diff).asHours()) < 10) ? `0${Math.floor(moment.duration(diff).asHours())}` : Math.floor(moment.duration(diff).asHours())) +"h"+ moment.utc(diff).format(" mm")+"min"}
              </span>
              </span> :
              out.join(' ') : <span><span>Not Checked In</span><br/></span>} <br />
              <span className="gx-text-grey">
                {record.last_attendance && !record.last_attendance.out_at && record.last_attendance.date === moment().format("YYYY-MM-DD") ? moment.utc(record.last_attendance.in_at).local().format("hh:mm:ss") : ""}
              </span>
          </span>
        )}
      }, {
        title: 'Working on',
        render: (text, record) => {
          let diff = 0;
          if(record.time_entry) {
            diff = (moment().local().diff(moment.utc(record.time_entry.start), 'milliseconds'));
          }
          return (
          <span>
              {record.time_entry ? this.props.projects.map((project, index) => {
                if(record.time_entry.project_id === project.id) {
                  return <span>{project.name} {record.time_entry.description ? "("+record.time_entry.description+")" : "(No description)"}</span>;
                }
                if(!record.time_entry.project_id && index === this.props.projects.length-1) {
                  return <span>{"No Project"} {record.time_entry.description ? "("+record.time_entry.description+")" : "(No description)"}</span>
                }
              }) : ""}<br/>
              <span className="gx-text-grey">
                {record.time_entry ?
                  <Timer initialTime={diff} lastUnit="h">
                    {({getTime}) => (
                    <div>
                      <Timer.Hours formatValue={value => `${(value < 10 ? `0${value}` : value)} : `} />
                      <Timer.Minutes formatValue={value => `${(value < 10 ? `0${value}` : value)} : `} />
                      <Timer.Seconds formatValue={value => `${(value < 10 ? `0${value}` : value)}`} />
                    </div>
                  )}
                </Timer> : ""}
              </span>
          </span>
        )}
      }];
    return (
      <div>
      {this.state.loading ?
      <CircularProgress /> :
      <Auxiliary>
        <div className="gx-profile-content">
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
                <div style={{marginBottom: "2.5rem"}}>
                  <Search
                    placeholder="Search Employee"
                    defaultValue={this.state.q}
                    onChange={(e) => {this.setSearchQuery(e.target.value)}}
                    style={{float: "right", width: 200}}
                  />
                </div>
                <Table className="gx-table-responsive" columns={columns} dataSource={this.state.employees} pagination={this.state.pagination} onChange={this.handleTableChange}/>
            </Col>
          </Row>
        </div>
      </Auxiliary>}
      </div>
    );
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser, roles} = auth;
  const {currentCompany, currentEmployee} = company;
  return {authUser, roles, currentCompany, currentEmployee}
};

export default withRouter(connect(mapStateToProps)(LiveView));
