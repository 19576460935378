import React from "react";
import {Cell, Pie, PieChart, ResponsiveContainer, Tooltip} from "recharts";
import moment from "moment";

const COLORS = ['#e85b7a', '#ff6486', '#ff809c', '#ffc0ce'];

function CustomTooltip({ payload, label, active, projectData, employeeData, clientData, group }) {
  if (active) {
    return (
      <div>
      {payload[0].payload.seconds ?
        <div className="custom-tooltip" style={{backgroundColor: "#ffffff", borderRadius: "8px"}}>
          <div style={{margin: "5%", width: "150px"}}>
            <p className="day" style={{fontWeight: "bold"}}>{group === "project_id" ? payload[0].payload.id ? projectData.map((project) => {
                if(project.id === payload[0].payload.id) {
                  return project.name;
                }
              }) : "Without Project" : group === "employee_id" ? payload[0].payload.id ? employeeData.map((employee) => {
                if(employee.id === payload[0].payload.id) {
                  return employee.first_name;
                }
              }) : "Without User" : group === "client_id" ? payload[0].payload.id ? clientData.map((client) => {
                if(client.id === payload[0].payload.id) {
                  return client.name;
                }
              }) : "Without Client" : ''}</p>
            <p className="label">Total : {Math.floor(moment.duration(payload[0].payload.seconds*1000).asHours()) + moment.utc(payload[0].payload.seconds*1000).format(":mm:ss")}</p>
          </div>
        </div> : ''}
      </div>
    );
  }
  return null
}

const WeeklyReportChart = (reportData) => {
  let total=0;
  console.log(reportData.graphData);
  let result = [];
  reportData.graphData.forEach((data, index) => {
    let totalTime=0;
    data.sub_groups.map((subData, i) => {
      totalTime = totalTime + subData.seconds;
    })
    let tempObj = {id: data.id, seconds: totalTime};
    result.push(tempObj);
  })
  return (
    <div>
      <div style={{fontWeight: "bold", fontSize: "18px", textAlign: "center"}}>Clocked Time</div>
      {reportData.graphData.map((data, index) => {
        data.sub_groups.map((subData, index) => (
          total=total+subData.seconds
        ))
      })}
      <div style={{textAlign: "center"}}>{Math.floor(moment.duration(total*1000).asHours()) + moment.utc(total*1000).format(":mm:ss")}</div>
      <div className="gx-py-3">
        <ResponsiveContainer width="100%" height={100}>
          <PieChart>
            <Tooltip content={<CustomTooltip projectData={reportData.projectData} employeeData={reportData.employeeData} clientData={reportData.clientData} group={reportData.group}/>}/>
            <Pie
              data={result}
              dataKey="seconds"
              cx="50%"
              cy="50%"
              innerRadius={30}
              outerRadius={50}
            >
              {
                result.map((entry, index) => <Cell key={index} fill={entry.id ? "#4bc800" : "#cecece"}/>)
              }
            </Pie>
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
export default WeeklyReportChart;
