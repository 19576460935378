import React, {Component} from "react";
import {connect} from "react-redux";
import {Avatar, Popover} from "antd";
import {userSignOut} from "appRedux/actions/Auth";
import { withRouter } from 'react-router';
import {API_URL_V1} from "../../constants";

class UserInfo extends Component {

    state = {
      name: '',
      visible: false,
    };

    hide = () => {
      this.setState({
        visible: false,
      });
    }

    handleVisibleChange = (visible) => {
      this.setState({ visible });
    }

  render() {
    const userMenuOptions = (
      <ul className="gx-user-popover">
        <li onClick={() => {this.props.history.push('/my_account'); this.hide()}}>My Account</li>
        <li onClick={() => {this.props.history.push('/my_profile'); this.hide()}}>My Profile</li>
        <li onClick={() => {this.props.history.push('/workspaces'); this.hide()}}>Switch Company</li>
        <li onClick={() => this.props.userSignOut(this.props.authUser)}>Logout
        </li>
      </ul>
    );

    let avatarUrl = "";
    if (this.props.currentEmployee && this.props.currentEmployee.avatar) {
      avatarUrl = `${API_URL_V1}${this.props.currentEmployee.avatar}`;
    }

    return (
      <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={userMenuOptions} visible={this.state.visible} onVisibleChange={this.handleVisibleChange}
               trigger="click">
        <Avatar src={avatarUrl} size={35}
                className="gx-avatar gx-pointer" alt=""/>
      </Popover>
    )

  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentEmployee} = company;
  return {authUser, currentEmployee}
};

export default withRouter(connect(mapStateToProps, {userSignOut})(UserInfo));
