import React, {Component} from "react";
import {connect} from "react-redux";
import {Card, Table, Spin, Select} from "antd";
import {API_URL_V1} from "../../../constants";
import MonthlyAttendance from "./MonthlyAttendance";
import { withRouter } from 'react-router';
import axios from "axios";
import moment from "moment";

const Option = Select.Option;
class Attendance extends Component {
  state={
    attendance: [],
    month: '',
    year: '',
    loading: true,
    monthView: false,
    record: '',
  }

  getYearlyAttendance = (params) => {
    const {authUser, currentEmployee} = this.props;
    let employee_id = this.props.employee ? this.props.employee.id : currentEmployee.id;
    axios.all([
      axios.get(`${API_URL_V1}/employees/${employee_id}/attendances-yearly`, {
        params: {
          year: params,
        },
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      })
    ])
    .then(axios.spread((attendanceData) => {
      this.setState({...this.state, attendance: attendanceData.data.data, loading: false})
    }))
    .catch(error => error)
  }

  componentDidMount () {
    this.getYearlyAttendance();
  }

  handleChange = (value) => {
    this.setState({...this.state, year: value, loading: true});
    this.getYearlyAttendance(value);
  }

  changeView = (record, e) => {
    this.setState({...this.state, month: record.month, year: this.state.year, monthView: true, record: record});
  }

  onYearView = () => {
    this.setState({...this.state, monthView: false});
  }

  render() {
    let employee_id = this.props.employee ? this.props.employee.id : this.props.currentEmployee.id
    const columns = [{
        title: 'Month',
        render: (text, record) => (
          <span className="gx-link" onClick={(e) => {this.changeView(record, e)}}>{moment(record.month, 'MM').format('MMMM')}</span>
        )
      }, {
        title: 'Working Days',
        render: (text, record) => (
          <span>
            {record.working_days}
          </span>
        ),
      }, {
        title: 'Attendance',
        render: (text, record) => (
          <span>
            {record.attendance}
          </span>
        ),
      }, {
        title: 'Leaves Applied',
        render: (text, record) => (
          <span>
            {record.leaves_applied}
          </span>
        ),
      }];
    let minOffset = 0, maxOffset = 10;
    let thisYear = (new Date()).getFullYear();
    let allYears = [];
    let yearUpto = (new Date(this.props.currentEmployee.created_at)).getFullYear();
    for(let x = 0; x <= maxOffset; x++) {
      if(yearUpto <= (thisYear - x)) {
        allYears.push(thisYear - x)
      }
    }
    const yearList = allYears.map((x) => {return(<Option key={x}>{x}</Option>)});
    return (
      <div>
        {this.state.monthView ?
          <MonthlyAttendance month={this.state.month} year={this.state.year} onYearView={this.onYearView} employee={this.props.employee} attendanceStats={this.state.record}/> :
          <Spin spinning={this.state.loading}>
            <div><b>Yearly Attendance</b></div>
            <div className="gx-mb-5">
              <Select placeholder="Select Year" defaultValue={this.state.year || thisYear} style={{float: "right"}} onChange={this.handleChange}>
                {yearList}
              </Select>
            </div>
            <Table className="gx-table-responsive" dataSource={this.state.attendance} columns={columns} pagination={false}/>
          </Spin>
        }
      </div>
    )
  }
}

const mapStateToProps = ({auth, company}) => {
  const {authUser} = auth;
  const {currentEmployee} = company;
  return {authUser, currentEmployee}
};

export default withRouter(connect(mapStateToProps)(Attendance));
