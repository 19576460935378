import React from "react";
import {Avatar, Timeline, Row, Col} from "antd";
import {API_URL_V1} from '../../../constants';
import CircularProgress from "components/CircularProgress/index";
import Widget from "components/Widget/index";

const TimeLineItem = Timeline.Item;

function compare(a,b) {
  if (a.date < b.date)
    return -1;
  if (a.date > b.date)
    return 1;
  return 0;
}

function getRandomColor() {
  let letters = '0123456789ABCDEF';
  let color = "#";
  for (let i= 0; i< 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

function getName(metadata, shape) {
  if(metadata.avatar !== undefined) {
    if (metadata.avatar === '' || metadata.avatar === null) {
      let first_nameSplit = metadata.first_name.split(" ");
      let last_nameSplit = metadata.last_name.split(" ");
      if (metadata.first_name.split(" ").length === 1) {
        const initials = first_nameSplit[0].charAt(0).toUpperCase() + last_nameSplit[0].charAt(0).toUpperCase();
        return <Avatar shape={shape} className="gx-size-40" style={{backgroundColor: getRandomColor()}}>{initials}</Avatar>
      } else {
        const initials = first_nameSplit[0].charAt(0).toUpperCase() + first_nameSplit[1].charAt(0).toUpperCase();
        return <Avatar shape={shape} className="gx-size-40" style={{backgroundColor: getRandomColor()}}>{initials}</Avatar>
      }
    } else {
      return <Avatar shape={shape} className="gx-size-40" src={`${API_URL_V1}${metadata.avatar}`}/>;
    }
  } else if(metadata.employee !== undefined) {
    if (metadata.employee.avatar === '' || metadata.employee.avatar === null) {
      let first_nameSplit = metadata.employee.first_name.split(" ");
      let last_nameSplit = metadata.employee.last_name.split(" ");
      if (metadata.employee.first_name.split(" ").length === 1) {
        const initials = first_nameSplit[0].charAt(0).toUpperCase() + last_nameSplit[0].charAt(0).toUpperCase();
        return <Avatar shape={shape} className="gx-size-40" style={{backgroundColor: getRandomColor()}}>{initials}</Avatar>
      } else {
        const initials = first_nameSplit[0].charAt(0).toUpperCase() + first_nameSplit[1].charAt(0).toUpperCase();
        return <Avatar shape={shape} className="gx-size-40" style={{backgroundColor: getRandomColor()}}>{initials}</Avatar>
      }
    } else {
      return <Avatar shape={shape} className="gx-size-40" src={`${API_URL_V1}${metadata.employee.avatar}`}/>;
    }
  } else {
    return <Avatar shape={shape} className="gx-size-40" src={''}/>;
  }
}

class UpcomingActivity extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      recentList: [],
      shape: '',
      limit: 2,
      reload: 0
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentWillMount() {
    this.setState({
      height: window.innerHeight + 'px',
      width: window.innerWidth + 'px',
      recentList: this.props.recentList,
      shape: 'circle'
    });
    if (window.innerWidth < 575) {
      this.setState({limit: 1});
    }

  }

  componentDidUpdate() {
    if((this.props.recentList).length === 0 && this.state.limit !== 0 && this.state.reload === 0) {
      this.setState({...this.state, limit: 0});
    }
    if((this.props.recentList).length !== 0 && this.state.limit === 0 && this.state.reload === 0) {
      this.setState({...this.state, limit: ((this.props.recentList).length < 2) ? ((this.props.recentList).length) : 2, reload: 1});
    }
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({width: window.innerWidth, height: window.innerHeight});
  }

  onLoadMore() {
    this.setState((previousState) => ({
      limit: previousState.limit + 1,
    }));
  }

  onLoadLess() {
    this.setState((previousState) => ({
      limit: previousState.limit - 1,
    }));
  }

  render() {
    const {shape, limit} = this.state;
    const {recentList} = this.props;
    console.log(this.props.recentList);
    let month = ("0" + (new Date().getMonth() + 1)).slice(-2)
    let day = ("0" + new Date().getDate()).slice(-2)
    let year = new Date().getFullYear();
    let date = year + "-" + month + "-" + day;
    recentList.sort(compare);
    const recentLists=[];
    return (
      <div className="gx-entry-sec">
      <Widget title={<h2 className="h4 gx-text-capitalize gx-mb-0">Upcoming Events</h2>} extra={<span className="gx-ml-2 gx-search-icon" onClick={() => {this.props.refresh()}}><i
        className="icon icon-frequent gx-pointer gx-text-primary gx-fs-xxl"/></span>}>
        {this.props.loader ?
        <CircularProgress height="5%"/>:
        <div>
        {recentList.slice(0, limit).map((events, index) =>
          <div className="gx-timeline-info" key={"activity" + index}>
            <h4 className="gx-timeline-info-day">{(events.date === date) && 'Today' || events.date}</h4>
            <Timeline>
                <TimeLineItem key={"timeline" + index} mode="alternate" dot={
                  getName(events.metadata, shape)
                }>
                {events.type === "birthday" &&
                  <span>
                    {events.metadata.first_name} {events.metadata.last_name + "'s"} {events.type}
                    <br/>
                    {events.date === date &&
                      <span>
                    Say Happy Birthday!!</span>}
                  </span>
                }
                {events.type === "leave" &&
                  <span>
                    {events.metadata.employee.first_name} {events.metadata.employee.last_name + " on Leave"}
                    <br/>
                    From: {events.metadata.start_on} To: {events.metadata.finish_on}
                  </span>
                }
                {events.type === "event" &&
                  <span>
                    {events.metadata.title} {"("+events.metadata.location+")"}
                    <br/>
                    {events.metadata.end_date ?
                    "From: "+events.metadata.event_on+ " to: "+events.metadata.end_date.split(' ')[0] :
                    "On: "+events.metadata.event_on+ " at: "+events.metadata.event_time
                    }
                  </span>
                }
                {events.type === "holiday" &&
                  <span>
                    {events.metadata.name+ "'s Holiday"}
                    <br/>
                    On: {events.metadata.date}
                  </span>
                }
                </TimeLineItem>
            </Timeline>
          </div>)}
          {recentList.length === 0 && <Row type="flex" justify="center" className="gx-mb-3">
            <Col xs={18} lg={9} className="text-center">
            <div className="gx-mt-2">
              <i className={"icon icon-hotel-booking gx-fs-xlxl gx-text-green"}/>
            </div>
            <div className="gx-mt-2">
              No Upcoming Event
            </div>
            </Col>
          </Row>}
        {(this.state.limit === this.props.recentList.length) ? null :
        <span className="gx-link gx-btn-link" onClick={this.onLoadMore.bind(this)}>Load More</span>}
        {(this.state.limit === 0) ? null :
        <span className="gx-link gx-btn-link" style={{float: "right"}} onClick={this.onLoadLess.bind(this)}>Show Less</span>}
        </div>}
        </Widget>
      </div>
    );
  }
}

export default UpcomingActivity;
