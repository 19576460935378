import React, {Component} from "react";
import {connect} from "react-redux";
import { withRouter } from 'react-router-dom';
import {Button, Card, Table, Divider, message, Row, Col, Dropdown, Menu, Tag, Modal} from "antd";
import Auxiliary from "../../../util/Auxiliary";
import CircularProgress from "components/CircularProgress/index";
import ContractForm from "./ContractForm";
import ContractFormModal from "./ContractFormModal";
import {API_URL_V1} from "../../../constants";
import axios from "axios";
import { Link } from 'react-router-dom'
import Widget from "@/components/Widget/index";

class ContractWidget extends Component {
  state = {
    deleteVisible: false,
  }
  render() {
    let desig = this.props.designation.filter((designation, index) => {
      if(designation.id === this.props.contract.designation_id) {
        return designation.name;
      }
    });
    return (
      <Col xs={24} lg={12}>
      <Modal
        visible={this.state.deleteVisible}
        title="Delete Contract?"
        onOk={(e) => this.props.deleteContract(this.props.contract, e)}
        onCancel={(e) => {this.setState({...this.state, deleteVisible: false})}}
        confirmLoading={this.props.deleteLoading}
        footer={[
          <Button key="submit" loading={this.props.deleteLoading} onClick={(e) => this.props.deleteContract(this.props.contract, e)}>
            OK
          </Button>,
          <Button key="cancel" onClick={(e) => {this.setState({...this.state, deleteVisible: false})}}>
            Cancel
          </Button>
        ]}
      >
        <p>Are you Sure you want to delete this Contract?</p>
      </Modal>
      <Widget
          styleName="gx-card-full gx-text-center ant-card-bordered" extra={ this.props.showIcons ?
        <ul className="gx-list-inline gx-ml-auto gx-mb-0 gx-text-grey">
          <li>{this.props.contract.can_be_deleted ?
            <Dropdown
                  overlay={<Menu>
                              {this.props.contract.can_be_deleted ?
                              <Menu.Item key="0">
                                <a onClick={(e) => {this.setState({...this.state, deleteVisible: true})}}>Delete</a>
                              </Menu.Item> : '' }
                            </Menu>}

              trigger={['click']}>
            <span className="gx-link ant-dropdown-link gx-text-grey gx-mr-2">
              <i className="icon icon-setting"/>
            </span>
          </Dropdown> : ''}</li>
        </ul> : null
      }>
      <div className="gx-pt-4 gx-px-3" onClick={() => {this.props.selectContract(this.props.contract)}} style={{cursor: 'pointer'}}>
        <h4 className="gx-mb-3">Effective On: {this.props.contract.effective_on}</h4>
        <div className="gx-mb-2">
          Designation: {(desig.length > 0) ? desig[0].name : ''}<br/>
          Role: {this.props.contract.role_description}<br/>
          Start Date: {this.props.contract.start_date}<br/>
          End Date: {this.props.contract.end_date}
        </div>
      </div>
      </Widget>
      </Col>
    );
  }
}

let contracts = [];
let workspace_id, disabled;

class Contracts extends Component {
  constructor(props){
    super(props)
    this.state = {
      loading: true,
      visible: false,
      contractData: '',
      selectedContract: '',
      modalVisible: false,
      confirmLoading: false,
      deleteLoading: false,
    };
  }

  getAllContracts = () => {
    const {authUser, currentEmployee} = this.props;
    let employee_id = this.props.employee ? this.props.employee.id : currentEmployee.id;
    contracts = [];
    axios.all([
      axios.get(`${API_URL_V1}/employees/${employee_id}/contracts`, {
        headers: {
          'Authorization': 'Bearer '+authUser
        }
      }),
    ])
    .then(axios.spread((contractData) => {
      for (let i = 0; i < (contractData.data.data).length; i++) {
        contracts.push(contractData.data.data[i])
      }
      this.setState({...this.state, contractData: contractData.data.data, visible: false, loading: false});
    }))
    .catch(error => error)
  }

  componentDidMount() {
    this.getAllContracts();
  }

  selectContract = (contract) => {
    this.setState({...this.state, selectedContract: contract, visible: true});
  }

  onClick = () => {
    this.setState({...this.state, modalVisible: true});
  }

  onOk = (values) => {
    this.setState({...this.state, confirmLoading: true});
    const {authUser, currentEmployee} = this.props;
    let employee_id = this.props.employee ? this.props.employee.id : currentEmployee.id;
    axios.post(`${API_URL_V1}/employees/${employee_id}/contracts`, {
      effective_on: values,
      },
      {
        headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(contractData => {
      message.success("New Contract created");
      this.setState({...this.state, modalVisible: false, confirmLoading: false});
      this.getAllContracts();
    })
  }

  onCancel = () => {
    this.setState({...this.state, modalVisible: false});
  }

  deleteRecord = (record) => {
    this.setState({...this.state, deleteLoading: true});
    const {authUser} = this.props;
    let url = `${API_URL_V1}/employees/${record.employee_id}/contracts/${record.id}`;
    axios.delete(url, {
      headers: {
        'Authorization': 'Bearer '+authUser
      }
    })
    .then(response => {
      message.success("Contract Deleted");
      this.getAllContracts();
      this.setState({...this.state, deleteLoading: false});
    })
    .catch(error => error)
  }

  render() {
    disabled = (this.props.currentEmployee && this.props.currentEmployee.role) ? this.props.currentEmployee.role.permissions.includes("edit_company") : '';
    return(
      <div>
      <Row type="flex" justify="center" className="gx-mb-5">
        <Col xs={18} lg={9} className="text-center">
        <div className="gx-mt-2">
          <i className={"icon icon-select gx-fs-xlxl gx-text-orange"}/>
        </div>
        <div className="gx-mt-2">
          Manage here all {this.props.employee ? this.props.employee.first_name+" "+this.props.employee.last_name : this.props.currentEmployee.first_name+" "+this.props.currentEmployee.last_name}'s Contracts.
        </div>
        </Col>
      </Row>
      <div>
      {this.state.loading ?
      <CircularProgress/> :
      <div>
      <Auxiliary>
        <Row type="flex" justify="center">
        <div className="gx-mb-5">
          <Button style={{float: "right"}} disabled={!disabled} onClick={this.onClick}>Add New Contract</Button>
        </div>
      </Row>
        <Row type="flex" justify="center" gutter={16}>
          {contracts.map((contract, index) => {
            return <ContractWidget key={index} contract={contract} designation={this.props.designation} edit={this.edit} deleteContract={this.deleteRecord} showIcons={disabled} selectContract={this.selectContract} deleteLoading={this.state.deleteLoading}/>
          })}
        </Row>
      </Auxiliary>
      </div>}
      {this.state.visible ?
        <ContractForm contract={this.state.selectedContract} designation={this.props.designation} workspaceData={this.props.currentEmployee} getAllContracts={this.getAllContracts} deleteRecord={this.deleteRecord} deleteLoading={this.state.deleteLoading} currentCompany={this.props.currentCompany} employee={this.props.employee}/> : null}
      {this.state.modalVisible ?
        <ContractFormModal title={"Add New Contract"} visible={this.state.modalVisible} onCancel={this.onCancel} onOk={this.onOk} confirmLoading={this.state.confirmLoading}/> : ''}
      </div>
      </div>
    );
  };
}
const mapStateToProps = ({auth, company}) => {
  const {authUser, userWorkspaceData} = auth;
  const {currentEmployee, currentCompany} = company;
  return {authUser, currentEmployee, currentCompany}
};

export default withRouter(connect(mapStateToProps)(Contracts));
