import React from "react";
import {Card, Icon, message, Upload} from "antd";
import {API_URL_V1} from '../../../constants';
import axios from "axios";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

function beforeUpload(file) {

  const isLt2M = file.size / 1024 / 1024 < 1;
  if (!isLt2M) {
    message.error('Image must smaller than 1MB!');
  }
  return isLt2M;
}

class Avatar extends React.Component {
  state = {
    loading: false,
    imageUrl: null,
  };
  handleChange = (info) => {
    if (info.file.status === 'uploading') {
      this.setState({loading: true});
      return;
    }
    if (info.file.status === 'done') {
      // Get this url from response in real world.
      getBase64(info.file.originFileObj, imageUrl => this.setState({
        imageUrl,
        loading: false,
      }));
      if (this.props.onUpdate){
        this.props.onUpdate(info.file.response.data.avatar);
      }
    }
  };

  customRequest = (options: any) => {
    const {token, currentCompany} = this.props;
    const data= new FormData();

    data.append("_method", "PUT");
    data.append("avatar", options.file);
    var config = {
      onUploadProgress: function(progressEvent) {
        var percentCompleted = Math.round( (progressEvent.loaded * 100) / progressEvent.total );
      },
      headers: {
      'Authorization': 'Bearer '+token
      }
    };
    axios.post(`${API_URL_V1}/workspaces/${currentCompany.id}/me`, data,
      config
    )
    .then(userData =>
      {options.onSuccess(userData.data, options.file)
        message.success("Avatar Updated");
      }
    )
    .catch(error => error);

  }

  render() {
    const uploadButton = (
      <div>
        <Icon type={this.state.loading ? 'loading' : 'plus'}/>
        <div className="ant-upload-text">Upload Profile Pic</div>
      </div>
    );
    const imageUrl = this.state.imageUrl || this.props.avatarUrl;
    const id = this.props.currentCompany ? this.props.currentCompany.id : '';
    return (
        <Upload
          accept="image/*"
          name="avatar"
          listType="picture-card"
          className="avatar-uploader"
          showUploadList={false}
          customRequest={this.customRequest}
          beforeUpload={beforeUpload}
          onChange={this.handleChange}
        >
          {imageUrl ? <img src={imageUrl} alt=""/> : uploadButton}
        </Upload>
    );
  }
}

export default Avatar;
