import React, {Component} from "react";
import {Modal, Form, Input, Select, Button, Switch} from 'antd';

const {TextArea} = Input;
const Option = Select.Option;

const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends Component {
    render() {
      const formItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 8},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 16},
        },
      };
      const { getFieldDecorator } = this.props.form;
      return (
        <Modal
          visible={this.props.visible}
          title={this.props.title}
          onCancel={this.props.onCancel}
          onOk={this.props.onCreate}
          footer={[
            <Button key="submit" loading={this.props.loading} onClick={this.props.onCreate}>
              {this.props.title}
            </Button>,
            <Button key="cancel" onClick={this.props.onCancel}>
              Cancel
            </Button>
          ]}
        >
          <Form >
            <Form.Item
            {...formItemLayout}
            label="Question">
              {getFieldDecorator('question', {
                initialValue: this.props.value.question,
                rules: [{ required: true, message: 'Please enter question!' }],
              })(
                <TextArea placeholder= "Question to be asked"/>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Question Type">
              {getFieldDecorator('answer_type', {
                initialValue: this.props.value.answer_type ? (this.props.value.answer_type).toString() : undefined,
                rules: [{ required: true, message: 'Please select the Type!' }],
              })(
                <Select placeholder="Select Type" showAction={['click', 'focus']}>
                  <Option value="rating">Rating</Option>
                  <Option value="text">Text</Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item
            {...formItemLayout}
            label="Enabled">
              {getFieldDecorator('enabled', {
                initialValue: this.props.value.enabled,
                valuePropName: 'checked',
              })(
                <Switch />
              )}
            </Form.Item>
          </Form>
        </Modal>
      );
    }
  }
);

class QuestionModal extends React.Component {

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onOk(values, this.props.value.id);
    });
  }

  render() {
    return (
      <div>

        <CollectionCreateForm
          title={this.props.title}
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          onCreate={this.handleCreate}
          value={this.props.value}
          loading={this.props.loading}
        />

      </div>
    );
  }
}

export default QuestionModal;
