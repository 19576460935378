import React from "react";
import {Row, Col} from "antd";
import Widget from "components/Widget";
import CircularProgress from "components/CircularProgress";
import LeaveItem from "./LeaveItem";

class LeaveList extends React.Component {

  render() {
    const {data} = this.props;
    return (
      <Widget title={
        <h2 className="h4 gx-text-capitalize gx-mb-0">
          Leave Requests</h2>
      } styleName="gx-card-ticketlist"
              extra={<span className="gx-ml-2 gx-search-icon" onClick={() => {this.props.refresh()}}><i
                className="icon icon-frequent gx-pointer gx-text-primary gx-fs-xxl"/></span>}>
        {this.props.loader ? <CircularProgress className="gx-loader-400" height="5%"/> :
          (data.length !== 0) ? data.map((data, id) => <LeaveItem key={id} data={data}/>) :
          <div>
            <Row type="flex" justify="center" className="gx-mb-3">
              <Col xs={18} lg={9} className="text-center">
              <div className="gx-mt-2">
                <i className={"icon icon-datepicker gx-fs-xlxl gx-text-green"}/>
              </div>
              <div className="gx-mt-2">
                No Pending Leave Requests
              </div>
              </Col>
            </Row>
          </div>
        }
      </Widget>
    );
  }
}


export default LeaveList;
