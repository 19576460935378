import React, {Component} from "react";
import {Modal, Form, Input, Switch, Col, Select, Row, Button} from 'antd';
import {CirclePicker} from "react-color";

const Option = Select.Option;
const CollectionCreateForm = Form.create({ name: 'form_in_modal' })(
  class extends Component {
    render() {
      const formItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 6},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 18},
        },
      };
      const inlineItemLayout = {
        labelCol: {
          xs: {span: 24},
          sm: {span: 12},
        },
        wrapperCol: {
          xs: {span: 24},
          sm: {span: 12},
        },
      };


      const { getFieldDecorator } = this.props.form;
      return (
        <Modal
          visible={this.props.visible}
          title={this.props.title}
          onCancel={this.props.onCancel}
          onOk={this.props.onCreate}
          width={600}
          footer={[
            <Button key="submit" loading={this.props.confirmLoading} onClick={this.props.onCreate}>
              {this.props.title}
            </Button>,
            <Button key="cancel" onClick={this.props.onCancel}>
              Cancel
            </Button>
          ]}
        >
          <Form >
            <Form.Item
            {...formItemLayout}
            label="Leave Type">
              {getFieldDecorator('leavestypes_name', {
                initialValue: this.props.value.name,
                rules: [{ required: true, message: 'Please input the Leave Type!' }],
              })(
                <Input placeholder= "Leave Type"/>
              )}
            </Form.Item>
            <Row gutter={24}>
              <Col span={12} style={{ display:'block'}}>
                <Form.Item
                  label="Leave Accrues"
                  {...inlineItemLayout}
                >
                  {getFieldDecorator('leave_accrues', {
                    initialValue: (this.props.value.accrues !== undefined) ? (this.props.value.accrues).toString() : undefined,
                    rules: [{ required: true, message: 'Select Leave Accrues!' }],
                  })(
                    <Select placeholder="Please Select" showAction={['click', 'focus']}>
                      <Option value="true">Yes</Option>
                      <Option value="false">No</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
              <Col span={12} style={{ display:'block'}}>
                <Form.Item
                  label="Requires Approval"
                  {...inlineItemLayout}
                >
                  {getFieldDecorator('requires_approval', {
                    initialValue: (this.props.value.approval_required !== undefined) ? (this.props.value.approval_required).toString() : undefined,
                    rules: [{ required: true, message: 'Select Approval!' }],
                  })(
                    <Select placeholder="Please Select" showAction={['click', 'focus']}>
                      <Option value="true">Yes</Option>
                      <Option value="false">No</Option>
                    </Select>
                  )}
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Modal>
      );
    }
  }
);

class LeavesTypesModal extends React.Component {

  saveFormRef = (formRef) => {
    this.formRef = formRef;
  }

  handleCreate = () => {
    const form = this.formRef.props.form;
    form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.props.onOk(values, this.props.value.id);
    });
  }

  render() {
    return (
      <div>

        <CollectionCreateForm
          title={this.props.title}
          wrappedComponentRef={this.saveFormRef}
          visible={this.props.visible}
          onCancel={this.props.onCancel}
          onCreate={this.handleCreate}
          value={this.props.value}
          confirmLoading={this.props.confirmLoading}
        />

      </div>
    );
  }
}

export default LeavesTypesModal;
